import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import DataTable from 'react-data-table-component';
import { Link } from 'gatsby';
import Layout from '../components/layouts/layout-default';
import HeaderHome from '../components/common/header-home';
import { defaultToastError } from '../utils/toast-error';
import { getProducts, updateProductStatus } from '../api/product.request';
import { getProductStatuses } from '../api/referenceData.request';
import ProductStatusConfirmation from '../components/product-status-confirmation';
import ProductCsvImportModal from '../components/product-csv-import-modal';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const columns = [
	{
		name: 'Product Name',
		selector: 'productName',
		sortable: true,
		cell: (row) => (
			<Link
				className="font-bold hover:underline"
				to={`/product/${row.productId}`}
			>
				{row.productName}
			</Link>
		),
		wrap: true
	},
	{
		name: 'Status',
		selector: 'productStatus',
		sortable: true,
		wrap: true
	},
	{
		name: 'Qty Available',
		selector: 'inventory',
		sortable: true,
		wrap: true
	},
	{
		name: 'Qty Reserved',
		selector: 'reserved',
		sortable: true,
		wrap: true
	}
];

const ProductPage = () => {
	const [loading, setLoading] = useState(true);
	const [products, setProducts] = useState([]);
	const [tableProducts, setTableProducts] = useState([]);
	const [status, setStatus] = useState(null);
	const [statuses, setStatuses] = useState([]);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [clearSelectedProducts, setClearSelectedProducts] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [
		areProductsValidForActiveUpdate,
		setAreProductsValidForActiveUpdate
	] = useState(true);
	const [activeClicked, setActiveClicked] = useState(false);
	const [showCsvImportModal, setShowCsvImportModal] = useState(false);
	const [storeName, setStoreName] = useState('');
	const [searchValue, setSearchValue] = useState('');

	const showError = (message) => {
		toast.error(message, {
			position: 'top-center',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	};

	const fetchProducts = () => {
		getProducts()
			.then((res) => {
				const tempProducts = res.data.map((product) => {
					if (!storeName) {
						setStoreName(product.item2.account.companyName);
					}
					const tempProduct = {
						productName: product.item2.productName,
						productStatusId: product.item2.productStatusId,
						productStatus: product.item2.productStatus.referenceDataValue,
						productTypeId: product.item2.productTypeId,
						productType: product.item2.productType.referenceDataValue,
						inventory: product.item2.availableStock,
						productId: product.item2.productId,
						hasImage: product.item1,
						reserved:
							product.item2.availableStock - product.item2.totalAvailableStock,
						tags: product.item2.tags
					};
					return tempProduct;
				});
				setProducts(tempProducts);
			})
			.catch((err) => {
				if (err.response && err.response.data.error) {
					defaultToastError(err.response.data.error);
				}
			})
			.finally(() => {
				setStatus(0); // draft
				setLoading(false);
			});
	};

	useEffect(() => {
		getProductStatuses()
			.then((res) => {
				const tempStatuses = res.data
					.map((s) => {
						if (s.referenceDataId !== 119) {
							return {
								id: s.referenceDataId,
								value: s.referenceDataValue
							};
						}
						return {
							id: 0,
							value: 'All'
						};
					})
					.filter((f) => f != null)
					.sort((a, b) => a.id - b.id);

				setStatuses(tempStatuses);
			})
			.catch((err) => {
				console.error(err);
				if (err.response && err.response.data.error) {
					defaultToastError(err.response.data.error);
				}
			});

		fetchProducts();
	}, []);

	useEffect(() => {
		setAreProductsValidForActiveUpdate(true);

		if (!selectedProducts.length > 0) return;
		const invalid = selectedProducts.filter((f) => !f.hasImage).length === 0;

		setAreProductsValidForActiveUpdate(invalid);
	}, [selectedProducts]);

	const updateSelectedProducts = (selectedStatus) => {
		const productIds = [...selectedProducts].map((p) => p.productId);

		updateProductStatus(productIds, selectedStatus)
			.then(() => {
				setClearSelectedProducts(!clearSelectedProducts);
				fetchProducts();
			})
			.catch((err) => {
				console.error(err);
				if (err.response && err.response.data.error) {
					defaultToastError(err.response.data.error);
				}
			});
	};

	useEffect(() => {
		if (!activeClicked && !loading) return;

		if (areProductsValidForActiveUpdate) {
			updateSelectedProducts('active');
		} else {
			showError(
				"Products cannot be activated as some products don't have image"
			);
		}
		setActiveClicked(false);
	}, [activeClicked]);

	const handleSearch = (e, text) => {
		let value = '';
		if (e && e.target) {
			value = e.target.value;
			setSearchValue(value);
		} else {
			value = text;
		}
		const tempProducts = [...products].filter(
			(product) => (product.productName.toLowerCase().includes(value.toLowerCase())
					|| product.inventory.toString().includes(value)
					|| (product.tags.length
						&& product.tags.some((p) => p.tags.toLowerCase().includes(value.toLowerCase()))))
				&& product.productStatusId !== 119
				&& (product.productStatusId === status || status === 0)
		);
		setTableProducts(tempProducts);
	};

	useEffect(() => {
		if (status == null || !products.length) return;

		const tempProducts = [...products].filter((product) => {
			if (status !== 0) {
				return product.productStatusId === status;
			}
			return product.productStatusId !== 119;
		});
		setTableProducts(tempProducts);

		if (searchValue) {
			handleSearch(null, searchValue);
		}
	}, [status, products]);

	const onStatusChange = (value) => {
		setStatus(Number(value));
	};

	const handleSelectedRows = (state) => {
		setSelectedProducts(state.selectedRows);
	};

	if (loading) {
		return (
			<div className="w-screen h-screen bg-white absolute left-0 top-0">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}
	return (
		<Layout auth>
			<div className="flex flex-col flex-1">
				<HeaderHome
					siteTitle="Products"
					hideSearch
					setLoading={setLoading}
					redirectToHomeIfNotSeller
				/>
				<div className="flex flex-col flex-grow w-full h-full mt-4 items-center">
					<ToastContainer />
					<ProductCsvImportModal
						showModal={showCsvImportModal}
						setShowModal={setShowCsvImportModal}
						onError={(errorMessage) => {
							defaultToastError(errorMessage);
						}}
						setLoading={setLoading}
						onImport={() => {
							fetchProducts();
						}}
					/>
					<div className="flex flex-col bg-white w-5/6 rounded-lg content-center justify-center">
						<ProductStatusConfirmation
							showModal={showConfirmation}
							setShowModal={setShowConfirmation}
							onConfirm={() => updateSelectedProducts('deleted')}
						/>
						{products && products.length === 0 ? (
							<>
								<div className="flex w-full justify-center pb-8">
									<p className="text-2xl text-gray-700 text-center mx-auto mt-5">
										You don't have any products yet
									</p>
								</div>
								<div className="flex flex-col w-full mb-4 md:mb-0 lg:mb-0">
									<div>
										<Link to="/product">
											<button
												type="button"
												className="h-10 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white bg-red-700 hover:bg-red-900 md:py-4 md:text-lg md:px-10 mb-5"
											>
												Add Product
											</button>
										</Link>
									</div>
								</div>
							</>
						) : (
							<>
								<div className="w-full justify-center pb-8 shadow-lg">
									<div className="flex flex-col lg:flex-row p-4 lg:p-8 justify-between items-start lg:items-stretch w-full">
										<div className="w-full flex flex-col md:flex-row  justify-start">
											{!selectedProducts || selectedProducts.length === 0 ? (
												<>
													<div className="relative text-gray-600 mb-2 md:mb-0 w-full md:w-auto">
														<input
															type="search"
															name="serch"
															placeholder="Search"
															className="h-10 px-5 pl-10 text-sm focus:outline-none border rounded-md w-full md:w-auto"
															onChange={handleSearch}
														/>
														<button
															type="submit"
															className="absolute left-0 top-0 mt-3 ml-4"
														>
															<svg
																className="h-4 w-4 fill-current"
																xmlns="http://www.w3.org/2000/svg"
																// xmlns:xlink="http://www.w3.org/1999/xlink"
																version="1.1"
																id="Capa_1"
																x="0px"
																y="0px"
																viewBox="0 0 56.966 56.966"
																// style="enable-background:new 0 0 56.966 56.966;"
																// xml:space="preserve"
																width="512px"
																height="512px"
															>
																<path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
															</svg>
														</button>
													</div>
													<div className="">
														<div className="relative rounded-md shadow-sm mb-2 md:mb-0  w-full md:w-auto">
															<select
																id="event_type"
																name="event_type"
																autoComplete="event_type"
																className="md:ml-3 ml-0 h-10 px-5 pr-10 text-sm focus:outline-none border rounded-md  w-full md:w-auto"
																onBlur={(e) => onStatusChange(e.target.value)}
																onChange={(e) => onStatusChange(e.target.value)}
																value={status}
															>
																{statuses.map((object) => (
																	<option key={object.id} value={object.id}>
																		{object.value}
																	</option>
																))}
															</select>
														</div>
													</div>
													<div className="relative text-gray-600 md:ml-3 ml-0 mb-2 md:mb-0   w-full md:w-auto">
														<input
															type="text"
															name="serch"
															disabled
															value={storeName}
															className="h-10 px-5 pl-10 text-sm focus:outline-none border rounded-md   w-full md:w-auto"
														/>
													</div>
												</>
											) : (
												<>
													<div className="lg:ml-2 flex items-center justify-center w-40">
														<button
															type="button"
															className="border-2  border-red-600 justify-center w-full rounded-lg px-3 py-2 text-red-600 cursor-pointer hover:bg-red-600 hover:text-gray-100 text-center"
															onClick={() => {
																updateSelectedProducts('draft');
															}}
														>
															Set as draft
														</button>
													</div>
													{' '}
													<div className="lg:ml-6 flex items-center justify-center w-40">
														<button
															type="button"
															className="border-2  border-red-600 justify-center w-full rounded-lg px-3 py-2 text-red-600 cursor-pointer hover:bg-red-600 hover:text-gray-100 text-center
																disabled:opacity-50"
															// disabled={!areProductsValidForActiveUpdate}
															onClick={() => {
																setActiveClicked(true);
																// setShowModal(true);
																// updateSelectedProducts(
																//     'active'
																// );
															}}
														>
															Set as active
														</button>
													</div>
													{' '}
													<div className="lg:ml-6 flex items-center justify-center w-40">
														<button
															type="button"
															className="border-2  border-red-600 justify-center w-full rounded-lg px-3 py-2 text-red-600 cursor-pointer hover:bg-red-600 hover:text-gray-100 text-center"
															onClick={() => {
																// setShowModal(true);
																updateSelectedProducts('archived');
															}}
														>
															Set as archived
														</button>
													</div>
													{' '}
													<div className="lg:ml-6 flex items-center justify-center w-40">
														<button
															type="button"
															className="border-2  border-red-600 justify-center w-full rounded-lg px-3 py-2 text-red-600 cursor-pointer hover:bg-red-600 hover:text-gray-100 text-center"
															onClick={() => {
																setShowConfirmation(true);
															}}
														>
															Delete products
														</button>
													</div>
												</>
											)}
										</div>
										{selectedProducts.length === 0 && (
											<div className="w-full flex flex-row items-start lg:items-center md:justify-end justify-center">
												<div className="lg:ml-6 flex items-center justify-center w-24 md:mr-0 mr-4">
													<div
														className="border-2 border-red-600 justify-center w-full rounded-lg px-3 py-2 text-red-600 cursor-pointer hover:bg-red-600 hover:text-gray-100 text-center md:h-auto h-10"
														onClick={() => {
															setShowCsvImportModal(true);
														}}
														onKeyDown={() => {
															setShowCsvImportModal(true);
														}}
														aria-hidden="true"
													>
														Import
													</div>
												</div>
												<div className="lg:ml-6 flex items-center">
													<Link to="/product">
														<div className="border-2 border-red-600 rounded-lg px-3 py-2 bg-white text-red-600 cursor-pointer hover:bg-red-600 hover:text-gray-100 text-center md:h-auto h-10">
															Add Products
														</div>
													</Link>
												</div>
											</div>
										)}
									</div>
									<div className="grid w-full overflow-x-scroll xl:overflow-x-hidden px-5 rounded-md border">
										<DataTable
											columns={columns}
											selectableRows
											selectableRowsHighlight
											onSelectedRowsChange={handleSelectedRows}
											data={tableProducts}
											pagination
											noContextMenu
											className=""
											contextActions={null}
											striped
											paginationComponentOptions={{
												rowsPerPageText: 'Rows per page:',
												rangeSeparatorText: 'of',
												noRowsPerPage: false,
												selectAllRowsItem: true,
												selectAllRowsItemText: 'All'
											}}
											paginationRowsPerPageOptions={[10, 25, 100]}
											customStyles={{
												rows: {
													style: {}
												},
												headCells: {
													style: {
														backgroundColor: '#e82e2c',
														color: 'white',
														fontSize: '1rem',
														fontWeight: 'bold'
													}
												}
											}}
											clearSelectedRows={clearSelectedProducts}
										/>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ProductPage;
