import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { inputTypes } from './types';
import { isTruthy } from '../../utils/commonFunction';
import 'react-datepicker/dist/react-datepicker.css';

const DateInput = ({
	type = 'primary',
	width = 'full',
	mobileWidth = 'full',
	height = '12',
	mobileHeight = 'auto',
	textSize = 'sm',
	additionalClassName = '',
	rounded = true,
	roundedSize = 'md',
	...restProps
}) => {
	const inputType = inputTypes[type];

	return (
		<DatePicker
			className={`focus:ring-${
				inputType.focusColor
			} pl-2 pr-2 block md:w-${width} w-${mobileWidth} h-${mobileHeight} md:h-${height} text-${textSize} border border-${
				inputType.borderColor
			} ${
				isTruthy(rounded) && `rounded-${roundedSize}`
			} ${additionalClassName}`}
			{...restProps}
		/>
	);
};

DateInput.propTypes = {
	type: PropTypes.string,
	width: PropTypes.string,
	mobileWidth: PropTypes.string,
	height: PropTypes.string,
	mobileHeight: PropTypes.string,
	textSize: PropTypes.string,
	additionalClassName: PropTypes.string,
	rounded: PropTypes.bool,
	roundedSize: PropTypes.string
};

export default DateInput;
