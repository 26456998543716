import React from 'react';

const StepContext = React.createContext({
	completed: false,
	active: false,
	disabled: false,
	index: 0
});

export default StepContext;
