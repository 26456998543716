/* eslint-disable react/self-closing-comp */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
// import { useStaticQuery, graphql } from "gatsby"

// import Header from "./header"

const Layout = ({ children }) => (
	<div className="h-screen">
		<div className="h-full">
			<main className="bg-gray-100 h-full">{children}</main>
			<footer />
		</div>
	</div>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
