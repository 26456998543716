/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useState, useRef, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { UsersIcon } from '@heroicons/react/outline';
import { navigate } from 'gatsby-link';
import { isTextTruncated } from '../../utils/element';
import { isTruthy } from '../../utils/commonFunction';

const ConversationHeader = ({
	title,
	description,
	mobileSize,
	setShowSidebar,
	setShowParticipants,
	showParticipantsButton,
	setShowConversations,
	conversation,
	isConnectedToInternet
}) => {
	const [isTitleTruncated, setIsTitleTruncated] = useState(false);
	const titleRef = useRef();

	useEffect(() => {
		if (!titleRef || !titleRef.current) return;
		setIsTitleTruncated(isTextTruncated(titleRef.current));
	}, [title]);

	const onHeaderClick = () => {
		if (conversation && conversation.refund && conversation.refund_id) {
			setShowConversations(false);
			navigate(`/sales/refund/details/${conversation.refund_id}`);
		}
	};

	if (!isConnectedToInternet) {
		return (
			<div className="border-b flex px-6 py-2 items-center">
				<div className="flex flex-row justify-start w-full">
					<div className="mb-1">
						<svg
							className="w-5 h-6"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M17.778 8.222c-4.296-4.296-11.26-4.296-15.556 0A1 1 0 01.808 6.808c5.076-5.077 13.308-5.077 18.384 0a1 1 0 01-1.414 1.414zM14.95 11.05a7 7 0 00-9.9 0 1 1 0 01-1.414-1.414 9 9 0 0112.728 0 1 1 0 01-1.414 1.414zM12.12 13.88a3 3 0 00-4.242 0 1 1 0 01-1.415-1.415 5 5 0 017.072 0 1 1 0 01-1.415 1.415zM9 16a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
								clipRule="evenodd"
							/>
						</svg>
					</div>
					<h3
						className="text-grey-darkest text-md font-extrabold ml-3 truncate"
						style={{ color: 'red' }}
					>
						No intenet connection
					</h3>
				</div>
			</div>
		);
	}

	return (
		<div className="border-b flex px-6 py-2 items-center">
			<ReactTooltip place="bottom" />
			{isTruthy(mobileSize) && (
				<div
					className="mb-1"
					onClick={() => {
						setShowSidebar(true);
					}}
					onKeyDown={() => {
						setShowSidebar(true);
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="mr-3"
						width="25"
						height="25"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M15 19l-7-7 7-7"
						/>
					</svg>
				</div>
			)}
			<div className="flex flex-row justify-between w-full">
				<div
					className={`flex flex-col mt-1 truncate ${
						conversation && conversation.refund == true && 'cursor-pointer'
					}`}
					data-tip={isTitleTruncated ? title : null}
					onClick={onHeaderClick}
					onKeyDown={onHeaderClick}
				>
					{isTruthy(isTitleTruncated) && (
						<marquee
							className={`${
								conversation && conversation.refund == true && 'underline'
							} text-grey-darkest text-md font-extrabold mt-1`}
						>
							{title}
						</marquee>
					)}
					<h3
						className={`${
							conversation && conversation.refund == true && 'underline'
						} text-grey-darkest text-md font-extrabold mt-1 truncate`}
						ref={titleRef}
						hidden={isTitleTruncated}
					>
						{title}
					</h3>
					<div className="text-grey font-thin text-sm truncate">
						{description}
					</div>
				</div>
				{isTruthy(showParticipantsButton) && (
					<div className="flex flex-row justify-end mt-1">
						<div className="flex">
							{mobileSize ? (
								<div
									className="flex items-center bg-white ml-3 mt-1 mb-1 cursor-pointer"
									onClick={setShowParticipants}
									onKeyDown={setShowParticipants}
								>
									<UsersIcon className="w-6 h-6" />
								</div>
							) : (
								<button
									type="button"
									className="h-10 mx-auto flex items-center justify-center px-3 border rounded-2xl text-sm font-sm text-white bg-djawn-light hover:bg-red-900 md:text-md md:px-5"
									onClick={setShowParticipants}
								>
									Participants
								</button>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ConversationHeader;
