import React, { createContext } from 'react';
import useCreateEvent from '../hooks/useCreateEvent';

const CreateEventContext = createContext();

const CreateEventProvider = ({ children }) => {
	const {
		eventData,
		eventTypes,
		sports,
		tierTypes,
		loading,
		setLoading,
		activeStep,
		setActiveStep,
		eventName,
		setEventName,
		eventTypeId,
		setEventTypeId,
		eventDate,
		setEventDate,
		loadTemplates,
		loadUserTemplates,
		breakTemplate,
		setBreakTemplate,
		templates,
		userTemplates,
		sellerImage,
		templateActionType,
		setTemplateActionType,
		eventDescription,
		setEventDescription,
		files,
		setFiles,
		selectedProducts,
		setSelectedProducts,
		shippingTypes,
		shippingCards,
		selectedShippingType,
		setSelectedShippingType,
		selectedShippingCard,
		setSelectedShippingCard,
		fixedShipRate,
		setFixedShipRate,
		shippingFrequency,
		setShippingFrequency,
		createEvent,
		editedTemplate,
		setEditedTemplate,
		costPerSlot,
		setCostPerSlot,
		selectedProductSelectionId,
		setSelectedProductSelectionId,
		maximumNumberOfSlotsAllowed,
		maximumNumberOfTiersAllowed,
		maximumNumberOfSlotsAndTiersAllowed
	} = useCreateEvent();

	return (
		<CreateEventContext.Provider
			value={{
				eventData,
				eventTypes,
				sports,
				tierTypes,
				loading,
				setLoading,
				activeStep,
				setActiveStep,
				eventName,
				setEventName,
				eventTypeId,
				setEventTypeId,
				eventDate,
				setEventDate,
				loadTemplates,
				loadUserTemplates,
				breakTemplate,
				setBreakTemplate,
				templates,
				userTemplates,
				sellerImage,
				templateActionType,
				setTemplateActionType,
				eventDescription,
				setEventDescription,
				files,
				setFiles,
				selectedProducts,
				setSelectedProducts,
				shippingTypes,
				shippingCards,
				selectedShippingType,
				setSelectedShippingType,
				selectedShippingCard,
				setSelectedShippingCard,
				fixedShipRate,
				setFixedShipRate,
				shippingFrequency,
				setShippingFrequency,
				createEvent,
				editedTemplate,
				setEditedTemplate,
				costPerSlot,
				setCostPerSlot,
				selectedProductSelectionId,
				setSelectedProductSelectionId,
				maximumNumberOfSlotsAllowed,
				maximumNumberOfTiersAllowed,
				maximumNumberOfSlotsAndTiersAllowed
			}}
		>
			{children}
		</CreateEventContext.Provider>
	);
};

export { CreateEventContext, CreateEventProvider };
