import axios from 'axios';
import env from '../../environment.config';
import { getAccessToken } from '../utils/auth';

const axiosConfig = axios.create({
	baseURL: `${env.api_url}api/`
});

axiosConfig.interceptors.request.use((config) => {
	config.headers = { Authorization: `Bearer ${getAccessToken()}` };
	return config;
});

export default axiosConfig;
