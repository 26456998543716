import React from 'react';
import PropTypes from 'prop-types';
import { isMultilineTextTruncated } from '../../utils/element';

const ellipsisDefaultStyle = {
	overflow: 'hidden',
	overflowWrap: 'break-word',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	wordBreak: 'break-all'
};

class EllipisWithTooltip extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasOverflowingChildren: false,
			text: undefined,
			prevPropsChildren: props.children
		};
		this.updateOverflow = this.updateOverflow.bind(this);
	}

	// eslint-disable-next-line react/sort-comp
	updateOverflow(e) {
		const el = e.target;
		const { hasOverflowingChildren, text } = this.state;
		const { isMultiline } = this.props;
		const isTextTruncated = isMultiline
			? isMultilineTextTruncated(el)
			: el.scrollWidth > el.clientWidth;
		if ((!hasOverflowingChildren || isMultiline) && isTextTruncated) {
			this.setState({ hasOverflowingChildren: true });
			if (el.textContent !== text) {
				this.setState({ text: el.textContent });
			}
		} else {
			this.setState({ hasOverflowingChildren: false });
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.children === state.prevPropsChildren) return null;
		return {
			hasOverflowingChildren: false,
			prevPropsChildren: props.children
		};
	}

	render() {
		const { hasOverflowingChildren, text } = this.state;
		const {
			style = {},
			children,
			className,
			isParagraph,
			overridenStyle
		} = this.props;
		const ellipsisStyle = { ...ellipsisDefaultStyle, ...style };

		if (isParagraph) {
			return hasOverflowingChildren ? (
				<p
					data-tip={text}
					className={className}
					onMouseEnter={this.updateOverflow}
					style={overridenStyle}
				>
					{children}
				</p>
			) : (
				<p
					data-tip=""
					className={className}
					onMouseEnter={this.updateOverflow}
					style={overridenStyle}
				>
					{children}
				</p>
			);
		}
		return hasOverflowingChildren ? (
			<div
				style={overridenStyle || ellipsisStyle}
				data-tip={text}
				className={className}
			>
				{children}
			</div>
		) : (
			<div
				style={overridenStyle || ellipsisStyle}
				data-tip=""
				className={className}
				onMouseEnter={this.updateOverflow}
			>
				{children}
			</div>
		);
	}
}

EllipisWithTooltip.propTypes = {
	children: PropTypes.node.isRequired,
	style: PropTypes.object,
	className: PropTypes.string,
	isParagraph: PropTypes.bool,
	isMultiline: PropTypes.bool,
	overridenStyle: PropTypes.object
};

EllipisWithTooltip.defaultProps = {
	className: undefined,
	style: undefined,
	isParagraph: false,
	isMultiline: false,
	overridenStyle: undefined
};

export default EllipisWithTooltip;
