import React, { createContext } from 'react';
import useSoundNotification from '../hooks/use-sound-notification';

const NotificationSoundContext = createContext();

const NotificationSoundProvider = ({ isSeller, children }) => {
	const {
		playWebSocketNotificationAudio,
		playDefaultAudio
	} = useSoundNotification(isSeller);

	return (
		<NotificationSoundContext.Provider
			value={{
				playWebSocketNotificationAudio,
				playDefaultAudio
			}}
		>
			{children}
		</NotificationSoundContext.Provider>
	);
};

export { NotificationSoundContext, NotificationSoundProvider };
