export const referenceDataToSelect = (data, includeDescription = false) => {
	const referenceDatas = data.map((referenceData) => {
		const refData = {
			id: referenceData.referenceDataId,
			value: referenceData.referenceDataValue
		};

		if (includeDescription) {
			refData.description = referenceData.referenceDataDescription;
		}
		return refData;
	});
	return referenceDatas;
};
