/* eslint-disable quote-props */

const lineClamp = require('@tailwindcss/line-clamp');

module.exports = {
	purge: ['./public/**/*.html', './src/**/*.{js,jsx,ts,tsx,css}'],
	darkMode: 'class', // or 'media' or 'class'
	theme: {
		fontFamily: {
			sans: ['Inter', 'sans-serif'],
			serif: ['Inter', 'serif'],
		},
		fontSize: {
			'xs': '.75rem',
			'xsm': '.82rem',
			'sm': '.875rem',
			'tiny': '.875rem',
			'base': '1rem',
			'lg': '1.125rem',
			'xl': '1.25rem',
			'2xl': '1.5rem',
			'3xl': '1.875rem',
			'4xl': '2.25rem',
			'5xl': '3rem',
			'6xl': '4rem',
			'7xl': '5rem',
		},
		screens: {
			xxs: '375px',
			xs: '480px',
			sm: '640px',
			md: '768px',
			lg: '1024px',
			xl: '1280px',
			'2xl': '1536px',
		},
		extend: {
			height: {
				'screen/2': '50vh',
				'screen/3': 'calc(100vh / 3)',
				'screen/4': 'calc(100vh / 4)',
				'screen/5': 'calc(100vh / 5)',
				85: '85vh',
				90: '90vh',
				102: '27rem',
				103: '30rem',
				105: '33rem',
				112: '39rem',
			},
			padding: {
				'per-16': '16%',
				'per-8': '8%',
				13: '3.25rem',
				68: '17rem'
			},
			margin: {
				15: '3.5rem'
			},
			width: {
				18: '4.5rem',
				29: '7.5rem',
				40: '10rem',
				112: '39rem',
				'96%': '96%'
			},
			spacing: { '2/3': '66.666667%' },
			colors: {
				coolDark: {
					400: '#40444B',
					500: '#36393f',
					600: '#32353b',
				},
				primary: {
					50: '#fbeff3',
					100: '#d96d91',
					200: '#d55d85',
					300: '#d14d79',
					400: '#cd3c6d',
					500: '#c33364',
					600: '#b22e5a',
					700: '#a22a52',
					800: '#92264a',
					900: '#822142',
				},
				pressedIconsRedColor: {
					DEFAULT: '#EA2B21'
				},
				greenModified: {
					500: '#11A632',
					700: '#199F36',
				},
				darkGray: {
					50: '#201F1F',
					100: '#323131',
					200: '#747272'
				},
				djawn: {
					DEFAULT: '#e82e2c',
					light: '#e82e2c',
					dark: '#e82e2c',
					300: '#f04a48'
				}
			},
			boxShadow: {
				button: 'var(--shadow-button)',
				card: 'var(--shadow-card)',
			},
		},
		tableLayout: ['hover', 'focus'],
	},
	variants: {
		extend: {
			opacity: ['disabled'],
			cursor: ['disabled'],
			padding: ['hover']
		},
	},
	plugins: [
		lineClamp,
		({ addUtilities }) => {
			const newUtilities = {
				'.product-input-width': {
					width: '40.3125vw',
				},
				'.w-81': {
					width: '22rem'
				},
				'.h-80vh': {
					height: '80vh'
				},
				'.border-radius-25px': {
					'border-radius': '25px'
				}
			};

			addUtilities(newUtilities, ['responsive']);
		},
	]
};
