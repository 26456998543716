import React from 'react';

const HitOnly = () => (
	<p className="text-sm font-semibold text-white border rounded-md px-1 bg-blue-700 border-blue-700 absolute hits-only w-29 flex justify-center items-center feed-button">
		<svg
			className="w-4 h-4"
			fill="red"
			stroke="currentColor"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
			/>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
			/>
		</svg>
        Hits only
	</p>
);

export default HitOnly;
