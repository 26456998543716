import { navigate } from 'gatsby';
import React, { useContext, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import BackButton from '../../components/button/back-button';
import NextButton from '../../components/button/next-button';
import { InfoIcon } from '../../components/Event/event-icons';
import { CreateEventContext } from '../../contexts/create-event';
import useErrorNotifier from '../../hooks/use-error-notifier';
import { FREE_SHIPPING_REFERENCE_DATA } from '../../utils/constants';

const RipNShipDetails = () => {
	const { showToastError } = useErrorNotifier();
	const {
		shippingTypes,
		shippingCards,
		selectedShippingType,
		setSelectedShippingType,
		selectedShippingCard,
		setSelectedShippingCard,
		fixedShipRate,
		setFixedShipRate,
		shippingFrequency,
		setShippingFrequency,
		setActiveStep,
		createEvent
	} = useContext(CreateEventContext);
	const [submitDisabled, setSubmitDisabled] = useState(false);

	const onEventCreate = async () => {
		setSubmitDisabled(true);
		try {
			const eventId = await createEvent();
			navigate(`/event/${eventId}`);
		} catch (error) {
			showToastError(error);
		}
		setSubmitDisabled(false);
	};

	return (
		<div className="flex-grow p-4">
			<ReactTooltip />
			<div className="mb-6">
				<label
					htmlFor="shipping_type"
					className="block text-sm font-medium text-gray-700 pl-2"
				>
					<div className="flex text-center justify-start space-x-2">
						<div>
							<div className="w-full text-center">Shipping Type</div>
						</div>
						<div data-tip="Decide what kind of shipping you're offering for this event.">
							<InfoIcon />
						</div>
					</div>
				</label>
				<div className="mt-1 relative rounded-md shadow-sm">
					<select
						id="shipping_type"
						name="shipping_type"
						autoComplete="shipping_type"
						className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						onBlur={(e) => setSelectedShippingType(e.target.value)}
						value={selectedShippingType}
						onChange={(e) => setSelectedShippingType(e.target.value)}
					>
						<option key="0" value="0">
							Select Shipping Type
						</option>
						{shippingTypes.map((object, i) => (
							<option key={i} value={object.id}>
								{object.value}
							</option>
						))}
					</select>
				</div>
			</div>

			{selectedShippingType
				&& selectedShippingType != FREE_SHIPPING_REFERENCE_DATA && (
				<div className="mb-6">
					<label
						htmlFor="cost_per_slot"
						className="block text-sm font-medium text-gray-700 pl-2"
					>
							Fixed ship rate
					</label>
					<div className="mt-1 flex rounded-md shadow-sm">
						<span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
								$
						</span>
						<input
							type="number"
							min="0"
							name="cost_per_slot"
							id="cost_per_slot"
							className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-none rounded-r-md"
							placeholder="Cost per slot"
							value={fixedShipRate}
							onChange={(e) => {
								if (e.target.value < 0) {
									setFixedShipRate(e.target.value * -1);
								} else {
									setFixedShipRate(e.target.value);
								}
							}}
							onBlur={(e) => {
								if (e.target.value.trim().length == 0) {
									setFixedShipRate(0);
								}
							}}
						/>
					</div>
				</div>
			)}

			<div className="mb-6">
				<label
					htmlFor="shipping_card"
					className="block text-sm font-medium text-gray-700 pl-2"
				>
					<div className="flex text-center justify-start space-x-2">
						<div>
							<div className="w-full text-center">Shipping Cards</div>
						</div>
						<div data-tip="Then decide if you'll be shipping all cards or only the 'hits'">
							<InfoIcon />
						</div>
					</div>
				</label>
				<div className="mt-1 relative rounded-md shadow-sm">
					<select
						id="shipping_card"
						name="shipping_card"
						autoComplete="shipping_card"
						className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						onBlur={(e) => setSelectedShippingCard(e.target.value)}
						onChange={(e) => setSelectedShippingCard(e.target.value)}
						value={selectedShippingCard}
					>
						<option key="0" value="0">
							Select Shipping Card
						</option>
						{shippingCards.map((object, i) => (
							<option key={i} value={object.id}>
								{object.value}
							</option>
						))}
					</select>
				</div>
			</div>

			<div className="mb-6">
				<label
					htmlFor="frequency"
					className="block text-sm font-medium text-gray-700 pl-2"
				>
					Frequency
				</label>
				<div className="mt-1 relative rounded-md shadow-sm">
					<input
						type="text"
						name="event_name"
						id="event_name"
						className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
						placeholder="Frequency"
						value={shippingFrequency}
						onBlur={(e) => setShippingFrequency(e.target.value)}
						onChange={(e) => setShippingFrequency(e.target.value)}
					/>
				</div>
			</div>

			<div className="flex justify-center mx-auto">
				<div className="mr-2">
					<BackButton onClick={() => setActiveStep(1)} />
				</div>
				<div className="ml-2">
					<NextButton
						overridenText="CREATE"
						onClick={onEventCreate}
						disabled={submitDisabled}
					/>
				</div>
			</div>
		</div>
	);
};

export default RipNShipDetails;
