import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { searchUsers } from '../../api/auth.request';

const Autocomplete = ({
	setItemSelected,
	queryMinimumLengthRequest = 3,
	authenticatedUserId,
	selectedUsers
}) => {
	const [inputValue, setInputValue] = useState('');
	const loadData = async (query) => {
		if (query && query.length >= queryMinimumLengthRequest) {
			const res = await searchUsers(query);
			if (res && res.data && res.data.length > 0) {
				res.data.forEach((user) => {
					if (selectedUsers.some((e) => e.id === user.id)) {
						user.is_selected = true;
					}
				});
				return res.data.filter((item) => item.id !== authenticatedUserId);
			}
		}

		return [];
	};

	return (
		<>
			<AsyncSelect
				isClearable
				isOptionDisabled={(option) => option.is_selected}
				getOptionLabel={(option) => (
					<div className="flex flex-row items-center justify-between disabled">
						<div className="flex">
							<img
								// src="https://i.imgur.com/8Km9tLL.jpg"
								src={option.image}
								alt="foto"
								className="w-10 h-10 mr-3 border rounded-2xl"
							/>
							<div className="flex flex-col break-all">
								<div className="flex items-center text-center">
									<span className="font-bold text-md mr-2 font-sans">
										{option.name}
									</span>
								</div>
								<div>{option.nickname}</div>
							</div>
						</div>
						{option.is_selected && (
							<div className="flex">
								<div className="flex items-center text-center">
									<span className="font-bold text-green-900 text-sm mr-2 font-sans">
										Added
									</span>
								</div>
							</div>
						)}
					</div>
				)}
				getOptionValue={(option) => option.id}
				loadOptions={loadData}
				placeholder="Search by the name of user..."
				value={inputValue}
				className="w-full"
				onChange={(selectedItem) => {
					if (selectedItem) {
						setItemSelected(selectedItem);
						setInputValue('');
					}
				}}
				// styles={colourStyles}
			/>
		</>
	);
};

Autocomplete.propTypes = {
	setItemSelected: PropTypes.func.isRequired,
	queryMinimumLengthRequest: PropTypes.number
};

export default Autocomplete;
