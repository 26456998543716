import React, { useContext, useEffect } from 'react';
import Modal from '../../../components/modals';
import Button from '../../../components/button';
import { SellerEventAttendeeActionsContext } from '../../../contexts/seller-event-attendee-actions';
import { TwilioTrackContext } from '../../../contexts/twilio-track';

const EventActions = ({ user, closeModal }) => {
	const { setUserNicknameToBan } = useContext(
		SellerEventAttendeeActionsContext
	);

	return (
		<div className="w-full flex flex-col items-center space-y-2">
			<h5>Event Actions</h5>
			<Button
				width="full"
				height="10"
				mobileHeight="10"
				onClick={() => {
					closeModal();
					setUserNicknameToBan(user.nickname);
				}}
			>
				Remove user
			</Button>
		</div>
	);
};

const MicActions = ({ user, closeModal }) => {
	const {
		approveOrDeclineMicRequest,
		audioParticipants,
		muteOrUnmuteMicParticipants,
		closeMic
	} = useContext(TwilioTrackContext);

	return (
		<div className="w-full flex flex-col items-center space-y-2">
			<h5>Mic Actions</h5>
			<Button
				width="full"
				height="10"
				mobileHeight="10"
				disabled={
					!user.micRequest
					|| (audioParticipants
						&& audioParticipants.length
						&& audioParticipants.find(
							(p) => p.twilioParticipantId == user.twilioParticipantId
						) != null)
				}
				onClick={() => {
					approveOrDeclineMicRequest(
						[
							{
								userId: user.userId,
								twilioParticipantId: user.twilioParticipantId
							}
						],
						true,
						false
					).finally(() => {
						closeModal();
					});
				}}
			>
				Approve mic request
			</Button>
			<Button
				width="full"
				height="10"
				mobileHeight="10"
				disabled={
					!user.micRequest
					|| (audioParticipants
						&& audioParticipants.length
						&& audioParticipants.find(
							(p) => p.twilioParticipantId == user.twilioParticipantId
						) != null)
				}
				onClick={() => {
					approveOrDeclineMicRequest(
						[
							{
								userId: user.userId,
								twilioParticipantId: user.twilioParticipantId
							}
						],
						false,
						false
					).finally(() => {
						closeModal();
					});
				}}
			>
				Decline mic request
			</Button>
			<Button
				width="full"
				height="10"
				mobileHeight="10"
				disabled={
					!audioParticipants
					|| !audioParticipants.length
					|| audioParticipants.find(
						(participant) => participant.twilioParticipantId == user.twilioParticipantId
							&& participant.muted == false
					) == null
				}
				onClick={() => {
					closeModal();
					muteOrUnmuteMicParticipants(
						[
							{
								twilioParticipantId: user.twilioParticipantId,
								userId: user.userId
							}
						],
						true,
						true,
						false
					);
				}}
			>
				Mute user
			</Button>
			<Button
				width="full"
				height="10"
				mobileHeight="10"
				disabled={
					!audioParticipants
					|| !audioParticipants.length
					|| audioParticipants.find(
						(participant) => participant.twilioParticipantId == user.twilioParticipantId
					) == null
				}
				onClick={() => {
					closeModal();
					closeMic([user.twilioParticipantId], true, false);
				}}
			>
				Close mic
			</Button>
		</div>
	);
};

const SellerAttendeeActionsModal = ({
	fetchAttendeesAndUpdateAudioParticipants
}) => {
	const { selectedUserForActions, setSelectedUserForActions } = useContext(
		SellerEventAttendeeActionsContext
	);

	useEffect(() => {
		if (selectedUserForActions != null) return;
		fetchAttendeesAndUpdateAudioParticipants();
	}, [selectedUserForActions]);

	if (!selectedUserForActions) return null;

	return (
		<Modal
			showModal={selectedUserForActions != null}
			onClose={() => setSelectedUserForActions(null)}
			hideCloseButton
			header={`Seller Actions - ${selectedUserForActions.nickname}`}
		>
			<div className="w-full py-2">
				<div className="border-b-2 border-t-2 py-4">
					<EventActions
						user={selectedUserForActions}
						closeModal={() => setSelectedUserForActions(null)}
					/>
				</div>
				<div className="py-4">
					<MicActions
						user={selectedUserForActions}
						closeModal={() => setSelectedUserForActions(null)}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default SellerAttendeeActionsModal;
