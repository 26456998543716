/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React, { useState, useEffect, useRef } from 'react';
import { Link, navigate } from 'gatsby';
import UserAvatar from 'react-user-avatar';
import { useLocation } from '@reach/router';
import { connect } from 'react-redux';
import SEO from '../seo';
import useOutsideClick from './useOutsideClick';
import {
	getCurrentUser,
	isLoggedIn,
	logout,
	shouldRedirectToSignIn
} from '../../utils/auth';
import { isAdmin as isAdminReq } from '../../api/auth.request';
import { getSourcePageHelp } from '../../api/popup.request';
import { isUserASeller } from '../../api/seller.request';
import {
	planRipNShip,
	getAvailableRipNShip,
	sellerHasNoLiveEventInProgress
} from '../../api/event.request';
import DynamicPopups from '../dynamic-popup';
import logo from '../../images/djawn_logo.png';
import HeaderLoginButton from './header-login-button';
import { updateShowMobileSearch } from '../../state/action';
import env from '../../../environment.config';
import { defaultToastError } from '../../utils/toast-error';
import Conversation from '../conversation';
import usePopups from '../../hooks/usePopups';
import useErrorNotifier from '../../hooks/use-error-notifier';

const HeaderHome = ({
	onChange,
	cartOnClick,
	showCart = false,
	setLoading = false,
	unreadMessages,
	cartItemCount,
	onSearch,
	setShowSellerOnBoardModal,
	showMobileSearch,
	dispatch,
	redirectLocation,
	hideSearch = false,
	leavePage = false,
	onSellerConfirmed,
	onStripeLoginLinkGenerated,
	redirectToHomeIfNotSeller = false,
	selectedConversation,
	noMargins = true,
	showHelpIconForEvent = false,
	isRipNShip
}) => {
	const location = useLocation();
	const [loggedIn, setLoggedIn] = useState(false);
	const [isSeller, setIsSeller] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [ripNShipEventId, setRipNShipEventId] = useState(null);
	const [showHelpIcon, setShowHelpIcon] = useState(false);
	const [sourcePageHelp, setSourcePageHelp] = useState(null);
	const { popups, acknowledge: acknowledgePopup } = usePopups(
		location.pathname == '/'
	);
	const [isOn, setIsOn] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const user = getCurrentUser();
	const [showSellerMenuItems, setShowSellerMenuItems] = useState(false);
	const [showConversations, setShowConversations] = useState(false);
	const [startedConversation, setStartedConversation] = useState(null);
	const { showToastError } = useErrorNotifier();
	const [avatarName, setAvatarName] = useState('');

	const getFirstWord = (str) => {
		const spaceIndex = str.indexOf(' ');
		return spaceIndex === -1 ? str : str.substr(0, spaceIndex);
	};

	useEffect(() => {
		if (!user || !user.name) return;
		if (!user.firstName && !user.lastName) {
			setAvatarName(user.name);
			return;
		}

		const firstName = user.firstName ? getFirstWord(user.firstName) : '';
		const lastName = user.lastName ? getFirstWord(user.lastName) : '';

		setAvatarName(`${firstName} ${lastName}`);
	}, [user]);

	useEffect(() => {
		if (!selectedConversation) return;
		setShowConversations(true);
		setStartedConversation(selectedConversation);
	}, [selectedConversation]);

	useEffect(() => {
		if (showConversations) {
			document.body.style.overflow = 'hidden';
			// document.body.classList.add('no-scroll');
		} else {
			document.body.style.overflow = 'visible';
			// document.body.classList.add('scroll');
		}
	}, [showConversations]);

	useEffect(() => {
		if (setShowSellerOnBoardModal) {
			setShowSellerOnBoardModal(!popups.length);
		}
	}, [popups]);

	const onHelpClick = () => {
		if (showHelpIconForEvent) {
			window.open(
				isRipNShip
					? 'https://support.djawn.com/help-ras/'
					: 'https://support.djawn.com/help-breaks/',
				'_blank',
				'noopener,noreferrer'
			);
		} else {
			window.open(sourcePageHelp.url, '_blank', 'noopener,noreferrer');
		}
	};

	useEffect(() => {
		const locations = location.pathname.split('/');
		let hasMoreSubRoutes = false;
		// if there is a trailing slash("/") that has another route e.g. "/event/" after removing the first slash
		// we remove the second slash this way we know that we could have a subroute like "/event/eventId"
		if (locations.length > 2) {
			locations.splice(-1, 1);
			hasMoreSubRoutes = true;
		}

		let pathName = locations.join('/');
		if (hasMoreSubRoutes) {
			pathName += '/';
		}

		if (!location || !location.pathname) return;

		getSourcePageHelp(pathName)
			.then((res) => {
				if (res.data) {
					setShowHelpIcon(true);
					setSourcePageHelp(res.data);
				}
			})
			.catch((error) => {
				if (error.response) {
					console.error(error.message);
				}
			});
	}, []);

	useEffect(() => {
		if (!shouldRedirectToSignIn()) {
			setLoggedIn(true);
			isAdminReq()
				.then((res) => {
					setIsAdmin(res.data);
				})
				.catch((err) => {
					console.error(err);
				});
			isUserASeller()
				.then((res) => {
					if (res.data && res.data.url && onStripeLoginLinkGenerated) {
						onStripeLoginLinkGenerated(res.data.url);
						return;
					}
					setIsSeller(true);

					if (res.data && res.data.url && onStripeLoginLinkGenerated) {
						onStripeLoginLinkGenerated(res.data.url);
						return;
					}

					if (onSellerConfirmed) {
						onSellerConfirmed(true);
					}
					getAvailableRipNShip()
						.then((availableRipNShipRes) => {
							setRipNShipEventId(availableRipNShipRes.data);
						})
						.catch((err) => {
							const mute = err;
							console.log(mute);
						});
				})
				.catch((err) => {
					setIsSeller(false);
					if (onSellerConfirmed) {
						onSellerConfirmed(false);
					}
					if (
						err.response
						&& err.response.data
						&& err.response.data.error
						&& redirectToHomeIfNotSeller
					) {
						setTimeout(() => {
							defaultToastError(err.response.data.error);
						}, 4000);
						navigate('/');
					}
				});
		}
	}, []);

	const startDefaultRipping = () => {
		sellerHasNoLiveEventInProgress()
			.then(() => {
				setLoading(true);
				planRipNShip('Rip Event', null, null)
					.then((res) => {
						setLoading(false);
						navigate(`/event/room/${res.data}`);
					})
					.catch((err) => {
						if (err && err.response.data) {
							console.log(err.response.data.error);
							if (
								err.response.data.error.errorCode
								&& err.response.data.error.errorCode.trim()
									== 'PRODUCT_NO_AVAILABLE_STOCK'
							) {
								navigate('/no-available-products');
							} else {
								showToastError(err.response.data.error);
							}
						}
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((err) => {
				showToastError(err);
			});
	};

	const ref = useRef();

	useOutsideClick(ref, () => {
		if (isOn) {
			setIsOn(false);
		}
	});

	useEffect(() => {
		if (isOn) {
			isUserASeller()
				.then((res) => {
					if (res.data && res.data.url && onStripeLoginLinkGenerated) {
						onStripeLoginLinkGenerated(res.data.url);
						return;
					}

					setIsSeller(true);
					getAvailableRipNShip()
						.then((availableRipNShipRes) => {
							setRipNShipEventId(availableRipNShipRes.data);
						})
						.catch((err) => {
							const mute = err;
							console.log(mute);
						});
				})
				.catch(() => {
					setIsSeller(false);
				});
		}
	}, [isOn]);

	const signOutUser = () => {
		logout();
		setLoggedIn(false);
		if (window) {
			window.location.href = window.location.origin;
		}
	};

	const conversationIcon = () => (
		// <Link to="/conversation">
		<button
			type="button"
			className="focus:outline-none text-black hover:text-black text-lg rounded-md rounded-tr-none p-3 rounded-br-none mt-1"
			onClick={() => {
				setShowConversations(!showConversations);
			}}
		>
			<div className="relative">
				<svg
					className="w-8 h-8 md:w-9 md:h-9"
					fill="currentColor"
					stroke="white"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
					/>
				</svg>
			</div>
			{unreadMessages > 0 && (
				<div className="rounded-full h-4 w-4 flex items-center justify-center mb-1 cursor-pointer absolute bottom-2 m-5 bg-red-400">
					<p className="font-sans font-semibold text-white text-xs">
						{unreadMessages}
					</p>
				</div>
			)}
		</button>
		// </Link>
	);

	return (
		<>
			<header
				className={`flex ${
					showMobileSearch == true ? 'w-11/12 relative' : 'w-full sticky'
				}  ${
					showConversations ? 'h-screen min-h-screen relative' : 'h-auto'
				}  top-0 z-30 `}
				style={{ zIndex: showConversations && 9999 }}
			>
				{popups && popups.length ? (
					<DynamicPopups popups={popups} acknowledgePopup={acknowledgePopup} />
				) : null}
				<SEO title="Djawn" />
				<nav className="w-full bg-white border-b">
					{showMobileSearch == true && hideSearch == false ? (
						<div className="rounded-md shadow-sm w-full">
							<input
								type="text"
								name="search"
								id="search"
								value={searchValue}
								className="focus:ring-blue-400 focus:border-blue-400 pl-2 pr-2 block w-full h-10 sm:text-sm border border-gray-200 rounded-md"
								placeholder="Search"
								onChange={(e) => {
									setSearchValue(e.target.value);
								}}
								onKeyDown={(e) => {
									if (!e.keyCode) return;
									if (e.keyCode == 13) {
										onSearch(searchValue);
										dispatch(updateShowMobileSearch());
									}
								}}
							/>

							<div className="absolute inset-y-0 right-0 flex">
								<button
									type="button"
									className="flex items-center h-auto w-14 bg-blue-600 hover:bg-blue-900 text-blue-600 hover:text-blue-500 rounded-md rounded-br-md"
									onClick={() => {
										onSearch(searchValue);
										dispatch(updateShowMobileSearch());
									}}
								>
									<div className="flex items-center h-auto w-6 text-white mx-auto">
										{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white"><path d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.9 3.5l4.8 4.8a1 1 0 01-1.4 1.4L11.5 13A6 6 0 012 8z" /></svg> */}
										<svg
											className="h-6 w-6"
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
											/>
										</svg>
									</div>
								</button>

								<button
									type="button"
									className="flex items-center h-auto w-10 bg-djawn hover:bg-red-900 text-white hover:text-white rounded-md rounded-br-md"
									onClick={() => {
										dispatch(updateShowMobileSearch());
									}}
								>
									<div className="flex items-center h-auto w-6 text-white mx-auto">
										<svg
											fill="white"
											className="h-6 w-6"
											stroke="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M6 18L18 6M6 6l12 12"
											/>
										</svg>
									</div>
								</button>
							</div>
						</div>
					) : (
						<div className="relative mx-auto px-4 sm:px-6 lg:px-8">
							<div
								className={`absolute flex left-0 top-0 h-12 w-auto sm:h-12 sm:w-auto md:w-12 md:h-12 lg:w-12 lg:h-12 xl:h-12 xl:w-12 items-center text-center justify-start content-center xl:hidden ${
									noMargins === false && 'ml-10'
								} md:ml-0`}
							>
								<button
									type="button"
									className={`bg-transparent rounded-md p-2 inline-flex items-center justify-center text-gray-900 hover:text-gray-700 focus:outline-none mr-${
										noMargins === false && '3'
									} md:mr-0`}
									id="main-menu"
									aria-haspopup="true"
									onClick={() => {
										if (!showConversations) {
											onChange();
										}
									}}
								>
									<span className="sr-only">Open main menu</span>
									<svg
										className="h-6 w-6 md:h-7 md:w-7"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M4 6h16M4 12h16M4 18h16"
										/>
									</svg>
								</button>
								{hideSearch == false && (
									<>
										<div
											className="block md:hidden xs:hidden"
											onClick={() => {
												dispatch(updateShowMobileSearch());
											}}
											onKeyDown={() => {
												dispatch(updateShowMobileSearch());
											}}
										>
											<svg
												className="w-6 h-6 mr-1"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
												/>
											</svg>
										</div>
										{isLoggedIn() && (
											<div className="visible md:invisible">
												{conversationIcon()}
											</div>
										)}
									</>
								)}
							</div>
							<div className="flex flex-col md:flex-row items-center justify-center h-12 xl:h-16">
								<div className="flex items-center justify-center w-28 ml-4 md:ml-4 lg:ml-4 xl:ml-0">
									<div
										className="flex-shrink-0"
										onClick={() => {
											setShowConversations(false);
										}}
										onKeyDown={() => {
											setShowConversations(false);
										}}
									>
										{leavePage == false ? (
											<Link to="/">
												<img
													className="h-8 w-auto sm:h-10 cursor-pointer"
													src={logo}
													alt="Djawn"
												/>
											</Link>
										) : (
											<a
												href={`${env.frontend_rul}`}
												onClick={() => confirm('Changes you made may not be saved.')}
											>
												<img
													className="h-8 w-auto sm:h-10 cursor-pointer"
													src={logo}
													alt="Djawn"
												/>
											</a>
										)}
									</div>
								</div>

								<div className="flex flex-grow hidden md:block md:flex md:flex-1">
									<div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-1">
										<div
											className={`flex items-center justify-between w-3/5 mx-auto ${
												hideSearch == true ? 'hidden' : ''
											}`}
										>
											<div className="relative rounded-md shadow-sm w-full">
												<input
													type="text"
													name="search"
													id="search"
													className="focus:ring-blue-400 focus:border-blue-400 pl-2 pr-2 block w-full h-10 sm:text-sm border border-gray-200 rounded-md"
													placeholder="Search"
													onChange={(e) => {
														setSearchValue(e.target.value);
													}}
													onBlur={(e) => {
														setSearchValue(e.target.value);
													}}
													onKeyDown={(e) => {
														if (!e.keyCode) return;
														if (e.keyCode == 13) {
															onSearch(searchValue);
														}
													}}
												/>

												<button
													type="button"
													className="absolute inset-y-0 right-0 flex items-center h-auto w-20 bg-blue-600 hover:bg-blue-900 text-blue-600 hover:text-blue-500 rounded-tr-md rounded-br-md"
													onClick={() => {
														onSearch(searchValue);
													}}
												>
													<div className="flex items-center h-auto w-6 text-white mx-auto">
														<svg
															className="h-6 w-6"
															fill="none"
															stroke="currentColor"
															viewBox="0 0 24 24"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth="2"
																d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
															/>
														</svg>
													</div>
												</button>
											</div>
										</div>
									</div>
								</div>

								{!loggedIn && (
									<div
										className={`flex items-center justify-center md:flex absolute md:static right-2 md:right-0 flex-row ${
											showCart == false && `mr-${noMargins ? '2' : '5'}`
										}`}
									>
										{showHelpIcon && (
											<div className="md:mr-2 -mr-2">
												<button
													type="button"
													className="w-12 h-12 focus:outline-none text-black hover:text-black text-lg p-3 rounded-md rounded-tr-none rounded-br-none"
													onClick={onHelpClick}
												>
													<div className="relative">
														<svg
															className="w-6 h-6"
															fill="currentColor"
															viewBox="0 0 20 20"
															xmlns="http://www.w3.org/2000/svg%22"
														>
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
															/>
														</svg>
													</div>
												</button>
											</div>
										)}
										<div className="flex items-center">
											{redirectLocation ? (
												<Link
													to="/signin"
													state={{
														redirectUrl: redirectLocation
													}}
												>
													<HeaderLoginButton />
												</Link>
											) : (
												<Link to="/signin">
													<HeaderLoginButton />
												</Link>
											)}
										</div>
									</div>
								)}
								{loggedIn && (
									<div
										className={`flex items-center justify-center md:flex absolute md:static right-2 md:right-0 ${
											showCart == false && `mr-${noMargins ? '2' : '5'}`
										}`}
									>
										<div className="flex items-center">
											{isLoggedIn() && (
												<div className="invisible md:visible">
													{conversationIcon()}
												</div>
											)}
											{showCart && (
												<div className="md:mr-2 -mr-2">
													<button
														type="button"
														className="w-12 h-12 focus:outline-none text-black hover:text-black text-lg p-3 rounded-md rounded-tr-none rounded-br-none"
														onClick={() => {
															setShowConversations(false);
															cartOnClick();
														}}
													>
														<div className="relative">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 24 24"
																stroke="currentColor"
															>
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	strokeWidth="2"
																	d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
																/>
															</svg>
														</div>

														{cartItemCount() > 0 && (
															<div className="rounded-full h-4 w-4 flex items-center justify-center mb-1 cursor-pointer absolute bottom-1 m-3 bg-red-400">
																<p className="font-sans font-semibold text-white text-xs">
																	{cartItemCount()}
																</p>
															</div>
														)}
													</button>
												</div>
											)}
											{(showHelpIcon || showHelpIconForEvent) && (
												<div className="md:mr-2 -mr-2">
													<button
														type="button"
														className="w-12 h-12 focus:outline-none text-black hover:text-black text-lg p-3 rounded-md rounded-tr-none rounded-br-none"
														onClick={onHelpClick}
													>
														<div className="relative">
															<svg
																className="w-6 h-6"
																fill="currentColor"
																viewBox="0 0 20 20"
																xmlns="http://www.w3.org/2000/svg%22"
															>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
																/>
															</svg>
														</div>
													</button>
												</div>
											)}
											<div className="relative text-center" ref={ref}>
												<button
													type="button"
													className="w-10 h-10 p-2 rounded-full text-gray-900 focus:outline-none flex items-center jutify-center"
													onClick={() => setIsOn(!isOn)}
													style={{ zIndex: 10000 }}
												>
													<UserAvatar
														name={avatarName}
														className="rounded-full w-10 hover:bg-gray-200"
														color="black"
													/>
												</button>
												{isOn == true ? (
													<>
														<div
															className={`${
																isOn
																	? 'opacity-100 scale-100'
																	: 'opacity-0 scale-95'
															} origin-top-right absolute right-0 mt-0 w-36 shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 z-50`}
															role="menu"
															aria-orientation="vertical"
															aria-labelledby="options-menu"
														>
															<div className="py-1">
																{user && user.userDetails && isSeller ? (
																	<>
																		<button
																			onClick={() => {
																				setShowSellerMenuItems(
																					!showSellerMenuItems
																				);
																			}}
																			type="button"
																			className="block px-4 py-2 text-sm font-normal text-gray-700 w-full hover:bg-gray-200 active:bg-green-700"
																			// className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
																		>
																			<div className="flex flex-row justify-start">
																				<div className="-ml-1">
																					<svg
																						className="w-5 h-5"
																						fill="currentColor"
																						viewBox="0 0 24 24"
																						xmlns="http://www.w3.org/2000/svg"
																					>
																						{!showSellerMenuItems ? (
																							<path
																								strokeLinecap="round"
																								strokeLinejoin="round"
																								strokeWidth="2"
																								d="M15 19l-7-7 7-7"
																							/>
																						) : (
																							<path
																								strokeLinecap="round"
																								strokeLinejoin="round"
																								strokeWidth="2"
																								d="M9 5l7 7-7 7"
																							/>
																						)}
																					</svg>
																				</div>
																				<span className="item-text">
																					Seller Menu
																				</span>
																			</div>
																		</button>
																	</>
																) : null}
																{isAdmin && (
																	<Link
																		to="/admin"
																		className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
																	>
																		Admin Page
																	</Link>
																)}
																<Link
																	to="/view-profile"
																	className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
																	role="menuitem"
																>
																	Profile
																</Link>
																<Link
																	to="/orders"
																	className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
																	role="menuitem"
																>
																	Orders
																</Link>
															</div>
															<div className="py-1">
																<button
																	type="button"
																	className="w-full block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
																	onClick={signOutUser}
																>
																	Sign Out
																</button>
															</div>
														</div>
														{showSellerMenuItems && (
															<div
																className="origin-top-right absolute mt-0 w-36 shadow-lg ring-1 bg-white ring-black ring-opacity-5 divide-y divide-gray-100 z-50"
																style={{ right: '144px', top: '50px' }}
															>
																<Link
																	to="/event"
																	className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
																>
																	Plan An Event
																</Link>
																<div
																	className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
																	onClick={startDefaultRipping}
																	onKeyDown={startDefaultRipping}
																>
																	Start Ripping
																</div>
																<Link
																	to="/products"
																	className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
																>
																	Products
																</Link>
																<Link
																	to="/sales"
																	className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
																>
																	Sales
																</Link>
															</div>
														)}
													</>
												) : null}
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					)}
				</nav>
				<div
					className={`h-full max-h-full w-full absolute overflow-hidden bg-green max-w-full mt-10 md:mt-15 bg-white dark:bg-darkGray-50 ${
						showConversations ? 'visible' : 'hidden'
					}`}
				>
					<Conversation
						showConversations={showConversations}
						startedConversation={startedConversation}
						setShowConversations={setShowConversations}
					/>
				</div>
			</header>
			{/* <div
				style={{ zIndex: 9998, height: '94vh' }}
				className={`h-screen w-full absolute mt-10 md:mt-15 overflow-hidden bg-green max-w-full ${
					showConversations ? 'visible' : 'hidden'
				}`}
			>
				<Conversation
					showConversations={showConversations}
					startedConversation={startedConversation}
				/>
			</div> */}
		</>
	);
};

export default connect(
	(state) => ({
		showMobileSearch: state.utils.showMobileSearch,
		unreadMessages: state.utils.unreadMessages
	}),
	null
)(HeaderHome);
