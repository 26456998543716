export const inputTypes = {
	primary: {
		focusColor: 'indigo-400',
		borderColor: 'gray-200'
	},
	validPrimary: {
		focusColor: 'indigo-400',
		borderColor: 'green-500'
	},
	invalidPrimary: {
		focusColor: 'indigo-400',
		borderColor: 'red-500'
	}
};
