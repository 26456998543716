/* eslint-disable no-unused-vars */
import React from 'react';

const RoomOptions = ({
	eventId, showModal, setShowModal, onStartButtonClicked, onPauseButtonClicked, onStopButtonClicked, isPaused
}) => (
	<>
		{showModal ? (
			<>
				<div
					className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
					onClick={() => setShowModal(false)}
					onKeyDown={() => setShowModal(false)}
				>
					<div className="relative w-auto my-6 mx-auto max-w-3xl">
						{/* content */}
						<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
							{/* header */}
							<div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
								<h3 className="text-3xl font-semibold">
									Event Options
								</h3>
								<button
									type="button"
									className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
									onClick={() => setShowModal(false)}
								>
									<span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
										×
									</span>
								</button>
							</div>
							{/* body */}
							<div className="relative p-3 flex-auto">
								<p className="my-4 text-gray-600 text-lg leading-relaxed">
									<button
										className="w-full bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
										type="button"
										style={{
											transition: 'all .15s ease'
										}}
										onClick={() => onStartButtonClicked()}
									>
										Start
									</button>
								</p>
							</div>
							<div className="relative p-3 flex-auto">
								<p className="my-4 text-gray-600 text-lg leading-relaxed">
									<button
										className="w-full bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
										type="button"
										style={{
											transition: 'all .15s ease'
										}}
										onClick={() => onPauseButtonClicked()}
									>
										{!isPaused ? 'Pause' : 'Continue'}
									</button>
								</p>
							</div>
							<div className="relative p-3 flex-auto">
								<p className="my-4 text-gray-600 text-lg leading-relaxed">
									<button
										className="w-full bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
										type="button"
										style={{
											transition: 'all .15s ease'
										}}
										onClick={() => onStopButtonClicked()}
									>
										Stop
									</button>
								</p>
							</div>
							{/* footer */}
						</div>
					</div>
				</div>
				<div className="opacity-25 fixed inset-0 z-40 bg-black" />
			</>
		) : null}
	</>
);

export default RoomOptions;
