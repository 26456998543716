import React from 'react';

const CustomDontShowAgain = ({ message }) => message && (
	<>
		<p>{message}</p>
		<div className="flex justify-end">
			{/* <p onClick={onDontShowAgain}>Don’t show this to me again</p> */}
		</div>

	</>
);

export default CustomDontShowAgain;
