export const defaultAutocompleteOptions = {
	fields: [
		'address_components',
		'vicinity',
		'formatted_address'
	],
	types: ['address'],
	componentRestrictions: {
		country: ['us']
	}
};
