import { useEffect, useRef, useState } from 'react';
// TODO: Rename exception
const useVirtualAndroidKeyboardDetector = (detect, exception) => {
	const [visible, setVisible] = useState(false);
	const exceptionRef = useRef(false);

	useEffect(() => {
		if (!window || !detect) return;
		const height =			window.innerHeight
			|| document.documentElement.clientHeight
			|| document.body.clientHeight;
		if ('visualViewport' in window) {
			window.visualViewport.addEventListener('resize', (e) => {
				if (exceptionRef.current == true) return;
				if (e.target && e.target.height) {
					const resizedHeight = parseInt(e.target.height, 0);
					const keyboardVisible = height > resizedHeight;
					setVisible(keyboardVisible);
					if (height > resizedHeight) {
						window.setTimeout(() => {
							document.activeElement.scrollIntoView();
						}, 0);
					}
				}
			});
		}
	}, [detect]);

	useEffect(() => {
		exceptionRef.current = exception;
	}, [exception]);

	return [visible];
};

export default useVirtualAndroidKeyboardDetector;
