import React from 'react';
import Modal from '../../components/modals';
import Button from '../../components/button/index';

const RefundDeclineModal = ({
	showModal,
	setShowModal,
	rejectedRefundReasonId,
	setRejectedRefundReasonId,
	declineReasons,
	onDecline
}) => (
	<Modal
		showModal={showModal}
		onClose={() => setShowModal(false)}
		hideCloseButton
	>
		<div className="w-full flex flex-col">
			<p>Select the reason why the refund is declined</p>
			<select
				id="rejected_request_reason"
				name="rejected_request_reason"
				autoComplete="rejected_request_reason"
				className="h-10 text-sm focus:outline-none border px-5 rounded-md mb-3 md:mb-0"
				onBlur={(e) => setRejectedRefundReasonId(e.target.value)}
				onChange={(e) => setRejectedRefundReasonId(e.target.value)}
				value={rejectedRefundReasonId || ''}
			>
				<option key={null} value={0} selected>
					Refund Reason
				</option>
				{declineReasons.map((refundReason) => (
					<option key={refundReason.id} value={refundReason.id}>
						{refundReason.value}
					</option>
				))}
			</select>
		</div>
		<div className="flex w-full justify-between py-2">
			<Button height="9" mobileHeight="9" onClick={() => setShowModal(false)}>
				Cancel
			</Button>
			<Button
				height="9"
				width="32"
				mobileHeight="9"
				disabled={!rejectedRefundReasonId || rejectedRefundReasonId == 0}
				onClick={() => {
					setShowModal(false);
					onDecline();
				}}
			>
				Decline
			</Button>
		</div>
	</Modal>
);

export default RefundDeclineModal;
