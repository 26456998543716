import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import HeaderHome from '../components/common/header-home';
import Layout from '../components/layouts/layout-default';
import SEO from '../components/seo';
import { defaultToastError } from '../utils/toast-error';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const NoAvailableProducts = () => {
	const [loading, setLoading] = useState(false);

	if (loading) {
		return (
			<div className="w-screen h-screen bg-white absolute left-0 top-0">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}

	return (
		<Layout>
			<SEO title="Create Event" auth />
			<div className="bg-white mx-auto w-full h-screen pt-0 ">
				<HeaderHome
					siteTitle="Title"
					auth={false}
					setLoading={setLoading}
					signin={false}
					hideSearch
					onError={(errorMessage) => {
						defaultToastError(errorMessage);
					}}
				/>
				<ToastContainer />
				<div className="bg-white pt-20">
					<div className="flex w-full justify-center pb-8">
						<p className="text-2xl text-gray-700 text-center mx-auto mt-5">
							You don't have any products yet
						</p>
					</div>
					<div className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0">
						<div>
							<Link to="/products">
								<button
									type="button"
									className="h-10 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white bg-red-700 hover:bg-red-900 md:py-4 md:text-lg md:px-10 mb-5"
								>
									Add Product
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default NoAvailableProducts;
