/* eslint-disable array-callback-return */
/* eslint-disable radix */
/* eslint-disable no-loop-func */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { css } from '@emotion/core';
import { getEventStepTeamDistribution } from '../../api/event.request';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;
const ViewEventTemplateModal = ({ template, showModal, onClose }) => {
	const [loading, setLoading] = useState(true);
	const [teamDistribution, setTeamDistribution] = useState([]);
	const [tiersData, setTiersData] = useState([]);

	useEffect(() => {
		Promise.all([getEventStepTeamDistribution(template.breakTemplateId)]).then(
			([teamDist]) => {
				setTeamDistribution(teamDist.data);
				TeamDistributionData(teamDist.data);
			}
		);
	}, []);

	const TeamDistributionData = (teamDist) => {
		const rows = [];
		let children = [];
		for (let i = 1; i <= parseInt(template.tierSize); i++) {
			teamDist.map((value) => {
				if (parseInt(value.tier) == i) {
					children.push({ teamId: value.teamId, team: value.team });
				}
			});
			rows.push(children);
			children = [];
		}

		const array = JSON.parse(JSON.stringify(rows));

		setTiersData(array);
		setLoading(false);
	};

	return (
		<div>
			{showModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-1 top-2.5 z-50 top-30 outline-none focus:outline-none">
						<div
							className={`relative my-6 mx-auto max-w-3xl ${
								window.screen.width <= 786 ? 'w-full' : 'w-auto'
							}`} // "relative w-auto my-6 mx-auto max-w-3xl"
							// onMouseLeave={() => onClose()}
						>
							{/* content */}
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/* header */}
								<div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
									<h3 className="text-sm font-semibold">
										{template.templateName}
									</h3>
									<button
										type="button"
										className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
										onClick={() => onClose()}
									>
										<span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
											×
										</span>
									</button>
								</div>
								{/* body */}
								{loading ? (
									<BounceLoader loading css={override} size={30} />
								) : (
									<div
										className="relative p-6 flex flex-auto overflow-x-auto text-center max-h-100 h-100"
										style={{
											maxHeight: '400px',
											overflowY: 'scroll'
										}}
									>
										{tiersData.map((tier, i) => (
											<div key={i} className="m-5">
												<span className="font-bold text-center">
													Tier
													{' '}
													{i + 1}
												</span>
												{tier.map((t, j) => (
													<div
														key={j}
														className="shadow-lg h-16 w-40 font-sm bg-white rounded-md items-center justify-center py-5 my-2"
													>
														{t.team}
													</div>
												))}
												<div />
											</div>
										))}
									</div>
								)}
								{/* footer */}
								<div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
									<button
										className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-lg"
										type="button"
										onClick={() => onClose()}
									>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black" />
				</>
			) : null}
		</div>
	);
};

export default ViewEventTemplateModal;
