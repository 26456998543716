/* eslint-disable no-multi-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, {
	useState, useEffect, useContext
} from 'react';
import { navigate } from 'gatsby';
import { isAndroid, isTablet } from 'react-device-detect';
import firebase from 'gatsby-plugin-firebase';
import Participant from './participant';
import RoomOptions from '../room/room-options';
import env from '../../../environment.config';
import {
	requestMic, joinRipNShipQueue, endTurnRequest, initiateEndTurnRequest, exitQueuePosition,
	exitQueue, getEventDetails, subscribeToTwilioTrack,
	stopMicrophoneAccess, banUserFromEvent, getRipNShipEventDetail, hasUserPurchasedItemsInEvent,
	updateRoomStatusToStarted,
	isRipNShipQueueClosed,
	closeRipNShipQueue,
	shouldShowKeepItemsOptionsInRipNShip,
	openRipNShipQueueLine,
	isEventStopped,
	getRipNShipActiveProducts,
	approveMicRequestByBuyer
} from '../../api/event.request';
import CameraChangeModal from '../room/camera-change-modal';
import { formatJsonDateToProperDateObject, formatUTCDateToLocal } from '../../utils/formatter';
import { createHubConnection } from '../../utils/hubConnection';
import AudioParticipant from './audio-participant';
import { useInterval } from '../common/use-interval';
import StoreModal from '../common/store-modal';
import EventQueue from '../room/event-queue';
import ProductModalEventQueue from '../room/product-modal-event-queue';
import { getCurrentUser } from '../../utils/auth';
import PurchasedProductsModal from '../room/purchased-products-modal';
import RipNShipQueueModal from '../room/ripnship-queue-modal';
import { defaultToastError } from '../../utils/toast-error';
import BuyerRipNShipExitConfirmation from '../buyer-ripnship-exit-confirmation';
import SellerRipNShipExitConfirmation from '../seller-ripnship-exit-confirmation';
import { dontShowAgainToast } from '../../utils/dont-show-again-toast';
import { informationToast } from '../../utils/information-toast';
import BanConfirmation from '../ban-confirmation';
import CloseLineModal from '../../containers/event/room/close-line-modal';
import { isTruthy } from '../../utils/commonFunction';
import useErrorNotifier from '../../hooks/use-error-notifier';
import { ProductsProvider } from '../../contexts/products';
import EventProductsRemove from '../../containers/event/event-products-remove';
import AddedProductModal from '../../containers/event/room/added-product-modal';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import { TwilioTrackContext } from '../../contexts/twilio-track';
import useAudio from '../../hooks/useAudio';
import { NotificationSoundContext, NotificationSoundProvider } from '../../contexts/notification-sound';
import { NOTIFY_MIC_REQUEST, MOTIFY_PRODUCT_TRIGGER } from '../../utils/constants/webSocketNameConstants';

// TODO: Refactor:: too many props
const VideoStream = ({
	roomName,
	isMobile,
	additionalEndTimeInSeconds,
	eventInformation,
	breaks,
	token,
	room,
	setSearchInputActive,
	handleLogout,
	landscapeMode,
	eventNamePopupRef,
	data,
	isHost,
	isSeller,
	authStatus,
	ownerNickname,
	eventName,
	eventDetails,
	eventId,
	onStartClicked,
	onStopClicked,
	onPauseClicked,
	isPaused,
	showThankYou,
	isStopped,
	mobileSize,
	reorganizeAccordion,
	onTwilioVideoReconnect,
	arrowButtonContainerRef,
	eventStartDate,
	setEventStartDate,
	children,
	setHasRaisedHand,
	exitEventRoom,
	setRoom,
	setVideoHeight,
	changeProductQuantityToCart,
	addProductToCart,
	setEventInformation,
	cartItems,
	openQueueContainer,
	setOpenQueueContainer,
	updatePopupsStatus,
	saveTrack,
	resize,
	setResize,
	showAddToCartPopup,
	onProductNamePopupClose,
	addToCartPopupFadingOut,
	openedCartWindow,
	onOpenQueueContainer,
	setLeftArrowButtonPosition,
	setTopArrowButtonPosition,
	removeChildrenContainerHeight,
	onOrientationChange,
	arrowButtonRef,
	logoExitClicked,
	onLogoExitClosed,
	onEventStopped,
	eventStopped,
	fetchAttendees,
	roomRef,
	purchasedProductsUserId,
	setPurchasedProductsUserId,
	showEventNameInChatContainer,
	setShowEventNameInChatContainer
}) => {
	const [participants, setParticipants] = useState([]);
	const [grids, setGrids] = useState(2);
	const [showModal, setShowModal] = useState(false);
	const [showCameraChange, setShowCameraChange] = useState(false);
	const [hostParticipant, setHostParticipant] = useState(null);
	const [isInRipnShipQueueActiveLine, setIsInRipnShipQueueActiveLine] = useState(null);
	const [buyerHasPurchasedItems, setBuyerHasPurchasedItems] = useState(false);
	const [deviceId, setDeviceId] = useState(null);
	const [hubConnection, setHubConnection] = useState(null);
	const [notifyMicRequestMessage, setNotifyMicRequestMessage] = useState(
		null
	);

	const [changeWsMessage, setChangeWsMessage] = useState(null);
	const [swapChangeWsMessage, setSwapChangeWsMessage] = useState(null);

	const [
		initiatedEndTurnsWsMessage,
		setInitiatedEndTurnsWsMessage
	] = useState(null);
	const [endTurnWsMessage, setEndTurnWsMessage] = useState(null);
	const [exitWsMessage, setExitWsMessage] = useState(null);
	const [newBuyersWsMessage, setNewBuyersWsMessage] = useState(null);

	const [wsMessage, setWsMessage] = useState(null);
	const [quantityUpdateWsMessage, setQuantityUpdateWsMessage] = useState(null);
	const [newTrack, setNewTrack] = useState(null);
	const [talkerParticipantId, setTalkerParticipantId] = useState(null);
	const [talkerStartTime, setTalkerStartTime] = useState(null);
	const [talkerLimit, setTalkerLimit] = useState(null);
	const [showProductModal, setShowProductModal] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [bannedUserWsMessage, setBannedUserWsMessage] = useState(null);
	const [trigger, setTrigger] = useState(false);
	const [showPurchasedItemsModal, setShowPurchasedItemsModal] = useState(false);
	const [purchasedItemsBuyer, setPurchasedItemsBuyer] = useState(null);
	const [updatedBuyersQueueWsMessage, setUpdatedBuyersQueueWsMessage] = useState(null);
	const [showRipNShipModal, setShowRipNShipModal] = useState(false);
	const [joinedQueue, setJoinedQueue] = useState(false);
	const [showBuyerExitConfirmationModal, setShowBuyerExitConfirmationModal] = useState(false);
	const [showSellerExitConfirmationModal, setShowSellerExitConfirmationModal] = useState(false);
	const [buyerExitId, setBuyerExitId] = useState(null);
	const [queueOrderId, setQueueOrderId] = useState(null);
	const [isStoreModalOpen, setIsStoreModalOpen] = useState(false);
	const [storeInfo, setStoreInfo] = useState(null);
	const [sellerImage, setSellerImage] = useState(null);
	const [sound, setSound] = useState(true);
	const [wsTriggeredWsMessage, setWsTriggeredWsMessage] = useState(null);
	const [isEventInformationReinitiating, setIsEventInformationReinitiating] = useState(false);
	const [reversedCamera, setReversedCamera] = useState(-1);
	const [showCloseLineModal, setShowCloseLineModal] = useState(false);
	const [showEndEventModal, setShowEndEventModal] = useState(false);
	const [futureEventDate, setFutureEventDate] = useState(null);
	const [closeLineTime, setCloseLineTime] = useState(null);
	const [ripNShipTimers, setRipNShipTimers] = useState([]);
	const [ripNShipActiveBuyersInLine, setRipNShipActiveBuyersInLine] = useState([]);
	const [disableRipNShipFunctionality, setDisableRipNShipFunctionality] = useState(false);
	const [userWantsToKeepItemsWsMessage, setUserWantsToKeepItemsWsMessage] = useState(null);
	const [eventStoppedWsMessage, setEventStoppedWsMessage] = useState(null);
	const [attendeesChangedWsMessage, setAttendeesChangedWsMessage] = useState(null);
	const { showToastError } = useErrorNotifier();
	const {
		attendees, setAttendees, loadTeamsRandomization, isRipNShip
	} = useContext(LiveEventDataContext);
	const {
		setLocalAudioTracks,
		twilioParticipantId,
		setTwilioParticipantId,
		setApprovedAudioParticipants,
		setNewAudioParticipant,
		setNewCanceledMicRequestAccess,
		setExpiredMicAccessMessage,
		micAccessApprovedByBuyer,
		setNewMutedAudioParticipants,
		audioParticipants,
		setAudioParticipants,
		closeMic,
		removeLocalMicAccess
	} = useContext(TwilioTrackContext);
	const { playWebSocketNotificationAudio } = useContext(NotificationSoundContext);

	useEffect(() => {
		if (!bannedUserWsMessage) return;
		const user = getCurrentUser();
		const anonToken = localStorage.getItem(`anon-${eventId}`);
		if ((bannedUserWsMessage.userId && bannedUserWsMessage.userId.length > 0 && user.id == bannedUserWsMessage.userId)
			|| (bannedUserWsMessage.anonymousToken && bannedUserWsMessage.anonymousToken.length > 0 && anonToken == bannedUserWsMessage.anonymousToken)) {
			if (bannedUserWsMessage.error) {
				navigate('/', {
					state: {
						toastError: bannedUserWsMessage.error
					}
				});
			} else {
				navigate('/');
			}
		}
	}, [bannedUserWsMessage]);

	useEffect(() => {
		if (!micAccessApprovedByBuyer) return;
		onTwilioVideoReconnect({
			overrideRefreshCheck: true,
			audio: true,
			pendingMessage: 'Setting up the microphone...',
			successMessage: 'Your microphone has been connected to the event successfully.',
			onFailure: () => {
				setTimeout(() => {
					closeMic([twilioParticipantId], false, false);
					removeLocalMicAccess();
				}, 2000);
			}
		});
	}, [micAccessApprovedByBuyer]);

	useEffect(() => {
		if (!attendeesChangedWsMessage) return;
		fetchAttendeesAndUpdateAudioParticipants();
		setAttendeesChangedWsMessage(null);
	}, [attendeesChangedWsMessage]);

	useEffect(() => {
		if (!eventStoppedWsMessage || isHost) return;
		if (onEventStopped) {
			onEventStopped(eventStoppedWsMessage.additionalEndTimeInSeconds);
		}
	}, [eventStoppedWsMessage]);

	const onEventEndingMethod = () => {
		if (ripNShipActiveBuyersInLine
				&& ripNShipActiveBuyersInLine.length && ripNShipActiveBuyersInLine.length > 1
				&& isRipNShip) {
			setShowRipNShipModal(false);
			setShowEndEventModal(true);
			setShowCloseLineModal(true);
		} else {
			setShowRipNShipModal(false);
			setShowEndEventModal(false);
			exitEventRoom();
		}
	};
	useEffect(() => {
		if (!logoExitClicked) return;
		onEventEndingMethod();
	}, [logoExitClicked]);

	const onRipNShipLineClosed = () => {
		closeRipNShipQueue(eventId, false, false).then(() => {
			setShowRipNShipModal(false);
			setShowCloseLineModal(false);
		}).catch((err) => {
			console.error(err);
		});
	};

	const updateRipnShipFunctionalityOnRipNShipLineClosed = (obj) => {
		const ripnshipKey = `event-${eventId}-ripnship`;
		const ripnshipStringified = sessionStorage.getItem(ripnshipKey);
		let ripnship = {};
		if (ripnshipStringified) {
			ripnship = JSON.parse(ripnshipStringified);
		}
		if (ripnship == null) {
			ripnship = {};
		}
		if (ripnship == null || ripnship.closeLine != obj.closeLine) {
			informationToast(obj.closeLine == true ? 'The seller has closed the line' : 'The seller has opened the line');
			ripnship.closeLine = obj.closeLine;

			sessionStorage.setItem(ripnshipKey, JSON.stringify(ripnship));
		}
		setDisableRipNShipFunctionality(obj.closeLine);
		if (obj.hasFutureEvent) {
			shouldShowKeepItemsOptionsInRipNShip(eventId).then((res) => {
				if (!res.data.showBuyerKeepItemsOption && !res.data.isSeller) return;
				if (obj.futureEventTime && res.data.showBuyerKeepItemsOption) {
					const date = formatUTCDateToLocal(obj.futureEventTime, 'DD-MMM-YYYY HH:mm').replace(/--/g, '-');
					setFutureEventDate(date);
				}

				if (obj.countdownTimerInSeconds && obj.timerStartTime && closeLineTime == null && (ripnship == null || !ripnship.endEventTimerStarted)) {
					ripnship.endEventTimerStarted = true;
					sessionStorage.setItem(ripnshipKey, JSON.stringify(ripnship));
					const timerObject = {
						countdownTimerStartTime: obj.timerStartTime,
						timerInSeconds: obj.countdownTimerInSeconds
					};
					setCloseLineTime(timerObject);
					setShowCloseLineModal(true);
				}
			}).catch((err) => {
				console.error(err);
			});
		}
	};

	useEffect(() => {
		if (!notifyMicRequestMessage) return;
		const tempAttendees = [...attendees].map((at) => {
			if (at.userId == notifyMicRequestMessage.buyerId) {
				at.micRequest = true;
			}
			return at;
		});
		setAttendees(tempAttendees);
		setNotifyMicRequestMessage(null);
	}, [notifyMicRequestMessage]);

	useEffect(() => {
		if (!wsTriggeredWsMessage) return;
		const tempEventInformation = { ...eventInformation };
		tempEventInformation.buyersQueue.map((b) => {
			if (b.userId == wsTriggeredWsMessage) {
				b.purchasedItem = true;
			}
			return b;
		});

		setEventInformation(tempEventInformation);
		setWsTriggeredWsMessage(null);
	}, [wsTriggeredWsMessage]);

	const joinQueue = () => {
		joinRipNShipQueue(eventId)
			.then((res) => {
				setJoinedQueue(true);
				if (eventInformation) {
					dontShowAgainToast('Please complete purchases as quickly as possible in order to keep your place in line.', 'ACTIVE_LINE');
				}
			})
			.catch((err) => {
				if (err && err.response) {
					defaultToastError(err.response.data.error);
				}
			});
	};

	useEffect(() => {
		const swapJson = JSON.stringify(swapChangeWsMessage);
		const changeJson = JSON.stringify(changeWsMessage);
		if (swapJson != changeJson) {
			setChangeWsMessage(swapChangeWsMessage);
		}
	}, [swapChangeWsMessage]);

	useEffect(() => {
		setGrids(mobileSize ? 1 : 2);
	}, [mobileSize]);

	const initiateEndTurn = () => {
		initiateEndTurnRequest(eventId)
			.then((res) => {
			})
			.catch((err) => {
				console.error(err);
			}).finally((_) => {
				if (showRipNShipModal == true) {
					setShowRipNShipModal(false);
				}
			});
	};
	const endQueueActiveTurn = (position, userId) => {
		endTurnRequest(eventId, userId, position)
			.then((res) => {

			})
			.catch((err) => {
				if (err.response && err.response.data.error) {
					defaultToastError(err.response.data.error);
				}
			}).finally((_) => {
				if (showRipNShipModal == true) {
					setShowRipNShipModal(false);
				}
			});
	};

	useEffect(() => {
		if (!quantityUpdateWsMessage || !isRipNShip) return;

		const tempEventDetail = { ...eventInformation };
		tempEventDetail.products.map((eventProduct) => {
			if (eventProduct.eventProductId == quantityUpdateWsMessage.eventProductId) {
				eventProduct.reservedQuantityByBuyers += quantityUpdateWsMessage.quantity;
				eventProduct.product.totalAvailableStock -= quantityUpdateWsMessage.quantity;
			}
			return eventProduct;
		});

		setEventInformation(tempEventDetail);

		setTrigger(!trigger);
	}, [quantityUpdateWsMessage]);

	useEffect(() => {
		if (additionalEndTimeInSeconds == 0) return;
		if (showThankYou === true && isSeller === false) {
			setTimeout(() => {
				if (typeof window !== 'undefined') {
					isEventStopped(eventId).then((res) => {
						if (res.data && res.data.stopped === true && res.data.additionalEndTimeInSeconds && res.data.additionalEndTimeInSeconds > 0) {
							if (eventId === res.data.eventId) {
								if (window.location) {
									window.location.href = window.location.origin;
								} else {
									navigate('/');
								}
							}
						}
					}).catch((err) => {

					});
				}
			}, additionalEndTimeInSeconds * 1000);
		}
	}, [showThankYou, additionalEndTimeInSeconds]);

	useEffect(() => {
		if (eventId) {
			isRipNShipQueueClosed(eventId).then((res) => {
				updateRipnShipFunctionalityOnRipNShipLineClosed(res.data);
			}).catch((err) => {
				setDisableRipNShipFunctionality(false);
			});
		}

		const onVisibilityChangeListener = () => {
			if (document.visibilityState === 'visible') {
				firebase.database().goOffline();
				firebase.database().goOnline();
				setTimeout(() => {
					eventStopped(false, isHost, () => {
						if (onTwilioVideoReconnect && !isHost) {
							onTwilioVideoReconnect();
						}
					});
				}, 1500);

				fetchAttendees();

				reinitiateEventInformation();
				isRipNShipQueueClosed(eventId).then((res) => {
					updateRipnShipFunctionalityOnRipNShipLineClosed(res.data);
				}).catch((err) => {
					setDisableRipNShipFunctionality(false);
				});
				setHubConnection(createHubConnection('event'));
			}
		};

		document.addEventListener('visibilitychange', onVisibilityChangeListener);
		return () => {
			setDisableRipNShipFunctionality(false);
			document.removeEventListener('visibilitychange', onVisibilityChangeListener);
		};
	}, [eventId]);

	useEffect(() => {
		if (!eventInformation) return;
		if (eventInformation.seller) {
			setStoreInfo(eventInformation.seller.account);
			setSellerImage(eventInformation.seller.sellerImage);
		}
	}, [eventInformation]);

	const loadRipNShipProducts = () => {
		getRipNShipActiveProducts(eventId).then((productsRes) => {
			setEventInformation({
				...eventInformation,
				products: productsRes.data
			});

			setIsEventInformationReinitiating(false);
		}).catch((err) => {
			console.error(err);
		});
	};

	useEffect(() => {
		if (!userWantsToKeepItemsWsMessage || !isSeller) return;
		if (userWantsToKeepItemsWsMessage.userId) {
			const tempBuyers = [...ripNShipActiveBuyersInLine].map((buyer) => {
				if (buyer.userId == userWantsToKeepItemsWsMessage.userId) {
					buyer.keepItems = userWantsToKeepItemsWsMessage.keepItems;
				}
				return buyer;
			});

			setRipNShipActiveBuyersInLine(tempBuyers);
		}
	}, [userWantsToKeepItemsWsMessage]);

	const onLineClosedWsMessage = (wsMessage) => {
		if (!wsMessage) return;
		updateRipnShipFunctionalityOnRipNShipLineClosed(wsMessage);
	};

	const remoteParticipants = participants.map((participant) => (
		<AudioParticipant key={participant.sid} participant={participant} sound={sound} />
	));

	const fetchAttendeesAndUpdateAudioParticipants = () => {
		fetchAttendees((newAttendees) => {
			if (!audioParticipants || !audioParticipants.length) return;
			setAudioParticipants((tempAudioParticipants) => tempAudioParticipants.filter((audioParticipant) => {
				const foundAttendee = newAttendees.find((attendee) => attendee.twilioParticipantId == audioParticipant.twilioParticipantId);
				return foundAttendee != null;
			}));
		});
	};
	useEffect(() => {
		if (!hubConnection) return;
		hubConnection
			.start()
			.then((res) => {
				hubConnection.invoke('JoinEvent', eventId);
			})
			.then((result) => {
				if (isSeller) {
					hubConnection.on('NotifyBuyerActiveLineWantsToKeepItems', (message) => {
						setUserWantsToKeepItemsWsMessage(message);
					});
				}

				hubConnection.on(NOTIFY_MIC_REQUEST, (message) => {
					playWebSocketNotificationAudio(NOTIFY_MIC_REQUEST);
					setNotifyMicRequestMessage(message);
					fetchAttendeesAndUpdateAudioParticipants();
				});

				hubConnection.on('NotifyExpiredMicAccess', (message) => {
					setExpiredMicAccessMessage(message);
					fetchAttendeesAndUpdateAudioParticipants();
				});

				hubConnection.on('NotifyCancelledMicRequest', (message) => {
					setNewCanceledMicRequestAccess(message);
					fetchAttendeesAndUpdateAudioParticipants();
				});

				hubConnection.on('NotifyMicRequestDecision', (message) => {
					setApprovedAudioParticipants(message);
					fetchAttendeesAndUpdateAudioParticipants();
				});

				hubConnection.on('NotifyMicRequestApprovedAndSubscribed', (message) => {
					fetchAttendeesAndUpdateAudioParticipants();
					setNewAudioParticipant(message);
				});

				hubConnection.on('NotifyForJoinedBuyer', (message) => {
					setNewBuyersWsMessage(message);
					fetchAttendees();
				});

				hubConnection.on('NotifyIfQueueIsClosed', (message) => {
					onLineClosedWsMessage(message);
				});

				hubConnection.on('NotifyForMutedMicStatus', (message) => {
					setNewMutedAudioParticipants(message);
				});

				hubConnection.on(MOTIFY_PRODUCT_TRIGGER, (message) => {
					playWebSocketNotificationAudio(MOTIFY_PRODUCT_TRIGGER);
					setWsTriggeredWsMessage(message);
				});

				hubConnection.on('NotifyForReservedProducts', (message) => {
					setQuantityUpdateWsMessage(message);
				});

				hubConnection.on('NotifyIfUserGotBanned', (message) => {
					setBannedUserWsMessage(message);
				});

				hubConnection.on('NotifyForInitiatedEndTurn', (message) => {
					setInitiatedEndTurnsWsMessage(message);
				});

				hubConnection.on('NotifyNewPositionsAfterUpdate', (message) => {
					setUpdatedBuyersQueueWsMessage(message);
				});

				hubConnection.on('NotifyEventStoppedStatus', (message) => {
					setEventStoppedWsMessage(message);
				});

				hubConnection.on('NotifyEventAttendeesChanged', (message) => {
					setAttendeesChangedWsMessage(message);
				});

				if (!isSeller) {
					hubConnection.on('NotifyForPositionSwap', (message) => {
						setSwapChangeWsMessage(message);
					});
				}

				hubConnection.on('NotifyUsersForBreakRandomization', (message) => {
					loadTeamsRandomization();
				});

				hubConnection.on(
					'NotifyNewPositionsAfterEndTurn',
					(message) => {
						setEndTurnWsMessage(message);
					}
				);

				hubConnection.on(
					'NotifyUsersToRefreshProducts',
					(message) => {
						loadRipNShipProducts();
					}
				);

				hubConnection.on(
					'NotifyNewPositionsAfterExitTurn',
					(message) => {
						setExitWsMessage(message);
					}
				);
			})
			.catch((err) => {
				const mute = err;
			});

		return () => {
			if (hubConnection && hubConnection.stop) {
				hubConnection.stop().catch((err) => {
					console.error(err);
				});
			}
		};
	}, [hubConnection]);

	const changeCamera = (camera) => {
		setDeviceId(camera.deviceId);
	};

	const reinitiateEventInformation = () => {
		setIsEventInformationReinitiating(true);

		getRipNShipEventDetail(eventId).then((res) => {
			const tempEventInformation = { ...eventInformation };
			tempEventInformation.buyersQueue = res.data.buyersQueue;
			tempEventInformation.buyersHistoryQueue = res.data.buyersHistoryQueue;
			tempEventInformation.products = res.data.products;

			setEventInformation(tempEventInformation);

			setIsEventInformationReinitiating(false);
		});
	};

	useEffect(() => {
		if (!hubConnection) {
			setHubConnection(createHubConnection('event'));
		}

		if (!room) return;
		if (!room.localParticipant) return;
		if (!isHost) {
			setLocalAudioTracks(room.localParticipant.audioTracks);
		}
		const oldTwilioParticipantId = twilioParticipantId;
		const newTwilioParticipantId = room.localParticipant.sid;

		setTwilioParticipantId(room.localParticipant.sid);

		// If we got here that means we have reconnected with turned on audio
		if (micAccessApprovedByBuyer != null && oldTwilioParticipantId != null) {
			if (room.localParticipant.audioTracks && room.localParticipant.audioTracks.size > 0) {
				approveMicRequestByBuyer(eventId, oldTwilioParticipantId, newTwilioParticipantId,
					room.localParticipant.audioTracks.entries().next().value[0])
					.catch((err) => {
						console.warn(err);
					});
			}
		}

		if (isHost) {
			updateRoomStatusToStarted(eventId);
		}

		const participantConnected = (participant) => {
			if (participant.identity === 'presenter') setHostParticipant(participant);

			participant.on('trackPublished', (publication) => {
				publication.on('subscribed', (track) => {
					console.log('subscribe', track);
				});

				publication.on('subscriptionFailed', (track) => {
					console.error('SUBSCRIPTION FAILED', track);
				});
			});

			participant.on('trackSubscribed', (track, publication) => {
				setNewTrack(track);
			});

			participant.on('trackSubscriptionFailed', (error, pub) => {
				console.error('err', error);
			});

			setParticipants((prevParticipants) => [
				...prevParticipants,
				participant
			]);
		};

		const participantDisconnected = (participant) => {
			setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
		};

		room.on('participantConnected', participantConnected);
		room.on('participantDisconnected', participantDisconnected);
		room.participants.forEach(participantConnected);

		roomRef.current = room;
		return () => {
			room.off('participantConnected', participantConnected);
			room.off('participantDisconnected', participantDisconnected);
			if (room.disconnect) {
				room.disconnect();
			}
			setRoom(null);
		};
	}, [room]);

	const handleExitQueue = (userId = undefined, exitModal = false) => {
		if (exitModal == true) {
			exitQueuePosition(eventId, userId)
				.then((res) => {

				})
				.catch((err) => { const mute = err; })
				.finally((_) => {
					if (exitModal) {
						setShowBuyerExitConfirmationModal(false);
						setShowSellerExitConfirmationModal(false);
					}
					setJoinedQueue(false);
				});
		}
	};

	useEffect(() => {
		if (!purchasedProductsUserId) return;
		setShowPurchasedItemsModal(true);
	}, [purchasedProductsUserId]);

	useEffect(() => {
		if (!showPurchasedItemsModal) {
			setPurchasedProductsUserId(null);
		}
	}, [showPurchasedItemsModal]);

	const openPurchasedItemsModalByQueueOrderId = (queueOrderId) => {
		setQueueOrderId(queueOrderId);
		setShowPurchasedItemsModal(true);
	};

	useEffect(() => {
		if (reversedCamera == -1) return;

		navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
			const devices = mediaDevices.filter((device) => device.kind == 'videoinput');
			if (devices.length > 1) {
				setDeviceId(devices[reversedCamera].deviceId);
			}
		});
	}, [reversedCamera]);

	useEffect(() => {
		if (isMobile) {
			document.body.style.overflowY = 'hidden';
		}
	}, [isMobile]);

	const nextUpOrEndTurn = () => {
		endQueueActiveTurn(eventInformation.buyersQueue[0].position, eventInformation.buyersQueue[0].userId);
	};

	return (
		<ProductsProvider>
			<div className="container-fluid md:block flex justify-center mx-auto px-1 md:px-12" id="video-page">
				<div
					className={`flex flex-wrap -mx-1 lg:-mx-4 ${resize === true ? 'justify-center' : ''
					}`}
				>
					<div className={`my-1 px-1 w-full lg:my-4 lg:px-4 ${isTruthy(isAndroid) && 'flex justify-center'}`}>
						<RoomOptions
							eventId={eventId}
							showModal={showModal}
							setShowModal={setShowModal}
							onStartButtonClicked={onStartClicked}
							onPauseButtonClicked={onPauseClicked}
							onStopButtonClicked={onStopClicked}
							isPaused={isPaused}
						/>
						{selectedProduct && !showThankYou && (
							<ProductModalEventQueue
								showModal={showProductModal}
								setShowModal={setShowProductModal}
								buyNowEnabled={!disableRipNShipFunctionality}
								onBuyNow={(eventProduct, image, quantity) => {
									const existingEventProduct = cartItems.find((f) => f.id == eventProduct.eventProductId);
									if (existingEventProduct) {
										changeProductQuantityToCart(existingEventProduct, quantity);
									} else {
										addProductToCart(eventProduct, image, quantity);
									}
									setShowProductModal(false);
								}}
								eventProduct={selectedProduct}
								trigger={trigger}
								isLiveEvent
								authenticated={authStatus}
							/>
						)}

						{storeInfo && !showThankYou && <StoreModal isOpen={isStoreModalOpen} setIsOpen={setIsStoreModalOpen} store={storeInfo} image={sellerImage} />}
						{(purchasedProductsUserId || queueOrderId) && eventId && (
							<PurchasedProductsModal
								userId={purchasedProductsUserId}
								eventId={eventId}
								showModal={showPurchasedItemsModal}
								setShowModal={setShowPurchasedItemsModal}
								queueOrderId={queueOrderId}
								setQueueOrderId={setQueueOrderId}
							/>
						)}

						{isSeller && (
							<>
								<EventProductsRemove />
								<AddedProductModal />
							</>
						)}
						{isRipNShip && (
							<>
								<RipNShipQueueModal
									showModal={showRipNShipModal}
									setShowModal={setShowRipNShipModal}
									isSeller={isSeller}
									buyers={eventInformation.buyersQueue}
									joinQueue={joinQueue}
									isEventInformationReinitiating={isEventInformationReinitiating}
									setBuyerExitId={setBuyerExitId}
									onBuyerRemove={(buyer) => {
										setBuyerExitId(buyer);
										setShowRipNShipModal(false);
										hasUserPurchasedItemsInEvent(eventId, buyer).then((res) => {
											if (res.data == true) {
												setShowSellerExitConfirmationModal(true);
											} else {
												handleExitQueue(buyer, true);
											}
										});
									}}
									onExitLine={() => {
										setShowRipNShipModal(false);
										const buyer = eventInformation.buyersQueue.find((b) => b.userId == getCurrentUser().id);
										if (buyer.purchasedItem) {
											showToastError('If you no longer want to keep these items and stay in the line, please request a refund');
										} else {
											hasUserPurchasedItemsInEvent(eventId, getCurrentUser().id).then((res) => {
												if (res.data == true) {
													setShowBuyerExitConfirmationModal(true);
												} else {
													handleExitQueue(getCurrentUser().id, true);
												}
											});
										}
									}}
									lineClosed={disableRipNShipFunctionality}
									initiateEndTurn={initiateEndTurn}
									onCloseLine={() => {
										setShowRipNShipModal(false);
										setShowEndEventModal(false);
										setShowCloseLineModal(true);
									}}
									onOpenLine={() => {
										setShowRipNShipModal(false);
										setShowEndEventModal(false);
										openRipNShipQueueLine(eventId).catch((err) => {
											console.error(err);
										});
									}}
									isInLine={isInRipnShipQueueActiveLine}
									setIsInLine={setIsInRipnShipQueueActiveLine}
									setHasPurchasedItem={setBuyerHasPurchasedItems}
								/>
								<CloseLineModal
									isSeller={isSeller}
									eventId={eventId}
									showModal={showCloseLineModal}
									setShowModal={setShowCloseLineModal}
									showEndEventModal={showEndEventModal}
									setShowEndEventModal={setShowEndEventModal}
									timerInSeconds={(closeLineTime && closeLineTime.timerInSeconds) ? closeLineTime.timerInSeconds : null}
									countdownTimerStartTime={(closeLineTime && closeLineTime.countdownTimerStartTime) ? closeLineTime.countdownTimerStartTime : null}
									buyers={ripNShipActiveBuyersInLine}
									timers={ripNShipTimers}
									futureEventDate={futureEventDate}
									onCancel={onLogoExitClosed}
									onEndEvent={(skipConfirmation = false) => {
										setShowCloseLineModal(false);
										exitEventRoom(true, skipConfirmation);
									}}
									onLineClosed={onRipNShipLineClosed}
								/>
							</>
						)}

						<CameraChangeModal
							showModal={showCameraChange}
							changeCamera={changeCamera}
							setShowModal={setShowCameraChange}
						/>

						{eventInformation && eventInformation.buyersQueue && (
							<BuyerRipNShipExitConfirmation
								onExitLine={() => {
								}}
								showModal={showBuyerExitConfirmationModal}
								setShowModal={setShowBuyerExitConfirmationModal}
								buyers={eventInformation.buyersQueue}
								isEventInformationReinitiating={isEventInformationReinitiating}
							/>
						)}

						{eventInformation && eventInformation.buyersQueue && (
							<SellerRipNShipExitConfirmation
								onExitLine={() => {
									if (buyerExitId) {
										handleExitQueue(buyerExitId, true);
									}
								}}
								showModal={showSellerExitConfirmationModal}
								buyerExitId={buyerExitId}
								setShowModal={setShowSellerExitConfirmationModal}
								buyers={eventInformation.buyersQueue}
								isEventInformationReinitiating={isEventInformationReinitiating}
							/>
						)}

						<div className={`${landscapeMode ? 'flex' : 'flex'} ${isTablet && 'justify-center'}`}>
							<div
								className={`${isMobile ? 'w-full flex justify-center' : 'w-96'}  bg-white border dark:border-black px-5 py-2 dark:bg-darkGray-50
                        ${!openQueueContainer ? 'hidden' : 'block'}`}
								style={{ borderRadius: '25px' }}
							>
								<EventQueue
									isHost={isSeller}
									setSearchInputActive={setSearchInputActive}
									ownerNickname={ownerNickname}
									eventInformation={eventInformation}
									setEventInformation={setEventInformation}
									showProductModal={showProductModal}
									setShowProductModal={setShowProductModal}
									selectedProduct={selectedProduct}
									setSelectedProduct={setSelectedProduct}
									isMobile={isMobile}
									disableRipNShipFunctionality={disableRipNShipFunctionality}
									reinitiateEventInformation={reinitiateEventInformation}
									eventId={eventId}
									setPurchasedItemsBuyer={setPurchasedItemsBuyer}
									setShowPurchasedItemsModal={setShowPurchasedItemsModal}
									changeWsMessage={changeWsMessage}
									initiatedEndTurnsWsMessage={initiatedEndTurnsWsMessage}
									setInitiatedEndTurnsWsMessage={setInitiatedEndTurnsWsMessage}
									endTurnWsMessage={endTurnWsMessage}
									exitWsMessage={exitWsMessage}
									newBuyersWsMessage={newBuyersWsMessage}
									isSeller={isSeller}
									setPurchasedProductsUserId={setPurchasedProductsUserId}
									updatedBuyersQueueWsMessage={updatedBuyersQueueWsMessage}
									joinQueue={joinQueue}
									joined={joinedQueue}
									setJoined={setJoinedQueue}
									onEndTurn={() => endQueueActiveTurn(eventInformation.buyersQueue[0].position, eventInformation.buyersQueue[0].userId)}
									openPurchasedItemsModalByQueueOrderId={openPurchasedItemsModalByQueueOrderId}
									setShowQueueModal={setShowRipNShipModal}
									showSeller={() => setIsStoreModalOpen(true)}
									timers={ripNShipTimers}
									setTimers={setRipNShipTimers}
									buyers={ripNShipActiveBuyersInLine}
									setBuyers={setRipNShipActiveBuyersInLine}
									showAddToCartPopup={showAddToCartPopup}
									onProductNamePopupClose={onProductNamePopupClose}
									addToCartPopupFadingOut={addToCartPopupFadingOut}
									eventGeneralDetails={{ eventName, eventDetails }}
									fetchAttendeesAndUpdateAudioParticipants={fetchAttendeesAndUpdateAudioParticipants}
								/>
							</div>

							<article
								className={`overflow-y-hidden md:overflow-y-auto rounded-lg container-fluid pb-3 ${mobileSize && resize && ('w-screen h-screen')} ${openQueueContainer && (mobileSize || isTablet) ? 'hidden' : 'block'}`}
							>
								<div
									className={`grid grid-cols-${((isMobile && !isTablet) || (isTablet && !landscapeMode) || resize === true) ? '1' : '3'}`}
								>
									<div
										id="video-ref-container"
										className="row-span-1 md:col-span-2"
									>
										{room ? (
											isHost === true ? (
												<div className="local-participant">
													<Participant
														key={room.localParticipant.sid}
														participant={
															room.localParticipant
														}
														isLocal
														arrowButtonContainerRef={arrowButtonContainerRef}
														isPaused={isPaused}
														resize={resize}
														isSeller={isSeller}
														eventNamePopupRef={eventNamePopupRef}
														saveTrack={saveTrack}
														reversedCamera={reversedCamera}
														setResize={setResize}
														isStopped={isStopped}
														eventName={eventName}
														nickname={ownerNickname}
														deviceId={deviceId}
														arrowButtonRef={arrowButtonRef}
														newTrack={newTrack}
														showEventNameInChatContainer={showEventNameInChatContainer}
														setShowEventNameInChatContainer={setShowEventNameInChatContainer}
														setHasRaisedHand={setHasRaisedHand}
														optionsOnClick={() => {
															setShowModal(true);
														}}
														changeCameraOnClick={(
															video,
															tracks
														) => {
															if (reversedCamera == -1) {
																setReversedCamera(1);
															} else if (reversedCamera == 0) {
																setReversedCamera(1);
															} else if (reversedCamera == 1) {
																setReversedCamera(0);
															}
														}}
														mobileSize={mobileSize}
														eventStartDate={formatJsonDateToProperDateObject(
															eventStartDate
														)}
														setEventStartDate={setEventStartDate}
														exitEventRoom={onEventEndingMethod}
														setVideoHeight={setVideoHeight}
														eventId={eventId}
														sound={sound}
														setSound={setSound}
														showEndTurn={eventInformation && eventInformation.buyersQueue && eventInformation.buyersQueue.length && eventInformation.buyersQueue[0].position}
														onEndTurn={nextUpOrEndTurn}
														openQueueContainer={openQueueContainer}
														setOpenQueueContainer={setOpenQueueContainer}
														updatePopupsStatus={updatePopupsStatus}
														onOpenQueueContainer={onOpenQueueContainer}
														setLeftArrowButtonPosition={setLeftArrowButtonPosition}
														setTopArrowButtonPosition={setTopArrowButtonPosition}
														onOrientationChange={onOrientationChange}
													/>
												</div>
											) : hostParticipant
                                            && hostParticipant.sid ? (
													<div className="local-participant w-full h-full">
														<Participant
															key={hostParticipant.sid}
															participant={
																hostParticipant
															}
															arrowButtonContainerRef={arrowButtonContainerRef}
															showThankYou={showThankYou}
															isSeller={isSeller}
															eventNamePopupRef={eventNamePopupRef}
															authStatus={authStatus}
															onOpenQueueContainer={onOpenQueueContainer}
															arrowButtonRef={arrowButtonRef}
															isPaused={isPaused}
															resize={resize}
															saveTrack={saveTrack}
															localParticipant={
																room.localParticipant
															}
															setResize={setResize}
															eventName={eventName}
															sound={sound}
															setSound={setSound}
															nickname={ownerNickname}
															showEventNameInChatContainer={showEventNameInChatContainer}
															setShowEventNameInChatContainer={setShowEventNameInChatContainer}
															mobileSize={mobileSize}
															newTrack={newTrack}
															eventStartDate={formatJsonDateToProperDateObject(
																eventStartDate
															)}
															setHasRaisedHand={setHasRaisedHand}
															exitEventRoom={exitEventRoom}
															setVideoHeight={setVideoHeight}
															eventId={eventId}
															showEndTurn={isSeller && eventInformation && eventInformation.buyersQueue && eventInformation.buyersQueue.length && eventInformation.buyersQueue[0].position}
															onEndTurn={nextUpOrEndTurn}
															openQueueContainer={openQueueContainer}
															setOpenQueueContainer={setOpenQueueContainer}
															updatePopupsStatus={updatePopupsStatus}
															setLeftArrowButtonPosition={setLeftArrowButtonPosition}
															setTopArrowButtonPosition={setTopArrowButtonPosition}
															onOrientationChange={onOrientationChange}
														/>
														{/* )} */}
													</div>
												) : null
										) : (
											''
										)}

										{showThankYou === true ? null : <></>}
									</div>
									{
										!resize && (
											<div className={`md:block ${removeChildrenContainerHeight ? '' : 'h-96'} overflow-y-hidden md:h-auto rounded-md flex justify-center md:px-0`}>
												{children}
											</div>
										)
									}

								</div>
							</article>
						</div>
					</div>
				</div>
			</div>
		</ProductsProvider>
	);
};

export default VideoStream;
