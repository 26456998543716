/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import firebase from 'gatsby-plugin-firebase';
import moment from 'moment';
import { isMobile, isTablet } from 'react-device-detect';
import * as mime from 'mime-types';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import ConversationReactions from './conversation-reactions';
import ConversationMessageOptions from './conversation-message-options';
import ConversationHeader from './conversation-header';
import ConversationSidebar from './conversation-sidebar';
import ConversationTypeMessage from './conversation-type-message';
import CreateConversation from './create-conversation';
import ConversationMessage from './messages/conversation-message';
import ConversationMessages from './messages/conversation-messages';
import { base64ToBlob } from '../../utils/blob';
import {
	getAuthenticatedUserId,
	getUsersByIds,
	uploadMessageAttachment,
	getFileBytes
} from '../../api/auth.request';
import { formatUTCDateToLocal } from '../../utils/formatter';
import { defaultToastError } from '../../utils/toast-error';
import { defaultToastWarning } from '../../utils/toast-warning';
import { getError } from '../../api/referenceData.request';
import ConversationParticipants from './conversation-participants';
import {
	CONVERSATION_COULD_NOT_BE_UPDATED,
	CONVERSATION_MENTION_COULD_NOT_BE_CREATED,
	CONVERSATION_MESSAGE_COULD_NOT_BE_SENT
} from '../../utils/errorCodes';
import { setAuthenticatedUserId } from '../../state/action';

// TODO: Add prop types
const Conversation = ({
	showConversations,
	setShowConversations,
	startedConversation,
	hideSidebar = false,
	hideHeader = false,
	removeHiddenOverflows = false,
	absoluteEditorPosition = false,
	removeListsFromToolbar = false,
	userId,
	dispatch,
	disableMessaging = false,
	reduceReplyMessageSize = false,
	additionalStyles = {},
	prePopulatedMessages = [],
	hasPrepopulatedMessages = false,
	showScreenName = false,
	hideAttachmentsButton = false,
	truncateName = false,
	disableReplying = false,
	authenticated = true,
	removeDayDivider = false,
	useInlineInputEditor = false,
	onRedirectToSignIn,
	onMessagesLoaded,
	enabledChatInput = true,
	insideLiveEvent = false,
	limitedChatMessages = false,
	chatLimits = 0,
	removeToastContainer = false,
	enableMentions = true,
	onPurchasedItemsHyperlinkClicked,
	onRandomizationHyperlinkClicked,
	children = null
}) => {
	const defaultHeaderTitle =		'Select a conversation or direct message to see all the posts';
	const [
		showConversationTypeMessage,
		setShowConversationTypeMessage
	] = useState(false);
	const messagesDivRef = useRef();
	const [headerTitle, setHeaderTitle] = useState(defaultHeaderTitle);
	const [headerDescription, setHeaderDescription] = useState();
	const [selectedConversation, setSelectedConversation] = useState(null);
	const [conversation, setConversation] = useState({});
	const [conversationShown, setConversationShown] = useState(false);
	const [messageToReply, setMessageToReply] = useState();
	const [content, setContent] = useState(null);
	const [showSidebar, setShowSidebar] = useState(true);
	const [reload, setReload] = useState(false);
	const [sendingMessage, setSendingMessage] = useState(false);
	const [conversations, setConversations] = useState([]);
	const [showParticipants, setShowParticipants] = useState(false);
	const [participants, setParticipants] = useState([]);
	const [participantsForMention, setParticipantsForMention] = useState([]);
	const [isConnectedToInternet, setIsConnectedToInternet] = useState(true);
	const [inputFocused, setInputFocused] = useState(false);
	const [showModalForNewMessages, setShowModalForNewMessages] = useState(false);
	const [scrollToBottom, setScrollToBottom] = useState(false);
	const [numberOfNewMessages, setNumberOfNewMessages] = useState(1);
	const [numberOfMessagesLatestTime, setNumberOfMessagesLatestTime] = useState(
		0
	);
	const [listenToNewMessages, setListenToNewMessages] = useState(false);
	const [conversationMessages, setConversationMessages] = useState([]);
	const [reactions, setReactions] = useState([]);
	const [showReactions, setShowReactions] = useState();
	const [showMessageOptions, setShowMessageOptions] = useState();
	const [selectedForEdit, setSelectedForEdit] = useState(null);
	const [selectedMessage, setSelectedMessage] = useState({});

	const isScrolledToBottom =		messagesDivRef
		&& messagesDivRef.current
		&& messagesDivRef.current.scrollHeight
			- messagesDivRef.current.scrollTop
			- messagesDivRef.current.clientHeight
			<= 20;

	useEffect(() => {
		if (!startedConversation) return;
		setShowConversationTypeMessage(true);
		setSelectedConversation(startedConversation);
	}, [startedConversation]);

	const showInternetConnectionError = () => {
		toast.error('No intenet connection', {
			position: 'top-center',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	};

	const groupConversationExists = async (hostId, selectedParticipants) => {
		const myGroupConversations = conversations.filter(
			(c) => c.is_group && c.user_id == hostId && !c.started_by_event
		);

		let conversationExists = false;
		const tempConversationParticipants = [];
		const conversationParticipants = [];
		const conversationParticipantRef = firebase
			.database()
			.ref('conversation_participant');

		tempConversationParticipants.push(conversationParticipantRef.once('value'));

		const results = await Promise.all(tempConversationParticipants);
		results.forEach((snapshot) => {
			snapshot.forEach((childSnapshot) => {
				const conversationParticipant = childSnapshot.val();
				conversationParticipants.push(conversationParticipant);
			});
		});

		myGroupConversations.forEach((item) => {
			const currentParticipants = conversationParticipants.filter(
				(p) => p.conversation_id == item.id && !p.removed_from_chat
			);

			if (
				currentParticipants
				&& currentParticipants.length == selectedParticipants.length
			) {
				const containsAll = currentParticipants.every((currentParticipant) => selectedParticipants.find((e) => e.id == currentParticipant.user_id));
				if (containsAll) {
					conversationExists = true;
				}
			}
		});

		if (conversationExists) return true;

		const selectedParticipantsConversations = conversations.filter(
			(c) => c.is_group
				&& !c.started_by_event
				&& selectedParticipants.find((e) => e.id == c.user_id)
		);

		selectedParticipants.push({
			id: hostId
		});

		selectedParticipantsConversations.forEach((item) => {
			const filteredParticipants = selectedParticipants.filter(
				(p) => p.id != item.user_id
			);

			const currentParticipants = conversationParticipants.filter(
				(p) => p.conversation_id == item.id && !p.removed_from_chat
			);

			if (
				currentParticipants
				&& currentParticipants.length == filteredParticipants.length
			) {
				const containsAll = currentParticipants.every((currentParticipant) => filteredParticipants.find((e) => e.id == currentParticipant.user_id));
				if (containsAll) {
					conversationExists = true;
				}
			}
		});

		return conversationExists;
	};

	const directConversationExists = async (hostId, participantId) => {
		const foundConversation = conversations.find(
			(c) => !c.is_group
				&& ((c.user_id == hostId && c.participant_user_id == participantId)
					|| (c.participant_user_id == hostId && c.user_id == participantId))
		);
		let removedFromChat = false;
		if (foundConversation) {
			const tempConversationParticipants = [];
			const conversationParticipantRef = firebase
				.database()
				.ref('conversation_participant');

			tempConversationParticipants.push(
				conversationParticipantRef.once('value')
			);
			const results = await Promise.all(tempConversationParticipants);
			results.forEach((snapshot) => {
				snapshot.forEach((childSnapshot) => {
					const conversationParticipant = childSnapshot.val();
					if (
						foundConversation.id == conversationParticipant.conversation_id
						&& (conversationParticipant.user_id == participantId
							|| conversationParticipant.user_id == hostId)
					) {
						if (conversationParticipant.removed_from_chat) {
							removedFromChat = true;
						}
					}
				});
			});
		}

		if (removedFromChat) return false;

		return foundConversation != null;
	};

	const setConversationMessagesInfo = (tempConversation, users) => tempConversation.map((c) => {
		const user = users.find((u) => u.user_id == c.user_id);

		if (user) {
			c.name = showScreenName ? user.nickname : user.name;
			c.image = user.image;
		}
		if (hasPrepopulatedMessages) {
			c.sent_date = formatUTCDateToLocal(
				c.date_created,
				'MM/DD/YYYY HH:mm:ss'
			);
			c.sent_date_only = moment(c.date_created).format('LL');
		} else {
			c.send_date_server = c.sent_date;
			c.sent_date = formatUTCDateToLocal(c.sent_date, 'MM/DD/YYYY HH:mm:ss');
			c.sent_date_only = moment(c.sent_date).format('LL');
		}

		return c;
	});

	const userSawTheMention = (messageMentions) => {
		messageMentions.forEach((messageMention) => {
			firebase
				.database()
				.ref(`message_mention/${messageMention.id}`)
				.update({
					seen: true
				})
				.then(() => {
					setReload(!reload);
				});
		});
	};

	const updateLastSeenDate = (conversationToUpdate) => {
		if (conversationToUpdate && conversationToUpdate.participant_id) {
			firebase
				.database()
				.ref(`conversation_participant/${conversationToUpdate.participant_id}`)
				.update({
					last_seen_date: moment().utc().toDate().toJSON()
				})
				.then(() => {
					setReload(!reload);
				});
		} else {
			firebase
				.database()
				.ref(`conversation/${conversationToUpdate.id}`)
				.update({
					last_seen_date: moment().utc().toDate().toJSON()
				})
				.then(() => {
					setReload(!reload);
				});
		}
	};

	const initializeConversationParticipants = async (
		conversationInfo,
		conversationParticipants,
		addMentionParticipants = false
	) => {
		setParticipantsForMention([]);

		let conversationUserIdsToSearch;
		if (conversationParticipants && conversationParticipants.length) {
			conversationUserIdsToSearch = conversationParticipants
				.map((c) => c.user_id)
				.concat(conversationInfo.user_id);
		} else {
			conversationUserIdsToSearch = [conversationInfo.user_id];
		}
		const fetchedParticipantsWithoutUserInfoRes = await getUsersByIds(
			conversationUserIdsToSearch
		);
		let results = [];
		if (
			fetchedParticipantsWithoutUserInfoRes
			&& fetchedParticipantsWithoutUserInfoRes.data
			&& fetchedParticipantsWithoutUserInfoRes.data.length
		) {
			results = fetchedParticipantsWithoutUserInfoRes.data.map((userRes) => {
				const foundParticipant = conversationParticipants.find(
					(u) => u.user_id === userRes.id
				);
				if (!foundParticipant) {
					return {
						conversation_id: conversationInfo.id,
						show_conversation: true,
						user_id: userRes.id,
						live_event: true,
						name: userRes.name,
						nickname: userRes.nickname,
						image: userRes.image
					};
				}

				const newParticipant = {
					...foundParticipant,
					name: userRes.name,
					nickname: userRes.nickname,
					image: userRes.image
				};
				if (userRes.id == conversationInfo.user_id) {
					newParticipant.owner = true;
				}
				return newParticipant;
			});
		}
		let tempParticipantsForMention = [];

		if (addMentionParticipants && results && results.length) {
			tempParticipantsForMention = results.map((user) => ({
				id: user.user_id,
				value: user.name
			}));
		}

		if (results && results.length) {
			results = results.filter((r) => r != null);
		}
		setParticipants(results);
		if (addMentionParticipants) {
			setParticipantsForMention(tempParticipantsForMention);
		}
		return results;
	};

	const showError = (errorCode) => {
		getError(errorCode)
			.then((res) => {
				defaultToastError(res.data);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const removeParticipant = (participantId, index) => {
		if (!isConnectedToInternet) {
			showInternetConnectionError();
			return;
		}

		firebase
			.database()
			.ref(`conversation_participant/${participantId}`)
			.update({
				show_conversation: false,
				removed_from_chat: true
			})
			.then(() => {
				const changedParticipants = [...participants];
				changedParticipants[index].show_conversation = false;
				setParticipants(changedParticipants);
			});
	};

	const loadParticipantsFromFirebaseSnapshot = async (
		conversationId,
		snapshot
	) => {
		let currentParticipantId = null;
		if (!conversationId) return;
		if (!hideSidebar) {
			conversationId = sessionStorage.getItem('current-chat-id');
		}
		const tempParticipants = [];
		snapshot.forEach((childrenSnapshot) => {
			const tempParticipantObj = childrenSnapshot.val();
			tempParticipantObj.id = childrenSnapshot.key;
			if (tempParticipantObj.conversation_id == conversationId) {
				if (tempParticipantObj.user_id == userId) {
					currentParticipantId = tempParticipantObj.id;
				}
				tempParticipants.push(tempParticipantObj);
			}
		});

		let initialParticipants = [];
		if (tempParticipants && tempParticipants.length) {
			initialParticipants = await initializeConversationParticipants(
				selectedConversation,
				tempParticipants,
				!selectedConversation.live_event
			);
		}
		return { currentParticipantId, initialParticipants };
	};

	const initializeConversationMessages = async (
		conversationInfo,
		messages,
		users
	) => {
		const scrolledToBottom =			messagesDivRef
			&& messagesDivRef.current
			&& messagesDivRef.current.scrollHeight
				- messagesDivRef.current.scrollTop
				- messagesDivRef.current.clientHeight
				<= 20;
		if (
			!scrolledToBottom
			&& messages
			&& (conversationInfo
				|| (conversationInfo.messages
					&& conversationInfo.messages.length < messages.length))
		) {
			if (messages[messages.length - 1].user_id == userId) {
				setShowModalForNewMessages(false);
				setScrollToBottom(!scrollToBottom);
			} else {
				const newLengthOfMessages = messages.length;
				if (
					numberOfMessagesLatestTime > 0
					&& newLengthOfMessages !== numberOfMessagesLatestTime
				) {
					setNumberOfNewMessages(
						newLengthOfMessages - numberOfMessagesLatestTime
					);
				}
				setShowModalForNewMessages(true);
			}
		}
		if (scrolledToBottom) {
			setShowModalForNewMessages(false);
		}

		if (!messages || !messages.length) {
			setConversationMessages([]);
			return;
		}

		if (!conversationInfo || (conversationInfo.live_event && !insideLiveEvent)) return;

		const tempConversationMessages = setConversationMessagesInfo(
			messages,
			users
		);

		setConversationMessages(tempConversationMessages);

		setListenToNewMessages(true);
	};

	useEffect(() => {
		if (!hasPrepopulatedMessages || !selectedConversation) return;
		// setConversationWithoutUserInfo(prePopulatedMessages);
		if (!prePopulatedMessages) prePopulatedMessages = [];
		initializeConversationMessages(
			selectedConversation,
			prePopulatedMessages,
			participants
		);
	}, [hasPrepopulatedMessages, JSON.stringify(prePopulatedMessages)]);

	const loadConversationMessagesFromFirebaseSnapshot = async (
		conversationId,
		snapshot
	) => {
		let users = sessionStorage.getItem('current-chat-participants');
		if (users) {
			users = JSON.parse(users);
		}
		if (!conversationId) return;
		const tempConversation = [];
		snapshot.forEach((childrenSnapshot) => {
			const tempConversationObj = childrenSnapshot.val();
			tempConversationObj.id = childrenSnapshot.key;
			if (insideLiveEvent && chatLimits) {
				tempConversation.push(tempConversationObj);
			} else if (
				tempConversationObj.conversation_id == selectedConversation.id
			) {
				tempConversation.push(tempConversationObj);
			}
		});

		if (tempConversation && tempConversation.length) {
			await initializeConversationMessages(
				selectedConversation,
				tempConversation,
				users
			);
			setConversation(selectedConversation);
		} else if (
			selectedConversation
			&& (selectedConversation.event_id
				|| selectedConversation.refund
				|| selectedConversation.live_event)
		) {
			setShowSidebar(false);
			setConversation(selectedConversation);
			setListenToNewMessages(true);
		}

		if (onMessagesLoaded) {
			setTimeout(() => {
				setScrollToBottom(!scrollToBottom);
				onMessagesLoaded();
			}, 3000);
		}
	};

	// eslint-disable-next-line no-unused-vars
	const attachMessage = (message, messageId) => {
		const isInMessagesList =			conversationMessages
			&& conversationMessages.length
			&& conversationMessages.find((f) => f.id === messageId);
		if (isInMessagesList) return;
		message.id = messageId;
		if (
			!isScrolledToBottom
			&& conversationMessages
			&& conversationMessages.length
			&& selectedConversation
		) {
			if (message.user_id == userId) {
				setShowModalForNewMessages(false);
				setScrollToBottom(!scrollToBottom);
			} else {
				const newLengthOfMessages = conversationMessages.length + 1;
				if (
					numberOfMessagesLatestTime > 0
					&& newLengthOfMessages !== numberOfMessagesLatestTime
				) {
					setNumberOfNewMessages(
						newLengthOfMessages - numberOfMessagesLatestTime
					);
				}
				setShowModalForNewMessages(true);
			}
		} else if (isScrolledToBottom) {
			setShowModalForNewMessages(false);
		}

		const conversationMessage = setConversationMessagesInfo(
			[message],
			participants
		);
		setConversationMessages((c) => {
			if (!c || !c.length) {
				return conversationMessage;
			}
			return [...c].concat(conversationMessage);
		});
	};

	const attachParticipants = (newParticipants) => {
		let newParticipantsWithUserInfo = [];
		getUsersByIds(newParticipants.map((m) => m.user_id))
			.then((fetchedParticipantsRes) => {
				if (
					fetchedParticipantsRes
					&& fetchedParticipantsRes.data
					&& fetchedParticipantsRes.data.length
				) {
					newParticipantsWithUserInfo = fetchedParticipantsRes.data.map(
						(userRes) => {
							const participant = newParticipants.find(
								(f) => f.user_id == userRes.user_id
							);
							if (!participant) return null;
							const newParticipant = {
								...participant,
								name: userRes.name,
								nickname: userRes.nickname,
								image: userRes.image
							};
							if (userRes.user_id == selectedConversation.user_id) {
								newParticipant.owner = true;
							}
							return newParticipant;
						}
					);

					setParticipants((p) => [...p].concat(newParticipantsWithUserInfo));
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		sessionStorage.setItem(
			'current-chat-participants',
			JSON.stringify(participants)
		);
	}, [participants]);

	useEffect(() => {
		if (!listenToNewMessages || hasPrepopulatedMessages) return;
		let conversationId = null;
		if (selectedConversation && hideSidebar && !insideLiveEvent) {
			conversationId = selectedConversation.id;
		} else {
			conversationId = sessionStorage.getItem('current-chat-id');
		}

		const messagesRef =			insideLiveEvent && limitedChatMessages && chatLimits && chatLimits > 0
			? firebase
				.database()
				.ref('message')
				.orderByChild('conversation_id')
				.equalTo(conversationId)
				.limitToLast(chatLimits)
			: firebase.database().ref('message');

		const participantsRef = insideLiveEvent
			? firebase
				.database()
				.ref('conversation_participant')
				.orderByChild('conversation_id')
				.equalTo(conversationId)
			: firebase.database().ref('conversation_participant');

		participantsRef.on('value', (snapshot) => {
			const tempParticipants = [...participants];
			const participantsWithoutInfo = [];
			snapshot.forEach((childSnapshot) => {
				if (!tempParticipants.find((f) => f.id == childSnapshot.key)) {
					const participantObj = childSnapshot.val();
					participantObj.id = childSnapshot.key;
					participantsWithoutInfo.push(participantObj);
				}
			});

			if (participantsWithoutInfo && participantsWithoutInfo.length) {
				attachParticipants(participantsWithoutInfo);
			}
		});
		messagesRef.on('value', (snapshot) => {
			if (!hideSidebar) {
				conversationId = sessionStorage.getItem('current-chat-id');
			}
			if (!conversationId) return;
			loadConversationMessagesFromFirebaseSnapshot(conversationId, snapshot);
		});
	}, [userId, listenToNewMessages]);

	useEffect(() => {
		if (!selectedConversation) return;
		setShowConversationTypeMessage(true);
		sessionStorage.setItem('current-chat-id', selectedConversation.id);
		let conversationId = null;
		if (selectedConversation && hideSidebar && !insideLiveEvent) {
			conversationId = selectedConversation.id;
		} else {
			conversationId = sessionStorage.getItem('current-chat-id');
		}

		const initializeConversationInfo = async () => {
			const participantsRef = insideLiveEvent
				? firebase
					.database()
					.ref('conversation_participant')
					.orderByChild('conversation_id')
					.equalTo(conversationId)
				: firebase.database().ref('conversation_participant');

			const participantsSnapshot = await participantsRef.once('value');

			const {
				currentParticipantId,
				initialParticipants
			} = await loadParticipantsFromFirebaseSnapshot(
				conversationId,
				participantsSnapshot
			);

			if (currentParticipantId && !selectedConversation.live_event) {
				firebase
					.database()
					.ref('conversation_participant')
					.child(currentParticipantId)
					.on('value', (snapshot) => {
						if (
							snapshot.val().show_conversation == false
							&& !snapshot.val().live_event
						) {
							setShowConversationTypeMessage(false);
							setConversationShown(false);
							setSelectedConversation(null);
							const message = "You've been removed from the conversation";
							setHeaderTitle(message);
							setHeaderDescription();
							defaultToastWarning(message);
							setReload(true);
						}
					});
			}

			if (hasPrepopulatedMessages) {
				setConversation(selectedConversation);
				setShowSidebar(false);
			} else {
				if (selectedConversation && hideSidebar && !insideLiveEvent) {
					conversationId = selectedConversation.id;
				} else {
					conversationId = sessionStorage.getItem('current-chat-id');
				}
				const messagesRef =					limitedChatMessages && chatLimits && chatLimits > 0
					? firebase
						.database()
						.ref('message')
						.orderByChild('conversation_id')
						.equalTo(conversationId)
						.limitToLast(chatLimits)
					: firebase.database().ref('message');
				const messagesSnapshot = await messagesRef.once('value');
				await loadConversationMessagesFromFirebaseSnapshot(
					conversationId,
					messagesSnapshot,
					initialParticipants
				);
			}
		};

		initializeConversationInfo();
	}, [selectedConversation]);

	useEffect(() => {
		if (!selectedConversation) return;
		if (!conversationShown) {
			setShowConversationTypeMessage(true);
			setConversationShown(true);
		}
		setHeaderTitle(selectedConversation.name);
		setHeaderDescription();
	}, [conversation]);

	useEffect(() => {
		if (
			selectedConversation
			&& showConversations
			&& !selectedConversation.live_event
		) {
			updateLastSeenDate(selectedConversation);
			if (
				selectedConversation.message_mentions
				&& selectedConversation.message_mentions.length > 0
			) {
				userSawTheMention(selectedConversation.message_mentions);
			}
			setParticipants([]);
			setParticipantsForMention([]);
		}
	}, [selectedConversation, showConversations]);

	const createNewConversation = () => {
		setShowConversationTypeMessage(false);
		setConversationShown(false);
		setSelectedConversation(null);
		setContent(
			<CreateConversation
				showError={showError}
				directConversationExists={directConversationExists}
				authenticatedUserId={userId}
				mobileSize={isMobile}
				refreshConversations={() => setReload(!reload)}
				openCreatedConversation={setSelectedConversation}
				groupConversationExists={groupConversationExists}
				showInternetConnectionError={showInternetConnectionError}
				setInputFocused={setInputFocused}
				authenticated={authenticated}
				enabledChatInput={enabledChatInput}
			/>
		);
		setHeaderTitle('New conversation');
		setHeaderDescription();
	};

	// eslint-disable-next-line no-unused-vars
	const deactivateConversation = (conversationId) => {
		firebase
			.database()
			.ref(`conversation/${conversationId}`)
			.update({
				active: false
			})
			.catch(() => {
				showError(CONVERSATION_COULD_NOT_BE_UPDATED);
			});
	};

	const sendMessageToFirebase = (messageObj, mentions) => {
		firebase
			.database()
			.ref('message')
			.push(messageObj)
			.then((snap) => {
				if (mentions && mentions.length > 0) {
					const messageId = snap.key;
					mentions.forEach((mention) => {
						firebase
							.database()
							.ref('message_mention')
							.push({
								messageId,
								conversation_id: messageObj.conversation_id,
								user_mentioned: mention,
								seen: false
							})
							.catch(() => {
								showError(CONVERSATION_MENTION_COULD_NOT_BE_CREATED);
							});
					});
				}
			})
			.catch(() => {
				showError(CONVERSATION_MESSAGE_COULD_NOT_BE_SENT);
			});
	};

	const onSendMessage = (message, type = 'message', files = [], mentions) => {
		if (!isConnectedToInternet) {
			showInternetConnectionError();
			return;
		}

		setSendingMessage(true);
		if (!selectedConversation) return;

		if (
			selectedConversation
			&& showConversations
			&& !selectedConversation.live_event
		) {
			updateLastSeenDate(selectedConversation);
			if (
				selectedConversation.message_mentions
				&& selectedConversation.message_mentions.length > 0
			) {
				userSawTheMention(selectedConversation.message_mentions);
			}
		}

		if (
			(!conversationShown || (conversation && conversation.length == 0))
			&& !selectedConversation.live_event
		) {
			if (userId != selectedConversation.user_id) {
				firebase
					.database()
					.ref(`conversation/${selectedConversation.id}`)
					.update({
						show_conversation: true
					});
			}
			const tempParticipantsToBeUpdated = [];
			firebase
				.database()
				.ref('conversation_participant')
				.on('value', (snapshot) => {
					snapshot.forEach((childSnapshot) => {
						const tempParticipantObj = childSnapshot.val();
						if (tempParticipantObj.conversation_id == selectedConversation.id) {
							tempParticipantsToBeUpdated.push(childSnapshot.key);
						}
					});
				});

			if (
				tempParticipantsToBeUpdated
				&& tempParticipantsToBeUpdated.length > 0
			) {
				tempParticipantsToBeUpdated.forEach((participantId) => {
					firebase
						.database()
						.ref(`conversation_participant/${participantId}`)
						.update({
							show_conversation: true
						});
				});
			}
		}

		const messageObj = {
			conversation_id: selectedConversation.id,
			user_id: userId,
			sent_date: moment().utc().toDate().toJSON()
		};

		if (messageToReply) {
			messageObj.reply_message_id = messageToReply.id;
		}

		messageObj.message = message;

		if (type == 'attachment') {
			const formData = new FormData();
			files.forEach((item) => {
				formData.append('files', item.file);
			});

			const attachments = [];
			uploadMessageAttachment(formData)
				.then((attachmentRes) => {
					if (attachmentRes.data) {
						attachmentRes.data.forEach((item) => {
							attachments.push({
								display_file_name: item.fileName,
								generated_file_name: item.generatedFileName
							});
							messageObj.attachments = attachments;
						});
					}
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					sendMessageToFirebase(messageObj, mentions);
				});
		} else {
			sendMessageToFirebase(messageObj, mentions);
		}
		setTimeout(() => {
			// setInputFocused(false);
			setScrollToBottom(!scrollToBottom);
		}, 1000);
	};

	const download = (bytes, filename, type) => {
		const blob = base64ToBlob(bytes, type);
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = filename;
		link.click();
	};

	const onAttachmentDownloaded = (filename, displayFilename) => {
		const type = mime.contentType(filename);
		getFileBytes(filename).then((res) => {
			download(res.data, displayFilename, type);
		});
	};

	useEffect(() => {
		getAuthenticatedUserId()
			.then((userRes) => {
				dispatch(setAuthenticatedUserId(userRes.data));
			})
			.catch((err) => {
				console.error(err);
			});

		const connectedRef = firebase.database().ref('.info/connected');
		connectedRef.on('value', (snap) => {
			setIsConnectedToInternet(snap.val());
		});
	}, []);

	useEffect(() => {
		if (!showModalForNewMessages) {
			setNumberOfNewMessages(1);
			setNumberOfMessagesLatestTime(0);
		} else {
			setNumberOfMessagesLatestTime(conversationMessages.length);
		}
	}, [showModalForNewMessages]);

	return (
		<>
			{!disableMessaging && !removeToastContainer && <ToastContainer />}
			<div
				className={`flex w-full h-full max-h-max ${
					removeHiddenOverflows ? '' : 'overflow-hidden'
				}`}
				style={additionalStyles}
			>
				{userId && !hideSidebar && (
					<ConversationSidebar
						conversations={conversations}
						setConversations={setConversations}
						authenticatedUserId={userId}
						showConversations={showConversations}
						onConversationChange={(c) => {
							if (selectedConversation && showConversations) {
								updateLastSeenDate(selectedConversation);
								if (
									selectedConversation.message_mentions
									&& selectedConversation.message_mentions.length > 0
								) {
									userSawTheMention(selectedConversation.message_mentions);
								}
							}
							setSelectedConversation(c);
						}}
						onCreateNewConversation={() => {
							createNewConversation();
						}}
						mobileSize={isMobile}
						showSidebar={showSidebar}
						setShowSidebar={setShowSidebar}
						reload={reload}
						selectedConversation={selectedConversation}
						showError={showError}
					/>
				)}

				<div
					className={`w-full h-max max-h-max flex flex-col ${
						removeHiddenOverflows ? '' : 'overflow-hidden'
					} bg-white dark:bg-darkGray-50`}
					style={additionalStyles}
				>
					{!hideHeader && (
						<ConversationHeader
							title={headerTitle}
							setShowConversations={setShowConversations}
							description={headerDescription}
							mobileSize={isMobile}
							setShowSidebar={setShowSidebar}
							setShowParticipants={setShowParticipants}
							showParticipantsButton={
								(selectedConversation && selectedConversation.is_group)
								|| (selectedConversation && selectedConversation.user_id == userId)
							}
							conversation={selectedConversation}
							isConnectedToInternet={isConnectedToInternet}
						/>
					)}
					{children}

					<div
						ref={messagesDivRef}
						className={`px-5 md:px-6 py-2 md:py-4 flex-1 w-full overflow-chat bg-white dark:bg-darkGray-50 relative ${
							isMobile && showSidebar && !hideHeader ? 'hidden' : ''
						} ${showModalForNewMessages ? 'h-auto' : 'h-full max-h-full'}`}
						onScroll={(e) => {
							const bottom =								e.target.scrollHeight
									- e.target.scrollTop
									- e.target.clientHeight
								<= 20;
							if (bottom) {
								setShowModalForNewMessages(false);
							}
						}}
						style={{
							overflowX: 'hidden',
							height: '15px',
							marginBottom:
								isMobile
								&& selectedConversation
								&& !absoluteEditorPosition
								&& !isTablet
								&& '35vh',
							// marginBottom:
							// 	isMobile
							// 	&& selectedConversation
							// 	&& !absoluteEditorPosition
							// 	&& '35vh',
							...additionalStyles
						}}
					>
						{showModalForNewMessages && !isScrolledToBottom && (
							<div
								className="sticky-position h-10"
								style={{
									zIndex: '100000',
									top: '0px',
									left: !isMobile && '40%'
								}}
							>
								<div
									className="w-48 md:w-48 rounded-3xl bg-djawn-300 place-self-center justify-center shadow-lg mx-auto"
									style={{
										zIndex: '100000'
									}}
								>
									<div
										className="flex justify-between p-1 place-self-center"
										style={{ zIndex: '60' }}
									>
										<div
											className="flex"
											onKeyDown={() => {
												setShowModalForNewMessages(false);
												setScrollToBottom(!scrollToBottom);
											}}
											onClick={() => {
												setShowModalForNewMessages(false);
												setScrollToBottom(!scrollToBottom);
											}}
										>
											<button
												type="button"
												className="items-center cursor-pointer justify-center text-djawn transition-colors duration-150 bg-djawn rounded-full focus:shadow-outline hover:bg-djawn-300 bg-opacity-50 relative"
												onKeyDown={() => {
													setShowModalForNewMessages(false);
													setScrollToBottom(!scrollToBottom);
												}}
												onClick={() => {
													setShowModalForNewMessages(false);
													setScrollToBottom(!scrollToBottom);
												}}
											>
												<svg
													className="w-6 h-6"
													fill="white"
													viewBox="0 0 20 20"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
														clipRule="evenodd"
													/>
												</svg>
											</button>
											<p className="cursor-pointer text-white text-sm ml-4">
												{numberOfNewMessages}
												{' '}
new messages
											</p>
										</div>
										<button
											type="button"
											className="items-center cursor-pointer justify-center text-indigo-100 transition-colors duration-150 bg-djawn rounded-full focus:shadow-outline hover:bg-djawn-300 bg-opacity-50 relative pr-1 pt-0.5"
											onKeyDown={() => {
												setShowModalForNewMessages(false);
											}}
											onClick={() => {
												setShowModalForNewMessages(false);
											}}
										>
											<svg
												className="w-5 h-5"
												fill="white"
												viewBox="0 0 20 20"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
													clipRule="evenodd"
												/>
											</svg>
										</button>
									</div>
								</div>
							</div>
						)}

						{conversationShown ? (
							<ConversationMessages
								onAttachmentDownloaded={onAttachmentDownloaded}
								messages={conversationMessages}
								hostUserId={
									selectedConversation && selectedConversation.user_id
								}
								messageToReply={messageToReply}
								sendingMessage={sendingMessage}
								setSendingMessage={setSendingMessage}
								setMessageToReply={setMessageToReply}
								setSelectedForReply={(message) => {
									setMessageToReply(message);
								}}
								authenticatedUserId={userId}
								mobileSize={isMobile}
								disableMessaging={disableMessaging}
								showScreenName={showScreenName}
								reduceReplyMessageSize={reduceReplyMessageSize}
								truncateName={truncateName}
								disableReplying={disableReplying}
								inputFocused={inputFocused}
								removeDayDivider={removeDayDivider}
								scrollToBottom={scrollToBottom}
								isScrolledToBottom={isScrolledToBottom}
								authenticated={authenticated}
								hasReplyMessages={
									selectedConversation && !selectedConversation.live_event
								}
								onPurchasedItemsHyperlinkClicked={
									onPurchasedItemsHyperlinkClicked
								}
								onRandomizationHyperlinkClicked={
									onRandomizationHyperlinkClicked
								}
								setReactions={setReactions}
								showReactions={showReactions}
								setShowReactions={setShowReactions}
								setShowMessageOptions={setShowMessageOptions}
								setSelectedForEdit={setSelectedForEdit}
								selectedForEdit={selectedForEdit}
								setSelectedMessage={setSelectedMessage}
							/>
						) : (
							content
						)}
					</div>

					<div
						className={`flex flex-col w-full bg-white dark:bg-darkGray-50 dark:text-white ${
							hideSidebar ? 'md:mb-0' : 'md:mb-15'
						}`}
						style={{
							position:
								isMobile
								&& !isTablet
								&& (absoluteEditorPosition ? 'relative' : 'fixed'),
							bottom: isMobile && !absoluteEditorPosition && 0,
							borderBottomLeftRadius: useInlineInputEditor && '25px',
							borderBottomRightRadius: useInlineInputEditor && '35px'
						}}
					>
						{messageToReply && (
							<div className="flex items-start md:mx-20 mx-5 rounded-lg bg-gray border-2 p-5 border-grey mb-2 border-b px-6 py-3 bg-white dark:bg-darkGray-50 dark:text-white">
								<ConversationMessage
									message={messageToReply}
									onReply
									onAttachmentDownloaded={onAttachmentDownloaded}
									mobileSize={isMobile}
									authenticated={authenticated}
								/>
								<div className="ml-auto md:block items-center">
									<button
										type="button"
										className="focus:outline-none items-center text-black hover:text-black text-lg rounded-md rounded-tr-none rounded-br-none -mr-3"
										onClick={() => {
											setMessageToReply(null);
										}}
									>
										<div className="relative">
											<svg
												className="w-7 h-7"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
												/>
											</svg>
										</div>
									</button>
								</div>
							</div>
						)}
						{showConversationTypeMessage
							&& selectedConversation
							&& selectedConversation.active
							&& !disableMessaging && (
							<div
								className={`flex ${
									useInlineInputEditor ? 'border-t-2' : 'rounded-lg border-2'
								} border-grey h-full`}
							>
								<ConversationTypeMessage
									onSendMessage={onSendMessage}
									mobileSize={isMobile}
									showError={showError}
									participants={participantsForMention}
									removeListsFromToolbar={removeListsFromToolbar}
									hideAttachmentsButton={hideAttachmentsButton}
									setInputFocused={setInputFocused}
									authenticated={authenticated}
									onRedirectToSignIn={onRedirectToSignIn}
									reduceReplyMessageSize={reduceReplyMessageSize}
									useInlineInputEditor={useInlineInputEditor}
									toolbarId="cl"
									enabledChatInput={enabledChatInput}
									enableMentions={enableMentions}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
			<ConversationParticipants
				showModal={showParticipants}
				setShowModal={setShowParticipants}
				participants={participants}
				removeParticipant={removeParticipant}
				canRemoveParticipants={
					selectedConversation && selectedConversation.user_id == userId
				}
			/>
			<ConversationReactions
				showModal={showReactions}
				setShowModal={setShowReactions}
				reactions={reactions}
				participants={participants}
			/>
			<ConversationMessageOptions
				showModal={showMessageOptions}
				setShowModal={setShowMessageOptions}
				setSelectedForEdit={() => {
					setSelectedForEdit(selectedMessage);
				}}
				setSelectedForReply={() => {
					setMessageToReply(selectedMessage);
				}}
				disableMessaging={disableMessaging}
				selectedMessage={selectedMessage}
				authenticatedUserId={userId}
			/>
		</>
	);
};

export default connect(
	(state) => ({
		userId: state.utils.userId
	}),
	null
)(Conversation);
