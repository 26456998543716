/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import { getPopupPageContent } from '../api/popup.request';

const DynamicPopup = ({ popups, acknowledgePopup }) => {
	const [checked, setChecked] = useState(false);
	const [currentPageContent, setCurrentPageContent] = useState('');
	const [popup, setPopup] = useState(null);
	const [showPopup, setShowPopup] = useState(true);

	function handleTermCondition(e) {
		setChecked(e.target.checked);
	}

	useEffect(() => {
		setPopup(popups[0]);
	}, [popups]);

	const onNextPopup = () => {
		if (
			(popup.popTypeAttributes.requiresTermsAgreement && checked)
			|| !popup.popTypeAttributes.requiresTermsAgreement
		) {
			acknowledgePopup(popup.mainPopId);
		}
		setChecked(false);
		setShowPopup(false);
	};

	const getPageContent = (mainPopId) => {
		getPopupPageContent(mainPopId)
			.then((res) => {
				if (res.data && res.data.length) {
					const content = res.data;
					let formatedContent = content.replace(
						'</strong>',
						'</strong><br/><br/>'
					);
					formatedContent = formatedContent.replace(
						'<strong>',
						'<br/><strong>'
					);

					setCurrentPageContent(formatedContent);
					setShowPopup(true);
				}
			})
			.catch((error) => {
				onNextPopup();
				if (error.response) {
					console.error(error.message);
				}
			});
	};

	useEffect(() => {
		if (!popup) return;
		getPageContent(popup.mainPopId);
	}, [popup]);

	if (!popup || !showPopup) return null;
	return (
		<>
			<div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
				<div className="border-0 rounded-lg shadow-lg flex flex-col bg-white outline-none focus:outline-none w-full md:w-auto">
					<div className="flex flex-row items-center justify-between p-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg ml-15 md:ml-0">
						<div style={{ textAlign: 'center' }}>
							<p className="text-opacity-100 font-bold">{popup.popName}</p>
						</div>
					</div>
					<div className="flex flex-col px-6 py-5 bg-gray-50 mb-5 mx-10 md:mx-0">
						{currentPageContent && (
							<InnerHTML
								className="overflow-x-hidden overflow-y-auto outline-none focus:outline-none px-2 popup-content"
								style={{
									maxHeight: '40vh',
									maxWidth: '80vh'
								}}
								html={currentPageContent}
							/>
						)}
					</div>
					<div className="flex mb-8 mb-0 md:mx-5  mx-0  md:ml-5 justify-center">
						{popup.popType
						&& popup.popTypeAttributes
						&& popup.popTypeAttributes.requiresTermsAgreement ? (
								<div className="flex flex-col">
									{popup.popTypeAttributes.requiresInstructions && (
										<div className="flex">
											<p className="font-bold">{popup.instructions}</p>
										</div>
									)}
									<div className="flex mb-3">
										<label className="flex items-center">
											<input
												type="checkbox"
												className="form-checkbox"
												checked={checked}
												onChange={handleTermCondition}
											/>
											<span className="ml-2">
											I have read and agreed to the terms
											</span>
										</label>
									</div>
									<div className="flex justify-center">
										<div className="mr-3">
											<button
												disabled={!checked}
												type="button"
												className="h-12 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium
															text-white
															disabled:opacity-50 bg-djawn hover:bg-djawn-300
															md:py-4 md:text-lg md:px-10"
												onClick={onNextPopup}
											>
											Submit
											</button>
										</div>
									</div>
								</div>
							) : (
								<div className="mr-3 ml-10 md:ml-5">
									<button
										type="button"
										className="h-12 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white bg-djawn hover:bg-djawn-300 md:py-4 md:text-lg md:px-10"
										onClick={onNextPopup}
									>
									OK
									</button>
								</div>
							)}
					</div>
				</div>
			</div>
			<div className="opacity-25 fixed inset-0 z-40 bg-black" />
		</>
	);
};

export default DynamicPopup;
