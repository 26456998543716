import React from 'react';
import clsx from 'clsx';

import { connectorStyleDefaults, useConnectorStyles } from './styles';
import { convertNumericToPixel } from '../../../utils/commonFunction';
import StepContext from '../step/step-context';
import StepperContext from '../stepper-context';

const Connector = () => {
	const { connectorStateColors, connectorStyleConfig } = React.useContext(
		StepperContext
	);
	const { completed, active, stepSize } = React.useContext(StepContext);

	const connectorStyle = {
		...connectorStyleDefaults,
		...connectorStyleConfig,
		stepSize
	};

	convertNumericToPixel(connectorStyle, 'stepSize');
	convertNumericToPixel(connectorStyle, 'size');

	const classes = useConnectorStyles(connectorStyle);
	return (
		<div id="RFS-ConnectorContainer" className={classes.ConnectorContainer}>
			<span
				id="RFS-Connector"
				className={clsx(
					classes.Connector,
					{ completed: completed && connectorStateColors },
					{ active: active && connectorStateColors }
				)}
			/>
		</div>
	);
};

export default Connector;
