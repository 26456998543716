/* eslint-disable  no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { connect } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';
import { css } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import { getUserById } from '../../api/auth.request';
import { isDateBigger } from '../../utils/formatter';
import { updateMessagesCount } from '../../state/action';
import EllipisWithTooltip from '../ellipsis-tooltip';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const ConversationSidebar = ({
	onCreateNewConversation,
	onConversationChange,
	authenticatedUserId,
	mobileSize,
	dispatch,
	showSidebar,
	setShowSidebar,
	setConversations,
	conversations,
	reload,
	selectedConversation,
	showConversations
}) => {
	const [
		conversationParticipantData,
		setConversationParticipantData
	] = useState([]);
	const [conversationData, setConversationData] = useState([]);
	const [firebaseDataLoaded, setFirebaseDataLoaded] = useState(false);
	const [conversationsLoaded, setConversationsLoaded] = useState(false);
	const [directConversationNames, setDirectConversationNames] = useState([]);
	const [
		conversationsStartedByEvent,
		setConversationsStartedByEvent
	] = useState([]);

	const getConversationNameByUserId = async (conversation) => {
		const foundDirectConversation = directConversationNames.filter(
			(f) => f.id == conversation.id
		);
		if (!conversation.is_group) {
			if (foundDirectConversation && foundDirectConversation.length) {
				conversation.name = foundDirectConversation[0].name;
			} else {
				let participantUserId = conversation.user_id;

				if (conversation.user_id == authenticatedUserId) {
					const filteredParticipants = conversationParticipantData.filter(
						(f) => f.conversation_id == conversation.id
					);
					if (
						filteredParticipants
						&& filteredParticipants.length > 0
						&& filteredParticipants[0].user_id
					) {
						participantUserId = filteredParticipants[0].user_id;
					}
				}

				const userRes = await getUserById(participantUserId);
				if (userRes && userRes.data) {
					conversation.name = userRes.data.name;
				}
			}
		}
		return conversation;
	};

	const filterConversationNamesIfItsADirectMessage = async (
		unfilteredConversations
	) => {
		const results = await Promise.all(
			unfilteredConversations.map((conversation) => getConversationNameByUserId(conversation))
		);
		const tempConversationNames = [];
		results.forEach((conversation) => {
			tempConversationNames.push({
				id: conversation.id,
				name: conversation.name
			});
		});

		setDirectConversationNames(tempConversationNames);
		return results;
	};

	useEffect(() => {
		const database = firebase.database();

		database.ref('conversation_participant').on('value', (snapshot) => {
			const tempConversationParticipant = [];
			snapshot.forEach((childSnapshot) => {
				const conversationParticipant = childSnapshot.val();
				conversationParticipant.id = childSnapshot.key;
				if (
					conversationParticipant.show_conversation
					&& !conversationParticipant.live_event
				) {
					tempConversationParticipant.push(conversationParticipant);
				}
			});
			setConversationParticipantData(tempConversationParticipant);
		});

		let tempConversations = [];
		database.ref('conversation').on('value', (snapshot) => {
			tempConversations = [];
			const tempConverationsStartedByEvent = [];
			snapshot.forEach((childSnapshot) => {
				const conversation = childSnapshot.val();
				const showConversation =					(conversation.show_conversation || conversation.started_by_event)
					&& !conversation.live_event;
				if (showConversation && conversation.user_id !== authenticatedUserId) {
					tempConverationsStartedByEvent.push(childSnapshot.key);
				}
				if (
					!(conversation.user_id == authenticatedUserId && !showConversation)
				) {
					conversation.id = childSnapshot.key;
					tempConversations.push(conversation);
				}
			});
			setConversationsStartedByEvent(tempConverationsStartedByEvent);

			database.ref('message_mention').once('value', (mentionSnapshot) => {
				const tempMessageMentions = [];
				mentionSnapshot.forEach((childSnapshot) => {
					const messageMention = childSnapshot.val();
					messageMention.id = childSnapshot.key;
					tempMessageMentions.push(messageMention);
				});

				tempConversations = tempConversations.map((c) => {
					const filteredMessageMentions = tempMessageMentions.filter(
						(m) => m.conversation_id == c.id
							&& m.seen == false
							&& m.user_mentioned.id == authenticatedUserId
					);
					c.message_mentions = filteredMessageMentions;
					return c;
				});
			});

			database.ref('message').once('value', (messageSnapshot) => {
				const messages = [];
				messageSnapshot.forEach((childSnapshot) => {
					const tempMessage = childSnapshot.val();
					tempMessage.id = childSnapshot.key;
					messages.push(tempMessage);
				});

				// eslint-disable-next-line no-undef
				tempConversations = tempConversations.map((c) => {
					const filteredMessages = messages.filter(
						(m) => m.conversation_id == c.id
					);
					c.messages = filteredMessages;
					return c;
				});

				setConversationData(tempConversations);
				setFirebaseDataLoaded(true);
			});
		});

		if (tempConversations && tempConversations.length > 0) {
			database.ref('message_mention').on('value', (mentionSnapshot) => {
				const tempMessageMentions = [];
				mentionSnapshot.forEach((childSnapshot) => {
					const messageMention = childSnapshot.val();
					messageMention.id = childSnapshot.key;
					tempMessageMentions.push(messageMention);
				});

				tempConversations = tempConversations.map((c) => {
					const filteredMessageMentions = tempMessageMentions.filter(
						(m) => m.conversation_id == c.id
							&& m.seen == false
							&& m.user_mentioned.id == authenticatedUserId
					);
					c.message_mentions = filteredMessageMentions;
					return c;
				});
			});

			database.ref('message').on('value', (messageSnapshot) => {
				const messages = [];
				messageSnapshot.forEach((childSnapshot) => {
					const tempMessage = childSnapshot.val();
					tempMessage.id = childSnapshot.key;
					messages.push(tempMessage);
				});

				// eslint-disable-next-line no-undef
				tempConversations = tempConversations.map((c) => {
					const filteredMessages = messages.filter(
						(m) => m.conversation_id == c.id
					);
					c.messages = filteredMessages;
					return c;
				});

				setConversationData(tempConversations);
				setFirebaseDataLoaded(true);
			});
		}
	}, [reload]);

	useEffect(() => {
		if (!firebaseDataLoaded) return;

		const tempConversations = [];
		const participatedConversations = conversationParticipantData
			.map((participant) => {
				const isStartedByEvent = conversationsStartedByEvent.filter(
					(c) => c == participant.conversation_id
				);
				if (
					participant
					&& participant.user_id
					&& participant.user_id === authenticatedUserId
					&& isStartedByEvent
				) {
					return participant;
				}
				return null;
			})
			.filter((f) => f != null);

		const otherUsersParticipatedConversations = conversationParticipantData
			.map((participant) => {
				if (
					participant
					&& participant.user_id
					&& participant.user_id !== authenticatedUserId
				) {
					return participant;
				}
				return null;
			})
			.filter((f) => f != null);
		let tempTotalUnreadMessages = 0;
		conversationData.forEach((conversation) => {
			const participatedConversation = participatedConversations.find(
				(p) => p.conversation_id == conversation.id
			);
			if (
				conversation
				&& conversation.messages
				&& conversation.user_id
				&& (conversation.user_id === authenticatedUserId
					|| participatedConversation != null)
			) {
				if (!conversation.is_group) {
					const participant = conversationParticipantData[0];

					if (
						participant
						&& participant.conversation_id == conversation.id
						&& participant.user_id != authenticatedUserId
					) {
						conversation.participant_user_id = participant.user_id;
						conversation.host_user_id = conversation.user_id;
						conversation.user_id = authenticatedUserId;
					} else {
						if (participant && participant.conversation_id) {
							if (participant.conversation_id == conversation.id) {
								conversation.participant_user_id = participant.user_id;
							}
						}

						const foundOtherParticipantUser = otherUsersParticipatedConversations.find(
							(p) => p.conversation_id == conversation.id
						);
						if (foundOtherParticipantUser) {
							conversation.participant_user_id =								foundOtherParticipantUser.user_id;
							conversation.host_user_id = foundOtherParticipantUser.user_id;
						} else {
							conversation.host_user_id = authenticatedUserId;
							conversation.participant_user_id = authenticatedUserId;
						}
					}
				}
				if (
					(!conversation.is_group && conversation.host_user_id)
					|| (conversation.is_group && !conversation.host_user_id)
				) {
					if (participatedConversation != null) {
						if (conversation.last_seen_date) {
							conversation.last_seen_date =								participatedConversation.last_seen_date;
						}
						conversation.last_seen_date =							participatedConversation.last_seen_date;
						conversation.participant_id = participatedConversation.id;
					}
					const unreadMessages = conversation.messages.filter(
						(f) => f.user_id != authenticatedUserId
							&& isDateBigger(f.sent_date, conversation.last_seen_date) < 0
					);
					if (unreadMessages) {
						conversation.numberOfUnreadMessages = Number(unreadMessages.length);
					} else {
						conversation.numberOfUnreadMessages = 0;
					}

					if (
						selectedConversation
						&& conversation.id == selectedConversation.id
						&& showConversations
					) {
						conversation.numberOfUnreadMessages = 0;
					}

					tempTotalUnreadMessages += conversation.numberOfUnreadMessages;

					tempConversations.push(conversation);
				}
			}
		});

		dispatch(updateMessagesCount(tempTotalUnreadMessages));

		filterConversationNamesIfItsADirectMessage(tempConversations)
			.then((c) => {
				let sortedConversationsWithMessages = c
					.filter((b) => b.messages && b.messages.length > 0)
					.sort(
						(a, b) => new Date(b.messages[b.messages.length - 1].sent_date)
							- new Date(a.messages[a.messages.length - 1].sent_date)
					);
				const refundMessages = c.filter(
					(b) => !b.messages || !b.messages.length
				);
				sortedConversationsWithMessages = sortedConversationsWithMessages.concat(
					refundMessages
				);

				setConversations(sortedConversationsWithMessages);
			})
			.finally(() => {
				setConversationsLoaded(true);
			});
	}, [conversationData, firebaseDataLoaded]);

	if (!conversationsLoaded) {
		return (
			<div className="w-screen h-screen bg-white absolute left-0 top-0">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}

	return (
		<>
			<div
				className={`pb-6 bg-purple-darker text-purple-lighter overflow-y bg-djawn-light overflow-x-hidden h-full z-50 ${
					mobileSize ? 'w-screen absolute left-0' : 'w-1/5 md:block'
				}
			${mobileSize && !showSidebar && 'hidden'}`}
				style={{ maxHeight: '95vh' }}
			>
				<ReactTooltip />
				<div className="flex mb-6">
					<div
						className="cursor-pointer item mt-0 flex items-center text-base font-normal hover:bg-gray-800 text-white w-full px-6 py-4 active:bg-green-700 border-b-4"
						onClick={() => {
							setShowSidebar(false);
							onCreateNewConversation();
						}}
						onKeyDown={() => {
							setShowSidebar(false);
							onCreateNewConversation();
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="mr-4"
							width="20"
							height="20"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
							/>
						</svg>
						<span className="item-text">Create new message</span>
					</div>
				</div>
				<div
					className="overflow-y-auto"
					style={{ maxHeight: mobileSize && '75%' }}
				>
					{conversations.length
						? conversations.map((object, i) => (
							<div className="flex px-4" key={i}>
								<div
									className="cursor-pointer item mt-0 flex items-center justify-between text-base font-normal text-white w-full px-6 py-2 hover:bg-gray-800 active:bg-green-700"
									key={i}
									onClick={() => {
										setShowSidebar(false);
										onConversationChange(object);
									}}
									onKeyDown={() => {
										setShowSidebar(false);
										onConversationChange(object);
									}}
								>
									<EllipisWithTooltip
										placement="bottom"
										className={`item-text text-md ${
											object.numberOfUnreadMessages && 'font-bold'
										} `}
									>
										{object.name}
									</EllipisWithTooltip>
									{object.message_mentions
										&& object.message_mentions.length > 0
										&& object.numberOfUnreadMessages ? (
											<div className="rounded-full h-10 w-10 flex items-center justify-center border-2 border-grey border-b">
												<span className="font-bold text-sm px-10">
													{object.message_mentions.length}
												</span>
											</div>
										) : null}
								</div>
							</div>
						  ))
						: null}
				</div>
			</div>
		</>
	);
};

export default connect(
	(state) => ({
		unreadMessages: state.utils.unreadMessages
	}),
	null
)(ConversationSidebar);
