import React, { useContext } from 'react';
import clsx from 'clsx';
import StepContext from '../step/step-context';

const StepButton = ({ children, contentClasses, ...rest }) => {
	const { completed, disabled, index } = useContext(StepContext);

	return (
		<button disabled={disabled} {...rest} type="button">
			<span
				className={clsx(
					contentClasses,
					{ active: !disabled && !completed },
					{ completed }
				)}
			>
				{children || index + 1}
			</span>
		</button>
	);
};

export default StepButton;
