import { useState, useEffect } from 'react';
import { getBreakTeamsRandomization } from '../api/break.request';
import { getEventStepTeamDistribution } from '../api/event.request';
import { groupBy } from '../utils/commonFunction';
import { PICK_YOUR_SLOTS, RANDOMIZED, RIP_N_SHIP } from '../utils/constants';
import {
	ACTIVE_LINE_VIEW,
	ATTENDEES_VIEW,
	PICK_YOUR_SLOTS_VIEW,
	RANDOMIZED_BREAKS_VIEW,
	SELLER_INFORMATION_VIEW,
	LINE_HISTORY_VIEW,
	AVAILABLE_PRODUCTS_VIEW
} from '../utils/views';

const useLiveEventData = (eventTypeId, eventId, breakTemplateId) => {
	const [activeView, setActiveView] = useState(ATTENDEES_VIEW);
	const [isRipNShip, setIsRipNShip] = useState(false);
	const [isRandomizedBreak, setIsRandomizedBreak] = useState(false);
	const [isPickYourSlotsBreak, setIsPickYourSlotsBreak] = useState(false);
	const [views, setViews] = useState([]);
	const [attendees, setAttendees] = useState([]);
	const [teamsRandomization, setTeamsRandomization] = useState([]);

	const getPreRandomizedTiers = async () => {
		try {
			const preRandomizedTiersRes = await getEventStepTeamDistribution(
				breakTemplateId
			);
			if (preRandomizedTiersRes.data && preRandomizedTiersRes.data.length) {
				return groupBy(preRandomizedTiersRes.data, 'tier', false);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const getBreakTeamsRandomizationWithDisplayOrder = (
		breakTeamsRandomization,
		preTierRandomization
	) => {
		let teamsRandomizationWithDisplayOrder = [...breakTeamsRandomization].map(
			(teamRandomization) => {
				let displayOrder = 0;

				teamRandomization.tiers.forEach((tierTeam) => {
					if (tierTeam.tier == 1) {
						const foundPreRandomizedTierIndex = preTierRandomization.findIndex(
							(tier) => tier.teamId == tierTeam.teamId
						);
						if (!Number.isNaN(foundPreRandomizedTierIndex)) {
							displayOrder = foundPreRandomizedTierIndex + 1;
						}
					}
				});

				return {
					...teamRandomization,
					displayOrder
				};
			}
		);
		if (teamsRandomizationWithDisplayOrder) {
			teamsRandomizationWithDisplayOrder = teamsRandomizationWithDisplayOrder.sort(
				(a, b) => a.displayOrder - b.displayOrder
			);
		}

		return teamsRandomizationWithDisplayOrder;
	};

	const loadTeamsRandomization = async () => {
		getBreakTeamsRandomization(eventId)
			.then(async (randomizationsRes) => {
				const preRandomizedTiers = await getPreRandomizedTiers();
				if (!preRandomizedTiers || Object.keys(preRandomizedTiers) <= 0) return;
				const firstTierPreRandomizedBreaks =					preRandomizedTiers[Object.keys(preRandomizedTiers)[0]];
				if (
					!firstTierPreRandomizedBreaks
					|| !firstTierPreRandomizedBreaks.length
				) return;
				setTeamsRandomization(
					getBreakTeamsRandomizationWithDisplayOrder(
						randomizationsRes.data,
						firstTierPreRandomizedBreaks
					)
				);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(() => {
		if (!eventTypeId || !eventId) return;
		if (eventTypeId == RANDOMIZED && !breakTemplateId) return;
		const tempViews = [];

		if (eventTypeId == RANDOMIZED) {
			tempViews.push(ATTENDEES_VIEW);
			tempViews.push(RANDOMIZED_BREAKS_VIEW);
			tempViews.push(SELLER_INFORMATION_VIEW);
			setIsRandomizedBreak(true);
			loadTeamsRandomization();
		} else if (eventTypeId == PICK_YOUR_SLOTS) {
			tempViews.push(ATTENDEES_VIEW);
			tempViews.push(PICK_YOUR_SLOTS_VIEW);
			tempViews.push(SELLER_INFORMATION_VIEW);
			setIsPickYourSlotsBreak(true);
		} else if (eventTypeId == RIP_N_SHIP) {
			tempViews.push(ACTIVE_LINE_VIEW);
			tempViews.push(LINE_HISTORY_VIEW);
			tempViews.push(ATTENDEES_VIEW);
			tempViews.push(AVAILABLE_PRODUCTS_VIEW);
			tempViews.push(SELLER_INFORMATION_VIEW);
			setIsRipNShip(true);
		}

		setViews(tempViews);
	}, [eventTypeId, eventId, breakTemplateId]);

	return {
		views,
		activeView,
		setActiveView,
		eventTypeId,
		isRandomizedBreak,
		isRipNShip,
		isPickYourSlotsBreak,
		teamsRandomization,
		loadTeamsRandomization,
		setAttendees,
		attendees
	};
};

export default useLiveEventData;
