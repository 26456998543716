import React from 'react';
import { isTruthy } from '../../utils/commonFunction';
import { inputTypes } from './types';

const TextInput = ({
	width = 'full',
	type = 'primary',
	inputType = 'text',
	mobileWidth = 'full',
	height = '12',
	mobileHeight = 'auto',
	textSize = 'sm',
	additionalClassName = '',
	rounded = true,
	roundedSize = 'md',
	...restProps
}) => {
	const selectedType = inputTypes[type];

	return (
		<input
			type={inputType}
			className={`focus:ring-${
				selectedType.focusColor
			} pl-2 pr-2 block md:w-${width} w-${mobileWidth} h-${mobileHeight} md:h-${height} text-${textSize} border border-${
				selectedType.borderColor
			} ${
				isTruthy(rounded) && `rounded-${roundedSize}`
			} ${additionalClassName} outline-none`}
			{...restProps}
		/>
	);
};

export default TextInput;
