/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable radix */
/* eslint-disable no-loop-func */
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import EditEventSaveTemplateModal from '../../components/Event/edit-template-save-modal';
import { InfoIcon } from '../../components/Event/event-icons';
import { CreateEventContext } from '../../contexts/create-event';
import { getEventStepTeamDistribution } from '../../api/event.request';
import BackButton from '../../components/button/back-button';
import NextButton from '../../components/button/next-button';
import useErrorNotifier from '../../hooks/use-error-notifier';

const EditTemplate = () => {
	const { showToastError } = useErrorNotifier();
	const {
		eventDescription,
		setEventDescription,
		eventTypeId,
		setActiveStep,
		setFiles,
		files,
		getProducts,
		breakTemplate,
		selectedProducts,
		setSelectedProducts,
		tiersData: contextTiersData,
		setTiersData: contextSetTiersData,
		editedTemplate,
		setEditedTemplate
	} = useContext(CreateEventContext);

	const [showSaveModal, setShowSaveModal] = useState(false);
	const [tiersData, setTiersData] = useState(contextTiersData);
	const [loading, setLoading] = useState(true);
	const [isEdited, setIsEdited] = useState(false);
	const [customTemplateName, setCustomTemplateName] = useState('');
	const [showResetButton, setShowResetButton] = useState(false);

	const grid = 8;
	const getListStyle = (tierLength, isDraggingOver) => ({
		background: isDraggingOver ? 'lightgrey' : 'lightgrey',
		borderColor: tierLength > breakTemplate.template.slotSize ? 'red' : 'white',
		borderWidth: tierLength > breakTemplate.template.slotSize ? '2px' : '1px',
		padding: grid,
		width: 250
	});

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: 'none',
		padding: grid * 2,
		margin: `0 0 ${grid}px 0`,
		fontSize: window.screen.width <= 768 ? '12px' : '',
		// change background colour if dragging
		background: isDragging ? 'grey' : 'white',

		// styles we need to apply on draggables
		...draggableStyle
	});

	const getStepData = () => {
		const requestTeamsData = [];
		if (tiersData.length) {
			let displayOrder = 0;
			tiersData.map((tier, index) => {
				displayOrder = 1;
				tier.map((team, j) => {
					requestTeamsData.push({
						tier: index + 1,
						teamID: team.teamId <= 0 ? null : team.teamId,
						team: team.team,
						displayOrder
					});
					displayOrder++;
				});
			});
		}
		return requestTeamsData;
	};

	const setTeamDistribution = (teamDistribution) => {
		const rows = [];
		let children = [];
		for (let i = 1; i <= parseInt(breakTemplate.template.tierSize); i++) {
			teamDistribution.map((value) => {
				if (parseInt(value.tier) == i) {
					children.push({ teamId: value.teamId, team: value.team });
				}
			});
			rows.push(children);
			children = [];
		}
		const array = JSON.parse(JSON.stringify(rows));
		setTiersData(array);
	};

	useEffect(() => {
		if (editedTemplate.tiersData.length) {
			let i = 0;
			editedTemplate.tiersData.forEach((team) => {
				if (team.teamId == null) {
					i--;
					team.teamId = i;
				}
			});
			setTiersData(editedTemplate.tiersData.tiersData);
			// setCustomTemplateName(stepData.customTemplateName);
			// if (stepData.customTemplateName) setShowResetButton(true);
			if (editedTemplate.customTemplateName) setShowResetButton(true);
			setLoading(false);
		} else {
			Promise.all([
				getEventStepTeamDistribution(breakTemplate.templateId)
			]).then(([teamDist]) => {
				let i = 0;
				teamDist.data.forEach((team) => {
					if (team.teamId == null) {
						i--;
						team.teamId = i;
					}
				});
				setTeamDistribution(teamDist.data);
				setLoading(false);
			});
		}
	}, []);

	const saveUpdatedTemplate = (updatedTemplate) => {
		const formData = {
			templateName: updatedTemplate,
			tierSize: tiersData.length,
			slotSize: breakTemplate.template.slotSize,
			isTemplateEdited: isEdited,
			sportTypeID: breakTemplate.template.sportTypeId,
			customTemplateName: updatedTemplate,
			teams: getStepData()
		};
		setShowSaveModal(false);
		formData.tiersData = tiersData;
		// form_data.breakEventTemplateId = res.data.breakTemplateId;
		setCustomTemplateName(updatedTemplate);
		setEditedTemplate(formData);
		setActiveStep(3);
	};

	const onDragEnd = (result) => {
		const movedTeamID = result.draggableId;
		const columns = [];
		const { source, destination } = result;
		let selectedTeam = [];
		if (!destination) {
			return;
		}
		setIsEdited(true);
		tiersData.map((tier, i) => {
			selectedTeam = tier.find((t) => t.teamId == parseInt(movedTeamID));
			const filteredTeams = tier.filter(
				(t) => t.teamId !== parseInt(movedTeamID)
			);
			columns.push(filteredTeams);
		});
		selectedTeam = tiersData.map((tier, i) => tier.find((t) => t.teamId == parseInt(movedTeamID)));
		selectedTeam = selectedTeam.filter(Boolean);
		// const filteredteam = tier.filter( t => t.teamId === parseInt(movedTeamID));
		columns[parseInt(destination.droppableId)].splice(
			destination.index,
			0,
			selectedTeam[0]
		);
		setTiersData(columns);
	};

	const resetTemplateData = () => {
		setShowResetButton(false);
		setTiersData([]);
		Promise.all([getEventStepTeamDistribution(breakTemplate.templateId)]).then(
			([teamDist]) => {
				setTeamDistribution(teamDist.data);
				setCustomTemplateName('');
			}
		);
	};

	const onNextClick = () => {
		let slotSizeValidated = true;
		tiersData.map((tier) => {
			if (slotSizeValidated) {
				if (tier.length > breakTemplate.template.slotSize) {
					slotSizeValidated = false;
				}
			}
		});
		if (!slotSizeValidated) {
			showToastError(
				'Please remove one or more teams from the tier highlighted in red as there are more teams then the maximum number of teams allowed for a tier based on this template.'
			);
			return;
		}
		if (!isEdited) {
			const formData = {
				templateName: breakTemplate.template.templateName,
				tierSize: breakTemplate.template.tierSize,
				slotSize: breakTemplate.template.slotSize,
				isTemplateEdited: isEdited,
				sportTypeID: breakTemplate.template.sportTypeId,
				breakEventTemplateId: breakTemplate.template.breakTemplateId,
				customTemplateName,
				teams: getStepData(),
				tiersData
			};
			setEditedTemplate(formData);
			setActiveStep(3);
			// }
		} else if (customTemplateName) {
			contextSetTiersData(tiersData);
			// stepData.customTemplateName = customTemplateName;
			setActiveStep(3);
		} else {
			setShowSaveModal(true);
		}
	};

	const onBackClick = () => {
		setActiveStep(1);
	};
	if (loading) return null;
	return (
		<div>
			<EditEventSaveTemplateModal
				showModal={showSaveModal}
				onNext={(updatedTemplate) => saveUpdatedTemplate(updatedTemplate)}
				onClose={() => setShowSaveModal(false)}
			/>
			<div className="container justify-center text-center  shadow shadow-lg mb-6">
				<div className="flex text-center justify-center space-x-2">
					<div>
						<span className="font-bold text-lg py-10">Edit Template</span>
					</div>
					<div data-tip="Rearrange your slots however you wish. Any changes you make will be saved as a new personal template.">
						<InfoIcon />
					</div>
				</div>
				<div>
					{showResetButton && (
						<div className="h-12">
							<button
								type="button"
								style={{ float: 'right' }}
								className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6"
								onClick={resetTemplateData}
							>
								Reset
							</button>
						</div>
					)}

					<div>
						<DragDropContext onDragEnd={onDragEnd}>
							<div className="flex flex-column justify-center py-5">
								{tiersData.map((tier, i) => (
									<Droppable droppableId={i.toString()}>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												className="border text-center border-md m-2"
												style={getListStyle(
													tier.length,
													snapshot.isDraggingOver
												)}
											>
												<span className="font-bold text-md text-center p-5">
													Tier
													{' '}
													{i + 1}
												</span>
												{tier.map((team, index) => (
													<Draggable
														key={team.teamId}
														draggableId={team.teamId.toString()}
														index={index}
														type="TASK"
													>
														{(provided, snapshot) => (
															<div
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={getItemStyle(
																	snapshot.isDragging,
																	provided.draggableProps.style
																)}
																className="shadow shadow-md h-14 w-50 font-sm bg-white  items-center justify-center py-5 my-2"
															>
																{team.team}
															</div>
														)}
													</Draggable>
												))}

												{provided.placeholder}
											</div>
										)}
									</Droppable>
								))}
							</div>
						</DragDropContext>
					</div>
				</div>
			</div>
			{/* Steps Next/Previous Button  */}
			<div className="flex justify-center mx-auto">
				<div className="mr-2">
					<BackButton onClick={onBackClick} />
				</div>
				<div className="ml-2">
					<NextButton onClick={onNextClick} />
				</div>
			</div>
		</div>
	);
};
export default EditTemplate;
