import React, { useState, useEffect } from 'react';
import Modal from '../../components/modals';
import { getSlotName } from '../../utils/slot';

const RefundItemDetailModal = ({ item, onClose }) => {
	if (!item) return null;

	const [isProduct, setIsProduct] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setIsProduct(item.productId != null);
		setLoading(false);
	}, [item]);

	if (loading) return null;
	return (
		<Modal showModal={item != null} onClose={onClose} hideCloseButton>
			<div className="flex flex-col w-full">
				<div className="flex">
					<div className="flex flex-col px-4">
						{isProduct ? (
							<>
								<b>Item name</b>
								<p>{item.productName}</p>
							</>
						) : (
							<>
								<b>Slot name</b>
								<p>{getSlotName(item)}</p>
							</>
						)}
					</div>
					<div className="flex flex-col px-4">
						{isProduct ? (
							<>
								<b>Quantity</b>
								<p>{item.quantity}</p>
							</>
						) : (
							<>
								<b>Slot no</b>
								<p>{item.slotPurchased}</p>
							</>
						)}
					</div>
				</div>
				<div className="flex justify-center pt-4">
					<b className="pr-3">Cost</b>
$
					{' '}
					{Number(
						item.unitCost * (item.quantity == 0 ? 1 : item.quantity)
					).toFixed(2)}
				</div>
			</div>
		</Modal>
	);
};

export default RefundItemDetailModal;
