import React from 'react';

const BuyerRoomLeaveConfirmation = ({
	showModal,
	setShowModal,
	onExit,
	onCancel
}) => (
	<>
		{showModal ? (
			<>
				<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
					<div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
						<div className="flex flex-row justify-end p-4 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
							<svg
								className="cursor-pointer w-6 h-6"
								onClick={() => {
									setShowModal(false);
									onCancel();
								}}
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</div>
						<div className="flex flex-col px-6 py-5 bg-gray-50 mb-10">
							<div className="flex flex-row items-center">
								<svg
									className="w-20 h-20"
									fill="red"
									stroke="white"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
								<p className="font-semibold text-gray-700 mb-5 px-10 text-lg break-normal  w-96">
									Please note that you have one or more items in your cart from
									this event. Please confirm you wish to leave the room or hit
									cancel to remain in the room.
								</p>
							</div>
							<div className="flex xxs:flex-row justify-center items-center xxs:w-full xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0 mb-5">
								<div className="mr-3">
									<button
										type="button"
										className="h-12 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white bg-red-700 hover:bg-red-900 md:py-4 md:text-lg md:px-10"
										onClick={onExit}
									>
										Exit Room
									</button>
								</div>
								<div className="mr-3">
									<button
										type="button"
										className="h-12 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white bg-red-700 hover:bg-red-900 md:py-4 md:text-lg md:px-10"
										onClick={() => {
											setShowModal(false);
											onCancel();
										}}
									>
										Cancel
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="opacity-25 fixed inset-0 z-40 bg-black" />
			</>
		) : null}
	</>
);

export default BuyerRoomLeaveConfirmation;
