import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import {
	canRestartRoom,
	sellerHasNoLiveEventInProgress
} from '../api/event.request';
import useErrorNotifier from '../hooks/use-error-notifier';

const EventDetailsMenuModal = ({
	showModal,
	setShowModal,
	eventId,
	isLive,
	isPlanned,
	isRipNShip,
	setShowDeniedAccess,
	completed,
	downloadEventReport,
	onCancelEvent,
	canCancelEvent,
	conversation,
	setSelectedConversation,
	showMessageBuyersBtn,
	canChangePicture,
	onChangePicture,
	hasUnsoldSlots,
	setShowRandomizedBreakUnsoldSlotsWarning
}) => {
	const [canRestartLiveEvent, setCanRestartLiveEvent] = useState(false);
	const { showToastError } = useErrorNotifier();
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (!showModal) return;
		if (completed && !isRipNShip) {
			canRestartRoom(eventId)
				.then(() => {
					setCanRestartLiveEvent(true);
				})
				.catch((err) => {
					const mute = err;
					console.error(mute);
					setCanRestartLiveEvent(false);
				})
				.finally(() => setLoaded(true));
		} else {
			setLoaded(true);
		}
	}, [showModal]);

	if (!loaded) return null;

	return (
		<>
			{showModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
							<div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
								<p className="font-semibold text-gray-800">
									Event Details Menu
								</p>
								<svg
									className="cursor-pointer w-6 h-6"
									onClick={() => setShowModal(false)}
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</div>
							<div className="flex flex-col px-6 py-5 bg-gray-50">
								{(isLive || isPlanned) && (
									<button
										type="button"
										onClick={() => {
											if (hasUnsoldSlots) {
												setShowRandomizedBreakUnsoldSlotsWarning(true);
												return;
											}

											sellerHasNoLiveEventInProgress()
												.then(() => {
													navigator.mediaDevices
														.getUserMedia({
															audio: true,
															video: true
														})
														.then(() => {
															navigate(`/event/room/${eventId}`);
														})
														.catch(() => {
															setShowDeniedAccess(true);
														})
														.finally(() => {
															setShowModal(false);
														});
												})
												.catch((err) => {
													showToastError(err);
												});
										}}
										activeClassName="bg-gray-200"
										className="item mt-0 flex items-center text-base font-normal text-black w-full px-6 py-3 hover:bg-gray-200 active:bg-green-700"
									>
										<svg
											className="w-6 h-6 mr-4"
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
											/>
										</svg>
										<span className="item-text">Start Live Event</span>
									</button>
								)}
								{showMessageBuyersBtn && !isRipNShip && (
									<button
										type="button"
										onClick={() => {
											if (!conversation) return;

											setSelectedConversation(conversation);
										}}
										activeClassName="bg-gray-200"
										className="item mt-0 flex items-center text-base font-normal text-black w-full px-6 py-3 hover:bg-gray-200 active:bg-green-700"
									>
										<svg
											className="w-6 h-6 mr-4"
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
											/>
										</svg>
										<span className="item-text">Message buyers</span>
									</button>
								)}

								{canChangePicture && (
									<button
										type="button"
										onClick={onChangePicture}
										activeClassName="bg-gray-200"
										className="item mt-0 flex items-center text-base font-normal text-black w-full px-6 py-3 hover:bg-gray-200 active:bg-green-700"
									>
										<svg
											className="w-6 h-6 mr-4"
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
											<path
												fillRule="evenodd"
												d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
												clipRule="evenodd"
											/>
										</svg>
										<span className="item-text">Change picture</span>
									</button>
								)}

								{canCancelEvent && isPlanned && (
									<button
										type="button"
										onClick={onCancelEvent}
										activeClassName="bg-gray-200"
										className="item mt-0 flex items-center text-base font-normal text-black w-full px-6 py-3 hover:bg-gray-200 active:bg-green-700"
									>
										<svg
											className="w-6 h-6 mr-4"
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M6 18L18 6M6 6l12 12"
											/>
										</svg>
										<span className="item-text">Cancel event</span>
									</button>
								)}
								{completed && (
									<>
										{canRestartLiveEvent && (
											<button
												type="button"
												onClick={() => {
													navigator.mediaDevices
														.getUserMedia({
															audio: true,
															video: true
														})
														.then(() => {
															navigate(`/event/room/${eventId}`, {
																state: { restart: true }
															});
														})
														.catch(() => {
															setShowDeniedAccess(true);
														})
														.finally(() => {
															setShowModal(false);
														});
												}}
												activeClassName="bg-gray-200"
												className="item mt-0 flex items-center text-base font-normal text-black w-full px-6 py-3 hover:bg-gray-200 active:bg-green-700"
											>
												<svg
													className="w-6 h-6 mr-4"
													fill="none"
													stroke="currentColor"
													viewBox="0 0 24 24"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
													/>
												</svg>
												<span className="item-text">Restart Event</span>
											</button>
										)}
										<button
											type="button"
											onClick={downloadEventReport}
											activeClassName="bg-gray-200"
											className="item mt-0 flex items-center text-base font-normal text-black w-full px-6 py-3 hover:bg-gray-200 active:bg-green-700"
										>
											<svg
												className="w-6 h-6 mr-4"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
												/>
											</svg>
											<span className="item-text">
												Download Event Report as Excel
											</span>
										</button>
									</>
								)}
							</div>
						</div>
					</div>
					<div
						className="opacity-25 fixed inset-0 z-40 bg-black"
						onClick={() => setShowModal(false)}
						onKeyDown={() => setShowModal(false)}
					/>
				</>
			) : null}
		</>
	);
};

export default EventDetailsMenuModal;
