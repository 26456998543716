import React from 'react';

const AvailableSlots = ({ availableSlotNumber, totalNumberOfSlots }) => (
	<p
		className="w-max text-xs font-semibold text-white bg-blue-700 border border-blue-700 rounded-md px-1 flex items-center justify-center feed=button whitespace-nowrap"
		style={{ maxHeight: '1.6rem' }}
	>
		{availableSlotNumber > 0 ? (
			<>
				Available slots:
				{' '}
				{availableSlotNumber}
/
				{totalNumberOfSlots}
			</>
		) : (
			<>Sold Out</>
		)}
	</p>
);

export default AvailableSlots;
