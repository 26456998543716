import Moment from 'moment';
import axiosConfig from './config';
import { getCurrentUser, getAccessToken } from '../utils/auth';

export const authHeaders = () => ({ headers: { Authorization: `Bearer ${getAccessToken()}` } });

export const getCreateEventUserTemplates = async (breakTemplateOwnerId) => await axiosConfig.get(`event/templates/${breakTemplateOwnerId}`);
export const getEventStepTeamDistribution = async (templateId) => await axiosConfig.get(`event/team_distribution/${templateId}`);
export const saveEventStep = async (formData) => await axiosConfig.post('event/add', formData);
export const addEventProducts = async (eventId, products, productSelectionId) => await axiosConfig.post(`event/add_products/${eventId}/${productSelectionId}`, products);
export const updateInventory = async (eventId, selectedProducts) => await axiosConfig.put(`event/products/update-inventory/${eventId}`, selectedProducts);
export const getEventStepPresetData = async (
	sportType,
	numberOfTiers,
	numberOfSlots
) => {
	try {
		const res = await axiosConfig.get(`event/templates/${sportType}/${numberOfTiers}/${numberOfSlots}`);
		return res;
	} catch (error) {
		return { status: 400, error: error.response };
	}
};
export const getEventStepInitials = async () => await axiosConfig.get('event/initial');

export const getCreatedEventBreakTemplates = async () => await axiosConfig.get('event/templates');
export const getCreatedEventBreakTemplatesByTiers = async (tiers) => await axiosConfig.get(`event/templates-by-tiers/${tiers}`);
export const getUserTemplatesByUser = async () => await axiosConfig.get('event/templates-by-user');
export const getUserTemplates = async () => {
	try {
		const user = getCurrentUser();
		const res = await axiosConfig.get(`event/templates/${user.id}`);
		return res;
	} catch (error) {
		console.error(error.response);
		return { status: 400, error: error.response };
	}
};

export const getUserTemplatesByTiers = async (tiers) => {
	try {
		const user = getCurrentUser();
		const res = await axiosConfig.get(`event/templates/${user.id}/${tiers}`);
		return res;
	} catch (error) {
		console.error(error.response);
		return { status: 400, error: error.response };
	}
};
export const saveEventTemplate = async (formData) => await axiosConfig.post('event/templates/create', formData);
export const getEventDetails = async (id, liveEvent = true) => await axiosConfig.get(`event/${id}/${liveEvent}`);
export const updateChatId = async (eventId, chatId) => await axiosConfig.put(`event/room/chat/${eventId}/${chatId}`, null);
export const getEventMessages = async (eventRoomId) => await axiosConfig.get(`event/room/messages/${eventRoomId}`);
export const uploadEventImages = async (files, eventId) => {
	const headers = authHeaders();

	const formData = new FormData();
	files.forEach((f) => {
		formData.append('images', f);
	});
	headers['Content-Type'] = 'multipart/form-data';
	headers.redirect = 'follow';
	headers.mode = 'no-cors';
	return await axiosConfig.post(`event/images/${eventId}`, formData, headers);
};

export const getRoomTokens = async (eventId, nickname) => {
	const token = getAccessToken();
	const data = { eventId };
	if (!token) {
		data.nickname = nickname + (Math.floor(Math.random() * 10) + 1).toString();
		data.chatNickname = nickname;
	}

	return await axiosConfig.post('event/room/tokens', data);
};

export const isEventOwner = async (eventId) => await axiosConfig.get(`event/owner/${eventId}`);
export const startRoom = async (eventId, restart = false) => await axiosConfig.post(`event/room/create/${eventId}/${restart}`, null);
// Difference between `stopRoom`and `stopEventRoom` is this method will stop the event as opposed to stopeventroom where the user is removed from the room
export const stopRoom = async (eventId) => await axiosConfig.put(`event/stop/${eventId}`, null);
export const pauseRoom = async (eventId) => await axiosConfig.put(`event/pause/${eventId}`, null);
export const isEventStopped = async (eventId) => await axiosConfig.get(`event/stopped/${eventId}`);
export const exitRoom = async (eventId, nickname) => await axiosConfig.delete(`event/room/delete/${eventId}/${nickname}`);
export const exitRoomViaFetch = (eventId, nickname) => fetch(`${axiosConfig.defaults.baseURL}event/room/delete/${eventId}/${nickname}`, {
	keepalive: true,
	method: 'DELETE',
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${getAccessToken()}`,
	},
});
export const exitRoomByAnonymousToken = async (eventId, token) => await axiosConfig.delete(`event/room/delete/anonymous/${eventId}/${token}`);
export const exitRoomByAnonymousTokenViaFetch = (eventId, token) => fetch(`${axiosConfig.defaults.baseURL}event/room/delete/anonymous/${eventId}/${token}`, {
	keepalive: true,
	method: 'DELETE',
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${getAccessToken()}`,
	},
});
export const stopEventRoomViaFetch = (eventId) => fetch(`${axiosConfig.defaults.baseURL}event/stop/${eventId}`, {
	keepalive: true,
	method: 'PUT',
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${getAccessToken()}`,
	},
});
export const stopEventRoom = async (eventId) => await axiosConfig.put(`event/stop/${eventId}`, null);
export const getAttendees = async (eventId) => await axiosConfig.get(`event/room/attendees/${eventId}`);
export const isRoomHost = async (eventId) => await axiosConfig.get(`event/is-host/${eventId}`);
export const loadEventBuyers = async (eventId) => await axiosConfig.get(`event/buyers/${eventId}`);
export const messageBuyers = async (requestBody) => await axiosConfig.post('event/buyers/message`', requestBody);
export const verifySeller = async (eventId) => await axiosConfig.get(`event/${eventId}/verify-seller`);
export const addBookmark = async (formData, eventId) => {
	const headers = authHeaders();
	headers['Content-Type'] = 'multipart/form-data';
	headers.redirect = 'follow';
	headers.mode = 'no-cors';

	const currentDate = Moment.utc(new Date()).toJSON();
	return await axiosConfig.post(`event/room/bookmark/${eventId}/${currentDate}`, formData, headers);
};
export const moveQueuePosition = async (eventId, orderId, position) => await axiosConfig.put(
	`event/ripnship/queue/move/${eventId}/${orderId}/${position}`, null
);
export const exitQueuePosition = async (eventId, userId) => await axiosConfig.put(
	`event/ripnship/queue/exitturn/${eventId}/${userId}`,
	null
);
export const joinRipNShipQueue = async (eventId) => await axiosConfig.post(`event/ripnship/join/${eventId}`, null);
export const initiateEndTurnRequest = async (eventId) => await axiosConfig.put(`event/ripnship/queue/initiate/endturn/${eventId}`, null);
export const endTurnRequest = async (eventId, userId, position) => await axiosConfig.put(
	`event/ripnship/queue/endturn/${eventId}/${userId}/${position}`,
	null
);
export const exitQueue = async (eventId, userId) => await axiosConfig.delete(`event/ripnship/queue/exit/${eventId}/${userId}`);
export const requestMic = async (eventId, participantId, twilioIdentity) => await axiosConfig.post(
	`event/room/mic/request/${eventId}/${participantId}/${twilioIdentity}`,
	null,
);
export const requestedMicDecision = async (eventId, approved, users, updateAll) => await axiosConfig.put(
	'event/room/mic/request/decision', {
		eventId,
		approved,
		users,
		updateAll
	}
);
export const subscribeToTwilioTrack = async (eventId, participantId, trackId) => await axiosConfig.put(
	`event/room/subscribe/track/${eventId}/${participantId}/${trackId}`,
	null
);

export const subscribeToEventRoomTwilioTracks = async (eventId, twilioParticipantId) => await axiosConfig.post(`event/room/subscribe/${eventId}/${twilioParticipantId}`, null);
export const reissueVideoToken = async (eventId, nickname) => await axiosConfig.post('event/room/tokens/reissue', {
	eventId, nickname
});

export const stopMicrophoneAccess = async (eventId) => await axiosConfig.put(
	`event/room/mic/end/${eventId}`,
	null
);
export const createRipNShipEvent = async (ripNShip, eventId) => await axiosConfig.post(`event/ripnship/add/${eventId}`, ripNShip);
export const startRipNShipEvent = async (eventId) => await axiosConfig.post(`event/ripnship/start/${eventId}`, null);
export const getAvailableRipNShip = async () => await axiosConfig.get('event/ripnship/active');
export const planRipNShip = async (eventName, eventTime, parentEventId) => await axiosConfig.post('event/ripnship/plan', {
	eventName, eventTime, parentEventId
});
export const getBookmarkedEvents = async () => await axiosConfig.get('event/bookmarks');
export const bookmarkOrUnbookmarkEvent = async (eventId) => await axiosConfig.post(`event/bookmark/${eventId}`, null);
export const getBookmarkedEvent = async (eventId) => await axiosConfig.get(`event/bookmark/${eventId}`);
export const getEvents = async (listType, pageSize, currentPage, eventType = '') => await axiosConfig.get(`event/list/${listType}/${pageSize}/${currentPage}/${eventType}`);
export const banUserFromEvent = async (eventId, nickname) => await axiosConfig.put(`event/room/ban/${eventId}/${nickname}`, null);
export const isUserEligibleForEvent = async (eventId, token = 'undefined') => await axiosConfig.get(`event/room/eligible/${eventId}/${token}`);
export const getQueueOrderPurchasedProducts = async (eventId, userId) => await axiosConfig.get(`event/ripnship/products/${eventId}/${userId}`);
export const getRipNShipEventDetail = async (eventId) => await axiosConfig.get(`event/ripnship/${eventId}`);
export const getQueueOrderPurchasedProductsByQueueOrder = async (queueOrderId) => await axiosConfig.get(`event/ripnship/products/order/${queueOrderId}`);
export const hasUserPurchasedItemsInEvent = async (eventId, userId) => await axiosConfig.get(`event/room/purchased/${eventId}/${userId}`);
export const updateRoomStatusToStarted = async (eventId) => await axiosConfig.put(`event/room/has-started/${eventId}`, null);
export const hasRoomStatusBeenUpdatedToStarted = async (eventId) => await axiosConfig.get(`event/room/has-been-started/${eventId}`);
export const updateEventRoomVideoTrackId = async (eventId, trackId) => await axiosConfig.put(`event/room/videotrack/${eventId}/${trackId}`, null);
export const getEventRoomVideoTrackId = async (eventId) => await axiosConfig.get(`event/room/videotrack/${eventId}`);
export const updateEventName = async (eventNameBody) => await axiosConfig.put('event/update-name', eventNameBody);
export const updateEventDate = async (eventDateBody) => await axiosConfig.put('event/update-date', eventDateBody);
export const getUndetailedLiveEvents = async () => await axiosConfig.get('event/undetailed/live');
export const showRoomMonitoringTool = async () => await axiosConfig.get('event/room/show-monitoring-tool');
export const getParticipantsFromTwilio = async (eventId) => await axiosConfig.get(`event/room/twilio/participants/${eventId}`);
export const getFailedAttendees = async (eventId) => await axiosConfig.get(`event/room/attendees/failed/${eventId}`);
export const getUndetailedEvent = async (eventId) => await axiosConfig.get(`event/undetailed/${eventId}`);
export const savedEventJoinFailure = async (eventId, reason) => await axiosConfig.post(`event/room/attendees/failed/${eventId}/${reason}`, null);
export const removeUsersFromEvent = async (eventId, userIds, anonymousTokens) => await axiosConfig.put('event/room/remove', { eventId, userIds, tokens: anonymousTokens });
export const getTwilioMonitoringToolToken = async (eventId) => await axiosConfig.get(`event/room/monitoring-tool/token/${eventId}`);
export const generateTwilioMonitoringToolToken = async (eventId) => await axiosConfig.put(`event/room/monitoring-tool/token/${eventId}`);
export const saveSubscribedTrack = async (eventId, nickname, trackId, kind) => await axiosConfig.post(`event/room/subscribe/${eventId}/${nickname}/${trackId}/${kind}`);
export const saveSubscribedTrackAnonymousUser = async (eventId, token, trackId, kind) => await axiosConfig.post(`event/room/subscribe/anonymous/${eventId}/${token}/${trackId}/${kind}`);
export const updateEventRoomDate = async (eventId) => await axiosConfig.put('event/room/date', { eventId, startedDate: Moment.utc(new Date()).toJSON() });
export const removeMonitoringToolTwilioToken = async (eventId) => await axiosConfig.delete(`event/room/monitoring-tool/${eventId}`);
export const getEventReport = async (eventId, type = 'excel', returnType = 'base64') => await axiosConfig.get(`event/report/${eventId}/${type}/${returnType}`);
export const sendEventReportEmail = async (eventId, base64) => await axiosConfig.post('event/report/email', { eventId, bodyBase64: base64 });
export const canRestartRoom = async (eventId) => await axiosConfig.get(`event/room/can-restart/${eventId}`);
export const cancelEvent = async (eventId) => await axiosConfig.put(`event/cancel/${eventId}`);
export const closeRipNShipQueue = async (eventId, hasFutureEvent, eventEnding) => await axiosConfig.put(`event/ripnship/close-line/${eventId}/${eventEnding}/${hasFutureEvent}`);
export const activeLineDecideItemStatus = async (eventId, keepItems = false) => await axiosConfig.put(`event/ripnship/active-line/keep-items/${eventId}/${keepItems}`);
export const isRipNShipQueueClosed = async (eventId) => await axiosConfig.get(`event/ripnship/line-closed/${eventId}`);
export const addBuyersToNextLineInFutureEvent = async (eventId) => await axiosConfig.post(`event/ripnship/buyers/add-to-line/next-event/${eventId}`);
export const shouldShowKeepItemsOptionsInRipNShip = async (eventId) => await axiosConfig.get(`event/ripnship/${eventId}/current-line/show-keep-items-options`);
export const openRipNShipQueueLine = async (eventId) => await axiosConfig.put(`event/ripnship/open-line/${eventId}`);
export const generateEventRequestLink = async (eventId, emailAddress) => await axiosConfig.post('event/request-link', {
	eventId,
	emailAddress
});
export const getEventFeed = async (eventId) => await axiosConfig.get(`event/feed/${eventId}`);
export const processRefundsOnRipNShipNoContinuation = async (eventId) => await axiosConfig.put(`event/ripnship/no-continuation/${eventId}`, null);
export const sellerHasNoLiveEventInProgress = async (excludedEventId = '') => {
	let apiMethod = 'event/no-live-event-in-progress';
	if (excludedEventId && excludedEventId.length) {
		apiMethod += `/${excludedEventId}`;
	}
	await axiosConfig.get(apiMethod);
};
export const revertEventStartedStatus = async (eventId) => await axiosConfig.put(`event/room/revert-status/${eventId}`);
export const getRipNShipActiveProducts = async (eventId) => await axiosConfig.get(`event/ripnship/products/${eventId}`);
export const deleteEventProduct = async (eventProductId) => await axiosConfig.delete(`event/product/remove/${eventProductId}`);
export const addTwilioParticipantInfoToEventAttendee = async (eventId, twilioRoomIdentity, twilioParticipantId) => await axiosConfig.put('event/room/add-twilio-participant-info', {
	eventId,
	twilioParticipantId,
	twilioRoomIdentity,
});

export const unsubcribeAudioTracksFromEvent = async (eventId, twilioParticipantId, expireActiveMicTracksAsWell = false) => await axiosConfig.put('event/room/tracks/unsubscribe', {
	eventId,
	twilioParticipantId,
	kind: 'audio',
	expireActiveMic: expireActiveMicTracksAsWell
});
export const cancelMicRequest = async (eventId, twilioParticipantId) => await axiosConfig.put(`event/room/mic/cancel/${eventId}/${twilioParticipantId}`);
export const approveMicRequestByBuyer = async (eventId, oldTwilioParticipantId, newTwilioParticipantId, twilioTrackId) => await axiosConfig.put('event/room/mic/approved-mic-info', {
	eventId, oldTwilioParticipantId, newTwilioParticipantId, twilioTrackId
});
export const updateMutedMicStatus = async (eventId, users, muted, initiatedBySeller, muteOrUnmuteAll) => await axiosConfig.put('event/room/mic/mute-status',
	{
		eventId,
		muted,
		initiatedBySeller,
		users,
		muteOrUnmuteAll
	});
export const closeActiveMic = async (eventId, twilioParticipantIds, initiatedBySeller, closeAll) => await axiosConfig.put('event/room/mic/close', {
	eventId,
	initiatedBySeller,
	twilioParticipantIds,
	closeAllMics: closeAll
});
export const expireActiveMicSessionsOrRequests = async (eventId, twilioIdentity) => await axiosConfig.put(`event/room/mic/expire/requests-and-active-mic-sessions/${eventId}/${twilioIdentity}`);
export const deleteMicRequest = async (eventId, twilioParticipantId) => await axiosConfig.delete(`event/room/mic/request/delete/${eventId}/${twilioParticipantId}`);
