import axiosConfig from './config';
import { getAccessToken } from '../utils/auth';

export const authHeaders = () => ({ headers: { Authorization: `Bearer ${getAccessToken()}` } });

export const getProducts = async () => await axiosConfig.get('product/list');
export const getProductsByStatus = async (status) => await axiosConfig.get(`product/list/${status}`);
export const addProduct = async (product) => await axiosConfig.post('product/add', product);
export const getProduct = async (productId) => await axiosConfig.get(`product/${productId}`);
export const updateProductStatus = async (products, status) => await axiosConfig.put(`product/update/status/${status}`, products);
export const uploadProductImage = async (formData, productId, token) => {
	let headers = authHeaders();
	if (token) {
		headers = { headers: { Authorization: `Bearer ${token}` } };
	}

	headers['Content-Type'] = 'multipart/form-data';
	headers.redirect = 'follow';
	headers.mode = 'no-cors';
	return await axiosConfig.post(
		`product/image/${productId}`,
		formData,
		headers
	);
};
export const updateProduct = async (product) => await axiosConfig.put('product/update', product);
export const deleteProductImage = async (productId) => await axiosConfig.delete(`product/image/${productId}`);
export const hasAvailableProductsInStock = async () => await axiosConfig.get('product/has-available-stock');
export const importMultipleProducts = async (products) => await axiosConfig.post('product/import', products);
export const uploadProductsViaCsv = async (formData, token) => {
	let headers = authHeaders();
	if (token) {
		headers = { headers: { Authorization: `Bearer ${token}` } };
	}

	headers['Content-Type'] = 'multipart/form-data';
	headers.redirect = 'follow';
	headers.mode = 'no-cors';
	return await axiosConfig.post(
		'product/validate/csv',
		formData,
		headers
	);
};
export const reserveProduct = async (eventId, eventProductId, quantity) => await axiosConfig.post(
	`product/reserve/${eventId}/${eventProductId}/${quantity}`,
	null
);
export const releaseProduct = async (eventId, eventProductId) => await axiosConfig.post(
	`product/release/${eventId}/${eventProductId}`,
	null
);
export const startProductPayment = async (eventId, eventProductIds) => await axiosConfig.post(
	`product/startpayment/${eventId}`,
	eventProductIds
);
export const updatePendingProducts = async (eventId) => await axiosConfig.put(`product/update-pending/${eventId}`, null);
export const getProductPath = async (productId) => await axiosConfig.get(`product/image/${productId}`);
