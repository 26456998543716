import { createUseStyles } from 'react-jss';

export const connectorStyleDefaults = {
	disabledColor: '#bdbdbd',
	activeColor: '#ed1d24',
	completedColor: '#a10308',
	size: 1,
	style: 'solid'
};

export const useConnectorStyles = createUseStyles({
	ConnectorContainer: (props) => ({
		top: `calc((${props.stepSize} - ${props.size}) / 2)`,
		left: `calc(-50% + ${props.stepSize} - 8px)`,
		right: `calc(50% + ${props.stepSize} - 8px)`,
		position: 'absolute'
	}),
	Connector: (props) => ({
		borderTopStyle: props.style,
		borderTopWidth: props.size,
		borderColor: props.disabledColor,
		display: 'block',
		'&.completed': {
			borderColor: props.completedColor
		},
		'&.active': {
			borderColor: props.activeColor
		}
	})
});
