import React from 'react';

export function AboutUsIcon() {
	return (
		<svg
			version="1.0"
			xmlns="http://www.w3.org/2000/svg"
			className="mr-4"
			width="20"
			height="20"
			viewBox="0 0 2000.000000 2000.000000"
			preserveAspectRatio="xMidYMid meet"
		>
			<g
				transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)"
				fill="#000000"
				stroke="none"
			>
				<path
					d="M9375 19985 c-2065 -127 -4044 -896 -5657 -2197 -480 -388 -971 -869
-1384 -1358 -676 -800 -1248 -1763 -1639 -2755 -398 -1010 -622 -2036 -685
-3139 -13 -226 -13 -849 0 -1071 120 -2071 834 -3985 2094 -5610 490 -632
1122 -1263 1753 -1753 1608 -1246 3521 -1967 5543 -2087 314 -19 886 -19 1200
0 1762 105 3449 667 4920 1640 797 527 1552 1202 2154 1925 1394 1673 2191
3700 2316 5884 13 230 13 842 0 1072 -104 1829 -671 3525 -1683 5039 -379 567
-721 980 -1246 1506 -351 350 -595 568 -922 821 -1607 1243 -3519 1963 -5539
2083 -299 18 -930 18 -1225 0z m3250 -3407 c-3 -24 -37 -306 -75 -628 -142
-1195 -173 -1452 -315 -2645 -80 -671 -157 -1319 -171 -1440 -14 -121 -29
-224 -34 -228 -9 -10 -25 -14 -1480 -341 -646 -146 -1677 -378 -2290 -516
-613 -138 -1252 -282 -1420 -320 -168 -38 -676 -152 -1130 -255 -454 -102
-904 -203 -1000 -225 -96 -21 -176 -38 -178 -36 -2 2 26 203 62 447 36 244
113 767 171 1162 58 394 152 1038 210 1430 57 391 143 975 190 1297 46 322 87
600 90 617 4 23 12 34 28 38 12 3 148 34 302 69 154 36 498 115 765 176 1058
243 1485 341 1715 394 302 69 1164 267 1920 441 319 73 857 197 1195 274 338
78 797 184 1020 235 223 52 411 95 418 95 10 1 12 -10 7 -41z m2988 -5820 c-5
-49 -161 -1619 -348 -3503 -91 -918 -169 -1681 -173 -1695 -6 -21 -21 -29
-102 -51 -52 -15 -176 -50 -275 -77 -99 -28 -524 -148 -945 -266 -421 -118
-1024 -287 -1340 -376 -316 -89 -629 -176 -695 -195 -66 -18 -322 -90 -570
-159 -1018 -287 -1341 -377 -2050 -576 -297 -83 -610 -171 -695 -195 -811
-228 -960 -269 -965 -263 -4 3 16 203 44 444 43 370 126 1095 447 3894 19 168
41 362 49 433 8 70 16 131 19 136 3 4 245 -48 538 -116 293 -68 747 -173 1008
-234 261 -61 630 -146 820 -190 190 -44 381 -88 425 -99 44 -10 238 -55 430
-100 193 -45 445 -103 560 -130 116 -27 338 -79 495 -115 439 -102 818 -190
1124 -261 153 -36 279 -64 280 -62 2 2 38 352 80 778 219 2178 255 2533 263
2540 4 5 170 56 368 115 198 59 545 163 770 231 226 67 417 123 425 123 12 1
15 -7 13 -31z"
				/>
			</g>
		</svg>
	);
}

export function OurPoliciesIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="mr-4"
			width="20"
			height="20"
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
			/>
		</svg>
	);
}

export function ContactUsIcon() {
	return (
		<svg
			version="1.0"
			xmlns="http://www.w3.org/2000/svg"
			className="mr-4"
			width="20"
			height="20"
			viewBox="0 0 375.000000 390.000000"
			preserveAspectRatio="xMidYMid meet"
		>
			<g
				transform="translate(0.000000,390.000000) scale(0.100000,-0.100000)"
				fill="#000000"
				stroke="none"
			>
				<path
					d="M1785 3675 c-92 -20 -164 -44 -235 -80 -267 -135 -441 -379 -491
		-688 -8 -55 -17 -81 -29 -87 -10 -5 -27 -26 -39 -45 -19 -33 -21 -51 -21 -221
		0 -203 4 -220 60 -268 26 -21 42 -26 87 -26 64 0 99 17 131 66 21 32 22 44 22
		222 0 204 -7 236 -56 269 -34 22 -32 54 7 193 49 170 186 344 346 439 37 22
		104 52 148 68 74 26 91 28 235 28 144 0 161 -2 235 -28 161 -56 303 -166 394
		-302 67 -99 85 -141 116 -265 21 -83 19 -115 -9 -133 -50 -33 -56 -64 -56
		-278 l0 -198 -42 -25 c-51 -29 -138 -58 -229 -76 -64 -13 -68 -12 -94 8 -24
		19 -41 22 -114 22 -96 0 -120 -10 -141 -60 -17 -42 2 -88 46 -106 63 -27 224
		-9 224 24 0 5 35 15 78 21 102 16 217 54 285 95 36 22 60 30 69 25 7 -5 40 -9
		72 -9 57 0 61 2 103 45 l43 45 0 195 c0 179 -2 197 -21 230 -12 19 -29 40 -39
		45 -12 6 -21 32 -29 87 -50 309 -224 553 -491 688 -168 85 -393 116 -565 80z"
				/>
				<path
					d="M1870 3454 c-275 -32 -510 -245 -570 -516 -12 -52 -11 -55 17 -98 48
		-72 53 -100 53 -287 l0 -174 31 -62 c76 -151 188 -281 296 -343 27 -16 50 -31
		53 -35 16 -22 111 -42 200 -42 91 0 200 23 200 43 0 4 13 13 29 20 16 6 32 18
		35 25 3 10 -19 14 -93 17 -90 3 -102 6 -136 32 -104 80 -94 238 19 307 26 16
		50 19 144 19 81 0 121 -4 142 -15 35 -18 84 -12 174 20 l61 22 5 184 c6 190 9
		206 55 274 26 38 27 41 15 93 -53 240 -245 437 -485 500 -46 13 -194 22 -245
		16z"
				/>
				<path
					d="M1310 1960 c-8 -5 -27 -10 -42 -10 -16 0 -28 -4 -28 -9 0 -5 -10 -13
		-22 -16 -24 -7 -75 -28 -118 -49 -225 -111 -389 -313 -461 -571 -18 -64 -23
		-106 -23 -210 0 -181 30 -272 117 -352 56 -52 105 -77 199 -102 79 -22 239
		-42 249 -32 3 3 11 42 18 86 34 222 148 405 326 524 152 102 269 135 454 129
		147 -5 239 -31 356 -100 69 -41 192 -154 233 -213 69 -103 114 -216 133 -340
		7 -44 15 -83 18 -86 10 -10 170 10 249 32 209 56 300 169 318 394 14 191 -52
		418 -166 571 -69 92 -174 188 -252 233 -20 10 -44 24 -54 30 -39 21 -108 51
		-120 51 -7 0 -19 6 -26 14 -14 14 -54 24 -128 33 l-45 5 -14 -48 c-29 -96
		-147 -180 -318 -225 -114 -30 -307 -30 -426 0 -168 43 -289 128 -317 224 -14
		46 -15 47 -55 47 -22 0 -47 -5 -55 -10z"
				/>
				<path
					d="M1787 1194 c-130 -35 -272 -136 -344 -244 -120 -178 -144 -378 -71
		-570 66 -171 186 -288 372 -364 55 -23 357 -23 412 0 186 76 306 193 372 364
		130 340 -68 723 -422 815 -70 18 -251 18 -319 -1z m111 -226 l-3 -103 -57 0
		c-32 0 -62 5 -68 11 -23 23 75 193 113 194 16 0 17 -10 15 -102z m169 59 c44
		-58 77 -137 63 -151 -6 -6 -36 -11 -68 -11 l-57 0 -3 103 c-2 92 -1 102 15
		102 10 0 32 -20 50 -43z m-367 -5 c0 -4 -4 -12 -9 -17 -4 -6 -16 -32 -26 -58
		-18 -46 -20 -49 -49 -43 -56 11 -55 24 4 77 51 45 80 60 80 41z m580 -41 c59
		-53 60 -66 4 -77 -29 -6 -31 -3 -49 43 -10 26 -22 52 -26 58 -5 5 -9 13 -9 17
		0 19 29 4 80 -41z m-712 -178 l54 -17 -7 -56 c-4 -30 -9 -58 -12 -62 -2 -5
		-38 -8 -79 -8 -70 0 -74 1 -74 23 0 12 9 47 20 77 11 30 20 56 20 57 0 7 27 2
		78 -14z m842 14 c0 -1 9 -27 20 -57 11 -30 20 -65 20 -77 0 -22 -4 -23 -74
		-23 -41 0 -77 3 -79 8 -3 4 -8 32 -12 62 l-7 56 49 16 c44 15 83 22 83 15z
		m-522 -80 c6 -5 12 -25 12 -43 l0 -34 -80 0 c-44 0 -83 -1 -87 -2 -12 -2 -16
		30 -9 67 l7 34 72 -6 c40 -3 78 -10 85 -16z m287 -11 c8 -38 4 -72 -8 -68 -4
		1 -43 2 -87 2 l-80 0 0 34 c0 44 14 54 80 59 30 2 63 4 72 5 12 1 19 -8 23
		-32z m-570 -198 c3 -7 6 -37 7 -66 l3 -53 -40 -15 c-35 -12 -85 -19 -85 -11 0
		1 -9 27 -20 57 -11 30 -20 65 -20 77 0 22 4 23 75 23 52 0 77 -4 80 -12z m290
		-33 c0 -46 -13 -52 -106 -54 -43 -1 -58 3 -63 15 -8 21 -8 69 1 78 4 4 43 5
		88 4 l80 -3 0 -40z m283 16 c2 -12 1 -34 -2 -47 -6 -22 -11 -24 -64 -23 -85 1
		-106 10 -110 43 -6 53 0 57 90 54 79 -3 83 -4 86 -27z m272 6 c0 -12 -9 -47
		-20 -77 -11 -30 -20 -56 -20 -57 0 -8 -50 -1 -85 11 l-40 15 3 53 c4 79 2 78
		87 78 71 0 75 -1 75 -23z m-552 -281 c3 -120 -6 -137 -50 -86 -38 45 -91 148
		-84 165 6 16 37 23 91 21 l40 -1 3 -99z m235 85 c24 -24 -94 -214 -121 -197
		-13 8 -17 197 -4 210 10 11 112 0 125 -13z m-483 -42 c41 -84 49 -109 36 -109
		-15 0 -116 87 -116 99 0 9 51 30 64 27 5 -1 13 -9 16 -17z m663 4 c26 -8 22
		-16 -34 -67 -65 -59 -87 -62 -59 -6 11 21 20 45 20 53 0 20 23 38 40 31 8 -3
		23 -9 33 -11z"
				/>
			</g>
		</svg>
	);
}

export function PartnersIcon() {
	return (
		<svg
			version="1.0"
			xmlns="http://www.w3.org/2000/svg"
			className="mr-3"
			width="25"
			height="25"
			viewBox="0 0 950.000000 648.000000"
			preserveAspectRatio="xMidYMid meet"
		>
			<g
				transform="translate(0.000000,648.000000) scale(0.100000,-0.100000)"
				fill="#000000"
				stroke="none"
			>
				<path
					d="M1187 5360 c-57 -15 -90 -34 -131 -81 -51 -55 -72 -107 -226 -544
-15 -44 -90 -251 -165 -460 -206 -573 -225 -631 -232 -698 -13 -149 68 -253
248 -318 35 -12 66 -26 69 -31 10 -14 777 -288 807 -288 16 0 30 33 98 230 63
185 165 473 193 550 12 30 69 190 128 355 58 165 147 413 196 550 129 359 130
364 125 379 -6 14 -34 25 -387 153 -560 202 -637 224 -723 203z"
				/>
				<path
					d="M7940 5256 c-36 -13 -148 -60 -250 -104 -102 -43 -264 -113 -361
-155 -174 -74 -299 -134 -299 -141 0 -3 43 -105 96 -228 201 -469 329 -767
374 -873 25 -60 83 -195 127 -300 45 -104 121 -284 170 -400 82 -192 91 -210
112 -207 28 3 146 51 426 172 116 51 263 114 328 142 65 28 123 56 130 63 7 7
27 20 44 28 51 27 129 120 142 171 24 96 8 165 -86 386 -338 787 -541 1255
-563 1300 -34 67 -91 126 -145 151 -59 27 -163 25 -245 -5z"
				/>
				<path
					d="M2645 4838 c-45 -129 -130 -363 -142 -393 -8 -22 -30 -83 -48 -135
-18 -52 -70 -196 -115 -320 -45 -124 -97 -268 -115 -320 -18 -52 -59 -165 -90
-250 -31 -85 -63 -177 -72 -205 -8 -27 -28 -84 -43 -125 -16 -41 -59 -158 -95
-260 -36 -102 -91 -254 -122 -338 -30 -84 -53 -155 -51 -157 10 -10 114 -25
172 -25 128 0 249 49 351 142 35 32 67 58 71 58 5 0 31 -15 59 -34 27 -19 143
-95 256 -169 113 -74 293 -197 400 -274 517 -371 1137 -787 1286 -862 140 -70
308 -111 457 -111 105 0 181 17 266 60 58 29 60 30 139 19 201 -27 383 57 502
233 l40 58 78 0 c190 0 371 90 460 228 31 48 32 49 109 64 110 22 217 74 279
136 69 69 102 139 122 264 l18 103 164 92 c90 51 168 92 172 93 5 0 34 -23 65
-51 73 -64 174 -113 281 -135 89 -18 214 -15 233 4 8 8 -15 73 -85 234 -113
265 -324 756 -377 883 -42 99 -215 501 -420 975 -73 168 -139 320 -146 338 -8
17 -19 32 -25 32 -24 0 -131 -86 -169 -134 -22 -29 -53 -83 -70 -120 l-31 -68
-45 7 c-57 8 -419 113 -539 155 -49 18 -165 63 -257 101 -335 137 -459 169
-658 169 -242 0 -459 -72 -695 -230 -38 -25 -79 -50 -90 -54 -11 -4 -114 -16
-230 -27 -115 -11 -350 -35 -520 -54 -171 -19 -323 -35 -337 -35 -23 0 -27 6
-38 53 -7 28 -28 85 -46 125 -42 92 -141 195 -231 241 -71 35 -72 36 -78 19z
m2358 -308 c94 -11 237 -53 345 -101 235 -106 595 -227 902 -304 80 -20 150
-42 157 -48 6 -7 60 -122 119 -257 59 -135 134 -306 166 -380 33 -74 70 -162
83 -195 12 -33 75 -179 139 -324 64 -145 114 -266 112 -268 -2 -2 -79 -46
-172 -97 l-169 -94 -110 107 c-153 148 -405 349 -735 586 -52 38 -99 74 -105
81 -5 6 -120 92 -255 190 -135 97 -311 230 -392 295 -151 121 -193 149 -226
149 -10 0 -56 -18 -103 -41 -129 -63 -176 -79 -284 -100 l-100 -19 -80 -82
c-90 -93 -160 -135 -275 -167 -226 -63 -335 20 -270 206 39 110 130 235 293
401 111 113 202 189 331 276 159 108 298 167 432 185 96 12 99 12 197 1z
m-1223 -326 c0 -3 -33 -46 -74 -94 -97 -116 -148 -192 -197 -293 -105 -219
-83 -395 68 -538 36 -33 84 -69 109 -79 113 -49 270 -50 417 -4 45 15 84 29
87 33 3 3 32 19 65 35 83 39 150 86 216 149 50 48 65 56 127 70 38 9 108 31
154 49 45 18 86 33 90 33 4 0 27 -17 50 -37 24 -20 113 -89 198 -152 85 -64
167 -126 181 -140 14 -13 99 -77 190 -142 508 -365 799 -594 946 -746 87 -90
113 -134 113 -193 0 -77 -40 -128 -127 -161 -45 -17 -46 -17 -87 4 -51 28
-155 117 -560 481 -176 157 -331 294 -345 303 -64 44 -168 10 -205 -66 -27
-55 -12 -96 61 -167 85 -83 476 -433 645 -578 106 -90 148 -132 148 -148 0
-28 -54 -82 -107 -105 -69 -30 -132 -34 -184 -10 -71 32 -252 185 -583 491
-92 86 -182 164 -200 173 -114 62 -245 -68 -177 -177 39 -63 329 -335 579
-543 92 -77 102 -89 97 -112 -24 -102 -166 -167 -266 -123 -45 20 -236 171
-320 252 -82 81 -450 416 -465 424 -23 13 -99 12 -131 -2 -38 -16 -64 -54 -70
-102 -7 -49 11 -92 52 -127 18 -15 88 -79 156 -142 69 -63 191 -173 272 -244
81 -71 147 -133 147 -137 0 -5 -31 -9 -69 -9 -80 0 -212 35 -301 80 -134 68
-750 482 -1265 850 -104 75 -251 176 -325 225 -74 48 -180 118 -235 154 -55
36 -121 80 -147 99 l-47 33 25 67 c41 108 114 310 144 397 15 44 65 181 110
305 102 278 177 482 197 537 13 39 18 42 56 47 68 9 740 83 765 85 12 0 22 -2
22 -5z"
				/>
				<path
					d="M241 2990 c-38 -137 -46 -191 -37 -253 9 -59 58 -132 110 -162 46
-27 559 -218 869 -323 100 -34 110 -36 135 -22 33 19 58 68 116 233 l45 127
-47 24 c-75 38 -212 89 -442 166 -330 111 -466 171 -620 274 -46 31 -86 56
-89 56 -3 0 -21 -54 -40 -120z"
				/>
				<path
					d="M9105 3048 c-146 -118 -307 -202 -675 -349 -209 -84 -420 -186 -420
-204 0 -15 77 -201 113 -271 28 -55 40 -68 75 -79 25 -7 46 1 382 145 427 184
565 248 602 279 98 83 108 189 37 390 -24 68 -49 126 -54 127 -6 2 -32 -15
-60 -38z"
				/>
			</g>
		</svg>
	);
}
