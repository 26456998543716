import React from 'react';
import PropTypes from 'prop-types';
import Sidebar from '../common/sidebar';

const LayoutHome = ({ children, sidebar }) => (
	<>
		{sidebar && (<Sidebar />)}
		<div className="flex flex-col flex-1">
			<main className="flex flex-1">{children}</main>
		</div>
	</>
);

LayoutHome.propTypes = {
	children: PropTypes.node.isRequired,
};

export default LayoutHome;
