import React, { useState, useContext, useEffect } from 'react';
import Button from '../../components/button';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import { TwilioTrackContext } from '../../contexts/twilio-track';

const ShowApproveOrDeclineMicActions = ({ twilioParticipantId, userId }) => {
	const { audioParticipants, approveOrDeclineMicRequest } = useContext(
		TwilioTrackContext
	);
	const { isHost } = useContext(LiveEventDataContext);
	const [audioParticipant, setAudioParticipant] = useState(null);

	if (!isHost) return <></>;

	useEffect(() => {
		if (
			!twilioParticipantId
			|| !userId
			|| !audioParticipants
			|| !audioParticipants.length
		) return;

		const foundAudioParticipant = audioParticipants.find(
			(a) => a.twilioParticipantId == twilioParticipantId
		);
		if (foundAudioParticipant) {
			setAudioParticipant(foundAudioParticipant);
		}
	}, [audioParticipants, twilioParticipantId, userId]);

	if (!audioParticipant) {
		return (
			<div className="flex flex-row space-x-4 px-4 py-2">
				<Button
					height="6"
					onClick={() => {
						approveOrDeclineMicRequest(
							[
								{
									userId,
									twilioParticipantId
								}
							],
							true,
							false
						);
					}}
				>
					Approve
				</Button>
				<Button
					height="6"
					onClick={() => {
						approveOrDeclineMicRequest(
							[
								{
									userId,
									twilioParticipantId
								}
							],
							false,
							false
						);
					}}
				>
					Decline
				</Button>
			</div>
		);
	}
	return <></>;
};

export default ShowApproveOrDeclineMicActions;
