import React from 'react';
import Button from '.';

const BackButton = ({ onClick }) => (
	<Button
		type="primary"
		width="auto"
		height="auto"
		mobileHeight="auto"
		mobileWidth="auto"
		textSize="md"
		additionalClassName="py-2 px-6 font-bold shadow-md"
		onClick={onClick}
	>
		BACK
	</Button>
);

export default BackButton;
