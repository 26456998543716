import { navigate } from 'gatsby-link';
import React from 'react';
import Product from '../../components/product';

const ProductModifyPage = ({ params }) => (
	<Product
		id={params.productId}
		onSuccess={() => navigate('/products')}
		onUnauthorized={() => navigate('/not-authorized', {
			state: {
				stripeAccountError: true
			}
		})}
	/>
);
export default ProductModifyPage;
