/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable radix */
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CreateEventContext } from '../../contexts/create-event';
import BackButton from '../../components/button/back-button';
import NextButton from '../../components/button/next-button';
import EditEventSaveTemplateModal from '../../components/Event/edit-template-save-modal';
import useErrorNotifier from '../../hooks/use-error-notifier';
import { getSportTeams } from '../../api/referenceData.request';
import { PICK_YOUR_SLOTS } from '../../utils/constants';

const CreateBreakTemplateTiers = () => {
	const [showResetButton, setShowResetButton] = useState(false);
	const { showToastError } = useErrorNotifier();
	const [showSaveTemplateModal, setShowSaveTemplateModel] = useState(false);
	const [searchKeyword, setSearchKeyword] = useState('');
	const [searchedData, setSearchedData] = useState([]);

	const [teams, setTeams] = useState([]);
	const [tempTiersData, setTempTiersData] = useState([]);
	const [, updateState] = useState({});
	const forceUpdate = React.useCallback(() => updateState({}), []);
	const [maxSlotSize, setMaxSlotSize] = useState(0);
	const [newTeamId, setNewTeamId] = useState(0);

	const grid = 8;
	const getListStyle = (isDraggingOver, width) => ({
		background: isDraggingOver ? 'lightgrey' : 'lightgrey',
		padding: grid,
		width
	});

	const getItemStyle = (id, isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: 'none',
		padding: grid * 2,
		margin: `0 0 ${grid}px 0`,
		// fontSize: window.screen.width <= 768 ? '12px' : '',

		// change background colour if dragging
		background: isDragging ? 'grey' : 'white',
		backgroundColor: parseInt(id) > 0 ? 'white' : '#e82e2c',

		// styles we need to apply on draggables
		...draggableStyle
	});

	const {
		editedTemplate,
		setEditedTemplate,
		setTemplateActionType,
		setActiveStep,
		eventTypeId
	} = useContext(CreateEventContext);

	useEffect(() => {
		const loadInitialTeamData = async () => {
			let i = 0;
			if (editedTemplate.teams && editedTemplate.teams.length > 0) {
				editedTemplate.teams.forEach((team) => {
					if (team.teamId == null) {
						i--;
						team.teamId = i;
					}
				});
				setTeams(editedTemplate.teams);
				setMaxSlotSize(editedTemplate.slotSize);
				setShowResetButton(true);
			} else {
				const result = await getSportTeams(editedTemplate.sportTypeID);
				if (result.status == 200) {
					const { data } = result;
					setTeams(data);
					setMaxSlotSize(editedTemplate.slotSize);
				} else {
					showToastError('Error! Failed to fetch teams data');
				}
			}

			const rows = [];
			if (editedTemplate.tiersData && editedTemplate.tiersData.length > 0) {
				editedTemplate.tiersData.forEach((team) => {
					if (team.teamId == null) {
						i--;
						team.teamId = i;
					}
				});
				setTempTiersData(editedTemplate.tiersData);
			} else if (editedTemplate) {
				for (let j = 0; j < parseInt(editedTemplate.tierSize); j++) {
					rows[j] = [];
				}
				setTempTiersData(rows);
			}
			setNewTeamId(i--);
		};

		loadInitialTeamData();
	}, []);

	const getStepData = (templateName) => {
		const requestTeamsData = [];
		if (tempTiersData.length) {
			// var maxSlotSize = tempTiersData[0].length;
			let displayOrder = 0;
			tempTiersData.map((tier, index) => {
				displayOrder = 1;
				tier.map((team) => {
					requestTeamsData.push({
						tier: index + 1,
						teamId: team.teamId <= 0 ? null : team.teamId,
						team: team.displayName,
						displayOrder
					});
					displayOrder++;
				});
			});
		}
		return {
			templateName,
			tierSize: tempTiersData.length,
			slotSize: maxSlotSize,
			sportTypeID: Number(editedTemplate.sportTypeID),
			teams: requestTeamsData,
			tiersData: tempTiersData,
			templateTiersData: requestTeamsData,
			maxSlotSize
		};
	};

	const onTemplateSave = (templateName) => {
		const data = getStepData(templateName);
		setEditedTemplate({
			...editedTemplate,
			templateName,
			teams: data.teams,
			tiersData: data.teams,
			templateTiersData: data.requestTeamsData
		});

		setTemplateActionType('create');
		setActiveStep(eventTypeId == PICK_YOUR_SLOTS ? 3 : 4);
		setShowSaveTemplateModel(false);
	};

	const resetTemplateData = async () => {
		setShowResetButton(false);

		setTeams([]);
		setTempTiersData([]);
		const result = await getSportTeams(editedTemplate.sportTypeID);
		if (result.status == 200) {
			const { data } = result;
			setTeams(data);
		} else {
			showToastError('Error! Failed to fetch teams data');
		}
		const rows = [];
		for (let i = 0; i < parseInt(editedTemplate.tierSize); i++) {
			rows[i] = [];
		}
		setTempTiersData(rows);
	};

	const onBackClick = () => {
		setTemplateActionType('create');
		setActiveStep(2);
	};

	const onNextClick = () => {
		let showError = false;
		let errorMessage = false;
		tempTiersData.map((tier) => {
			if (!showError) {
				if (tier.length <= 0) {
					errorMessage = 'Add data in tiers';
					showError = true;
				} else if (tier.length != editedTemplate.slotSize) {
					errorMessage = `Number of slots on each tier should be ${editedTemplate.slotSize}`;
					showError = true;
				}
			}
		});

		if (showError) showToastError(errorMessage);
		else if (editedTemplate.templateName) {
			const data = getStepData(editedTemplate.templateName);
			setEditedTemplate({
				...editedTemplate,
				teams: data.teams,
				tiersData: data.teams,
				templateTiersData: data.requestTeamsData
			});
			setTemplateActionType('create');
			setActiveStep(eventTypeId == PICK_YOUR_SLOTS ? 3 : 4);
		} else setShowSaveTemplateModel(true);
	};

	const onDragEnd = (result) => {
		const movedTeamID = result.draggableId;
		let columns = [];
		const { source, destination } = result;
		let selectedTeam = [];
		if (!destination) {
			return;
		}
		if (source.droppableId == 'teams') {
			if (destination.droppableId == 'teams') {
				return;
			}
			if (searchKeyword) {
				setSearchedData((prevState) => {
					prevState.splice(source.index, 1);
					return prevState;
				});
			}
			selectedTeam = teams.find((team) => team.teamId == parseInt(movedTeamID));
			const filteredTeams = teams.filter(
				(x) => x.teamId !== parseInt(movedTeamID)
			);
			columns = tempTiersData;
			if (columns[parseInt(destination.droppableId)].length == maxSlotSize) return;
			columns[parseInt(destination.droppableId)].splice(
				destination.index,
				0,
				selectedTeam
			);
			setTempTiersData(columns);
			setTeams(filteredTeams);
			forceUpdate();
		} else if (destination.droppableId == 'teams') {
			const teamsData = teams;
			const columnsData = tempTiersData;
			const filteredData = columnsData[parseInt(source.droppableId)].filter(
				(x) => x.teamId == parseInt(movedTeamID)
			);
			const filteredTiersData = columnsData[
				parseInt(source.droppableId)
			].filter((x) => x.teamId !== parseInt(movedTeamID));
			// teamsData.push(filteredData[0]);
			teamsData.splice(destination.index, 0, filteredData[0]);
			columnsData[parseInt(source.droppableId)] = filteredTiersData;
			setTempTiersData(columnsData);
			setTeams(teamsData);
			forceUpdate();
		} else {
			tempTiersData.map((tier) => {
				const filteredTeams = tier.filter(
					(t) => t.teamId !== parseInt(movedTeamID)
				);
				columns.push(filteredTeams);
			});
			selectedTeam = tempTiersData.map((tier) => tier.find((t) => t.teamId == parseInt(movedTeamID)));
			selectedTeam = selectedTeam.filter(Boolean);
			// const filteredteam = tier.filter( t => t.teamId === parseInt(movedTeamID));
			columns[parseInt(destination.droppableId)].splice(
				destination.index,
				0,
				selectedTeam[0]
			);
			setTempTiersData(columns);
		}
	};

	const addNewTeam = () => {
		const tempTeams = [...teams];
		if (searchKeyword != null && searchKeyword != '') {
			let nameAlreadyExists = false;
			if (teams.find((x) => x.displayName == searchKeyword)) {
				nameAlreadyExists = true;
			} else if (tempTiersData.length > 0) {
				tempTiersData.map((tier) => {
					tier.map((team) => {
						if (team.displayName == searchKeyword) nameAlreadyExists = true;
					});
				});
			}

			if (nameAlreadyExists) showToastError('Team is already in available list. Please find!');
			else {
				setNewTeamId(newTeamId - 1);
				tempTeams.unshift({
					teamId: newTeamId,
					displayName: searchKeyword
				});
				setTeams(tempTeams);
				setSearchKeyword('');
			}
		} else {
			showToastError('Please fill the field!');
		}
	};

	const removeItemsFromTeams = (teamId, index) => {
		const teamsData = teams;
		const columnsData = tempTiersData;
		const filteredTeam = columnsData[index].filter((x) => x.teamId == teamId);
		const filteredTeams = columnsData[index].filter((x) => x.teamId !== teamId);
		teamsData.push(filteredTeam[0]);
		columnsData[index] = filteredTeams;
		setTempTiersData(columnsData);
		setTeams(teamsData);
		forceUpdate();
	};

	return (
		<div>
			<EditEventSaveTemplateModal
				showModal={showSaveTemplateModal}
				onNext={(val) => onTemplateSave(val)}
				onClose={() => setShowSaveTemplateModel(false)}
			/>
			<div>
				{showResetButton && (
					<div className="h-12">
						<button
							type="button"
							style={{ float: 'right' }}
							className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6"
							onClick={resetTemplateData}
						>
							Reset
						</button>
					</div>
				)}
				<div>
					<div className="container justify-center text-center h-50 shadow shadow-lg overflow-hidden overflow-y-auto mb-6">
						<DragDropContext onDragEnd={onDragEnd}>
							<div className="flex flex-row justify-center py-5">
								<Droppable droppableId="teams">
									{(provided, snapshot) => (
										<div
											className="flex flex-col items-center"
											style={{ width: '29%' }}
										>
											<div className="flex flex-row ml-2 mr-2 relative border">
												<div className="flex flex-col" style={{ width: '90%' }}>
													<input
														type="text"
														name="slots_count"
														id="slots_count"
														placeholder="Search or Add new"
														className="inline-block m-1 sm:text-sm w-full p-1"
														style={{
															outlineColor: 'white',
															height: '35px'
														}}
														value={searchKeyword}
														onChange={({ target }) => {
															setSearchKeyword(target.value);
															setSearchedData(
																teams.filter(({ displayName }) => displayName
																	.toLowerCase()
																	.includes(target.value.toLowerCase()))
															);
														}}
													/>
												</div>
												<div
													className="flex flex-col m-2"
													style={{ width: '10%' }}
												>
													<button
														type="button"
														className="inline-block"
														style={{
															outlineColor: 'white',
															padding: '3%'
														}}
														onClick={addNewTeam}
														title="Add Team"
													>
														<svg
															width="24"
															height="24"
															xmlns="http://www.w3.org/2000/svg"
															fillRule="evenodd"
															clipRule="evenodd"
														>
															<path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z" />
														</svg>
													</button>
												</div>
											</div>
											<div
												ref={provided.innerRef}
												style={getListStyle(snapshot.isDraggingOver, 250)}
												className="border w-80 text-center border-md m-2 "
											>
												<span className="font-bold text-md text-center p-5">
													Available Teams
												</span>
												{(searchKeyword ? searchedData : teams).map(
													(team, index) => (
														<Draggable
															key={team.teamId}
															draggableId={team.teamId.toString()}
															index={index}
														>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={getItemStyle(
																		team.teamId,
																		snapshot.isDragging,
																		provided.draggableProps.style
																	)}
																	className="shadow shadow-md h-16 w-50 font-xs py-2items-center justify-center py-5 my-2"
																>
																	{team.displayName}
																</div>
															)}
														</Draggable>
													)
												)}

												{provided.placeholder}
											</div>
										</div>
									)}
								</Droppable>
								<div
									className="flex flex-column overflow-x-auto border p-2"
									style={{ width: '71%' }}
								>
									<div className="flex flex-row">
										{tempTiersData.map((tier, i) => (
											<Droppable droppableId={i.toString()}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														className="border text-center border-md m-2"
														style={getListStyle(snapshot.isDraggingOver, 200)}
													>
														<span className="font-bold text-md text-center p-5">
															Tier
															{' '}
															{i + 1}
														</span>
														{tier.map((team, index) => (
															<Draggable
																key={team.teamId}
																draggableId={team.teamId.toString()}
																index={index}
																type="TASK"
															>
																{(provided, snapshot) => (
																	<div
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		style={getItemStyle(
																			team.teamId,
																			snapshot.isDragging,
																			provided.draggableProps.style
																		)}
																		className="shadow shadow-md h-16 w-50 font-sm items-center justify-center py-5 my-2"
																	>
																		<div className="flex justify-between items-center">
																			<span className="py-1">
																				{' '}
																				{team.displayName}
																			</span>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 512 512"
																				height="15pt"
																				width="15pt"
																				style={{
																					cursor: 'pointer'
																				}}
																				onClick={() => removeItemsFromTeams(team.teamId, i)}
																			>
																				<path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm112 277.332031h-224c-11.777344 0-21.332031-9.554687-21.332031-21.332031s9.554687-21.332031 21.332031-21.332031h224c11.777344 0 21.332031 9.554687 21.332031 21.332031s-9.554687 21.332031-21.332031 21.332031zm0 0" />
																			</svg>
																		</div>
																	</div>
																)}
															</Draggable>
														))}

														{provided.placeholder}
													</div>
												)}
											</Droppable>
										))}
									</div>
								</div>
							</div>
						</DragDropContext>
					</div>
				</div>
			</div>

			{/* Steps Next/Previous Button  */}
			<div className="flex justify-center mx-auto">
				<div className="mr-2">
					<BackButton onClick={onBackClick} />
				</div>
				<div className="ml-2">
					<NextButton onClick={onNextClick} />
				</div>
			</div>
		</div>
	);
};

export default CreateBreakTemplateTiers;
