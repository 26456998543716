import React, { useState, useEffect } from 'react';

import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import Layout from '../components/layouts/layout-default';
import Header from '../components/header';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
`;

const RandomidationPolicy = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// authState();
		setLoading(false);
	}, []);

	return (
		<Layout>
			<div className="flex flex-col flex-1">
				<Header siteTitle="Signin" auth />
				{loading && (
					<div className="w-screen h-screen bg-white absolute left-0 top-0 z-50">
						<div className="flex h-full items-center">
							<BounceLoader
								loading
								css={override}
								size={30}
							/>
						</div>
					</div>
				)}

				{!loading && (
					<div className="flex flex-col flex-1 justify-start content-center">
						<div className="flex flex-1 flex-col justify-items-center bg-white w-8/12 mx-auto px-6 py-10 rounded-lg">
							<h2 className="text-xl text-gray-700 font-semibold text-center sm:text-lg sm:max-w-xl sm:mx-auto  md:text-xl mb-4">
                                Randomization Policy
							</h2>
							<div className="w-6 h-0.5 mx-auto bg-gray-600 mb-6" />

							<div className="flex flex-col w-full mx-auto px-16">
								<p className="text-base font-normal text-gray-900  mb-4">
                                    Breaks with random slots use DJAWN's
                                    proprietary randomization algorithm to
                                    fairly and randomly distribute slots to
                                    buyers. Randomization automatically occurs
                                    once all slots have been filled.
								</p>

								<p className="text-base font-normal text-gray-900  mb-4">
                                    DJAWN in no way benefits from this
                                    randomization and does not alter the
                                    algorithm to affect results.
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default RandomidationPolicy;
