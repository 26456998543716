import React from 'react';
import { toast } from 'react-toastify';
import CustomDontShowAgain from '../components/common/custom-dont-show-again';

export const informationToast = (message) => {
	toast.dismiss();
	toast.info(<CustomDontShowAgain message={message} />, {
		position: 'top-center',
		autoClose: 10000,
		hideProgressBar: false,
		closeOnClick: true,
		// pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'colored'
	});
};

export const notificationToast = (message) => {
	toast.dark(message, {
		position: 'top-center',
		autoClose: 2000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: false,
		progress: undefined,
		draggable: true,
		type: 'success'
	});
};
