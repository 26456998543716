/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
	Fragment, useEffect, useState, useRef
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { getCurrentUser } from '../../utils/auth';

const RipNShipQueueModal = ({
	showModal,
	setShowModal,
	isSeller,
	initiateEndTurn,
	joinQueue,
	onBuyerRemove,
	buyers,
	onExitLine,
	setBuyerExitId,
	isEventInformationReinitiating,
	onCloseLine,
	isInLine,
	setIsInLine,
	onOpenLine,
	setHasPurchasedItem,
	lineClosed
}) => {
	const cancelButtonRef = useRef();
	const [selectedBuyer, setSelectedBuyer] = useState(0);
	const [position, setPosition] = useState(null);
	const [formated, setFormated] = useState(false);

	useEffect(() => {
		if (isEventInformationReinitiating) return;
		setFormated(false);
		const userId = getCurrentUser().id;
		const filteredBuyer = buyers.find((f) => f.userId == userId);
		if (filteredBuyer) {
			setPosition(filteredBuyer.position);
			setHasPurchasedItem(filteredBuyer.purchasedItem);
			setIsInLine(true);
		} else {
			setIsInLine(false);
		}
		setFormated(true);
	}, [buyers, isEventInformationReinitiating]);

	if (isEventInformationReinitiating || !formated) return null;

	return (
		<Transition.Root show={showModal} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-50 inset-0 overflow-y-auto"
				initialFocus={cancelButtonRef}
				open={showModal}
				onClose={() => setShowModal(false)}
			>
				<div className="flex items-center md:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay
							className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
							style={{ zIndex: '1040!important' }}
						/>
					</Transition.Child>

					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						style={{ zIndex: '1050!important' }}
					>
						<div className="inline-block fixed md:relative bg-opacity-100 align-bottom dark:bg-darkGray-50 bg-white rounded-lg text-left overflow-hidden shadow-xl transform sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
							<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative dark:bg-darkGray-50">
								<div className="flex items-center justify-center">
									<div className="mt-3 text-center justify-center sm:mt-0 sm:ml-4 sm:text-left">
										<div className="flex flex-col items-center justify-center dark:bg-darkGray-50">
											{isSeller ? (
												<>
													<div className="flex">
														<select
															id="product_status"
															name="product_status"
															autoComplete="event_type"
															className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-18  sm:text-sm border bg-white border-gray-200 dark:border-darkGray-100 dark:bg-darkGray-200 dark:text-white rounded-md mr-2"
															onBlur={(e) => setSelectedBuyer(e.target.value)}
															onChange={(e) => {
																setSelectedBuyer(e.target.value);
															}}
															value={selectedBuyer}
														>
															<option key={0} value={0} />
															{buyers.map((buyer) => (
																<option key={buyer.userId} value={buyer.userId}>
																	{buyer.nickname}
																</option>
															))}
														</select>
														<button
															className="w-1/2 bg-djawn text-white active:bg-green-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none h-18 mb-1"
															type="button"
															style={{
																transition: 'all .15s ease'
															}}
															onClick={() => {
																if (selectedBuyer && selectedBuyer != 0) {
																	setBuyerExitId(selectedBuyer);
																	onBuyerRemove(selectedBuyer);
																}
															}}
														>
															Remove Buyer From Line
														</button>
													</div>
													<div className="flex items-center justify-center w-full pt-1">
														{lineClosed ? (
															<button
																className="w-1/2 bg-djawn text-white active:bg-green-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
																type="button"
																style={{
																	transition: 'all .15s ease'
																}}
																onClick={onOpenLine}
															>
																Open Line
															</button>
														) : (
															<button
																className="w-1/2 bg-djawn text-white active:bg-green-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
																type="button"
																style={{
																	transition: 'all .15s ease'
																}}
																onClick={onCloseLine}
															>
																Close Line
															</button>
														)}
													</div>
												</>
											) : (
												<>
													{position && position == 1 && isInLine && (
														<button
															className="w-full bg-djawn text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
															type="button"
															style={{
																transition: 'all .15s ease'
															}}
															onClick={initiateEndTurn}
														>
															Indicate "All Done"
														</button>
													)}
													{!isInLine && (
														<button
															className="w-full bg-djawn text-white active:bg-green-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
															type="button"
															style={{
																transition: 'all .15s ease'
															}}
															onClick={() => {
																joinQueue();
																setShowModal(false);
															}}
														>
															Get In Line
														</button>
													)}
													{isInLine && (
														<button
															className="w-full bg-djawn text-white active:bg-green-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
															type="button"
															style={{
																transition: 'all .15s ease'
															}}
															onClick={onExitLine}
														>
															Get Out Of Line
														</button>
													)}
												</>
											)}
										</div>
									</div>
								</div>

								<button
									type="button"
									className="absolute px-3 pt-3 text-gray-500 top-0 right-0 cursor-pointer outline-none border-none focus:outline-none"
									onClick={() => setShowModal(false)}
								>
									X
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default RipNShipQueueModal;
