/* eslint-disable radix */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';

import { ReactSortable } from 'react-sortablejs';
import Sortable, { Swap } from 'sortablejs';
import { Beforeunload } from 'react-beforeunload';
import ReactTooltip from 'react-tooltip';
import {
	endTurnRequest,
	exitQueue,
	initiateEndTurnRequest,
	joinRipNShipQueue,
	moveQueuePosition
} from '../../api/event.request';
import BuyerRipNShipExitConfirmation from '../buyer-ripnship-exit-confirmation';
import SellerRipNShipExitConfirmation from '../seller-ripnship-exit-confirmation';
import { useInterval } from '../common/use-interval';
import {
	getElapsedTimestampWithCurrentDateQueue,
	getElapsedTimestampWithCurrentDateQueueTwoDates,
	getElapsedTimestampWithCurrentDateQueueTwoDatesForSeeking
} from '../../utils/formatter';
import { getCurrentUser } from '../../utils/auth';
import { defaultToastError } from '../../utils/toast-error';
import EllipisWithTooltip from '../ellipsis-tooltip';
import { TwilioTrackContext } from '../../contexts/twilio-track';
import { SellerEventAttendeeActionsContext } from '../../contexts/seller-event-attendee-actions';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import MicOrRequestIcon from '../mic-or-request-mic-icon';
import HamburgerMenuIcon from '../../assets/icons/hamburger-menu-icon';

const defaultImageUrl =	'https://www.pngix.com/pngfile/middle/468-4686906_no-frills-yacht-party-default-profile-photo-png.png';

const RipNShipEventQueue = ({
	isSeller,
	eventId,
	initialBuyers,
	changeWsMessage,
	initiatedEndTurnsWsMessage,
	setInitiatedEndTurnsWsMessage,
	onEndTurn,
	endTurnWsMessage,
	exitWsMessage,
	newBuyersWsMessage,
	reinitiateEventInformation,
	visible,
	setBuyer,
	setShowPurchasedItemsModal,
	setPurchasedProductsUserId,
	updatedBuyersQueueWsMessage,
	barrierLine,
	joinQueue,
	joined,
	setJoined,
	setAllBuyers,
	timers,
	setTimers,
	buyers,
	setBuyers,
	hideJoinButton
}) => {
	const [enableMovement, setEnableMovement] = useState(true);
	const [initiatedEndTurns, setInitiatedEndTurns] = useState([]);
	const [enableEndTurn, setEnableEndTurn] = useState(true);
	const [exitPageResult, setExitPageResult] = useState(false);
	const [lineHistoryBuyers, setLineHistoryBuyers] = useState([]);
	const [
		showBuyerExitConfirmationModal,
		setShowBuyerExitConfirmationModal
	] = useState(false);
	const [
		showSellerExitConfirmationModal,
		setShowSellerExitConfirmationModal
	] = useState(false);
	const [buyerExitId, setBuyerExitId] = useState(null);
	const { audioParticipants } = useContext(TwilioTrackContext);
	const { setSelectedUserForActions } = useContext(
		SellerEventAttendeeActionsContext
	);
	const { attendees } = useContext(LiveEventDataContext);
	const [raisedHandOrIconUserIds, setRaisedHandOrIconUserIds] = useState([]);

	useEffect(() => {
		if (!changeWsMessage) return;

		updateTimerOnPositionUpdate(
			changeWsMessage.position,
			changeWsMessage.swappedPosition,
			changeWsMessage.positionDateTime,
			changeWsMessage.previousPositionDateTime
		);
		const tempBuyers = [...buyers]
			.map((buyer) => {
				let done = false;
				if (buyer.position == changeWsMessage.swappedPosition) {
					buyer.position = changeWsMessage.position;
					done = true;
				} else if (!done && buyer.position == changeWsMessage.position) {
					buyer.position = changeWsMessage.swappedPosition;
				}

				return buyer;
			})
			.sort((a, b) => a.position - b.position);

		setBuyers(tempBuyers);
	}, [changeWsMessage]);

	useEffect(() => {
		if (!endTurnWsMessage) return;
		reinitiateEventInformation();
	}, [endTurnWsMessage]);

	useEffect(() => {
		if (!updatedBuyersQueueWsMessage) return;
		setBuyers(updatedBuyersQueueWsMessage);
		reinitiateEventInformation();
	}, [updatedBuyersQueueWsMessage]);

	useEffect(() => {
		if (!exitWsMessage) return;
		let tempBuyers = [...buyers]
			.map((buyer) => {
				const foundBuyer = exitWsMessage.find((p) => p.item1 == buyer.userId);
				if (!foundBuyer) return buyer;
				buyer.position = foundBuyer.item2;
				return buyer;
			})
			.sort((a, b) => a.position - b.position)
			.filter((el) => el != null);

		const deletedPerson = exitWsMessage.find((p) => p.item2 == 0); // if position 0 delete

		tempBuyers = tempBuyers
			.filter((f) => f.userId != deletedPerson.item1)
			.sort((a, b) => a.position - b.position);

		let tempTimers = [...timers]
			.map((timer) => {
				const foundTimer = exitWsMessage.find((t) => t.item1 == timer.userId);
				if (!foundTimer) return timer;
				timer.position = foundTimer.item2;
				timer.startTime = foundTimer.item3;
				timer.time = '00:00:00';

				return timer;
			})
			.sort((a, b) => a.position - b.position)
			.filter((el) => el != null);

		tempTimers = tempTimers
			.filter((f) => f.userId != deletedPerson.item1)
			.sort((a, b) => a.position - b.position);

		setBuyers(tempBuyers);
		setTimers(tempTimers);

		const tempInitiatedEndTurns = [...initiatedEndTurns].filter(
			(f) => f != deletedPerson.item1
		);
		setInitiatedEndTurns(tempInitiatedEndTurns);
	}, [exitWsMessage]);

	useEffect(() => {
		if (!initiatedEndTurnsWsMessage) return;

		const tempInitiatedEndTurns = [...initiatedEndTurns].concat(
			initiatedEndTurnsWsMessage
		);
		setInitiatedEndTurns(tempInitiatedEndTurns);
		setInitiatedEndTurnsWsMessage(null);
	}, [initiatedEndTurnsWsMessage]);

	const createTimers = () => {
		const tempTimers = [];
		[...initialBuyers].forEach((buyer) => {
			tempTimers.push({
				position: buyer.position,
				userId: buyer.userId,
				startTime: buyer.positionStartDate,
				lineStartTime: buyer.lineStartDate,
				useSameTime: buyer.positionStartDate == buyer.lineStartDate,
				time: '00:00:00',
				lineTime: '00:00:00'
			});
		});
		setTimers(tempTimers);
	};

	useInterval(() => {
		const tempTimers = [...timers].map((timer) => {
			if (timer.useSameTime) {
				const time = getElapsedTimestampWithCurrentDateQueue(timer.startTime);
				timer.time = time;
				timer.lineTime = time;
			} else {
				const [
					time,
					lineTime
				] = getElapsedTimestampWithCurrentDateQueueTwoDates(
					timer.startTime,
					timer.lineStartTime
				);
				timer.time = time;
				timer.lineTime = lineTime;
			}

			return timer;
		});

		setTimers(tempTimers);
	}, 1000);

	useEffect(() => {
		if (!eventId || !initialBuyers || !initialBuyers.length) return;
		const user = getCurrentUser();
		const joinedQueue = initialBuyers.find((f) => f.userId == user.id);
		if (joinedQueue) setJoined(true);
		createTimers();

		const tempArr = [];
		const endTurnBuyers = [...initialBuyers].map((b) => {
			if (b.initiatedEndTurn == true) {
				tempArr.push(b.userId);
			}
		});

		setInitiatedEndTurns(tempArr);

		const hasCurrentUserInitiatedEndTurn = [...buyers].find(
			(f) => f.initatedEndTurn == true && f.userId == user.id
		);

		if (hasCurrentUserInitiatedEndTurn) setEnableEndTurn(false);
		setBuyers(initialBuyers);

		if (window) {
			window.addEventListener('unload', handleExit);
		}

		return () => {
			if (window) {
				window.removeEventListener('unload', handleExit);
			}
		};
	}, []);

	useEffect(() => {
		if (!initialBuyers) return;
		createTimers();
		setBuyers(initialBuyers);
	}, [initialBuyers]);

	const handleExit = (userId = undefined, exitModal = false) => {
		if (exitPageResult == true || exitModal == true) {
			exitQueue(eventId, userId)
				.then((res) => {})
				.catch((err) => {
					const mute = err;
				})
				.finally((_) => {
					if (exitModal) {
						setShowBuyerExitConfirmationModal(false);
						setShowSellerExitConfirmationModal(false);
					}
					setJoined(false);
				});
		}
	};

	// useEffect(() => {
	//     if (window) {
	//         window.addEventListener('unload', handleExit);
	//     }

	//     return () => {
	//         if (window) {
	//             window.removeEventListener('unload', handleExit);
	//         }
	//     };
	// });

	useEffect(() => {
		if (!newBuyersWsMessage) return;
		const newBuyer = {
			id: newBuyersWsMessage.queueOrder.ripNShipQueueOrderId,
			imageUrl: newBuyersWsMessage.queueOrder.profileImageName,
			name: newBuyersWsMessage.queueOrder.fullName,
			nickname: newBuyersWsMessage.queueOrder.nickname,
			position: newBuyersWsMessage.queueOrder.currentPosition,
			purchasedItem: newBuyersWsMessage.queueOrder.purchasedItem,
			userId: newBuyersWsMessage.queueOrder.userId
		};

		const tempTimers = [...timers].concat({
			position: newBuyer.position,
			startTime: newBuyersWsMessage.positionStartedDate,
			lineStartTime: newBuyersWsMessage.lineStartedDate,
			time: '00:00:00',
			userId: newBuyer.userId,
			useSameTime:
				newBuyersWsMessage.positionStartedDate
				== newBuyersWsMessage.lineStartedDate
		});

		setTimers(tempTimers);

		if (!buyers || !buyers.length) {
			setBuyers([newBuyer]);
		} else {
			let tempBuyers = [...buyers];
			tempBuyers = tempBuyers.filter((b) => b.userId != newBuyer.userId);
			tempBuyers.push(newBuyer);

			tempBuyers = tempBuyers.sort((a, b) => a.position - b.position);

			setBuyers(tempBuyers);
		}

		reinitiateEventInformation();
	}, [newBuyersWsMessage]);

	const movePosition = ({ id, position }, upInterval) => {
		setEnableMovement(false);
		if (upInterval) {
			const tempBuyers = [...buyers]
				.map((b) => {
					if (b.id == id) {
						b.position = parseInt(position) + upInterval;
					} else if (parseInt(position) + upInterval == parseInt(b.position)) {
						b.position = position;
					}

					return b;
				})
				.sort((a, b) => a.position - b.position);

			setBuyers(tempBuyers);
			position = parseInt(position) + upInterval;
		}
		moveQueuePosition(eventId, id, position)
			.then(({ data }) => {
				updateTimerOnPositionUpdate(
					position,
					data.swappedPosition,
					data.positionTurnStartDate,
					data.previousPositionTurnStartDate
				);
			})
			.catch((err) => {
				if (err && err.response && err.response.data.error) {
					defaultToastError(err.response.data.error);
				}
			})
			.finally((_) => {
				setEnableMovement(true);
			});
	};

	const updateTimerOnPositionUpdate = (
		position,
		nextPosition,
		date,
		nextDate
	) => {
		const tempTimersArr = [...timers];
		const nextPositionLineStartTime = tempTimersArr.filter(
			(p) => p.position == nextPosition
		)[0].lineStartTime;
		const previousPositionTimerLineStartTime = tempTimersArr.filter(
			(p) => p.position == position
		)[0].lineStartTime;

		const tempTimers = [...timers].map((timer) => {
			if (timer.position == position) {
				timer.startTime = date;
				timer.time = '00:00:00';
				timer.lineStartTime = nextPositionLineStartTime;
			} else if (timer.position == nextPosition) {
				timer.startTime = nextDate;
				timer.time = '00:00:00';
				timer.lineStartTime = previousPositionTimerLineStartTime;
			}
			return timer;
		});

		setTimers(tempTimers);
	};

	const getTimerElapsedByPosition = (position) => {
		const timer = timers.find((p) => p.position == position);
		if (!timer || !timer.time) return null;
		return <p>{timer.time}</p>;
	};

	const getLineStartTimeElapsedByPosition = (position) => {
		const timer = timers.find((p) => p.position == position);

		if (!timer || !timer.lineTime) return null;
		return <p>{timer.lineTime}</p>;
	};

	const getSvgBasedOnPurchasedItems = (items) => {
		if (items && items > 0) {
			return (
				<td className="px-4 xxs:px-4 py-2 whitespace-nowrap text-greenModified-500 flex flex-col content-center justify-center items-center pt-4">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-5 w-5"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
							clipRule="evenodd"
						/>
					</svg>
				</td>
			);
		}
		return (
			<td className="px-4 xxs:px-4 py-2 whitespace-nowrap text-red-500 flex flex-col content-center justify-center items-center pt-4">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-5 w-5"
					viewBox="0 0 20 20"
					fill="currentColor"
				>
					<path
						fillRule="evenodd"
						d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
						clipRule="evenodd"
					/>
				</svg>
			</td>
		);
	};

	const getSvgBasedOnInitiatedEndTurn = (position, userId) => {
		if (position !== 1) return;
		const initatedEndTurn = [...initiatedEndTurns].find((f) => f == userId);
		if (initatedEndTurn) {
			return (
				<>
					<td className="px-4 xxs:px-4 py-2 whitespace-nowrap text-greenModified-500">
						<button
							type="button"
							className="cursor-pointer w-20 bg-greenModified-500 hover:bg-greenModified-700 text-gray-100 hover:text-gray-100 text-xs font-semibold rounded-sm px-4 xxs:px-2 py-2 border-0 flex justify-center mb-1"
							onClick={() => endTurn(position, userId)}
						>
							End Turn
						</button>
					</td>
					<td className="px-4 xxs:px-4 py-2 whitespace-nowrap text-greenModified-500 flex flex-col content-center justify-center items-center pt-4">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-5 w-5"
							viewBox="0 0 20 20"
							fill="currentColor"
						>
							<path
								fillRule="evenodd"
								d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
								clipRule="evenodd"
							/>
						</svg>
					</td>
				</>
			);
		}

		return (
			<>
				<td className="px-4 xxs:px-4 py-2 whitespace-nowrap text-greenModified-500">
					<button
						type="button"
						className="cursor-pointer w-20 bg-greenModified-500 hover:bg-greenModified-700 text-gray-100 hover:text-gray-100 text-xs font-semibold rounded-sm px-4 xxs:px-2 py-2 border-0 flex justify-center mb-1"
						onClick={() => endTurn(position, userId)}
					>
						End Turn
					</button>
				</td>
				<td className="px-4 xxs:px-4 whitespace-nowrap text-red-500 flex flex-col content-center justify-center items-center pt-4">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-5 w-5"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
							clipRule="evenodd"
						/>
					</svg>
				</td>
			</>
		);
	};

	const initiateEndTurn = () => {
		initiateEndTurnRequest(eventId)
			.then((res) => {
				setEnableEndTurn(false);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const endTurn = (position, userId) => {
		endTurnRequest(eventId, userId, position)
			.then((res) => {})
			.catch((err) => {
				if (err.response && err.response.data.error) {
					defaultToastError(err.response.data.error);
				}
			});
	};

	const getImageSrc = (image) => {
		if (!image) return defaultImageUrl;
		return image;
	};

	const getEndTurnButton = (userId) => {
		const initiated = initiatedEndTurns.filter((f) => f == userId)[0];
		if (initiated) {
			return (
				<button
					type="button"
					className="w-64 bg-djawn hover:bg-djawn-300 text-gray-100 hover:text-gray-100 text-xs font-semibold rounded-sm px-4 xxs:px-2 py-2 border-0 flex justify-center mb-1"
					onClick={onEndTurn}
				>
					All Done
				</button>
			);
		}
		return null;
	};
	return (
		<>
			<Beforeunload
				onBeforeunload={(event) => {
					event.preventDefault();
					event.returnValue = 'Are you sure you want to leave';

					setExitPageResult(true);
				}}
			>
				<div className={`${visible == true ? 'block' : 'hidden'}`}>
					<BuyerRipNShipExitConfirmation
						onExitLine={() => {
							handleExit(null, true);
						}}
						showModal={showBuyerExitConfirmationModal}
						setShowModal={setShowBuyerExitConfirmationModal}
					/>

					<SellerRipNShipExitConfirmation
						onExitLine={() => {
							if (buyerExitId) {
								handleExit(buyerExitId, true);
							}
						}}
						showModal={showSellerExitConfirmationModal}
						setShowModal={setShowSellerExitConfirmationModal}
					/>

					<div className="flex flex-col w-2/3 xxs:w-11/12 mx-auto text-left py-6 pt-2 overflow-x-scroll">
						<div>
							<table className="table-fixed w-full text-left shadow-md">
								<thead>
									<tr className="border-b flex justify-center">
										<p className="w-64 flex justify-center font-bold dark:text-white">
											Ripping Now
										</p>
									</tr>
								</thead>
								<ReactSortable
									tag="tbody"
									className="text-gray-700 text-sm"
									list={buyers}
									disabled={!isSeller}
									setList={(newState) => {
										const tempBuyers = [...newState]
											.filter((f) => f != null)
											.map((buyer, index) => {
												buyer.position = index + 1;
												return buyer;
											});
										setBuyers(tempBuyers);
									}}
									onUpdate={(order, sortable, store) => {
										let oldIndex = order.oldIndex + 1;
										let newIndex = order.newIndex + 1;

										if (oldIndex > 3) {
											oldIndex -= 2;
										}
										if (newIndex > 3) {
											newIndex -= 2;
										}

										const movedBuyer = [...buyers].find(
											(b) => b.position == oldIndex
										);

										if (movedBuyer) {
											movedBuyer.position = newIndex;
											movePosition(movedBuyer);
										}
									}}
									swap
								>
									{buyers.map((buyer, index) => (
										<>
											<tr
												className={`
									${isSeller == true && 'cursor-move'} 
									bg-white font-medium text-sm ${buyer.position > 1 && 'border-b'} ${
											buyer.position == barrierLine
													&& 'border-b-4 border-black'
										} flex justify-between dark:bg-darkGray-50 dark:text-white items-center py-3`}
												key={buyer.id}
											>
												<ReactTooltip />
												<td className="inline-flex">
													<div className="flex items-center">
														<p className="inline-flex text-sm">
															{buyer.position}
															<span
																className={`flex space-x-4 items-between pl-3 w-${
																	(raisedHandOrIconUserIds
																		&& raisedHandOrIconUserIds.length
																		&& raisedHandOrIconUserIds.find(
																			(uid) => buyer.userId
																		) != null)
																	|| buyer.purchasedItem == true
																		? '32'
																		: '48'
																}`}
															>
																<EllipisWithTooltip
																	isParagraph
																	placement="bottom"
																	className="text-sm"
																	overridenStyle={{
																		whiteSpace: 'nowrap',
																		overflow: 'hidden',
																		textOverflow: 'ellipsis'
																	}}
																>
																	{buyer.nickname}
																</EllipisWithTooltip>
															</span>
															{attendees && attendees.length && (
																<MicOrRequestIcon
																	attendee={attendees.find(
																		(a) => a.userId == buyer.userId
																	)}
																	audioParticipants={audioParticipants}
																	matchAttendeesViaUserId
																	setRaisedHandOrMicIconUserId={(userId) => {
																		setRaisedHandOrIconUserIds((userIds) => {
																			if (!userIds || !userIds.length) return [userId];
																			const foundUserId = userIds.find(
																				(uid) => uid == userId
																			);
																			if (foundUserId != null) return userIds;
																			const newUserIds = [...userIds];
																			newUserIds.push(userId);
																			return newUserIds;
																		});
																	}}
																/>
															)}
														</p>
													</div>
												</td>
												<td className="inline-flex items-center text-sm space-x-2">
													{buyer.position == 1
														? getTimerElapsedByPosition(buyer.position)
														: getLineStartTimeElapsedByPosition(buyer.position)}

													{buyer.purchasedItem && (
														<div
															className="pl-2"
															onClick={() => {
																setPurchasedProductsUserId(buyer.userId);
																// setShowPurchasedItemsModal(true);
															}}
															onKeyDown={() => {
																setPurchasedProductsUserId(buyer.userId);
																// setShowPurchasedItemsModal(true);
															}}
														>
															<svg
																className="md:w-6 w-5 h-5 md:h-6"
																fill="none"
																stroke="currentColor"
																viewBox="0 0 24 24"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	strokeWidth="2"
																	d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
																/>
															</svg>
														</div>
													)}
													{isSeller ? (
														<HamburgerMenuIcon
															className="w-6 h-6"
															onClick={() => {
																const attendee = attendees.find(
																	(b) => b.userId == buyer.userId
																);
																if (attendee) {
																	setSelectedUserForActions(attendee);
																}
															}}
														/>
													) : null}
												</td>
											</tr>

											{buyer.position == 1 && isSeller && (
												<>
													<tr>
														<td className="px-4 xxs:px-4 py-2 whitespace-nowrap dark:text-white">
															{getEndTurnButton(buyer.userId)}
														</td>
													</tr>
													<tr className="border-b">
														<p className="w-64 flex justify-center font-bold dark:text-white">
															Next
														</p>
													</tr>
												</>
											)}

											{buyer.position == 1 && !isSeller && (
												<>
													<tr>
														<td className="px-4 xxs:px-4 py-2 whitespace-nowrap dark:text-white" />
													</tr>
													<tr className="border-b">
														<p className="w-64 flex justify-center font-bold dark:text-white">
															Next
														</p>
													</tr>
												</>
											)}
										</>
									))}
								</ReactSortable>
							</table>
							{!isSeller && !joined && !hideJoinButton && (
								<div className="flex-1 hidden md:block mt-3 dark:bg-darkGray-50">
									<button
										type="button"
										className="w-full h-12 px-6 text-red-100 transition-colors duration-150 bg-red-700 rounded-sm focus:shadow-outline hover:bg-red-800"
										onClick={joinQueue}
									>
										Get In Line
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
			</Beforeunload>
		</>
	);
};

export default RipNShipEventQueue;
