import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { referenceData } from '../api/referenceData.request';
import { referenceDataToSelect } from '../utils/referencedataParser';

const useReferenceData = (referenceTypeId, includeDescription = false) => {
	const [data, setData] = useState([]);
	const [loaded, setLoaded] = useState(false);
	if (!referenceTypeId) return [];

	useEffect(() => {
		referenceData(referenceTypeId)
			.then((res) => referenceDataToSelect(res.data, includeDescription))
			.then((res) => setData(res))
			.finally(() => setLoaded(true));
	}, [referenceTypeId]);

	return [data, loaded];
};
useReferenceData.propTypes = {
	referenceTypeId: PropTypes.number.isRequired,
	includeDescription: PropTypes.bool
};

export default useReferenceData;
