const silentSound = require('../assets/sounds/1-second-of-silence.mp3');

const useAudio = () => {
	const audio = new Audio();
	const playAudio = (url = silentSound) => {
		audio.src = url;
		audio.autoplay = true;
		audio.play();
	};
	return { playAudio };
};

export default useAudio;
