import React from 'react';
import OrderDetails from '../../components/order/order-details';

const OrderDetailsPage = ({ params }) => (
	<OrderDetails
		orderId={params.orderId}
		useSalesList={false}
		showStripeFee={false}
		showCustomer={false}
		allowRefund
	/>
);

export default OrderDetailsPage;
