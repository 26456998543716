import React, { useEffect } from 'react';
import Video from 'twilio-video';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';

const TwilioRoomMonitoringToolModal = ({ token, open, close }) => {
	useEffect(() => {
		if (!token) return;
		Video.connect(token).then((room) => {
			VideoRoomMonitor.registerVideoRoom(room);
			VideoRoomMonitor.on('closed', () => {
				VideoRoomMonitor.closeMonitor();
				close();
			});
		});
	}, [token]);

	useEffect(() => {
		if (open) {
			VideoRoomMonitor.openMonitor();
		}
	}, [open]);

	return <div> </div>;
};

export default TwilioRoomMonitoringToolModal;
