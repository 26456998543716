import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { css } from '@emotion/core';
import DataTable from 'react-data-table-component';
import { Link } from 'gatsby';
import BounceLoader from 'react-spinners/BounceLoader';
import Layout from '../../components/layouts/layout-default';
import HeaderHome from '../../components/common/header-home';
import { getUndetailedLiveEvents } from '../../api/event.request';
import { formatDateToLocal } from '../../utils/formatter';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const columns = [
	{
		name: 'Event name',
		selector: 'eventName',
		sortable: true,
		cell: (row) => (
			<Link
				className="font-bold hover:underline"
				to={`/admin/event/${row.eventId}`}
			>
				{row.eventName}
			</Link>
		),
		wrap: true
	},
	{
		name: 'Event Id',
		selector: 'eventId',
		sortable: true,
		wrap: true
	},
	{
		name: 'Event Type',
		selector: 'eventType.referenceDataValue',
		sortable: true,
		wrap: true
	},
	{
		name: 'Merchant name',
		selector: 'seller.accountUsers[0].account.companyName',
		sortable: true,
		wrap: true
	},
	{
		name: 'Event Time',
		selector: 'eventTime',
		cell: (row) => <p>{formatDateToLocal(row.eventTime)}</p>,
		sortable: true,
		wrap: true
	}
];

const AdminPage = () => {
	const [loading, setLoading] = useState(true);
	const [events, setEvents] = useState([]);
	const [tableEvents, setTableEvents] = useState([]);
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		getUndetailedLiveEvents()
			.then((res) => {
				setEvents(res.data);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const onEventSearch = (e, text) => {
		let value = '';
		if (e && e.target) {
			value = e.target.value;
			setSearchValue(value);
		} else {
			value = text;
		}
		const tempEvents = [...events].filter((event) => event.eventName.toLowerCase().includes(value.toLowerCase()));
		setTableEvents(tempEvents);
	};

	useEffect(() => {
		if (!events || !events.length) return;
		setTableEvents(events);
		if (searchValue) onEventSearch(null, searchValue);
	}, [events]);

	if (loading) {
		return (
			<div className="w-screen h-screen bg-white absolute left-0 top-0">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}

	return (
		<Layout auth>
			<div className="flex flex-col flex-1">
				<HeaderHome siteTitle="Admin" hideSearch setLoading={setLoading} />
				<div className="flex flex-col flex-grow w-full h-full mt-4 items-center">
					<ToastContainer />
					<div className="flex flex-col bg-white w-5/6 rounded-lg content-center justify-center">
						<div className="w-full justify-center pb-8 shadow-lg">
							<div className="flex flex-col lg:flex-row p-4 lg:p-8 justify-between items-between lg:items-stretch w-full">
								<div className="w-full flex flex-col md:flex-row  justify-start">
									<div className="relative text-gray-600 mb-2 md:mb-0 w-full md:w-auto">
										<input
											type="search"
											name="serch"
											placeholder="Search"
											className="h-10 px-5 pl-10 text-sm focus:outline-none border rounded-md w-full md:w-auto"
											onChange={onEventSearch}
										/>
										<button
											type="submit"
											className="absolute left-0 top-0 mt-3 ml-4"
										>
											<svg
												className="h-4 w-4 fill-current"
												xmlns="http://www.w3.org/2000/svg"
												// xmlns:xlink="http://www.w3.org/1999/xlink"
												version="1.1"
												id="Capa_1"
												x="0px"
												y="0px"
												viewBox="0 0 56.966 56.966"
												// style="enable-background:new 0 0 56.966 56.966;"
												// xml:space="preserve"
												width="512px"
												height="512px"
											>
												<path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
											</svg>
										</button>
									</div>
								</div>
								<div className="w-full flex flex-col md:flex-row justify-end">
									<Link to="/">
										<div className="rounded-lg px-3 py-2 bg-djawn text-white cursor-pointer hover:bg-djawn-300 text-center md:h-auto h-10 flex items-center">
											<span className="rounded-xl bg-black mr-2">
												<svg
													className="w-6 h-6"
													fill="none"
													stroke="currentColor"
													viewBox="0 0 24 24"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="3"
														d="M15 19l-7-7 7-7"
													/>
												</svg>
											</span>
											Back to Home Page
										</div>
									</Link>
								</div>
							</div>
							<div className="grid w-full overflow-x-scroll xl:overflow-x-hidden px-5 rounded-md border">
								<DataTable
									columns={columns}
									selectableRows
									selectableRowsHighlight
									data={tableEvents}
									pagination
									noContextMenu
									className=""
									contextActions={null}
									striped
									paginationComponentOptions={{
										rowsPerPageText: 'Rows per page:',
										rangeSeparatorText: 'of',
										noRowsPerPage: false,
										selectAllRowsItem: true,
										selectAllRowsItemText: 'All'
									}}
									paginationRowsPerPageOptions={[10, 25, 100]}
									customStyles={{
										rows: {
											style: {}
										},
										headCells: {
											style: {
												backgroundColor: '#e82e2c',
												color: 'white',
												fontSize: '1rem',
												fontWeight: 'bold'
											}
										}
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default AdminPage;
