/* eslint-disable */
import { navigate } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import { ToastContainer, toast } from 'react-toastify';

import AutoComplete from 'react-google-autocomplete';
import { useDropzone } from 'react-dropzone';
import Layout from '../components/layouts/layout-default';
import Header from '../components/header';

import { saveLogin } from '../utils/auth';
import {
	googleAuthentication,
	uploadProfileImage,
	facebookAuthentication
} from '../api/auth.request';
import env from '../../environment.config';
import { defaultToastError } from '../utils/toast-error';

import { findAddressComponent } from '../utils/placesParser';
import { defaultAutocompleteOptions } from '../utils/autocomplete-utils';
import { redirectToDefaultBrowser } from '../utils/redirect';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const SignupPage = ({ location }) => {
	const [loading, setLoading] = useState(false);
	const [, setRandomKey] = useState(new Date());
	const [loadingMessage, setLoadingMessage] = useState('loading...');
	const [, setFbResponse] = useState({});
	const [userAccessToken, setUserAccessToken] = useState('');
	const [userProfilePic, setUserProfilePic] = useState('');
	const [userFirstName, setUserFirstName] = useState('');
	const [userLastName, setUserLastName] = useState('');
	const [userEmailAddress, setUserEmailAddress] = useState('');
	const [userCompanyName] = useState('');
	const [userCompanyEIN] = useState('');
	const [userNickname, setUserNickname] = useState('');
	const [userAddress, setUserAddress] = useState('');
	const [userAddress2, setUserAddress2] = useState('');
	const [userCity, setUserCity] = useState('');
	const [userState, setUserState] = useState('');
	const [userPostal, setUserPostal] = useState('');
	const [files, setFiles] = useState([]);
	const [facebookImageBlob, setFacebookImageBlob] = useState(null);
	const [provider, setProvider] = useState('facebook');
	const [, setFirstAddressLineSelected] = useState(false);

	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		onDrop: (acceptedFiles) => {
			const newFiles = acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file)
				})
			);
			const tempNewFiles = [...newFiles];
			const tempOldFiles = [...files];
			Array.prototype.push.apply(tempOldFiles, tempNewFiles);

			setFiles(tempNewFiles);
		}
	});

	const onFileChange = () => {
		// pass
	};

	const onLoginError = () => {
		setLoading(false);
		toast.error('⚠️ Login Failed! Please try again.', {
			position: 'top-center',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	};

	useEffect(() => {
		redirectToDefaultBrowser();
	}, [window]);

	const customResponseFacebook = (response) => {
		setFbResponse(response);
		setUserAccessToken(response.accessToken);

		fetch(response.picture.data.url)
			.then((res) => res.blob())
			.then((blobImage) => {
				setFacebookImageBlob(blobImage);
			})
			.catch((err) => {
				console.error(err);
			});

		setUserProfilePic(response.picture.data.url);
		const name = response.name.split(' ');
		setUserFirstName(name[0]);
		setUserLastName(name[name.length - 1]);
		setUserEmailAddress(response.email);
	};

	const responseGoogle = (response) => {
		setUserProfilePic(response.imageUrl);

		fetch(response.imageUrl)
			.then((res) => res.blob())
			.then((blobImage) => {
				setFacebookImageBlob(blobImage);
			})
			.catch((err) => {
				console.error(err);
			});

		const name = response.name.split(' ');
		setUserFirstName(name[0]);
		setUserLastName(name[name.length - 1]);
		setUserEmailAddress(response.email);
	};

	useEffect(() => {
		if (!location || !location.state) return;
		const { facebookResponse, googleResponse } = location.state;
		if (!facebookResponse && !googleResponse) {
			navigate('/signin');
			return;
		}
		if (facebookResponse) {
			customResponseFacebook(facebookResponse);
			setProvider('facebook');
		} else {
			responseGoogle(googleResponse.response);
			setUserAccessToken(googleResponse.token);
			setProvider('google');
		}
	}, [location]);

	const onFirstNameChange = (value) => {
		setUserFirstName(value);
	};
	const onLastNameChange = (value) => {
		setUserLastName(value);
	};
	const onEmailChange = (value) => {
		setUserEmailAddress(value);
	};
	const onNicknameChange = (value) => {
		setUserNickname(value);
	};
	const onCityChange = (value) => {
		setUserCity(value);
	};
	const onStateChange = (value) => {
		setUserState(value);
	};
	const onPostalChange = (value) => {
		setUserPostal(value);
	};

	const submitFormData = async () => {
		setLoadingMessage('Saving user...');

		const userData = {
			mailingAddress1: userAddress,
			mailingAddress2: userAddress2,
			mailingCity: userCity,
			mailingState: userState,
			mailingZip: userPostal,
			accountType: 'Individual',
			legalEntityName: `${userFirstName} ${userLastName}`,
			nickName: userNickname,
			companyName: userCompanyName,
			firstName: userFirstName,
			lastName: userLastName,
			ein: userCompanyEIN,
			accessToken: userAccessToken,
			isSignUpInitiated: true
		};

		const providerAuthentication =
			provider === 'facebook' ? facebookAuthentication : googleAuthentication;
		const res = await providerAuthentication(userData).catch((error) => {
			console.error(error);
			if (error.response) {
				defaultToastError(error.response.data.error);
			} else if (error.request) {
				toast.error(
					'⚠️ Sign Up Failed! Server not responding. Please try after a while',
					{
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					}
				);
			} else {
				// Something happened in setting up the request that triggered an Error
				toast.error(
					'⚠️ Sign Up Failed! Something wen worng. Please try again.',
					{
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					}
				);
			}
		});

		if (res !== undefined && res !== null) {
			if (res.status === 200) {
				const formdata = new FormData();
				if (files && files.length > 0) {
					formdata.append('image', files[0]);
				} else {
					formdata.append('image', facebookImageBlob);
				}

				let image = null;
				uploadProfileImage(formdata, res.data.token)
					.then((imageResponse) => {
						image = imageResponse.data.imageUrl;
					})
					.catch((err) => {
						console.error(err); // TODO: add errors from db
					});

				saveLogin({
					name: userData.legalEntityName,
					pic: image,
					id: userData.id,
					email: userEmailAddress,
					token: res.data.token,
					refreshToken: res.data.refreshToken,
					userDetails: res.data.userDetails,
					expirationDate: res.data.expirationDate,
					firstName: res.data.firstName,
					lastName: res.data.lastName
				});

				navigate('/');
				setLoading(false);
			} else {
				toast.error('⚠️ Sign Up Failed! Please try again.', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
			}
		}
	};

	const getImageSource = () => {
		if (files && files.length > 0) {
			return files[0].preview;
		}
		if (userProfilePic && userProfilePic.length > 0) return userProfilePic;
		return null;
	};

	if (loading) {
		return (
			<div className="w-screen md:h-screen h-auto bg-white opacity-50 absolute left-0 top-0">
				<div className="flex flex-col w-full h-full justify-center items-center">
					<div className="w-full">
						<BounceLoader loading css={override} size={30} />
					</div>
					<div className="w-full mt-4">
						<p className="text-xl not-italic text-center font-sans font-semibold tracking-wide mb-1">
							{loadingMessage}
						</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<Layout auth>
			<div className="flex flex-col flex-1">
				<Header siteTitle="Signup" auth />
				<div className="flex flex-col flex-1 justify-center content-center">
					<ToastContainer />
					<div className="flex flex-col bg-white mx-auto md:w-3/5 w-full h-auto py-4 md:px-12 px-0 mt-0 md:-mt-24 rounded-lg max-w-2xl">
						<div className="flex w-full">
							<p className="md:text-2xl text-xl text-gray-500 text-center mx-auto">
								Personal Information
							</p>
						</div>

						<div className="flex flex-col w-full max-w-lg mx-auto">
							<div className="flex-grow p-4">
								<div className="flex-grow-0 w-full md:w-1/2 h-auto mx-auto p-4 pt-0">
									<div
										{...getRootProps({
											className:
												'dropzone bg-gray-200 rounded-full h-28 w-28 flex items-center justify-center cursor-pointer mx-auto text-base'
										})}
									>
										<input
											{...getInputProps({
												onChange: (event) => onFileChange(event.target)
											})}
											className="h-full w-full border bg-gray-300 md:hidden"
										/>
										{files.length > 0 ||
										(userProfilePic && userProfilePic.length > 0) ? (
											<img
												src={getImageSource()}
												className="h-full w-auto sm:h-full rounded-full cursor-pointer"
												alt="file.name"
											/>
										) : null}
									</div>
								</div>
								<div className="flex flex-col md:flex-row w-full">
									<div className="flex flex-col w-full mb-4 md:pr-4">
										<div className="w-full mt-1 relative rounded-md shadow-sm">
											<input
												type="text"
												name="first_name"
												id="first_name"
												className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
												placeholder="First Name - Required value"
												value={userFirstName}
												onChange={(e) => onFirstNameChange(e.target.value)}
											/>
										</div>
									</div>

									<div className="flex flex-col w-full mb-4">
										<div className="w-full mt-1 relative rounded-md shadow-sm">
											<input
												type="text"
												name="last_name"
												id="last_name"
												className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
												placeholder="Last Name - Required value"
												value={userLastName}
												onChange={(e) => onLastNameChange(e.target.value)}
											/>
										</div>
									</div>
								</div>

								<div className="flex flex-col w-full mb-4">
									<div>
										<div className="mt-1 relative rounded-md shadow-sm">
											<input
												type="text"
												name="email"
												id="email"
												className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
												placeholder="jhon_doe@example.com - Required value"
												value={userEmailAddress}
												onChange={(e) => onEmailChange(e.target.value)}
												disabled
											/>
										</div>
									</div>
								</div>

								<div className="flex flex-col w-full mb-4">
									<div>
										<div className="mt-1 relative rounded-md shadow-sm">
											<input
												type="text"
												name="nickname"
												id="nickname"
												className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
												placeholder="Screen Name - Required value"
												value={userNickname}
												onChange={(e) => onNicknameChange(e.target.value)}
											/>
										</div>
									</div>
								</div>

								<div className="flex flex-col w-full mb-4">
									<div>
										<div className="mt-1 relative rounded-md shadow-sm">
											<AutoComplete
												apiKey={env.google_places_key}
												onChange={(e) => {
													if (e.target.value || e.target.value.length === 0) {
														setUserCity('');
														setUserPostal('');
														setUserState('');
													}
													setUserAddress(e.target.value);
													setFirstAddressLineSelected(false);
												}}
												onPlaceSelected={(place, e) => {
													setFirstAddressLineSelected(true);

													const route = findAddressComponent(
														place.address_components,
														'route'
													);

													const streetNumber = findAddressComponent(
														place.address_components,
														'street_number'
													);
													if (route) {
														if (streetNumber) {
															setUserAddress(`${streetNumber} ${route}`);
															if (e.value) {
																e.value = `${streetNumber} ${route}`;
															}
														} else {
															setUserAddress(route);
															if (e.value) {
																e.value = route;
															}
														}
													}
													// } else {
													//     if (
													//         place.formatted_address
													//     )
													//         setUserAddress(
													//             place.formatted_address
													//         );

													//     if (e.value) {
													//         e.value =
													//             place.formatted_address;
													//     }
													// }

													const foundPostalCode = findAddressComponent(
														place.address_components,
														'postal_code'
													);
													if (foundPostalCode) {
														setUserPostal(foundPostalCode);
													}

													const foundState = findAddressComponent(
														place.address_components,
														'administrative_area_level_1',
														true
													);
													if (foundState) {
														setUserState(foundState);
													}

													if (place.vicinity) {
														setUserCity(place.vicinity);
													}
												}}
												className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
												options={defaultAutocompleteOptions}
												placeholder="Street Address Line - Required value"
											/>
										</div>
									</div>
								</div>

								<div className="flex flex-col w-full mb-4">
									<div>
										<div className="mt-1 relative rounded-md shadow-sm">
											<input
												type="text"
												name="mailingAddress1"
												id="mailingAddress1"
												className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
												placeholder="Apartment, suite, etc."
												value={userAddress2}
												onChange={(e) => setUserAddress2(e.target.value)}
											/>
										</div>
									</div>
								</div>

								<div className="flex flex-col md:flex-row w-full mb-4">
									<div className="flex flex-col w-full mb-4 lg:pr-4">
										<div className="w-full mt-1 relative rounded-md shadow-sm">
											<input
												type="text"
												name="city"
												id="city"
												className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
												placeholder="City"
												value={userCity}
												onChange={(e) => onCityChange(e.target.value)}
												disabled
											/>
										</div>
									</div>

									<div className="flex flex-col w-full mb-4 lg:pr-4">
										<div className="w-full mt-1 relative rounded-md shadow-sm">
											<input
												type="text"
												name="state"
												id="state"
												className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
												placeholder="State"
												value={userState}
												onChange={(e) => onStateChange(e.target.value)}
												disabled
											/>
										</div>
									</div>

									<div className="flex flex-col w-full md:mb-4 mb-0">
										<div className="w-full mt-1 relative rounded-md shadow-sm">
											<input
												type="text"
												name="post_code"
												id="post_code"
												className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
												placeholder="Zip"
												value={userPostal}
												onChange={(e) => onPostalChange(e.target.value)}
												disabled
											/>
										</div>
									</div>
								</div>

								<div className="flex flex-col w-full mb-4 md:mb-0 lg:mb-0">
									<div>
										<button
											type="button"
											className="w-full h-10 mx-auto flex items-center justify-center px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-900 md:py-4 md:text-lg md:px-10"
											onClick={submitFormData}
										>
											SAVE
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default SignupPage;
