import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import {
	Tab, Tabs, TabList, TabPanel
} from 'react-tabs';
import { useQueryParam, StringParam } from 'use-query-params';
import Layout from '../components/layouts/layout-default';
import HeaderHome from '../components/common/header-home';
import 'react-tabs/style/react-tabs.css';
import ProfilePage from './profile';
import SellerEditViewProfilePage from '../components/seller/seller-profile';
import { getSellerProfile } from '../api/seller.request';

const ProfileViewerPage = () => {
	const [tabValue, setTabValue] = useState(0);
	const [showSellerTab, setShowSellerTab] = useState(false);
	const [merchantId] = useQueryParam('merchantId', StringParam);
	const [paypalMerchantId] = useQueryParam('merchantIdInPayPal', StringParam);

	useEffect(() => {
		getSellerProfile()
			.then(() => {
				setShowSellerTab(true);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (paypalMerchantId && merchantId) {
			setTabValue(1);
		}
	}, [paypalMerchantId]);

	return (
		<Layout auth>
			<div className="flex flex-col flex-1">
				<HeaderHome siteTitle="Profile" hideSearch />
				<div className="">
					<ToastContainer />

					{showSellerTab ? (
						<div className="flex flex-col min-w-0 break-words bg-white w-full rounded h-full">
							<div className="mx-5">
								<Tabs
									className="w-full"
									onSelect={(e) => setTabValue(e)}
									selectedIndex={tabValue}
								>
									<TabList className="cursor-pointer flex mb-0 list-none flex-wrap flex-row bg-gray-200 rounded-lg">
										<Tab className="-mb-px last:mr-0 flex-auto pt-3 pb-4 text-center ">
											Personal
										</Tab>

										<Tab className="-mb-px last:mr-0 flex-auto pt-3 pb-4 text-center">
											Seller
										</Tab>
									</TabList>
									<TabPanel className="tab-content tab-space">
										<ProfilePage />
									</TabPanel>
									<TabPanel className="tab-content tab-space">
										<SellerEditViewProfilePage
											paypalMerchantId={paypalMerchantId}
											merchantId={merchantId}
										/>
									</TabPanel>
								</Tabs>
							</div>
						</div>
					) : (
						<ProfilePage />
					)}
				</div>
			</div>
		</Layout>
	);
};

export default ProfileViewerPage;
