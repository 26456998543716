import React from 'react';
import chromelogo from '../images/chromelogo.png';
import safarilogo from '../images/safarilogo.png';

const NotSupportedBrowser = () => (
	<div style={{ textAlign: 'center' }} className="mt-20 font-bold">
		<div className="flex flex-col">
			<div style={{ textAlign: 'center' }}>
				<p className="text-opacity-100"> Sorry DJAWN does not support this browser. Please use a supported browser from the list below.</p>
			</div>

			<div className="flex flex-row justify-center items-center mt-4">
				<div>
					<img
						className="sm:h-20"
						src={chromelogo}
						alt="Workflow"
					/>
					<p>Chrome</p>
				</div>
				<div>
					<img
						className="sm:h-20"
						src={safarilogo}
						alt="Workflow"
					/>
					<p>Safari</p>
				</div>
			</div>
		</div>
	</div>
);

export default NotSupportedBrowser;
