import React from 'react';
import Popup from '../../../components/popup';

const ArrowButton = ({
	openQueueContainer,
	onOpenQueueContainer,
	updatePopupsStatus,
	containerRef,
	buttonRef
}) => (
	<Popup
		ref={containerRef}
		removeTriggerEvents
		position="top left"
		// className="djawn-popup"
		trigger={(
			<div className="pl-2">
				<button
					type="button"
					className="pulsing-button inline-flex items-center justify-center w-8 h-8 text-indigo-100 transition-colors duration-150 bg-djawn rounded-full focus:shadow-outline hover:bg-djawn-300
bg-opacity-50 relative"
					style={{
						outline: 'none',
						zIndex: '60',
						marginTop: '-6%'
					}}
					ref={buttonRef}
					id="live-event-arrow-button"
					onClick={() => {
						updatePopupsStatus('show_cards', true);
						onOpenQueueContainer(window.scrollY, window.scrollY);
					}}
					onKeyDown={() => {
						updatePopupsStatus('show_cards', true);
						onOpenQueueContainer(window.scrollY, window.scrollY);
					}}
				>
					{openQueueContainer ? (
						<svg
							className="w-4 h-4 fill-current"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M9 5l7 7-7 7"
							/>
						</svg>
					) : (
						<svg
							className="w-4 h-4 fill-current"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M15 19l-7-7 7-7"
							/>
						</svg>
					)}
				</button>
			</div>
		)}
	>
		<div
			style={{
				zIndex: '60'
			}}
		>
			<h3 className="text-white text-sm pt-4 pl-3" style={{ zIndex: '60' }}>
				Click here to buy cards.
			</h3>
			<div
				className="flex justify-between px-3 pt-3 mb-1"
				style={{ zIndex: '60' }}
			>
				<p
					className="cursor-pointer text-white text-md"
					onClick={() => updatePopupsStatus('show_cards', true)}
					onKeyDown={() => updatePopupsStatus('show_cards', true)}
				>
					Dismiss
				</p>
				<p
					className="cursor-pointer text-white text-md"
					onClick={() => updatePopupsStatus('show_cards', false)}
					onKeyDown={() => updatePopupsStatus('show_cards', false)}
				>
					Don't show again
				</p>
			</div>
		</div>
	</Popup>
);

export default ArrowButton;
