import React, { useEffect, useState, useContext } from 'react';
import Button from '../../../components/button';
import Modal from '../../../components/modals';
import { TwilioTrackContext } from '../../../contexts/twilio-track';
import {
	CLOSED_ALL_MIC_NOTIFIER,
	CLOSED_MIC_NOTIFIER,
	MUTED_MIC_NOTIFIER,
	UNMUTED_MIC_NOTIFIER
} from '../../../utils/mic-notification-buyer-types';

const ParticipantMicBuyerNotifier = () => {
	const [showModal, setShowModal] = useState(false);
	const { buyerNotificationType, setBuyerNotificationType } = useContext(
		TwilioTrackContext
	);

	const [action, setAction] = useState('');
	const [entireTextChanging, setEntireTextChanging] = useState(false);

	useEffect(() => {
		if (!buyerNotificationType) return;
		let actionType = '';
		setEntireTextChanging(false);
		switch (buyerNotificationType) {
			case CLOSED_MIC_NOTIFIER:
				actionType = 'closed';
				break;
			case MUTED_MIC_NOTIFIER:
				actionType = 'muted';
				break;
			case UNMUTED_MIC_NOTIFIER:
				actionType = 'unmuted';
				break;
			case CLOSED_ALL_MIC_NOTIFIER:
				actionType = 'The seller canceled all active mics.';
				setEntireTextChanging(true);
				break;
			default:
				actionType = '';
				break;
		}
		setAction(actionType);
	}, [buyerNotificationType]);

	useEffect(() => {
		setShowModal(action != '');
	}, [action]);

	if (!action || !showModal) return <></>;
	return (
		<Modal
			showModal={showModal}
			onClose={() => setShowModal(false)}
			hideCloseButton
		>
			<div>
				<p>
					{entireTextChanging ? action : `Your mic is ${action} by the seller.`}
				</p>
				<div className="mt-3 flex xxs:flex-row justify-center items-center xxs:w-full xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0 mb-5">
					<Button
						height="12"
						mobileHeight="8"
						onClick={() => {
							setBuyerNotificationType(null);
							setAction('');
						}}
					>
						Ok
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default ParticipantMicBuyerNotifier;
