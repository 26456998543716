import React, { useState, useEffect } from 'react';

const TemplateFilter = ({
	eventType,
	onClose,
	sportTypes,
	filteredSlotSize,
	filteredSportType,
	filteredTierSize,
	onFilter
}) => {
	const [sportType, setSportType] = useState('');
	const [tierSize, setTierSize] = useState('');
	const [slotSize, setSlotSize] = useState('');
	const [disableTierSize, setDisableTierSize] = useState(false);

	useEffect(() => {
		if (eventType == 10) {
			setDisableTierSize(true);
			setTierSize(1);
		} else setTierSize(filteredTierSize);

		setSportType(filteredSportType);

		setSlotSize(filteredSlotSize);
	}, []);

	const clearAllFilters = () => {
		setSportType('');
		if (eventType != 10) setTierSize('');
		setSlotSize('');
	};

	const setNumberOfSlot = (value) => {
		value = value.replace(/[^\d].+/, '');
		setSlotSize(value);
	};

	const setNumberOfTiers = (value) => {
		value = value.replace(/[^\d].+/, '');
		setTierSize(value);
	};

	return (
		<div>
			<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-1 top-2.5 z-50 top-30 outline-none focus:outline-none">
				<div className="relative w-auto my-6 mx-auto max-w-3xl">
					{/* content */}
					<div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						{/* header */}

						{/* body */}
						<div className="relative p-5 pb-2 text-center justify-center overflow-hidden h-100">
							<span className="text-lg font-bold">Filters</span>
							<div className="py-1" />
							<div className="flex flex-row p-2">
								<div className="flex flex-col" style={{ width: '35%' }}>
									<label
										htmlFor="sport_type"
										className="block text-sm font-bold text-gray-700 p-4 pr-4 pl-1"
									>
										{' '}
										Sport Type
									</label>
								</div>

								<div
									className="flex flex-col mt-1 relative rounded-md w-full"
									style={{ width: '65%' }}
								>
									<select
										id="sport_type"
										name="sport_type"
										autoComplete="sport_type"
										className="focus:ring-indigo-400 focus:border-indigo-400 w-full pl-2 pr-2 block h-10  sm:text-sm border border-gray-200 rounded-md"
										onBlur={(e) => setSportType(e.target.value)}
										onChange={(e) => setSportType(e.target.value)}
										value={sportType}
										// style={{ width: '100%' }}
									>
										<option key={0} value={0}>
											Select Sports
										</option>
										{sportTypes.map((object) => (
											<option
												key={object.referenceDataId}
												value={object.referenceDataId}
												selected={object.referenceDataId == sportType}
											>
												{object.referenceDataValue}
											</option>
										))}
									</select>
								</div>
							</div>
							<div className="flex flex-row p-2">
								<div className="flex flex-col" style={{ width: '35%' }}>
									{' '}
									<label
										htmlFor="tier_size"
										className="block text-sm font-bold text-gray-700 p-4"
									>
										Tier Size
									</label>
								</div>

								<div
									className="flex flex-col mt-1 relative rounded-md"
									style={{ width: '65%' }}
								>
									<input
										type="text"
										name="tierSize"
										id="tierSize"
										maxLength="10"
										className="focus:ring-indigo-400 focus:border-indigo-400 w-full pl-2 pr-2 block h-10  sm:text-sm border border-gray-200 rounded-md"
										placeholder="Tier Size"
										value={tierSize}
										onBlur={(e) => setNumberOfTiers(e.target.value)}
										onChange={(e) => setNumberOfTiers(e.target.value)}
										readOnly={disableTierSize}
									/>
								</div>
							</div>
							<div className="flex flex-row p-2 pb-0">
								<div className="flex flex-col" style={{ width: '35%' }}>
									{' '}
									<label
										htmlFor="slot_size"
										className="block text-sm font-bold text-gray-700 p-4"
									>
										Slot Size
									</label>
								</div>
								<div
									className="flex flex-col mt-1 relative rounded-md"
									style={{ width: '65%' }}
								>
									<input
										type="text"
										name="slotSize"
										id="slotSize"
										maxLength="30"
										className="focus:ring-indigo-400 focus:border-indigo-400 w-full pl-2 pr-2 block h-10  sm:text-sm border border-gray-200 rounded-md"
										placeholder="Slot Size"
										value={slotSize}
										onBlur={(e) => setNumberOfSlot(e.target.value)}
										onChange={(e) => setNumberOfSlot(e.target.value)}
										// style={{ width: '80%' }}
									/>
								</div>
							</div>
							<div className="flex flex-row px-2" style={{ float: 'right' }}>
								<a
									className="text-blue-500 hover:text-blue-700 text-sm"
									onClick={clearAllFilters}
									onKeyDown={clearAllFilters}
									style={{
										cursor: 'pointer'
									}}
								>
									Clear All
								</a>
							</div>
						</div>
						{/* footer */}
						<div className="flex items-center justify-end p-5 border-t border-solid border-blueGray-200 rounded-b">
							<button
								type="button"
								className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-lg"
								onClick={() => onClose()}
							>
								Close
							</button>
							<button
								type="button"
								className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg ml-2"
								onClick={() => {
									onFilter(sportType, tierSize, slotSize);
								}}
							>
								Apply
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="opacity-25 fixed inset-0 z-40 bg-black" />
		</div>
	);
};

export default TemplateFilter;
