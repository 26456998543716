/* eslint-disable prefer-destructuring */
import { useEffect, useState } from 'react';
import {
	addTwilioParticipantInfoToEventAttendee,
	cancelMicRequest,
	closeActiveMic,
	deleteMicRequest,
	expireActiveMicSessionsOrRequests,
	requestedMicDecision,
	requestMic,
	unsubcribeAudioTracksFromEvent,
	updateMutedMicStatus
} from '../api/event.request';
import { isTimeDue } from '../utils/formatter';
import { informationToast } from '../utils/information-toast';
import {
	CLOSED_ALL_MIC_NOTIFIER,
	CLOSED_MIC_NOTIFIER,
	MUTED_MIC_NOTIFIER,
	UNMUTED_MIC_NOTIFIER
} from '../utils/mic-notification-buyer-types';

// TODO: Load audio participants from backend initially
const useTwilioTracks = (eventId, isHost, twilioRoomIdentity) => {
	const [requestedMicAccess, setRequestedMicAccess] = useState(false);
	const [canceledMicRequestAccess, setCanceledMicRequestAccess] = useState(
		false
	);
	const [twilioParticipantId, setTwilioParticipantId] = useState(null);
	const [audioParticipants, setAudioParticipants] = useState([]);
	const [newAudioParticipant, setNewAudioParticipant] = useState(null);
	const [localAudioTracks, setLocalAudioTracks] = useState(new Map());
	const [
		newCanceledMicRequestAccess,
		setNewCanceledMicRequestAccess
	] = useState(null);
	const [expiredMicAccessMessage, setExpiredMicAccessMessage] = useState(null);
	const [approvedAudioParticipants, setApprovedAudioParticipants] = useState(
		[]
	);
	const [micAccessApprovedBySeller, setMicAccessApprovedBySeller] = useState(
		null
	);
	const [micAccessApprovedByBuyer, setMicAccessApprovedByBuyer] = useState(
		null
	);
	const [newMutedAudioParticipants, setNewMutedAudioParticipants] = useState(
		[]
	);
	const [mutedByBuyer, setMutedByBuyer] = useState(false);
	const [mutedBySeller, setMutedBySeller] = useState(false);
	const [buyerNotificationType, setBuyerNotificationType] = useState(null);

	const requestMicrophoneAccess = async () => {
		try {
			await requestMic(eventId, twilioParticipantId, twilioRoomIdentity);
		} catch (err) {
			console.warn(err);
		}
	};

	useEffect(() => {
		if (!approvedAudioParticipants || !approvedAudioParticipants.length) return;
		const foundLocalApprovedAudioParticipant = approvedAudioParticipants.find(
			(participant) => participant.twilioParticipantId == twilioParticipantId
		);
		if (requestedMicAccess && foundLocalApprovedAudioParticipant != null) {
			setMicAccessApprovedBySeller(foundLocalApprovedAudioParticipant.approved);
			if (!foundLocalApprovedAudioParticipant.approved) {
				setRequestedMicAccess(false);
			}
		}
	}, [approvedAudioParticipants]);

	const disableAudioTracks = (audioTracks) => {
		audioTracks.forEach((trackPub) => {
			trackPub.track.disable();
		});
	};
	const enableAudioTracks = (audioTracks) => {
		audioTracks.forEach((trackPub) => {
			trackPub.track.enable();
		});
	};

	useEffect(() => {
		if (!twilioParticipantId || !twilioRoomIdentity || !eventId) return;
		addTwilioParticipantInfoToEventAttendee(
			eventId,
			twilioRoomIdentity,
			twilioParticipantId
		)
			.then((res) => {
				if (!res || !res.data || !res.data.length) return;
				const fetchedAudioParticipants = res.data.map((participant) => ({
					limitInSeconds: participant.limitInSeconds,
					startDate: participant.startDate,
					twilioParticipantId: participant.twilioParticipantId,
					trackId: participant.trackId,
					userId: participant.buyerId,
					muted: participant.muted,
					approved: participant.approved
				}));
				setAudioParticipants(fetchedAudioParticipants);
			})
			.catch((err) => {
				console.warn(err);
			});
	}, [twilioParticipantId, twilioRoomIdentity, eventId]);

	useEffect(() => {
		if (isHost || !twilioParticipantId || !twilioRoomIdentity) return;
		if (requestedMicAccess) {
			requestMicrophoneAccess();
		}
		disableAudioTracks(localAudioTracks);
	}, [requestedMicAccess, localAudioTracks, isHost, twilioRoomIdentity]);

	useEffect(() => {
		if (!newMutedAudioParticipants || !newMutedAudioParticipants.length) return;
		const foundLocalParticipant = newMutedAudioParticipants.find(
			(participant) => participant.twilioParticipantId == twilioParticipantId
		);
		if (foundLocalParticipant != null) {
			if (foundLocalParticipant.muted) {
				disableAudioTracks(localAudioTracks);
			} else {
				enableAudioTracks(localAudioTracks);
			}
			if (foundLocalParticipant.initiatedBySeller === true) {
				setMutedBySeller(foundLocalParticipant.muted);
				if (foundLocalParticipant.muted == true) {
					setBuyerNotificationType(MUTED_MIC_NOTIFIER);
				} else {
					setBuyerNotificationType(UNMUTED_MIC_NOTIFIER);
				}
			}
			setMutedByBuyer(foundLocalParticipant.muted);
		}
		setAudioParticipants((tempParticipants) => tempParticipants.map((participant) => {
			const foundMutedParticipant = newMutedAudioParticipants.find(
				(mutedParticipant) => mutedParticipant.twilioParticipantId
						== participant.twilioParticipantId
			);
			if (foundMutedParticipant) {
				participant.muted = foundMutedParticipant.muted;
			}
			return participant;
		}));
	}, [newMutedAudioParticipants]);

	const cancelMicRequestAccess = async () => {
		try {
			await cancelMicRequest(eventId, twilioParticipantId);
		} catch (err) {
			console.warn(err);
		}
	};

	useEffect(() => {
		if (!newCanceledMicRequestAccess) return;
		if (
			newCanceledMicRequestAccess.twilioParticipantId == twilioParticipantId
		) {
			setRequestedMicAccess(false);
			setCanceledMicRequestAccess(false);
			setMutedByBuyer(false);
			setMutedBySeller(null);
		}
	}, [newCanceledMicRequestAccess]);

	useEffect(() => {
		if (!canceledMicRequestAccess) return;
		cancelMicRequestAccess();
	}, [canceledMicRequestAccess]);

	// TODO: check if likely to be redundant
	useEffect(() => {
		if (!twilioParticipantId || !newAudioParticipant) return;
		if (newAudioParticipant.twilioParticipantId == twilioParticipantId) {
			if (newAudioParticipant.approved) {
				enableAudioTracks(localAudioTracks);
			} else {
				setRequestedMicAccess(false);
			}
		}
		if (!newAudioParticipant.approved) return;
		setAudioParticipants([
			...audioParticipants,
			{
				limitInSeconds: newAudioParticipant.limitInSeconds,
				startDate: newAudioParticipant.startDate,
				twilioParticipantId: newAudioParticipant.twilioParticipantId,
				trackId: newAudioParticipant.trackId,
				userId: newAudioParticipant.buyerId,
				muted: newAudioParticipant.muted
			}
		]);
	}, [newAudioParticipant, twilioParticipantId, localAudioTracks]);

	useEffect(() => {
		if (!audioParticipants || !audioParticipants.length) return;

		const localAudioParticipant = audioParticipants.find(
			(participant) => participant.twilioParticipantId == twilioParticipantId
		);
		if (!localAudioParticipant) return;

		const intervalId = setInterval(() => {
			if (
				isTimeDue(
					localAudioParticipant.startDate,
					localAudioParticipant.limitInSeconds
				)
			) {
				unsubcribeAudioTracksFromEvent(eventId, twilioParticipantId, true)
					.then(() => {
						informationToast(
							'Time is expired for your mic. Please raise a mic request to talk again'
						);
					})
					.catch((err) => {
						console.warn(err);
					});

				setRequestedMicAccess(false);
				setMicAccessApprovedByBuyer(false);
				setMutedByBuyer(false);
				setMutedBySeller(false);
				disableAudioTracks(localAudioTracks);
				clearInterval(intervalId);
			}
		}, 1000);

		return () => clearInterval(intervalId);
	}, [audioParticipants]);

	useEffect(() => {
		if (
			!expiredMicAccessMessage
			|| !expiredMicAccessMessage.users
			|| !expiredMicAccessMessage.users.length
		) return;

		const foundLocalAudioParticipant = expiredMicAccessMessage.users.find(
			(u) => u.twilioParticipantId == twilioParticipantId
		);

		if (foundLocalAudioParticipant) {
			if (expiredMicAccessMessage.closed === true) {
				disableAudioTracks(localAudioTracks);
				setRequestedMicAccess(false);
				setMicAccessApprovedByBuyer(false);
				setMicAccessApprovedBySeller(null);
			}
			if (expiredMicAccessMessage.closedBySeller == true) {
				if (expiredMicAccessMessage.closed === true) {
					setBuyerNotificationType(
						expiredMicAccessMessage.closeAllMicsNotificationMessage === true
							? CLOSED_ALL_MIC_NOTIFIER
							: CLOSED_MIC_NOTIFIER
					);
				}
			}
			setMutedByBuyer(false);
			setMutedBySeller(null);
		}

		const newAudioParticipants = [...audioParticipants].filter(
			(audioParticipant) => {
				const foundExpiredAudioParticipant = expiredMicAccessMessage.users.find(
					(u) => u.twilioParticipantId == audioParticipant.twilioParticipantId
				);
				if (foundExpiredAudioParticipant) return false;
				return true;
			}
		);

		setAudioParticipants(newAudioParticipants);
	}, [expiredMicAccessMessage]);

	const removeLocalMicAccess = () => {
		setMicAccessApprovedByBuyer(false);
		setMicAccessApprovedBySeller(null);
		setRequestedMicAccess(false);
	};
	const approveOrDeclineMicRequest = async (
		users,
		approve = true,
		updateAll = false
	) => {
		try {
			await requestedMicDecision(eventId, approve, users, updateAll);
		} catch (err) {
			console.warn(err);
		}
	};

	const muteOrUnmuteMicParticipants = (
		users,
		mute,
		initiatedBySeller,
		muteOrUnmuteAll
	) => {
		updateMutedMicStatus(
			eventId,
			users,
			mute,
			initiatedBySeller,
			muteOrUnmuteAll
		).catch((err) => {
			console.warn(err);
		});

		// doing this for the seller to quickly update the mic mute/unmute functionality
		if (!initiatedBySeller) {
			setMutedByBuyer(mute);
			if (mute) {
				disableAudioTracks(localAudioTracks);
			} else {
				enableAudioTracks(localAudioTracks);
			}
		}
	};

	const closeMic = (userTwilioParticipantIds, initiatedBySeller, closeAll) => {
		closeActiveMic(
			eventId,
			userTwilioParticipantIds,
			initiatedBySeller,
			closeAll
		).catch((err) => {
			console.warn(err);
		});
	};
	const expireActiveMicRequestsOrSessions = () => {
		expireActiveMicSessionsOrRequests(eventId, twilioRoomIdentity).catch(
			(err) => {
				console.error(err);
			}
		);
	};

	useEffect(() => {
		if (!eventId || !twilioRoomIdentity) return;
		expireActiveMicRequestsOrSessions();
	}, [eventId, twilioRoomIdentity]);

	const deleteCurrentMicRequest = async () => {
		try {
			await deleteMicRequest(eventId, twilioParticipantId);
		} catch (err) {
			console.warn(err);
		}
	};
	return {
		setLocalAudioTracks,
		twilioParticipantId,
		setTwilioParticipantId,
		requestedMicAccess,
		setRequestedMicAccess,
		setNewAudioParticipant,
		approveOrDeclineMicRequest,
		audioParticipants,
		setAudioParticipants,
		setCanceledMicRequestAccess,
		setNewCanceledMicRequestAccess,
		setExpiredMicAccessMessage,
		setApprovedAudioParticipants,
		micAccessApprovedByBuyer,
		micAccessApprovedBySeller,
		setMicAccessApprovedByBuyer,
		setMicAccessApprovedBySeller,
		muteOrUnmuteMicParticipants,
		setNewMutedAudioParticipants,
		closeMic,
		mutedByBuyer,
		mutedBySeller,
		buyerNotificationType,
		setBuyerNotificationType,
		deleteCurrentMicRequest,
		removeLocalMicAccess
	};
};

export default useTwilioTracks;
