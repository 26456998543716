import React from 'react';

const ConversationMessageOptions = ({
	showModal,
	setShowModal,
	setSelectedForEdit,
	setSelectedForReply,
	disableMessaging,
	selectedMessage,
	authenticatedUserId
}) => (
	<>
		{showModal ? (
			<>
				<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
					<div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
						<div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
							<p className="font-semibold text-gray-800">Options</p>
							<svg
								className="cursor-pointer w-6 h-6"
								onClick={() => setShowModal(false)}
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</div>
						<div className="flex flex-col px-6 py-5 bg-gray-50">
							<button
								type="button"
								onClick={() => {
									setSelectedForReply();
									setShowModal(false);
								}}
								activeClassName="bg-gray-200"
								className="item mt-0 flex items-center text-base font-normal text-black w-full px-6 py-3 hover:bg-gray-200 active:bg-green-700"
							>
								<svg
									className="w-6 h-6 mr-4"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
									/>
								</svg>
								<span className="item-text">Reply to message</span>
							</button>
							{authenticatedUserId == selectedMessage.user_id
								&& !disableMessaging && (
								<button
									type="button"
									onClick={() => {
										setSelectedForEdit(true);
										setShowModal(false);
									}}
									activeClassName="bg-gray-200"
									className="item mt-0 flex items-center text-base font-normal text-black w-full px-6 py-3 hover:bg-gray-200 active:bg-green-700"
								>
									<svg
										className="w-6 h-6 mr-4"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
										/>
									</svg>
									<span className="item-text">Edit message</span>
								</button>
							)}
						</div>
					</div>
				</div>
				<div
					className="opacity-25 fixed inset-0 z-40 bg-black"
					onClick={() => setShowModal(false)}
					onKeyDown={() => setShowModal(false)}
				/>
			</>
		) : null}
	</>
);

export default ConversationMessageOptions;
