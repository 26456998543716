/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import quillEmoji from 'quill-emoji';
import 'quill-emoji/dist/quill-emoji.css';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { isTruthy } from '../../utils/commonFunction';

// TODO: Add Prop Types
const Toolbar = ({
	sendButtonEnabled,
	onSendMessage,
	attachmentDisabled,
	onFileAttached,
	hideSendButton,
	hideAttachmentsButton,
	removeListsFromToolbar,
	authenticated,
	onRedirectToSignIn,
	yOverflow,
	setYOverflow,
	useInlineInputEditor,
	toolbarId
}) => (
	<div
		id={`custom-toolbar-${toolbarId}`}
		className={`${
			useInlineInputEditor
				? 'inline-editor-toolbar-container w-6 mr-3 flex items-start'
				: ''
		}`}
	>
		{authenticated && (
			<>
				{!useInlineInputEditor ? (
					<>
						<select
							className="ql-size"
							style={isMobile ? { marginLeft: '-3px' } : {}}
							defaultValue="small"
						>
							<option className="truncate" value="extra-small">
								Small
							</option>
							<option className="truncate" value="small">
								Normal
							</option>
							<option className="truncate" value="medium">
								Large
							</option>
							<option className="truncate" value="large">
								Huge
							</option>
						</select>
						<button className="ql-bold" />
						<button className="ql-italic" />
						<button className="ql-underline" />
						{!removeListsFromToolbar && (
							<>
								<button className="ql-strike" />
								<button className="ql-list" value="ordered" />
								<button className="ql-list" value="bullet" />
							</>
						)}
					</>
				) : null}
				<button
					className="ql-emoji"
					onClick={() => {
						setYOverflow(!yOverflow);
					}}
				/>
			</>
		)}
		{!hideAttachmentsButton && !useInlineInputEditor && (
			<button className="attachment-button outline-none">
				<label htmlFor="photo" className="outline-none">
					<svg
						className="w-4 h-4"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
						/>
					</svg>
					<input
						type="file"
						name="photo"
						id="photo"
						disabled={attachmentDisabled}
						className="focus:ring-indigo-400 focus:border-indigo-400 block w-full h-10  text-sm md:text-md border border-gray-200 rounded-md"
						onChange={onFileAttached}
						accept="image/png, image/jpg, image/jpeg, image/gif, image/tif, image/bmp, image/tiff, video/*, application/pdf, .csv, text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
					/>
				</label>
			</button>
		)}
		{!hideSendButton && !useInlineInputEditor && (
			<>
				{authenticated ? (
					<button
						className={`send-button send-button-size${
							isTruthy(removeListsFromToolbar) ? '-2' : ''
						} rounded-md ml-auto`}
						disabled={!sendButtonEnabled}
						onClick={onSendMessage}
					>
						<svg
							width="50"
							height="20"
							viewBox="0 0 19 20"
							fill="white"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M11.4397 9.95393L3.4031 9.98068L1.6002 2.40599C1.36403 1.41337 2.42167 0.604794 3.31469 1.09604L17.606 8.94496C18.4177 9.39231 18.4095 10.5646 17.5886 11.0085L3.24532 18.7826C2.34757 19.2689 1.30511 18.4662 1.55012 17.4754L3.4031 9.98068"
								stroke="#e82e2c"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
				) : (
					<button
						type="button"
						className="rounded-md ml-auto px-5 signin-to-chat-btn focus:shadow-outline text-xs"
						onClick={() => {
							if (onRedirectToSignIn) {
								onRedirectToSignIn();
							}
						}}
					>
						SIGN IN TO CHAT
					</button>
				)}
			</>
		)}
	</div>
);

Toolbar.propTypes = {
	sendButtonEnabled: PropTypes.bool,
	onSendMessage: PropTypes.func,
	onFileAttached: PropTypes.func,
	hideSendButton: PropTypes.bool,
	hideAttachmentsButton: PropTypes.bool,
	removeListsFromToolbar: PropTypes.bool,
	authenticated: PropTypes.bool,
	onRedirectToSignIn: PropTypes.func,
	yOverflow: PropTypes.bool,
	setYOverflow: PropTypes.func,
	useInlineInputEditor: PropTypes.bool,
	toolbarId: PropTypes.string
};

const ConversationEditor = ({
	value,
	setValue,
	onSendMessage,
	setEditor,
	participants,
	editorRef,
	sendButtonEnabled,
	attachmentDisabled,
	onFileAttached,
	hideSendButton,
	hideAttachmentsButton,
	hidePlaceholder,
	setInputFocused,
	removeListsFromToolbar = false,
	authenticated,
	onRedirectToSignIn,
	reduceReplyMessageSize,
	useInlineInputEditor,
	toolbarId,
	enabledChatInput,
	enableMentions
}) => {
	const [yOverflow, setYOverflow] = useState(true);
	const [quillModules, setQuillModules] = useState();

	const emojiIcon =		'<svg style="width:24px!important; height:24px!important;" fill="none" stroke="currentColor" viewBox="0 0 24 24"xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/></svg>';

	Quill.register(
		{
			'formats/emoji': quillEmoji.EmojiBlot,
			'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
			'modules/emoji-textarea': quillEmoji.TextAreaEmoji,
			'modules/emoji-shortname': quillEmoji.ShortNameEmoji
		},
		true
	);
	const Size = Quill.import('formats/size');
	Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
	Quill.register(Size, true);

	useEffect(() => {
		if (enableMentions) return;
		sessionStorage.setItem(
			'mention-participants',
			JSON.stringify(participants)
		);
	}, [participants]);

	useEffect(() => {
		editorRef.current.blur();
		const modules = {
			toolbar: {
				container: `#custom-toolbar-${toolbarId}`
			},
			'emoji-toolbar': {
				buttonIcon: emojiIcon
			},
			'emoji-textarea': false,
			'emoji-shortname': true
		};

		if (useInlineInputEditor) {
			modules['emoji-toolbar'] = {
				buttonIcon: emojiIcon
			};
		} else {
			modules['emoji-toolbar'] = true;
		}

		if (enableMentions) {
			modules.mention = {
				allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
				mentionDenotationChars: ['@'],
				defaultMenuOrientation: 'top',
				onOpen: () => {
					setYOverflow(false);
				},
				source: async (searchTerm, renderItem, mentionChar) => {
					const participantsFromSessionStorage = JSON.parse(
						sessionStorage.getItem('mention-participants')
					);
					if (
						!participantsFromSessionStorage
						|| participantsFromSessionStorage.length === 0
					) return;
					let values;
					if (mentionChar === '@' || mentionChar === '#') {
						values = participantsFromSessionStorage;
					}
					if (searchTerm.length === 0) {
						renderItem(values, searchTerm);
					} else {
						const filteredParticipants = participantsFromSessionStorage.filter(
							(p) => p.value
								.toLowerCase()
								.replace(/\s/g, '')
								.includes(searchTerm.toLowerCase().replace(/\s/g, ''))
						);
						renderItem(filteredParticipants, searchTerm);
					}
					sessionStorage.setItem('on-mentioning', true);
				},
				onClose: () => {
					setYOverflow(true);
				}
			};
		}
		setQuillModules(modules);

		return () => {
			setQuillModules(null);
		};
	}, [useInlineInputEditor]);

	const formats = [
		'size',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'link',
		'image',
		'mention',
		'emoji'
	];

	const handleProcedureContentChange = (content, delta, source, editor) => {
		if (delta.ops[delta.ops.length - 1].insert == '\n') {
			setValue(value);
		} else {
			setValue(content);
		}
		if (editor) {
			setEditor(editor);
		}

		setYOverflow(true);
	};

	const onKeyDown = (e) => {
		if (isMobile) return;
		// eslint-disable-next-line no-restricted-globals
		if (!e) e = event;
		const hasImageNode = value && value.length && value.includes('<img');
		if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !hasImageNode) {
			return;
		}
		if (e.key == 'Enter') {
			if (e.shiftKey) return;
			if (sessionStorage.getItem('on-mentioning')) {
				sessionStorage.removeItem('on-mentioning');
				return;
			}
			onSendMessage();
		}
	};

	return (
		<>
			{useInlineInputEditor ? (
				authenticated ? (
					<button
						hidden={!authenticated}
						type="submit"
						disabled={!sendButtonEnabled}
						onClick={onSendMessage}
						className="uppercase font-semibold text-sm tracking-wider text-gray-500 hover:text-gray-900 dark:hover:text-white transition-colors mr-1 flex items-center pl-3"
					>
						<svg
							width="19"
							height="20"
							viewBox="0 0 19 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M11.4397 9.95393L3.4031 9.98068L1.6002 2.40599C1.36403 1.41337 2.42167 0.604794 3.31469 1.09604L17.606 8.94496C18.4177 9.39231 18.4095 10.5646 17.5886 11.0085L3.24532 18.7826C2.34757 19.2689 1.30511 18.4662 1.55012 17.4754L3.4031 9.98068"
								stroke="#828282"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
				) : (
					<button
						type="button"
						className="rounded-md ml-auto px-5 signin-to-chat-btn focus:shadow-outline text-xs"
						onClick={() => {
							if (onRedirectToSignIn) {
								onRedirectToSignIn();
							}
						}}
					>
						SIGN IN TO CHAT
					</button>
				)
			) : null}
			<ReactQuill
				ref={editorRef}
				defaultValue="<div>e</div>"
				readOnly={!authenticated || !enabledChatInput}
				theme="snow"
				modules={quillModules}
				formats={formats}
				placeholder={`${hidePlaceholder ? '' : 'Type your message here...'}`}
				value={value}
				onChange={handleProcedureContentChange}
				onKeyDown={onKeyDown}
				className={`border-grey max-w-full h-auto ${
					reduceReplyMessageSize ? 'md:h-12 max-h-12' : 'md:h-20 max-h-20'
				} conversation-editor 
				overflow-y-${yOverflow && !useInlineInputEditor ? 'scroll' : 'none'} ${
			useInlineInputEditor ? 'flex-1 inline-editor-input w-48' : 'border-2 '
		} ${authenticated ? '' : 'conversation-editor-disabled'}`}
				onBlur={() => {
					if (setInputFocused) {
						return setInputFocused(false);
					}
				}}
				onFocus={() => {
					if (setInputFocused) {
						setInputFocused(true);
					}
				}}
			/>

			<Toolbar
				sendButtonEnabled={sendButtonEnabled}
				onSendMessage={onSendMessage}
				attachmentDisabled={attachmentDisabled}
				onFileAttached={onFileAttached}
				hideSendButton={hideSendButton}
				removeListsFromToolbar={removeListsFromToolbar}
				hideAttachmentsButton={hideAttachmentsButton}
				authenticated={authenticated}
				onRedirectToSignIn={onRedirectToSignIn}
				yOverflow={yOverflow}
				toolbarId={toolbarId}
				setYOverflow={setYOverflow}
				useInlineInputEditor={useInlineInputEditor}
			/>
		</>
	);
};

ConversationEditor.propTypes = {
	value: PropTypes.any,
	setValue: PropTypes.func,
	onSendMessage: PropTypes.func,
	setEditor: PropTypes.func,
	participants: PropTypes.array,
	editorRef: PropTypes.oneOfType([
		// Either a function
		PropTypes.func,
		// Or the instance of a DOM native element (see the note about SSR)
		PropTypes.shape({ current: PropTypes.elementType })
	]),
	sendButtonEnabled: PropTypes.bool,
	attachmentDisabled: PropTypes.bool,
	onFileAttached: PropTypes.func,
	hideSendButton: PropTypes.bool,
	hideAttachmentsButton: PropTypes.bool,
	hidePlaceholder: PropTypes.bool,
	setInputFocused: PropTypes.func,
	removeListsFromToolbar: PropTypes.bool,
	authenticated: PropTypes.bool,
	onRedirectToSignIn: PropTypes.func,
	reduceReplyMessageSize: PropTypes.bool,
	useInlineInputEditor: PropTypes.bool,
	toolbarId: PropTypes.string,
	enabledChatInput: PropTypes.bool,
	enableMentions: PropTypes.bool
};

export default ConversationEditor;
