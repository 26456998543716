import React from 'react';
import PropTypes from 'prop-types';

const LayoutSignin = ({ children }) => (
	<div className="flex flex-col flex-1 items-center justify-center h-full min-h-screen w-full">
		<div className="flex flex-1 w-full h-auto">
			<main className="flex flex-col flex-1 w-full bg-gray-100">{children}</main>
		</div>
	</div>
);

LayoutSignin.propTypes = {
	children: PropTypes.node.isRequired,
};

export default LayoutSignin;
