/* eslint-disable no-useless-escape */
/* eslint-disable no-shadow */
/* eslint-disable radix */
import React, { useState, useEffect, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getSportTeams } from '../../api/referenceData.request';
import { defaultToastWarning } from '../../utils/toast-warning';
import useErrorNotifier from '../../hooks/use-error-notifier';
import { CreateEventContext } from '../../contexts/create-event';
import BackButton from '../../components/button/back-button';
import NextButton from '../../components/button/next-button';
import EditEventSaveTemplateModal from '../../components/Event/edit-template-save-modal';

const CreatePickYourSlotTemplate = () => {
	const { showToastError } = useErrorNotifier();
	const {
		editedTemplate,
		setEditedTemplate,
		setActiveStep,
		setLoading,
		sports,
		maximumNumberOfSlotsAndTiersAllowed
	} = useContext(CreateEventContext);
	const [sportTypeId, setSportTypeId] = useState();
	const [slotSize, setSlotSize] = useState(0);
	const [teams, setTeams] = useState([]);
	const [slots, setSlots] = useState([]);
	const [newTeamId, setNewTeamId] = useState(0);
	const [searchedData, setSearchedData] = useState([]);
	const [searchKeyword, setSearchKeyword] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [showResetButton, setShowResetButton] = useState(false);
	const [, updateState] = useState({});
	const forceUpdate = React.useCallback(() => updateState({}), []);

	useEffect(() => {
		if (editedTemplate.tiersData.length) {
			let i = 0;
			editedTemplate.tiersData.forEach((team) => {
				if (team.teamId == null) {
					i--;
					team.teamId = i;
				}
			});
			setSlots(editedTemplate.tiersData);
			editedTemplate.teams.forEach((team) => {
				if (team.teamId == null) {
					i--;
					team.teamId = i;
				}
			});
			setTeams(editedTemplate.teams);
			setSportTypeId(editedTemplate.sportTypeID);
			setSlotSize(editedTemplate.maxSlotSize);
			forceUpdate();
			setShowResetButton(true);
			setNewTeamId(i--);
		}
	}, []);

	const onBackClick = () => {
		setActiveStep(1);
	};

	const getSlotData = () => {
		if (slots.length) {
			return slots.map((slot) => ({
				slotName: slot.displayName,
				cost: slot.cost
			}));
		}
		return [];
	};

	const getStepData = (val) => {
		let requestTeamsData = [];
		if (slots.length) {
			let displayOrder = 1;
			requestTeamsData = slots.map((team) => {
				const teamData = {
					tier: 1,
					team: team.displayName,
					teamId: team.teamId <= 0 ? null : team.teamId,
					displayOrder
				};

				displayOrder++;
				return teamData;
			});
		}

		return {
			templateName: val,
			tierSize: 1,
			slotSize: slots.length,
			sportTypeID: Number(sportTypeId),
			teams,
			pickYourSlotCost: getSlotData(),
			templateTiersData: requestTeamsData,
			tiersData: slots,
			maxSlotSize: slotSize
		};
	};

	const onNextClick = () => {
		const slotsWithNoCost = slots.filter(
			(t) => t.cost == '' || Number(t.cost) == 0
		);
		if (!slots.length) {
			showToastError('Please add teams in slots.');
			return;
		}

		if (slots.length != slotSize) {
			showToastError(
				'Number of slots should be equal with the number you specified.'
			);
			return;
		}

		if (slotsWithNoCost.length) {
			showToastError('Please enter cost for all slots.');
			return;
		}

		if (editedTemplate.templateName) {
			const templateData = getStepData(editedTemplate.templateName);
			setEditedTemplate({
				...editedTemplate,
				slotSize,
				sportTypeID: sportTypeId,
				teams: templateData.tiersData,
				templateTiersData: templateData.templateTiersData,
				pickYourSlotCosts: templateData.pickYourSlotCost,
				customTemplateName: templateData.templateName,
				tierSize: 1,
				templateName: templateData.templateName
			});

			setActiveStep(3);
			return;
		}
		setShowModal(true);
	};

	const stepComplete = async (val) => {
		const templateData = getStepData(val);
		setEditedTemplate({
			...editedTemplate,
			slotSize,
			sportTypeID: sportTypeId,
			teams: templateData.tiersData,
			templateTiersData: templateData.templateTiersData,
			pickYourSlotCosts: templateData.pickYourSlotCost,
			customTemplateName: val,
			templateName: val,
			tierSize: 1
		});
		setActiveStep(3);
		setShowModal(false);
	};

	const onSportTypeChange = async (value) => {
		setLoading(true);
		const res = await getSportTeams(value);

		res.data.forEach((team) => {
			team.cost = '';
		});
		setSearchKeyword('');
		setSportTypeId(value);
		setTeams(res.data);
		setSlots([]);
		setLoading(false);
	};

	const addNewTeam = () => {
		if (!sportTypeId) {
			showToastError('Please select sport type first!');
			return;
		}
		const tempTeams = [...teams];
		if (searchKeyword == null || searchKeyword == '') {
			showToastError('Please fill the field!');
			return;
		}

		if (teams.find((team) => team.displayName == searchKeyword)) {
			showToastError('Team is already in available list. Please find!');
			return;
		}
		setNewTeamId(newTeamId - 1);
		tempTeams.unshift({
			teamId: newTeamId,
			displayName: searchKeyword,
			cost: ''
		});
		setTeams(tempTeams);
		setSearchKeyword('');
	};

	const removeItemFromSlots = (index) => {
		const teamsData = teams;
		const slotsList = slots;
		const [removedSlot] = slotsList.splice(index, 1);

		removedSlot.cost = '';
		teamsData.unshift(removedSlot);

		setTeams(teamsData);
		setSlots(slotsList);
		forceUpdate();
	};

	const grid = 8;
	const getListStyle = (isDraggingOver, isSlot) => ({
		background: isDraggingOver ? 'lightgrey' : 'lightgrey',
		padding: grid,
		width: 250,
		height: isSlot ? '100%' : ''
	});

	const getItemStyle = (id, isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: 'none',
		padding: grid * 2,
		margin: `0 0 ${grid}px 0`,

		// change background colour if dragging
		background: isDragging ? 'grey' : 'white',
		backgroundColor: parseInt(id) > 0 ? 'white' : '#e82e2c',

		// styles we need to apply on draggables
		...draggableStyle
	});

	const setCostOfSlot = (index, value) => {
		value = value.replace(/[^0-9\.]/g, '');
		const slotsList = [...slots];
		slotsList[index].cost = value;
		setSlots(slotsList);
	};

	const validateCostList = (noOfSlots) => {
		if (noOfSlots < slots.length) {
			const teamsList = teams;
			const slotsList = slots;

			const itemsToBeRemoved = slots.length - noOfSlots;
			const itemsRemovingIndex = slots.length - itemsToBeRemoved;

			const removedSlots = slotsList.splice(
				itemsRemovingIndex,
				itemsToBeRemoved
			);

			removedSlots.forEach((slot) => {
				slot.cost = '';
				teamsList.splice(0, 0, slot);
			});

			setSlots(slotsList);
			setTeams(teamsList);
			forceUpdate();
		}
	};

	const setNumberOfSlot = (value) => {
		if (value == 0) {
			setSlotSize(0);
			validateCostList(Number(value));
			return;
		}
		value = value.replace(/[^\d].+/, '');
		setSlotSize(value);

		validateCostList(Number(value));
	};

	const onDragEnd = (result) => {
		if (slotSize <= 0 || !slotSize) {
			showToastError('Please enter no. of slots first.');
			return;
		}
		if (slots.length >= Number(slotSize)) {
			showToastError('All slots are reserved.');
			return;
		}

		const { source, destination } = result;

		if (!destination) return;

		const teamsList = teams;
		const slotsList = slots;

		if (source.droppableId == 'teams') {
			if (destination.droppableId == 'teams') return;

			const [removedTeam] = teamsList.splice(source.index, 1);
			slotsList.splice(destination.index, 0, removedTeam);
		} else if (destination.droppableId == 'slots') {
			if (source.index !== destination.index) {
				const [removedTeam] = slotsList.splice(source.index, 1);
				slotsList.splice(destination.index, 0, removedTeam);
			} else return;
		} else {
			const [removedSlot] = slotsList.splice(source.index, 1);

			removedSlot.cost = '';
			teamsList.splice(destination.index, 0, removedSlot);
		}

		setTeams(teamsList);
		setSlots(slotsList);
		forceUpdate();
	};

	const onNumberOfSlotsChange = (e) => {
		if (e.target.value) {
			if (e.target.value > maximumNumberOfSlotsAndTiersAllowed) {
				defaultToastWarning(
					`No. of slots can not be more than ${maximumNumberOfSlotsAndTiersAllowed}.`
				);
				setNumberOfSlot(0);
				return;
			}
		}
		setNumberOfSlot(e.target.value);
	};

	const resetTemplateData = () => {
		setShowResetButton(false);
		setTeams([]);
		setSlots([]);
		setSportTypeId('');
		setSearchKeyword('');
		setEditedTemplate({
			...editedTemplate,
			templateName: ''
		});
		setSlotSize(0);
	};

	return (
		<div>
			<EditEventSaveTemplateModal
				showModal={showModal}
				onNext={(val) => stepComplete(val)}
				onClose={() => setShowModal(false)}
			/>
			<div
				className="slot-create-your-own flex-grow p-4"
				style={{ margin: '0 8%' }}
			>
				<div className="flex flex-row mb-5 main-div">
					<DragDropContext onDragEnd={onDragEnd}>
						<div
							className="flex flex-col items-center py-5 h-50 mr-2 border overflow-hidden overflow-y-auto teams-div"
							style={{ width: '35%' }}
						>
							<div
								className="flex flex-row items-center sport-type-div"
								style={{ width: '250px' }}
							>
								<div
									className="flex flex-col sport-type-label"
									style={{ width: '35%' }}
								>
									<label
										htmlFor="event_type"
										className="w-full pr-2 text-sm font-bold text-gray-700"
									>
										Sport Type
									</label>
								</div>
								<div
									className="flex flex-col my-2 sport-type-list"
									style={{ width: '65%' }}
								>
									<select
										id="sport_type"
										name="sport_type"
										autoComplete="sport_type"
										className="w-full py-2 px-3 border border-gray-300 bg-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										onChange={(e) => onSportTypeChange(e.target.value)}
									>
										<option key={0} value={0}>
											Select Sport Type
										</option>
										{sports.map((sport) => (
											<option
												key={sport.referenceDataId}
												value={sport.referenceDataId}
												selected={sport.referenceDataId == sportTypeId}
											>
												{sport.referenceDataValue}
											</option>
										))}
									</select>
								</div>
							</div>
							<div
								className="flex flex-row border add-free-text"
								style={{ width: '250px' }}
							>
								<div className="flex flex-col" style={{ width: '90%' }}>
									<input
										type="text"
										name="slots_count"
										id="slots_count"
										placeholder="Search or Add new"
										className="inline-block m-1 sm:text-sm w-full p-1"
										style={{
											outlineColor: 'white',
											height: '35px'
										}}
										value={searchKeyword}
										onChange={({ target }) => {
											setSearchKeyword(target.value);
											setSearchedData(
												teams.filter(({ displayName }) => displayName
													.toLowerCase()
													.includes(target.value.toLowerCase()))
											);
										}}
									/>
								</div>
								<div className="flex flex-col m-2" style={{ width: '10%' }}>
									<button
										type="button"
										className="inline-block"
										style={{
											outlineColor: 'white',
											padding: '3%'
										}}
										onClick={addNewTeam}
										title="Add Team"
									>
										<svg
											width="24"
											height="24"
											xmlns="http://www.w3.org/2000/svg"
											fillRule="evenodd"
											clipRule="evenodd"
										>
											<path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z" />
										</svg>
									</button>
								</div>
							</div>
							<Droppable droppableId="teams">
								{(provided, snapshot) => (
									<div className="">
										<div
											ref={provided.innerRef}
											style={getListStyle(snapshot.isDraggingOver, false)}
											className="border w-80 mt-5 text-center border-md teams-list-div"
										>
											<span className="font-bold text-md text-center p-5 teams-label">
												Available Teams
											</span>
											{(searchKeyword ? searchedData : teams).map(
												(team, index) => (
													<Draggable
														key={team.teamId}
														draggableId={team.teamId.toString()}
														index={index}
													>
														{(provided, snapshot) => (
															<div
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={getItemStyle(
																	team.teamId,
																	snapshot.isDragging,
																	provided.draggableProps.style
																)}
																className="shadow shadow-md h-16 w-50 font-xs py-2 items-center justify-center py-5 my-2 team-name"
															>
																{team.displayName}
															</div>
														)}
													</Draggable>
												)
											)}
										</div>
									</div>
								)}
							</Droppable>
						</div>
						<div
							className="flex flex-col py-5 border tiers-div"
							style={{ width: '65%' }}
						>
							<div className="flex flex-row items-center justify-center pt-2 pb-5 slot-input-div">
								<div
									className="flex flex-col slot-input-div-label"
									style={{ width: '20%' }}
								>
									<label
										htmlFor="total_slotS"
										className="text-sm font-bold text-gray-700 p-2"
									>
										No. of Slots
									</label>
								</div>
								<div
									className="flex flex-col slot-input"
									style={{ width: '40%' }}
								>
									<input
										type="number"
										name="total_slots"
										id="total_slots"
										maxLength="30"
										className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10 sm:text-sm border border-gray-200"
										placeholder="Slots"
										value={slotSize}
										onBlur={onNumberOfSlotsChange}
										onChange={onNumberOfSlotsChange}
									/>
								</div>
								<div className="reset">
									{showResetButton && (
										<div className="flex flex-col pl-5 ">
											<button
												type="button"
												className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6"
												onClick={resetTemplateData}
											>
												Reset
											</button>
										</div>
									)}
								</div>
							</div>
							<div className="flex flex-row mb-2">
								<div className="flex flex-col" style={{ width: '48%' }}>
									<div className="w-full py-1 text-center font-bold">Slots</div>
								</div>
								<div className="flex flex-col" style={{ width: '48%' }}>
									<div className="w-full py-1 text-center font-bold">
										Cost Per Slot
									</div>
								</div>
							</div>

							<div className="flex flex-row" style={{ height: '100%' }}>
								<div
									className="flex flex-col items-center m-2 mb-0 slot-list-div"
									style={{ width: '50%' }}
								>
									<Droppable droppableId="slots">
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												style={getListStyle(snapshot.isDraggingOver, true)}
												className="border w-80 text-center border-md slot-list"
											>
												{slots.map((slot, index) => (
													<Draggable
														key={slot.teamId}
														draggableId={slot.teamId.toString()}
														index={index}
													>
														{(provided, snapshot) => (
															<div
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={getItemStyle(
																	slot.teamId,
																	snapshot.isDragging,
																	provided.draggableProps.style
																)}
																className="shadow shadow-md h-16 w-50 font-xs py-2 items-center justify-center py-5 my-2 team-name"
															>
																<div className="flex justify-between items-center slot-name">
																	<span
																		className="py-1"
																		title={slot.displayName}
																	>
																		{' '}
																		{slot.displayName}
																	</span>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 512 512"
																		height="15pt"
																		width="15pt"
																		style={{
																			cursor: 'pointer'
																		}}
																		onClick={() => removeItemFromSlots(index)}
																	>
																		<path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm112 277.332031h-224c-11.777344 0-21.332031-9.554687-21.332031-21.332031s9.554687-21.332031 21.332031-21.332031h224c11.777344 0 21.332031 9.554687 21.332031 21.332031s-9.554687 21.332031-21.332031 21.332031zm0 0" />
																	</svg>
																</div>
															</div>
														)}
													</Draggable>
												))}
											</div>
										)}
									</Droppable>
								</div>
								<div className="flex flex-col m-2" style={{ width: '50%' }}>
									{slots.map((slot, i) => (
										<div className="flex flex-row slot-cost-main-div" key={i}>
											<div
												className="flex flex-col justify-center pt-5 slot-cost-div"
												style={{
													padding: grid,
													paddingBottom: '2px',
													width: '92%'
												}}
											>
												<div className="flex rounded-md shadow-sm">
													<span className="inline-flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm slot-cost-icon">
														$
													</span>
													<input
														type="text"
														min="0"
														name="slot_cost"
														id="slot_cost"
														className="focus:ring-indigo-400 focus:border-indigo-400 py-5 px-2 block w-full sm:text-sm border border-gray-200 h-16 w-50 slot-cost"
														placeholder="Cost"
														value={slot.cost}
														onChange={(e) => setCostOfSlot(i, e.target.value)}
													/>
												</div>
											</div>
											<div className="flex flex-col ml-2 cancel-btn">
												<button
													type="button"
													className="bg-black rounded-sm p-1 inline-flex items-center justify-center text-white hover:text-white hover:bg-black"
													style={{
														position: 'relative',
														top: '50%',
														left: '50%',
														msTransform: 'translate(-50%, -50%)',
														transform: 'translate(-50%, -50%)'
													}}
													onClick={() => removeItemFromSlots(i)}
												>
													<span className="sr-only">Close menu</span>
													<svg
														className="h-3 w-3"
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														stroke="currentColor"
														aria-hidden="true"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
															d="M6 18L18 6M6 6l12 12"
														/>
													</svg>
												</button>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</DragDropContext>
				</div>
				<div className="flex justify-center mx-auto">
					<div className="mr-2">
						<BackButton onClick={onBackClick} />
					</div>
					<div className="ml-2">
						<NextButton onClick={onNextClick} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreatePickYourSlotTemplate;
