import React from 'react';

const StepperContext = React.createContext({
	activeStep: 0,
	hideConnectors: false,
	nonLinear: false,
	connectorStateColors: false
});

export default StepperContext;
