/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import BounceLoader from 'react-spinners/BounceLoader';
import { css } from '@emotion/core';
import ReactTooltip from 'react-tooltip';
import EditEventSaveTemplateModal from '../../components/Event/edit-template-save-modal';
import { InfoIcon } from '../../components/Event/event-icons';
import {
	getEventStepTeamDistribution,
	saveEventTemplate
} from '../../api/event.request';
import { CreateEventContext } from '../../contexts/create-event';
import BackButton from '../../components/button/back-button';
import NextButton from '../../components/button/next-button';
import useErrorNotifier from '../../hooks/use-error-notifier';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const PickYourSlotEditEventTemplate = () => {
	const {
		editedTemplate,
		setEditedTemplate,
		setActiveStep,
		breakTemplate
	} = useContext(CreateEventContext);
	const { showToastError } = useErrorNotifier();

	const [loading, setLoading] = useState(true);
	const [teamDistribution, setTeamDistribution] = useState([]);
	const [tiersData, setTiersData] = useState(editedTemplate.tiersData || []);
	const [showModal, setShowModal] = useState(false);
	const [isEdited, setIsEdited] = useState(false);
	const [saveEditValues, setSaveEditValues] = useState({
		saveTemplate: 0,
		templateName: ''
	});
	const [customTemplateName, setCustomTemplateName] = useState('');
	const [, updateState] = useState({});
	const forceUpdate = React.useCallback(() => updateState({}), []);
	const [showResetButton, setShowResetButton] = useState(false);
	const [totalCostSum, setTotalCostSum] = useState(0);

	useEffect(() => {
		if (editedTemplate.tiersData.length) {
			let i = 0;
			editedTemplate.tiersData.forEach((team, index) => {
				if (team.teamId == null) {
					i--;
					team.teamId = i;
				}
			});
			setTeamDistribution(editedTemplate.tiersData);
			if (editedTemplate.customTemplateName) setShowResetButton(true);
			setCustomTemplateName(editedTemplate.customTemplateName);
			setLoading(false);
		} else {
			Promise.all([
				getEventStepTeamDistribution(breakTemplate.templateId)
			]).then(([teamDist]) => {
				let i = 0;
				teamDist.data.forEach((team, index) => {
					if (team.teamId == null) {
						i--;
						team.teamId = i;
					}
					team.unitCost = '';
				});
				setTeamDistribution(teamDist.data);
				setLoading(false);
			});
		}
	}, []);

	const grid = 8;
	const getListStyle = () => ({
		background: 'lightgrey',
		padding: grid,
		width: 250
	});

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: 'none',
		padding: grid * 2,
		margin: `0 0 ${grid}px 0`,
		fontSize: window.screen.width <= 768 ? '12px' : '',
		// change background colour if dragging
		background: isDragging ? 'grey' : 'white',

		// styles we need to apply on draggables
		...draggableStyle
	});

	const onBackClick = () => {
		setActiveStep(1);
	};

	const getStepData = (val, customTempName) => {
		const requestTeamsData = [];
		if (teamDistribution.length) {
			let displayOrder = 1;
			teamDistribution.map((team, index) => {
				requestTeamsData.push({
					tier: 1,
					teamID: team.teamId <= 0 ? null : team.teamId,
					team: team.team,
					displayOrder
				});
				displayOrder++;
			});
		}
		return {
			templateName: val,
			tierSize: editedTemplate.tierSize,
			slotSize: editedTemplate.slotSize,
			isTemplateEdited: !!customTemplateName,
			sportTypeID: editedTemplate.sportTypeId,
			teams: requestTeamsData,
			tiersData: teamDistribution,
			pickYourSlotCosts: getSlotData(),
			customTemplateName: customTempName
		};
	};

	const stepComplete = async (val) => {
		const tierData = getStepData(val, val);
		setShowModal(false);
	};

	const onNextClick = () => {
		const teamsWithNoCost = teamDistribution.filter((t) => t.unitCost == '');

		if (teamsWithNoCost.length) {
			showToastError('Please enter cost for all slots.');
			return;
		}

		if (!isEdited) {
			const tierData = getStepData(
				editedTemplate.templateName,
				editedTemplate.customTemplateName
			);
			tierData.breakEventTemplateId = breakTemplate.templateId;
			setEditedTemplate({
				...editedTemplate,
				pickYourSlotCosts: tierData.pickYourSlotCosts
			});
			setActiveStep(3);
		} else if (customTemplateName) {
			const tierData = {};
			tierData.tiersData = teamDistribution;
			tierData.pickYourSlotCosts = getSlotData();

			setEditedTemplate({
				...editedTemplate,
				tiersData: teamDistribution,
				pickYourSlotCosts: getSlotData()
			});
			setActiveStep(3);
		} else {
			setShowModal(true);
		}
	};

	function getSlotData() {
		const slotsData = [];
		if (teamDistribution.length) {
			teamDistribution.map((team, index) => {
				slotsData.push({
					cost: team.unitCost,
					slotName: team.team
				});
			});
		}
		return slotsData;
	}

	const setCostPerSlot = (index, value) => {
		value = value.replace(/[^0-9\.]/g, '');
		const teams = [...teamDistribution];
		teams[index].unitCost = value;
		setTotalCostSum(teams.reduce((a, b) => +a + +b.unitCost, 0));
		setTeamDistribution(teams);
	};

	function onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}
		const teams = reorder(
			teamDistribution,
			result.source.index,
			result.destination.index
		);

		setIsEdited(true);
		setTeamDistribution(teams);
	}
	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const resetTemplateData = () => {
		setShowResetButton(false);
		setTeamDistribution([]);
		Promise.all([
			getEventStepTeamDistribution(breakTemplate.breakTemplateId)
		]).then(([teamDist]) => {
			teamDist.data.forEach((team, index) => {
				team.unitCost = '';
			});
			setTeamDistribution(teamDist.data);
			setCustomTemplateName('');
			editedTemplate.customTemplateName = '';
		});
	};

	return (
		<div>
			<ReactTooltip />
			<EditEventSaveTemplateModal
				showModal={showModal}
				onNext={(val) => stepComplete(val)}
				saveData={saveEditValues}
				onClose={() => setShowModal(false)}
			/>
			<div className="flex-grow p-4 mb-6">
				<div className="container">
					{showResetButton && (
						<div className="h-12">
							<button
								type="button"
								style={{ float: 'right' }}
								className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6"
								onClick={resetTemplateData}
							>
								Reset
							</button>
						</div>
					)}
					<div className="flex flex-row justify-center">
						<div className="flex flex-col w-1/3 xxs:w-1/3 lg:w-1/3">
							<div className="w-full border ">
								<div className="w-full py-1 text-center font-bold">
									<div className="flex text-center justify-center space-x-2">
										<div>
											<div className="w-full text-center font-bold">
												Edit Template
											</div>
										</div>
										<div data-tip="Rearrange your slots however you wish. Any changes you make will be saved as a new personal template.">
											<InfoIcon />
										</div>
									</div>
								</div>
								<div className="w-full py-1 text-center font-bold">Tier 1</div>
								<div className="flex flex-column justify-center py-5">
									<DragDropContext onDragEnd={onDragEnd}>
										<Droppable droppableId="teams">
											{(provided) => (
												<div
													ref={provided.innerRef}
													{...provided.droppableProps}
													style={getListStyle()}
												>
													{teamDistribution.map((team, i) => (
														<Draggable
															draggableId={i.toString()}
															index={i}
															key={i}
														>
															{(provided, snapshot) => (
																<div
																	key={i}
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={getItemStyle(
																		snapshot.isDragging,
																		provided.draggableProps.style
																	)}
																	className="shadow shadow-md h-14 w-50 font-sm bg-white text-center items-center justify-center py-5 my-2"
																>
																	{team.team}
																</div>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>
								</div>
							</div>
						</div>
						<div className="flex flex-col w-1/3 xxs:w-1/3 lg:w-1/3 ml-4">
							<div className="w-full">
								<div className="w-full py-3 font-bold mt-9">
									<div className="w-full py-1 text-center font-bold">
										<div className="flex text-center justify-start space-x-2">
											<div>
												<div className="w-full text-center font-bold">
													Cost Per Slot
												</div>
											</div>
											<div
												data-tip="Add a cost for each slot. The more desired slots should cost more than the less desired ones.
														Make sure you double-check your total break cost covers your inventory and any shipping costs."
											>
												<InfoIcon />
											</div>
										</div>
									</div>
								</div>
								<div
									className="justify-center py-5"
									style={{
										background: 'lightgrey',
										padding: grid,
										width: 250
									}}
								>
									{teamDistribution.map((team, i) => (
										<div key={i}>
											<div className="flex rounded-md shadow-sm mb-2">
												<span className="inline-flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
													$
												</span>
												<input
													type="text"
													min="0"
													name="slot_cost"
													id="slot_cost"
													className="focus:ring-indigo-400 focus:border-indigo-400 px-2 block w-full h-14 sm:text-sm border border-gray-200"
													placeholder="Cost per slot"
													value={team.unitCost}
													onChange={(e) => setCostPerSlot(i, e.target.value)}
												/>
											</div>
										</div>
									))}
								</div>
							</div>
							<div className="justify-center mt-3 ml-3">
								Total Cost = $
								{totalCostSum}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex justify-center mx-auto">
				<div className="mr-2">
					<BackButton onClick={onBackClick} />
				</div>
				<div className="ml-2">
					<NextButton onClick={onNextClick} />
				</div>
			</div>
		</div>
	);
};

export default PickYourSlotEditEventTemplate;
