import React from 'react';
import { CheckIcon } from '@heroicons/react/solid';
import { getSlotName } from '../../utils/slot';

const SelectRefundItemsMobile = ({
	tipMessage = '',
	orderDetails,
	onInfoIconClicked,
	onItemClicked,
	isChecked
}) => (
	<div>
		{tipMessage && <p>{tipMessage}</p>}
		<p className="pl-2 pt-2 pb-2">
			<b>Event Name</b>
:
			{' '}
			<span className="text-sm">{orderDetails[0].event.eventName}</span>
		</p>
		<div className="flex border-2 w-full mt-2">
			<table className="w-full">
				<tbody className="w-full">
					{orderDetails.map((orderDetail, index) => (
						<tr
							className="flex px-6 py-3 w-full justify-between hover:bg-gray-200"
							onClick={() => onItemClicked(orderDetail)}
							onKeyDown={() => onItemClicked(orderDetail)}
							key={index}
						>
							{isChecked(orderDetail.orderDetailsId) ? (
								<CheckIcon className="w-6 h-6" />
							) : (
								<div className="w-6 h-6 " />
							)}
							<div>
								<b>
									{orderDetail.productId == null ? 'Slot Name' : 'Product Name'}
								</b>
								:
								{' '}
								<span className="pl-1">
									{orderDetail.productId == null
										? getSlotName(orderDetail)
										: orderDetail.productName}
								</span>
							</div>
							<div
								className="pl-4"
								onClick={(e) => {
									e.stopPropagation();
									onInfoIconClicked(orderDetail);
								}}
								onKeyDown={(e) => {
									e.stopPropagation();
									onInfoIconClicked(orderDetail);
								}}
							>
								<svg
									className="w-6 h-6"
									fill="black"
									stroke="white"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
							</div>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	</div>
);

export default SelectRefundItemsMobile;
