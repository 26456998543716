import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const CancelPage = () => {
	useEffect(() => {
		navigate('/', {
			state: {
				redirectToStep3: true
			}
		});
	}, []);

	return (
		<>
			<h1>Canceling...</h1>
		</>
	);
};

export default CancelPage;
