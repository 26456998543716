/* eslint-disable react/destructuring-assignment */
import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import moment from 'moment';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import axios from 'axios';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Layout from '../../components/layouts/layout-default';
import SEO from '../../components/seo';

import logo from '../../images/djawn_logo.png';
import env from '../../../environment.config';
import { getAccessToken } from '../../utils/auth';
import { defaultToastError } from '../../utils/toast-error';
import { removeCartItem } from '../../state/action';
import { onlyUnique } from '../../utils/commonFunction';
import { getAuthenticatedUserId } from '../../api/auth.request';
import {
	CONVERSATION_PARTICIPANT_COULD_NOT_BE_CREATED,
	CONVERSATION_PARTICIPANT_COULD_NOT_BE_UPDATED
} from '../../utils/errorCodes';
import { getError } from '../../api/referenceData.request';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const ThankYouPage = (props) => {
	const [loading, setLoading] = useState(true);
	const [loadingMessage] = useState('loading...');
	const [returnUrl, setReturnUrl] = useState(null);

	const timer = useRef(null);

	const showError = (errorCode) => {
		getError(errorCode)
			.then((res) => {
				defaultToastError(res.data);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const addUserToConversations = (breaks) => {
		const eventIds = breaks.map((b) => b.eventId);
		if (eventIds && eventIds.length) {
			const uniqueEventIds = eventIds.filter(onlyUnique);
			if (uniqueEventIds && uniqueEventIds.length) {
				getAuthenticatedUserId().then((userRes) => {
					const participatedConversationEventIds = [];
					const database = firebase.database();
					database.ref('conversation_participant').once('value', (snapshot) => {
						snapshot.forEach((childSnapshot) => {
							const participant = childSnapshot.val();
							if (
								participant
								&& participant.event_id
								&& participant.user_id == userRes.data
							) {
								participatedConversationEventIds.push({
									eventId: participant.event_id,
									participantId: childSnapshot.key
								});
							}
						});
						uniqueEventIds.forEach((eventId) => {
							const alreadyParticipating = participatedConversationEventIds.find(
								(e) => e.eventId == eventId
							);
							database
								.ref('conversation')
								.orderByChild('event_id')
								.equalTo(eventId)
								.once('value', (convSnapshot) => {
									convSnapshot.forEach((convChildSnapshot) => {
										if (!alreadyParticipating) {
											database
												.ref('conversation_participant')
												.push({
													conversation_id: convChildSnapshot.key,
													user_id: userRes.data,
													event_id: eventId,
													date: moment().utc().toDate().toJSON(),
													show_conversation: true
												})
												.catch(() => {
													showError(
														CONVERSATION_PARTICIPANT_COULD_NOT_BE_CREATED
													);
												});
										} else if (convChildSnapshot.val().started_by_event) {
											database
												.ref(
													`conversation_participant/${alreadyParticipating.participantId}`
												)
												.update({
													show_conversation: true,
													rejoined_date: moment().utc().toDate().toJSON()
												})
												.catch(() => {
													showError(
														CONVERSATION_PARTICIPANT_COULD_NOT_BE_UPDATED
													);
												});
										}
									});
									setLoading(false);
								});
						});
					});
				});
			}
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!props.eventId) return;

		const jsonObject = JSON.parse(localStorage.getItem(props.eventId));

		if (jsonObject) {
			// TODO: json object should have a return url of empty string if the cart is coming from live event
			setReturnUrl(jsonObject.returnUrl);

			axios
				.post(`${env.api_url}api/payment/execute`, jsonObject, {
					headers: { Authorization: `Bearer ${getAccessToken()}` }
				})
				.then(() => {
					props.cartItems.forEach((item) => {
						props.dispatch(removeCartItem(item));
					});

					localStorage.removeItem(props.eventId);
					if (jsonObject.eventBreaks && jsonObject.eventBreaks.length) {
						addUserToConversations(jsonObject.eventBreaks);
					} else {
						setLoading(false);
					}

					timer.current = setTimeout(() => {
						if (jsonObject.returnUrl) {
							// setLoading(true);
							window.location.href = jsonObject.returnUrl;
							// window.open(window.location.origin, '_self');
						} else {
							// window.location.href = jsonObject.returnUrl;
							// navigate('/');
							window.close();
						}
					}, 10000);
				})
				.catch((err) => {
					if (
						err
						&& err.response
						&& err.response.data
						&& err.response.data.error
					) {
						defaultToastError(err.response.data.error);
						timer.current = setTimeout(() => {
							if (jsonObject.returnUrl) {
								window.location.href = jsonObject.returnUrl;
								// setLoading(true);
								// window.open(window.location.origin, '_self');
							} else {
								// window.location.href = jsonObject.returnUrl;
								window.close();
								// navigate('/');
							}
						}, 10000);
					}
				});
		}

		return () => clearTimeout(timer.current);
	}, []);

	const gotoEventDetails = () => {
		if (returnUrl) {
			window.location.href = returnUrl;
		} else {
			window.close();
		}
	};

	if (loading) {
		return (
			<div className="w-screen h-screen xxs:h-auto bg-white opacity-50 absolute left-0 top-0">
				<ToastContainer />
				<div className="flex flex-col w-full h-full justify-center items-center">
					<div className="w-full">
						<BounceLoader loading css={override} size={30} />
					</div>
					<div className="w-full mt-4">
						<p className="text-xl not-italic text-center font-sans font-semibold tracking-wide mb-1">
							{loadingMessage}
						</p>
					</div>
				</div>
			</div>
		);
	}
	return (
		<Layout>
			<ToastContainer />
			<div className="flex flex-col flex-1">
				<header className="flex w-full sticky top-0 z-50">
					<SEO title="Thank You Page" />
					<nav className="w-full bg-white border-b">
						<div className=" mx-auto px-4 sm:px-6 lg:px-8">
							<div className="flex items-center justify-center h-16">
								<div className="flex items-center">
									<div className="flex-shrink-0">
										<Link to={`/event/${props.params.eventId}`}>
											<img
												className="h-8 w-auto sm:h-10 cursor-pointer"
												src={logo}
												alt="Workflow"
											/>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</nav>
				</header>

				<div className="flex flex-col flex-1 justify-center content-center">
					<div className="flex flex-col justify-items-center bg-white w-4/6 max-w-xl mx-auto -mt-10 py-10 rounded-lg">
						<div className="flex w-full">
							<h2 className="text-base text-gray-500 text-center sm:text-lg sm:max-w-xl sm:mx-auto  md:text-xl mb-4">
								Payment was successfully processed.
								<br />
								{' '}
Thank you for your business.
							</h2>
						</div>

						<div className="flex w-full">
							<button
								type="button"
								className=" w-36 mx-auto focus:outline-none text-white text-sm py-2.5 px-5 rounded-md bg-blue-500 hover:bg-blue-600 hover:shadow-lg"
								onClick={() => gotoEventDetails()}
							>
								OK
							</button>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default connect(
	(state) => ({
		cartItems: state.cart.cartItems
	}),
	null
)(ThankYouPage);
