import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

import { ToastContainer } from 'react-toastify';

import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import Header from '../components/header';
import Layout from '../components/layouts/layout-default';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
`;

const OurPolicies = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// authState();
		setLoading(false);
	}, []);

	return (
		<Layout>
			<div className="flex flex-col flex-1">
				<Header siteTitle="Signin" auth />
				{loading && (
					<div className="w-screen h-screen bg-white absolute left-0 top-0 z-50">
						<div className="flex h-full items-center">
							<BounceLoader
								loading
								css={override}
								size={30}
							/>
						</div>
					</div>
				)}

				{!loading && (
					<div className="flex flex-col flex-1 justify-center content-center">
						<div className="flex flex-col justify-items-center bg-white w-3/6 xxs:w-10/12 sm:w-8/12 md:w-1/2 xl:w-1/2 max-w-lg mx-auto -mt-10 py-10 rounded-lg">
							<h2 className="text-xl text-gray-700 font-semibold text-center sm:text-lg sm:max-w-xl sm:mx-auto  md:text-xl mb-4">
                                Our Policies
							</h2>
							<div className="w-6 h-0.5 mx-auto bg-gray-600 mb-6" />
							<ToastContainer />
							<div className="flex flex-col w-64 mx-auto px-4">
								<Link
									to="/"
									activeClassName="bg-gray-200"
									className="w-full h-10 bg-white text-base text-gray-700 hover:text-blue-500 rounded-sm font-medium py-2 px-2 flex items-center"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-5 w-5"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
										/>
									</svg>
									<span className="ml-4">Home</span>
								</Link>
								<Link
									to="/privacy"
									activeClassName="bg-gray-200"
									className="w-full h-10 bg-white text-base text-gray-700 hover:text-blue-500 rounded-sm font-medium py-2 px-2 flex items-center"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-5 w-5"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
										/>
									</svg>
									<span className="ml-4">Privacy Policy</span>
								</Link>
								<Link
									to="/terms-and-conditions"
									activeClassName="bg-gray-200"
									className="w-full h-10 bg-white text-base text-gray-700 hover:text-blue-500 rounded-sm font-medium py-2 px-2 flex items-center"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-5 w-5"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
										/>
									</svg>
									<span className="ml-4">
                                        Terms of Service
									</span>
								</Link>

								<Link
									to="/shipping-policy"
									activeClassName="bg-gray-200"
									className="w-full h-10 bg-white text-base text-gray-700 hover:text-blue-500 rounded-sm font-medium py-2 px-2 flex items-center"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-5 w-5"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											fill="#fff"
											d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
										/>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
										/>
									</svg>
									<span className="ml-4">
                                        Shipping Policy
									</span>
								</Link>
								<Link
									to="/refund-policy"
									activeClassName="bg-gray-200"
									className="w-full h-10 bg-white text-base text-gray-700 hover:text-blue-500 rounded-sm font-medium py-2 px-2 flex items-center"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-5 w-5"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M16 15v-1a4 4 0 00-4-4H8m0 0l3 3m-3-3l3-3m9 14V5a2 2 0 00-2-2H6a2 2 0 00-2 2v16l4-2 4 2 4-2 4 2z"
										/>
									</svg>
									<span className="ml-4">Refund Policy</span>
								</Link>
								<Link
									to="/randomization-policy"
									activeClassName="bg-gray-200"
									className="w-full h-10 bg-white text-base text-gray-700 hover:text-blue-500 rounded-sm font-medium py-2 px-2 flex items-center"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-5 w-5"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
										/>
									</svg>
									<span className="ml-4">
                                        Randomization Policy
									</span>
								</Link>
								<Link
									to="/seller-policy"
									activeClassName="bg-gray-200"
									className="w-full h-10 bg-white text-base text-gray-700 hover:text-blue-500 rounded-sm font-medium py-2 px-2 flex items-center"
								>
									{/* <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                        />
                                    </svg> */}
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-5 w-5"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path d="M23.995 24h-1.995c0-3.104.119-3.55-1.761-3.986-2.877-.664-5.594-1.291-6.584-3.458-.361-.791-.601-2.095.31-3.814 2.042-3.857 2.554-7.165 1.403-9.076-1.341-2.229-5.413-2.241-6.766.034-1.154 1.937-.635 5.227 1.424 9.025.93 1.712.697 3.02.338 3.815-.982 2.178-3.675 2.799-6.525 3.456-1.964.454-1.839.87-1.839 4.004h-1.995l-.005-1.241c0-2.52.199-3.975 3.178-4.663 3.365-.777 6.688-1.473 5.09-4.418-4.733-8.729-1.35-13.678 3.732-13.678 4.983 0 8.451 4.766 3.732 13.678-1.551 2.928 1.65 3.624 5.09 4.418 2.979.688 3.178 2.143 3.178 4.663l-.005 1.241zm-13.478-6l.91 2h1.164l.92-2h-2.994zm2.995 6l-.704-3h-1.615l-.704 3h3.023z" />
									</svg>

									<span className="ml-4">Seller Policy</span>
								</Link>
							</div>
						</div>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default OurPolicies;
