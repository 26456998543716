import { useEffect } from 'react';

const useOnClickOutside = (ref, handler, active = true) => {
	useEffect(() => {
		if (!active) return;
		const listener = (event) => {
			// Do nothing if clicking ref's element or descendent elements
			const refs = Array.isArray(ref) ? ref : [ref];

			let contains = false;
			refs.forEach((r) => {
				if (!r.current || r.current.contains(event.target)) {
					contains = true;
				}
			});
			event.stopPropagation();
			if (!contains) handler(event);
		};

		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);

		return () => {
			if (!active) return;
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [ref, handler, active]);
};

export default useOnClickOutside;
