/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from 'react';
import Chat from 'twilio-chat';
import { updateChatId } from '../../api/event.request';
import ChatApp from './chat';

class Channel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			isLoading: true,
			messages: [],
			channelName: '',
			token: ''
		};

		this.user = {
			id: props.nickname,
			username: props.nickname
		};

		this.setupChatClient = this.setupChatClient.bind(this);
		this.messagesLoaded = this.messagesLoaded.bind(this);
		this.messageAdded = this.messageAdded.bind(this);
		this.sendMessage = this.sendMessage.bind(this);
		this.handleError = this.handleError.bind(this);
		this.setChannelName = this.setChannelName.bind(this);

		console.log(props);
	}

	async componentDidMount() {
		if (this.props.channelToken) {
			Chat.create(this.props.channelToken).then(this.setupChatClient);
		}
	}

	async componentWillUnmount() {
		if (this.client) {
			this.client.shutdown();
		}
	}

	handleError(err) {
		console.log(err);
		this.setState({
			error: 'Could not load chat.'
		});
	}

	setChannelName(name) {
		this.setState({
			channelName: name
		});
	}

	setupChatClient(client) {
		this.client = client;
		this.client
			// .getChannelByUniqueName(this.props.uniqueName)
			.getChannelBySid(this.props.channelSid)
			.then((channel) => channel)
			.catch((error) => {
				if (error.body.code === 50300) {
					return this.client.createChannel({
						uniqueName: this.props.uniqueName
					});
				}
				this.handleError(error);
			})
			.then((channel) => {
				if (this.props.isHost) {
					updateChatId(this.props.uniqueName, channel.sid).then(() => {
						console.log('done');
					});
				}
				this.channel = channel;
				return this.channel.join().catch(() => {});
			})
			.then(() => {
				this.setState({ isLoading: false });
				this.channel.getMessages().then(this.messagesLoaded);

				this.channel.on('messageAdded', this.messageAdded);
			})
			.catch(this.handleError);
	}

	messagesLoaded(messages) {
		if (!messages || messages.items.length == 0) return;
		const formatedMessages = messages.items.map(this.formatMessage);
		this.setState({
			messages: formatedMessages
		});
	}

	// formatMessage = (message) => {
	//     return {
	//         text: message.state.body,
	//         author: { id: message.state.author, name: message.state.author },
	//         timestamp: message.state.timestamp
	//     };
	// };

	formatMessage(message) {
		return {
			text: message.state.body,
			author: { id: message.state.author, name: message.state.author },
			timestamp: message.state.timestamp
		};
	}

	messageAdded(message) {
		this.setState((prevState) => ({
			messages: [...prevState.messages, this.formatMessage(message)]
		}));
	}

	sendMessage(text) {
		this.channel.sendMessage(text);
	}

	render() {
		if (this.state.error) {
			return <p>{this.state.error}</p>;
		}
		if (this.state.isLoading) {
			return <p>Loading...</p>;
		}
		return (
			<ChatApp
				user={this.user}
				nickname={this.props.nickname}
				messages={this.state.messages}
				onMessageSend={this.sendMessage}
				authenticated={this.props.authenticated}
				onCollapseClick={this.props.onCollapseClick}
				collapsed={this.props.collapsed}
				eventName={this.props.eventName}
				exitEventRoom={this.props.exitEventRoom}
				mobileSize={this.props.mobileSize}
				eventStartDate={this.props.eventStartDate}
				hasRaisedHand={this.props.hasRaisedHand}
				videoHeight={this.props.videoHeight}
				hostNickname={this.props.hostNickname}
				onRedirectToSignIn={this.props.onRedirectToSignIn}
			/>
		);
	}
}

export default Channel;
