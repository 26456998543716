import React from 'react';
import { navigate } from 'gatsby-link';
import Product from '../components/product';

const ProductRegisterPage = () => (
	<Product
		onSuccess={() => navigate('/products')}
		onUnauthorized={() => navigate('/not-authorized')}
	/>
);
export default ProductRegisterPage;
