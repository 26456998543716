import { useState, useEffect } from 'react';
import { getDynamicPopups, acknowledgePopup } from '../api/popup.request';

const usePopups = (loadPopups = false) => {
	const [popups, setPopups] = useState([]);
	const getPopups = () => {
		getDynamicPopups()
			.then((res) => {
				if (res.data && res.data.length) {
					setPopups(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(() => {
		if (loadPopups) {
			getPopups();
		}
	}, [loadPopups]);

	const acknowledge = (mainPopId) => {
		acknowledgePopup(mainPopId)
			.then(() => {
				setPopups([...popups].filter((p) => p.mainPopId !== mainPopId));
			})
			.catch((err) => {
				console.error(err);
			});
	};

	return { popups, acknowledge };
};

export default usePopups;
