/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useState, useEffect, useRef } from 'react';
import { Picker } from 'emoji-mart';
import { elementScrollIntoView, polyfill } from 'seamless-scroll-polyfill';
import Message from './message';
import { getElapsedTimestamp } from '../../utils/formatter';
import 'emoji-mart/css/emoji-mart.css';

const Chat = ({
	messages,
	nickname,
	onMessageSend,
	authenticated,
	mobileSize,
	eventStartDate,
	hasRaisedHand,
	videoHeight,
	hostNickname,
	onRedirectToSignIn
}) => {
	const [newMessage, setNewMessage] = useState('');
	const inputRef = useRef();
	const bottomListRef = useRef();
	const [emojiPickerState, setEmojiPickerState] = useState(false);
	// useEffect(() => {
	//     const height = document.getElementById('video-ref-container').offsetHeight;
	//     if(height > 800) setChatHeight(90);
	//     else setChatHeight(80);
	// },[]);

	// useEffect(() => {
	//     if(!messages || !messages.length > 0) return;
	//     const tempMessages = [...messages];

	//     console.log(messages);

	//     tempMessages.map((message, i) => {
	//         if(tempMessages[i + 1] && tempMessages[i + 1].author && message.author) {
	//             if(message.author.id === tempMessages[i + 1].author.id) {
	//                 message.text += tempMessages[i + 1].text;
	//                 tempMessages[i + 1] = null;
	//                 return message;
	//             } else {
	//                 return message;
	//             }
	//         }
	//         return null;
	//     }).filter(f => f != null);

	//     console.log(tempMessages)

	//     setGroupedMessages(tempMessages);
	// }, [messages]);

	useEffect(() => {
		if (hasRaisedHand) {
			onMessageSend(`${nickname} has requested the microphone.`);
		}
	}, [hasRaisedHand]);

	useEffect(() => {
		if (inputRef.current) inputRef.current.focus();
	}, [inputRef]);

	const scroll = () => {
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		if (isSafari) {
			polyfill();
			elementScrollIntoView(bottomListRef.current, {
				behavior: 'smooth',
				block: 'nearest',
				inline: 'start'
			});
			// bottomListRef.current.scrollIntoView();
		} else {
			bottomListRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'start'
			});
		}
	};

	useEffect(() => {
		scroll();
	}, [messages]);

	useEffect(() => {
		scroll();
	}, [emojiPickerState]);

	const openEmojiPicker = () => {
		setEmojiPickerState(!emojiPickerState);
	};

	const handleOnChange = (e) => {
		setNewMessage(e.target.value);
	};

	const handleOnSubmit = (e) => {
		e.preventDefault();

		newMessage.trim();
		setNewMessage('');
	};
	return (
		<div
			// className={`${
			//     mobileSize == false ? 'chat-container-desktop-height' : 'h-full'
			// }
			className="
			flex flex-col rounded-xl bg-white dark:bg-black"
			// style={{height:'100%'}}
			style={{
				height:
					mobileSize || videoHeight == 0 ? '100%' : `${videoHeight + 10}px`,
				marginTop: '5px'
			}}
		>
			{/* {mobileSize == true ? null : (
				<div className="">
					<span
						onClick={exitEventRoom}
						className="text-sm text-teal-800 font-mono bg-teal-100 inline rounded-full px-2 align-top float-right"
						style={{ cursor: 'pointer' }}
					>
						<button
							className="bg-red-700 rounded-full h-10 w-10 flex items-center justify-center px-3 ml-2"
							style={{ color: 'white', outline: 'none' }}
						>
							X
						</button>
					</span>
				</div>
			)} */}
			<div
				className={`${
					mobileSize ? 'chat-container-mobile' : 'chat-desktop-height'
				} overflow-chat dark:text-white dark:bg-black`}
				style={{
					borderTopRightRadius: '10px',
					borderTopLeftRadius: '10px'
				}}
				id="chat-container-fx"

				// style={{height:'60vh'}}
			>
				{emojiPickerState && (
					<Picker
						set="apple"
						style={{ width: '100%' }}
						onSelect={(emoji) => {
							setNewMessage(newMessage + emoji.native);
							setEmojiPickerState(!emojiPickerState);
						}}
					/>
				)}

				<ul>
					{messages && messages.length > 0 && !emojiPickerState
						? messages.map((message, i) => (
								<li key={i} className="w-full">
									<Message
										createdAt={getElapsedTimestamp(
											eventStartDate,
											message.timestamp
										)}
										text={message.text}
										nickname={message.author.name}
										previousAuthorId={
											messages[i - 1] ? messages[i - 1].author.id : ''
										}
										currentAuthorId={messages[i].author.id}
										nextAuthorId={
											messages[i + 1] ? messages[i + 1].author.id : ''
										}
										hostNickname={hostNickname}
									/>
								</li>
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  ))
						: null}
					<div ref={bottomListRef} />
				</ul>
			</div>

			<div>
				{/* <div className="relative flex h-full"> */}
				<div>
					<div
						className="w-full h-16 bg-gray-100 dark:bg-black"
						style={{ borderTop: '1px dotted gray' }}
					>
						<form
							onSubmit={handleOnSubmit}
							style={{
								borderBottomRightRadius: '10px',
								borderBottomLeftRadius: '10px'
							}}
							className="flex flex-row bg-white dark:bg-darkGray-50 rounded-md px-4 py-2 z-10 max-w-screen-lg mx-auto dark:text-white h-full"
							// style={{width: '130%'}}
						>
							<div
								className={`flex items-center mr-3 cursor-pointer ${
									!authenticated && 'disabled-link'
								}`}
								disabled={!authenticated}
								onClick={openEmojiPicker}
								onKeyDown={openEmojiPicker}
							>
								<svg
									className="w-6 h-6"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
							</div>
							<input
								disabled={!authenticated}
								ref={inputRef}
								type="text"
								value={newMessage}
								onKeyDown={(e) => {
									if (!e.keyCode) return;
									if (e.keyCode === 13) {
										e.preventDefault();
										onMessageSend(newMessage);
										setNewMessage('');
									}
								}}
								onChange={handleOnChange}
								placeholder={
									authenticated
										? 'Type your message here...'
										: 'Sign in to chat'
								}
								className="flex-1 bg-transparent outline-none text-lg "
							/>
							<button
								hidden={!authenticated}
								type="submit"
								disabled={!newMessage}
								className="uppercase font-semibold text-sm tracking-wider text-gray-500 hover:text-gray-900 dark:hover:text-white transition-colors"
								onClick={() => {
									onMessageSend(newMessage);
								}}
							>
								<svg
									width="19"
									height="20"
									viewBox="0 0 19 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.4397 9.95393L3.4031 9.98068L1.6002 2.40599C1.36403 1.41337 2.42167 0.604794 3.31469 1.09604L17.606 8.94496C18.4177 9.39231 18.4095 10.5646 17.5886 11.0085L3.24532 18.7826C2.34757 19.2689 1.30511 18.4662 1.55012 17.4754L3.4031 9.98068"
										stroke="#828282"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</button>

							<button
								type="button"
								hidden={authenticated}
								className="h-12 px-5 m-2 text-blue-100 transition-colors duration-150 bg-blue-600 rounded-lg focus:shadow-outline hover:bg-blue-700 text-sm md:text-base"
								onClick={onRedirectToSignIn}
							>
								SIGN IN TO CHAT
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Chat;
