/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import { generalQuestions } from '../../api/seller.request';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

function GeneralQuestions({ stepData, onPrev, onNext }) {
	const [generalQuestionsList, setGeneralQuestionsList] = useState('');
	const [plateformQuestionData, setPlateformQuestionData] = useState('');
	const [scammerQuestionData, setScammerQuestionData] = useState('');
	const [referencesQuestionData, setReferencesQuestionData] = useState();
	const [plateform, setPlateform] = useState(
		stepData.plateform ? stepData.plateform : EditorState.createEmpty()
	);
	const [scammer, setScammer] = useState(
		stepData.scammer ? stepData.scammer : EditorState.createEmpty()
	);
	const [references, setReferences] = useState(
		stepData.references ? stepData.references : EditorState.createEmpty()
	);
	const [plateformErr, setPlateformErr] = useState(false);
	const [scammerErr, setScammerErr] = useState(false);
	const [referencesErr, setReferencesErr] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		generalQuestions()
			.then((res) => {
				setGeneralQuestionsList(res.data);
				res
					&& res.data
					&& res.data.map((qData) => {
						if (qData.sellerGeneralQuestionId === 1) {
							setPlateformQuestionData(qData);
							setLoading(false);
						} else if (qData.sellerGeneralQuestionId === 2) {
							setScammerQuestionData(qData);
							setLoading(false);
						} else if (qData.sellerGeneralQuestionId === 3) {
							setReferencesQuestionData(qData);
							setLoading(false);
						}
					});
			})
			.catch((err) => {
				setLoading(false);
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const saveStepData = (nextStep = true, cb) => {
		const isPlateform = plateform.getCurrentContent().getPlainText();
		const isScammer = scammer.getCurrentContent().getPlainText();
		const isReferences = references.getCurrentContent().getPlainText();

		const platefornRawContentState = convertToRaw(
			plateform.getCurrentContent()
		);
		const plateformMarkup = draftToHtml(platefornRawContentState);

		const scammerRawContentState = convertToRaw(scammer.getCurrentContent());
		const scammerMarkup = draftToHtml(scammerRawContentState);

		const referencesRawContentState = convertToRaw(
			references.getCurrentContent()
		);
		const referencesMarkup = draftToHtml(referencesRawContentState);

		if (isPlateform) {
			setPlateformErr(false);
		} else {
			setPlateformErr(true);
		}
		if (isScammer) {
			setScammerErr(false);
		} else {
			setScammerErr(true);
		}
		if (isReferences) {
			setReferencesErr(false);
		} else {
			setReferencesErr(true);
		}

		let formStatus = false;
		const step2values = {
			plateform,
			scammer,
			references,
			plateformText: isPlateform,
			scammerText: isScammer,
			referencesText: isReferences,
			plateformQuestionData,
			scammerQuestionData,
			referencesQuestionData,
			referencesHtml: referencesMarkup,
			plateformHtml: plateformMarkup,
			scammerHtml: scammerMarkup
		};
		if (isPlateform && isScammer && isReferences) {
			formStatus = true;
		} else {
			formStatus = false;
		}

		if (formStatus && nextStep) {
			onNext(step2values);
			return;
		}

		cb(step2values);
	};

	const prevStep = () => {
		saveStepData(false, (values) => onPrev(values));
	};

	const handleScammer = (e) => {
		const isScammer = e.getCurrentContent().getPlainText();
		if (isScammer) {
			setScammerErr(false);
		} else {
			// setScammerErr(true)
		}
		setScammer(e);
	};

	const handlePlateform = (e) => {
		const isPlateform = e.getCurrentContent().getPlainText();
		if (isPlateform) {
			setPlateformErr(false);
		}
		setPlateform(e);
	};

	const handleReferences = (e) => {
		const isReferences = e.getCurrentContent().getPlainText();
		if (isReferences) {
			setReferencesErr(false);
		} else {
			// setReferencesErr(true)
		}
		setReferences(e);
	};

	return (
		<div className="">
			{loading && (
				<div className="w-screen h-screen bg-white absolute left-0 top-0">
					<div className="flex h-full items-center">
						<BounceLoader loading css={override} size={30} />
					</div>
				</div>
			)}
			{generalQuestionsList && (
				<>
					<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
						<label
							htmlFor="plateform"
							className="block text-sm font-medium text-gray-700 required"
						>
							{plateformQuestionData.questionText}
						</label>
						<div className="mt-1 relative rounded-md shadow-sm border-2">
							<Editor
								editorState={plateform}
								toolbarClassName="toolbarClassName"
								wrapperClassName="wrapperClassName"
								editorClassName="editorClassName"
								onEditorStateChange={(e) => handlePlateform(e)}
							/>
						</div>
						{plateformErr && (
							<p className="text-red-700">This field is required</p>
						)}
					</div>

					<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
						<label
							htmlFor="scammer"
							className="block text-sm font-medium text-gray-700 required"
						>
							{scammerQuestionData.questionText}
						</label>
						<div className="mt-1 relative rounded-md shadow-sm border-2">
							<Editor
								editorState={scammer}
								toolbarClassName="toolbarClassName"
								wrapperClassName="wrapperClassName"
								editorClassName="editorClassName"
								onEditorStateChange={(e) => handleScammer(e)}
							/>
						</div>
						{scammerErr && (
							<p className="text-red-700">This field is required</p>
						)}
					</div>

					<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full">
						<label
							htmlFor="references"
							className="block text-sm font-medium text-gray-700 required"
						>
							{generalQuestionsList[2].questionText
								? generalQuestionsList[2].questionText
								: referencesQuestionData.questionText}
						</label>
						<div className="mt-1 relative rounded-md shadow-sm border-2">
							<Editor
								editorState={references}
								toolbarClassName="toolbarClassName"
								wrapperClassName="wrapperClassName"
								editorClassName="editorClassName"
								onEditorStateChange={(e) => handleReferences(e)}
							/>
						</div>
						{referencesErr && (
							<p className="text-red-700">This field is required</p>
						)}
					</div>
				</>
			)}
			<div className="flex justify-between mx-auto">
				<div className="w-2/5">
					<button
						type="button"
						className="min-w-full bg-gray-200 hover:bg-gray-300 text-white font-bold py-2 px-6 rounded-lg"
						onClick={prevStep}
					>
						Previous
					</button>
				</div>
				<div className="w-2/5">
					<button
						type="button"
						className="min-w-full bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-lg"
						onClick={() => saveStepData()}
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
}

export default GeneralQuestions;
