import React from 'react';
import { toast } from 'react-toastify';
import CustomError from '../components/common/custom-error';

export const defaultToastError = (error) => {
	toast.error(<CustomError error={error} />, {
		position: 'top-center',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'colored'
	});
};
