/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import Modal from '../../../components/modals';
import Button from '../../../components/button/index';
import TextInput from '../../../components/input/text-input';
import { isTruthy } from '../../../utils/commonFunction';
import { InfoIcon } from '../../../components/Event/event-icons';
import DateInput from '../../../components/input/date-input';
import useErrorNotifier from '../../../hooks/use-error-notifier';
import {
	formatDateTimeToUTC,
	getElapsedTimestamp,
	getTimeWithAdditionalSeconds
} from '../../../utils/formatter';
import {
	closeRipNShipQueue,
	planRipNShip,
	activeLineDecideItemStatus,
	processRefundsOnRipNShipNoContinuation
} from '../../../api/event.request';
import { useInterval } from '../../../components/common/use-interval';

const clipLoaderCss = css`
	display: block;
	border-color: white;
`;

const BottomButton = ({ children, width = '32', ...restProps }) => (
	<Button
		type="djawn"
		rounded={false}
		height="9"
		mobileHeight="9"
		width={width}
		{...restProps}
	>
		{children}
	</Button>
);

const RipNShipEventCreateItem = ({
	label = '',
	htmlName = '',
	tooltipText = '',
	children
}) => (
	<div className="flex flex-col w-full">
		<label
			htmlFor={htmlName}
			className="block text-sm font-medium text-gray-700 pl-2"
		>
			<div className="flex justify-start space-x-2">
				<div>{label}</div>
				{isTruthy(tooltipText && tooltipText.length) && (
					<div data-tip={tooltipText}>
						<InfoIcon />
					</div>
				)}
			</div>
		</label>
		{children}
	</div>
);

const CloseLineModal = ({
	showModal,
	setShowModal,
	showEndEventModal,
	setShowEndEventModal,
	isSeller,
	eventId,
	futureEventDate,
	countdownTimerStartTime,
	timerInSeconds,
	buyers,
	timers,
	onEndEvent,
	onLineClosed,
	onCancel
}) => {
	const [futureEvent, setFutureEvent] = useState(false);
	const [closingActiveLine, setClosingActiveLine] = useState(false);
	const [futureEventName, setFutureEventName] = useState('');
	const now = new Date();
	const today = new Date(now.setDate(now.getDate()));
	today.setHours(now.getHours() + 1, 0, 0, 0);
	const [startDate, setStartDate] = useState(today);
	const [eventDate, setEventDate] = useState(today);
	const [eventTime, setEventTime] = useState(today);
	const [, setMinimumTime] = useState(today);
	const [countdown, setCountdown] = useState(0);
	const [canEndEvent, setCanEndEvent] = useState(false);
	const [noContinuationConfirmation, setNoContinuationConfirmation] = useState(
		false
	);
	const [noContinuationConfirmed, setNoContinuationConfirmed] = useState(false);
	const [refundsProcessing, setRefundsProcessing] = useState(false);
	const { showToastError } = useErrorNotifier();

	useInterval(() => {
		if (!countdownTimerStartTime || !timerInSeconds || canEndEvent) return;
		const initialTimeToCompare = getTimeWithAdditionalSeconds(
			countdownTimerStartTime,
			timerInSeconds
		);

		const currentDate = moment();
		const time = getElapsedTimestamp(currentDate, initialTimeToCompare);

		if (currentDate >= initialTimeToCompare) {
			if (isSeller) {
				setCanEndEvent(true);
			}
			setCountdown('00:00:00');
		} else {
			setCountdown(time);
		}
	}, 1000);

	useEffect(() => {
		if (!noContinuationConfirmed) return;
		setTimeout(() => {
			onEndEvent(true);
		}, 60000);
	}, [noContinuationConfirmed]);

	const decideIfUserWantsToKeepItems = (keepItems) => {
		activeLineDecideItemStatus(eventId, keepItems).then(() => {
			setShowModal(false);
		});
	};
	const onKeepItems = () => {
		decideIfUserWantsToKeepItems(true);
	};

	const onRefundItems = () => {
		decideIfUserWantsToKeepItems(false);
	};

	const getTimeInLine = (position) => {
		const timer = timers.find((p) => p.position == position);
		if (timer && timer.lineTime) return timer.lineTime;
		return null;
	};

	useEffect(() => {
		let hours = now.getHours().toString();
		let minutes = now.getMinutes().toString();
		if (hours < 10) hours = `0${hours}`;
		if (minutes < 10) minutes = `0${minutes}`;

		setEventTime(`${hours}:${minutes}`);
	}, []);

	const getMinimumTime = (value) => {
		const startDateYear = value.getFullYear();
		const startDateMonth = value.getMonth();
		const startDateDate = value.getDate();

		const currentDateYear = new Date().getFullYear();
		const currentDateMonth = new Date().getMonth();
		const currentDateDate = new Date().getDate();

		if (
			startDateYear === currentDateYear
			&& startDateMonth === currentDateMonth
			&& startDateDate === currentDateDate
		) {
			return new Date(0, 0, 0, new Date().getHours() + 1);
		}
		return new Date(0, 0, 0, 0);
	};

	const onEventDateChange = (value) => {
		const currentDate = new Date();

		if (value < currentDate) {
			showToastError('The date and time must not be in the past');
			const formatedToday = new Date(
				currentDate.setDate(currentDate.getDate())
			);
			formatedToday.setHours(currentDate.getHours());
			formatedToday.setMinutes(currentDate.getMinutes() + 2);
			formatedToday.setSeconds(0);
			formatedToday.setMilliseconds(0);

			setStartDate(formatedToday);
			setEventDate(formatedToday.toLocaleDateString());
		} else {
			setMinimumTime(getMinimumTime(value));
		}
		setStartDate(value);
		setEventDate(value.toLocaleDateString());
	};

	const onEndRipNShipEvent = async (createNewEvent = false) => {
		if (createNewEvent) {
			const currentDate = new Date();
			const selectedDate = new Date(startDate);
			if (selectedDate < currentDate) {
				showToastError('The date and time must not be in the past');
				return;
			}

			const date = startDate.toLocaleDateString('en-US');
			const hour = startDate.getHours();
			const minutes = startDate.getMinutes();
			// am or pm
			const meridiem = startDate.toLocaleTimeString().slice(-2);

			const eventDateInUtc = formatDateTimeToUTC(date, hour, minutes, meridiem);

			await planRipNShip(futureEventName, eventDateInUtc, eventId);
		} else {
			setRefundsProcessing(true);
		}

		closeRipNShipQueue(eventId, createNewEvent, true).then(() => {
			if (createNewEvent == false) {
				processRefundsOnRipNShipNoContinuation(eventId)
					.then(() => {
						setNoContinuationConfirmed(true);
						setRefundsProcessing(false);
					})
					.catch((err) => {
						showToastError(err);
					});
			} else {
				setFutureEvent(false);
				setClosingActiveLine(true);
				setShowEndEventModal(false);
			}
		});
	};
	const onEventTimeChange = (value) => {
		if (value == '') return;
		const time = value.split(':');

		setEventTime(value);
		const dateVal = startDate;
		dateVal.setHours(time[0]);
		dateVal.setMinutes(time[1]);
		setStartDate(dateVal);
		setEventDate(dateVal.toLocaleDateString());
	};

	const showBuyerSelectionButton = (buyer) => {
		if (buyer.keepItems == null || buyer.keepItems == undefined) return null;
		let text = 'Refund me';
		if (buyer.keepItems === true) {
			text = 'Keep items';
		}
		return (
			<Button type="djawn" width="32" height="10" disabled>
				{text}
			</Button>
		);
	};

	const sellerView = () => (closingActiveLine ? (
		<>
			<table className="table-fixed text-left">
				<thead>
					<tr>
						<th className="w-3/12 p-4 text-left text-sm font-medium text-gray-100 bg-djawn">
								Active line
						</th>
						<th className="w-3/12 p-4 text-left text-sm font-medium text-gray-100 bg-djawn">
								Time in line
						</th>
						<th className="w-3/12 p-4 text-left text-sm font-medium text-gray-100 bg-djawn">
								Selection
						</th>
					</tr>
				</thead>
				<tbody className="text-gray-800 text-sm dark:text-white dark:bg-darkGray-50">
					{isTruthy(buyers && buyers.length)
							&& buyers
								.filter((buyer) => buyer.position > 1 && buyer.purchasedItem)
								.map((buyer) => (
									<tr key={buyer.position}>
										<td className="px-4 xxs:px-4 py-2">
											<p className="text-base font-light leading-relaxed mb-2 text-left break-all w-36">
												{buyer.nickname}
											</p>
										</td>
										<td>
											<p>{getTimeInLine(buyer.position)}</p>
										</td>
										<td>{showBuyerSelectionButton(buyer)}</td>
									</tr>
								))}
				</tbody>
			</table>
			<div className="flex justify-between items-center w-full pt-4">
				<p>
						Time remaining
					{' '}
					<span className="ml-2 border-2 p-1">{countdown}</span>
				</p>
				<BottomButton onClick={onEndEvent} disabled={!canEndEvent}>
						End event
				</BottomButton>
			</div>
		</>
	) : showEndEventModal ? (
		futureEvent ? (
			<>
				<ReactTooltip />
				<div className="">
					<RipNShipEventCreateItem
						label="Event Name"
						htmlName="event-name"
						tooltipText="Set your event apart by giving it a descriptive name."
					>
						<TextInput
							additionalClassName="mt-1"
							name="event-name"
							value={futureEventName}
							onChange={({ target }) => setFutureEventName(target.value)}
						/>
					</RipNShipEventCreateItem>
					<RipNShipEventCreateItem label="Event Date" htmlName="event-date">
						<DateInput
							additionalClassName="mt-1"
							name="event-date"
							value={eventDate}
							selected={startDate}
							minDate={new Date()}
							onChange={(date) => onEventDateChange(date)}
						/>
					</RipNShipEventCreateItem>
					<RipNShipEventCreateItem label="Event Time" htmlName="event-time">
						<TextInput
							additionalClassName="mt-1"
							name="event-time"
							value={eventTime}
							inputType="time"
							onChange={({ target }) => onEventTimeChange(target.value)}
						/>
					</RipNShipEventCreateItem>
				</div>
				<div className="flex justify-between items-center w-full pt-4">
					<BottomButton onClick={() => setFutureEvent(false)}>
							Go back
					</BottomButton>
					<BottomButton onClick={() => onEndRipNShipEvent(true)}>
							Continue
					</BottomButton>
				</div>
			</>
		) : (
			<>
				{!noContinuationConfirmation ? (
					<>
						<p>
								You are about to end this event. If you plan to do another
								event, select "Set feature date/time". Existing buyers will be
								added to the line for that future event, unless they selected to
								get a refund.
						</p>
						<br />
						<p>
								If you are not planning another event, buyers will still be
								given a choice to keep their purchase or receive a refund.
								Select "No continuation planned"
						</p>
						<div className="flex justify-between items-center w-full pt-4">
							<BottomButton width="36" onClick={() => setFutureEvent(true)}>
									Set future date/time
							</BottomButton>
							<BottomButton
								width="36"
								onClick={() => setNoContinuationConfirmation(true)}
							>
									No continuation planned
							</BottomButton>
						</div>
					</>
				) : noContinuationConfirmed ? (
					<>
						<p>
								Please note that the following customers have been refunded and
								the event has been ended.
						</p>
						<div className="h-36 flex justify-start w-full overflow-y-scroll pt-4">
							<table className="table-fixed w-full text-left">
								<thead>
									<tr>
										<th className="w-full p-4 text-left text-sm font-medium text-gray-100 bg-djawn">
												Active line
										</th>
									</tr>
								</thead>
								<tbody className="text-gray-800 text-sm dark:text-white dark:bg-darkGray-50">
									{isTruthy(buyers && buyers.length)
											&& buyers
												.filter(
													(buyer) => buyer.position > 1 && buyer.purchasedItem
												)
												.map((buyer) => (
													<tr key={buyer.position}>
														<td className="px-2 py-1">
															<p className="text-base font-light leading-relaxed mb-2 text-left break-all w-full">
																{buyer.nickname}
															</p>
														</td>
													</tr>
												))}
								</tbody>
							</table>
						</div>

						<div className="flex justify-end items-center w-full pt-4">
							<BottomButton onClick={() => onEndEvent(true)}>Ok</BottomButton>
						</div>
					</>
				) : (
					<>
						<p>
								Please note that you have selected to refund customers who have
								not started their turn. Please click continue to process the
								refunds or cancel to go back and set a date for continuation.
						</p>
						<div className="flex justify-between items-center w-full pt-4">
							<BottomButton
								width="36"
								disabled={refundsProcessing}
								onClick={() => setNoContinuationConfirmation(false)}
							>
									Cancel
							</BottomButton>
							{refundsProcessing ? (
								<BottomButton
									width="36"
									disabled
									onClick={() => onEndRipNShipEvent(false)}
									additionalClassName="flex justify-between px-5 items-center"
								>
									<ClipLoader size={30} css={clipLoaderCss} />
									<>
										<span>Continue</span>
									</>
								</BottomButton>
							) : (
								<BottomButton
									width="36"
									onClick={() => onEndRipNShipEvent(false)}
								>
										Continue
								</BottomButton>
							)}
						</div>
					</>
				)}
			</>
		)
	) : (
		<>
			<p>
					You are about to close the line. This means that no further items can
					be purchased and no new buyers can be added to the line.
			</p>
			<div className="flex justify-between items-center w-full pt-4">
				<BottomButton onClick={() => setShowModal(false)}>
						Cancel action
				</BottomButton>
				<BottomButton onClick={onLineClosed}>Close the line</BottomButton>
			</div>
		</>
	));

	const buyerView = () => (
		<>
			<p>
				You are in the active line but the seller has indicated they need to end
				this event.
			</p>
			<p>
				You need to decide whether you want to keep the item/s you have
				purchased or request a refund
			</p>
			{futureEventDate && (
				<p>
					A future event has been scheduled for
					{futureEventDate}
				</p>
			)}
			<br />
			<p>
				Select your prefered option. Any buyer not making a selection within
				expiry of timer, will be selected for a refund of their purchase
			</p>
			<br />
			<div className="flex justify-start">
				<p>
					Time remaining
					{' '}
					<span className="ml-2 border-2 p-1">{countdown}</span>
				</p>
			</div>
			<div className="flex justify-between items-center w-full pt-4 gap-1">
				<BottomButton onClick={onKeepItems}>Keep items</BottomButton>
				<BottomButton width="36" onClick={onRefundItems}>
					Request refund
				</BottomButton>
			</div>
		</>
	);
	return (
		<Modal
			showModal={showModal}
			onClose={() => {
				setFutureEvent(false);
				setShowModal(false);
				onCancel();
			}}
			hideOverflow={false}
			hideCloseButton
			noOutsideClick={noContinuationConfirmation}
			hideXButton={noContinuationConfirmation}
			headerMargin={noContinuationConfirmation ? '4' : '10'}
			header={
				isSeller ? !isTruthy(futureEvent) && 'WARNING' : 'EVENT IS ENDING !!'
			}
		>
			{isSeller ? sellerView() : buyerView()}
		</Modal>
	);
};

export default CloseLineModal;
