import React from 'react';
import { isMobile } from 'react-device-detect';

const Divider = ({
	children,
	bgColor = 'white',
	hasBorderColor = false,
	borderColor = 'black'
}) => (
	<div className="wrap my-5">
		<div className="flex justify-evenly relative">
			<div
				className={`flex items-center bg-${bgColor} ${
					!!hasBorderColor && `border-${borderColor}`
				} border h-${isMobile ? '8' : '10'} w-auto rounded-3xl`}
			>
				{children}
			</div>
		</div>
	</div>
);

export default Divider;
