import React, { useState, useEffect, useRef } from 'react';
import { XCircleIcon } from '@heroicons/react/outline';
import { isTextTruncated } from '../../../utils/element';

const ConversationEventName = ({ eventName, closeEventName }) => {
	const [truncated, setTruncated] = useState(false);
	const nameRef = useRef();

	useEffect(() => {
		if (!nameRef || !nameRef.current) return;
		setTruncated(isTextTruncated(nameRef.current));
	}, [eventName]);

	return (
		<div className="sticky top-0 w-full h-10 flex px-4 pt-2">
			<div className="flex flex-1 items-start justify-start w-full">
				{truncated ? (
					// eslint-disable-next-line jsx-a11y/no-distracting-elements
					<marquee className="text-grey-darkest text-md font-extrabold mt-1 w-64">
						{eventName}
					</marquee>
				) : (
					<h4 ref={nameRef} className="w-48 truncate line-clamp-1">
						{eventName}
					</h4>
				)}
			</div>
			<button
				type="button"
				className="flex items-start"
				onClick={closeEventName}
			>
				<XCircleIcon className="w-6 h-6" />
			</button>
		</div>
	);
};

export default ConversationEventName;
