import React from 'react';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { applyMiddleware, createStore as reduxCreateStore } from 'redux';
import { PERSIST, PURGE, REHYDRATE } from 'redux-persist/es/constants';

import {
	createStateSyncMiddleware,
	initMessageListener
} from 'redux-state-sync';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import rootReducer from '.';

const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['utils', 'theme'],
	predicate: (action) => {
		const blacklist = [PERSIST, PURGE, REHYDRATE];
		if (typeof action !== 'function') {
			if (Array.isArray(blacklist)) {
				return blacklist.indexOf(action.type) < 0;
			}
		}
		return false;
	}
};

const middlewares = [createStateSyncMiddleware(persistConfig)];

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = reduxCreateStore(
	persistedReducer,
	{},
	applyMiddleware(...middlewares)
);

initMessageListener(store);

export default ({ element }) => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistStore(store)}>
			{element}
		</PersistGate>
	</Provider>
);
