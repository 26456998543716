import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { getDurationTime } from '../../utils/formatter';

const AdminEventAttendees = ({
	attendees,
	setSelectedUserIds,
	setSelectedAnonymousTokens,
	onUserClicked,
	sellerNickname
}) => {
	const columns = useMemo(() => [
		{
			name: 'Customer Name',
			selector: 'nickname',
			sortable: true,
			cell: (row) => (!row.isAnonymous ? (
				<p
					className="link underline cursor-pointer"
					onClick={() => onUserClicked(row.userId)}
					onKeyDown={() => onUserClicked(row.userId)}
				>
					{row.nickname}
				</p>
			) : (
				<p>Anonymous</p>
			)),
			wrap: true
		},
		{
			name: 'Total Number of Attendance',
			selector: 'numberOfAttendances',
			sortable: true,
			wrap: true
		},
		{
			name: 'Twilio participant Id',
			selector: 'participantResource.sid',
			sortable: true,
			wrap: true
		},
		{
			name: 'Time since last attend',
			selector: 'participantResource.duration',
			cell: (row) => <p>{getDurationTime(row.participantResource.duration)}</p>,
			sortable: true,
			wrap: true
		},
		{
			name: 'In the event?',
			selector: 'participantResource.status',
			cell: (row) => (
				<p>{row.participantResource.status === 'connected' ? 'Yes' : 'No'}</p>
			),
			sortable: true,
			wrap: true
		},
		{
			name: 'Audio Received',
			selector: 'audioReceived',
			cell: (row) => <p>{row.audioReceived === true ? 'Yes' : 'No'}</p>,
			sortable: true,
			wrap: true
		},
		{
			name: 'Video Received',
			selector: 'videoReceived',
			cell: (row) => <p>{row.videoReceived === true ? 'Yes' : 'No'}</p>,
			sortable: true,
			wrap: true
		}
	]);

	const rowDisabledCriteria = (row) => row.nickname === sellerNickname;
	return (
		<div className="w-full">
			<DataTable
				columns={columns}
				selectableRows
				selectableRowsHighlight
				data={attendees}
				pagination
				onSelectedRowsChange={(row) => {
					const { selectedRows } = row;
					setSelectedUserIds(
						selectedRows.map((att) => att.userId).filter((f) => f != null)
					);
					setSelectedAnonymousTokens(
						selectedRows
							.map((att) => att.anonymousToken)
							.filter((f) => f != null)
					);
				}}
				selectableRowDisabled={rowDisabledCriteria}
				noContextMenu
				className=""
				contextActions={null}
				striped
				paginationComponentOptions={{
					rowsPerPageText: 'Rows per page:',
					rangeSeparatorText: 'of',
					noRowsPerPage: false,
					selectAllRowsItem: true,
					selectAllRowsItemText: 'All'
				}}
				paginationRowsPerPageOptions={[10, 25, 100]}
				customStyles={{
					rows: {
						style: {}
					},
					headCells: {
						style: {
							backgroundColor: '#e82e2c',
							color: 'white',
							fontSize: '1rem',
							fontWeight: 'bold'
						}
					}
				}}
			/>
		</div>
	);
};
export default AdminEventAttendees;
