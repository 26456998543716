import React, { useContext } from 'react';
import BackButton from '../../components/button/back-button';
import NextButton from '../../components/button/next-button';
import { CreateEventContext } from '../../contexts/create-event';
import useErrorNotifier from '../../hooks/use-error-notifier';

const CreateBreakTemplate = () => {
	const { showToastError } = useErrorNotifier();
	const {
		sports,
		costPerSlot,
		setCostPerSlot,
		editedTemplate,
		setEditedTemplate,
		setTemplateActionType,
		setActiveStep,
		maximumNumberOfSlotsAllowed,
		maximumNumberOfTiersAllowed,
		maximumNumberOfSlotsAndTiersAllowed
	} = useContext(CreateEventContext);

	const onSportTypeChange = (sportTypeId) => {
		setEditedTemplate({
			...editedTemplate,
			sportTypeID: sportTypeId
		});
	};

	const onNumberOfSlotsChange = (value) => {
		value = value.replace(/[^\d].+/, '');
		setEditedTemplate({
			...editedTemplate,
			slotSize: value
		});
	};

	const onNextClick = () => {
		if (
			editedTemplate.tierSize <= 0
			|| editedTemplate.slotSize <= 0
			|| costPerSlot <= 0
		) {
			showToastError('Please fill all the fields');
			return;
		}
		if (editedTemplate.slotSize > maximumNumberOfSlotsAllowed) {
			showToastError(
				`Number of slots should not be more than ${maximumNumberOfSlotsAllowed}`
			);
			setEditedTemplate({ ...editedTemplate, slotSize: 0 });
			return;
		}

		if (editedTemplate.tierSize > maximumNumberOfTiersAllowed) {
			showToastError(
				`Number of tiers should not be more than ${maximumNumberOfTiersAllowed}`
			);
			setEditedTemplate({ ...editedTemplate, tierSize: 0 });
			return;
		}

		if (
			editedTemplate.slotSize * editedTemplate.tierSize
			> maximumNumberOfSlotsAndTiersAllowed
		) {
			showToastError(
				`Total number of tiers can not be more than ${maximumNumberOfSlotsAndTiersAllowed}`
			);
			setEditedTemplate({ ...editedTemplate, tierSize: 0, slotSize: 0 });
			return;
		}

		setTemplateActionType('create-tiers');
		setActiveStep(3);
	};

	const onBackClick = () => {
		setTemplateActionType('');

		setActiveStep(2);
	};
	const onNumberOfTiersChange = (value) => {
		value = value.replace(/[^\d].+/, '');
		setEditedTemplate({
			...editedTemplate,
			tierSize: value
		});
	};

	return (
		<div className="flex-grow p-4">
			<div className="justify-center mx-auto w-full md:w-1/2 lg:w-1/2">
				<div className="mb-6">
					<label
						htmlFor="event_type"
						className="block text-sm font-medium text-gray-700 pl-2"
					>
						Sport
					</label>
					<div className="mt-1 relative rounded-md shadow-sm">
						<select
							id="sport_type"
							name="sport_type"
							autoComplete="sport_type"
							className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							onBlur={(e) => onSportTypeChange(e.target.value)}
							onChange={(e) => onSportTypeChange(e.target.value)}
							value={editedTemplate.sportTypeID}
						>
							<option key={0} value={0}>
								Select Sports
							</option>
							{sports.map((object) => (
								<option
									key={object.referenceDataId}
									value={object.referenceDataId}
								>
									{object.referenceDataValue}
								</option>
							))}
						</select>
					</div>
				</div>

				<div className="mb-6">
					<label
						htmlFor="slots_count"
						className="block text-sm font-medium text-gray-700 pl-2"
					>
						Number of slots
					</label>
					<div className="mt-1 relative rounded-md shadow-sm">
						<input
							type="number"
							min="0"
							name="slots_count"
							id="slots_count"
							className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
							placeholder="Number of slots"
							value={editedTemplate.slotSize}
							onChange={(e) => onNumberOfSlotsChange(e.target.value)}
						/>
					</div>
				</div>

				<div className="mb-6">
					<label
						htmlFor="cost_per_slot"
						className="block text-sm font-medium text-gray-700 pl-2"
					>
						Cost per slot
					</label>
					<div className="mt-1 flex rounded-md shadow-sm">
						<span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
							$
						</span>
						<input
							type="text"
							min="0"
							name="cost_per_slot"
							id="cost_per_slot"
							className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-none rounded-r-md"
							placeholder="Cost per slot"
							value={costPerSlot}
							onChange={(e) => setCostPerSlot(e.target.value)}
						/>
					</div>
				</div>
				<div className="mb-6">
					<label
						htmlFor="cost_per_slot"
						className="block text-sm font-medium text-gray-700 pl-2"
					>
						No of Tiers
					</label>
					<div className="mt-1 flex rounded-md shadow-sm">
						<input
							type="number"
							min="0"
							name="tierSize"
							id="tierSize"
							className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-none rounded-r-md"
							placeholder="No of Tiers"
							value={editedTemplate.tierSize}
							onChange={(e) => onNumberOfTiersChange(e.target.value)}
						/>
					</div>
				</div>

				<div className="mb-4 border-t">
					<p className="block text-4lx font-medium text-center text-gray-700 pt-4">
						Total Break Cost $
						{editedTemplate.slotSize * costPerSlot}
					</p>

					<p className="block text-4lx font-medium text-center text-gray-700 pt-8">
						Please edit or confirm these values
					</p>
				</div>
			</div>

			<div className="flex justify-center mx-auto">
				<div className="mr-2">
					<BackButton onClick={onBackClick} />
				</div>
				<div className="ml-2">
					<NextButton onClick={onNextClick} />
				</div>
			</div>
		</div>
	);
};

export default CreateBreakTemplate;
