import React, { createContext } from 'react';
import useTwilioTracks from '../hooks/useTwilioTracks';

const TwilioTrackContext = createContext();

const TwilioTrackProvider = ({
	eventId,
	isHost,
	twilioRoomIdentity,
	children
}) => {
	const {
		setLocalAudioTracks,
		twilioParticipantId,
		setTwilioParticipantId,
		requestedMicAccess,
		setNewAudioParticipant,
		setRequestedMicAccess,
		onRequestMicDecision,
		approveOrDeclineMicRequest,
		audioParticipants,
		setAudioParticipants,
		setCanceledMicRequestAccess,
		setNewCanceledMicRequestAccess,
		setExpiredMicAccessMessage,
		setApprovedAudioParticipants,
		micAccessApprovedBySeller,
		micAccessApprovedByBuyer,
		setMicAccessApprovedBySeller,
		setMicAccessApprovedByBuyer,
		muteOrUnmuteMicParticipants,
		setNewMutedAudioParticipants,
		closeMic,
		mutedByBuyer,
		mutedBySeller,
		buyerNotificationType,
		setBuyerNotificationType,
		deleteCurrentMicRequest,
		removeLocalMicAccess
	} = useTwilioTracks(eventId, isHost, twilioRoomIdentity);

	return (
		<TwilioTrackContext.Provider
			value={{
				setLocalAudioTracks,
				twilioParticipantId,
				setTwilioParticipantId,
				requestedMicAccess,
				setNewAudioParticipant,
				setRequestedMicAccess,
				onRequestMicDecision,
				approveOrDeclineMicRequest,
				audioParticipants,
				setAudioParticipants,
				setCanceledMicRequestAccess,
				setNewCanceledMicRequestAccess,
				setExpiredMicAccessMessage,
				setApprovedAudioParticipants,
				micAccessApprovedBySeller,
				micAccessApprovedByBuyer,
				setMicAccessApprovedBySeller,
				setMicAccessApprovedByBuyer,
				muteOrUnmuteMicParticipants,
				setNewMutedAudioParticipants,
				closeMic,
				mutedByBuyer,
				mutedBySeller,
				buyerNotificationType,
				setBuyerNotificationType,
				deleteCurrentMicRequest,
				removeLocalMicAccess
			}}
		>
			{children}
		</TwilioTrackContext.Provider>
	);
};

export { TwilioTrackContext, TwilioTrackProvider };
