import { navigate } from 'gatsby-link';
import React from 'react';
import Product from '../../components/product';

const ProductRegisterPage = () => (
	<Product
		onSuccess={() => navigate('/products')}
		onUnauthorized={() => navigate('/not-authorized', {
			state: {
				stripeAccountError: true
			}
		})}
	/>
);
export default ProductRegisterPage;
