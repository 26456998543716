import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const AdminShowUserInfoModal = ({ user, showModal, setShowModal }) => {
	const cancelButtonRef = useRef();

	return (
		<Transition.Root show={showModal} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-50 inset-0 overflow-y-auto"
				initialFocus={cancelButtonRef}
				open={showModal}
				onClose={() => setShowModal(false)}
			>
				<div className="flex items-center md:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay
							className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
							style={{ zIndex: '1040!important' }}
						/>
					</Transition.Child>

					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						style={{ zIndex: '1050!important' }}
					>
						<div className="inline-block fixed md:relative bg-opacity-100 align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
							<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative">
								<div className="flex items-center justify-center">
									<div className="mt-3 text-center justify-center sm:mt-0 sm:ml-4 sm:text-left">
										<div className="flex flex-col items-center justify-center">
											<div className="sm:col-auto md:col-span-1 lg:col-span-1 xl:col-s store-logo-info mt-10">
												<div className="flex flex-wrap">
													<div className="bg-gray-200 rounded-full h-28 w-28 flex items-center justify-center cursor-pointer mx-auto text-base">
														<img
															src={user.imageUrl}
															className="h-full w-auto sm:h-full rounded-full cursor-pointer"
															alt="file.name"
														/>
													</div>
													<div className="flex xxs:flex-col lg:flex-row xxs:w-full">
														<div className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-4 md:pr-4">
															<div className="w-full mt-1 relative rounded-md">
																<input
																	type="text"
																	name="first_name"
																	id="first_name"
																	className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
																	placeholder="First Name - Required value"
																	value={user.firstName}
																	disabled
																/>
															</div>
														</div>

														<div className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-4">
															<div className="w-full mt-1 relative rounded-md">
																<input
																	type="text"
																	name="last_name"
																	id="last_name"
																	className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
																	placeholder="Last Name - Required value"
																	value={user.lastName}
																	disabled
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-4">
													<div>
														<div className="mt-1 relative rounded-md">
															<input
																type="text"
																name="email"
																id="email"
																className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
																placeholder="john_doe@example.com - Required value"
																value={user.email}
																disabled
															/>
														</div>
													</div>
												</div>
												<div className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-4">
													<div>
														<div className="mt-1 relative rounded-md">
															<input
																type="text"
																name="nickname"
																id="nickname"
																className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
																placeholder="Screen Name - Required value"
																value={user.nickName}
																disabled
															/>
														</div>
													</div>
												</div>
												<div className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-4">
													<div>
														<div className="mt-1 relative rounded-md">
															<input
																type="text"
																name="nickname"
																id="nickname"
																className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
																placeholder="Screen Name - Required value"
																value={user.mailingAddress1}
																disabled
															/>
														</div>
													</div>
												</div>
												<div className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-4">
													<div>
														<div className="mt-1 relative rounded-md">
															<input
																type="text"
																name="mailingAddress1"
																id="mailingAddress1"
																className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
																placeholder="Apartment, suite, etc."
																value={user.mailingAddress2}
																disabled
															/>
														</div>
													</div>
												</div>
												<div className="flex xxs:flex-col lg:flex-row xxs:w-full mb-4 xxs:mb-4">
													<div className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-4 lg:pr-4">
														<div className="w-full mt-1 relative rounded-md">
															<input
																type="text"
																name="city"
																id="city"
																className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
																placeholder="City"
																value={user.mailingCity}
																disabled
															/>
														</div>
													</div>

													<div className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-4 lg:pr-4">
														<div className="w-full mt-1 relative rounded-md">
															<input
																type="text"
																name="state"
																id="state"
																className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
																placeholder="State"
																value={user.mailingState}
																disabled
															/>
														</div>
													</div>
													<div className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-0 disabled">
														<div className="w-full mt-1 relative rounded-md">
															<input
																type="text"
																name="post_code"
																id="post_code"
																className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
																placeholder="Zip"
																value={user.mailingZip}
																disabled
															/>
														</div>
													</div>
												</div>
												<div className="flex mt-8 justify-center">
													<div className="sm:text-center store-next-button">
														<button
															type="button"
															className="min-w-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-10 rounded-md "
															onClick={() => setShowModal(false)}
														>
															Close
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<button
									type="button"
									className="absolute px-3 pt-3 text-gray-500 top-0 right-0 cursor-pointer outline-none border-none focus:outline-none"
									onClick={() => setShowModal(false)}
								>
									X
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default AdminShowUserInfoModal;
