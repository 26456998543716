import React, {
	useContext, useState, useMemo, useEffect
} from 'react';
import { XIcon } from '@heroicons/react/solid';
import DataTable from 'react-data-table-component';
import { ProductsContext } from '../../contexts/products';
import useErrorNotifier from '../../hooks/use-error-notifier';

const EventProducts = ({
	products,
	onProductModalShow,
	showRemoveProductOption = false
}) => {
	const { showToastError } = useErrorNotifier();
	const [toggleStatus, settoggleStatus] = useState(true);
	const [, setProductSearchValue] = useState('');
	const [tableEventProducts, setTableEventProducts] = useState([]);
	const { setEventProductIdToRemove } = useContext(ProductsContext);

	const columns = useMemo(() => {
		const defaultColumns = [
			{
				name: 'Name',
				selector: (row) => row.product.productName,
				sortable: true,
				cell: (row) => (
					<div
						className="font-bold hover:underline cursor-pointer"
						data-tag="allowRowEvents"
					>
						{row.product.productName}
					</div>
				),
				wrap: true
			},
			{
				name: 'Price',
				selector: (row) => row.product.salesPriceRipShip,
				sortable: true,
				right: true,
				cell: (row) => (
					<div
						className="font-bold hover:underline cursor-pointer"
						data-tag="allowRowEvents"
					>
						$
						{row.product.salesPriceRipShip}
					</div>
				),
				wrap: true
			}
		];

		if (showRemoveProductOption) {
			defaultColumns.push({
				name: 'Remove product',
				sortable: true,
				right: true,
				cell: (row) => (
					<XIcon
						className="cursor-pointer w-8 h-8"
						onClick={() => {
							if (tableEventProducts.length <= 1) {
								showToastError(
									'You cannot remove the product, you should have at least one product in the event.'
								);
								return;
							}

							setEventProductIdToRemove(row.eventProductId);
						}}
					/>
				),
				wrap: true
			});
		}
		return defaultColumns;
	}, [showRemoveProductOption, tableEventProducts]);

	useEffect(() => {
		setTableEventProducts(products);
	}, [products]);

	const handleProductSearch = (e, text) => {
		let value = '';
		if (e && e.target) {
			value = e.target.value;
			setProductSearchValue(value);
		} else {
			value = text;
		}
		const tempProducts = [...products].filter(
			(eventProduct) => eventProduct.product.productName
				.toLowerCase()
				.includes(value.toLowerCase())
				|| eventProduct.product.salesPriceRipShip.toString().includes(value)
				|| eventProduct.product.totalAvailableStock.toString().includes(value)
				|| (eventProduct.product.tags
					&& eventProduct.product.tags.length
					&& eventProduct.product.tags.some((p) => p.tags.toLowerCase().includes(value.toLowerCase())))
		);
		setTableEventProducts(tempProducts);
	};

	return (
		<div className="flex flex-row w-2/3 xxs:w-11/12 mx-auto text-left py-2">
			<div className="flex flex-col w-full items-start">
				<div className="flex flex-wrap justify-center w-full">
					<div
						className="w-full flex cursor-pointer shadow-sm flex-row items-center justify-between text-left text-gray-100 text-sm font-medium py-2 px-3 rounded-md bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-none flex text-gray-100 font-bold text-base tracking-wide"
						onClick={() => settoggleStatus(!toggleStatus)}
						onKeyDown={() => settoggleStatus(!toggleStatus)}
					>
						Featured Products
						<span className="flex w-5 h-5">
							{!toggleStatus && (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M19 9l-7 7-7-7"
									/>
								</svg>
							)}
							{toggleStatus && (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M5 15l7-7 7 7"
									/>
								</svg>
							)}
						</span>
					</div>
				</div>
				<div
					className={`flex flex-col w-full justify-center xxs:justify-start w-full border-0 overflow-x-auto  transform ${
						toggleStatus
							? 'h-auto ease-in transition'
							: 'h-0 ease-out transition'
					}`}
				>
					<div className="flex flex-grow flex-wrap justify-center content-center w-full">
						<div className="w-full">
							<div className="relative text-gray-600  w-full md:w-auto mt-3 mb-3 flex justify-center">
								<input
									type="search"
									name="serch"
									placeholder="Search"
									className="h-10 px-10 pl-10 text-sm focus:outline-none border dark:bg-darkGray-50 dark:border-black dark:text-white rounded-md w-full "
									onChange={handleProductSearch}
								/>
								<button
									type="submit"
									className="absolute left-0 top-0 mt-3 ml-4"
								>
									<svg
										className="h-4 w-4 fill-current"
										xmlns="http://www.w3.org/2000/svg"
										version="1.1"
										id="Capa_1"
										x="0px"
										y="0px"
										viewBox="0 0 56.966 56.966"
										width="512px"
										height="512px"
									>
										<path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
									</svg>
								</button>
							</div>
							<DataTable
								columns={columns}
								data={tableEventProducts}
								pagination
								onRowClicked={(row) => {
									onProductModalShow(row);
								}}
								paginationComponentOptions={{
									rowsPerPageText: '',
									rangeSeparatorText: 'of',
									noRowsPerPage: true,
									selectAllRowsItem: true,
									selectAllRowsItemText: 'All'
								}}
								striped
								contextActions={null}
								noContextMenu
								customStyles={{
									rows: {
										style: {}
									},
									headCells: {
										style: {
											backgroundColor: '#e82e2c',
											color: 'white',
											fontSize: '1rem',
											fontWeight: 'bold'
										}
									}
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EventProducts;
