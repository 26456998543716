/* eslint-disable import/no-named-default */
import React from 'react';
import { default as ReactDataTable } from 'react-data-table-component';

const DataTable = ({
	columns,
	data,
	noContextMenu,
	pagination = false,
	className,
	onRowClicked
}) => (
	<ReactDataTable
		noContextMenu={noContextMenu}
		columns={columns}
		data={data}
		onRowClicked={onRowClicked}
		pagination={pagination}
		className={className}
		contextActions={null}
		striped
		paginationComponent={null}
		customStyles={{
			table: {
				style: {
					border: '1px #a7a6a6 solid'
				}
			},
			headCells: {
				style: {
					backgroundColor: '#e82e2c',
					color: 'white',
					fontSize: '0.75rem',
					fontWeight: 'bold'
				}
			}
		}}
	/>
);

export default DataTable;
