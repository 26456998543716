export const getVideoSettings = (settings, videoStreamOptions) => {
	settings.map((setting) => {
		const name = setting.referenceType.referenceTypeName;
		if (name == 'VideoDesktopHeight') {
			videoStreamOptions.desktop.height = setting.referenceDataValue;
		} else if (name == 'VideoDesktopWidth') {
			videoStreamOptions.desktop.width = setting.referenceDataValue;
		} else if (name == 'VideoMobileWidth') {
			videoStreamOptions.mobile.width = setting.referenceDataValue;
		} else if (name == 'VideoMobileHeight') {
			videoStreamOptions.mobile.height = setting.referenceDataValue;
		} else if (name == 'FPS') {
			videoStreamOptions.desktop.fps = setting.referenceDataValue;
		}
		return setting;
	});
	return videoStreamOptions;
};

export const getVideoSetting = (settings, name) => {
	if (settings && settings.length && name) {
		return settings.find((r) => r.referenceType && r.referenceType.referenceTypeName == name);
	}
	return null;
};
export const generateTwilioVideoConfig = (videoStreamOptions, roomToken, owner, isMobile, audio = false) => {
	const twilioConfig = {
		name: roomToken,
		audio,
		video: false,
		automaticSubscription: false,
		workaroundWebKitBug180748: true,
		workaroundWebKitBug1208516: false
	};

	if (owner) {
		twilioConfig.video = {
			width: videoStreamOptions.desktop.width,
			frameRate: 24,
			height: videoStreamOptions.desktop.height
		};

		twilioConfig.audio = true;
		twilioConfig.frameRate = videoStreamOptions.fps;
		twilioConfig.bandwidthProfile = {
			video: {
				mode: 'presentation',
				maxTracks: 10,
				dominantSpeakerPriority: 'standard',
				renderDimensions: {
					high: { height: 720, width: 1280 },
					standard: { height: 400, width: 800 },
					low: { height: 176, width: 144 }
				}
			}
		};
		twilioConfig.dominantSpeaker = true;
		twilioConfig.maxAudioBitrate = 16000;
		twilioConfig.preferredVideoCodecs = [
			{ codec: 'VP8', simulcast: true }
		];

		if (isMobile) {
			twilioConfig.video = {
				height: videoStreamOptions.mobile.height,
				frameRate: videoStreamOptions.fps,
				width: videoStreamOptions.mobile.width
			};
			twilioConfig.bandwidthProfile = {
				video: {
					mode: 'presentation',
					maxSubscriptionBitrate: 2500000,
					maxTracks: 5,
					dominantSpeakerPriority: 'standard',
					renderDimensions: {
						high: { height: 1080, width: 1920 },
						standard: { height: 720, width: 1280 },
						low: { height: 176, width: 144 }
					}
				}
			};
			twilioConfig.dominantSpeaker = true;
			twilioConfig.maxAudioBitrate = 16000;
			twilioConfig.preferredVideoCodecs = [
				{ codec: 'VP8', simulcast: true }
			];
		}
	}
	return twilioConfig;
};
