import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ToastContainer } from 'react-toastify';
import { uploadEventImages } from '../../api/event.request';
import { defaultToastError } from '../../utils/toast-error';
import { defaultToastSucesss } from '../../utils/toast-success';
import ImagePreview from '../common/image-preview';
import Loader from '../loader/index';

const ChangeEventPictureModal = ({
	showModal,
	setShowModal,
	eventId,
	onChangePicture
}) => {
	const [files, setFiles] = useState([]);
	const [loading, setLoading] = useState(false);
	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		onDrop: (acceptedFiles) => {
			const newFiles = acceptedFiles.map((file) => Object.assign(file, {
				preview: URL.createObjectURL(file)
			}));
			const tempNewFiles = [...newFiles];
			// let tempOldFiles = [...files];
			Array.prototype.push.apply(tempNewFiles);

			setFiles(tempNewFiles);
		},
		multiple: false
	});
	const canUpdate = files && files.length > 0;

	const onPreviewChange = (selectedFiles) => {
		setFiles(
			selectedFiles.map((file) => Object.assign(file, {
				preview: URL.createObjectURL(file)
			}))
		);
	};

	return (
		<>
			{showModal ? (
				<>
					<ToastContainer />
					<>
						<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
							{loading ? (
								<div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
									<div className="flex h-full items-center">
										<Loader />
									</div>
								</div>
							) : (
								<div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
									<div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
										<p className="font-semibold text-gray-800">
											Change Event Picture
										</p>
										<svg
											className="cursor-pointer w-6 h-6"
											onClick={() => setShowModal(false)}
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M6 18L18 6M6 6l12 12"
											/>
										</svg>
									</div>
									<div className="flex flex-col px-6 py-5 bg-gray-50 w-full">
										<div className="flex flex-row">
											<div className="flex flex-col w-3/4">
												<div
													style={{ verticalAlign: 'middle' }}
													{...getRootProps({
														className:
															'dropzone border bg-blue-100 rounded-md text-lg text-center inline-block'
													})}
												>
													<input
														{...getInputProps()}
														className="h-full border bg-gray-300"
														multiple={false}
													/>
													<p className="lg:h-24 md:h-24 xs:h-25 inline-block align-middle leading-6 p-4">
														{window.screen.width > 786
															? 'Drag and drop a file here, or '
															: ''}
														click here to select a file.
													</p>
												</div>
											</div>
											<div
												className="flex flex-col border ml-2"
												style={{ height: 100, width: 100 }}
											>
												<div>
													<ImagePreview
														files={files}
														onChange={(values) => onPreviewChange(values)}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="flex xxs:flex-row justify-center items-center xxs:w-full xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0 mb-5 p-3">
										<div className="mr-3">
											<button
												disabled={!canUpdate}
												type="button"
												className={`${
													!canUpdate
														? 'bg-red-300'
														: 'bg-red-700 hover:bg-red-900'
												} h-12 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white  md:py-4 md:text-lg md:px-10`}
												style={{ pointerEvents: !canUpdate ? 'none' : '' }}
												onClick={() => {
													setLoading(true);
													uploadEventImages(files, eventId)
														.then(() => {
															defaultToastSucesss(
																'Picture has been updated successfully!'
															);
														})
														.catch(() => {
															defaultToastError(
																'Picture could not be updated!'
															);
														})
														.finally(() => {
															setShowModal(false);
															setLoading(false);
															onChangePicture(files);
														});
												}}
											>
												Update
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
						<div
							className="opacity-25 fixed inset-0 z-40 bg-black"
							onClick={() => setShowModal(false)}
							onKeyDown={() => setShowModal(false)}
						/>
					</>
				</>
			) : null}
		</>
	);
};

export default ChangeEventPictureModal;
