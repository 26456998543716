/* eslint-disable no-multi-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, {
	useState, useEffect, useRef, useContext
} from 'react';
import { connect, createLocalTracks, Logger } from 'twilio-video';
import BounceLoader from 'react-spinners/BounceLoader';
import { css } from '@emotion/core';
import { navigate } from 'gatsby';
import {
	isSafari,
	isChrome,
	isEdge,
	isFirefox,
	isMobile as isMobileSize,
	isTablet,
	isAndroid
} from 'react-device-detect';
import { connect as ReduxConnect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useInterval } from '../common/use-interval';
import Layout from '../layouts/layout-default';
import Channel from '../chat/channel';
import VideoStream from '../video/video';
import { isLoggedIn, getAccessToken, getCurrentUser } from '../../utils/auth';
import env from '../../../environment.config';

import {
	getRoomTokens,
	startRoom,
	exitRoom,
	exitRoomByAnonymousToken,
	isEventStopped,
	getAttendees,
	hasUserPurchasedItemsInEvent,
	updateRoomStatusToStarted,
	getEventRoomVideoTrackId,
	savedEventJoinFailure,
	saveSubscribedTrack,
	saveSubscribedTrackAnonymousUser,
	sendEventReportEmail,
	addBuyersToNextLineInFutureEvent,
	updateChatId,
	reissueVideoToken,
	revertEventStartedStatus
} from '../../api/event.request';
import { reserveProduct } from '../../api/product.request';
import RoomOptions from './room-options';
import useWindowSize from '../common/use-window-size';

import RoomHeader from '../room-header';
import {
	generateTwilioVideoConfig,
	getVideoSetting,
	getVideoSettings
} from '../../utils/videoSettings';

import NotSupportedBrowser from '../../pages/not-supported-browser';
import SellerRoomLeaveConfirmation from '../seller-room-leave-confirmation';
import BuyerRoomLeaveConfirmation from '../buyer-room-leave-confirmation';
import MiniCart from '../common/mini-cart';
import {
	addCartItem,
	removeCartItem,
	updateCartItemTimer
} from '../../state/action';

import { defaultToastError } from '../../utils/toast-error';
import { isDateAfter } from '../../utils/formatter';
import AccessDeniedMessage from './access-denied';
import useLocalStorage from '../../hooks/use-local-storage';
import Conversation from '../conversation';
import useConversation from '../../hooks/useConversation';
import { RANDOMIZED, RIP_N_SHIP } from '../../utils/constants';
import { getAuthenticatedUserId } from '../../api/auth.request';
import { getDjawnUser } from '../../api/referenceData.request';
import ConversationEventName from '../../containers/event/room/conversation-event-name';
import useVirtualAndroidKeyboardDetector from '../../hooks/useVirtualAndroidKeyboardDetector';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import { RANDOMIZED_BREAKS_VIEW } from '../../utils/views';
import { TwilioTrackProvider } from '../../contexts/twilio-track';
import { NotificationSoundProvider } from '../../contexts/notification-sound';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const Room = ({
	params,
	nickname,
	eventInformation,
	setNickname,
	anonymous,
	isRoomHost,
	setIsRoomHost,
	hasStarted,
	setHasStarted,
	cartItems,
	dispatch,
	setEventInformation,
	isSeller,
	restart,
	userId
}) => {
	const [popups, setPopups] = useLocalStorage('live_event_views_popups', {
		show_cards: true,
		show_views: true,
		show_addtocart_information: true,
		show_event_name: true
	});
	const [dismissedPopups, setDismissedPopups] = useState({
		show_cards: true,
		show_views: true,
		show_addtocart_information: true,
		show_event_name: true
	});
	const [resize, setResize] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [videoRoomToken, setVideoRoomToken] = useState(null);
	const [isHost, setIsHost] = useState(isRoomHost);
	const [ownerNickname, setOwnerNickname] = useState('');
	const [eventName, setEventName] = useState('');
	const [channelSid, setChannelSid] = useState('');
	const [eventDetails, setEventDetails] = useState('');
	const [eventStartDate, setEventStartDate] = useState(null);
	const [tempNickname, setTempNickname] = useState(`${nickname}`);
	const [showModal, setShowModal] = useState(false);
	const [isPaused, setIsPaused] = useState(false);
	const [room, setRoom] = useState(null);
	const [stopped, setStopped] = useState(false);
	const [reorganizeAccordion, setReorganizeAccordion] = useState(false);
	const [showThankYou, setShowThankYou] = useState(false);
	const [width, setWidth] = useState(window.innerWidth);
	const [mobileSize, setMobileSize] = useState(false);
	const [goToHome, setGoToHome] = useState(false);
	const [hasRaisedHand, setHasRaisedHand] = useState(false);
	const [videoHeight, setVideoHeight] = useState(0);
	const [
		showSellerRoomLeaveConfirmation,
		setShowSellerRoomLeaveConfirmation
	] = useState(false);
	const [
		showBuyerRoomLeaveConfirmation,
		setShowBuyerRoomLeaveConfirmation
	] = useState(false);
	const [openQueueContainer, setOpenQueueContainer] = useState(false);
	const [hostNickname, setHostNickname] = useState('');
	const [eventType, setEventType] = useState(null);
	const [breaks, setBreaks] = useState([]);
	const [additionalEndTimeInSeconds, setAdditionalEndTimeInSeconds] = useState(
		0
	);
	const [isCartOpen, setIsCartOpen] = useState(false);
	const [showPermissionDeniedModal, setShowPermissionDeniedModal] = useState(
		false
	);
	const [openedCartWindow, setOpenedCartWindow] = useState(null);
	const [viewPopupFadingOut, setViewPopupFadingOut] = useState(false);
	const [optionPopupFadingOut, setOptionPopupFadingOut] = useState(false);
	const [addToCartPopupFadingOut, setAddToCartPopupFadingOut] = useState(false);
	const [topArrowButtonPosition, setTopArrowButtonPosition] = useState(null);
	const [leftArrowButtonPosition, setLeftArrowButtonPosition] = useState(null);
	const [
		showEventNameInChatContainer,
		setShowEventNameInChatContainer
	] = useState(
		isMobileSize
			&& eventInformation
			&& eventInformation.showEventNameOnMobileByDefault
			? eventInformation.showEventNameOnMobileByDefault
			: false
	);
	const {
		createConversation,
		addParticipantToConversation,
		findConversationByChild
	} = useConversation();
	const [conversation, setConversation] = useState(null);
	const conversationContainerRef = useRef();
	const arrowButtonRef = useRef();
	const arrowButtonContainerRef = useRef();
	const [
		conversationContainerHeight,
		setConversationContainerHeight
	] = useState(0);
	const [landscapeMode, setLandscapeMode] = useState(false);
	const [evaluateConversationHeight, setEvaluateConversationHeight] = useState(
		false
	);
	const [portraitChatHeight, setPortraitChatHeight] = useState(0);
	const [showBuyCards, setShowBuyCards] = useState(false);
	const [enabledChatInput, setEnabledChatInput] = useState(!isMobileSize);
	const [logoExitClicked, setLogoExitClicked] = useState(false);
	const [videoOptions, setVideoOptions] = useState(null);
	const [purchasedProductsUserId, setPurchasedProductsUserId] = useState(null);
	const eventNamePopupRef = useRef();
	const roomRef = useRef();
	const [
		twilioReconnectOnEveryRefresh,
		setTwilioReconnectOnEveryRefresh
	] = useState(false);
	const [
		twilioConnectionHasBeenRefreshed,
		setTwilioConnectionHasBeenRefreshed
	] = useState(false);
	const [limitedChatMessages, setLimitedChatMessages] = useState(false);
	const [chatLimits, setChatLimits] = useState(0);
	const [searchInputActive, setSearchInputActive] = useState(false);
	const [virtualKeyboardVisible] = useVirtualAndroidKeyboardDetector(
		isAndroid,
		searchInputActive
	);
	const [twilioRoomIdentity, setTwilioRoomIdentity] = useState(null);
	const { setAttendees, setActiveView } = useContext(LiveEventDataContext);
	useEffect(() => {
		// bug on android: keeps scrolling up when keyboard is visible
		if (!virtualKeyboardVisible && window && isAndroid) {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
		}
	}, [virtualKeyboardVisible]);

	useEffect(() => {
		if (goToHome) {
			if (typeof window !== 'undefined') {
				if (loaded) {
					window.location.href = `${env.frontend_rul}event/${params.eventId}`;
				}
			}
		}
	}, [goToHome]);

	const showError = (message) => {
		toast.error(message, {
			position: 'top-center',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	};

	const changeProductQuantityToCart = async (product, quantity) => {
		if (product.quantity + quantity <= 0) {
			showError('You cannot choose a quantity of 0');
			return;
		}

		reserveProduct(params.eventId, product.id, quantity)
			.then((res) => {
				dispatch(removeCartItem(product));
				product.quantity += quantity;
				dispatch(addCartItem(product));
			})
			.catch((err) => {
				if (err.response) {
					const { isSlotAvailable } = err.response.data;
					if (!isSlotAvailable) {
						showError('Adding additional products is not possible');
					}
				}
			});
	};

	// Will be called when the screen orientation changes
	const onOrientationChange = (portrait = true) => {
		setLandscapeMode(!portrait);
	};

	const addProductToCart = (eventProduct, image, quantity) => {
		reserveProduct(params.eventId, eventProduct.eventProductId, quantity)
			.then((res) => {
				let { timerInSeconds: timer } = res.data.slot;
				if (timer == null) timer = 5;
				timer /= 60;

				const item = {
					id: eventProduct.eventProductId,
					eventName: eventInformation.event.eventName,
					quantity,
					image,
					price: eventProduct.product.salesPriceRipShip,
					eventId: params.eventId,
					isProduct: true,
					time: new Date(Date.parse(new Date()) + timer * 60 * 1000),
					title: `${eventProduct.product.productName}`
				};

				dispatch(addCartItem(item));
			})
			.catch((err) => {
				if (err.response) {
					defaultToastError(err.response.data.error);
				}
			});
	};

	const persistAnonymousToken = (token) => {
		localStorage.setItem(`anon-${params.eventId}`, token);
	};

	const initializeConversation = (
		eventHost,
		eventName,
		updateConversation = false
	) => {
		if (eventHost) {
			findConversationByChild('event_id', params.eventId, true).then(
				(existingConversation) => {
					if (!existingConversation) {
						createConversation(
							`Live Event - ${eventName}`,
							userId,
							true,
							true,
							{ live_event: true, event_id: params.eventId },
							false
						).then((newConversationId) => {
							getDjawnUser()
								.then((djawnUserRes) => {
									addParticipantToConversation(
										newConversationId,
										djawnUserRes.data.userId,
										false,
										{
											live_event: true
										}
									)
										.then(() => {
											initializeConversation(eventHost, eventName, true);
										})
										.catch((err) => {
											console.error(err);
										});
								})
								.catch(() => {
									initializeConversation(eventHost, eventName, true);
								});
						});
					} else {
						sessionStorage.setItem('current-chat-id', existingConversation.id);
						setConversation(existingConversation);
						if (updateConversation) {
							updateChatId(params.eventId, existingConversation.id).catch(
								(err) => {
									console.error(err);
								}
							);
						}
					}
				}
			);
		} else {
			findConversationByChild('event_id', params.eventId, true).then((c) => {
				sessionStorage.setItem('current-chat-id', c.id);
				if (c && userId) {
					addParticipantToConversation(c.id, userId, false, {
						live_event: true
					}).then(() => {
						setConversation(c);
					});
				} else if (c && !userId) {
					setConversation(c);
					getAuthenticatedUserId()
						.then((userRes) => {
							addParticipantToConversation(c.id, userRes.data, false, {
								live_event: true
							}).then(() => {
								setConversation(c);
							});
						})
						.catch((err) => {
							setConversation(c);
						});
				}
			});
		}
	};

	const joinRoomViaTokens = () => {
		getRoomTokens(params.eventId, tempNickname)
			.then((res) => {
				getEventAttendees();
				const { data } = res;
				setIsHost(data.owner);
				setIsRoomHost(data.owner);
				setVideoRoomToken(data.roomToken);
				setOwnerNickname(data.ownerNickname);
				setEventStartDate(data.eventDate);
				setEventName(data.eventName);
				setNickname(data.nickname);
				setEventDetails(data.details);
				setChannelSid(data.channelSid);
				setTwilioRoomIdentity(data.twilioRoomIdentity);

				if (data.anonymousToken && data.anonymousToken.length > 0) {
					// persist to localStorage
					persistAnonymousToken(data.anonymousToken);
				}

				const { videoSettings } = data;

				if (data && data.twilioReconnectOnEveryRefresh) {
					setTwilioReconnectOnEveryRefresh(data.twilioReconnectOnEveryRefresh);
				}

				if (data && data.limitChatMessages && data.limitChatMessages === true) {
					setLimitedChatMessages(true);
					const referenceTypeName = isMobileSize
						? 'LiveEventChatLimitMobile'
						: 'LiveEventChatLimitDesktop';
					const chatLimitReferenceData = getVideoSetting(
						videoSettings,
						referenceTypeName
					);
					if (chatLimitReferenceData) {
						setChatLimits(Number(chatLimitReferenceData.referenceDataValue));
					}
				}
				let videoStreamOptions = {
					desktop: {
						height: 400,
						width: 800
					},
					mobile: {
						height: 480,
						width: 640
					},
					fps: 24
				};

				if (data.owner) {
					videoStreamOptions = getVideoSettings(
						videoSettings,
						videoStreamOptions
					);
				}

				setVideoOptions(videoStreamOptions);

				const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

				const twilioConfig = {
					name: data.roomToken,
					audio: false,
					video: false,
					automaticSubscription: false,
					workaroundWebKitBug180748: true,
					workaroundWebKitBug1208516: false
				};

				if (data.owner) {
					twilioConfig.video = {
						width: videoStreamOptions.desktop.width,
						frameRate: 24,
						height: videoStreamOptions.desktop.height
					};

					twilioConfig.audio = true;
					twilioConfig.frameRate = videoStreamOptions.fps;
					twilioConfig.bandwidthProfile = {
						video: {
							mode: 'presentation',
							maxTracks: 10,
							dominantSpeakerPriority: 'standard',
							renderDimensions: {
								high: { height: 720, width: 1280 },
								standard: { height: 400, width: 800 },
								low: { height: 176, width: 144 }
							}
						}
					};
					twilioConfig.dominantSpeaker = true;
					twilioConfig.maxAudioBitrate = 16000;
					twilioConfig.preferredVideoCodecs = [
						{ codec: 'VP8', simulcast: true }
					];

					if (isMobile) {
						twilioConfig.video = {
							height: videoStreamOptions.mobile.height,
							frameRate: videoStreamOptions.fps,
							width: videoStreamOptions.mobile.width
						};
						twilioConfig.bandwidthProfile = {
							video: {
								mode: 'presentation',
								maxSubscriptionBitrate: 2500000,
								maxTracks: 5,
								dominantSpeakerPriority: 'standard',
								renderDimensions: {
									high: { height: 1080, width: 1920 },
									standard: { height: 720, width: 1280 },
									low: { height: 176, width: 144 }
								}
							}
						};
						twilioConfig.dominantSpeaker = true;
						twilioConfig.maxAudioBitrate = 16000;
						twilioConfig.preferredVideoCodecs = [
							{ codec: 'VP8', simulcast: true }
						];
					}
				}

				const logger = Logger.getLogger('twilio-video');

				const originalFactory = logger.methodFactory;
				logger.methodFactory = function (methodName, logLevel, loggerName) {
					const method = originalFactory(methodName, logLevel, loggerName);
					return function (datetime, logLevel, component, message, data) {
						const prefix = 'Djawn';
						method(prefix, datetime, logLevel, component, message, data);
					};
				};
				logger.setLevel('error');

				connect(data.roomToken, twilioConfig)
					.then((room) => {
						setLoaded(true);
						setRoom(room);
						initializeConversation(data.owner, data.eventName);
					})
					.catch((err) => {
						if (data.owner) {
							revertEventStartedStatus(params.eventId).catch((err) => {
								console.error(err);
							});
						}
						savedEventJoinFailure(params.eventId, err).then(() => {
							if (data.owner) {
								navigate('/', { state: { errorMessage: err.message } });
							} else if (window.location) {
								window.location.href = window.location.origin;
							} else {
								navigate('/');
							}
						});
						setLoaded(true);
					});
			})
			.catch((err) => {
				setLoaded(true);
			});
	};

	const onTwilioVideoReconnect = (settings = {}) => {
		if (
			(settings.overrideRefreshCheck
				&& settings.overrideRefreshCheck === true)
			|| (!twilioReconnectOnEveryRefresh
				&& roomRef
				&& roomRef.current
				&& roomRef.current.state
				&& roomRef.current.state === 'disconnected'
				&& !showThankYou)
			|| twilioReconnectOnEveryRefresh
		) {
			if (
				document
				&& document.body
				&& document.documentElement
				&& document.documentElement.scrollTop
			) {
				document.body.scrollTop = document.documentElement.scrollTop = 0;
			}
			const resolvePromise = new Promise((resolve, reject) => {
				setTwilioConnectionHasBeenRefreshed(true);
				const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

				const twilioConfig = generateTwilioVideoConfig(
					videoOptions,
					videoRoomToken,
					isHost,
					isMobile,
					settings.audio && settings.audio === true
				);

				setRoom(null);
				reissueVideoToken(params.eventId, nickname)
					.then((reissuedTokenRes) => {
						connect(reissuedTokenRes.data, twilioConfig)
							.then((reconnectedRoom) => {
								setRoom(reconnectedRoom);
								resolve();
							})
							.catch((err) => {
								if (settings.overrideRefreshCheck) {
									reject();
									if (settings.onFailure) {
										settings.onFailure();
										onTwilioVideoReconnect();
									}
								} else {
									resolve();
								}
								console.error(err);
							});
					})
					.catch((err) => {
						resolve();
						console.error(err);
					});
			});
			// TODO: Test reconnect on twilio
			toast.promise(
				resolvePromise,
				{
					pending: settings.pendingMessage
						? settings.pendingMessage
						: 'Please wait while we connect you to the event.',
					success: {
						render: () => (settings.successMessage
							? settings.successMessage
							: 'Connection to the event has been reestablished.'),
						autoClose: 1500,
						position: 'top-center',
						hideProgressBar: false,
						pauseOnHover: false,
						draggable: true,
						type: 'success'
					},
					error: {
						render: () => "You've blocked the mic access",
						autoClose: 1500,
						position: 'top-center',
						hideProgressBar: false,
						pauseOnHover: false,
						draggable: true,
						type: 'error'
					}
				},
				{
					position: 'top-center'
				}
			);
		}
	};

	const size = useWindowSize();

	const [authStatus, setAuthStatus] = useState(false);

	const updateWidthAndHeight = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', updateWidthAndHeight);
		setMobileSize(width < 911);
		return () => window.removeEventListener('resize', updateWidthAndHeight);
	});

	const onStartClicked = () => {
		startRoom(params.eventId, restart).then((r) => {
			setHasStarted(true);
		});
	};

	const eventStopped = (quit, isHost, onEventStillPlaying) => {
		isEventStopped(params.eventId)
			.then((res) => {
				if (
					res.data
					&& res.data.stopped === true
					&& res.data.additionalEndTimeInSeconds
					&& res.data.additionalEndTimeInSeconds > 0
				) {
					if (params.eventId === res.data.eventId) {
						setShowThankYou(true);
						setAdditionalEndTimeInSeconds(res.data.additionalEndTimeInSeconds);

						if (quit) {
							setGoToHome(true);
						}
					}
				} else if (onEventStillPlaying) {
					onEventStillPlaying();
				}
			})
			.catch((err) => {
				if (onEventStillPlaying) {
					onEventStillPlaying();
				}
			});
	};

	const onEventStopped = (seconds) => {
		if (seconds) {
			setShowThankYou(true);
			setAdditionalEndTimeInSeconds(seconds);
		}
	};

	useEffect(() => {
		if (size.width <= 910) {
			setReorganizeAccordion(true);
		} else {
			setReorganizeAccordion(false);
		}
	}, [size]);

	const getEventAttendees = (callback = null) => {
		getAttendees(params.eventId)
			.then((data) => {
				setAttendees(data.data);
				if (callback) {
					callback(data.data);
				}
			})
			.catch((err) => {
				const mute = err;
			});
	};

	useInterval(async () => {
		if (!isHost) await eventStopped(false, isHost);
	}, 100000);

	const saveTrack = (trackId, kind) => {
		let saveSubsribedRoomParam = nickname;
		let saveTrackMethod = saveSubscribedTrack;
		const accessToken = getAccessToken();
		if (!accessToken) {
			const anonToken = localStorage.getItem(`anon-${params.eventId}`);
			if (anonToken) {
				saveSubsribedRoomParam = anonToken;
				saveTrackMethod = saveSubscribedTrackAnonymousUser;
			}
		}

		saveTrackMethod(params.eventId, saveSubsribedRoomParam, trackId, kind);
	};

	const exitEventRoom = (xClicked, dontNavigate = false) => {
		// setLoaded(false);
		let setTrue = false;
		let exitRoomParam = nickname;
		let exitRoomMethod = exitRoom;
		const accessToken = getAccessToken();
		if (!accessToken) {
			const anonToken = localStorage.getItem(`anon-${params.eventId}`);
			if (anonToken) {
				exitRoomParam = anonToken;
				exitRoomMethod = exitRoomByAnonymousToken;
			}
		}
		setStopped(true);
		exitRoomMethod(params.eventId, exitRoomParam)
			.then((res) => {
				if (xClicked) {
					// if (isHost) {
					//     onStopClicked();
					//     setInterval(() => {
					//         setGoToHome(true);
					//     }, 3000);
					//     return;
					// }
					return;
				}
				setTrue = true;
				// setGoToHome(true);
			})
			.catch((err) => {
				const mute = err;
			})
			.finally(() => {
				if (isHost) {
					navigate('/', {
						state: {
							refresh: true,
							stopEventId: params.eventId,
							nickname
						}
					});
				} else {
					// eslint-disable-next-line no-lonely-if
					if (!dontNavigate) {
						if (window.location) {
							window.location.href = window.location.origin;
						} else {
							navigate('/');
						}
					}
				}
			});

		if (setTrue) {
			setShowThankYou(true);
		}
	};

	const onPauseClicked = () => {
		setIsPaused(!isPaused);
	};

	const onStopClicked = () => {
		fetch(`${env.api_url}api/event/stop/${params.eventId}`, {
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${getAccessToken()}`
			}
		}).then((res) => {
			setStopped(true);
			// window.location.reload(false);
		});
	};

	useEffect(() => {
		authState();
		eventStopped(true, isHost);
		if (isHost) {
			startRoom(params.eventId)
				.then((_) => {})
				.catch((_) => {})
				.finally((_) => {
					joinRoomViaTokens();
				});
		} else {
			joinRoomViaTokens();
		}
		// return () => {

		// }
	}, []);

	const authState = () => {
		setAuthStatus(isLoggedIn());
	};

	useEffect(() => {
		if (landscapeMode && isMobileSize && isTablet) {
			setEnabledChatInput(true);
		}
		if (!isMobileSize) {
			setShowBuyCards(true);
		}

		if (!window || !isMobileSize || !conversation || landscapeMode) return;
		setEnabledChatInput(false);
		if (!landscapeMode && portraitChatHeight && portraitChatHeight > 0) {
			setConversationContainerHeight(portraitChatHeight);
			setEnabledChatInput(true);
			return;
		}
		if (
			conversationContainerRef.current
			&& conversationContainerRef.current.getBoundingClientRect
		) {
			const rect = conversationContainerRef.current.getBoundingClientRect();
			if (rect) {
				if (!landscapeMode) {
					const height = window.innerHeight - rect.top - 7;
					if (!isAndroid) {
						setConversationContainerHeight(height);
						setPortraitChatHeight(height);
					}
					const timer = isAndroid ? 4000 : 2000;

					setTimeout(() => {
						setEnabledChatInput(true);
						if (
							arrowButtonRef
							&& arrowButtonRef.current
							&& arrowButtonRef.current.getBoundingClientRect
						) {
							const arrowButtonRect = arrowButtonRef.current.getBoundingClientRect();
							setLeftArrowButtonPosition(arrowButtonRect.left);
							setTopArrowButtonPosition(arrowButtonRect.top);
							// const height = window.innerHeight - arrowButtonRect.top - 50;
							// setConversationContainerHeight(height);
							// setPortraitChatHeight(height);
						}
						if (
							!openQueueContainer
							&& popups
							&& (popups.show_event_name === true
								|| typeof popups.show_event_name === 'undefined')
							&& dismissedPopups
							&& (dismissedPopups.show_event_name === true
								|| typeof popups.dismissedPopups === 'undefined')
						) {
							if (
								eventNamePopupRef
								&& eventNamePopupRef.current
								&& eventNamePopupRef.current.open
							) {
								eventNamePopupRef.current.open();
							}
						}
					}, timer);
				}
			}
		}

		// }, 1500);
	}, [
		// window,
		// isMobileSize,
		// conversation,
		evaluateConversationHeight,
		landscapeMode
	]);

	useEffect(() => {
		setTimeout(() => {
			if (
				popups
				&& popups.show_cards
				&& dismissedPopups
				&& dismissedPopups.show_cards
			) {
				if (
					arrowButtonContainerRef
					&& arrowButtonContainerRef.current
					&& arrowButtonContainerRef.current.open
				) {
					const arrowButtonRect = arrowButtonRef.current.getBoundingClientRect();
					setLeftArrowButtonPosition(arrowButtonRect.left);
					setTopArrowButtonPosition(arrowButtonRect.top);
					const height = window.innerHeight - arrowButtonRect.top - 50;
					setConversationContainerHeight(height);
					setPortraitChatHeight(height);
					arrowButtonContainerRef.current.open();
				}
			}
		}, 8000);
	}, [arrowButtonContainerRef]);

	useEffect(() => {
		if (
			eventInformation
			&& eventInformation.event
			&& eventInformation.event.seller
			&& eventInformation.event.seller.nickName
		) {
			setHostNickname(eventInformation.event.seller.nickName);
			setEventType(eventInformation.event.eventTypeId);
			setBreaks(eventInformation.breaks);
		}
	}, [eventInformation]);

	if (!loaded) {
		return (
			<div className="w-screen h-screen bg-gray absolute left-0 top-0">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}

	const updatePopupsStatus = (type = '', dismissed = false) => {
		switch (type) {
			case 'show_cards':
				if (
					arrowButtonContainerRef
					&& arrowButtonContainerRef.current
					&& arrowButtonContainerRef.current.close
				) {
					arrowButtonContainerRef.current.close();
				}
				break;
			case 'show_views':
				setViewPopupFadingOut(true);
				break;
			case 'show_addtocart_information':
				setAddToCartPopupFadingOut(true);
				break;
			case 'show_event_name':
				if (
					eventNamePopupRef
					&& eventNamePopupRef.current
					&& eventNamePopupRef.current.close
				) {
					eventNamePopupRef.current.close();
				}
				break;
			default:
				return;
		}
		setTimeout(() => {
			let tempPopups = {};
			if (dismissed) {
				tempPopups = { ...dismissedPopups };
			} else {
				tempPopups = { ...popups };
			}
			switch (type) {
				case 'show_cards':
					tempPopups.show_cards = false;
					break;
				case 'show_views':
					tempPopups.show_views = false;
					break;
				case 'show_addtocart_information':
					tempPopups.show_addtocart_information = false;
					break;
				case 'show_event_name':
					tempPopups.show_event_name = false;
					break;
				default:
					return;
			}
			if (dismissed) {
				setDismissedPopups(tempPopups);
			} else {
				setPopups(tempPopups);
			}
		}, 300);
	};

	return (
		<Layout>
			<div className="flex flex-col flex-1 dark:bg-black">
				{showPermissionDeniedModal && (
					<AccessDeniedMessage
						showModal={showPermissionDeniedModal}
						setShowModal={setShowPermissionDeniedModal}
						onExit={exitEventRoom}
					/>
				)}
				{isSeller ? (
					<SellerRoomLeaveConfirmation
						showModal={showSellerRoomLeaveConfirmation}
						setShowModal={setShowSellerRoomLeaveConfirmation}
						onExit={exitEventRoom}
						onCancel={() => setLogoExitClicked(false)}
					/>
				) : (
					<BuyerRoomLeaveConfirmation
						showModal={showBuyerRoomLeaveConfirmation}
						setShowModal={setShowBuyerRoomLeaveConfirmation}
						onExit={exitEventRoom}
						onCancel={() => setLogoExitClicked(false)}
					/>
				)}
				{!resize && (
					<RoomHeader
						siteTitle="Room"
						auth={authStatus}
						exitRoom={exitEventRoom}
						landscapeMode={landscapeMode}
						eventId={params.eventId}
						showCart
						cartOnClick={() => {
							window.open(`${window.location.origin}/?openCart=true`, '_blank');
						}}
						cartItemCount={() => {
							let total = 0;
							cartItems.forEach((cartItem) => {
								if (cartItem.quantity) {
									total += cartItem.quantity;
								} else {
									total += 1;
								}
							});
							return total;
						}}
						isRipNShip={
							eventInformation
							&& eventInformation.event
							&& eventInformation.event.eventTypeId == RIP_N_SHIP
						}
					/>
				)}
				<MiniCart
					isOpen={isCartOpen}
					showError={(item) => {
						showError(item);
					}}
					removeItemDispatch={(item) => {
						dispatch(removeCartItem(item));
					}}
					updateTimerDispatch={(item) => {
						dispatch(updateCartItemTimer(item));
					}}
					onPurchased={() => {
						setIsCartOpen(!isCartOpen);
					}}
					showThankYou
					cartItemLength={() => {
						let total = 0;
						cartItems.forEach((cartItem) => {
							if (cartItem.quantity) {
								total += cartItem.quantity;
							} else {
								total += 1;
							}
						});
						return total;
					}}
					addProductQuantityToCart={(product) => {
						changeProductQuantityToCart(product, 1);
					}}
					decreaseProductQuantityFromCart={(product) => changeProductQuantityToCart(product, -1)}
					closeCart={() => setIsCartOpen(!isCartOpen)}
					items={cartItems}
					liveEvent
				/>
				{openQueueContainer
					&& popups
					&& popups.show_views
					&& dismissedPopups
					&& dismissedPopups.show_views && (
					<div
						className={`flex justify-end flex-col items-end pr-2 absolute signin-popup${
							viewPopupFadingOut ? '-fade-out' : ''
						} ml-1`}
						style={
							isMobileSize
								? {
									zIndex: '60',
									top: '2.8rem',
									right: '9%'
									  }
								: { zIndex: '60', top: '4.9rem', left: '5.7rem' }
						}
					>
						<div className="triangle-up mr-4" style={{ zIndex: '60' }} />

						<div
							className="w-64 md:w-80 border rounded"
							style={{
								backgroundColor: '#0c6ae6',
								borderColor: '#0c6ae6',
								zIndex: '60'
							}}
						>
							<h3
								className="text-white text-sm pt-4 pl-3"
								style={{ zIndex: '60' }}
							>
									Click here to see more options.
							</h3>
							<div
								className="flex justify-between px-3 pt-3 mb-1"
								style={{ zIndex: '60' }}
							>
								<p
									className="cursor-pointer text-white text-md"
									onClick={() => updatePopupsStatus('show_views', true)}
									onKeyDown={() => updatePopupsStatus('show_views', true)}
								>
										Dismiss
								</p>
								<p
									className="cursor-pointer text-white text-md"
									onClick={() => updatePopupsStatus('show_views', false)}
									onKeyDown={() => updatePopupsStatus('show_views', false)}
								>
										Don't show again
								</p>
							</div>
						</div>
					</div>
				)}
				{popups
					&& popups.show_cards
					&& dismissedPopups
					&& dismissedPopups.show_cards
					&& showBuyCards
					&& !isMobileSize && (
					<div
						className={`flex justify-end flex-col items-start pr-2 md:left-0 absolute ${
							mobileSize ? 'buy-cards-popup' : 'signin-popup'
						}${optionPopupFadingOut ? '-fade-out' : ''} ml-0 md:ml-1`}
						style={{
							left:
									mobileSize && `${Math.abs(leftArrowButtonPosition - 3)}px`,
							zIndex: '60',
							top: mobileSize
								? `${topArrowButtonPosition + 5}px`
								: 'calc(50% - 14rem)'
						}}
					>
						{mobileSize && (
							<div
								className="triangle-up ml-0 md:ml-3"
								style={{ zIndex: '60' }}
							/>
						)}
						<div
							className="w-80 border rounded md:mt-0 -mt-1"
							style={{
								backgroundColor: '#0c6ae6',
								borderColor: '#0c6ae6',
								zIndex: '60'
							}}
						>
							<h3
								className="text-white text-sm pt-4 pl-3"
								style={{ zIndex: '60' }}
							>
									Click here to buy cards.
							</h3>
							<div
								className="flex justify-between px-3 pt-3 mb-1"
								style={{ zIndex: '60' }}
							>
								<p
									className="cursor-pointer text-white text-md"
									onClick={() => updatePopupsStatus('show_cards', true)}
									onKeyDown={() => updatePopupsStatus('show_cards', true)}
								>
										Dismiss
								</p>
								<p
									className="cursor-pointer text-white text-md"
									onClick={() => updatePopupsStatus('show_cards', false)}
									onKeyDown={() => updatePopupsStatus('show_cards', false)}
								>
										Don't show again
								</p>
							</div>
						</div>
						{!mobileSize && (
							<div
								className="triangle-bottom ml-3"
								style={{ zIndex: '60' }}
							/>
						)}
					</div>
				)}
				<div className="mx-auto w-full h-full relative">
					{!showPermissionDeniedModal
						&& ((!mobileSize && (!isTablet || (isTablet && landscapeMode)))
							|| openQueueContainer) && (
						<>
							<div
								className="animate-ping fixed inline-flex items-center justify-center duration-1000 bg-djawn text-gray-100 rounded-full focus:shadow-outline hover:bg-djawn-300"
								style={{
									top: mobileSize
										? `${topArrowButtonPosition + 6}px`
										: 'calc(50% - 3.3rem)',
									outline: 'none',
									left: mobileSize ? '7px' : '23px',
									width: '1.6rem',
									height: '1.6rem'
								}}
							/>
							<button
								type="button"
								className="fixed left-0 md:left-4 inline-flex items-center justify-center w-10 h-10 mr-2 text-indigo-100 transition-colors duration-150 bg-djawn rounded-full focus:shadow-outline hover:bg-djawn-300
						bg-opacity-50"
								style={{
									top: mobileSize
										? `${topArrowButtonPosition}px`
										: 'calc(50% - 60px)',
									outline: 'none',
									zIndex: '60'
								}}
								onClick={() => {
									updatePopupsStatus('show_cards', true);
									setOpenQueueContainer(!openQueueContainer);
								}}
							>
								{openQueueContainer ? (
									<svg
										className="w-4 h-4 fill-current"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M9 5l7 7-7 7"
										/>
									</svg>
								) : (
									<svg
										className="w-4 h-4 fill-current"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M15 19l-7-7 7-7"
										/>
									</svg>
								)}
							</button>
						</>
					)}

					{loaded == true && videoRoomToken && !showPermissionDeniedModal ? (
						<TwilioTrackProvider
							eventId={params.eventId}
							isHost={isHost}
							twilioRoomIdentity={twilioRoomIdentity}
						>
							<VideoStream
								authStatus={authStatus}
								setSearchInputActive={setSearchInputActive}
								openQueueContainer={openQueueContainer}
								isMobile={isMobileSize}
								onTwilioVideoReconnect={onTwilioVideoReconnect}
								room={room}
								landscapeMode={landscapeMode}
								eventNamePopupRef={eventNamePopupRef}
								eventInformation={eventInformation}
								removeChildrenContainerHeight={
									conversationContainerHeight > 0 && isMobileSize
								}
								roomName={videoRoomToken}
								roomRef={roomRef}
								token={videoRoomToken}
								setHasRaisedHand={setHasRaisedHand}
								additionalEndTimeInSeconds={additionalEndTimeInSeconds}
								isHost={isHost}
								isSeller={isSeller}
								ownerNickname={ownerNickname}
								eventName={eventName}
								eventDetails={eventDetails}
								breaks={breaks}
								eventId={params.eventId}
								purchasedProductsUserId={purchasedProductsUserId}
								setPurchasedProductsUserId={setPurchasedProductsUserId}
								onLogoExitClosed={() => setLogoExitClicked(false)}
								onStartClicked={onStartClicked}
								onPauseClicked={onPauseClicked}
								onStopClicked={onStopClicked}
								arrowButtonRef={arrowButtonRef}
								isPaused={isPaused}
								showThankYou={showThankYou}
								saveTrack={saveTrack}
								isStopped={stopped}
								reorganizeAccordion={reorganizeAccordion}
								logoExitClicked={logoExitClicked}
								mobileSize={mobileSize}
								eventStartDate={eventStartDate}
								setEventStartDate={setEventStartDate}
								onOrientationChange={onOrientationChange}
								onOpenQueueContainer={(
									arrowButtonTop,
									pulsingArrowButtonTop
								) => {
									updatePopupsStatus('show_cards', true);
									if (isAndroid && !openQueueContainer == true) {
										if (window) {
											document.body.scrollTop = document.documentElement.scrollTop = 0;
										}
									}

									setOpenQueueContainer(!openQueueContainer);
								}}
								setRoom={setRoom}
								showAddToCartPopup={
									popups
									&& popups.show_addtocart_information
									&& dismissedPopups
									&& dismissedPopups.show_addtocart_information
								}
								onProductNamePopupClose={(dismissed) => {
									updatePopupsStatus('show_addtocart_information', dismissed);
								}}
								addToCartPopupFadingOut={addToCartPopupFadingOut}
								exitEventRoom={(
									addUsersInNewEvent,
									skipConfirmation = false
								) => {
									if (isHost) {
										if (addUsersInNewEvent === true) {
											addBuyersToNextLineInFutureEvent(params.eventId).catch(
												(err) => {
													console.error(err);
												}
											);
										}
										if (skipConfirmation) {
											exitEventRoom();
										} else {
											setShowSellerRoomLeaveConfirmation(true);
										}
									} else if (cartItems.length > 0) {
										setShowBuyerRoomLeaveConfirmation(true);
									} else {
										exitEventRoom();
									}
								}}
								addProductToCart={addProductToCart}
								cartItems={cartItems}
								changeProductQuantityToCart={changeProductQuantityToCart}
								setVideoHeight={setVideoHeight}
								setEventInformation={setEventInformation}
								onEventStopped={onEventStopped}
								resize={resize}
								setResize={setResize}
								eventStopped={eventStopped}
								arrowButtonContainerRef={arrowButtonContainerRef}
								fetchAttendees={getEventAttendees}
								openedCartWindow={openedCartWindow}
								updatePopupsStatus={updatePopupsStatus}
								setOpenQueueContainer={setOpenQueueContainer}
								setLeftArrowButtonPosition={setLeftArrowButtonPosition}
								setTopArrowButtonPosition={setTopArrowButtonPosition}
								showEventNameInChatContainer={showEventNameInChatContainer}
								setShowEventNameInChatContainer={
									setShowEventNameInChatContainer
								}
							>
								<div
									className={`w-full h-full relative ${
										landscapeMode && !isTablet ? 'hidden' : 'block'
									}`}
								>
									<div
										className={`
								row-span-1 md:col-span-1 w-full dark:bg-black ${
						mobileSize ? 'mb-1 h-auto' : 'h-full'
						}`}
										ref={conversationContainerRef}
									>
										<div>
											{!resize && conversation ? (
												<div
													style={{
														height:
															mobileSize || isTablet || videoHeight == 0
																? '100%'
																: `${videoHeight + 10}px`,
														marginTop: '5px'
													}}
												>
													<div
														className={`${
															conversationContainerHeight > 0
															&& ((isMobileSize && !isTablet)
																|| (isTablet && !landscapeMode))
																? ''
																: isMobileSize && !isTablet
																	? 'chat-container-mobile'
																	: isTablet && !landscapeMode
																		? 'tablet-portait-live-event-video-height'
																		: 'chat-desktop-height'
														}  dark:text-white dark:bg-black`}
														id="chat-container-fx"
														style={
															conversationContainerHeight > 0
															&& ((isMobileSize && !isTablet)
																|| (isTablet && !landscapeMode))
																? { height: `${conversationContainerHeight}px` }
																: {}
														}
													>
														<Conversation
															startedConversation={conversation}
															showConversations
															removeToastContainer
															hideSidebar
															enabledChatInput={enabledChatInput}
															removeHiddenOverflows
															hideHeader
															enableMentions
															onMessagesLoaded={() => {
																setEvaluateConversationHeight(
																	!evaluateConversationHeight
																);
															}}
															absoluteEditorPosition={mobileSize}
															removeListsFromToolbar
															limitedChatMessages={limitedChatMessages}
															chatLimits={chatLimits}
															additionalStyles={{
																borderTopRightRadius: '35px',
																borderTopLeftRadius: '25px',
																borderBottomRightRadius: '25px',
																borderBottomLeftRadius: '25px'
															}}
															showScreenName
															hideAttachmentsButton
															reduceReplyMessageSize
															disableReplying
															authenticated={authStatus}
															removeDayDivider
															onRedirectToSignIn={() => {
																exitEventRoom(false, true);
																navigate('/signin', {
																	state: {
																		redirectUrl: `/event/room/${params.eventId}`
																	}
																});
															}}
															useInlineInputEditor
															insideLiveEvent
															onPurchasedItemsHyperlinkClicked={(userId) => {
																if (userId) {
																	setPurchasedProductsUserId(userId);
																}
															}}
															onRandomizationHyperlinkClicked={() => {
																setActiveView(RANDOMIZED_BREAKS_VIEW);
																setOpenQueueContainer(true);
															}}
														>
															{showEventNameInChatContainer ? (
																<ConversationEventName
																	eventName={eventName}
																	closeEventName={() => setShowEventNameInChatContainer(false)}
																/>
															) : null}
														</Conversation>
													</div>
												</div>
											) : null}
										</div>
									</div>
								</div>
							</VideoStream>
						</TwilioTrackProvider>
					) : null}
				</div>
			</div>
		</Layout>
	);
};
export default ReduxConnect(
	(state) => ({
		cartItems: state.cart.cartItems,
		userId: state.utils.userId
	}),
	null
)(Room);
