/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { GoogleLogin } from 'react-google-login';
// import { FacebookLogin } from 'react-facebook-login-component';
// import { FacebookProvider, LoginButton, Login } from 'react-facebook';
import { toast } from 'react-toastify';
import {
 getCurrentUser, shouldRedirectToSignIn, refreshGoogleAuthTokenSetup, logout
} from '../../utils/auth';
import env from '../../../environment.config';
import {
	getFacebookUserDataAccessTokenFromCode
} from '../../api/auth.request';

const FacebookGoogleAuth = ({
	buttonTitle, onButtonClicked, onReAuth, authUser, onError
}) => {
	const [authenticating, setAuthenticating] = useState(false);
	const [loading, setLoading] = useState(false);
	const [userFound, setUserFound] = useState(false);
	const [userName, setUserName] = useState('');
	const [userEmail, setUserEmail] = useState('');
	const [userPic, setUserPic] = useState('');
	const currentUrl = window.location.href.split('?')[0];

	const authState = async () => {
		const currentUser = getCurrentUser();
		if (typeof currentUser != 'undefined') {
			setUserName(currentUser.name);
			setUserEmail(currentUser.email);
			setUserPic(currentUser.pic);
			setLoading(true);
			setUserFound(true);
			if (Object.keys(currentUser).length <= 0) {
				setLoading(false);
				setUserFound(false);
			}
		} else {
			setLoading(false);
			console.log('No User Found--2');
		}
	};

	useEffect(() => {
		if (shouldRedirectToSignIn() == true) {
			localStorage.removeItem('gatsbyUser');
		}
		authState();
	}, []);

	const onSwitchAccount = () => {
		setLoading(!loading);
	};
	const onRefreshAccessToken = async () => {
		onButtonClicked();
		onReAuth();
		// let res_auth_state = await refreshToken();
		// if(res_auth_state.status === 200){
		//     onReAuth()
		// }else{
		//     onError()
		// }
	};
	const customFacebookResponse = (code, url) => {
		getFacebookUserDataAccessTokenFromCode(code, url)
			.then((response) => {
				console.log(response, 'response');
				console.log(response.data.accessToken, 'accessToken');
				if (response.status === 200) {
					console.log(response.data.accessToken, 'accessToken1');
					if (response.data) {
						if (response.data.accessToken.length > 25) {
							const { userData } = response.data;
							const facebookResponse = userData;
							facebookResponse.accessToken = response.data.accessToken;
							console.log(facebookResponse);
							authUser(facebookResponse, response.data.accessToken);
						} else {
							setLoading(false);
							toast.error('⚠️ Login Failed! Please try again.', {
								position: 'top-center',
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined
							});
						}
					}
				}
			})
			.catch((err) => {
				console.error(err);
				onError();
			})
			.finally(() => {
				setAuthenticating(false);
			});
	};

	const responseGoogle = (res) => {
		refreshGoogleAuthTokenSetup(res);
		authUser(res, res.tokenId, 'google');
	};

	const failureGoogle = (res) => {
		console.error(res);
	};

	useEffect(() => {
		logout();
		const code = new URLSearchParams(window.location.search).get('code');
		console.log(code, 'code');
		if (code) {
			setAuthenticating(true);
			customFacebookResponse(code, currentUrl);
		}
	}, []);

	return (
		<div>
			<div className="bg-white py-8 pt-2 px-10 text-center max-w-full mx-auto">
				{loading && (
					<div>
						<img className="w-20 h-20 object-cover rounded-full mx-auto shadow-lg" src={userPic} alt="User avatar" />
						<h2 className="font-semibold text-xl">{userName}</h2>
						<p className="lowercase text-base">{userEmail}</p>
						<span className="flex items-center border rounded-full w-24 pr-2 justify-center mx-auto mt-2 mb-4">
							<div className="bg-green-400 rounded-full w-2.5 h-2.5 block mr-2" />
Active
						</span>
						<button type="button" className="w-4/6 mx-auto flex items-center justify-center px-4 py-1 mb-4 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-900 md:py-2 md:text-lg md:px-6" onClick={onRefreshAccessToken}>
Continue As
							{userName}
						</button>
					</div>
				)}
				{loading && (
					<button type="button" className="w-4/6 mx-auto flex items-center justify-center px-4 py-1 mb-4 border-2 border-gray-400 hover:border-gray-700 text-base font-medium rounded-md text-gray-700 hover:text-white bg-white hover:bg-gray-700 md:py-2 md:text-lg md:px-6" onClick={onSwitchAccount}>Switch Account</button>
				)}
				{!loading && (
					<>
						<button
							type="button"
							disabled={authenticating}
							className={`w-4/6 mx-auto flex items-center justify-center px-6 py-2 mb-4 border border-transparent text-base font-medium rounded-md text-white ${
								authenticating ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-900'
							} md:py-2 md:text-lg md:px-6`}
							onClick={async () => {
								window.open(`https://www.facebook.com/v14.0/dialog/oauth?client_id=${env.fb_app_id}&redirect_uri=${currentUrl}`, '_self');
							}}
						>
							{buttonTitle}
						</button>
						<GoogleLogin
							clientId={env.google_client_id}
							onSuccess={responseGoogle}
							onFailure={failureGoogle}
							cookiePolicy="single_host_origin"
							// isSignedIn={true}
							buttonText="Sign in With Google"
							className="w-4/6 mx-auto flex items-center justify-center px-6 py-2 mb-4 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-900 md:py-2 md:text-lg md:px-6"
						/>
					</>
				)}
				{!loading && userFound && (
					<button type="button" className="w-4/6 mx-auto flex items-center justify-center px-4 py-1 mb-4 border-2 border-gray-400 hover:border-gray-700 text-base font-medium rounded-md text-gray-700 hover:text-white bg-white hover:bg-gray-700 md:py-2 md:text-lg md:px-6" onClick={onSwitchAccount}>Switch Account</button>
				)}
			</div>

		</div>
	);
};

export default FacebookGoogleAuth;
