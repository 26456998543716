import React, { useEffect } from 'react';

const ConversationUserCard = ({
	value, name, image, onItemRemoved
}) => {
	useEffect(() => {}, []);

	return (
		<div className="flex flex-none items-center border rounded-2xl">
			<img
				// src="https://i.imgur.com/8Km9tLL.jpg"
				src={image}
				alt="foto"
				className="w-10 h-10 mr-3 border rounded-2xl"
			/>
			<div className="flex flex-col">
				<div className="flex items-center text-center">
					<span className="font-bold text-md mr-2 font-sans">{name}</span>
					<button
						type="button"
						className="focus:outline-none text-black hover:text-black text-lg p-3 rounded-md rounded-tr-none rounded-br-none"
						onClick={() => {
							onItemRemoved(value);
						}}
					>
						<div className="relative">
							<svg
								className="w-5 h-4"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</div>
					</button>
				</div>
			</div>
		</div>
	);
};

export default ConversationUserCard;
