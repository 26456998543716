import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { hideProductSavedNotification } from '../../../state/action';
import Modal from '../../../components/modals';

const AddedProductModal = ({ savedProductNotificationMessage, dispatch }) => {
	const [showModal, setShowModal] = useState(false);

	const onClose = () => {
		setShowModal(false);
		dispatch(hideProductSavedNotification());
	};

	useEffect(() => {
		if (savedProductNotificationMessage) {
			setShowModal(true);
		}
	}, [savedProductNotificationMessage]);

	return (
		<Modal showModal={showModal} onClose={onClose} hideCloseButton>
			<div className="flex justify-center items-center h-24">
				<h4>{savedProductNotificationMessage}</h4>
			</div>
		</Modal>
	);
};

export default connect(
	(state) => ({
		savedProductNotificationMessage:
			state.product.savedProductNotificationMessage
	}),
	null
)(AddedProductModal);
