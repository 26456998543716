import React, {
	Fragment, useRef, useState, useEffect
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { favoriteSeller, isSellerFavorite } from '../../api/seller.request';

const StoreModal = ({
	isOpen, setIsOpen, store, image, onUpdate
}) => {
	const [isFavorite, setIsFavorite] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const cancelButtonRef = useRef(null);

	useEffect(() => {
		if (!store) return;

		isSellerFavorite(store.accountId)
			.then((res) => {
				setIsFavorite(res.data);
			})
			.catch((err) => {
				// eslint-disable-next-line no-unused-vars
				const mute = err;
			})
			.finally(() => {
				setLoaded(true);
			});
	}, [store]);

	if ((!store && !image) || !loaded) return null;

	const saveFavoriteSeller = () => {
		favoriteSeller(store.accountId).then(() => {
			setIsFavorite((favorite) => !favorite);
			if (onUpdate) {
				onUpdate();
			}
		});
	};

	return (
		<Transition.Root show={isOpen} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-50 inset-0 overflow-y-auto"
				initialFocus={cancelButtonRef}
				open={isOpen}
				onClose={setIsOpen}
			>
				<div className="flex items-center md:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay
							className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
							style={{ zIndex: '1040!important' }}
						/>
					</Transition.Child>

					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						style={{ zIndex: '1050!important' }}
					>
						<div className="inline-block fixed md:relative bg-opacity-100 align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
							<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative">
								<div className="sm:flex sm:items-start">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<div className="flex flex-col md:flex-row items-center ">
											<div>
												<img
													alt="store"
													src={image}
													className="border rounded-lg shadow-md store-popup-image"
												/>
												<div className="flex justify-center items-center pt-3">
													<button
														type="button"
														className="outline-none focus:outline-none px-2"
														onClick={saveFavoriteSeller}
													>
														{isFavorite ? (
															<svg
																className="w-8 h-8"
																fill="red"
																stroke="currentColor"
																viewBox="0 0 24 24"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	strokeWidth="2"
																	d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
																/>
															</svg>
														) : (
															<svg
																className="w-8 h-8"
																fill="none"
																stroke="currentColor"
																viewBox="0 0 24 24"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	strokeWidth="2"
																	d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
																/>
															</svg>
														)}
													</button>
													<a
														href={`mailto:${store.email}`}
														className="outline-none focus:outline-none px-2"
														style={{
															pointerEvents: 'none',
															cursor: 'default'
														}}
													>
														<svg
															className="w-8 h-8"
															fill="#f2df61"
															stroke="currentColor"
															viewBox="0 0 24 24"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth="2"
																d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
															/>
														</svg>
													</a>
												</div>
											</div>
											<div className="mt-1 relative w-full px-3 py-2 text-gray-700 focus:outline-none">
												<h3>
													<u>
														<b>{store.companyName}</b>
													</u>
												</h3>
												<div className="flex items-center">
													<div>
														<p>
															{store.mailingCity}
,
															{store.mailingState}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<button
									type="button"
									className="absolute px-3 pt-3 text-gray-500 top-0 right-0 cursor-pointer outline-none border-none focus:outline-none"
									onClick={() => {
										setIsOpen(false);
									}}
								>
									X
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default StoreModal;
