import React, { createContext } from 'react';
import useLiveEventData from '../hooks/useLiveEventData';

const LiveEventDataContext = createContext();

const LiveEventDataProvider = ({
	eventTypeId,
	eventId,
	breakTemplateId,
	isHost,
	children
}) => {
	const {
		views,
		activeView,
		setActiveView,
		isRandomizedBreak,
		isRipNShip,
		isPickYourSlotsBreak,
		teamsRandomization,
		loadTeamsRandomization,
		setAttendees,
		attendees
	} = useLiveEventData(eventTypeId, eventId, breakTemplateId);

	return (
		<LiveEventDataContext.Provider
			value={{
				views,
				activeView,
				setActiveView,
				isRandomizedBreak,
				isRipNShip,
				isPickYourSlotsBreak,
				teamsRandomization,
				loadTeamsRandomization,
				setAttendees,
				attendees,
				isHost
			}}
		>
			{children}
		</LiveEventDataContext.Provider>
	);
};

export { LiveEventDataContext, LiveEventDataProvider };
