import React from 'react';
import { useStepLabelStyles } from './styles';

const StepLabel = ({ children, fontSize, fontWeight }) => {
	const classes = useStepLabelStyles({ fontSize, fontWeight });

	return (
		<div id="RFS-LabelContainer" className={classes.LabelContainer}>
			<span id="RFS-Label" className={classes.Label}>
				{children}
			</span>
		</div>
	);
};

export default StepLabel;
