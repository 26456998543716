import React, { useState, useEffect } from 'react';

import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import SEO from './seo';

import logo from '../images/djawn_logo.png';

const Header = ({ siteTitle, auth }) => {
	const [loaded, setLoaded] = useState(false);
	const [logoUrl, setLogoUrl] = useState('/');

	const location = useLocation();

	useEffect(() => {
		switch (location.pathname) {
			case '/login':
				setLogoUrl('/');
				break;
			case '/payment-error':
				setLogoUrl('/');
				break;
			case '/thank-you':
				setLogoUrl('/');
				break;
			case '/privacy':
				setLogoUrl('/');
				break;
			case '/our-policies':
				setLogoUrl('/');
				break;
			case '/terms-and-conditions':
				setLogoUrl('/');
				break;
			case '/shipping-policy':
				setLogoUrl('/');
				break;
			case '/refund-policy':
				setLogoUrl('/');
				break;
			case '/randomization-policy':
				setLogoUrl('/');
				break;
			case '/faq':
				setLogoUrl('/');
				break;
			default:
				setLogoUrl('/');
		}
	}, []);

	useEffect(() => {
		setLoaded(true);
	}, []);

	if (!loaded) {
		return (
			<></>
		);
	}
	return (
		<header className="flex w-full sticky top-0 z-50">
			<SEO title={siteTitle} />
			<nav className="w-full bg-white border-b">
				<div className=" mx-auto px-4 sm:px-6 lg:px-8">
					<div className="flex items-center justify-center h-16">
						<div className="flex items-center">
							<div className="flex-shrink-0">
								<Link to={logoUrl}>
									<img
										className="h-8 w-auto sm:h-10 cursor-pointer"
										src={logo}
										alt="Workflow"
									/>
								</Link>
							</div>
						</div>

						{!auth && (
							<div className="flex flex-grow" />
						)}

						{!auth && (
							<div className="flex items-center justify-center">
								<div className="flex items-center">
									<Link to="/">
										<button
											type="button"
											className="p-1 text-gray-900 hover:text-gray-400 focus:outline-none "
										>
											<span className="">Sign In</span>
										</button>
									</Link>
								</div>
							</div>
						)}

					</div>
				</div>

			</nav>
		</header>
	);
};

Header.propTypes = {
	siteTitle: PropTypes.string,
	auth: PropTypes.bool
};

Header.defaultProps = {
	siteTitle: '',
	auth: false
};

export default Header;
