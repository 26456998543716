import React from 'react';
import PropTypes from 'prop-types';

const Layout = ({ children }) => (
	<div className="flex flex-col flex-1 items-center justify-center h-full min-h-screen w-full">
		<main className="flex flex-1 w-full bg-gray-100">{children}</main>
	</div>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
