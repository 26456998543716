import React from 'react';
import { isMobile } from 'react-device-detect';
import Slider from 'react-slick';

const BreakTemplateList = ({
	templates,
	templatesSliderSize,
	createPlaceholder,
	onCreate,
	onTemplateClick,
	templateId,
	image,
	showDefaultImage,
	createNewTemplate,
	noTemplatesText = 'No User Templates were Found'
}) => (
	<div>
		<Slider
			slidesToShow={templatesSliderSize}
			slidesToScroll={isMobile ? templatesSliderSize : templatesSliderSize / 2}
			arrows
			infinite={false}
		>
			{createPlaceholder ? (
				<div>
					<div
						onClick={() => {
							if (onCreate) {
								onCreate();
							}
						}}
						onKeyDown={() => {
							if (onCreate) {
								onCreate();
							}
						}}
						className={
							createNewTemplate
								? 'cursor-pointer bg-gray-100 h-32 p-2 text-center transition  duration-500 ease-in-out'
								: 'cursor-pointer h-32 p-2 text-center transition  duration-500 ease-in-out'
						}
					>
						<svg
							cwidth="65pt"
							height="65pt"
							fill="none"
							stroke="currentColor"
							style={{
								display: '-webkit-inline-box'
							}}
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>
						<div className="text-center py-2">
							<h1 className="text-xs">Create Your Own</h1>
						</div>
					</div>
				</div>
			) : null}
			{!isMobile && (!templates || !templates.length) ? (
				<p>{noTemplatesText}</p>
			) : null}

			{templates.map((template, i) => (
				<div key={i}>
					<div
						onClick={() => onTemplateClick(template)}
						onKeyDown={() => onTemplateClick(template)}
						className={
							templateId == template.breakTemplateId
								? 'bg-gray-100 p-2 h-32 text-center transition  duration-500 ease-in-out'
								: 'h-32 p-2 text-center transition  duration-500 ease-in-out'
						}
						style={{
							width: 'max-content',
							marginRight: 'auto',
							marginLeft: 'auto'
						}}
						title={template.templateName}
					>
						{template.icon || image ? (
							<img
								alt="seller"
								className="h-8 w-auto sm:h-10 cursor-pointer"
								style={{
									display: '-webkit-inline-box',
									width: '65pt',
									height: '65pt'
								}}
								src={showDefaultImage ? image : template.icon}
							/>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="65pt"
								height="65pt"
								viewBox="0 0 957.599 957.6"
								version="1.1"
								style={{
									display: '-webkit-inline-box'
								}}
							>
								<g>
									<path
										d="M817.9,108.4h-28v687.901c0,45.699-37.2,82.898-82.899,82.898H423.3H197.7v25.5c0,29.201,23.7,52.9,52.9,52.9h283.6H817.8
									c29.2,0,52.899-23.699,52.899-52.9V161.3C870.7,132.1,847.1,108.4,817.9,108.4z"
									/>
									<path
										d="M423.3,849.199h283.6c29.2,0,52.9-23.699,52.9-52.898V108.4V52.9c0-29.2-23.7-52.9-52.9-52.9H423.3H329v17.5
									c0.199,1.8,0.3,3.7,0.3,5.6v115.3V168c0,41.1-33.4,74.5-74.5,74.5h-29.6H109.9c-1.5,0-3.1-0.1-4.6-0.2H86.9v554.001
									c0,29.199,23.7,52.898,52.9,52.898h58H423.3L423.3,849.199z M434,669.4H249.1c-13.8,0-25-11.201-25-25c0-13.801,11.2-25,25-25h185
									c13.8,0,25,11.199,25,25C459.1,658.199,447.8,669.4,434,669.4z M619,541.801H249.1c-13.8,0-25-11.201-25-25c0-13.801,11.2-25,25-25
									H619c13.8,0,25,11.199,25,25C644,530.6,632.8,541.801,619,541.801z M249.1,356.3H619c13.8,0,25,11.2,25,25c0,13.8-11.2,25-25,25
									H249.1c-13.8,0-25-11.2-25-25C224.1,367.5,235.3,356.3,249.1,356.3z"
									/>
									<path
										d="M109.9,212.5h144.9c0.1,0,0.3,0,0.4,0c24.2-0.2,43.8-19.8,44-44c0-0.1,0-0.3,0-0.4v-145c0-13.4-11-22.3-22.399-22.3
									c-5.5,0-11,2-15.6,6.6L94.1,174.5C80.1,188.5,90,212.5,109.9,212.5z"
									/>
								</g>
							</svg>
						)}
						<div className="text-center p-2 h-10">
							<h1
								className="text-xs"
								style={{
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									width: '65pt'
								}}
							>
								{template.templateName}
							</h1>
						</div>
					</div>
				</div>
			))}
		</Slider>
	</div>
);

export default BreakTemplateList;
