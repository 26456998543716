/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { createLocalVideoTrack } from 'twilio-video';
import { isFunction } from '../../utils/isFunction';
import { MicOffIcon, MicIcon } from './mic-icon';
import { VideoOnIcon, VideoOffIcon, ResizeIcon } from './video-icon';
import { SoundOffIcon, SoundOnIcon } from './sound-icon';
import { useInterval } from '../common/use-interval';
import { OptionsIcon } from './options-icon';
import {
	getElapsedTimestamp,
	getElapsedTimestampWithCurrentDate
} from '../../utils/formatter';
import { RaiseHandIcon } from './raise-hand-icon';
import { requestMic } from '../../api/event.request';

const AudioParticipant = ({
	participant,
	localParticipant,
	newTrack,
	isLocal,
	isPaused,
	resize,
	setResize,
	isStopped,
	nickname,
	eventName,
	optionsOnClick,
	changeCameraOnClick,
	mobileSize,
	requestMicrophoneAccess,
	deviceId,
	eventStartDate,
	sound
}) => {
	const [audioTracks, setAudioTracks] = useState([]);
	const [muted, setMuted] = useState(false);

	const videoRef = useRef();
	const audioRef = useRef();

	useEffect(() => {
		if (!newTrack) return;
		console.log('new track', newTrack);
		const tempAudioTracks = [...audioTracks];
		tempAudioTracks.push(newTrack);

		setAudioTracks(tempAudioTracks);
	}, [newTrack]);

	const trackpubsToTracks = (trackMap) => {
		if (!trackMap) return;
		return Array.from(trackMap.values())
			.map((publication) => publication.track)
			.filter((track) => track !== null);
	};

	useEffect(() => {
		if (!isLocal) return;
		console.log('new audios', participant.audioTracks);
		console.log('new audios', localParticipant);

		participant.audioTracks.forEach((track) => {
			// console.log(track);
			if (muted) track.track.disable();
			else track.track.enable();
			// track.disable();
		});
	}, [muted]);

	useEffect(() => {
		console.log('participant', participant);

		setAudioTracks(trackpubsToTracks(participant.audioTracks));

		console.log(participant.audioTracks.length, participant.audioTracks);

		const trackSubscribed = (track) => {
			console.log('trackSubscribed', track);
			if (track.kind === 'audio') {
				setAudioTracks((audioTracks) => [...audioTracks, track]);
			}
		};

		const trackUnsubscribed = (track) => {
			console.log('trackUnsubscribed', track);

			if (track.kind === 'audio') {
				setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
			}
		};

		if (isFunction(participant.on)) {
			participant.on('trackSubscribed', trackSubscribed);
			// participant.on('subscribed', trackSubscribed);
			participant.on('trackUnsubscribed', trackUnsubscribed);

			// if (!isLocal) {
			//     participant.on('trackDisabled', (track) => {
			//         console.log('track has been disabled, track');
			//         if (track.kind == 'video') {
			//             // videoRef.current = null;
			//         }
			//     });

			//     participant.on('trackEnabled', (track) => {
			//         if (track.kind == 'video') {
			//             console.log(videoRef);
			//             console.log(videoRef.current);
			//             if (isFunction(track.attach)) {
			//                 track.attach(videoRef.current);
			//                 console.log(videoRef);
			//                 console.log(videoRef.current);
			//             }
			//         }
			//     });
			// }
		}

		return () => {
			// setVideoTracks([]);
			setAudioTracks([]);
			if (isFunction(participant.removeAllListeners)) {
				participant.removeAllListeners();
			}
		};
	}, [participant]);

	useEffect(() => {
		console.log('here-audio-vlla', audioTracks);
		const audioTrack = audioTracks[0];

		if (audioTrack) {
			audioTrack.attach(audioRef.current);
			return () => {
				audioTrack.detach();
			};
		}
	}, [audioTracks]);

	return (
		<audio
			ref={audioRef}
			autoPlay
			muted={!sound}
		/>
	);
};

export default AudioParticipant;
