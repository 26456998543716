import React from 'react';
import ConfirmationModal from '../../components/confirmation-modal/index';

const RandomizedBreakUnsoldSlotsModal = ({
	onConfirm,
	showModal,
	setShowModal
}) => (
	<ConfirmationModal
		text="There are some slot/s which are not sold yet, do you want to start the event?"
		confirmText="Yes"
		cancelText="No"
		showModal={showModal}
		setShowModal={setShowModal}
		showConfirmationIcon={false}
		onCancel={() => {
			setShowModal(false);
		}}
		onConfirm={onConfirm}
		confirmButtonColorClassNames="bg-green-600 hover:bg-green-800"
	/>
);

export default RandomizedBreakUnsoldSlotsModal;
