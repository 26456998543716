import React, { useState, useEffect } from 'react';

import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import Layout from '../components/layouts/layout-default';
import Header from '../components/header';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
`;

const RefundPolicy = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// authState();
		setLoading(false);
	}, []);

	return (
		<Layout>
			<div className="flex flex-col flex-1">
				<Header siteTitle="Signin" auth />
				{loading && (
					<div className="w-screen h-screen bg-white absolute left-0 top-0 z-50">
						<div className="flex h-full items-center">
							<BounceLoader
								loading
								css={override}
								size={30}
							/>
						</div>
					</div>
				)}

				{!loading && (
					<div className="flex flex-col flex-1 justify-start content-center">
						<div className="flex flex-1 flex-col justify-items-center bg-white w-8/12 mx-auto px-6 py-10 rounded-lg">
							<h2 className="text-xl text-gray-700 font-semibold text-center sm:text-lg sm:max-w-xl sm:mx-auto  md:text-xl mb-4">
                            Refund Policy
							</h2>
							<div className="w-6 h-0.5 mx-auto bg-gray-600 mb-6" />

							<div className="flex flex-col w-full mx-auto px-16">

								<p className="text-lg font-semibold text-gray-800 mt-8 mb-2">Breaks</p>

								<p className="text-base font-normal text-gray-900  mb-4">
                                Items purchased via breaks may not be refunded once the event has begun. Please contact us at
									{' '}
									<a href="mailto: djawnllc@gmail.com" className="text-blue-500 hover:text-blue-700">djawnllc@gmail.com</a>
									{' '}
at least 24 hours prior to an event start to request a slot refund.
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-8 mb-2">Rip & Ships</p>

								<p className="text-base font-normal text-gray-900  mb-4">
                                Items purchased via rip & ships may be refunded prior to opening but only at the discretion of the seller. Once opened, items purchased via rip & ships may not be refunded.
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-8 mb-2">Damages and issues</p>
								<p className="text-base font-normal text-gray-900 ">
                                Please inspect your order upon receipt and contact us immediately if the item is defective, damaged or if you receive the wrong item, so that we can evaluate the issue and make it right.
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-8 mb-2">How to request a refund</p>
								<p className="text-base font-normal text-gray-900  mb-4">
                                    To start a return, you can contact us at
									{' '}
									{' '}
									<a href="mailto: djawnllc@gmail.com" className="text-blue-500 hover:text-blue-700">djawnllc@gmail.com</a>
. If your return is
                                    accepted, we’ll send you a return shipping
                                    label, as well as instructions on how and
                                    where to send your package. Items sent back
                                    to us without first requesting a return will
                                    not be accepted.
								</p>
								<p className="text-lg font-semibold text-gray-800 mt-8 mb-2">Refunds</p>
								<p className="text-base font-normal text-gray-900 ">
                                    We will notify you once we’ve received and
                                    inspected your return, and let you know if
                                    the refund was approved or not. If approved,
                                    you’ll be automatically refunded on your
                                    original payment method. Please remember it
                                    can take some time for your bank or credit
                                    card company to process and post the refund
                                    too.
								</p>
								<p className="text-base font-normal text-gray-900 ">
                                    You can always contact us for any return
                                    question at
									{' '}
									<a href="mailto: djawnllc@gmail.com" className="text-blue-500 hover:text-blue-700">djawnllc@gmail.com</a>
.
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default RefundPolicy;
