import React from 'react';

const thumbsContainer = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap'
	// marginTop: 16
};

const thumb = {
	display: 'inline-flex',
	// borderRadius: 2,
	// border: '1px solid #eaeaea',
	// marginBottom: 8,
	// marginRight: 8,
	width: 100,
	height: 100,
	padding: 4
	// boxSizing: 'border-box'
};

const thumbInner = {
	display: 'flex',
	minWidth: 0,
	overflow: 'hidden'
};

const img = {
	display: 'block',
	width: 'auto',
	height: '100%'
};

const ImagePreview = ({ files, onChange }) => {
	const removeImage = (imageName) => {
		console.log('remove image');
		console.log(imageName);
		// let removeIndex = files.map((file, index) => {
		//     if (file.name === imageName) {
		//         return (removeIndex = index);
		//     }
		// });
		let removeIndex;
		files.forEach((file, index) => {
			if (file.name === imageName) {
				removeIndex = index;
			}
		});
		files.splice(removeIndex, 1);
		onChange(files);
	};

	return (
		<aside style={thumbsContainer}>
			{files.map((file, index) => (
				<div style={thumb} key={index}>
					<div style={thumbInner} className="relative">
						<img src={file.preview} style={img} alt="thumb" />

						<button
							type="button"
							className="absolute right-0 top-0 bg-red-500 rounded-sm p-1 inline-flex items-center justify-center text-white hover:text-white hover:bg-red-800"
							onClick={() => {
								removeImage(file.name);
							}}
						>
							<span className="sr-only">Close menu</span>
							<svg
								className="h-4 w-4"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			))}
		</aside>
	);
};

export default ImagePreview;
