import React from 'react';
import clsx from 'clsx';

import Step from './step/step';
import { useStepperStyles } from './styles';
import StepperContext from './stepper-context';

const Stepper = ({
	steps,
	children,
	connectorStateColors = false,
	className = '',
	stepClassName = '',
	activeStep = 0,
	styleConfig,
	showNumber = true,
	connectorStyleConfig,
	hideConnectors = false,
	nonLinear = false,
	...rest
}) => {
	const classes = useStepperStyles();

	const contextValue = React.useMemo(
		() => ({
			activeStep,
			hideConnectors,
			nonLinear,
			connectorStateColors: connectorStateColors && !nonLinear,
			connectorStyleConfig
		}),
		[
			activeStep,
			hideConnectors,
			nonLinear,
			connectorStateColors,
			connectorStyleConfig
		]
	);

	const useStepsProp = steps instanceof Array && steps.length > 0;
	const stepsArray = useStepsProp ? steps : React.Children.toArray(children);

	const stepsToRender =		stepsArray
		&& stepsArray.map((step, index) => {
			if (!useStepsProp && !React.isValidElement(step)) return null;
			const stepProps = {
				className: stepClassName,
				styleConfig,
				index,
				showNumber
			};

			return (
				<div
					key={index}
					id="RFS-StepContainer"
					className={`${classes.StepContainer} ${step.hidden ? 'hidden' : ''}`}
				>
					{React.isValidElement(step) ? (
						React.cloneElement(step, {
							...stepProps,
							...step.props
						})
					) : (
						<Step {...stepProps} {...step} />
					)}
				</div>
			);
		});

	return (
		<StepperContext.Provider value={contextValue}>
			<div
				id="RFS-StepperContainer"
				className={clsx(classes.StepperContainer, className)}
				{...rest}
			>
				{stepsToRender}
			</div>
		</StepperContext.Provider>
	);
};

export default Stepper;
