import React, { useState, useMemo, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { isTruthy } from '../../utils/commonFunction';
import { getSlotName } from '../../utils/slot';
import { calculateFees } from '../../api/payment.request';
import DataTable from '../../components/datatable';

const MobileRefundRequestFinalize = ({
	orderDetails,
	setTotalValue,
	finalizedAmounts,
	invalidAmounts,
	setInvalidAmounts,
	djawnFees,
	setDjawnFees,
	discount,
	setDiscount,
	grossCost,
	stripeFees,
	setStripeFees,
	shippingPrice,
	setShippingPrice,
	initialShippingPrice,
	isBuyerInitiated,
	refundReasonId,
	setDjawnSellerFee,
	originalGrossCost,
	originalStripeFee
}) => {
	// eslint-disable-next-line no-unused-vars
	const [validShippingFee, setValidShippingFee] = useState(true);
	const [errorText, setErrorText] = useState('');
	const [showErrorAlert, setShowErrorAlert] = useState(false);

	const columns = useMemo(
		() => [
			{
				name: 'Product',
				selector: 'productName',
				sortable: true,
				cell: (row) => (
					<p>{row.productId != null ? row.productName : getSlotName(row)}</p>
				),
				width: '8rem'
			},
			{
				name: 'Units',
				selector: 'slotPurchased',
				cell: (row) => (row.slotPurchased == 0 ? 1 : row.slotPurchased),
				sortable: true,
				width: '4rem'
			},
			{
				name: 'Cost',
				selector: 'unitCost',
				sortable: true,
				cell: (row) => (
					<p>
$
						{row.unitCost}
					</p>
				),
				width: '4rem'
			},
			{
				name: 'Amount',
				cell: (row) => {
					const { amount, totalAllowed } = finalizedAmounts.find(
						(finalizedItem) => finalizedItem.orderDetailsId == row.orderDetailsId
					);
					const isInvalid = invalidAmounts.find(
						(orderDetailId) => orderDetailId == row.orderDetailsId
					);
					return (
						<NumberFormat
							className={` pl-2 pr-2 block w-18 h-8 sm:text-sm ${
								isInvalid
									? 'border-2 border-red-700 ring-indigo-900 focus:border-red-900'
									: 'border border-gray-400 focus:ring-indigo-400 focus:border-indigo-400'
							}`}
							thousandSeparator
							prefix="$"
							isAllowed={({ floatValue }) => {
								const foundInvalidAmount = invalidAmounts.find(
									(orderDetailId) => orderDetailId == row.orderDetailsId
								);
								let tempInvalidAmounts = [...invalidAmounts];
								if (floatValue == null || floatValue == undefined) {
									if (!foundInvalidAmount) {
										tempInvalidAmounts.push(row.orderDetailsId);
										setInvalidAmounts(tempInvalidAmounts);
									}
									return true;
								}
								if (foundInvalidAmount) {
									tempInvalidAmounts = tempInvalidAmounts.filter(
										(orderDetailId) => orderDetailId != row.orderDetailsId
									);
								}
								setInvalidAmounts(tempInvalidAmounts);
								if (
									floatValue
									&& floatValue.toString().length
									&& floatValue.toString()[0] == '-'
								) {
									floatValue = Number(floatValue) * -1;
								}
								return floatValue <= totalAllowed;
							}}
							value={amount}
							id={`amount-${row.orderDetailsId}`}
							onValueChange={(values) => {
								let { value } = values;
								if (
									value
									&& value.toString().length
									&& value.toString()[0] == '-'
								) {
									value = Number(value) * -1;
								}
								setTotalValue(row.orderDetailsId, value);
							}}
						/>
					);
				}
			}
		],
		[finalizedAmounts, invalidAmounts]
	);

	useEffect(() => {
		if (grossCost > 0) {
			calculateFees(
				grossCost,
				shippingPrice,
				originalGrossCost,
				initialShippingPrice,
				originalStripeFee,
				refundReasonId
			).then((res) => {
				if (djawnFees > 0) {
					setDjawnFees(res.data.buyerDjawnFee);
				}
				if (discount > 0) {
					setDiscount(res.data.buyerDjawnFee);
				}
				setDjawnSellerFee(res.data.sellerDjawnFee);
				if (isBuyerInitiated) {
					setStripeFees(
						grossCost == originalGrossCost
							? originalStripeFee
							: res.data.stripeFee
					);
				} else {
					setStripeFees(0);
				}
			});
		}

		const finalizedAmountsSmallerThenZero = finalizedAmounts.find(
			(p) => p.amount <= 0
		);
		if (finalizedAmountsSmallerThenZero) {
			setShowErrorAlert(true);
			setErrorText('All amounts should be bigger than 0');
			return;
		}
		if (
			(shippingPrice == '' && shippingPrice !== 0)
			|| shippingPrice == null
			|| shippingPrice == undefined
		) {
			setShowErrorAlert(true);
			setValidShippingFee(false);
			setErrorText('Shipping fee should not be empty');
			return;
		}
		const total = Number(
			Number(grossCost)
				+ Number(djawnFees)
				+ Number(shippingPrice)
				- Number(stripeFees)
				- Number(discount)
		);

		if (total <= 0) {
			setShowErrorAlert(true);
			setErrorText('Refund total should be a positive value');
			return;
		}
		if (invalidAmounts && invalidAmounts.length) {
			setErrorText('Please fill all the amount inputs properly');
			setShowErrorAlert(true);
			return;
		}
		setErrorText('');
		setShowErrorAlert(false);
		setValidShippingFee(true);
	}, [invalidAmounts, shippingPrice, grossCost, djawnFees, stripeFees]);

	return (
		<>
			{isTruthy(showErrorAlert && errorText && errorText.length) && (
				<div role="alert" className="w-full mb-3 px-4">
					<div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
						Cannot finalize request
					</div>
					<div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
						<p>{errorText}</p>
					</div>
				</div>
			)}

			<div className="order-details-header flex bg-white md:justify-between md:px-4 px-2 py-4 flex-col md:flex-row shadow-lg mb-3">
				<DataTable
					columns={columns}
					data={orderDetails}
					noContextMenu
					className=""
					contextActions={null}
					striped
					paginationComponent={null}
					customStyles={{
						table: {
							style: {
								border: '1px #a7a6a6 solid'
							}
						},
						headCells: {
							style: {
								backgroundColor: '#e82e2c',
								color: 'white',
								fontSize: '0.75rem',
								fontWeight: 'bold'
							}
						}
					}}
				/>
				<div className="flex justify-between py-3 w-full">
					<table className="w-full">
						<tbody>
							<tr>
								<td align="start">
									<p className="font-semibold pr-5 pb-2">Sub total</p>
								</td>
								<td align="start">
									<p className="pb-2">
$
										{Number(grossCost).toFixed(2)}
									</p>
								</td>
							</tr>
							<tr>
								<td align="start">
									<p className="font-semibold pr-5 pb-2">Add: Shipping fee</p>
								</td>
								<td align="start">
									<NumberFormat
										className={`pl-4 w-14 h-8  block sm:text-sm ${
											!validShippingFee
												? 'border-2 border-red-700 ring-indigo-900 focus:border-red-900'
												: 'border border-gray-400 focus:ring-indigo-400 focus:border-indigo-400'
										}`}
										thousandSeparator
										disabled={
											!initialShippingPrice || initialShippingPrice == 0
										}
										prefix="$"
										isAllowed={({ floatValue }) => {
											if (
												floatValue == null
												|| (floatValue == '' && floatValue != 0)
												|| floatValue == undefined
											) {
												return true;
											}
											return floatValue <= initialShippingPrice;
										}}
										value={shippingPrice}
										onValueChange={(values) => {
											const { value } = values;
											setShippingPrice(value);
											setValidShippingFee(
												!(value == null || value == undefined)
											);
										}}
									/>
								</td>
							</tr>
							<tr>
								<td align="start">
									<p className="font-semibold pr-5 pb-2">
										Add: DJAWN buyer fee
									</p>
								</td>
								<td align="start">
									<p className="pb-2">
$
										{Number(djawnFees).toFixed(2)}
									</p>
								</td>
							</tr>
							<tr>
								<td align="start">
									<p className="font-semibold pr-5 pb-2">
										Less: Djawn buyer discount
									</p>
								</td>
								<td align="start">
									<p className="pb-2">
$
										{Number(discount).toFixed(2)}
									</p>
								</td>
							</tr>
							<tr>
								<td align="start">
									<p className="font-semibold pr-5 pb-2">
										Less: Refund process fee
									</p>
								</td>
								<td align="start">
									<p className="pb-2">
$
										{Number(stripeFees).toFixed(2)}
									</p>
								</td>
							</tr>
							<tr className="border-t-2">
								<td align="start">
									<p className="font-semibold pr-5 pb-2">Refund total</p>
								</td>
								<td align="start">
									<p className="pb-2">
										$
										{Number(
											Number(grossCost)
												+ Number(djawnFees)
												+ Number(shippingPrice)
												- Number(stripeFees)
												- Number(discount)
										).toFixed(2)}
										{' '}
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
	// <div className="flex bg-white rounded-lg justify-between px-4 py-4 shadow-lg mt-3 mb-3">
	// 	<div className="flex justify-between flex-col md:flex-row">
	// 		<p className="text-md">
	// 			<b>Subtotal</b>
	// 			<span className="pl-1 pr-2">:</span>
	// 			<span className="pr-2">$</span>
	// 			{Number(Number(grossCost)).toFixed(2)}
	// 		</p>
	// 		<p className="flex text-md">
	// 			<b>Shipping fee</b>
	// 			<span className="pl-1 pr-2">:</span>
	// 			<span className="pr-2">$</span>
	// 			{Number(shippingPrice).toFixed(2)}
	// 		</p>

	// 		<p className="flex text-md">
	// 			<b>DJAWN fee</b>
	// 			<span className="pl-1 pr-2">:</span>
	// 			<span className="pr-2">$</span>
	// 			{Number(djawnFee).toFixed(2)}
	// 		</p>
	// 		<p className="flex md:pr-0 pr-5  text-md">
	// 			<b>Djawn discount</b>
	// 			<span className="pl-1 pr-2">:</span>
	// 			<span className="pr-2">$</span>
	// 			{Number(discount).toFixed(2)}
	// 		</p>
	// 	</div>
	// 	<p className="flex justify-center flex-col items-center pr-12 text-lg">
	// 		<b>Total</b>
	// 		<div className="flex justify-center">
	// 			<b className="pr-1">
	// 				$
	// 				{Number(
	// 					Number(grossCost)
	// 						+ Number(shippingPrice)
	// 						+ Number(djawnFee)
	// 						+ Number(discount)
	// 				).toFixed(2)}
	// 			</b>
	// 		</div>
	// 	</p>
	// </div>
	// <div className="flex flex-col bg-white rounded-lg justify-between px-4 py-4 shadow-lg mt-3 mb-3">
	// 	{showRefundReasonsSelect ? (
	// 		<>
	// 			<b className="p-1">Refund reason</b>
	// 			<select
	// 				id="request_reason"
	// 				name="request_reason"
	// 				autoComplete="request_reason"
	// 				className="h-10 text-sm focus:outline-none border px-5 rounded-md mb-3 md:mb-0"
	// 				onBlur={(e) => setRequestedRefundReason(e.target.value)}
	// 				onChange={(e) => setRequestedRefundReason(e.target.value)}
	// 				value={requestedRefundReason || ''}
	// 			>
	// 				<option key={null} value={0} selected>
	// 					Refund Reason
	// 				</option>
	// 				{refundReasons.map((refundReason) => (
	// 					<option key={refundReason.id} value={refundReason.id}>
	// 						{refundReason.value}
	// 					</option>
	// 				))}
	// 			</select>
	// 		</>
	// 	) : (
	// 		<p className="inline-flex w-full whitespace-nowrap">
	// 			<b>Refund Reason</b>
	// 			<span className="pl-1 pr-2">:</span>
	// 			<p>{selectedRefundReason}</p>
	// 		</p>
	// 	)}
	// 	{isTruthy(attachments && attachments.length) && (
	// 		<div className="flex flex-col justify-center pb-4">
	// 			<b className="pr-3">Attachments</b>
	// 			{isTruthy(attachments.length) && (
	// 				<div className="grid grid-cols-2 mb-3 pb-3">
	// 					{attachments.map((object, i) => (
	// 						<div className="flex pt-2 col-span-0" key={i}>
	// 							<div className="md:p-1 text-left md:text-left space-y-4 break-all overflow-x-hidden">
	// 								<div className="flex flex-none items-center border rounded-2xl">
	// 									<div className="flex items-center justify-between text-center w-full">
	// 										<span className="text-md mr-1 p-1 font-sans w-full pl-5">
	// 											{object.fileName}
	// 										</span>
	// 										<button
	// 											type="button"
	// 											className="focus:outline-none text-black hover:text-black text-lg p-3 rounded-md rounded-tr-none rounded-br-none"
	// 											onClick={() => onAttachmentDownloaded(object.fileName)}
	// 										>
	// 											<svg
	// 												className="w-5 h-4"
	// 												fill="none"
	// 												stroke="currentColor"
	// 												viewBox="0 0 24 24"
	// 												xmlns="http://www.w3.org/2000/svg"
	// 											>
	// 												<path
	// 													strokeLinecap="round"
	// 													strokeLinejoin="round"
	// 													strokeWidth="2"
	// 													d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
	// 												/>
	// 											</svg>
	// 										</button>
	// 									</div>
	// 								</div>
	// 							</div>
	// 						</div>
	// 					))}
	// 				</div>
	// 			)}
	// 		</div>
	// 	)}
	// </div>
};

export default MobileRefundRequestFinalize;
