import { XIcon } from '@heroicons/react/solid';
import React, { useContext, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { getEventDetails } from '../../api/event.request';
import RoomAddEventProduct from '../../containers/event/room/room-add-event-product';
import { ProductsContext } from '../../contexts/products';
import useErrorNotifier from '../../hooks/use-error-notifier';

const ProductsEventQueue = ({
	eventProducts,
	filteredEventProducts,
	eventId,
	setShowProductModal,
	setSelectedProduct,
	setEventInformation,
	disableRowClickedFunctionality,
	showAddToCartInformationPopup,
	onProductNamePopupClose,
	addToCartPopupFadingOut,
	isDarkMode,
	userCanAddOrRemoveEventProducts,
	userCanAddProducts,
	productSelectionId
}) => {
	const { showToastError } = useErrorNotifier();
	const { setEventProductIdToRemove } = useContext(ProductsContext);

	const columns = useMemo(() => {
		const defaultColumns = [
			{
				name: 'Name',
				selector: (row) => row.product.productName,
				sortable: true,
				cell: (row) => (
					<div
						className="font-bold hover:underline cursor-pointer"
						data-tag="allowRowEvents"
					>
						{row.product.productName}
					</div>
				),
				wrap: true
			},
			{
				name: 'Price',
				selector: (row) => row.product.salesPriceRipShip,
				sortable: true,
				right: true,
				cell: (row) => (
					<div
						className="font-bold hover:underline cursor-pointer"
						data-tag="allowRowEvents"
					>
						$
						{row.product.salesPriceRipShip}
					</div>
				),
				wrap: true
			}
		];

		if (userCanAddOrRemoveEventProducts) {
			defaultColumns.push({
				name: 'Remove product',
				sortable: true,
				right: true,
				cell: (row) => (
					<XIcon
						className="cursor-pointer w-8 h-8 dark:text-white text-black"
						onClick={() => {
							if (eventProducts.length <= 1) {
								showToastError(
									'You cannot remove the product, you should have at least one product in the event.'
								);
								return;
							}
							setEventProductIdToRemove(row.eventProductId);
						}}
					/>
				),
				wrap: true
			});
		}
		return defaultColumns;
	}, [userCanAddOrRemoveEventProducts, eventProducts]);

	useEffect(() => {
		if (!eventId) return;

		getEventDetails(eventId, true)
			.then((res) => {
				setEventInformation(res.data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [eventId]);

	return (
		<div className="relative h-full">
			{showAddToCartInformationPopup && (
				<div
					className={`flex justify-end flex-col items-start absolute signin-popup${
						addToCartPopupFadingOut ? '-fade-out' : ''
					} ml-1 top-11`}
				>
					<div className="triangle-up ml-4" style={{ zIndex: '60' }} />

					<div
						className="w-64 md:w-80 border rounded"
						style={{
							backgroundColor: '#0c6ae6',
							borderColor: '#0c6ae6',
							zIndex: '60'
						}}
					>
						<h3
							className="text-white text-sm pt-4 pl-3"
							style={{ zIndex: '60' }}
						>
							Click on a product name to see more information and add to cart.
						</h3>
						<div
							className="flex justify-between px-3 pt-3 mb-1"
							style={{ zIndex: '60' }}
						>
							<p
								className="cursor-pointer text-white text-md"
								onClick={() => onProductNamePopupClose(true)}
								onKeyDown={() => onProductNamePopupClose(true)}
							>
								Dismiss
							</p>
							<p
								className="cursor-pointer text-white text-md"
								onClick={() => onProductNamePopupClose(false)}
								onKeyDown={() => onProductNamePopupClose(false)}
							>
								Don't show again
							</p>
						</div>
					</div>
				</div>
			)}

			<div
				className={`flex flex-col justify-between h-full 
			${
		userCanAddOrRemoveEventProducts || userCanAddProducts
			? 'overflow-hidden'
			: ''
		}`}
			>
				<div
					className={`${
						userCanAddOrRemoveEventProducts || userCanAddProducts
							? 'overflow-y-auto'
							: ''
					}`}
				>
					<DataTable
						columns={columns}
						data={filteredEventProducts}
						pagination
						onRowClicked={(row) => {
							if (!disableRowClickedFunctionality) {
								setSelectedProduct(row);
								setShowProductModal(true);
							}
						}}
						paginationComponentOptions={{
							rowsPerPageText: '',
							rangeSeparatorText: 'of',
							noRowsPerPage: true,
							selectAllRowsItem: true,
							selectAllRowsItemText: 'All'
						}}
						striped
						contextActions={null}
						noContextMenu
						noDataComponent={
							userCanAddOrRemoveEventProducts || userCanAddProducts ? (
								<p className="flex justify-center items-center m-5">
									There is no product in the event which matches to your search
									criteria. Please use the buttons below to create a new product
									or to add existing product/s to the live event.
								</p>
							) : (
								<p className="flex justify-center items-center m-5">
									There are no records to display
								</p>
							)
						}
						customStyles={{
							className: 'bg-white dark:bg-darkGray-100',
							rows: {
								stripedStyle: {
									backgroundColor: isDarkMode ? '#323131' : '#fffcfc',
									color: isDarkMode && '#FFFFFF'
								},
								style: {
									backgroundColor: isDarkMode && '#201F1F',
									color: isDarkMode && '#FFFFFF'
								}
							},
							headCells: {
								style: {
									backgroundColor: 'rgb(232, 46, 44)',
									color: 'white',
									fontSize: '1rem',
									fontWeight: 'bold'
								}
							},
							pagination: {
								style: {
									backgroundColor: isDarkMode && '#201F1F',
									color: isDarkMode && '#FFFFFF'
								},
								pageButtonsStyle: {
									color: isDarkMode && '#FFFFFF',
									fill: isDarkMode && '#FFFFFF',
									backgroundColor: 'transparent',
									'&:disabled': {
										cursor: 'unset',
										color: isDarkMode && '#FFFFFF',
										fill: isDarkMode && '#FFFFFF'
									}
								}
							}
						}}
					/>
				</div>
				<RoomAddEventProduct
					excludedProductIds={eventProducts.map(
						(eventProduct) => eventProduct.productId
					)}
					eventId={eventId}
					userCanAddOrRemoveEventProducts={userCanAddOrRemoveEventProducts}
					userCanAddProducts={userCanAddProducts}
					productSelectionId={productSelectionId}
				/>
			</div>
		</div>
	);
};
export default connect(
	(state) => ({
		isDarkMode: state.theme.isDarkMode
	}),
	null
)(ProductsEventQueue);
