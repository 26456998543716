import axiosConfig from './config';
import { getAccessToken } from '../utils/auth';

export const authHeaders = () => ({ headers: { Authorization: `Bearer ${getAccessToken()}` } });

export const reserveBreak = async (breakId) => await axiosConfig.post(`break/reserve/${breakId}`, null);
// TODO: Check once more if we can just delete this request
export const reserveEventBreak = async (breakId) => {
	const myHeaders = new Headers();
	myHeaders.append('accept', '*/*');
	myHeaders.append('Authorization', `Bearer ${getAccessToken()}`);

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		redirect: 'follow'
	};

	const res = await fetch(
		`break/reserve/${breakId}`,
		requestOptions
	)
		.then((result) => result)
		.catch((error) => {
			if (typeof error.json === 'function') {
				error
					.json()
					.then((jsonError) => {
						console.error('Json error from API');
						console.error(jsonError);
						throw new Error('Something went wrong');
					})
					.catch(() => {
						console.error('Generic error from API');
						console.error(error.statusText);
					});
			} else {
				console.error('Fetch error');
				console.error(error);
			}
		});
	return res;
};
export const releaseBreak = async (breakId) => axiosConfig.post(`break/release/${breakId}`,);
export const releaseEventBreak = async (breakId) => {
	const myHeaders = new Headers();
	myHeaders.append('accept', '*/*');
	myHeaders.append('Authorization', `Bearer ${getAccessToken()}`);

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		redirect: 'follow'
	};

	const res = await fetch(
		`break/release/${breakId}`,
		requestOptions
	)
		.then((result) => result)
		.catch((error) => {
			if (typeof error.json === 'function') {
				error
					.json()
					.then((jsonError) => {
						console.error('Json error from API');
						console.error(jsonError.response);
						throw new Error('Something went wrong');
					})
					.catch(() => {
						console.error('Generic error from API');
						console.error(error.statusText);
					});
			} else {
				console.error('Fetch error');
				console.error(error);
			}
		});
	return res;
};
// TODO: Check if we can remove this
export const startpaymentEventBreak = async (breaks) => await axiosConfig.post('break/startpayment', breaks);
export const purchasedBreaksByEventId = async (eventId) => await axiosConfig.get(`break/purchased/${eventId}`);
export const getSlotStatusesByEventId = async (eventId) => await axiosConfig.get(`break/slotstatus/${eventId}`);
export const getBreaksByEventId = async (eventId) => await axiosConfig.get(`break/${eventId}`);
export const getBreakTeamsRandomization = async (eventId) => await axiosConfig.get(`break/randomization/${eventId}`);
