import React, { useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { navigate } from 'gatsby';
import ReactTooltip from 'react-tooltip';
import BackButton from '../../components/button/back-button';
import NextButton from '../../components/button/next-button';
import { CreateEventContext } from '../../contexts/create-event';
import { InfoIcon } from '../../components/Event/event-icons';
import ImagePreview from '../../components/common/image-preview';
import {
	ALL_PRODUCTS_PRODUCT_SELECTION_OPTION,
	RIP_N_SHIP
} from '../../utils/constants';
import useErrorNotifier from '../../hooks/use-error-notifier';

import RipNShipProductSelection from './ripnship-product-selection';
import SelectProductSlider from '../../components/select-product-slider';

// TODO: this could be much better performance wise
const EventDetails = () => {
	const [isRipNShip, setIsRipNShip] = useState(false);
	const [submitDisabled, setSubmitDisabled] = useState(false);

	const {
		eventDescription: eventDescriptionContext,
		setEventDescription: setEventDescriptionContext,
		eventTypeId,
		setActiveStep,
		setFiles,
		files,
		selectedProducts,
		setSelectedProducts,
		createEvent,
		setLoading,
		selectedProductSelectionId,
		setSelectedProductSelectionId
	} = useContext(CreateEventContext);

	const [eventDescription, setEventDescription] = useState(
		eventDescriptionContext
	);

	const { showToastError } = useErrorNotifier();

	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		onDrop: (acceptedFiles) => {
			const newFiles = acceptedFiles.map((file) => Object.assign(file, {
				preview: URL.createObjectURL(file)
			}));
			const tempNewFiles = [...newFiles];
			setFiles(tempNewFiles);
		},
		multiple: false
	});

	useEffect(() => {
		if (eventTypeId) {
			setIsRipNShip(eventTypeId == RIP_N_SHIP);
		}
	}, [eventTypeId]);

	const onNextClick = async () => {
		setEventDescriptionContext(eventDescription);
		if (isRipNShip) {
			setActiveStep(2);
		} else {
			setSubmitDisabled(true);
			setLoading(true);
			try {
				const eventId = await createEvent(eventDescription);
				navigate(`/event/${eventId}`);
			} catch (err) {
				showToastError(err);
				setLoading(false);
				setSubmitDisabled(false);
			}
		}
	};

	const onBackClick = () => {
		setActiveStep(0);
	};

	const onPreviewChange = (updatedFiles) => {
		setFiles(
			updatedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }))
		);
	};

	return (
		<div>
			<ReactTooltip />
			<div className="flex flex-col justify-center md:justify-normal items-center md:items-normal md:flex-row">
				<div className="flex flex-col inline-block w-full md:w-1/2 mb-1 md:pr-6 pr-0">
					<div className="flex text-center justify-start space-x-2 my-2">
						<div>
							<span className="text-lg font-semibold block">
								Event Description
							</span>
						</div>
						<div data-tip="To let your customers know what to expect in your event">
							<InfoIcon />
						</div>
					</div>
					<div className=" relative rounded-md shadow-sm">
						<textarea
							type="text"
							rows="4"
							name="event_details"
							id="event_details"
							className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 py-2 block w-full  sm:text-sm border border-gray-200 rounded-md"
							maxLength="2000"
							value={eventDescription}
							onChange={(e) => setEventDescription(e.target.value)}
						/>
					</div>
				</div>
				<div className="flex flex-col inline-block w-full md:w-1/2">
					<div className="flex text-center justify-start space-x-2 my-2">
						<div>
							<span className="text-lg font-semibold block">
								Event Cover Photo
							</span>
						</div>
						<div data-tip="Add a photo to get attention.">
							<InfoIcon />
						</div>
					</div>
					<div className="flex flex-row">
						<div className="flex flex-col w-3/4">
							<div
								style={{ verticalAlign: 'middle' }}
								{...getRootProps({
									className:
										'dropzone border bg-blue-100 rounded-md text-lg text-center inline-block'
								})}
							>
								<input
									{...getInputProps()}
									className="h-full border bg-gray-300"
									multiple={false}
								/>
								<p className="h-24 inline-block flex justify-center items-center align-middle leading-6 p-4">
									Drag 'n' drop some files here, or click to select files
								</p>
							</div>
						</div>
						<div
							className="flex flex-col border ml-2"
							style={{ height: 100, width: 100 }}
						>
							<div>
								<ImagePreview
									files={files}
									onChange={(values) => onPreviewChange(values)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			{isRipNShip && (
				<RipNShipProductSelection
					selectedProductSelectionId={selectedProductSelectionId}
					onProductSelectionChange={(id) => setSelectedProductSelectionId(id)}
				/>
			)}

			<div
				className={`${
					selectedProductSelectionId == ALL_PRODUCTS_PRODUCT_SELECTION_OPTION
						? 'hidden'
						: 'opacity-1'
				}`}
			>
				<SelectProductSlider
					isRipNShip={isRipNShip}
					selectedProducts={selectedProducts}
					setSelectedProducts={setSelectedProducts}
				/>
			</div>

			{selectedProductSelectionId == ALL_PRODUCTS_PRODUCT_SELECTION_OPTION && (
				<div className="my-10">
					<h4>
						All active products with an inventory will be available for buyers
						in the live event.
					</h4>
				</div>
			)}

			<div className="flex justify-center mx-auto">
				<div className="mr-2">
					<BackButton onClick={onBackClick} />
				</div>
				<div className="ml-2">
					<NextButton
						onClick={onNextClick}
						disabled={submitDisabled}
						overridenText={isRipNShip ? 'NEXT' : 'CREATE'}
					/>
				</div>
			</div>
		</div>
	);
};

export default EventDetails;
