/* eslint-disable radix */
import React, { useContext } from 'react';
import Loader from '../../components/loader';
import { CreateEventContext } from '../../contexts/create-event';
import CreateEventSteppers from './create-event-steppers';
import SetupEvent from './setup-event';
import SelectOrCreateTemplate from './select-or-create-template';
import { PICK_YOUR_SLOTS, RANDOMIZED, RIP_N_SHIP } from '../../utils/constants';
import EventDetails from './event-details';
import RipNShipDetails from './ripnship-details';
import EditTemplate from './edit-template';
import SetupTiers from './setup-tiers';
import CreateBreakTemplate from './create-break-template';
import CreateBreakTemplateTiers from './create-break-template-tiers';
import PickYourSlotEditEventTemplate from './edit-template-pick-your-slot';
import CreatePickYourSlotTemplate from './create-pick-your-slot-template';

const RipNShipCreationSteps = ({ activeStep }) => (
	<>
		{activeStep == 1 && <EventDetails />}
		{activeStep == 2 && <RipNShipDetails />}
	</>
);

const RandomizedBreakCreationSteps = ({
	activeStep,
	templateActionType = ''
}) => (
	<>
		{activeStep == 1 && <SelectOrCreateTemplate />}
		{activeStep == 2
			&& (templateActionType == '' || templateActionType == 'template') && (
			<EditTemplate />
		)}
		{activeStep == 2 && templateActionType == 'create' && (
			<CreateBreakTemplate />
		)}
		{activeStep == 3 && templateActionType == 'create-tiers' && (
			<CreateBreakTemplateTiers />
		)}
		{activeStep == 3
			&& (templateActionType == '' || templateActionType == 'template') && (
			<SetupTiers />
		)}
		{activeStep == 4 && <EventDetails />}
	</>
);

const PickYourSlotsBreakCreationSteps = ({
	activeStep,
	templateActionType = ''
}) => (
	<>
		{activeStep == 1 && <SelectOrCreateTemplate />}
		{activeStep == 2
			&& (templateActionType == '' || templateActionType == 'template') && (
			<PickYourSlotEditEventTemplate />
		)}
		{activeStep == 2 && templateActionType == 'create' && (
			<CreatePickYourSlotTemplate />
		)}
		{activeStep == 3 && <EventDetails />}
	</>
);

const CreateEvent = () => {
	const {
		loading, activeStep, eventTypeId, templateActionType
	} = useContext(
		CreateEventContext
	);

	return (
		<div className="flex flex-col flex-grow w-full h-full bg-white">
			<CreateEventSteppers />
			<div className="flex flex-col flex-grow p-8 pt-0 h-full">
				{loading ? <Loader /> : null}
				<div className="xs:w-full sm:w-8/12 xs:mx-0 sm:mx-auto h-full " />
				<div className={`${loading ? 'opacity-0' : ''}`}>
					{activeStep == 0 && <SetupEvent />}

					{(() => {
						switch (parseInt(eventTypeId)) {
							case RIP_N_SHIP:
								return <RipNShipCreationSteps activeStep={activeStep} />;
							case RANDOMIZED:
								return (
									<RandomizedBreakCreationSteps
										activeStep={activeStep}
										templateActionType={templateActionType}
									/>
								);
							case PICK_YOUR_SLOTS:
								return (
									<PickYourSlotsBreakCreationSteps
										activeStep={activeStep}
										templateActionType={templateActionType}
									/>
								);
							default:
								return null;
						}
					})()}
				</div>
			</div>
		</div>
	);
};

export default CreateEvent;
