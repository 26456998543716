import { useEffect, useState } from 'react';
import useAudio from './useAudio';
import { getWebSocketSoundNotifications } from '../api/websocket-sound-notification.request';

const useSoundNotification = (isSeller) => {
	const { playAudio } = useAudio();
	const [
		webSocketSoundNotifications,
		setWebSocketSoundNotifications
	] = useState([]);
	const [webSocketMessage, setWebSocketMessage] = useState([]);
	const [playSound, setPlaySound] = useState(false);

	useEffect(() => {
		if (!webSocketMessage) return;
		const notificationSound = webSocketSoundNotifications.find(
			(p) => p.webSocketMessage == webSocketMessage
		);
		if (!notificationSound) return;

		let play = false;
		if (!isSeller) {
			if (notificationSound.notifyAllBuyers) play = true;
		} else if (notificationSound.notifySeller) {
			play = true;
		}

		if (play) playAudio(notificationSound.soundUrl);
	}, [playSound]);

	const playDefaultAudio = () => {
		playAudio();
	};

	const playWebSocketNotificationAudio = (socketMessage) => {
		setWebSocketMessage(socketMessage);
		setPlaySound(false);
		setPlaySound(true);
	};

	useEffect(() => {
		getWebSocketSoundNotifications()
			.then((res) => {
				console.log(res.data, 'notifications');
				setWebSocketSoundNotifications(res.data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	return { playWebSocketNotificationAudio, playDefaultAudio };
};

export default useSoundNotification;
