import axiosConfig from './config';
import { getCurrentUser, getAccessToken } from '../utils/auth';

export const authHeaders = () => ({ headers: { Authorization: `Bearer ${getAccessToken()}` } });

export const getToken = async (response) => await axiosConfig.post('account/facebook', {
	accessToken: response.accessToken
});
export const checkAuthorization = async () => await axiosConfig.post('account/check-authorization', null);

export const refreshToken = async () => {
	const user = getCurrentUser();
	const req = { token: user.token, refreshToken: user.refreshToken };
	const config = { headers: { Authorization: `Bearer ${getAccessToken()}` } };
	const res = await axiosConfig.post(
		'account/refreshtoken',
		req,
		config
	);
	window.localStorage.gatsbyUser = JSON.stringify({
		name: user.name,
		firstName: user.firstName,
		lastName: user.firstName,
		pic: user.pic,
		id: user.id,
		email: user.email,
		token: res.data.token,
		refreshToken: res.data.refreshToken
	});
	return res;
};

export const facebookAuthentication = async (req) => await axiosConfig.post('account/facebook', req);
export const getFacebookUserDataAccessTokenFromCode = async (code, redirectUrl) => await axiosConfig.post(`account/facebook/user_data/${code}/${encodeURIComponent(redirectUrl)}`);
export const googleAuthentication = async (req) => await axiosConfig.post('account/google', req);
export const loadProfileData = async () => await axiosConfig.get('account/profile');
export const updateProfileData = async (profileData) => await axiosConfig.put('account/profile', profileData);
export const getImageByNickname = async (nickname) => await axiosConfig.get(`account/profile/image?nickname=${nickname}`);
export const uploadProfileImage = async (formData, token) => {
	let headers = authHeaders();
	if (token) {
		headers = { headers: { Authorization: `Bearer ${token}` } };
	}

	headers['Content-Type'] = 'multipart/form-data';
	headers.redirect = 'follow';
	headers.mode = 'no-cors';
	return await axiosConfig.post(
		'account/profile/image',
		formData,
		headers
	);
};
export const isAdmin = async () => await axiosConfig.get('account/admin');
export const loadProfileByUserId = async (userId) => await axiosConfig.get(`account/profile/${userId}`);
export const getAuthenticatedUserId = async () => await axiosConfig.get('account/user/id');
export const searchUsers = async (searchText) => await axiosConfig.get(`account/users/search/${searchText}`);
export const getUserById = async (userId) => await axiosConfig.get(`account/users/${userId}`);
export const getUsersByIds = async (userIds) => {
	if (!userIds || !userIds.length) return null;
	return await axiosConfig.post('account/users/list', { userIds });
};

// returns the filename
export const uploadMessageAttachment = async (formData, token) => {
	let headers = authHeaders();
	if (token) {
		headers = { headers: { Authorization: `Bearer ${token}` } };
	}

	headers['Content-Type'] = 'multipart/form-data';
	headers.redirect = 'follow';
	headers.mode = 'no-cors';
	return await axiosConfig.post(
		'file/upload',
		formData,
		headers
	);
};

export const getMessageAttachmentFolder = async () => await axiosConfig.get('file/folder/message');
export const getFileBytes = async (fileName) => await axiosConfig.get(`file/attachment/${fileName}`);
export const getRefundFileBytes = async (fileName) => await axiosConfig.get(`file/refunds/${fileName}`);
