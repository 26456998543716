export const SHIPPING_FEE_PRODUCT_ID = 'DJAWN-SHIPPING';
export const EVENT_STOPPED_STATUS = 60;
export const EVENT_PLANNED_STATUS = 68;
export const EVENT_PAUSED_STATUS = 61;
export const EVENT_LIVE_STATUS = 59;
export const EVENT_CANCELLED_STATUS = 103;
export const RANDOMIZED = 9;
export const PICK_YOUR_SLOTS = 10;
export const RIP_N_SHIP = 11;
export const AUCTION = 12;
export const REFUND_REASON_REFERENCE_TYPE_ID = 105;
export const REFUND_DECLINE_REASON_REFERENCE_TYPE_ID = 107;
export const SLOT_STATUS_SOLD = 49;
export const DJAWN_USER_ID = 199;
export const ORDER_DETAIL_STATUS_COMPLETED = 286;
export const FREE_SHIPPING_REFERENCE_DATA = 138;
export const SHIPPING_TYPES_REFERENCE_TYPE = 39;
export const SHIPPING_CARDS_REFERENCE_TYPE = 38;
export const PRODUCT_SELECTION_REFERENCE_TYPE_ID = 145;
export const ALL_PRODUCTS_PRODUCT_SELECTION_OPTION = 335;
export const SELECT_PRODUCTS_PRODUCT_SELECTION_OPTION = 336;

export const ACTIVE_PRODUCT_STATUS_ID = 118;
