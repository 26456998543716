import React, { useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import DatePicker from 'react-datepicker';
import { InfoIcon } from '../../components/Event/event-icons';
import { CreateEventContext } from '../../contexts/create-event';

import 'react-datepicker/dist/react-datepicker.css';
import useErrorNotifier from '../../hooks/use-error-notifier';
import { getMinimumTime } from '../../utils/formatter';
import NextButton from '../../components/button/next-button';

const SetupEvent = () => {
	const { showToastError } = useErrorNotifier();
	const {
		eventTypes,
		eventName: eventNameContext,
		setEventName: setEventNameContext,
		eventDate,
		setEventDate,
		eventTypeId,
		setEventTypeId,
		setActiveStep
	} = useContext(CreateEventContext);
	const [eventName, setEventName] = useState(eventNameContext);
	const [eventTime, setEventTime] = useState(eventDate);
	const now = new Date();
	const today = new Date(now.setDate(now.getDate()));
	today.setHours(now.getHours() + 1, 0, 0, 0);
	const [startDate, setStartDate] = useState(today);
	const [, setMinimumTime] = useState(eventDate);

	const initialDateTimes = () => {
		const hours = today.getHours();
		const minutes = today.getMinutes();
		let sHours = hours.toString();
		let sMinutes = minutes.toString();
		if (hours < 10) sHours = `0${sHours}`;
		if (minutes < 10) sMinutes = `0${sMinutes}`;
		setEventDate({
			...eventDate,
			hour: sHours,
			date: today.toLocaleDateString(),
			minutes: sMinutes,
			amOrPm: today.toLocaleTimeString().slice(-2)
		});

		setEventTime(`${sHours}:${sMinutes}`);
	};

	useEffect(() => {
		initialDateTimes();
	}, []);

	const onEventDateChange = (value) => {
		const currentDate = new Date();

		if (value < currentDate) {
			showToastError('The date and time must not be in the past');
			const formatedToday = new Date(
				currentDate.setDate(currentDate.getDate())
			);
			formatedToday.setHours(currentDate.getHours());
			formatedToday.setMinutes(currentDate.getMinutes() + 2);
			formatedToday.setSeconds(0);
			formatedToday.setMilliseconds(0);

			setStartDate(formatedToday);
			setEventDate({ ...eventDate, date: formatedToday.toLocaleDateString() });
		} else {
			setMinimumTime(getMinimumTime(value));
		}
		setStartDate(value);
		setEventDate(value.toLocaleDateString());
		setEventDate({ ...eventDate, date: value.toLocaleDateString() });
	};

	const onEventTimeChange = (value) => {
		if (value == '') return;
		const time = value.split(':');

		setEventTime(value);
		const dateVal = startDate;
		dateVal.setHours(time[0]);
		dateVal.setMinutes(time[1]);
		setStartDate(dateVal);
		setEventDate({
			...eventDate,
			date: dateVal.toLocaleDateString(),
			amOrPm: dateVal.toLocaleTimeString().slice(-2),
			hour: time[0],
			minutes: time[1]
		});
	};

	const onNextClicked = () => {
		const currentDate = new Date();
		const selectedDate = new Date(startDate);
		if (selectedDate < currentDate) {
			showToastError('The date and time must not be in the past');
			return;
		}
		if (
			!eventName.length
			|| eventTypeId <= 0
			|| eventDate.length <= 0
			|| eventDate.eventHour < 0
			|| eventDate.eventMinutes < 0
		) {
			showToastError('Please fill all the fields or validate.');
			return;
		}

		setEventNameContext(eventName);
		setActiveStep(1);
	};
	return (
		<div className="flex-grow p-4">
			<ReactTooltip />
			<div className="justify-center mx-auto w-full md:w-3/4 lg:w-1/3">
				<div className="mb-6">
					<label
						htmlFor="event_name"
						className="block text-sm font-medium text-gray-700 pl-2"
					>
						<div className="flex justify-start space-x-2">
							<div>Event Name</div>
							<div data-tip="Set your event apart by giving it a descriptive name.">
								<InfoIcon />
							</div>
						</div>
					</label>
					<div className="mt-1 relative rounded-md shadow-sm">
						<input
							type="text"
							name="event_name"
							id="event_name"
							maxLength="60"
							className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
							placeholder="Event name"
							value={eventName}
							onBlur={(e) => setEventName(e.target.value)}
							onChange={(e) => setEventName(e.target.value)}
						/>
					</div>
				</div>

				<div className="mb-6">
					<label
						htmlFor="event_type"
						className="block text-sm font-medium text-gray-700 pl-2"
					>
						<div className="flex justify-start space-x-2">
							<div>Event Type</div>
							<div data-tip="Are you doing a Rip & Ship or a Break?">
								<InfoIcon />
							</div>
						</div>
					</label>
					<div className="mt-1 relative rounded-md shadow-sm">
						<select
							id="event_type"
							name="event_type"
							autoComplete="event_type"
							className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							onBlur={(e) => setEventTypeId(e.target.value)}
							onChange={(e) => setEventTypeId(e.target.value)}
							value={eventTypeId}
						>
							<option key="0" value="0">
								Select event type
							</option>
							{eventTypes.map((object) => (
								<option
									key={object.referenceDataOrder}
									value={object.referenceDataId}
								>
									{object.referenceDataValue}
								</option>
							))}
						</select>
					</div>
				</div>

				<div className="mb-6">
					<label
						htmlFor="event_date"
						className="block text-sm font-medium text-gray-700 pl-2"
					>
						Event Date
					</label>
					<div className="mt-1 relative rounded-md shadow-sm grid justify-items-stretch">
						<DatePicker
							className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
							selected={startDate}
							minDate={new Date()}
							onChange={(date) => onEventDateChange(date)}
						/>
					</div>
				</div>

				<div className="mb-6">
					<label
						htmlFor="event_time"
						className="block text-sm font-medium text-gray-700 pl-2"
					>
						Event Time
					</label>
					<div className="mt-1 relative rounded-md shadow-sm grid justify-items-stretch step1-time-picker">
						<input
							type="time"
							value={eventTime}
							onChange={(ev) => onEventTimeChange(ev.target.value)}
							className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
						/>
					</div>
				</div>
			</div>
			<div className="flex justify-center mx-auto">
				<div>
					<NextButton onClick={onNextClicked} />
				</div>
			</div>
		</div>
	);
};

export default SetupEvent;
