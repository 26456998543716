import React from 'react';
import { Link } from 'gatsby';
import Button from '../../components/button';
import { getDayOfMonth, getMonthName, getYear } from '../../utils/formatter';

const MobileRefundRequestsTable = ({ data }) => {
	const paddingSize = '1';
	return (
		<div>
			{data.map((refundRequest) => {
				const formattedRefundRequest = refundRequest.requestDate;
				const monthName = getMonthName(formattedRefundRequest);
				const year = getYear(formattedRefundRequest);
				const day = getDayOfMonth(formattedRefundRequest);
				return (
					<div
						className="flex border-b-2 justify-between"
						key={refundRequest.refundId}
					>
						<div className="flex">
							<div className="order-date-container flex flex-col justify-between items-center content-between mx-1 my-1 px-2">
								<p className="text-xl">{monthName}</p>
								<p className="text-2xl">
									<b>{day}</b>
								</p>
								<p>{year}</p>
							</div>
							<div className="flex flex-col px-1 my-2 text-xs">
								<div className={`py-${paddingSize}`}>
									<span>Refund no.: </span>
									<span>{refundRequest.refundNumber}</span>
								</div>
								<div className={`py-${paddingSize}`}>
									<span>Buyer: </span>
									<span>{refundRequest.order.buyerName}</span>
								</div>
								<div className={`py-${paddingSize}`}>
									<Link
										to={`/sales/refund/details/${refundRequest.refundId}`}
										state={{
											refundDetails: refundRequest
										}}
									>
										<Button
											mobileWidth="20"
											mobileHeight="6"
											roundedSize="xl"
											additionalClassName="flex items-center"
										>
											<span className="px-1 md:px-2 ml-2">Details</span>
											<div className="mr-1 rounded-lg border border-white">
												<svg
													className="w-3 h-3"
													fill="none"
													stroke="currentColor"
													viewBox="0 0 24 24"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth={2}
														d="M13 5l7 7-7 7M5 5l7 7-7 7"
													/>
												</svg>
											</div>
										</Button>
									</Link>
								</div>
							</div>
						</div>
						<div className="my-2 pl-2 w-20 flex flex-col justify-center items-center">
							<div className="order-total-price">
								<b>
									$
									{' '}
									{Number(refundRequest.totalCostWithSellerFeeExtra).toFixed(2)}
								</b>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default MobileRefundRequestsTable;
