import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import Layout from '../components/layouts/layout-default';
import Header from '../components/header';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: blue;
`;

const Privacy = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(false);
	}, []);

	return (
		<Layout>
			<div className="flex flex-col flex-1 w-full">

				<Header siteTitle="Privacy Policy" auth />
				{loading && (
					<div className="w-screen h-screen bg-white absolute left-0 top-0 z-50">
						<div className="flex h-full items-center">
							<BounceLoader
								loading
								css={override}
								size={30}
							/>
						</div>
					</div>
				)}

				{!loading && (
					<div className="flex flex-col flex-1 justify-start content-center">
						<div className="flex flex-1 flex-col justify-items-center bg-white w-8/12 xxs:w-full lg:w-8/12 mx-auto px-6 xxs:px-0 py-10 xxs:py-8 rounded-lg">
							<h2 className="text-xl text-gray-700 font-semibold text-center sm:text-lg sm:max-w-xl sm:mx-auto  md:text-xl mb-4">
                                PRIVACY POLICY
							</h2>
							<div className="w-6 h-0.5 mx-auto bg-gray-600 mb-6" />

							<div className="flex flex-col w-full mx-auto px-16 xxs:px-8 md:px-12">
								<p className="text-base font-normal text-gray-900 mt-8  mb-4">
                                    This Privacy Policy describes
                                    how www.djawn.com (the “Site” or “we”)
                                    collects, uses, and discloses your Personal
                                    Information when you visit or make a
                                    purchase from the Site.
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-4 mb-4">
                                    Collecting Personal Information
								</p>

								<p className="text-base font-normal text-gray-900  mb-6">
                                    When you visit the Site, we collect certain
                                    information about your device, your
                                    interaction with the Site, and information
                                    necessary to process your purchases. We may
                                    also collect additional information if you
                                    contact us for customer support. In this
                                    Privacy Policy, we refer to any information
                                    that can uniquely identify an individual
                                    (including the information below) as
                                    “Personal Information”. See the list below
                                    for more information about what Personal
                                    Information we collect and why.
								</p>

								<p className="text-base font-normal text-gray-900  mb-4">
                                    Device information
								</p>

								<ul className="list-disc pl-20 xxs:pl-8 lg:pl-16 mb-8">
									<li>
										<p className="text-md font-normal text-gray-800 mb-4">
											<span className="font-semibold">
                                                Examples of Personal Information
                                                collected:
											</span>
											{' '}
                                            type of web browser, device, general
                                            location/time zone, cookie
                                            information.
										</p>
									</li>
									<li>
										<p className="text-md font-normal text-gray-800 mb-4">
											<span className="font-semibold">
                                                Purpose of collection:
											</span>
											{' '}
                                            to load the Site accurately for you,
                                            and to perform analytics on Site
                                            usage to optimize our Site.
										</p>
									</li>
									<li>
										<p className="text-md font-normal text-gray-800 mb-4">
											<span className="font-semibold">
                                                Source of collection:
											</span>
											{' '}
                                            Collected automatically when you
                                            access our Site using cookies, log
                                            files, web beacons, tags, or
                                            pixels (Facebook pixel/Google
                                            Analytics).
										</p>
									</li>
									<li>
										<p className="text-md font-normal text-gray-800 mb-4">
											<span className="font-semibold">
                                                Disclosure for a business
                                                purpose:
											</span>
											{' '}
                                            N/A
										</p>
									</li>
								</ul>

								<p className="text-base font-normal text-gray-900  mb-4">
                                    Order information
								</p>

								<ul className="list-disc pl-20 xxs:pl-8 lg:pl-16 mb-8">
									<li>
										<p className="text-md font-normal text-gray-800 mb-4 break-words">
											<span className="font-semibold">
                                                Examples of Personal Information
                                                collected:
											</span>
											{' '}
                                            name, billing address, shipping
                                            address, payment information
                                            (including credit card numbers,
                                            paypal and/or venmo user account),
                                            email address, and phone number.
										</p>
									</li>
									<li>
										<p className="text-md font-normal text-gray-800 mb-4 break-words">
											<span className="font-semibold">
                                                Purpose of collection:
											</span>
											{' '}
                                            to provide products or services to
                                            you to fulfill our contract, to
                                            process your payment information,
                                            arrange for shipping, and provide
                                            you with invoices and/or order
                                            confirmations, communicate with you,
                                            screen our orders for potential risk
                                            or fraud, and when in line with the
                                            preferences you have shared with us,
                                            provide you with information or
                                            advertising relating to our products
                                            or services.
										</p>
									</li>
									<li>
										<p className="text-md font-normal text-gray-800 mb-4">
											<span className="font-semibold">
                                                Source of collection:
											</span>
											{' '}
                                            collected from you.
										</p>
									</li>
									<li>
										<p className="text-md font-normal text-gray-800 mb-4">
											<span className="font-semibold">
                                                Disclosure for a business
                                                purpose:
											</span>
											{' '}
                                            shared with our processor Paypal
										</p>
									</li>
								</ul>

								<p className="text-base font-normal text-gray-900  mb-4">
                                    Customer support information
								</p>

								<ul className="list-disc pl-20 xxs:pl-8 lg:pl-16 mb-8">
									<li>
										<p className="text-md font-normal text-gray-800 mb-4 break-words">
											<span className="font-semibold">
                                                Examples of Personal Information
                                                collected:
											</span>
											{' '}
                                            order number, transaction date,
                                            name, billing address, shipping
                                            address, payment information
                                            (including credit card numbers,
                                            paypal and/or venmo user account),
                                            email address, and phone number.
										</p>
									</li>
									<li>
										<p className="text-md font-normal text-gray-800 mb-4 break-words">
											<span className="font-semibold">
                                                Purpose of collection:
											</span>
											{' '}
                                            to provide customer support.
										</p>
									</li>
									<li>
										<p className="text-md font-normal text-gray-800 mb-4">
											<span className="font-semibold">
                                                Source of collection:
											</span>
											{' '}
                                            collected from you.
										</p>
									</li>
									<li>
										<p className="text-md font-normal text-gray-800 mb-4">
											<span className="font-semibold">
                                                Disclosure for a business
                                                purpose:
											</span>
											{' '}
                                            N/A
										</p>
									</li>
								</ul>

								<p className="text-lg font-semibold text-gray-800 mt-4 mb-4">
                                    Minors
								</p>
								<p className="text-base font-normal text-gray-900  mb-8">
                                    The Site is not intended for individuals
                                    under the age of 18. We do not intentionally
                                    collect Personal Information from children.
                                    If you are the parent or guardian and
                                    believe your child has provided us with
                                    Personal Information, please contact us at
                                    the address below to request deletion.
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-4 mb-4">
                                    Sharing Personal Information
								</p>
								<p className="text-base font-normal text-gray-900  mb-6">
                                    We share your Personal Information with
                                    service providers to help us provide our
                                    services and fulfill our contracts with you,
                                    as described above. For example:
								</p>

								<ul className="list-disc pl-20 xxs:pl-8 lg:pl-16 mb-8">
									<li>
										<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                            We may share your Personal
                                            Information to comply with
                                            applicable laws and regulations, to
                                            respond to a subpoena, search
                                            warrant or other lawful request for
                                            information we receive, or to
                                            otherwise protect our rights.
										</p>
									</li>
								</ul>

								<p className="text-lg font-semibold text-gray-800 mt-4 mb-4">
                                    Behavioral Advertising
								</p>
								<p className="text-base font-normal text-gray-900  mb-6">
                                    As described above, we use your Personal
                                    Information to provide you with targeted
                                    advertisements or marketing communications
                                    we believe may be of interest to you. For
                                    example:
								</p>

								<ul className="list-disc pl-20 xxs:pl-8 lg:pl-16 mb-8">
									<li>
										<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                            We use Google Analytics to help us
                                            understand how our customers use the
                                            Site. You can read more about how
                                            Google uses your Personal
                                            Information
                                            here:
											{' '}
											<a className="text-blue-400 hover:text-blue-700" href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>
                                            .
											{' '}
You can also opt-out of Google Analytics
                                            here:
											{' '}
											<a className="text-blue-400 hover:text-blue-700" href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>
.
										</p>
									</li>
									<li>
										<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                            We share information about your use
                                            of the Site, your purchases, and
                                            your interaction with our ads on
                                            other websites with our advertising
                                            partners. We collect and share some
                                            of this information directly with
                                            our advertising partners, and in
                                            some cases through the use of
                                            cookies or other similar
                                            technologies (which you may consent
                                            to, depending on your location).
										</p>
									</li>
								</ul>

								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    For more information about how targeted
                                    advertising works, you can visit the Network
                                    Advertising Initiative’s (“NAI”) educational
                                    page
                                    at
									{' '}
									<a className="text-blue-400 hover:text-blue-700" href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</a>
.
								</p>
								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    You can opt out of targeted advertising via:
								</p>

								<ul className="list-disc pl-20 xxs:pl-8 lg:pl-16 mb-8">
									<li>
										<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                            FACEBOOK
                                            -
											{' '}
											<a className="text-blue-400 hover:text-blue-700" href="https://www.facebook.com/settings/?tab=ads">https://www.facebook.com/settings/?tab=ads</a>
										</p>
									</li>
									<li>
										<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                            GOOGLE
                                            -
											{' '}
											<a className="text-blue-400 hover:text-blue-700" href="https://www.google.com/settings/ads/anonymous">https://www.google.com/settings/ads/anonymous</a>
										</p>
									</li>
								</ul>

								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    Additionally, you can opt out of some of
                                    these services by visiting the Digital
                                    Advertising Alliance’s opt-out portal
                                    at:
									{' '}
									<a className="text-blue-400 hover:text-blue-700" href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>
.
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-4 mb-4">
                                    Using Personal Information
								</p>

								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    We use your personal Information to provide
                                    our services to you, which includes:
                                    offering products for sale, processing
                                    payments, shipping and fulfillment of your
                                    order, and keeping you up to date on new
                                    products, services, and offers.
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-4 mb-4">
                                    Your rights
								</p>
								<p className="text-lg font-semibold text-gray-800 mt-4 mb-4">
                                    CCPA
								</p>

								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    If you are a resident of California, you
                                    have the right to access the Personal
                                    Information we hold about you (also known as
                                    the ‘Right to Know’), to port it to a new
                                    service, and to ask that your Personal
                                    Information be corrected, updated, or
                                    erased. If you would like to exercise these
                                    rights, please contact us through the
                                    contact information below.
								</p>

								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    If you would like to designate an authorized
                                    agent to submit these requests on your
                                    behalf, please contact us at the address
                                    below.
								</p>
								<p className="text-lg font-semibold text-gray-800 mt-4 mb-4">
                                    Cookies
								</p>

								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    A cookie is a small amount of information
                                    that is downloaded to your computer or
                                    device when you visit our Site. We use a
                                    number of different cookies, including
                                    functional, performance, advertising, and
                                    social media or content cookies. Cookies
                                    make your browsing experience better by
                                    allowing the website to remember your
                                    actions and preferences (such as login and
                                    region selection). This means you do not
                                    have to re-enter this information each time
                                    you return to the site or browse from one
                                    page to another. Cookies also provide
                                    information on how people use the website,
                                    for instance whether it is their first time
                                    visiting or if they are a frequent visitor.
								</p>
								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    The length of time that a cookie remains on
                                    your computer or mobile device depends on
                                    whether it is a “persistent” or “session”
                                    cookie. Session cookies last until you stop
                                    browsing and persistent cookies last until
                                    they expire or are deleted. Most of the
                                    cookies we use are persistent and will
                                    expire between 30 minutes and two years from
                                    the date they are downloaded to your device.
								</p>
								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    You can control and manage cookies in
                                    various ways. Please keep in mind that
                                    removing or blocking cookies can negatively
                                    impact your user experience and parts of our
                                    website may no longer be fully accessible.
								</p>
								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    Most browsers automatically accept cookies,
                                    but you can choose whether or not to accept
                                    cookies through your browser controls, often
                                    found in your browser’s “Tools” or
                                    “Preferences” menu. For more information on
                                    how to modify your browser settings or how
                                    to block, manage or filter cookies can be
                                    found in your browser’s help file or through
                                    such sites as
									{' '}
									<a className="text-blue-400 hover:text-blue-700" href="https://www.allaboutcookies.org/">www.allaboutcookies.org</a>
.
								</p>
								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    Additionally, please note that blocking
                                    cookies may not completely prevent how we
                                    share information with third parties such as
                                    our advertising partners. To exercise your
                                    rights or opt-out of certain uses of your
                                    information by these parties, please follow
                                    the instructions in the “Behavioral
                                    Advertising” section above.
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-4 mb-4">
                                    Do Not Track
								</p>

								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    Please note that because there is no
                                    consistent industry understanding of how to
                                    respond to “Do Not Track” signals, we do not
                                    alter our data collection and usage
                                    practices when we detect such a signal from
                                    your browser.
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-4 mb-4">
                                    Changes
								</p>

								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    We may update this Privacy Policy from time
                                    to time in order to reflect, for example,
                                    changes to our practices or for other
                                    operational, legal, or regulatory reasons.
								</p>

								<p className="text-lg font-semibold text-gray-800 mt-4 mb-4">
                                    Contact
								</p>

								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    For more information about our privacy
                                    practices, if you have questions, or if you
                                    would like to make a complaint, please
                                    contact us by e-mail at [email address] or
                                    by mail using the details provided below:
								</p>
								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    djawn LLC
								</p>
								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    Attn: Privacy Dept.
								</p>
								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    109 Ravenhill Rd
								</p>
								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    Phoenixville, PA 19460
								</p>
								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    United States
								</p>
								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    Last updated: February 15th, 2021
								</p>
								<p className="text-md font-normal text-gray-800 mb-4 break-words">
                                    If you are not satisfied with our response
                                    to your complaint, you have the right to
                                    lodge your complaint with the relevant data
                                    protection authority
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default Privacy;
