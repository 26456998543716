import React from 'react';

import { toast } from 'react-toastify';
import CustomDontShowAgain from '../components/common/custom-dont-show-again';

const onDontShowAgain = (id) => {
	toast.dismiss();
	localStorage.setItem(JSON.stringify(id), 'saved');
};

const isIdInLocalStorage = (id) => {
	const exists = localStorage.getItem(JSON.stringify(id));
	return exists;
};

export const dontShowAgainToast = (message, id) => {
	if (isIdInLocalStorage(id)) return null;

	toast.info(
		<CustomDontShowAgain
			message={message}
			onDontShowAgain={() => onDontShowAgain(id)}
		/>,
		{
			position: 'top-center',
			autoClose: 500000,
			hideProgressBar: true,
			closeOnClick: false,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'colored'
		}
	);
};
