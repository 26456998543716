import React, { useEffect, useState } from 'react';
import { getCurrentUser } from '../utils/auth';

const BuyerRipNShipExitConfirmation = ({
	showModal, setShowModal, onRequestRefund, buyers, isEventInformationReinitiating
}) => {
	const [, setHasPurchased] = useState(false);

	useEffect(() => {
		if (isEventInformationReinitiating) return;

		const currentUserId = getCurrentUser().id;
		if (currentUserId == null || !buyers || !buyers.length) return;
		const purchasedItemBuyer = buyers.filter((f) => f.userId == currentUserId && f.purchasedItem === true)[0];
		if (purchasedItemBuyer) {
			setHasPurchased(true);
		} else {
			setHasPurchased(false);
		}
	}, [buyers]);

	if (isEventInformationReinitiating) return null;

	return (
		<>
			{showModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
							<div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">

								<svg
									className="cursor-pointer w-6 h-6"
									onClick={() => setShowModal(false)}
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</div>
							<div className="flex flex-col px-6 py-5 bg-gray-50 mb-10">
								<p className="font-semibold text-gray-700 pt-10 mb-5 px-10 text-lg">
									Please note that you have purchased an item for the event, please confirm one of the following options.
								</p>
								<div className="flex xxs:flex-row justify-center items-center xxs:w-full xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0 mb-5">
									<div className="mr-3">
										<button
											type="button"
											className="h-12 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white bg-red-700 hover:bg-red-900 md:py-4 md:text-lg md:px-10"
											onClick={() => {
												setShowModal(false);
											}}
										>
											Cancel
										</button>
									</div>
									<div className="mr-3">
										<button
											type="button"
											className="h-12 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white bg-red-700 hover:bg-red-900 md:py-4 md:text-lg md:px-10"
											onClick={onRequestRefund}
										>
											Request Refund
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black" />
				</>
			) : null}
		</>
	);
};

export default BuyerRipNShipExitConfirmation;
