/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useRef, useState, useEffect } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import Moment from 'moment';
import { elementScrollIntoView, polyfill } from 'seamless-scroll-polyfill';
import { isMobile } from 'react-device-detect';
import Message from './chat/message';
import {
	secondsBetweenFirstAndSecondDate,
	getElapsedTimestamp,
	getTimeWithAdditionalSeconds,
	isDateBigger
} from '../utils/formatter';
import { getEventMessages } from '../api/event.request';
import { useInterval } from './common/use-interval';
import Conversation from './conversation';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		textAlign: 'center',
		borderRadius: '10px'
	}
};

const VideoPlayer = ({
	eventDetails,
	videoReady,
	setVideoReady,
	room,
	isUsingTwilioChatService,
	conversation,
	roomConversationMessages
}) => {
	const [modalIsOpen, setIsOpen] = useState(false);
	const [playing, setPlaying] = useState(false);
	const [messages, setMessages] = useState([]);
	const [filteredMessages, setFilteredMessages] = useState([]);
	const [safariMode, setSafariMode] = useState(false);

	const playerRef = useRef(null);
	const playerDivRef = useRef(null);
	const bottomListRef = useRef();

	useEffect(() => {
		if (
			!filteredMessages
			|| !filteredMessages.length
			|| !isUsingTwilioChatService
		) return;
		if (safariMode) {
			setSafariMode(true);
			polyfill();
			elementScrollIntoView(bottomListRef.current, {
				behavior: 'smooth',
				block: 'nearest',
				inline: 'start',
				maxHeight:
					playerDivRef && playerDivRef.current
						? playerDivRef.current.clientHeight
						: '100%'
			});
		} else {
			bottomListRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'start',
				maxHeight:
					playerDivRef && playerDivRef.current
						? playerDivRef.current.clientHeight
						: '100%'
			});
		}
	}, [JSON.stringify(filteredMessages), isUsingTwilioChatService]);

	useEffect(() => {
		if (!eventDetails && !eventDetails.event) return;
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		setSafariMode(isSafari);
		if (isUsingTwilioChatService) {
			getEventMessages(room.eventRoomId)
				.then((res) => {
					setMessages(res.data);
				})
				.catch((error) => {
					if (error.response) {
						console.error(error.message);
					}
				});
		} else if (roomConversationMessages && roomConversationMessages.length) {
			setMessages(
				roomConversationMessages.map((m) => {
					m.date_created = m.sent_date;
					return m;
				})
			);
		}
	}, [eventDetails, isUsingTwilioChatService, roomConversationMessages]);

	function closeModal() {
		setIsOpen(false);
	}

	const SECOND_MS = 1000;

	useInterval(() => {
		if (playerRef && playerRef.current && playing) {
			const currentTime = playerRef.current.getCurrentTime();
			const filterTime = getTimeWithAdditionalSeconds(
				Moment.utc(room.recordingStartDate),
				currentTime
			);
			const tempMessages = messages.filter(
				(f) => isDateBigger(filterTime, f.date_created) < 0
			);

			if (filteredMessages.length !== tempMessages.length) {
				setFilteredMessages([...tempMessages]);
			}
		}
	}, [SECOND_MS, playing]);

	const handleBookmarkClick = (bookmarkDateTime) => {
		const secondsBetweenEventDateAndBookmarkDate = secondsBetweenFirstAndSecondDate(
			room.recordingStartDate,
			Moment.utc(bookmarkDateTime)
		);
		closeModal();
		playerRef.current.seekTo(secondsBetweenEventDateAndBookmarkDate);
	};

	return (
		<>
			{!videoReady && (
				<div className="flex justify-center items-center">
					<BounceLoader loading size={20} />
				</div>
			)}
			<div
				className={`flex flex-col md:flex-row w-full ${
					videoReady ? 'block md:h-96 h-105' : 'hidden'
				}`}
			>
				<div
					className={`${
						isUsingTwilioChatService
							? 'flex relative h-full'
							: 'relative md:h-full h-auto md:w-1/2 w-auto md:overflow-hidden'
					} video-wrapper`}
					ref={playerDivRef}
				>
					<ReactPlayer
						ref={playerRef}
						onReady={() => {
							setVideoReady(true);
						}}
						playing={playing}
						controls
						onPlay={() => setPlaying(true)}
						onPause={() => setPlaying(false)}
						height={!isMobile ? '100%' : ''}
						width={!isMobile ? '100%' : ''}
						style={safariMode ? { position: 'relative' } : {}}
						url={room.video}
						config={
							eventDetails.videoDownloadButtonEnabled == false
								? {
									file: {
										attributes: { controlsList: 'nodownload' }
									}
								  }
								: {}
						}
						playsinline
						className="object-fill past-events-video"
					/>
					<div
						className={`absolute ${!safariMode && 'inset-x-0'} top-0 md:1 h-16`}
						style={
							safariMode
								? { marginTop: '5px', marginRight: '5px', right: '0px' }
								: { marginTop: '5px', marginLeft: '5px' }
						}
					>
						<button
							type="button"
							className="w-100 bg-red-500 hover:bg-red-700 text-gray-100 hover:text-gray-100 text-xs font-semibold px-4 xxs:px-2 py-2 border-0"
							style={{ borderRadius: '20px' }}
							onClick={() => {
								setIsOpen(true);
							}}
						>
							Show bookmarks
						</button>
					</div>
				</div>
				{isUsingTwilioChatService ? (
					<div className="flex overflow-chat dark:text-white dark:bg-darkGray-50 w-auto md:w-1/2">
						<ul className="w-full h-full">
							{filteredMessages.map((message, i) => (
								<li key={i} className="w-full">
									<Message
										createdAt={getElapsedTimestamp(
											room.recordingStartDate,
											message.date_created.slice(0, -1)
										)}
										text={message.body}
										nickname={message.from}
									/>
								</li>
							))}
							{(!filteredMessages || filteredMessages.length == 0) && (
								<li className="w-full">
									<Message
										createdAt="Placeholder"
										text="Placeholder"
										isPlaceholder
										nickname="Placeholder"
									/>
								</li>
							)}
							<div ref={bottomListRef} />
						</ul>
					</div>
				) : (
					<>
						{filteredMessages && filteredMessages.length ? (
							<div className="md:w-1/2 w-full h-1/2 md:h-full">
								<Conversation
									startedConversation={conversation}
									showConversations
									hideSidebar
									insideLiveEvent
									removeHiddenOverflows
									disableConversation
									hideHeader
									absoluteEditorPosition={isMobile}
									removeListsFromToolbar={isMobile}
									additionalStyles={{
										borderTopRightRadius: '35px',
										borderTopLeftRadius: '25px'
									}}
									disableMessaging
									prePopulatedMessages={filteredMessages}
									hasPrepopulatedMessages
									showScreenName
								/>
							</div>
						) : null}
					</>
				)}

				<div>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						style={customStyles}
						contentLabel="Submit_Modal"
					>
						{room.bookmarks && room.bookmarks.length > 0 ? (
							<div
								className={`grid grid-cols-${
									room.bookmarks.length < 3 ? room.bookmarks.length : '3'
								}`}
							>
								{room.bookmarks.map((bookmark) => (
									<div
										className="flex flex-col gap-4 justify-items-center cursor-pointer"
										onClick={() => {
											handleBookmarkClick(
												getTimeWithAdditionalSeconds(
													bookmark.bookmarkDateTime,
													-1
												)
											);
										}}
										onKeyDown={() => {
											handleBookmarkClick(
												getTimeWithAdditionalSeconds(
													bookmark.bookmarkDateTime,
													-1
												)
											);
										}}
									>
										<img
											alt="bookmark"
											className="flex flex-wrap w-28 h-20 bg-gray-100 mx-2 object-cover"
											src={bookmark.image}
										/>
										<p className="font-medium">
											{getElapsedTimestamp(
												room.recordingStartDate,
												getTimeWithAdditionalSeconds(
													bookmark.bookmarkDateTime,
													-1
												)
											)}
										</p>
									</div>
								))}
							</div>
						) : (
							<div className="flex items-center">
								<p>There is no bookmark to be shown.</p>
							</div>
						)}
					</Modal>
				</div>
			</div>
		</>
	);
};

export default VideoPlayer;
