import React from 'react';

export function OptionsIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			id="Capa_1"
			x="0px"
			y="0px"
			viewBox="0 0 512 512"
			width="25"
			height="25"
			style={{ enableBackground: 'new 0 0 512 512;' }}
		>
			<g>
				<g>
					<path d="M492,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,236,492,236z" />
				</g>
			</g>
			<g>
				<g>
					<path d="M492,76H20C8.954,76,0,84.954,0,96s8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,76,492,76z" />
				</g>
			</g>
			<g>
				<g>
					<path d="M492,396H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20    C512,404.954,503.046,396,492,396z" />
				</g>
			</g>
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
		</svg>
	);
}

export function VideoCameraIcon() {
	return (
		<svg
			id="Capa_1"
			fill="currentColor"
			x="0px"
			y="0px"
			style={{ enableBackground: 'new 0 0 512 512;' }}
			viewBox="0 0 512 512"
			width="25"
			height="25"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g>
				<path d="m256 256c41.355 0 75-33.645 75-75s-33.645-75-75-75-75 33.645-75 75 33.645 75 75 75zm0-120c24.813 0 45 20.187 45 45s-20.187 45-45 45-45-20.187-45-45 20.187-45 45-45z" />
				<path d="m58.674 387.766c31.641 19.977 74.693 34.865 122.326 42.443v50.791c0 5.532 3.045 10.615 7.922 13.225 4.865 2.604 10.784 2.332 15.398-.744l90-60c4.173-2.782 6.68-7.465 6.68-12.481s-2.507-9.699-6.68-12.481l-90-60c-4.603-3.069-10.521-3.354-15.398-.744s-7.922 7.693-7.922 13.225v38.809c-41.76-7.13-79.045-20.196-106.31-37.41-28.819-18.196-44.69-40.001-44.69-61.399 0-17.736 10.484-35.429 30-51.245v51.245c0 8.284 6.716 15 15 15h362c8.284 0 15-6.716 15-15v-51.245c19.516 15.816 30 33.51 30 51.245 0 24.386-19.735 43.741-36.292 55.683-25.353 18.287-60.86 32.428-102.684 40.893-8.12 1.643-13.37 9.558-11.727 17.677 1.641 8.106 9.544 13.372 17.678 11.726 45.976-9.305 85.494-25.2 114.282-45.966 31.889-23 48.743-50.668 48.743-80.013 0-32.817-21.228-63.793-60-87.907v-152.093c0-8.284-6.716-15-15-15h-84.787l-16.819-16.82c-8.499-8.5-19.8-13.18-31.82-13.18h-95.146c-12.021 0-23.321 4.681-31.82 13.18l-16.821 16.82h-39.787v-15c0-8.284-6.716-15-15-15s-15 6.716-15 15v15h-15c-8.284 0-15 6.716-15 15v152.093c-38.772 24.114-60 55.09-60 87.907 0 32.527 20.289 62.531 58.674 86.766zm152.326 1.262 47.958 31.972-47.958 31.972zm-45-313.028c3.979 0 7.794-1.58 10.606-4.394l21.213-21.213c2.833-2.833 6.601-4.393 10.608-4.393h95.146c4.007 0 7.774 1.56 10.607 4.393l21.213 21.213c2.813 2.814 6.628 4.394 10.607 4.394h76v210h-332c0-11.289 0-191.61 0-210z" />
				<circle cx="376" cy="121" r="15" />
			</g>
		</svg>
	);
}
