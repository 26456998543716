import { useEffect, useState } from 'react';
import { banUserFromEvent } from '../api/event.request';
import { informationToast } from '../utils/information-toast';

const useSellerEventAttendeeActions = (eventId) => {
	const [selectedUserForActions, setSelectedUserForActions] = useState(null);
	const [banUserNickname, setBanUserNickname] = useState(null);
	const [userNicknameToBan, setUserNicknameToBan] = useState(null);
	const [bannedUsers, setBannedUsers] = useState([]);

	useEffect(() => {
		if (!banUserNickname) return;
		banUserFromEvent(eventId, banUserNickname)
			.then(() => {
				informationToast('The selected user has been removed');
				const tempBannedUsers = [...bannedUsers];
				tempBannedUsers.push(banUserNickname);
				setBannedUsers(tempBannedUsers);
				setBanUserNickname(null);
				setUserNicknameToBan(null);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [banUserNickname]);

	return {
		setBanUserNickname,
		setUserNicknameToBan,
		userNicknameToBan,
		selectedUserForActions,
		setSelectedUserForActions
	};
};

export default useSellerEventAttendeeActions;
