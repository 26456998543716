import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby-link';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { isMobile } from 'react-device-detect';
import { Link } from 'gatsby';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import HeaderHome from '../../../components/common/header-home';
import Layout from '../../../components/layouts/layout-default';
import Loader from '../../../components/loader/index';
import { formatUTCDateToLocal } from '../../../utils/formatter';
import OrderEventTable from '../../../components/order/order-event-table';
import MobileOrderEvent from '../../../components/order/mobile-order-event';
import {
	groupBy,
	isTruthy,
	bytesToMegaBytes
} from '../../../utils/commonFunction';
import {
	REFUND_REASON_REFERENCE_TYPE_ID,
	SHIPPING_FEE_PRODUCT_ID
} from '../../../utils/constants';
import useReferenceData from '../../../hooks/use-reference-data';
import Button from '../../../components/button';
import { getSlotName } from '../../../utils/slot';
import {
	orderDetailHasRefundRequested,
	sendRefundRequest,
	uploadRefundAttachments,
	hasUsedShippingPriceInARefundPreviously
} from '../../../api/payment.request';
import { defaultToastError } from '../../../utils/toast-error';
import SuccessModal from '../../../components/modals/success-modal';
import SelectRefundItemsMobile from '../../../containers/refund/select-refund-items-mobile';
import RefundItemDetailModal from '../../../containers/refund/refund-item-detail-modal';
import MobileRefundRequestDetails from '../../../containers/refund/mobile-refund-request-details';
import ConfirmationModal from '../../../components/confirmation-modal';
import useErrorNotifier from '../../../hooks/use-error-notifier';
import { ATTACHMENT_OVER_LIMITED_SIZE } from '../../../utils/errorCodes';
import useConversation from '../../../hooks/useConversation';
import { getAuthenticatedUserId } from '../../../api/auth.request';
import { getOrderStatusParagaph } from '../../../utils/element';
import { getDjawnUser } from '../../../api/referenceData.request';
import ConversationTypeMessage from '../../../components/conversation/conversation-type-message';
import PaymentBillingDetails from '../../../components/payment-billing-details';

const RefundPage = ({ location }) => {
	const [refundItems, setRefundItems] = useState([]);
	const [grossCost, setGrossCost] = useState(0);
	const [eventGroupedRefundItems, setEventGroupedRefundItems] = useState({});
	const [loading, setLoading] = useState(true);
	// eslint-disable-next-line no-unused-vars
	const [seller, setSeller] = useState(null);
	const [djawnFee, setDjawnFee] = useState(0);
	const [shippingPrice, setShippingPrice] = useState(0);
	const [order, setOrder] = useState(null);
	const [djawnFeeTip, setDjawnFeeTip] = useState('');
	const [attachments, setAttachments] = useState([]);
	const [totalShippingCostTip, setTotalShippingCostTip] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [totalCostTip, setTotalCostTip] = useState('');
	const [discount, setDiscount] = useState(0);
	const [requestedRefundReason, setRequestedRefundReason] = useState(null);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
	const [mobileRefund, setMobileRefund] = useState(false);
	const [totalOrderDetails, setTotalOrderDetails] = useState([]);
	const [selectableOrderDetails, setSelectableOrderDetails] = useState([]);
	const [refundReasons, refundReasonsLoaded] = useReferenceData(
		REFUND_REASON_REFERENCE_TYPE_ID
	);
	const [selectedRefundItem, setSelectedRefundItem] = useState(null);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const { setErrorFunction, showToastError } = useErrorNotifier();
	const [fullRefund, setFullRefund] = useState(false);
	const [orderDetailLength, setOrderDetailLength] = useState(0);
	const [shippingsPerEvent, setShippingsPerEvent] = useState(null);
	const {
		createConversation,
		findConversationByChild,
		sendMessage: sendConversationMessage
	} = useConversation();
	const [
		additionalInformationMessage,
		setAdditionalInformationMessage
	] = useState('');
	const [mobileRefundHeight, setMobileRefundHeight] = useState(0);

	const getRefundSummaryRefundTableRow = (name, price) => (
		<tr className="w-full">
			<td className="w-1/2">
				<b>{name}</b>
			</td>
			<td className="w-1/2 text-center">
				<span className="pr-2">$</span>
				{Number(Number(price)).toFixed(2)}
			</td>
		</tr>
	);
	const [mobileRefundDetailsPage, setMobileRefundDetailsPage] = useState(false);

	useEffect(() => {
		setSelectableOrderDetails(totalOrderDetails);
	}, [totalOrderDetails]);

	useEffect(() => {
		// 117 is the value of header and the bottom toolbar
		setMobileRefundHeight(window.innerHeight - 117);
	}, [mobileRefund]);

	const calculateTotalShipping = (
		shippings,
		items,
		accessEventDirectly = false
	) => {
		let total = 0;
		// eslint-disable-next-line no-unused-vars
		Object.keys(shippings).map((key, _) => {
			const shippingFeePerEvent = shippings[key][0];
			if (!shippingFeePerEvent || !shippingFeePerEvent.eventId) return;
			let foundEvent = null;
			if (accessEventDirectly) {
				foundEvent = items.find(
					(item) => item.eventId == shippingFeePerEvent.eventId
				);
			} else {
				foundEvent = items.find(
					(item) => item.orderDetails.eventId == shippingFeePerEvent.eventId
				);
			}
			if (foundEvent) {
				total += shippingFeePerEvent.cost;
			}
			return shippingFeePerEvent;
		});

		return total;
	};

	useEffect(() => {
		if (
			((!location.state.refundItems || !location.state.refundItems.length)
				&& !location.state.orderDetails)
			|| !location.state.order
		) {
			navigate('/orders');
		}

		if (location.state) {
			setDjawnFeeTip(location.state.djawnFeeTip);
			setTotalShippingCostTip(location.state.totalShippingCostTip);
			setTotalCostTip(location.state.totalCostTip);
		}

		if (
			location.state.shippingsPerEvent
			&& Object.keys(location.state.shippingsPerEvent).length
		) {
			setShippingsPerEvent(location.state.shippingsPerEvent);
			if (!isMobile) {
				const shipping = calculateTotalShipping(
					location.state.shippingsPerEvent,
					location.state.refundItems
				);
				hasUsedShippingPriceInARefundPreviously(
					location.state.order.orderId,
					location.state.refundItems[0].sellerId
				)
					.then((res) => {
						if (res.data == true) {
							setShippingPrice(0);
						} else {
							setShippingPrice(shipping);
						}
					})
					.catch((err) => {
						console.error(err);
					});
			}
		}
		if (location.state.fullRefund) {
			setFullRefund(location.state.fullRefund);
		}

		if (location.state.mobileRefund) {
			if (location.state.orderDetailLength) {
				setOrderDetailLength(location.state.orderDetailLength);
			}
			setMobileRefund(true);
			if (location.state.orderDetails) {
				setTotalOrderDetails(location.state.orderDetails);
			}
			setOrder(location.state.order);

			return;
		}
		setOrder(location.state.order);
		setSeller(location.state.refundItems[0].sellerId);
		const orderDetails = location.state.refundItems.map((item) => {
			item.orderDetails.quantity = item.quantityToRefund;
			return item.orderDetails;
		});
		let total = 0;
		let totalDjawnFees = 0;
		let totalDiscount = 0;
		let hasRipnShipProduct = false;
		location.state.refundItems.forEach((item) => {
			const orderDetail = item.orderDetails;
			total += item.quantityToRefund * orderDetail.unitCost;
			totalDjawnFees += orderDetail.buyerDjawnFee;
			totalDiscount += orderDetail.buyerDiscountAmount;
			if (!hasRipnShipProduct) {
				if (orderDetail.productId != null) {
					hasRipnShipProduct = true;
				}
			}
		});

		setDjawnFee(totalDjawnFees);
		setDiscount(totalDiscount);
		setGrossCost(total);
		setRefundItems(orderDetails);
	}, [location.state, isMobile]);

	const attachFile = (e) => {
		if (e && e.target && e.target.files && e.target.files[0]) {
			const file = e.target.files[0];
			// eslint-disable-next-line no-unused-vars
			const currentTargetSize = file.size;
			if (bytesToMegaBytes(currentTargetSize) > 10) {
				setErrorFunction(ATTACHMENT_OVER_LIMITED_SIZE);
				return;
			}
			setAttachments((prevFile) => [
				...prevFile,
				{
					fileName: file.name,
					size: file.size,
					file
				}
			]);
		}
	};

	const onRequestSend = () => {
		setLoading(true);
		sendRefundRequest(
			order.orderId,
			refundItems.map((item) => ({
				item1: item.orderDetailsId,
				item2: item.quantity == 0 ? 1 : item.quantity
			})),
			seller,
			requestedRefundReason,
			mobileRefund ? orderDetailLength != refundItems.length : !fullRefund
		)
			.then((res) => {
				if (res && res.data) {
					getAuthenticatedUserId().then((userRes) => {
						createConversation(
							`Refund - ${res.data.refundNumber}`,
							seller,
							true,
							false,
							{ refund: true, refund_id: res.data.refundId },
							true,
							[userRes.data]
						);

						getDjawnUser().then((djawnUserRes) => {
							findConversationByChild(
								'refund_id',
								res.data.refundId,
								false
							).then((foundConversation) => {
								if (foundConversation && foundConversation.id) {
									const url = `${window.location.origin}/sales/refund/details/${res.data.refundId}`;
									sendConversationMessage(
										`A refund request has been made.<a href="${url}" class="link-message-text ml-1" target="_blank">Please click on this link to view the refund request details.</a>`,
										foundConversation.id,
										djawnUserRes.data.userId
									);
									if (additionalInformationMessage != '<p><br></p>') {
										sendConversationMessage(
											additionalInformationMessage,
											foundConversation.id,
											userRes.data
										);
									}
								}
							});
						});
					});
					if (attachments && attachments.length) {
						const formData = new FormData();
						attachments.forEach((item) => {
							formData.append('files', item.file);
						});

						uploadRefundAttachments(formData, res.data.refundId).then(() => {
							setLoading(false);
							setIsSuccessModalOpen(true);
						});
					} else {
						setLoading(false);
						setIsSuccessModalOpen(true);
					}
				}
			})
			.catch((err) => {
				setLoading(false);
				if (err.response && err.response.data && err.response.data.error) {
					defaultToastError(err.response.data.error);
				}
			});
	};

	// TODO: Make this logic more reusable as this will be needed for desktop as well
	useEffect(() => {
		if (totalOrderDetails) {
			const firstRefundItem = [...refundItems][0];
			if (firstRefundItem) {
				const tempOrderDetails = { ...totalOrderDetails };
				Object.keys(tempOrderDetails).forEach((key) => {
					tempOrderDetails[key] = tempOrderDetails[key].filter(
						(orderDetail) => orderDetail.sellerId == firstRefundItem.sellerId
					);
				});
				setSelectableOrderDetails(tempOrderDetails);
			} else {
				setSelectableOrderDetails(totalOrderDetails);
			}
		}

		if (isMobile) {
			let total = 0;
			let totalDjawnFees = 0;
			let totalDiscount = 0;
			let hasRipNShipProduct = false;
			let shipping = 0;

			if (refundItems && refundItems.length && refundItems[0].sellerId) {
				setSeller(refundItems[0].sellerId);

				if (shippingsPerEvent && Object.keys(shippingsPerEvent).length) {
					shipping = calculateTotalShipping(
						shippingsPerEvent,
						refundItems,
						true
					);
				}
			}
			refundItems.forEach((orderDetail) => {
				total
					+= (orderDetail.quantity == 0 ? 1 : orderDetail.quantity)
					* orderDetail.unitCost;
				totalDjawnFees += orderDetail.buyerDjawnFee;
				totalDiscount += orderDetail.buyerDiscountAmount;
				if (!hasRipNShipProduct) {
					if (orderDetail.productId != null) {
						hasRipNShipProduct = true;
					}
				}
			});
			setDjawnFee(totalDjawnFees);
			setDiscount(totalDiscount);
			setGrossCost(total);

			setShippingPrice(shipping);
		}

		const groupedRefundItemsByEvent = groupBy(refundItems, 'eventId');
		setEventGroupedRefundItems(groupedRefundItemsByEvent);
	}, [refundItems, isMobile]);

	useEffect(() => {
		if (!eventGroupedRefundItems) return;
		setLoading(false);
	}, [eventGroupedRefundItems]);

	if (loading || !refundReasonsLoaded) {
		return <Loader />;
	}

	const isChecked = (orderDetailsId) => {
		const foundRefundItem = refundItems.find(
			(item) => item.orderDetailsId == orderDetailsId
		);
		return foundRefundItem;
	};

	return (
		<Layout auth>
			<div className="flex flex-col flex-1">
				<ReactTooltip />
				<SuccessModal
					isOpen={isSuccessModalOpen}
					onClose={() => setIsSuccessModalOpen(false)}
					onSuccess={() => navigate('/')}
					body="A refund request has been generated and the seller is notified of this request"
				/>
				<ConfirmationModal
					showModal={showConfirmationModal}
					setShowModal={setShowConfirmationModal}
					text="You have selected items for a refund. This request will be sent to the seller, for decision"
					confirmText="Request refund"
					cancelText="Cancel"
					showConfirmationIcon={false}
					onConfirm={() => {
						setShowConfirmationModal(false);
						onRequestSend();
					}}
				/>
				<HeaderHome
					siteTitle="Admin"
					hideSearch
					setLoading={setLoading}
					noMargins={isMobile}
				/>
				<ToastContainer />

				<div
					className={`flex flex-col ${
						!mobileRefund ? 'flex-grow' : ''
					} w-full ${
						mobileRefund ? '' : 'md:h-full h-80'
					} mt-4 items-center justify-between overflow-y-scroll md:overflow-y-auto`}
					style={
						mobileRefund && mobileRefundHeight > 0
							? { height: `${mobileRefundHeight}px` }
							: {}
					}
				>
					<RefundItemDetailModal
						item={selectedRefundItem}
						onClose={() => setSelectedRefundItem(null)}
					/>
					<div
						className={`flex flex-col ${
							isMobile ? 'w-full' : 'w-5/6'
						} rounded-lg content-center justify-center`}
					>
						<div className="flex justify-end mb-3 md:flex-row flex-col">
							<Link to={mobileRefund ? `/orders/${order.orderId}` : '/orders'}>
								<div className="rounded-lg px-3 py-2 md:my-0 my-3 bg-djawn text-white cursor-pointer hover:bg-djawn-300 text-center md:h-auto h-10 flex items-center">
									<span className="rounded-xl bg-black mr-2">
										<svg
											className="w-6 h-6"
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="3"
												d="M15 19l-7-7 7-7"
											/>
										</svg>
									</span>
									{mobileRefund ? 'Order details' : 'Order List'}
								</div>
							</Link>
						</div>
						{mobileRefund ? (
							<div className="w-full justify-center">
								{mobileRefundDetailsPage ? (
									<MobileRefundRequestDetails
										order={order}
										refundItems={
											refundItems.length
											&& refundItems.sort(
												(a, b) => a.slotPurchased - b.slotPurchased
											)
										}
										onInfoIconClicked={(orderDetail) => setSelectedRefundItem(orderDetail)}
										grossCost={grossCost}
										discount={discount}
										djawnFee={djawnFee}
										shippingPrice={shippingPrice}
										refundReasons={refundReasons}
										requestedRefundReason={requestedRefundReason}
										setRequestedRefundReason={setRequestedRefundReason}
										attachments={attachments}
										attachFile={attachFile}
										setAttachments={setAttachments}
										totalShippingCostTip={totalShippingCostTip}
										totalCostTip={totalCostTip}
										djawnFeeTip={djawnFeeTip}
										setAdditionalInformationMessage={
											setAdditionalInformationMessage
										}
									/>
								) : (
									Object.keys(selectableOrderDetails).map((keyName, index) => {
										if (
											!selectableOrderDetails[keyName]
											|| selectableOrderDetails[keyName].length == 0
										) {
											return null;
										}
										return (
											<div
												className={`order-details-header flex bg-white ${
													!isMobile && 'border-2 border-black'
												} rounded-lg md:justify-between md:px-4 px-2 py-4 flex-col md:flex-row shadow-lg mb-3`}
											>
												<SelectRefundItemsMobile
													tipMessage={
														index == 0 ? 'Select items for refund' : ''
													}
													key={index}
													onItemClicked={(orderDetail) => {
														orderDetailHasRefundRequested(
															orderDetail.orderDetailsId
														)
															.then(() => {
																let tempRefundItems = [...refundItems];
																const foundRefundItem = tempRefundItems.find(
																	(item) => item.orderDetailsId
																		== orderDetail.orderDetailsId
																);

																if (foundRefundItem) {
																	tempRefundItems = tempRefundItems.filter(
																		(item) => item.orderDetailsId
																			!= orderDetail.orderDetailsId
																	);
																} else {
																	tempRefundItems.push(orderDetail);
																}
																setRefundItems(tempRefundItems);
															})
															.catch((err) => {
																showToastError(err);
															});
													}}
													isChecked={(orderDetailsId) => isChecked(orderDetailsId)}
													orderDetails={selectableOrderDetails[keyName]
														.sort((a, b) => a.slotPurchased - b.slotPurchased)
														.filter(
															(o) => o.productId == null
																|| o.productId != SHIPPING_FEE_PRODUCT_ID
														)}
													onInfoIconClicked={(orderDetail) => setSelectedRefundItem(orderDetail)}
												/>
											</div>
										);
									})
								)}
							</div>
						) : (
							<div className="w-full justify-center">
								<div
									className={`order-details-header flex bg-white ${
										!isMobile && 'border-2 border-black'
									} rounded-lg md:justify-between md:px-4 px-2 py-4 flex-col md:flex-row shadow-lg`}
								>
									<p className="md:pb-0 pb-1">
										<b>Order Date:&nbsp;</b>
										{formatUTCDateToLocal(
											order.orderDateTime,
											'DD-MMM-YYYY HH:mm'
										).replace('--', '-')}
									</p>
									<p className="md:pb-0 pb-1">
										<b>Order Number:&nbsp;</b>
										{order.orderNumber}
									</p>
									<p className="md:pb-0 pb-1">
										<b>Customer Name:&nbsp;</b>
										{order.buyerName}
									</p>
									<p className="md:pb-0 pb-1 inline-flex">
										{getOrderStatusParagaph(
											order,
											order.orderStatus,
											'Order Status:'
										)}
									</p>
								</div>
								<div
									className={`flex bg-white ${
										!isMobile && 'border-2 border-black'
									} rounded-lg md:justify-between md:px-4 px-4 py-4 flex-col shadow-lg mt-3`}
								>
									{Object.keys(eventGroupedRefundItems).map(
										(keyName, index) => (
											<div
												className={`flex border-2 ${
													!isMobile && 'border-black'
												} w-full px-2 md:px-0`}
											>
												{!isMobile ? (
													<OrderEventTable
														key={index}
														orderDetails={eventGroupedRefundItems[keyName].sort(
															(a, b) => a.slotPurchased - b.slotPurchased
														)}
														showQuantityToRefund
														pagination={false}
														shippingPerEvent={() => {
															if (
																!shippingsPerEvent
																|| !Object.entries(shippingsPerEvent).length
															) {
																return 0;
															}
															const foundShippingPrice =																shippingsPerEvent[keyName];
															if (
																!foundShippingPrice
																|| !foundShippingPrice.length
															) {
																return 0;
															}
															return foundShippingPrice[0].cost;
														}}
													/>
												) : (
													<MobileOrderEvent
														key={index}
														orderDetails={eventGroupedRefundItems[keyName].sort(
															(a, b) => a.slotPurchased - b.slotPurchased
														)}
													/>
												)}
											</div>
										)
									)}
								</div>
								<div
									className={`flex bg-white ${
										!isMobile && 'border-2 border-black'
									} rounded-lg md:justify-between md:px-4 px-4 py-4 flex-col shadow-lg mt-3 mb-3`}
								>
									<p className="underline">Billing</p>
									{!isMobile && <br />}
									<div className="flex md:flex-row flex-col space-y-1 md:justify-start justify-between">
										<PaymentBillingDetails
											total={Number(
												Number(grossCost)
													+ Number(shippingPrice)
													+ (Number(djawnFee) - Number(discount))
											).toFixed(2)}
											subtotal={Number(grossCost).toFixed(2)}
											shipping={shippingPrice}
											djawnFees={djawnFee}
											djawnDiscount={discount}
											paymentMethod={order.paymentProcessor.referenceDataValue}
											shippingTip={totalShippingCostTip}
											djawnFeesTip={djawnFeeTip}
											totalTip={totalCostTip}
										/>
									</div>
								</div>
								<div
									className={`flex bg-white ${
										!isMobile && 'border-2 border-black'
									} rounded-lg md:justify-between md:px-4 px-4 py-4 flex-col shadow-lg mt-3 mb-3`}
								>
									<p className="underline">Refund details</p>
									<br />
									<div className="flex justify-between px-4 flex-col md:flex-row">
										<div className="flex space-x-3 w-full">
											<div>
												<div className="inline w-full whitespace-nowrap">
													<b>Refund Reason</b>
													<span className="pl-1 pr-2">:</span>
													<select
														id="request_reason"
														name="request_reason"
														autoComplete="request_reason"
														className="h-10 text-sm focus:outline-none border px-5 rounded-md mb-3 md:mb-0"
														onBlur={(e) => setRequestedRefundReason(e.target.value)}
														onChange={(e) => setRequestedRefundReason(e.target.value)}
														value={requestedRefundReason || ''}
													>
														<option key={null} value={0} selected>
															Refund Reason
														</option>
														{refundReasons.map((refundReason) => (
															<option
																key={refundReason.id}
																value={refundReason.id}
															>
																{refundReason.value}
															</option>
														))}
													</select>
												</div>
												<div className="flex items-center mt-3">
													<b>Attachments: </b>
													{attachments.length ? (
														<div className="grid grid-cols-1 ml-7">
															{attachments.map((object, i) => (
																<div
																	className="flex"
																	key={i}
																	style={{ width: '20.2rem' }}
																>
																	<div className="md:p-1 text-left md:text-left space-y-4 break-all overflow-x-hidden w-full">
																		<div className="flex flex-none items-center border rounded-2xl w-full">
																			<div className="flex items-center justify-between text-center w-full">
																				<span className="text-md mr-1 p-1 w-64 font-sans pl-5 truncate">
																					{object.fileName}
																				</span>
																				<button
																					type="button"
																					className="focus:outline-none text-black hover:text-black text-lg p-3 rounded-md rounded-tr-none rounded-br-none"
																					onClick={() => {
																						const tempAttachments = [
																							...attachments
																						].filter((item) => item !== object);
																						setAttachments(tempAttachments);
																					}}
																				>
																					<svg
																						className="w-5 h-4"
																						fill="none"
																						stroke="currentColor"
																						viewBox="0 0 24 24"
																						xmlns="http://www.w3.org/2000/svg"
																					>
																						<path
																							strokeLinecap="round"
																							strokeLinejoin="round"
																							strokeWidth="2"
																							d="M6 18L18 6M6 6l12 12"
																						/>
																					</svg>
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															))}
														</div>
													) : (
														<Button
															type="djawn"
															width="32"
															additionalClassName="px-4 ml-5 relative cursor-pointer"
															height="9"
														>
															<label
																htmlFor="attach"
																className="outline-none w-full h-full"
															>
																<input
																	type="file"
																	name="attach"
																	id="attach"
																	className="upload-file  cursor-pointer md:w-32 w-full md:h-9 h-auto"
																	size="60"
																	onChange={attachFile}
																	accept="image/png, image/jpg, image/jpeg, image/gif, image/tif, image/bmp, image/tiff, video/*, application/pdf, .csv, text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
																/>
																Upload file
															</label>
														</Button>
													)}
												</div>
												{attachments.length ? (
													<Button
														type="djawn"
														width="32"
														additionalClassName="mt-3 relative cursor-pointer"
														height="9"
													>
														<label
															htmlFor="attach"
															className="outline-none cursor-pointer w-full h-full"
														>
															<input
																type="file"
																name="attach"
																id="attach"
																className="upload-file md:w-32 w-full md:h-9 h-auto cursor-pointer"
																size="60"
																onChange={attachFile}
																accept="image/png, image/jpg, image/jpeg, image/gif, image/tif, image/bmp, image/tiff, video/*, application/pdf, .csv, text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
															/>
															Upload file
														</label>
													</Button>
												) : null}
											</div>
											<div className="flex flex-col w-full md:w-7/12 whitespace-nowrap">
												<b className="ml-5">Additional Information:</b>
												<ConversationTypeMessage
													hideSendButton
													toolbarId="cr"
													hideAttachmentsButton
													hidePlaceholder
													setMessageValue={setAdditionalInformationMessage}
													authenticated
													reduceReplyMessageSize={false}
													useInlineInputEditor={false}
													enabledChatInput
												/>
											</div>
										</div>
										<div className="w-1/3">
											<p>Summary of refund request</p>
											<div className="border-2 border-black p-1">
												<table className="w-full">
													<tbody className="w-full">
														{refundItems.map((refundItem) => getRefundSummaryRefundTableRow(
															refundItem.productId != null
																? refundItem.productName
																: getSlotName(refundItem),
															refundItem.unitCost
																	* (refundItem.quantity == 0
																		? 1
																		: refundItem.quantity)
														))}
														{!!(shippingPrice > 0)
															&& getRefundSummaryRefundTableRow(
																'Shipping',
																shippingPrice
															)}
														<br />
														{getRefundSummaryRefundTableRow(
															'Sub-Total',
															grossCost
														)}
														{getRefundSummaryRefundTableRow('Tax', 0)}
														{getRefundSummaryRefundTableRow(
															'Total',
															grossCost + shippingPrice
														)}
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div className="flex justify-between pt-5">
										<Button
											height="9"
											width="24"
											onClick={() => navigate('/orders')}
										>
											Cancel
										</Button>
										<Button
											height="9"
											width="32"
											disabled={
												!requestedRefundReason || requestedRefundReason == 0
											}
											onClick={() => setShowConfirmationModal(true)}
										>
											Submit Refund
										</Button>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				{isTruthy(mobileRefund)
					&& (mobileRefundDetailsPage ? (
						<div className="flex justify-between bg-white p-1 sticky border-t-2">
							<Button
								mobileHeight="10"
								type="djawn"
								mobileWidth="20"
								textSize="md"
								onClick={() => {
									setMobileRefundDetailsPage(false);
								}}
								additionalClassName="flex items-center justify-center"
							>
								<ChevronLeftIcon className="w-6 h-6" />
								<span>Back</span>
							</Button>
							<Button
								mobileHeight="10"
								type="djawn"
								mobileWidth="24"
								additionalClassName="flex items-center justify-center"
								textSize="md"
								disabled={!requestedRefundReason || requestedRefundReason == 0}
								onClick={() => setShowConfirmationModal(true)}
							>
								<span>Submit</span>
								<ChevronRightIcon className="w-6 h-6" />
							</Button>
						</div>
					) : (
						<div className="flex justify-between bg-white p-1 sticky border-t-2">
							<Button
								mobileHeight="10"
								type="djawn-outline"
								mobileWidth="48"
								rounded={false}
								textSize="md"
								onClick={() => {
									setRefundItems([]);
								}}
							>
								Clear selection
							</Button>
							<Button
								mobileHeight="10"
								type="djawn"
								mobileWidth="20"
								additionalClassName="flex items-center justify-center"
								textSize="md"
								disabled={!refundItems.length}
								onClick={() => setMobileRefundDetailsPage(true)}
							>
								<span>Next</span>
								<ChevronRightIcon className="w-6 h-6" />
							</Button>
						</div>
					))}
			</div>
		</Layout>
	);
};

export default RefundPage;
