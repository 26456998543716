import { Link } from 'gatsby';
import React from 'react';

import LayoutSignin from '../components/layouts/layout-signin';
// import Image from "../components/image"
import SEO from '../components/seo';

const UserPage = () => (
	<LayoutSignin>
		<SEO title="Home" auth />

		<div className="container mx-auto h-full pt-12">

			<div className=" bg-white mx-auto w-3/5 h-auto py-4 px-12 mt-12 rounded-lg">

				<div className="grid grid-cols-1 gap-0 pt-2 pb-4">
					<p className="text-2xl text-gray-500 text-center">
                        Personal Information
					</p>
				</div>

				<div className="flex">
					<div className="flex-grow-0 h-full p-4">
						<div className="bg-gray-200 rounded-full h-52 w-52 flex items-center justify-center">
                            Drag Photo Here
						</div>
						{/* <img className="h-8 w-auto sm:h-10 cursor-pointer" src="img/djawn_logo.png" /> */}
					</div>

					<div className="flex-grow p-4">
						<div className="grid grid-cols-2 gap-4">
							<div>
								<label
									htmlFor="first_name"
									className="block text-sm font-medium text-gray-700 pl-2"
								>
                                    First Name
								</label>
								<div className="mt-1 relative rounded-md shadow-sm">
									<input
										type="text"
										name="first_name"
										id="first_name"
										className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
										placeholder="First Name"
									/>
								</div>
							</div>

							<div>
								<label
									htmlFor="last_name"
									className="block text-sm font-medium text-gray-700 pl-2"
								>
                                    Last Name
								</label>
								<div className="mt-1 relative rounded-md shadow-sm">
									<input
										type="text"
										name="last_name"
										id="last_name"
										className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
										placeholder="Last Name"
									/>
								</div>
							</div>
						</div>

						<div className="grid grid-cols-1 gap-4 mt-4">
							<div>
								<label
									htmlFor="email"
									className="block text-sm font-medium text-gray-700 pl-2"
								>
                                    Email Address
								</label>
								<div className="mt-1 relative rounded-md shadow-sm">
									<input
										type="text"
										name="email"
										id="email"
										className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
										placeholder="jhon_doe@example.com"
									/>
								</div>
							</div>
						</div>

						<div className="grid grid-cols-1 gap-4 mt-4">
							<div>
								<label
									htmlFor="nickname"
									className="block text-sm font-medium text-gray-700 pl-2"
								>
                                    Nickname
								</label>
								<div className="mt-1 relative rounded-md shadow-sm">
									<input
										type="text"
										name="nickname"
										id="nickname"
										className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
										placeholder="Nickname"
									/>
								</div>
							</div>
						</div>

						<div className="grid grid-cols-1 gap-4 mt-4">
							<div>
								<label
									htmlFor="street_address"
									className="block text-sm font-medium text-gray-700 pl-2"
								>
                                    Street address
								</label>
								<div className="mt-1 relative rounded-md shadow-sm">
									<input
										type="text"
										name="street_address"
										id="street_address"
										className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
										placeholder="Street Address"
									/>
								</div>
							</div>
						</div>

						<div className="grid grid-cols-3 gap-2 mt-4">
							<div>
								<label
									htmlFor="city"
									className="block text-sm font-medium text-gray-700 pl-2"
								>
                                    City
								</label>
								<div className="mt-1 relative rounded-md shadow-sm">
									<input
										type="text"
										name="city"
										id="city"
										className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
										placeholder="City"
									/>
								</div>
							</div>

							<div>
								<label
									htmlFor="state"
									className="block text-sm font-medium text-gray-700 pl-2"
								>
                                    State
								</label>
								<div className="mt-1 relative rounded-md shadow-sm">
									<input
										type="text"
										name="state"
										id="state"
										className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
										placeholder="State"
									/>
								</div>
							</div>

							<div>
								<label
									htmlFor="post_code"
									className="block text-sm font-medium text-gray-700 pl-2"
								>
                                    Postal Code
								</label>
								<div className="mt-1 relative rounded-md shadow-sm">
									<input
										type="text"
										name="post_code"
										id="post_code"
										className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
										placeholder="Zip"
									/>
								</div>
							</div>
						</div>

						<div className="grid grid-cols-1 gap-2 mt-4">
							<div>
								<Link to="/">
									<button
										type="button"
										className="w-full h-10 mx-auto flex items-center justify-center px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-900 md:py-4 md:text-lg md:px-10"
									>
                                        SAVE
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</LayoutSignin>
);

export default UserPage;
