import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

const useScreenOrientation = () => {
	if (!window) return;
	const [orientation, setOrientation] = useState(window.orientation);
	const [isLandscape, setIsLandscape] = useState(false);

	useEffect(() => {
		if (!isMobile) return;
		if (orientation == 0 || orientation == 180) {
			setIsLandscape(false);
		} else {
			setIsLandscape(true);
		}
	}, [orientation]);

	useEffect(() => {
		if (!window) return;
		const handleOrientationChange = () => setOrientation(window.orientation);
		window.addEventListener('orientationchange', handleOrientationChange);
		return () => window.removeEventListener('orientationchange', handleOrientationChange);
	}, []);

	return { orientation, isLandscape };
};
export default useScreenOrientation;
