import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { isMobile } from 'react-device-detect';
import {
	uploadProductsViaCsv,
	importMultipleProducts
} from '../api/product.request';

const ProductCsvImportModal = ({
	showModal,
	setShowModal,
	onError,
	setLoading,
	onImport
}) => {
	const [products, setProducts] = useState([]);
	const [disableUpload, setDisabledUpload] = useState(false);
	const [csvFile, setCsvFile] = useState(null);

	useEffect(() => {
		if (!showModal) {
			setShowModal(false);
			setCsvFile(null);
			setProducts([]);
			setLoading(false);
		}
	}, [showModal]);

	const getValidProducts = () => {
		setDisabledUpload(true);
		const formData = new FormData();
		formData.append('file', csvFile);

		uploadProductsViaCsv(formData)
			.then((res) => {
				setProducts(res.data);
				setDisabledUpload(false);
			})
			.catch((err) => {
				if (err && err.response) {
					onError(err.response.data.error);
				}
				setCsvFile(null);
				setProducts([]);
			});
	};

	useEffect(() => {
		setDisabledUpload(!csvFile);
		if (csvFile) {
			getValidProducts();
		}
	}, [csvFile]);

	const { getRootProps, getInputProps } = useDropzone({
		accept: '.csv',
		onDrop: (acceptedFiles) => {
			const newFiles = acceptedFiles.map((file) => Object.assign(file, {
				preview: URL.createObjectURL(file)
			}));
			const tempNewFile = [...newFiles][0];
			setCsvFile(tempNewFile);
		}
	});

	const importProducts = () => {
		setLoading(true);
		importMultipleProducts(products)
			.then(() => {
				setShowModal(false);
				setCsvFile(null);
				setProducts([]);
				setLoading(false);
				onImport();
			})
			.catch((err) => {
				setLoading(false);
				if (err && err.response) onError(err.response.data.error);
			});
	};

	const onFileChange = (target) => {
		// pass
		console.log(target);
	};

	if (!showModal) return null;

	return (
		<>
			<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
				<div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
					<div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
						<p className="font-semibold text-gray-800">
							Import products by CSV
						</p>
						<svg
							className="cursor-pointer w-6 h-6"
							onClick={() => setShowModal(false)}
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</div>
					<div
						className={`flex flex-col px-6 py-5 bg-gray-50 h-80 ${
							!isMobile && 'w-112'
						}`}
					>
						{products.length == 0 ? (
							<div>
								<div
									{...getRootProps({
										className:
											'dropzone border-4 rounded-md border-black border-dashed flex flex-col items-center justify-center cursor-pointer mx-auto text-base h-56'
									})}
								>
									<input
										{...getInputProps({
											onChange: (event) => onFileChange(event.target)
										})}
										className="h-full w-full border bg-gray-300 md:hidden"
									/>

									<svg
										className="w-16 h-16"
										fill="black"
										stroke="white"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z"
										/>
									</svg>
									<button
										type="button"
										className="px-4 py-2 mt-2 text-white font-semibold bg-blue-500 rounded"
										onClick={() => {}}
									>
										Add file
									</button>
								</div>
							</div>
						) : (
							<div>
								You will be importing
								{' '}
								{products.length}
								{' '}
								{products.length == 1 ? 'product.' : 'products.'}
							</div>
						)}
					</div>
					<div className="flex flex-row w-full justify-between border-t border-gray-200 rounded-bl-lg rounded-br-lg">
						<div className="flex justify-start p-5">
							<p className="underline font-semibold text-blue-600">
								Learn more about import products
							</p>
						</div>
						<div className="flex justify-end p-5">
							<button
								type="button"
								className="px-4 py-2 font-semibold rounded"
								onClick={() => setShowModal(false)}
							>
								Cancel
							</button>
							{products.length == 0 ? (
								<button
									type="button"
									className="px-4 py-2 text-white font-semibold bg-blue-500 rounded disabled:opacity-50 disabled:cursor-default"
									disabled={disableUpload}
									onClick={getValidProducts}
								>
									Upload
								</button>
							) : (
								<button
									type="button"
									className="px-4 py-2 text-white font-semibold bg-blue-500 rounded"
									onClick={importProducts}
								>
									Import products
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="opacity-25 fixed inset-0 z-40 bg-black" />
		</>
	);
};

export default ProductCsvImportModal;
