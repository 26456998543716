/*  google places api returns an array of the address components seperately(country, city, state),
    the key is the indicator to find the request address components
*/

export const findAddressComponent = (addressComponents, key, useShortName = false) => {
	if (addressComponents) {
		const results = addressComponents.filter((component) => component.types.some((type) => key == type));
		if (results && results.length > 0) {
			if (useShortName) {
				if (results[0].short_name) {
					return results[0].short_name;
				}
			}
			if (results[0].long_name) {
				return results[0].long_name;
			}
			return null;
		}
	}

	return null;
};
