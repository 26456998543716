export const DIRECT_MESSAGE_SAME_PARTICIPANT = 'DIRECT_MESSAGE_SAME_PARTICIPANT';
export const ATTACHMENT_OVER_LIMITED_SIZE = 'ATTACHMENT_OVER_LIMITED_SIZE';
export const CONVERSATION_NAME_EXISTS = 'CONVERSATION_NAME_EXISTS';
export const CONVERSATION_COULD_NOT_BE_CREATED = 'CONVERSATION_COULD_NOT_BE_CREATED';
export const CONVERSATION_PARTICIPANT_COULD_NOT_BE_UPDATED = 'CONVERSATION_PARTICIPANT_COULD_NOT_BE_UPDATED';
export const CONVERSATION_PARTICIPANT_COULD_NOT_BE_CREATED = 'CONVERSATION_PARTICIPANT_COULD_NOT_BE_CREATED';
export const CONVERSATION_COULD_NOT_BE_UPDATED = 'CONVERSATION_COULD_NOT_BE_UPDATED';
export const CONVERSATION_MENTION_COULD_NOT_BE_CREATED = 'CONVERSATION_MENTIONS_COULD_NOT_BE_CREATED';
export const CONVERSATION_MESSAGE_COULD_NOT_BE_SENT = 'CONVERSATION_MESSAGE_COULD_NOT_BE_SENT';
export const GROUP_MESSAGE_SAME_PARTICIPANT = 'GROUP_MESSAGE_SAME_PARTICIPANT';
