/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
import { Link, navigate } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';
import * as mime from 'mime-types';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';
import Button from '../../../../components/button';
import HeaderHome from '../../../../components/common/header-home';
import Layout from '../../../../components/layouts/layout-default';
import Loader from '../../../../components/loader';
import MobileOrderEvent from '../../../../components/order/mobile-order-event';
import OrderEventTable from '../../../../components/order/order-event-table';
import MobileRefundRequestDetails from '../../../../containers/refund/mobile-refund-request-details';
import RefundItemDetailModal from '../../../../containers/refund/refund-item-detail-modal';
import {
	groupBy,
	isTruthy,
	isObjectEmpty
} from '../../../../utils/commonFunction';
import { formatUTCDateToLocal } from '../../../../utils/formatter';
import { base64ToBlob } from '../../../../utils/blob';
import { getRefundFileBytes } from '../../../../api/auth.request';
import MobileRefundRequestFinalize from '../../../../containers/refund/mobile-refund-request-finalize';
import {
	approveRefund,
	declineRefund,
	getRefundDetails,
	updateRefundStatusToPending,
	isUserEligibleToViewRefundDetails,
	getPurchasedProductImage,
	calculateFees,
	hasUsedShippingPriceInARefundPreviously
} from '../../../../api/payment.request';
import RefundRequestFinalizeModal from '../../../../containers/refund/refund-request-finalize-modal';
import ConfirmationModal from '../../../../components/confirmation-modal/index';
import SuccessModal from '../../../../components/modals/success-modal';
import useReferenceData from '../../../../hooks/use-reference-data';
import {
	REFUND_DECLINE_REASON_REFERENCE_TYPE_ID,
	SHIPPING_FEE_PRODUCT_ID
} from '../../../../utils/constants';
import RefundDeclineModal from '../../../../containers/refund/refund-decline-modal';
import useErrorNotifier from '../../../../hooks/use-error-notifier';
import useConversation from '../../../../hooks/useConversation';
import ProductInfoModal from '../../../../components/product/product-info-modal';
import { getOrderStatusParagaph } from '../../../../utils/element';
import Conversation from '../../../../components/conversation';
import PaymentBillingDetails from '../../../../components/payment-billing-details';

const RefundRequestDetailsPage = ({ refundId, location }) => {
	const [refundDetails, setRefundDetails] = useState(null);
	const [order, setOrder] = useState(null);
	const [groupedOrderDetails, setGroupedOrderDetails] = useState({});
	const [refundItems, setRefundItems] = useState([]);
	const [selectedRefundItem, setSelectedRefundItem] = useState(null);
	const [loading, setLoading] = useState(true);
	const [shippingPrice, setShippingPrice] = useState(0);
	const [grossCost, setGrossCost] = useState(0);
	const [djawnFees, setDjawnFees] = useState(0);
	const [djawnSellerFee, setDjawnSellerFee] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [attachments, setAttachments] = useState([]);
	const [finalizePage, setFinalizePage] = useState(false);
	const [orderDetailsInvalidAmounts, setOrderDetailsInvalidAmounts] = useState(
		[]
	);
	const [
		orderDetailsFinalizedAmounts,
		setOrderDetailsFinalizedAmounts
	] = useState([]);
	const [finalizedGrossCost, setFinalizedGrossCost] = useState(0);
	const [finalizedDjawnFees, setFinalizedDjawnFees] = useState(0);
	const [finalizedDiscount, setFinalizedDiscount] = useState(0);
	const [finalizedShippingFee, setFinalizedShippingFee] = useState(0);
	const [originalStripeFee, setOriginalStripeFee] = useState(0);
	const [stripeFee, setStripeFee] = useState(0);
	const [
		initialFinalizedShippingFee,
		setInitialFinalizedShippingFee
	] = useState(0);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [declineRefundReasons, declineRefundReasonsLoaded] = useReferenceData(
		REFUND_DECLINE_REASON_REFERENCE_TYPE_ID
	);
	const [showDeclineModal, setShowDeclineModal] = useState(false);
	const [rejectedRefundReasonId, setRejectedRefundReasonId] = useState(null);
	const { showToastError } = useErrorNotifier();
	const [declined, setDeclined] = useState(false);
	const { findConversationByChild } = useConversation();
	const [selectedConveration, setSelectedConversation] = useState(null);
	const [isBuyer, setIsBuyer] = useState(false);
	const [isBuyerInitiated, setIsBuyerInitiated] = useState(false);
	const [showProductModal, setShowProductModal] = useState(false);
	const [productImagePath, setProductImagePath] = useState(null);
	const [canMakeDecision, setCanMakeDecision] = useState(false);
	const [refundConversation, setRefundConversation] = useState(null);

	const download = (bytes, filename, type) => {
		const blob = base64ToBlob(bytes, type);
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = filename;
		link.click();
	};

	const onAttachmentDownloaded = (filename) => {
		const type = mime.contentType(filename);
		getRefundFileBytes(filename).then((res) => {
			download(res.data, filename, type);
		});
	};

	const onProductClicked = (image) => {
		getPurchasedProductImage(image).then((res) => {
			setProductImagePath(res.data);
			setShowProductModal(true);
		});
	};

	const getRefundConversation = (id) => findConversationByChild('refund_id', id, false).then(
		(foundConversation) => foundConversation
	);

	useEffect(() => {
		if ((location.state && location.state.refundDetails) || refundId) {
			const getDetails = async () => {
				let details = null;
				if (location.state && location.state.refundDetails) {
					details = location.state.refundDetails;
				} else {
					const res = await getRefundDetails(refundId);
					if (res && res.data) {
						details = res.data;
					}
				}
				setRefundDetails(details);
				setOrder(details.order);
				setIsBuyerInitiated(details.buyerInitiated);
				setRefundConversation(await getRefundConversation(details.refundId));
				const orderDetails = details.refundItems.map((refundItem) => {
					refundItem.orderDetail.quantity = refundItem.quantity;
					return refundItem.orderDetail;
				});
				setRefundItems(orderDetails);
				setAttachments(details.refundAttachments);
				let total = 0;
				let totalDjawnFees = 0;
				let totalDiscount = 0;
				let hasRipnShipProduct = false;
				const hasApprovedRefundItems =					details.approvedRefundDetail
					&& details.approvedRefundDetail.approvedRefundItems
					&& details.approvedRefundDetail.approvedRefundItems.length;
				orderDetails.forEach((orderDetail) => {
					if (!hasApprovedRefundItems) {
						total
							+= (orderDetail.quantity == 0 ? 1 : orderDetail.quantity)
							* orderDetail.unitCost;
					}
					totalDjawnFees += orderDetail.buyerDjawnFee;
					totalDiscount += orderDetail.buyerDiscountAmount;
					if (!hasRipnShipProduct) {
						if (orderDetail.productId != null) {
							hasRipnShipProduct = true;
						}
					}
				});

				if (hasApprovedRefundItems) {
					details.approvedRefundDetail.approvedRefundItems.forEach((item) => {
						total += item.approvedAmount;
					});
				}

				let shipping = 0;

				if (
					details.order
					&& details.order.orderDetails
					&& hasRipnShipProduct
					&& !details.approvedRefundDetail
				) {
					const shippingPrices = details.order.orderDetails.filter(
						(f) => f.productId != null
							&& f.productId == SHIPPING_FEE_PRODUCT_ID
							&& f.sellerId == details.sellerId
					);
					shippingPrices.forEach((orderDetail) => {
						shipping += orderDetail.unitCost;
					});

					hasUsedShippingPriceInARefundPreviously(
						details.order.orderId,
						details.sellerId
					)
						.then((res) => {
							if (res.data == true) {
								setShippingPrice(0);
								setInitialFinalizedShippingFee(0);
								setFinalizedShippingFee(0);
							} else {
								setShippingPrice(shipping);
								setInitialFinalizedShippingFee(shipping);
								setFinalizedShippingFee(shipping);
							}
						})
						.catch((err) => {
							console.error(err);
						});
				} else if (details.approvedRefundDetail) {
					setShippingPrice(details.approvedRefundDetail.shippingFee);
					setInitialFinalizedShippingFee(
						details.approvedRefundDetail.shippingFee
					);
					setFinalizedShippingFee(details.approvedRefundDetail.shippingFee);
				}

				const shippingFee =					details.approvedRefundDetail
					&& details.approvedRefundDetail.shippingFee
					? details.approvedRefundDetail.shippingFee
					: shipping;

				calculateFees(
					Number(total),
					shippingFee,
					Number(total),
					shippingFee,
					0,
					details.refundReasonId,
					false
				).then((res) => {
					if (totalDjawnFees > 0) {
						setDjawnFees(res.data.buyerDjawnFee);
						setFinalizedDjawnFees(res.data.buyerDjawnFee);
					}
					if (totalDiscount > 0) {
						setDiscount(res.data.buyerDjawnFee);
						setFinalizedDiscount(res.data.buyerDjawnFee);
					}
					setDjawnSellerFee(res.data.sellerDjawnFee);
					// TODO: Figure out why this was done and maybe store this in a better fashion
					let paymentProcessorFee = res.data.stripeFeeWithDjawnBuyerFee;

					if (!details.buyerInitiated) {
						paymentProcessorFee = 0;
					}

					if (totalDjawnFees - totalDiscount == 0) {
						paymentProcessorFee = res.data.stripeFee;
					}

					setStripeFee(paymentProcessorFee);
					setOriginalStripeFee(paymentProcessorFee);
				});
				setGrossCost(total);
				setDjawnFees(totalDjawnFees);
				setDiscount(totalDiscount);
				setFinalizedDiscount(totalDiscount);

				updateRefundStatusToPending(details.refundId).catch((err) => console.error(err));
				const groupByOrderDetails = groupBy(orderDetails, 'eventId');
				setGroupedOrderDetails(groupByOrderDetails);
			};

			if (refundId) {
				isUserEligibleToViewRefundDetails(refundId)
					.then((res) => {
						setIsBuyer(res.data.isBuyer);
						setCanMakeDecision(res.data.canMakeDecision && !res.data.isBuyer);
						getDetails();
					})
					.catch(() => {
						navigate('/');
					});
			}
		}
	}, [location.state]);

	const setTotalValue = (orderDetailId, value) => {
		const tempFinalizedAmounts = [...orderDetailsFinalizedAmounts].map(
			(finalizedItem) => {
				if (finalizedItem.orderDetailsId == orderDetailId) {
					finalizedItem.amount = value;
				}
				return finalizedItem;
			}
		);
		setOrderDetailsFinalizedAmounts(tempFinalizedAmounts);
		setTimeout(() => {
			const refundAmountEl = document.getElementById(`amount-${orderDetailId}`);
			if (refundAmountEl) {
				refundAmountEl.focus();
			}
		}, 50);
	};

	useEffect(() => {
		if (!refundItems || !refundItems.length) return;
		const tempFinalizedAmounts = [];
		refundItems.forEach((refundItem) => {
			tempFinalizedAmounts.push({
				amount: Number(
					refundItem.unitCost
						* (refundItem.quantity == 0 ? 1 : refundItem.quantity)
				).toFixed(2),
				totalAllowed: Number(
					refundItem.unitCost
						* (refundItem.quantity == 0 ? 1 : refundItem.quantity)
				).toFixed(2),
				orderDetailsId: refundItem.orderDetailsId
			});
		});
		setOrderDetailsFinalizedAmounts(tempFinalizedAmounts);
	}, [refundItems]);

	useEffect(() => {
		if (!refundItems || !refundItems.length) return;
		let total = 0;
		let hasRipnShipProduct = false;
		refundItems.forEach((refundItem) => {
			const finalizedItem = orderDetailsFinalizedAmounts.find(
				(orderDetail) => orderDetail.orderDetailsId == refundItem.orderDetailsId
			);
			if (!finalizedItem) return;
			if (!isNaN(finalizedItem.amount)) {
				total += Number(finalizedItem.amount);
			}

			// totalDjawnFees += refundItem.buyerDjawnFee;
			// totalDiscount += refundItem.buyerDiscountAmount;
			if (!hasRipnShipProduct) {
				if (refundItem.productId != null) {
					hasRipnShipProduct = true;
				}
			}
		});
		setFinalizedShippingFee(!hasRipnShipProduct ? 0 : shippingPrice);
		setInitialFinalizedShippingFee(!hasRipnShipProduct ? 0 : shippingPrice);
		setFinalizedGrossCost(Number(total).toFixed(2));
		// setFinalizedDjawnFees(totalDjawnFees);
	}, [orderDetailsFinalizedAmounts]);

	useEffect(() => {
		if (!groupedOrderDetails || isObjectEmpty(groupedOrderDetails)) return;
		setLoading(false);
	}, [groupedOrderDetails]);

	if (loading) {
		return <Loader />;
	}

	const onOpenChat = () => {
		if (refundDetails && refundDetails.refundId) {
			findConversationByChild('refund_id', refundDetails.refundId, true).then(
				(foundConversation) => {
					if (foundConversation && foundConversation.id) {
						setSelectedConversation(foundConversation);
					}
				}
			);
		}
	};

	return (
		<Layout auth>
			<div className="flex flex-col flex-1">
				<ReactTooltip />
				<SuccessModal
					isOpen={showSuccessModal}
					onClose={() => setShowSuccessModal(false)}
					onSuccess={() => navigate('/')}
					body={`Refund has been ${
						declined ? 'declined' : 'approved'
					}. The buyer will be notified.`}
				/>
				{declineRefundReasonsLoaded && (
					<RefundDeclineModal
						showModal={showDeclineModal}
						setShowModal={setShowDeclineModal}
						declineReasons={declineRefundReasons}
						rejectedRefundReasonId={rejectedRefundReasonId}
						setRejectedRefundReasonId={setRejectedRefundReasonId}
						onDecline={() => {
							declineRefund(
								refundDetails.refundId,
								rejectedRefundReasonId,
								orderDetailsFinalizedAmounts.map((orderDetail) => ({
									item1: orderDetail.orderDetailsId,
									item2: orderDetail.amount
								}))
							)
								.then(() => {
									setDeclined(true);
									setShowSuccessModal(true);
								})
								.catch((err) => {
									showToastError(err);
								});
						}}
					/>
				)}
				<ConfirmationModal
					showModal={showConfirmationModal}
					setShowModal={setShowConfirmationModal}
					text="Do you want to approve the refund?"
					confirmText="Confirm refund"
					cancelText="Cancel"
					showConfirmationIcon={false}
					onConfirm={() => {
						approveRefund(
							refundDetails.refundId,
							orderDetailsFinalizedAmounts.map((orderDetail) => ({
								item1: orderDetail.orderDetailsId,
								item2: orderDetail.amount
							})),
							Number(finalizedShippingFee).toFixed(2),
							Number(stripeFee).toFixed(2),
							Number(finalizedDjawnFees).toFixed(2),
							Number(finalizedDiscount).toFixed(2),
							Number(
								Number(finalizedGrossCost)
									+ (Number(finalizedDjawnFees) - Number(finalizedDiscount))
									+ Number(finalizedShippingFee)
									- Number(stripeFee).toFixed(2)
							).toFixed(2),
							Number(djawnSellerFee).toFixed(2)
						)
							.then(() => {
								setShowConfirmationModal(false);
								setShowSuccessModal(true);
							})
							.catch((err) => {
								showToastError(err);
							});
					}}
				/>
				<HeaderHome
					siteTitle="Admin"
					hideSearch
					setLoading={setLoading}
					noMargins={isMobile}
					selectedConversation={selectedConveration}
				/>
				<ToastContainer />
				{productImagePath && (
					<ProductInfoModal
						imageSrc={productImagePath}
						showModal={showProductModal}
						setShowModal={setShowProductModal}
					/>
				)}
				<div className="flex flex-col flex-grow w-full md:h-full h-80 mt-4 items-center justify-between overflow-y-scroll md:overflow-y-auto">
					<RefundItemDetailModal
						item={selectedRefundItem}
						onClose={() => setSelectedRefundItem(null)}
					/>
					{refundDetails && (
						<RefundRequestFinalizeModal
							orderDetails={
								refundItems.length
								&& refundItems.sort((a, b) => a.slotPurchased - b.slotPurchased)
							}
							setTotalValue={setTotalValue}
							finalizedAmounts={orderDetailsFinalizedAmounts}
							invalidAmounts={orderDetailsInvalidAmounts}
							setInvalidAmounts={setOrderDetailsInvalidAmounts}
							order={order}
							grossCost={finalizedGrossCost}
							originalGrossCost={grossCost}
							discount={finalizedDiscount}
							setDiscount={setFinalizedDiscount}
							djawnFees={finalizedDjawnFees}
							setDjawnFees={setFinalizedDjawnFees}
							stripeFees={stripeFee}
							setDjawnSellerFee={setDjawnSellerFee}
							setStripeFees={setStripeFee}
							showModal={finalizePage && !isMobile}
							refundReasonId={refundDetails.refundReasonId}
							setShowModal={setFinalizePage}
							shippingPrice={finalizedShippingFee}
							initialShippingPrice={initialFinalizedShippingFee}
							setShippingPrice={setFinalizedShippingFee}
							setShowDeclineModal={setShowDeclineModal}
							setShowConfirmationModal={setShowConfirmationModal}
							isBuyerInitiated={isBuyerInitiated}
							originalStripeFee={originalStripeFee}
						/>
					)}
					<div
						className={`flex flex-col ${
							isMobile ? 'w-full' : 'w-5/6'
						} rounded-lg content-center justify-center`}
					>
						<div className="flex justify-end mb-3 md:flex-row flex-col px-1">
							{!finalizePage ? (
								!isBuyer ? (
									<Link to={`/sales/refund/requests/${order.orderId}`}>
										<Button
											mobileHeight="10"
											type="djawn"
											mobileWidth="40"
											width="32"
											additionalClassName="flex items-center justify-center"
											textSize="md"
											onClick={() => setFinalizePage(true)}
										>
											<ChevronLeftIcon className="w-6 h-6" />

											<span>Refund requests</span>
										</Button>
									</Link>
								) : null
							) : (
								<Button
									mobileHeight="10"
									type="djawn"
									mobileWidth="24"
									additionalClassName="flex items-center justify-center"
									textSize="md"
									onClick={() => setFinalizePage(false)}
								>
									<ChevronLeftIcon className="w-6 h-6" />

									<span>Back</span>
								</Button>
							)}
						</div>
						<div className="w-full justify-center">
							{isMobile ? (
								finalizePage ? (
									<MobileRefundRequestFinalize
										orderDetails={
											refundItems.length
											&& refundItems.sort(
												(a, b) => a.slotPurchased - b.slotPurchased
											)
										}
										setTotalValue={setTotalValue}
										finalizedAmounts={orderDetailsFinalizedAmounts}
										invalidAmounts={orderDetailsInvalidAmounts}
										setInvalidAmounts={setOrderDetailsInvalidAmounts}
										order={order}
										grossCost={finalizedGrossCost}
										originalGrossCost={grossCost}
										originalStripeFee={originalStripeFee}
										refundReasonId={refundDetails.refundReasonId}
										discount={finalizedDiscount}
										setDiscount={setDiscount}
										setDjawnSellerFee={setDjawnSellerFee}
										djawnFees={finalizedDjawnFees}
										setDjawnFees={setFinalizedDjawnFees}
										stripeFees={stripeFee}
										setStripeFees={setStripeFee}
										shippingPrice={finalizedShippingFee}
										initialShippingPrice={initialFinalizedShippingFee}
										setShippingPrice={setFinalizedShippingFee}
										isBuyerInitiated={isBuyerInitiated}
									/>
								) : (
									<MobileRefundRequestDetails
										order={order}
										refundItems={
											refundItems.length
											&& refundItems.sort(
												(a, b) => a.slotPurchased - b.slotPurchased
											)
										}
										onInfoIconClicked={(orderDetail) => setSelectedRefundItem(orderDetail)}
										grossCost={grossCost}
										discount={discount}
										djawnFee={djawnFees}
										shippingPrice={shippingPrice}
										selectedRefundReason={
											refundDetails.refundReason.referenceDataValue
										}
										stripeFee={stripeFee}
										attachments={attachments}
										showRefundReasonsSelect={false}
										onAttachmentDownloaded={onAttachmentDownloaded}
										refundStatus={refundDetails.refundStatus.referenceDataValue}
									/>
								)
							) : (
								<>
									<div
										className={`order-details-header flex bg-white ${
											!isMobile && 'border-2 border-black'
										} rounded-lg md:justify-between md:px-4 px-2 py-4 flex-col md:flex-row shadow-lg `}
									>
										<p className="md:pb-0 pb-1">
											<b>Order Date:&nbsp;</b>
											{formatUTCDateToLocal(
												order.orderDateTime,
												'DD-MMM-YYYY HH:mm'
											).replace(/--/g, '-')}
										</p>
										<p className="md:pb-0 pb-1">
											<b>Order Number:&nbsp;</b>
											{order.orderNumber}
										</p>
										<p className="md:pb-0 pb-1">
											<b>Customer Name:&nbsp;</b>
											{order.buyerName}
										</p>
										<p className="md:pb-0 pb-1 inline-flex">
											{getOrderStatusParagaph(
												order,
												order.orderStatus,
												'Order Status:'
											)}
										</p>
									</div>
									<div
										className={`flex bg-white ${
											!isMobile && 'border-2 border-black'
										} rounded-lg md:justify-between md:px-4 px-4 py-4 flex-col shadow-lg mt-3`}
									>
										{Object.keys(groupedOrderDetails).map((keyName, index) => (
											<div
												className={`flex border-2 ${
													!isMobile && 'border-black'
												} w-full px-2 md:px-0`}
											>
												{!isMobile ? (
													<OrderEventTable
														key={index}
														orderDetails={groupedOrderDetails[keyName].sort(
															(a, b) => a.slotPurchased - b.slotPurchased
														)}
														onProductClicked={onProductClicked}
														showQuantityToRefund
														pagination={false}
													/>
												) : (
													<MobileOrderEvent
														key={index}
														orderDetails={groupedOrderDetails[keyName].sort(
															(a, b) => a.slotPurchased - b.slotPurchased
														)}
														onProductClicked={onProductClicked}
													/>
												)}
											</div>
										))}
									</div>
									<div
										className={`flex bg-white dark:bg-darkGray-50 ${
											!isMobile && 'border-2 border-black'
										} rounded-lg md:justify-between md:px-4 px-4 py-1 pb-2 flex-col shadow-lg mt-3 mb-3`}
										style={{ height: '55vh' }}
									>
										<Conversation
											startedConversation={refundConversation}
											showConversations
											hideSidebar
											removeHiddenOverflows
										/>
									</div>
									<div
										className={`flex bg-white ${
											!isMobile && 'border-2 border-black'
										} rounded-lg md:justify-between md:px-4 px-4 py-4 flex-col shadow-lg mt-3 mb-3`}
									>
										<p className="underline">Billing</p>
										{!isBuyer ? (
											<div className="flex md:flex-row md:space-y-0 space-y-1 flex-col justify-between md:justify-start">
												<PaymentBillingDetails
													total={Number(
														Number(grossCost)
															+ Number(shippingPrice)
															+ (Number(djawnFees) - Number(discount))
													).toFixed(2)}
													subtotal={Number(Number(order.grossCost)).toFixed(2)}
													shipping={shippingPrice}
													djawnFees={djawnFees}
													djawnDiscount={discount}
													paymentMethod={
														order.paymentProcessor.referenceDataValue
													}
													paymentProcessorFees={stripeFee}
													paymentProcessorFeeName="Refund processing fee"
												/>
												<div className="md:pl-48 pl-0">
													<PaymentBillingDetails
														total={Number(
															Number(grossCost)
																+ Number(shippingPrice)
																+ (Number(djawnFees) - Number(discount))
														).toFixed(2)}
														subtotal={Number(Number(order.grossCost)).toFixed(
															2
														)}
														shipping={shippingPrice}
														djawnFees={djawnFees}
														djawnDiscount={discount}
														paymentMethod={
															order.paymentProcessor.referenceDataValue
														}
														paymentProcessorFees={stripeFee}
														name="Buyer Total"
														popupHeaderName="Buyer Total Details"
														paymentProcessorFeeName="Refund processing fee"
													/>
												</div>
											</div>
										) : (
											<>
												<div className="flex justify-between px-4 flex-col md:flex-row">
													<p>
														<b>Item(s) Subtotal</b>
														<span className="pl-1 pr-2">:</span>
														<span className="pr-2">$</span>
														{Number(Number(grossCost)).toFixed(2)}
													</p>
													<p className="flex">
														<b>Total Shipping Cost</b>
														<span className="pl-1 pr-2">:</span>
														<span className="pr-2">$</span>
														{Number(shippingPrice).toFixed(2)}
													</p>

													<p className="flex">
														<b>DJAWN Buyer Fee</b>
														<span className="pl-1 pr-2">:</span>
														<span className="pr-2">$</span>
														{Number(djawnFees).toFixed(2)}
													</p>
													<p className="flex md:pr-0 pr-5">
														<b>Djawn Buyer Discount</b>
														<span className="pl-1 pr-2">:</span>
														<span className="pr-2">$</span>
														{Number(discount).toFixed(2)}
													</p>
												</div>
												<div className="flex flex-col md:flex-row">
													<p className="flex px-4">
														<b>Total</b>
														<span className="pl-1 pr-2">:</span>
														<span className="pr-2">$</span>
														{Number(
															Number(grossCost)
																+ Number(shippingPrice)
																+ (Number(djawnFees) - Number(discount))
														).toFixed(2)}
													</p>
													<p className="flex pl-68">
														<b>Refund processing fee</b>
														<span className="pl-1 pr-2">:</span>
														<span className="pr-2">$</span>
														{Number(stripeFee).toFixed(2)}
													</p>
												</div>
											</>
										)}
									</div>
									<div
										className={`flex bg-white ${
											!isMobile && 'border-2 border-black'
										} rounded-lg md:justify-between md:px-4 px-4 py-4 flex-col shadow-lg mt-3 mb-3`}
									>
										<p className="underline">Refund details</p>
										<br />
										<div className="flex justify-between px-4 flex-col md:flex-row">
											<div>
												<p>
													<b>Refund Number</b>
													<span className="pl-1 pr-2">:</span>
													{refundDetails.refundNumber}
												</p>
												<p className="inline-flex w-full whitespace-nowrap">
													<b>Refund Reason</b>
													<span className="pl-1 pr-2">:</span>
													<p>{refundDetails.refundReason.referenceDataValue}</p>
												</p>
												{refundDetails
												&& refundDetails.refundStatus
												&& refundDetails.refundStatus.referenceDataValue ? (
														<p className="inline-flex w-full whitespace-nowrap">
															<b>Refund Status</b>
															<span className="pl-1 pr-2">:</span>
															<p>
																{refundDetails.refundStatus.referenceDataValue}
															</p>
														</p>
													) : null}
												{isTruthy(attachments && attachments.length) && (
													<div className="flex flex-col justify-center pb-4">
														<b className="pr-3">Attachments</b>
														{isTruthy(attachments.length) && (
															<div className="grid grid-cols-2 mb-3 pb-3">
																{attachments.map((object, i) => (
																	<div className="flex pt-2 col-span-0" key={i}>
																		<div className="md:p-1 text-left md:text-left space-y-4 break-all overflow-x-hidden">
																			<div className="flex flex-none items-center border rounded-2xl">
																				<div className="flex items-center justify-between text-center w-full">
																					<span className="text-md mr-1 p-1 font-sans w-full pl-5">
																						{object.fileName}
																					</span>
																					<button
																						type="button"
																						className="focus:outline-none text-black hover:text-black text-lg p-3 rounded-md rounded-tr-none rounded-br-none"
																						onClick={() => onAttachmentDownloaded(
																							object.fileName
																						)}
																					>
																						<svg
																							className="w-5 h-4"
																							fill="none"
																							stroke="currentColor"
																							viewBox="0 0 24 24"
																							xmlns="http://www.w3.org/2000/svg"
																						>
																							<path
																								strokeLinecap="round"
																								strokeLinejoin="round"
																								strokeWidth="2"
																								d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
																							/>
																						</svg>
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																))}
															</div>
														)}
													</div>
												)}
											</div>
										</div>
										<div className="flex justify-end pt-5">
											{canMakeDecision && (
												<Button
													mobileHeight="10"
													type="djawn"
													mobileWidth="28"
													width="24"
													additionalClassName="flex items-center justify-center"
													textSize="md"
													disabled={!refundItems.length}
													onClick={() => setFinalizePage(true)}
												>
													<span>Process</span>
													<ChevronRightIcon className="w-6 h-6" />
												</Button>
											)}
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
				{isTruthy(isMobile)
					&& (finalizePage && canMakeDecision ? (
						<div className="flex justify-between bg-white p-1 sticky border-t-2">
							<Button
								mobileHeight="10"
								type="djawn"
								mobileWidth="24"
								textSize="md"
								additionalClassName="flex items-center justify-center"
								onClick={() => setShowDeclineModal(true)}
							>
								<ChevronLeftIcon className="w-6 h-6" />
								<span>Decline</span>
							</Button>
							<Button
								mobileHeight="10"
								type="djawn"
								mobileWidth="24"
								additionalClassName="flex items-center justify-center"
								textSize="md"
								// disabled={!requestedRefundReason || requestedRefundReason == 0}
								onClick={() => setShowConfirmationModal(true)}
							>
								<span>Approve</span>
								<ChevronRightIcon className="w-6 h-6" />
							</Button>
						</div>
					) : (
						<div className="flex justify-between bg-white p-1 absolute border-t-2 bottom-0 w-full">
							<Button
								mobileHeight="10"
								type="djawn"
								mobileWidth="24"
								textSize="md"
								onClick={() => {
									onOpenChat();
								}}
							>
								Open Chat
							</Button>
							{canMakeDecision && (
								<Button
									mobileHeight="10"
									type="djawn"
									mobileWidth="28"
									additionalClassName="flex items-center justify-center"
									textSize="md"
									disabled={!refundItems.length}
									onClick={() => setFinalizePage(true)}
								>
									<span>Finalize</span>
									<ChevronRightIcon className="w-6 h-6" />
								</Button>
							)}
						</div>
					))}
			</div>
		</Layout>
	);
};
export default RefundRequestDetailsPage;
