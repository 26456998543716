import React from 'react';
import Modal from 'react-modal';
import success from '../../images/success.svg';

const style = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		textAlign: 'center',
		borderRadius: '10px',
		zIndex: 20
	}
};
const SuccessModal = ({
	isOpen,
	onClose,
	customStyles = style,
	title = 'Successful',
	body = 'Your changes have been successfully saved.',
	onSuccess
}) => (
	<Modal
		isOpen={isOpen}
		onRequestClose={() => onClose()}
		style={customStyles}
		contentLabel="Submit_Modal"
	>
		<span
			className="flex justify-center"
			style={{
				marginLeft: '0px',
				left: '147px',
				right: '147px',
				alignItems: 'center',
				position: 'absolute'
			}}
		>
			<img src={success} alt="saved" width="40px" height="40px" />
		</span>
		<br />
		<h1 className="mt-8">
			<b>{title}</b>
		</h1>
		<br />
		<p className="md:w-112 w-64">{body}</p>
		<br />
		<button
			type="button"
			style={{ backgroundColor: '#008000' }}
			className="min-w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded-lg"
			onClick={onSuccess}
		>
			Done
		</button>
	</Modal>
);

export default SuccessModal;
