import React, { useContext, useState } from 'react';
import Modal from '../../../components/modals';
import Button from '../../../components/button';
import { TwilioTrackContext } from '../../../contexts/twilio-track';

const RequestMicAccess = ({ requestedMicAccess, onMicRequest, onClose }) => (requestedMicAccess ? (
	<div className="my-3">
		<p>
				Your mic request is submitted, when the seller approves your request you
				will be able to talk. You can cancel your mic request any time by
				clicking the mic icon.
		</p>
		<div className="mt-8 flex xxs:flex-row justify-center items-center xxs:w-full xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0 mb-5">
			<button
				type="button"
				className="h-12 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white bg-green-600 hover:bg-green-800  md:py-4 md:text-lg md:px-10"
				onClick={onClose}
			>
					Ok
			</button>
		</div>
	</div>
) : (
	<div>
		<p>Your mic is not active</p>
		<div className="mt-3 flex xxs:flex-row justify-center items-center xxs:w-full xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0 mb-5">
			<div className="mr-3">
				<button
					type="button"
					className="h-12 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white bg-green-600 hover:bg-green-800  md:py-4 md:text-lg md:px-10"
					onClick={() => {
						onMicRequest();
					}}
				>
						Request mic
				</button>
			</div>
			<div className="mr-3">
				<button
					type="button"
					className="h-12 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white bg-red-700 hover:bg-red-900 md:py-4 md:text-lg md:px-10"
					onClick={onClose}
				>
						Cancel
				</button>
			</div>
		</div>
	</div>
));

const CancelMicAccess = ({
	submitted,
	setSubmitted,
	onConfirmation,
	onClose,
	onCancelMicRequest
}) => (submitted ? (
	<div>
		<p>Your mic request is canceled.</p>
		<div className="mt-3 flex xxs:flex-row justify-center items-center xxs:w-full xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0 mb-5">
			<button
				type="button"
				className="h-12 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white bg-green-600 hover:bg-green-800  md:py-4 md:text-lg md:px-10"
				onClick={() => {
					onClose();
					setTimeout(() => {
						onConfirmation();
					}, 500);
				}}
			>
					Ok
			</button>
		</div>
	</div>
) : (
	<div className="my-3">
		<p>
				You have an active mic request, click the button below to cancel the
				request.
		</p>
		<div className="mt-8 flex xxs:flex-row justify-center items-center xxs:w-full xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0 mb-5">
			<Button
				height="10"
				mobileHeight="8"
				width="full"
				onClick={() => {
					onCancelMicRequest();
					setSubmitted(true);
				}}
			>
					Cancel the mic request
			</Button>
		</div>
	</div>
));
const ParticipantMicRequest = ({
	showMicRequestConfirmationModal,
	onClose
}) => {
	const {
		requestedMicAccess,
		setRequestedMicAccess,
		setCanceledMicRequestAccess
	} = useContext(TwilioTrackContext);

	const [requestSubmitted, setRequestSubmitted] = useState(false);
	const [
		waitingForRequestConfirmation,
		setWaitingForRequestConfirmation
	] = useState(false);
	const [cancellationSubmitted, setCancellationSubmitted] = useState(false);

	return (
		<Modal
			showModal={showMicRequestConfirmationModal}
			onClose={onClose}
			hideCloseButton
		>
			{(requestedMicAccess || cancellationSubmitted)
			&& requestSubmitted
			&& !waitingForRequestConfirmation ? (
					<CancelMicAccess
						onClose={onClose}
						submitted={cancellationSubmitted}
						setSubmitted={setCancellationSubmitted}
						onConfirmation={() => {
							setRequestSubmitted(false);
							setCancellationSubmitted(false);
							setWaitingForRequestConfirmation(false);
						}}
						onCancelMicRequest={() => {
							setCanceledMicRequestAccess(true);
						}}
					/>
				) : (
					<RequestMicAccess
						onClose={() => {
							onClose();
							setTimeout(() => {
								setRequestSubmitted(true);
								setWaitingForRequestConfirmation(false);
							}, 500);
						}}
						requestedMicAccess={requestedMicAccess}
						onMicRequest={() => {
							setWaitingForRequestConfirmation(true);
							setRequestedMicAccess(true);
							setCancellationSubmitted(false);
						}}
					/>
				)}
		</Modal>
	);
};

export default ParticipantMicRequest;
