import React from 'react';
import DataTable from 'react-data-table-component';

const columns = [
	{
		name: 'Customer Name',
		selector: 'nickname',
		sortable: true,
		cell: (row) => (
			<p>
				{' '}
				{!row.userId && 'Anonymous'}
			</p>
		),
		wrap: true
	},
	{
		name: 'Attempt Time',
		selector: 'joinAttemptDateTime',
		sortable: true,
		wrap: true
	},
	{
		name: 'Error type',
		selector: 'failedReason',
		sortable: true,
		wrap: true
	}
];

const AdminFailedEventAttendees = ({ failedAttendees }) => (
	<div className="w-full">
		<DataTable
			columns={columns}
			data={failedAttendees}
			pagination
			noContextMenu
			className=""
			contextActions={null}
			striped
			paginationComponentOptions={{
				rowsPerPageText: 'Rows per page:',
				rangeSeparatorText: 'of',
				noRowsPerPage: false,
				selectAllRowsItem: true,
				selectAllRowsItemText: 'All'
			}}
			paginationRowsPerPageOptions={[10, 25, 100]}
			customStyles={{
				rows: {
					style: {}
				},
				headCells: {
					style: {
						backgroundColor: '#FF5733',
						color: 'white',
						fontSize: '1rem',
						fontWeight: 'bold'
					}
				}
			}}
		/>
	</div>
);
export default AdminFailedEventAttendees;
