/* eslint-disable no-unused-vars */
import React from 'react';

const SellerInformationEventQueue = ({
	storeName,
	addressLine1,
	apartmentSuite,
	city,
	postalCode,
	state,
	shippingFrequency,
	shippingType,
	shipCards,
	shippingCost,
	onStoreNameClick,
	showSeller,
	eventGeneralDetails
}) => (
	<div className="mt-4">
		<div className="flex flex-col w-full">
			<div className="mt-1 relative w-full px-3 py-2 text-gray-700 dark:text-white dark:bg-darkGray-50 focus:outline-none h-64 gap-4">
				<h3
					onKeyDown={showSeller}
					onClick={showSeller}
					className="cursor-pointer"
				>
					<u>
						<b>{storeName}</b>
					</u>
				</h3>
				<div className="flex items-center">
					<div>
						<p>
							{city}
,
							{state}
						</p>
					</div>
				</div>
				<br />
				<div className="flex flex-col">
					<p>
						Frequency of Shipping:&nbsp;
						<b>{shippingFrequency}</b>
					</p>
					<br />
					<p>
						Ship Cards:&nbsp;
						<b>{shipCards}</b>
					</p>
					<p>
						Shipping Type:&nbsp;
						<b>{shippingType}</b>
					</p>
					<br />
					<p>
						Shipping Cost:&nbsp;
						<b>
$
							{shippingCost}
						</b>
					</p>
					<br />
					<p>
						Event Name:&nbsp;
						<b>{eventGeneralDetails.eventName}</b>
					</p>
					<br />
					<p>
						Event Details:&nbsp;
						<b>{eventGeneralDetails.eventDetails}</b>
					</p>
				</div>
				<br />
			</div>
		</div>
	</div>
);

export default SellerInformationEventQueue;
