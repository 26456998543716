import React from 'react';
import LiveNowButton from './live-now';
import BookmarkButton from './bookmark';
import FreeShippingButton from './free-shipping';
import HitOnly from './hit-only';

const FeedButtons = ({ feed }) => (
	<>
		{feed.status == 'Started' && <LiveNowButton />}
		{feed.bookmarked && <BookmarkButton />}
		{feed.freeShipping && <FreeShippingButton />}
		{feed.hitsOnly && <HitOnly />}
	</>
);

export default FeedButtons;
