import React from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { css } from '@emotion/core';

const override = css`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const Loader = ({ size = 30, overridenCss = override }) => (
	<BounceLoader loading css={overridenCss} size={size} />
);

export default Loader;
