import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { isMobile } from 'react-device-detect';
import DataTable from 'react-data-table-component';
import { getPendingRequests } from '../../../../api/payment.request';
import Loader from '../../../../components/loader/index';
import Layout from '../../../../components/layouts/layout-default';
import HeaderHome from '../../../../components/common/header-home';
import { formatUTCDateToLocal } from '../../../../utils/formatter';
import MobileRefundRequestsTable from '../../../../containers/refund/mobile-refund-requests-table';

const columns = [
	{
		name: 'Refund Number',
		selector: 'refundNumber',
		sortable: true
	},
	{
		name: 'Created',
		cell: (row) => (
			<p>
				{formatUTCDateToLocal(row.requestDate, 'DD-MMM-YYYY').replace(
					/-/g,
					' '
				)}
			</p>
		),
		selector: 'requestDate',
		sortable: true
	},
	{
		name: 'Buyer',
		selector: 'order.buyerName',
		sortable: true
	},
	{
		name: 'Value',
		cell: (row) => (
			<div className="w-24 flex justify-between">
				<div className="flex flex-col justify-end">
					<span className="pr-1">$</span>
				</div>
				<div className="flex justify-end items-center w-full">
					<span style={{ textAlign: 'right' }}>
						{Number(row.totalCostWithSellerFeeExtra).toFixed(2)}
					</span>
				</div>
			</div>
		),
		selector: 'totalCostWithSellerFeeExtra',
		sortable: true,
		grow: 0
	},
	{
		name: 'Details',
		cell: (row) => (
			<Link
				to={`/sales/refund/details/${row.refundId}`}
				state={{
					refundDetails: row
				}}
			>
				<button
					type="button"
					className="rounded-md bg-white text-gray-900 hover:text-gray-50 cursor-pointer hover:bg-djawn-300 text-center h-10 w-24 border border-black disabled:opacity-50"
				>
					Details
				</button>
			</Link>
		),
		sortable: false,
		wrap: true,
		center: true
	}
];

const OrderRefundRequestsPage = ({ orderId, location }) => {
	const [pendingRefundRequests, setPendingRefundRequests] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!orderId) return;
		if (location.state && location.state.pendingRefundRequests) {
			setPendingRefundRequests(location.state.pendingRefundRequests);
		} else {
			getPendingRequests(orderId)
				.then((res) => {
					if (res && res.data && res.data.length) {
						setPendingRefundRequests(res.data);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [location.state, orderId]);

	useEffect(() => {
		if (!pendingRefundRequests || !pendingRefundRequests.length) return;
		setLoading(false);
	}, [pendingRefundRequests]);

	if (loading) {
		return <Loader />;
	}

	return (
		<Layout auth>
			<div className="flex flex-col flex-1">
				<HeaderHome
					siteTitle="Admin"
					hideSearch
					setLoading={setLoading}
					noMargins={isMobile}
				/>
				<div className="flex flex-col flex-grow w-full h-full mt-4 items-center">
					<div
						className={`flex flex-col bg-white ${
							isMobile ? 'w-full' : 'w-5/6'
						} rounded-lg content-center justify-center`}
					>
						<div className="w-full justify-center pb-8 shadow-lg">
							<div
								className={`${
									isMobile
										? 'flex flex-col lg:flex-row p-4 lg:p-8 justify-between items-between lg:items-stretch w-full'
										: 'grid w-full overflow-x-scroll xl:overflow-x-hidden rounded-md border'
								}`}
							>
								<div className="flex items-center justify-between p-4">
									<h3>Refund requests</h3>
									<Link to="/sales">
										<div className="rounded-lg px-3 py-2 md:my-0 my-3 w-32 bg-djawn text-white cursor-pointer hover:bg-djawn-300 text-center md:h-auto h-10 flex items-center">
											<span className="rounded-xl bg-black mr-2">
												<svg
													className="w-6 h-6"
													fill="none"
													stroke="currentColor"
													viewBox="0 0 24 24"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="3"
														d="M15 19l-7-7 7-7"
													/>
												</svg>
											</span>
											Sales List
										</div>
									</Link>
								</div>
								{isMobile ? (
									<MobileRefundRequestsTable data={pendingRefundRequests} />
								) : (
									// <MobileOrderTable
									// 	data={tableOrders}
									// 	useSalesList={useSalesList}
									// 	getPurchasedOrders={getPurchasedOrders}
									// 	limit={limitMobileOrders}
									// />
									<DataTable
										columns={columns}
										data={pendingRefundRequests}
										pagination
										noContextMenu
										className=""
										contextActions={null}
										striped
										paginationComponentOptions={{
											rowsPerPageText: 'Rows per page:',
											rangeSeparatorText: 'of',
											noRowsPerPage: false,
											selectAllRowsItem: true,
											selectAllRowsItemText: 'All'
										}}
										paginationRowsPerPageOptions={[10, 25, 100]}
										customStyles={{
											rows: {
												style: {}
											},
											headCells: {
												style: {
													backgroundColor: '#e82e2c',
													color: 'white',
													fontSize: '1rem',
													fontWeight: 'bold'
												}
											}
										}}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default OrderRefundRequestsPage;
