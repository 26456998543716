import React, { useEffect, useRef, useState } from 'react';
import AutoComplete from 'react-google-autocomplete';

import { isTablet } from 'react-device-detect';
import { updateProfileData } from '../../api/auth.request';
import env from '../../../environment.config';
import { defaultToastSucesss } from '../../utils/toast-success';
import { findAddressComponent } from '../../utils/placesParser';
import { defaultToastError } from '../../utils/toast-error';
import { defaultAutocompleteOptions } from '../../utils/autocomplete-utils';
import { isTruthy } from '../../utils/commonFunction';
import ConfirmationModal from '../confirmation-modal';
import useScreenOrientation from '../../hooks/useScreenOrientation';

const CheckoutShipping = ({
	setActiveStep,
	address,
	setAddress,
	city,
	setCity,
	zip,
	setZip,
	firstName,
	setFirstName,
	lastName,
	setLastName,
	mailingAddress2,
	setMailingAddress2,
	state,
	setState,
	nickname,
	googleMapsEnabled,
	addressRef,
	initialFirstName,
	initialLastName,
	initialState,
	initialZip,
	initialMailingAddress2,
	initialAddress,
	initialCity
}) => {
	const { isLandscape } = useScreenOrientation();
	const inputAddressRef = useRef();
	const [showAddressSaveModal, setShowAddressSaveModal] = useState(false);

	const [headingToNextPage, setHeadingToNextPage] = useState(false);

	useEffect(() => {
		if (!googleMapsEnabled) {
			inputAddressRef.current.focus();
		}
	}, [googleMapsEnabled]);

	const onPaymentClicked = () => {
		if (address && city && zip && state && firstName && lastName) {
			// setActiveStep(2);
			if (!showAddressSaveModal) {
				setActiveStep(2);
			} else {
				setHeadingToNextPage(true);
			}
		} else {
			defaultToastError({
				shortMessage: 'Missing Information',
				longMessage: 'Please enter the missing information'
			});
		}
	};

	useEffect(() => {
		setShowAddressSaveModal(
			address != initialAddress
				|| state != initialState
				|| zip != initialZip
				|| city != initialCity
				|| firstName != initialFirstName
				|| lastName != initialLastName
				|| mailingAddress2 != initialMailingAddress2
		);
	}, [address, state, zip, city, mailingAddress2, firstName, lastName]);

	const updateProfile = () => {
		const updatedProfileData = {
			nickName: nickname,
			firstName,
			lastName,
			mailingAddress1: address,
			mailingAddress2,
			mailingCity: city,
			mailingZip: zip,
			mailingState: state
		};

		updateProfileData(updatedProfileData)
			.then(() => {
				defaultToastSucesss(
					'The delivery address has been updated succesfully'
				);
				setActiveStep(2);
			})
			.catch((err) => {
				if (err.response && err.response.data.error) {
					defaultToastError(err.response.data.error);
				}
			});
	};
	return (
		<div className="flex-grow px-0 py-2 flex flex-col items-between">
			{headingToNextPage && (
				<ConfirmationModal
					showModal={showAddressSaveModal}
					setShowModal={(status) => {
						if (!status) setHeadingToNextPage(false);
						setShowAddressSaveModal(status);
					}}
					onConfirm={updateProfile}
					cancelText="Ignore"
					confirmText="Save"
					onCancel={() => {
						setActiveStep(2);
					}}
					text="Do you want to save address back to profile?"
				/>
			)}
			<div
				className={`flex flex-col justify-center content-center mt-1 relative rounded-md shadow-sm md:px-20 md:py-4 w-full ${
					isLandscape || isTablet ? '' : 'md:w-1/2 items-center'
				} mx-auto`}
			>
				<div className="border-2 dark:border-black rounded-lg shadow-md px-5 py-5">
					<div
						className={`flex flex-${
							isLandscape || isTablet ? 'col items-start' : 'row items-center'
						}  pb-4`}
					>
						<label
							htmlFor="name"
							className="block text-sm font-medium text-gray-700 pr-2 dark:text-white"
						>
							First Name:
						</label>
						<input
							type="text"
							name="first_name"
							id="name"
							className="focus:ring-indigo-400 dark:bg-black dark:text-white focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
							placeholder="First Name - Required value"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>
						<label
							htmlFor="name"
							className="block text-sm font-medium text-gray-700 pr-2 dark:text-white"
						>
							Last Name:
						</label>
						<input
							type="text"
							name="last_name"
							id="lastName"
							className="focus:ring-indigo-400 dark:bg-black dark:text-white focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
							placeholder="Last Name - Required value"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>
					</div>
					<div
						className={`flex flex-col md:flex-${
							isLandscape || isTablet ? 'col items-start' : 'row items-center'
						}`}
					>
						<div
							className={`flex flex-${
								isLandscape || isTablet
									? 'col items-start w-full'
									: 'row items-center'
							} pb-4`}
						>
							<label
								htmlFor="address"
								className="block text-sm font-medium text-gray-700 pr-2  dark:text-white"
							>
								Street Address Line:
							</label>
							{isTruthy(googleMapsEnabled) ? (
								<AutoComplete
									apiKey={env.google_places_key}
									defaultValue={address}
									ref={addressRef}
									onChange={(e) => {
										if (e.target.value || e.target.value.length == 0) {
											setCity('');
											setZip('');
											setState('');
										}
										setAddress(e.target.value);
									}}
									onPlaceSelected={(place, e) => {
										const streetNumber = findAddressComponent(
											place.address_components,
											'street_number'
										);

										const route = findAddressComponent(
											place.address_components,
											'route'
										);

										if (route) {
											if (streetNumber && streetNumber != null) {
												setAddress(`${streetNumber} ${route}`);
											} else {
												setAddress(route);
											}

											if (e.value) {
												if (streetNumber) {
													e.value = `${streetNumber} ${route}`;
												} else {
													e.value = route;
												}
											}
										}

										const foundPostalCode = findAddressComponent(
											place.address_components,
											'postal_code'
										);
										if (foundPostalCode) setZip(foundPostalCode);

										const foundState = findAddressComponent(
											place.address_components,
											'administrative_area_level_1',
											true
										);
										if (foundState) setState(foundState);

										if (place.vicinity) setCity(place.vicinity);
									}}
									className="focus:ring-indigo-400  dark:bg-black dark:text-white focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
									options={defaultAutocompleteOptions}
									placeholder="Street Address Line - Required value"
									onFocus={() => {
										// setFocusedAddressLine(1);
										// calculateTopToPlacesContainer();
									}}
									onBlur={() => {
										// calculateTopToPlacesContainer();
									}}
								/>
							) : (
								<input
									type="text"
									className="focus:ring-indigo-400  dark:bg-black dark:text-white focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
									placeholder="Street Address Line - Required value"
									value={address}
									ref={inputAddressRef}
									onChange={({ target }) => setAddress(target.value)}
								/>
							)}
						</div>

						<div
							className={`flex flex-${
								isLandscape || isTablet
									? 'col items-start w-full'
									: 'row items-center'
							} pb-4`}
						>
							<label
								htmlFor="address"
								className="block text-sm font-medium text-gray-700 pr-2  dark:text-white"
							>
								Apartment, suite, etc:
							</label>
							<input
								type="text"
								name="mailingAddress1"
								id="mailingAddress1"
								className="focus:ring-indigo-400 focus:border-indigo-400 dark:bg-black dark:text-white pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
								placeholder="Apartment, suite, etc."
								value={mailingAddress2}
								// disabled={googleMapsEnabled}
								onChange={(e) => setMailingAddress2(e.target.value)}
							/>
						</div>
					</div>
					<div className="flex xxs:flex-col lg:flex-row xxs:w-full mb-4 xxs:mb-4">
						<div className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-4 lg:pr-4">
							<div className="w-full mt-1 relative rounded-md shadow-sm">
								<input
									type="text"
									name="city"
									id="city"
									className="focus:ring-indigo-400 dark:bg-black dark:text-white focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
									placeholder="City"
									value={city}
									onChange={(e) => setCity(e.target.value)}
									disabled={googleMapsEnabled}
								/>
							</div>
						</div>

						<div className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-4 lg:pr-4 ">
							<div className="w-full mt-1 relative rounded-md shadow-sm">
								<input
									type="text"
									name="state"
									id="state"
									className="focus:ring-indigo-400 focus:border-indigo-400 dark:bg-black dark:text-white pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
									placeholder="State"
									value={state}
									onChange={(e) => setState(e.target.value)}
									disabled={googleMapsEnabled}
								/>
							</div>
						</div>

						<div className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-0 disabled">
							<div className="w-full mt-1 relative rounded-md shadow-sm">
								<input
									type="text"
									name="post_code"
									id="post_code"
									className="focus:ring-indigo-400 dark:bg-black dark:text-white focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
									placeholder="Zip"
									value={zip}
									onChange={(e) => setZip(e.target.value)}
									disabled={googleMapsEnabled}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-row px-2 py-1 mb-8 justify-between items-end px-10 h-full">
				<div className="">
					<div className="flex flex-row">
						<button
							type="button"
							className="w-full focus:outline-none text-white text-sm py-2.5 px-5 rounded-sm bg-djawn hover:bg-red-600 hover:shadow-lg disabled:opacity-50 disabled:cursor-wait"
							// onClick={() => {setShowCheckout(false)}}
							onClick={() => {
								setActiveStep(0);
							}}
						>
							Back to order
						</button>
					</div>
				</div>
				<div className="flex flex-col">
					<div className="flex">
						<button
							type="button"
							className="w-full focus:outline-none text-white text-sm py-2.5 px-5 rounded-sm bg-djawn hover:bg-red-600 hover:shadow-lg disabled:opacity-50 disabled:cursor-wait"
							// onClick={(e) => onCheckoutButtonClicked()}
							// onClick={(e) => setActiveStep(2)}
							onClick={onPaymentClicked}
						>
							Payment
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CheckoutShipping;
