import React, { useState, useEffect } from 'react';
import ConversationTypeMessage from '../../components/conversation/conversation-type-message';
import PaymentBillingDetails from '../../components/payment-billing-details';
import { isTruthy } from '../../utils/commonFunction';
import { formatUTCDateToLocal } from '../../utils/formatter';
import { getSlotName } from '../../utils/slot';

const MobileRefundRequestDetails = ({
	order,
	refundItems,
	onInfoIconClicked,
	grossCost,
	djawnFee,
	shippingPrice,
	totalShippingCostTip,
	totalCostTip,
	djawnFeeTip,
	discount,
	refundReasons,
	requestedRefundReason,
	refundStatus,
	setRequestedRefundReason,
	attachments,
	selectedRefundReason,
	onAttachmentDownloaded,
	showRefundReasonsSelect = true,
	setAdditionalInformationMessage
}) => {
	const [
		additionalInformationInputFocused,
		setAdditionalInformationInputFocused
	] = useState(true);
	const [
		enabledAdditionalInformationInput,
		setEnabledAdditionalInformationInput
	] = useState(false);

	useEffect(() => {
		if (!additionalInformationInputFocused) {
			if (window) {
				// eslint-disable-next-line no-multi-assign
				document.body.scrollTop = document.documentElement.scrollTop = 0;
			}
		}
	}, [additionalInformationInputFocused]);

	useEffect(() => {
		document.body.style.overflow = 'hidden';

		setTimeout(() => {
			setEnabledAdditionalInformationInput(true);
		}, 1000);
		return () => {
			document.body.style.overflow = 'visible';
		};
	}, []);

	return (
		<>
			<div className="order-details-header flex bg-white rounded-lg md:justify-between md:px-4 px-2 py-4 flex-col md:flex-row shadow-lg mb-3">
				<b>Refund Request details</b>
				<div className="p-2 mb-4">
					<div className="flex">
						<b className="pr-3">Order no</b>
						{'  '}
						<p>{order.orderNumber}</p>
					</div>
					<div className="flex">
						<b className="pr-3">Order Date</b>
						{'  '}
						<p>
							{formatUTCDateToLocal(order.orderDateTime, 'DD-MMM-YYYY').replace(
								/-/g,
								' '
							)}
						</p>
					</div>
				</div>
				<div className="flex">
					<table className="w-full">
						<thead>
							<tr>
								<td align="center">
									<b>Event name:</b>
								</td>
								<td align="center">
									<b>Slot Name:</b>
								</td>
							</tr>
						</thead>
						<tbody className="w-full">
							{refundItems.map((refundItem) => (
								<tr>
									<td className="w-1/2">{refundItem.event.eventName}</td>
									<td align="center">
										{refundItem.productId != null
											? refundItem.productName
											: getSlotName(refundItem)}
									</td>
									<td align="center">
										<div
											className="pl-4"
											onClick={() => {
												onInfoIconClicked(refundItem);
											}}
											onKeyDown={() => {
												onInfoIconClicked(refundItem);
											}}
										>
											<svg
												className="w-6 h-6"
												fill="black"
												stroke="white"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
												/>
											</svg>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			<div className="flex bg-white rounded-lg justify-between px-4 py-4 shadow-lg mt-3 mb-3">
				<div className="flex justify-between flex-col md:flex-row">
					<PaymentBillingDetails
						total={Number(
							Number(grossCost)
								+ Number(shippingPrice)
								+ (Number(djawnFee) - Number(discount))
						).toFixed(2)}
						subtotal={Number(Number(grossCost)).toFixed(2)}
						shipping={shippingPrice}
						djawnFees={djawnFee}
						djawnDiscount={discount}
						paymentMethod={order.paymentProcessor.referenceDataValue}
						shippingTip={totalShippingCostTip}
						djawnFeesTip={djawnFeeTip}
						totalTip={totalCostTip}
					/>
				</div>
			</div>
			<div className="flex flex-col bg-white rounded-lg justify-between px-4 py-4 shadow-lg mt-3 mb-3">
				{showRefundReasonsSelect ? (
					<>
						<b className="p-1">Refund reason</b>
						<select
							id="request_reason"
							name="request_reason"
							autoComplete="request_reason"
							className="h-10 text-sm focus:outline-none border px-5 rounded-md mb-3 md:mb-0"
							onBlur={(e) => setRequestedRefundReason(e.target.value)}
							onChange={(e) => setRequestedRefundReason(e.target.value)}
							value={requestedRefundReason || ''}
						>
							<option key={null} value={0} selected>
								Refund Reason
							</option>
							{refundReasons.map((refundReason) => (
								<option key={refundReason.id} value={refundReason.id}>
									{refundReason.value}
								</option>
							))}
						</select>
						<b className="p-1">Additional information</b>
						<div className="mb-2">
							<ConversationTypeMessage
								hideSendButton
								hideAttachmentsButton
								hidePlaceholder
								toolbarId="cr22"
								authenticated
								enabledChatInput={enabledAdditionalInformationInput}
								setInputFocused={setAdditionalInformationInputFocused}
								reduceReplyMessageSize={false}
								useInlineInputEditor={false}
								setMessageValue={setAdditionalInformationMessage}
							/>
						</div>
					</>
				) : (
					<p className="inline-flex w-full whitespace-nowrap">
						<b>Refund Reason</b>
						<span className="pl-1 pr-2">:</span>
						<p>{selectedRefundReason}</p>
					</p>
				)}
				{refundStatus ? (
					<p className="inline-flex w-full whitespace-nowrap">
						<b>Refund Status</b>
						<span className="pl-1 pr-2">:</span>
						<p>{refundStatus}</p>
					</p>
				) : null}
				{isTruthy(attachments && attachments.length) && (
					<div className="flex flex-col justify-center pb-4">
						<b className="pr-3">Attachments</b>
						{isTruthy(attachments.length) && (
							<div className="grid grid-cols-2 mb-3 pb-3">
								{attachments.map((object, i) => (
									<div className="flex pt-2 col-span-0" key={i}>
										<div className="md:p-1 text-left md:text-left space-y-4 break-all overflow-x-hidden">
											<div className="flex flex-none items-center border rounded-2xl">
												<div className="flex items-center justify-between text-center w-full">
													<span className="text-md mr-1 p-1 font-sans w-full pl-5">
														{object.fileName}
													</span>
													<button
														type="button"
														className="focus:outline-none text-black hover:text-black text-lg p-3 rounded-md rounded-tr-none rounded-br-none"
														onClick={() => onAttachmentDownloaded(object.fileName)}
													>
														<svg
															className="w-5 h-4"
															fill="none"
															stroke="currentColor"
															viewBox="0 0 24 24"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth="2"
																d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
															/>
														</svg>
													</button>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default MobileRefundRequestDetails;
