import React from 'react';

const BreaksEventQueue = ({ breaks }) => (
	<div className="">
		<table className="table-fixed text-left">
			<thead>
				<tr>
					<th className="w-1/12 p-4 text-left text-sm font-medium text-gray-100 bg-djawn">
						#
					</th>
					<th className="w-3/12 p-4 text-left text-sm font-medium text-gray-100 bg-djawn">
						Buyer
					</th>
					<th className="w-3/12 p-4 text-left text-sm font-medium text-gray-100 bg-djawn">
						Team
					</th>
				</tr>
			</thead>
			<tbody className="text-gray-800 text-sm dark:text-white dark:bg-darkGray-50">
				{breaks.map((brk) => (
					<tr>
						{/* <td style={{margin:'13px'}}>{attendee.isOnline ? <OnlineIcon /> : <OfflineIcon />}</td>
										<td><p className="text-base font-light leading-relaxed mb-2 text-center">{attendee.nickname}</p></td> */}
						<td className="px-4 xxs:px-4 py-2 whitespace-nowrap">{brk.slot}</td>
						<td className="px-4 xxs:px-4 py-2 whitespace-nowrap">
							<p className="text-base font-light leading-relaxed mb-2 text-left">
								{brk.buyerNickname}
							</p>
						</td>
						<td className="px-4 xxs:px-4 py-2 whitespace-nowrap">
							<p className="text-base font-light leading-relaxed mb-2 text-left">
								{brk.slotName}
							</p>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	</div>
);

export default BreaksEventQueue;
