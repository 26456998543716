/* eslint-disable radix */
import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Stepper from '../../components/stepper';
import { CreateEventContext } from '../../contexts/create-event';
import { PICK_YOUR_SLOTS, RANDOMIZED, RIP_N_SHIP } from '../../utils/constants';

const STEP_LABELS = {
	SETUP_EVENT: 'Setup Event',
	EVENT_DETAILS: 'Event Details',
	RIPNSHIP_DETAILS: "Rip N' Ship Details",
	SELECT_TEMPLATE: 'Select Template',
	EDIT_TEMPLATE: 'Edit Template',
	SETUP_TIERS: 'Setup Tiers',
	PRODUCT_DETAILS: 'Product Details'
};

const CreateEventSteppers = () => {
	const { eventTypeId, activeStep } = useContext(CreateEventContext);
	const [steps, setSteps] = useState([]);

	const updateStepsVisibility = (createEventSteps) => {
		if (!createEventSteps || !createEventSteps.length) return;

		const tempSteps = [...createEventSteps];
		tempSteps.map((step, index) => {
			if (
				index == activeStep
				|| activeStep == index - 1
				|| activeStep == index + 1
			) {
				step.hidden = false;
			} else {
				step.hidden = true;
			}
			return step;
		});

		setSteps(tempSteps);
	};

	const getStepLabelsByEventTypeId = () => {
		let stepLabels = [];
		switch (parseInt(eventTypeId)) {
			case RANDOMIZED:
				stepLabels = [
					{ label: STEP_LABELS.SETUP_EVENT },
					{ label: STEP_LABELS.SELECT_TEMPLATE },
					{ label: STEP_LABELS.EDIT_TEMPLATE },
					{ label: STEP_LABELS.SETUP_TIERS },
					{ label: STEP_LABELS.PRODUCT_DETAILS }
				];
				break;
			case PICK_YOUR_SLOTS:
				stepLabels = [
					{ label: STEP_LABELS.SETUP_EVENT },
					{ label: STEP_LABELS.EVENT_DETAILS },
					{ label: STEP_LABELS.SETUP_TIERS },
					{ label: STEP_LABELS.PRODUCT_DETAILS }
				];
				break;
			case RIP_N_SHIP:
				stepLabels = [
					{ label: STEP_LABELS.SETUP_EVENT },
					{ label: STEP_LABELS.EVENT_DETAILS },
					{ label: STEP_LABELS.RIPNSHIP_DETAILS }
				];
				break;
			default:
				stepLabels = [{ label: STEP_LABELS.SETUP_EVENT }];
				break;
		}
		if (isMobile) {
			updateStepsVisibility(stepLabels);
		} else {
			setSteps(stepLabels);
		}
	};

	useEffect(() => {
		getStepLabelsByEventTypeId();
	}, [eventTypeId]);

	useEffect(() => {
		if (!isMobile) return;
		updateStepsVisibility(steps);
	}, [activeStep, isMobile]);

	return (
		<div className="flex flex-grow px-12 p-0">
			<div className="flex flex-row justify-center w-full mx-auto mt-8 h-auto">
				<Stepper
					activeStep={activeStep}
					showNumber={!isMobile}
					className="w-8/12"
					steps={steps}
				/>
			</div>
		</div>
	);
};

export default CreateEventSteppers;
