import React from 'react';

const LiveNowButton = () => (
	<p className="text-sm font-semibold text-white border rounded-md px-1 flex justify-center items-center live-now-color absolute live-now w-29 feed-button">
		<svg
			version="1.1"
			id="Capa_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 512 512"
			className="w-4 h-4 live-now-svg"
		>
			<g>
				<g>
					<path d="M160.536,21.211L139.325,0c-64.321,64.336-64.321,169.014,0,233.35l21.211-21.211C107.919,159.507,107.919,73.843,160.536,21.211z" />
				</g>
			</g>
			<g>
				<g>
					<path d="M372.675,0l-21.211,21.211c52.617,52.632,52.617,138.296,0,190.928l21.211,21.211
			C436.996,169.014,436.996,64.336,372.675,0z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path d="M202.973,63.648l-21.211-21.211c-19.805,19.805-30.718,46.157-30.718,74.238s10.913,54.434,30.718,74.238l21.211-21.211
			c-14.136-14.136-21.929-32.974-21.929-53.027S188.837,77.783,202.973,63.648z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path d="M330.238,42.437l-21.211,21.211c14.136,14.136,21.929,32.974,21.929,53.027s-7.793,38.892-21.929,53.027l21.211,21.211
			c19.805-19.805,30.718-46.157,30.718-74.238S350.043,62.241,330.238,42.437z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path d="M256,71.675c-24.814,0-45,20.185-45,45c0,24.814,20.186,45,45,45c24.814,0,45-20.186,45-45
			C301,91.861,280.814,71.675,256,71.675z"
					/>
				</g>
			</g>
			<g>
				<g>
					<path d="M386.66,482L285.427,185.629c-9.044,3.875-18.983,6.046-29.427,6.046c-10.444,0-20.383-2.172-29.427-6.046L125.34,482H76
			v30h360v-30H386.66z M230.138,270h51.724l16.811,49.327L256,360.222l-42.673-40.895L230.138,270z M202.487,350.495L234.318,381
			l-63.662,61.009L202.487,350.495z M173.333,482L256,401.778L338.667,482H173.333z M277.682,381.001l31.831-30.505l31.831,91.514
			L277.682,381.001z"
					/>
				</g>
			</g>
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
			<g />
		</svg>

            Live
            Now
	</p>
);

export default LiveNowButton;
