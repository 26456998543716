import React, { useEffect, useState } from 'react';
import { RaiseHandIcon } from '../video/raise-hand-icon';

const ICON_TYPE = {
	RAISE_HAND: 'RAISE_HAND',
	MIC_ICON: 'MIC_ICON'
};

const MicOrRequestIcon = ({
	attendee,
	audioParticipants,
	matchAttendeesViaUserId = false,
	setRaisedHandOrMicIconUserId
}) => {
	const [icon, setIcon] = useState('');

	useEffect(() => {
		if (
			attendee
			&& attendee.userId
			&& audioParticipants
			&& audioParticipants.length
			&& audioParticipants.find((p) => {
				if (matchAttendeesViaUserId) {
					if (!p.userId || !attendee.userId) return false;
					return p.userId == attendee.userId;
				}
				return p.twilioParticipantId == attendee.twilioParticipantId;
			}) != null
		) {
			setIcon(ICON_TYPE.MIC_ICON);
			if (setRaisedHandOrMicIconUserId) {
				setRaisedHandOrMicIconUserId(attendee.userId);
			}
			return;
		}

		if (attendee && attendee.micRequest && attendee.micRequest === true) {
			setIcon(ICON_TYPE.RAISE_HAND);
			if (setRaisedHandOrMicIconUserId) {
				setRaisedHandOrMicIconUserId(attendee.userId);
			}
			return;
		}
		setIcon(null);
	}, [attendee, audioParticipants]);

	if (icon == ICON_TYPE.MIC_ICON) {
		return (
			<svg
				className="w-5 h-5"
				fill="currentColor"
				stroke="currentColor"
				viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
				/>
			</svg>
		);
	}

	if (icon == ICON_TYPE.RAISE_HAND) {
		return <RaiseHandIcon />;
	}
	return null;
};

export default MicOrRequestIcon;
