import { useEffect } from 'react';

const useAsyncEffect = (effect, destroy, inputs) => {
	const hasDestroy = typeof destroy === 'function';

	useEffect(
		() => {
			let result;
			let mounted = true;
			const promise = effect(() => mounted);
			Promise.resolve(promise).then((promiseResult) => {
				result = promiseResult;
			});

			return () => {
				mounted = false;

				if (hasDestroy) {
					destroy(result);
				}
			};
		},
		hasDestroy ? inputs : destroy
	);
};

export default useAsyncEffect;
