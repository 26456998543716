import React, {
	Fragment, useEffect, useState, useRef
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
	getQueueOrderPurchasedProducts,
	getQueueOrderPurchasedProductsByQueueOrder
} from '../../api/event.request';

const PurchasedProductsModal = ({
	showModal,
	setShowModal,
	userId,
	eventId,
	queueOrderId,
	setQueueOrderId
}) => {
	const [products, setProducts] = useState([]);
	const cancelButtonRef = useRef(null);

	useEffect(() => {
		if (userId == null && queueOrderId == null) return;

		if (queueOrderId == null) {
			getQueueOrderPurchasedProducts(eventId, userId).then((res) => {
				setProducts(res.data);
			});
		} else {
			getQueueOrderPurchasedProductsByQueueOrder(queueOrderId).then((res) => {
				setProducts(res.data);
			});
		}
	}, [userId, queueOrderId]);

	if (!products || !products.length || products.length == 0) {
		return null;
	}
	return (
		<Transition.Root show={showModal} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed inset-0 overflow-y-auto"
				style={{ zIndex: '100' }}
				initialFocus={cancelButtonRef}
				open={showModal}
				onClose={() => {
					setQueueOrderId(null);
					setShowModal(false);
				}}
			>
				<div className="flex items-center md:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay
							className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
							style={{ zIndex: '1040!important' }}
						/>
					</Transition.Child>

					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						style={{ zIndex: '1050!important' }}
					>
						<div className="inline-block fixed md:relative bg-opacity-100 align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
							<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative w-full">
								<div className="flex items-center justify-center w-full">
									<div className="mt-3 text-center justify-center sm:mt-0 sm:ml-4 lg:ml-0 sm:text-left w-full">
										<div className="flex flex-col md:flex-row items-center justify-center w-full">
											<div className="mt-4 w-full">
												<table className="table-fixed text-left">
													<thead>
														<tr>
															<th
																className="w-1/12 p-4 text-left text-sm font-medium text-gray-100 bg-gray-100 "
																style={{
																	backgroundColor: '#FF5733'
																}}
															>
																Qty
															</th>
															<th
																className="w-4/12 p-4 text-left text-sm font-medium text-gray-100"
																style={{
																	backgroundColor: '#FF5733'
																}}
															>
																Product Name
															</th>
															<th
																className="w-2/12 p-4 text-left text-sm font-medium text-gray-100"
																style={{
																	backgroundColor: '#FF5733'
																}}
															>
																Price
															</th>
														</tr>
													</thead>
													<tbody className="text-gray-800 text-sm dark:text-white dark:bg-darkGray-50">
														{products.map((product) => (
															<tr>
																<td className="px-4 xxs:px-4 py-2 whitespace-nowrap">
																	<p className="text-base font-light leading-relaxed mb-2 text-left">
																		{product.quantity}
																	</p>
																</td>
																<td className="px-4 xxs:px-4 py-2">
																	<p className="text-base font-light leading-relaxed mb-2 text-left">
																		{product.product.productName}
																	</p>
																</td>
																<td className="px-4 xxs:px-4 py-2 whitespace-nowrap">
																	<p className="text-base font-light leading-relaxed mb-2 text-left">
																		$
																		{product.unitCost * product.quantity}
																	</p>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>

								<button
									type="button"
									className="absolute px-3 pt-3 text-gray-500 top-0 right-0 cursor-pointer outline-none border-none focus:outline-none"
									onClick={() => {
										setQueueOrderId(null);
										setShowModal(false);
									}}
								>
									X
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default PurchasedProductsModal;
