/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import useErrorNotifier from '../../hooks/use-error-notifier';

const EditEventSaveTemplateModal = ({ showModal, onNext, onClose }) => {
	const { showToastError } = useErrorNotifier();
	const [templateName, setTemplateName] = useState('');
	const [saveTemplate, setSaveTemplate] = useState(0);

	const onNextButton = () => {
		if (templateName == '') {
			showToastError('Please fill all the fields.');
			return;
		}
		onNext(templateName);
	};

	return (
		<div>
			{showModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-1 top-2.5 z-50 top-30 outline-none focus:outline-none">
						<div className="relative w-auto my-6 mx-auto max-w-3xl">
							{/* content */}
							<div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/* header */}

								{/* body */}
								<div className="relative p-6 text-center justify-center overflow-hidden h-100">
									<span className="text-lg font-bold">
										Save Template For Later Use
									</span>
									<div className="py-1" />
									<div className="flex flex-grow p-4">
										<label
											htmlFor="template_name"
											className="block text-sm font-bold text-gray-700 p-4"
										>
											Template Name
										</label>
										<div className="mt-1 relative">
											<input
												type="text"
												name="templateName"
												id="templateName"
												maxLength="30"
												className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md"
												placeholder="Template Name"
												value={templateName}
												onBlur={(e) => setTemplateName(e.target.value)}
												onChange={(e) => setTemplateName(e.target.value)}
											/>
										</div>
									</div>
								</div>
								{/* footer */}
								<div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
									<button
										className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-lg"
										type="button"
										onClick={() => onClose()}
									>
										Close
									</button>
									<button
										className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg ml-2"
										type="button"
										onClick={() => onNextButton()}
									>
										Next
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black" />
				</>
			) : null}
		</div>
	);
};

export default EditEventSaveTemplateModal;
