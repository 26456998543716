/* eslint-disable no-unused-vars */
import React from 'react';
import { toast } from 'react-toastify';

export const defaultToastSucesss = (message) => {
	toast.success(message, {
		position: 'top-center',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'colored'
	});
};
