import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import TemplateFilter from '../../components/Event/template-filter';
import { CreateEventContext } from '../../contexts/create-event';
import NextButton from '../../components/button/next-button';
import BackButton from '../../components/button/back-button';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ViewEventTemplateModal from '../../components/Event/view-template-modal';
import useErrorNotifier from '../../hooks/use-error-notifier';
import BreakTemplateList from '../../components/break-templates';

// TODO: template action types can have enums
const SelectOrCreateTemplate = () => {
	const { showToastError } = useErrorNotifier();
	const {
		loadTemplates,
		loadUserTemplates,
		sellerImage,
		templates,
		eventTypeId,
		sports,
		userTemplates,
		breakTemplate,
		setBreakTemplate,
		setActiveStep,
		templateActionType,
		setTemplateActionType,
		setLoading
	} = useContext(CreateEventContext);

	const [userTemplatesSliderSize, setUserTemplatesSliderSize] = useState(2);
	const [templatesSliderSize, setTemplatesSliderSize] = useState(6);
	const [createNewTemplate, setCreateNewTemplate] = useState(false);
	const [showTemplatesFilterModal, setShowTemplatesFilterModal] = useState(
		false
	);
	const [showTemplateModal, setShowTemplateModal] = useState(false);
	const [filteredSportTypes, setFilteredSportTypes] = useState('');
	const [filteredTierSize, setFilteredTierSize] = useState(1);
	const [filteredSlotSize, setFilteredSlotSize] = useState('');
	const [filteredTemplates, setFilteredTemplates] = useState([]);
	const [filteredUserTemplates, setFilteredUserTemplates] = useState([]);
	const [applyFilter, setApplyFilter] = useState(false);
	const [selectedTemplateId, setSelectedTemplateId] = useState('');
	const [currentSelectedTemplate, setCurrentSelectedTemplate] = useState('');
	const [dataChanged, setDataChanged] = useState(false);

	useEffect(() => {
		setLoading(true);
		loadTemplates().then(() => {
			loadUserTemplates().then(() => {
				setLoading(false);
			});
		});
	}, []);

	const onNextStep = (values) => {
		setBreakTemplate({
			...breakTemplate,
			templateId: values.templateId,
			template: values.template,
			dataChanged
		});
	};

	const onNextClick = () => {
		if (createNewTemplate) {
			onNextStep({
				templateId: '',
				template: [],
				dataChanged
			});
			setTemplateActionType('create');
			setActiveStep(2);
			return;
		}

		if (
			templateActionType === ''
			|| selectedTemplateId === ''
			|| selectedTemplateId == undefined
		) {
			showToastError('Please select one template or create your own template');
			return;
		}

		onNextStep({
			type: templateActionType,
			templateId: selectedTemplateId,
			template: currentSelectedTemplate,
			dataChanged
		});
		setActiveStep(2);
	};

	const updateTemplateSliderSize = (data, djawnTemplates = true) => {
		let size = 1;
		if (!isMobile) {
			size = data.length <= 6 ? data.length : 6;
		}
		if (djawnTemplates) {
			setTemplatesSliderSize(size);
		} else {
			setUserTemplatesSliderSize(size);
		}
	};

	const onBackClick = () => {
		setActiveStep(0);
	};

	useEffect(() => {
		if (templates && templates.length) {
			updateTemplateSliderSize(templates);
		}
	}, [templates]);

	useEffect(() => {
		if (userTemplates && userTemplates.length) {
			updateTemplateSliderSize(userTemplates, false);
		}
	}, [userTemplates]);

	const resetFilters = () => {
		setFilteredTemplates([]);
		updateTemplateSliderSize(templates);

		setFilteredUserTemplates([]);
		updateTemplateSliderSize(userTemplates, false);

		setFilteredSportTypes('');
		setFilteredSlotSize('');
		setFilteredTierSize('');
		setApplyFilter(false);
	};

	const previewTemplateModal = (template) => {
		setCreateNewTemplate(false);
		if (template.breakTemplateId == selectedTemplateId) setDataChanged(false);
		else setDataChanged(true);
		setSelectedTemplateId(template.breakTemplateId);
		setCurrentSelectedTemplate(template);

		setTemplateActionType('template');
		setShowTemplateModal(true);
	};

	const onTemplateFilter = (sportType, tierSize, slotSize) => {
		setShowTemplatesFilterModal(false);
		setLoading(true);
		setSelectedTemplateId('');
		setCurrentSelectedTemplate('');
		setFilteredSlotSize(slotSize);
		setFilteredTierSize(tierSize);
		setFilteredSportTypes(sportType);

		let tempDjawnTemplates = [...templates];
		if (sportType && sportType != 0) {
			tempDjawnTemplates = tempDjawnTemplates.filter(
				(x) => x.sportTypeId == sportType
			);
		}

		if (tierSize) {
			tempDjawnTemplates = tempDjawnTemplates.filter(
				(x) => x.tierSize == tierSize
			);
		}
		if (slotSize) {
			tempDjawnTemplates = tempDjawnTemplates.filter(
				(x) => x.slotSize == slotSize
			);
		}

		updateTemplateSliderSize(tempDjawnTemplates);
		setFilteredTemplates(tempDjawnTemplates);

		let tempMyTemplates = [...userTemplates];
		if (sportType && sportType != 0) {
			tempMyTemplates = tempMyTemplates.filter(
				(x) => x.sportTypeId == sportType
			);
		}

		if (tierSize) {
			tempMyTemplates = tempMyTemplates.filter((x) => x.tierSize == tierSize);
		}
		if (slotSize) {
			tempMyTemplates = tempMyTemplates.filter((x) => x.slotSize == slotSize);
		}
		updateTemplateSliderSize(tempMyTemplates, false);
		setFilteredUserTemplates(tempMyTemplates);
		setApplyFilter(true);
		setLoading(false);
	};

	return (
		<div>
			{showTemplatesFilterModal && (
				<TemplateFilter
					eventType={eventTypeId}
					sportTypes={sports}
					filteredSlotSize={filteredSlotSize}
					filteredSportType={filteredSportTypes}
					filteredTierSize={filteredTierSize}
					onFilter={(sportType, tierSize, slotSize) => onTemplateFilter(sportType, tierSize, slotSize)}
					onClose={() => setShowTemplatesFilterModal(false)}
				/>
			)}
			<div>
				<div className="flex flex-row mb-1 items-center">
					<h3 className="w-1/2">Select a template or start from scratch</h3>
					<div className="flex flex-row w-1/2">
						<div className="flex flex-col" style={{ width: '92%' }}>
							<button
								type="button"
								title="Remove Filters"
								className="bg-blue-600 hover:bg-blue-700 text-white font-bold p-2 w-1/3 lg:w-1/5"
								style={{ alignSelf: 'flex-end' }}
								onClick={resetFilters}
							>
								Reset
							</button>
						</div>
						<div
							className="flex flex-col justify-between items-center"
							style={{ width: '8%' }}
						>
							<button
								onClick={() => setShowTemplatesFilterModal(true)}
								title="Filters"
								type="button"
								className="shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
								id="menu-button"
								aria-expanded="true"
								aria-haspopup="true"
								style={{ height: '100%' }}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="15pt"
									height="15pt"
									viewBox="0 0 512 512"
									version="1.1"
								>
									<g>
										<path d="m187.304 252.717c8.045 11.642 5.64 1.941 5.64 233.997 0 20.766 23.692 32.651 40.39 20.23 71.353-53.797 85.609-58.456 85.609-83.619 0-169.104-1.971-159.594 5.64-170.608l115.869-157.718h-369.016z" />
										<path d="m484.221 12.86c-4.14-7.93-12.26-12.86-21.199-12.86h-414.156c-19.305 0-30.664 21.777-19.59 37.6.091.152-1.257-1.693 20.12 27.4h413.095c18.217-24.793 30.394-35.505 21.73-52.14z" />
									</g>
								</svg>
							</button>
						</div>
					</div>
				</div>
				<div className="border p-5">
					<h3>Djawn Templates: </h3>
					<div className="p-8">
						<BreakTemplateList
							templatesSliderSize={templatesSliderSize}
							onTemplateClick={previewTemplateModal}
							templateId={selectedTemplateId}
							templates={applyFilter ? filteredTemplates : templates}
							actionType={templateActionType}
							noTemplatesText="'No Djawn Templates were Found'"
						/>
					</div>
				</div>

				{showTemplateModal ? (
					<ViewEventTemplateModal
						showModal={showTemplateModal}
						template={currentSelectedTemplate}
						onClose={() => setShowTemplateModal(false)}
					/>
				) : null}
				<div className="border p-5 my-5">
					<h3>My Templates: </h3>
					<div className="p-8">
						{sellerImage ? (
							<BreakTemplateList
								templatesSliderSize={userTemplatesSliderSize}
								onCreate={() => {
									setDataChanged(true);
									setCreateNewTemplate(true);
									setSelectedTemplateId([]);
								}}
								onTemplateClick={previewTemplateModal}
								createPlaceholder
								templateId={selectedTemplateId}
								templates={applyFilter ? filteredUserTemplates : userTemplates}
								image={sellerImage}
								showDefaultImage
								createNewTemplate={createNewTemplate}
							/>
						) : null}
					</div>
				</div>
			</div>
			<div className="flex justify-center mx-auto">
				<div className="mr-2">
					<BackButton onClick={onBackClick} />
				</div>
				<div className="ml-2">
					<NextButton onClick={onNextClick} />
				</div>
			</div>
		</div>
	);
};

export default SelectOrCreateTemplate;
