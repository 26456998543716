import React from 'react';

export function ResizeIcon() {
	return (
		<svg
			fill="none"
			stroke="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			width="24pt"
			height="24pt"
			viewBox="0 0 24 24"
			version="1.1"
		>
			<g>
				<path
					style={{
						stroke: 'currentColor', fillRule: 'nonzero', fill: '#707578', fillOpacity: 1
					}}
					d="M 23.0625 0 L 16.136719 0 C 15.617188 0 15.199219 0.417969 15.199219 0.9375 C 15.199219 1.457031 15.617188 1.875 16.136719 1.875 L 20.800781 1.875 L 13.285156 9.390625 C 12.917969 9.757812 12.917969 10.351562 13.285156 10.714844 C 13.648438 11.082031 14.242188 11.082031 14.609375 10.714844 L 22.125 3.199219 L 22.125 7.863281 C 22.125 8.382812 22.542969 8.800781 23.0625 8.800781 C 23.582031 8.800781 24 8.382812 24 7.863281 L 24 0.9375 C 24 0.425781 23.585938 0 23.0625 0 Z M 23.0625 0 "
				/>
				<path
					style={{
						stroke: 'currentColor', fillRule: 'nonzero', fill: '#707578', fillOpacity: 1
					}}
					d="M 10.714844 13.285156 C 10.351562 12.917969 9.757812 12.917969 9.390625 13.285156 L 1.875 20.800781 L 1.875 16.136719 C 1.875 15.617188 1.457031 15.199219 0.9375 15.199219 C 0.417969 15.199219 0 15.617188 0 16.136719 L 0 23.0625 C 0 23.570312 0.414062 24 0.9375 24 L 7.863281 24 C 8.382812 24 8.800781 23.582031 8.800781 23.0625 C 8.800781 22.542969 8.382812 22.125 7.863281 22.125 L 3.199219 22.125 L 10.714844 14.609375 C 11.082031 14.242188 11.082031 13.648438 10.714844 13.285156 Z M 10.714844 13.285156 "
				/>
			</g>
		</svg>
	);
}

export function VideoOffIcon() {
	return (
		<svg
			stroke="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<g fill="none" fillRule="evenodd">
				<path
					fill="#707578"
					d="M10.503.85c.32 0 .611.125.827.329l-.696.697c-.025-.018-.053-.032-.084-.038l-.047-.006H1.207c-.102 0-.19.071-.216.165l-.008.059v7.866c0 .101.07.189.165.215l.059.008h1.156l-.983.983h-.173c-.624 0-1.139-.476-1.2-1.083L0 9.922V2.056C0 1.433.476.917 1.084.856l.123-.007h9.296zm5.49 1.124l.007.082v7.866c0 .337-.328.566-.635.47l-.076-.03-3.58-1.79v1.35c0 .623-.476 1.138-1.083 1.2l-.123.006H4.16l.984-.983h5.359c.101 0 .189-.07.215-.164l.008-.06V4.564l.983-.982.353-.353 3.227-1.612c.301-.15.652.04.704.358zm-.977.876L11.71 4.504v2.967l3.307 1.654V2.85z"
					transform="translate(4 6)"
				/>
				<path
					fill="red"
					fillRule="nonzero"
					d="M13.06.144c.192-.192.503-.192.695 0 .171.17.19.436.057.627l-.057.068-12.29 12.29c-.192.193-.503.193-.695 0-.171-.17-.19-.435-.057-.626l.057-.069L13.06.144z"
					transform="translate(4 6)"
				/>
			</g>
		</svg>
	);
}

export function VideoOnIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			stroke="currentColor"
		>
			<path
				fill="#707578"
				fillRule="evenodd"
				d="M15.003 7c.665 0 1.206.542 1.206 1.207v1.349l3.58-1.789c.301-.15.652.04.704.358l.007.082v7.866c0 .365-.385.603-.711.44l-3.58-1.79v1.35c0 .623-.476 1.138-1.083 1.2l-.123.006H5.707c-.665 0-1.207-.542-1.207-1.206V8.207C4.5 7.542 5.042 7 5.707 7zm0 .983H5.707c-.122 0-.224.102-.224.224v7.866c0 .121.102.223.224.223h9.296c.122 0 .223-.102.223-.223V8.207c0-.122-.101-.224-.223-.224zm4.513 1.019l-3.307 1.654v2.968l3.307 1.653V9.002z"
			/>
		</svg>
	);
}
