import React, { useContext, useEffect, useState } from 'react';
import Modal from '../../../components/modals';
import Button from '../../../components/button';
import { TwilioTrackContext } from '../../../contexts/twilio-track';
import { LiveEventDataContext } from '../../../contexts/live-event-data';

const BulkMicActionsModal = ({ showModal, setShowModal }) => {
	const {
		audioParticipants,
		approveOrDeclineMicRequest,
		muteOrUnmuteMicParticipants,
		closeMic
	} = useContext(TwilioTrackContext);
	const [
		hasAttendeesWaitingForMicApproval,
		setHasAttendeesWaitingForMicApproval
	] = useState(false);
	const { attendees } = useContext(LiveEventDataContext);

	useEffect(() => {
		if (!showModal) return;
		if (!attendees || !attendees.length) {
			setHasAttendeesWaitingForMicApproval(false);
			return;
		}

		const twilioParticipantIdsWithMicRequests = [...attendees]
			.map((attendee) => {
				if (attendee.micRequest && attendee.micRequest === true) return attendee.twilioParticipantId;
				return null;
			})
			.filter((attendee) => attendee != null);
		if (
			!twilioParticipantIdsWithMicRequests
			|| !twilioParticipantIdsWithMicRequests.length
		) {
			setHasAttendeesWaitingForMicApproval(false);
			return;
		}

		if (!audioParticipants || !audioParticipants.length) {
			setHasAttendeesWaitingForMicApproval(true);
			return;
		}

		const participantTwilioParticipantIds = [...audioParticipants].map(
			(p) => p.twilioParticipantId
		);

		const foundMicRequest = twilioParticipantIdsWithMicRequests.find(
			(attendeeTwilioParticipantId) => !participantTwilioParticipantIds.includes(attendeeTwilioParticipantId)
		);

		if (foundMicRequest) {
			setHasAttendeesWaitingForMicApproval(true);
			return;
		}

		setHasAttendeesWaitingForMicApproval(false);
	}, [showModal]);

	const getMicRequestedUsers = () => {
		let attendeesWithMicRequest = [...attendees].filter(
			(attendee) => attendee.micRequest === true
		);
		if (!attendeesWithMicRequest || !attendeesWithMicRequest.length) return null;
		if (!audioParticipants || !audioParticipants.length) {
			return attendeesWithMicRequest.map((att) => ({
				userId: att.userId,
				twilioParticipantId: att.twilioParticipantId
			}));
		}
		audioParticipants.forEach((audioParticipant) => {
			const foundAttendee = attendeesWithMicRequest.find(
				(att) => att.twilioParticipantId == audioParticipant.twilioParticipantId
			);
			if (foundAttendee) {
				attendeesWithMicRequest = attendeesWithMicRequest.filter(
					(att) => att.twilioParticipantId !== foundAttendee.twilioParticipantId
				);
			}
		});
		return attendeesWithMicRequest.map((att) => ({
			userId: att.userId,
			twilioParticipantId: att.twilioParticipantId
		}));
	};

	if (!showModal) return false;

	return (
		<Modal
			showModal={showModal}
			onClose={() => setShowModal(false)}
			hideCloseButton
			header="Actions"
		>
			<div className="w-full py-2">
				<div className="w-full flex flex-col items-center space-y-2">
					<Button
						width="full"
						height="10"
						mobileHeight="10"
						disabled={!hasAttendeesWaitingForMicApproval}
						onClick={() => {
							const users = getMicRequestedUsers();
							if (users && users.length) {
								approveOrDeclineMicRequest(users, true, true);
							}
							setShowModal(false);
						}}
					>
						Approve all mic requests
					</Button>
					<Button
						width="full"
						height="10"
						mobileHeight="10"
						disabled={!hasAttendeesWaitingForMicApproval}
						onClick={() => {
							const users = getMicRequestedUsers();
							if (users && users.length) {
								approveOrDeclineMicRequest(users, false, true);
							}
							setShowModal(false);
						}}
					>
						Decline all mic requests
					</Button>
					<Button
						width="full"
						height="10"
						mobileHeight="10"
						disabled={!audioParticipants || !audioParticipants.length}
						onClick={() => {
							const users = [...audioParticipants].map((participant) => ({
								userId: participant.userId,
								twilioParticipantId: participant.twilioParticipantId
							}));

							muteOrUnmuteMicParticipants(users, true, true, true);
							setShowModal(false);
						}}
					>
						Mute all active mics
					</Button>
					<Button
						width="full"
						mobileHeight="10"
						height="10"
						disabled={!audioParticipants || !audioParticipants.length}
						onClick={() => {
							const twilioParticipantIds = [...audioParticipants].map(
								(participant) => participant.twilioParticipantId
							);

							closeMic(twilioParticipantIds, true, true);
							setShowModal(false);
						}}
					>
						Cancel all active mics
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default BulkMicActionsModal;
