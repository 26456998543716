import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getError } from '../api/referenceData.request';
import { defaultToastError } from '../utils/toast-error';

const useErrorNotifier = () => {
	const [errorFunction, setErrorFunction] = useState(null);

	useEffect(() => {
		if (!errorFunction) return;
		getError(errorFunction)
			.then((res) => {
				defaultToastError(res.data);
				setErrorFunction(null);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [errorFunction]);

	const showToastError = (error) => {
		if (typeof error == 'string') {
			toast.error(error, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		} else if (
			error.response
			&& error.response.data
			&& error.response.data.error
		) {
			defaultToastError(error.response.data.error);
		}
	};

	return { setErrorFunction, showToastError };
};

export default useErrorNotifier;
