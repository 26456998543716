import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import React, { useState, useMemo, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import Button from '../../components/button';
import Modal from '../../components/modals';
import { getSlotName } from '../../utils/slot';
import { isTruthy } from '../../utils/commonFunction';
import { calculateFees } from '../../api/payment.request';
import DataTable from '../../components/datatable';

const RefundRequestFinalizeModal = ({
	orderDetails,
	setTotalValue,
	finalizedAmounts,
	invalidAmounts,
	setInvalidAmounts,
	djawnFees,
	setDjawnFees,
	discount,
	setDiscount,
	grossCost,
	stripeFees,
	setStripeFees,
	shippingPrice,
	refundReasonId,
	setShippingPrice,
	initialShippingPrice,
	showModal,
	setShowModal,
	setShowDeclineModal,
	setShowConfirmationModal,
	isBuyerInitiated,
	setDjawnSellerFee,
	originalGrossCost,
	originalStripeFee
}) => {
	// eslint-disable-next-line no-unused-vars
	const [validShippingFee, setValidShippingFee] = useState(true);
	const [errorText, setErrorText] = useState('');
	const [showErrorAlert, setShowErrorAlert] = useState(false);

	const columns = useMemo(
		() => [
			{
				name: 'Product',
				selector: 'productName',
				sortable: true,
				cell: (row) => (
					<p>{row.productId != null ? row.productName : getSlotName(row)}</p>
				),
				width: '8rem'
			},
			{
				name: 'Units',
				selector: 'quantity',
				cell: (row) => (row.quantity == 0 ? 1 : row.quantity),
				sortable: true,
				width: '4rem'
			},
			{
				name: 'Cost',
				selector: 'unitCost',
				cell: (row) => (
					<p>
$
						{row.unitCost}
					</p>
				),
				sortable: true,
				width: '4rem'
			},
			{
				name: 'Amount',
				cell: (row) => {
					const { amount, totalAllowed } = finalizedAmounts.find(
						(finalizedItem) => finalizedItem.orderDetailsId == row.orderDetailsId
					);
					const isInvalid = invalidAmounts.find(
						(orderDetailId) => orderDetailId == row.orderDetailsId
					);
					return (
						<NumberFormat
							className={` pl-2 pr-2 block w-18 h-8 sm:text-sm ${
								isInvalid
									? 'border-2 border-red-700 ring-indigo-900 focus:border-red-900'
									: 'border border-gray-400 focus:ring-indigo-400 focus:border-indigo-400'
							}`}
							thousandSeparator
							prefix="$"
							tabIndex="-4"
							isAllowed={({ floatValue }) => {
								const foundInvalidAmount = invalidAmounts.find(
									(orderDetailId) => orderDetailId == row.orderDetailsId
								);
								let tempInvalidAmounts = [...invalidAmounts];
								if (floatValue == null || floatValue == undefined) {
									if (!foundInvalidAmount) {
										tempInvalidAmounts.push(row.orderDetailsId);
										setInvalidAmounts(tempInvalidAmounts);
									}
									return true;
								}
								if (foundInvalidAmount) {
									tempInvalidAmounts = tempInvalidAmounts.filter(
										(orderDetailId) => orderDetailId != row.orderDetailsId
									);
								}
								setInvalidAmounts(tempInvalidAmounts);

								if (
									floatValue
									&& floatValue.toString().length
									&& floatValue.toString()[0] == '-'
								) {
									floatValue = Number(floatValue) * -1;
								}
								return floatValue <= totalAllowed;
							}}
							value={amount}
							id={`amount-${row.orderDetailsId}`}
							min={0.01}
							onValueChange={(values) => {
								let { value } = values;
								if (
									value
									&& value.toString().length
									&& value.toString()[0] == '-'
								) {
									value = Number(value) * -1;
								}
								setTotalValue(row.orderDetailsId, value);
							}}
						/>
					);
				}
			}
		],
		[finalizedAmounts, invalidAmounts]
	);

	useEffect(() => {
		if (grossCost > 0) {
			calculateFees(
				grossCost,
				shippingPrice,
				originalGrossCost,
				initialShippingPrice,
				originalStripeFee,
				refundReasonId
			).then((res) => {
				if (djawnFees > 0) {
					setDjawnFees(res.data.buyerDjawnFee);
				}
				if (discount > 0) {
					setDiscount(res.data.buyerDjawnFee);
				}
				setDjawnSellerFee(res.data.sellerDjawnFee);
				if (isBuyerInitiated) {
					setStripeFees(
						grossCost == originalGrossCost
							? originalStripeFee
							: res.data.stripeFee
					);
				} else {
					setStripeFees(0);
				}
			});
		}

		const finalizedAmountsSmallerThenZero = finalizedAmounts.find(
			(p) => p.amount <= 0
		);
		if (finalizedAmountsSmallerThenZero) {
			setShowErrorAlert(true);
			setErrorText('All amounts should be bigger than 0');
			return;
		}
		if (
			(shippingPrice == '' && shippingPrice !== 0)
			|| shippingPrice == null
			|| shippingPrice == undefined
		) {
			setShowErrorAlert(true);
			setValidShippingFee(false);
			setErrorText('Shipping fee should not be empty');
			return;
		}
		const total = Number(
			Number(grossCost)
				+ Number(djawnFees)
				+ Number(shippingPrice)
				- Number(stripeFees)
				- Number(discount)
		);

		if (total <= 0) {
			setShowErrorAlert(true);
			setErrorText('Refund total should be a positive value');
			return;
		}
		if (invalidAmounts && invalidAmounts.length) {
			setErrorText('Please fill all the amount inputs properly');
			setShowErrorAlert(true);
			return;
		}
		setErrorText('');
		setShowErrorAlert(false);
		setValidShippingFee(true);
	}, [invalidAmounts, shippingPrice, grossCost, djawnFees, stripeFees]);

	return (
		<Modal
			showModal={showModal}
			onClose={() => setShowModal(false)}
			hideCloseButton
		>
			{isTruthy(showErrorAlert && errorText && errorText.length) && (
				<div role="alert" className="w-full mb-3 px-4">
					<div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
						Cannot finalize request
					</div>
					<div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
						<p>{errorText}</p>
					</div>
				</div>
			)}
			<div className="w-full order-details-header flex bg-white md:justify-between md:px-4 px-2 py-4 flex-col shadow-lg mb-3">
				<DataTable
					columns={columns}
					data={orderDetails}
					noContextMenu
					className=""
					contextActions={null}
					striped
					paginationComponent={null}
					customStyles={{
						table: {
							style: {
								border: '1px #a7a6a6 solid'
							}
						},
						headCells: {
							style: {
								backgroundColor: '#e82e2c',
								color: 'white',
								fontSize: '0.75rem',
								fontWeight: 'bold'
							}
						}
					}}
				/>
				<div className="flex justify-between py-3 w-full">
					<table className="w-full">
						<tbody>
							<tr>
								<td align="start">
									<p className="font-semibold pr-5 pb-2">Sub total</p>
								</td>
								<td align="start">
									<p className="pb-2">
$
										{Number(grossCost).toFixed(2)}
									</p>
								</td>
							</tr>
							<tr>
								<td align="start">
									<p className="font-semibold pr-5 pb-2">Add: Shipping fee</p>
								</td>
								<td align="start">
									<NumberFormat
										className={`pl-4 w-14 h-8  block sm:text-sm ${
											!validShippingFee
												? 'border-2 border-red-700 ring-indigo-900 focus:border-red-900'
												: 'border border-gray-400 focus:ring-indigo-400 focus:border-indigo-400'
										}`}
										thousandSeparator
										disabled={
											!initialShippingPrice || initialShippingPrice == 0
										}
										prefix="$"
										tabIndex="-3"
										isAllowed={({ floatValue }) => {
											if (
												floatValue == null
												|| (floatValue == '' && floatValue != 0)
												|| floatValue == undefined
											) {
												return true;
											}
											return floatValue <= initialShippingPrice;
										}}
										value={shippingPrice}
										onValueChange={(values) => {
											const { value } = values;
											setShippingPrice(value);
											setValidShippingFee(
												!(value == null || value == undefined)
											);
										}}
									/>
								</td>
							</tr>
							<tr>
								<td align="start">
									<p className="font-semibold pr-5 pb-2">
										Add: DJAWN buyer fee
									</p>
								</td>
								<td align="start">
									<p className="pb-2">
$
										{Number(djawnFees).toFixed(2)}
									</p>
								</td>
							</tr>
							<tr>
								<td align="start">
									<p className="font-semibold pr-5 pb-2">
										Less: Djawn buyer discount
									</p>
								</td>
								<td align="start">
									<p className="pb-2">
$
										{Number(discount).toFixed(2)}
									</p>
								</td>
							</tr>
							<tr>
								<td align="start">
									<p className="font-semibold pr-5 pb-2">
										Less: Refund process fee
									</p>
								</td>
								<td align="start">
									<p className="pb-2">
$
										{Number(stripeFees).toFixed(2)}
									</p>
								</td>
							</tr>
							<tr className="border-t-2">
								<td align="start">
									<p className="font-semibold pr-5 pb-2">Refund total</p>
								</td>
								<td align="start">
									<p className="pb-2">
										$
										{Number(
											Number(grossCost)
												+ Number(djawnFees)
												+ Number(shippingPrice)
												- Number(stripeFees)
												- Number(discount)
										).toFixed(2)}
										{' '}
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="flex justify-between p-3">
					<Button
						mobileHeight="10"
						type="djawn"
						width="24"
						textSize="md"
						tabIndex="-1"
						onClick={() => {
							setShowModal(false);
							setShowDeclineModal(true);
						}}
						additionalClassName="flex items-center justify-center"
					>
						<ChevronLeftIcon className="w-6 h-6" />
						<span>Decline</span>
					</Button>
					<Button
						mobileHeight="10"
						type="djawn"
						width="24"
						additionalClassName="flex items-center justify-center"
						textSize="md"
						onClick={() => {
							setShowErrorAlert(false);
							setErrorText('');
							setShowModal(false);
							setShowConfirmationModal(true);
						}}
						tabIndex="-2"
						disabled={showErrorAlert && errorText && errorText.length}
					>
						<span>Approve</span>
						<ChevronRightIcon className="w-6 h-6" />
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default RefundRequestFinalizeModal;
