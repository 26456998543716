/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, {
	useState, useEffect, useRef, useContext
} from 'react';
import { createLocalVideoTrack } from 'twilio-video';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import ReactTooltip from 'react-tooltip';
import {
	isMobile, isTablet
} from 'react-device-detect';
import clsx from 'clsx';
import { isFunction } from '../../utils/isFunction';
import { MicOffIcon, MicIcon } from './mic-icon';
import { VideoOnIcon, VideoOffIcon, ResizeIcon } from './video-icon';
import { SoundOffIcon, SoundOnIcon } from './sound-icon';
import { useInterval } from '../common/use-interval';
import { VideoCameraIcon } from './options-icon';
import {
	getElapsedTimestampWithCurrentDate,
} from '../../utils/formatter';
import { RaiseHandIcon } from './raise-hand-icon';
import {
	addBookmark, updateRoomStatusToStarted, updateEventRoomDate
} from '../../api/event.request';
import BookmarkIcon from '../bookmark-icon';

import { defaultToastSucesss } from '../../utils/toast-success';
import { defaultToastError } from '../../utils/toast-error';
import { defaultToastWarning } from '../../utils/toast-warning';
import {
	isLoggedIn
} from '../../utils/auth';
import { isTruthy } from '../../utils/commonFunction';
import EventNameInformationButton from '../../containers/event/room/event-information-button';
import ArrowButton from '../../containers/event/room/arrow-button';
import { TwilioTrackContext } from '../../contexts/twilio-track';
import ParticipantMicRequest from '../../containers/event/room/participant-mic-request';
import ParticipantMicRequestDecision from '../../containers/event/room/participant-mic-request-decision';
import ParticipantMicBuyerOptions from '../../containers/event/room/participant-mic-buyer-options';
import ParticipantMicBuyerNotifier from '../../containers/event/room/participant-mic-buyer-notifier';

const Participant = ({
	participant,
	localParticipant,
	newTrack,
	isLocal = false,
	isPaused,
	resize,
	setResize,
	isStopped,
	nickname,
	eventName,
	optionsOnClick,
	changeCameraOnClick,
	eventNamePopupRef,
	mobileSize,
	authStatus,
	deviceId,
	setHasRaisedHand,
	arrowButtonContainerRef,
	eventStartDate,
	setEventStartDate,
	exitEventRoom,
	setVideoHeight,
	setSound,
	sound,
	eventId,
	reversedCamera,
	saveTrack,
	showEndTurn = false,
	onEndTurn,
	updatePopupsStatus,
	setOpenQueueContainer,
	openQueueContainer,
	onOpenQueueContainer,
	setLeftArrowButtonPosition,
	setTopArrowButtonPosition,
	showThankYou,
	onOrientationChange,
	arrowButtonRef,
	showEventNameInChatContainer,
	setShowEventNameInChatContainer,
	isSeller
}) => {
	const [videoTracks, setVideoTracks] = useState([]);
	const [audioTracks, setAudioTracks] = useState([]);
	const [muted, setMuted] = useState(false);
	const [cam, setCam] = useState(true);
	const [iconHeight, setIconHeight] = useState(12);
	const [timestamp, setTimestamp] = useState('00:00:00');
	const [isDateUpdated, setIsDateUpdated] = useState(false);
	const [videoFixed, setVideoFixed] = useState(false);
	const [orientation, landscapeMode] = useScreenOrientation(onOrientationChange);
	const [remoteAudios, setRemoteAudios] = useState([]);
	const [videoDisabled, setVideoDisabled] = useState(false);
	const {
		requestedMicAccess,
		micAccessApprovedByBuyer,
		mutedByBuyer: isBuyerMuted
	} = useContext(TwilioTrackContext);
	const [showMicRequestConfirmationModal, setShowMicRequestConfirmationModal] = useState(false);
	const [showParticipantMicOptions, setShowParticipantMicOptions] = useState(false);
	const pulsingArrowButtonRef = useRef();
	const videoRef = useRef();
	const videoDivRef = useRef();
	const audioRef = useRef();
	const attachedTracks = [];
	// useEffect(() => {
	//     if (!newTrack) return;
	//     console.log(newTrack);
	//     const tempAudioTracks = [...audioTracks];
	//     tempAudioTracks.push(newTrack);

	//     setAudioTracks(tempAudioTracks);
	// }, [newTrack]);

	useEffect(() => {
		if (!newTrack) return;
		const tempAudioTracks = [...audioTracks];
		if (newTrack.kind == 'audio') {
			tempAudioTracks.push(newTrack);
			setAudioTracks(tempAudioTracks);
		} else {
			setVideoTracks([newTrack]);
		}
	}, [newTrack]);

	useEffect(() => {
		if (!localParticipant) return;

		localParticipant.audioTracks.forEach((track) => {
			track.track.disable();
		});
	}, [localParticipant]);

	useEffect(() => {
		if (isLocal) {
			updateRoomStatusToStarted(eventId).then(() => {
				updateEventRoomDate(eventId).then((newDateRes) => {
					setIsDateUpdated(true);
					if (setEventStartDate) {
						setEventStartDate(newDateRes.data);
					}
				}).catch((err) => {
					const mute = err;
				});
			}).catch((err) => {
				const mute = err;
			});
		}

		if (videoRef.current.clientHeight) {
			setVideoHeight(videoRef.current.clientHeight);
		}
	}, []);

	useEffect(() => {
		if (!deviceId || !isLocal || !videoTracks) return;

		createLocalVideoTrack({ deviceId: { exact: deviceId } }).then((
			localVideoTrack
		) => {
			const tracks = Array.from(videoTracks.values());

			// tracks.forEach(track => {
			//     if (track && track.disable) {
			//         track.disable();
			//     }
			//     if (track.stop) {
			//         track.stop();
			//     }
			// })

			tracks.forEach((track) => track.stop());

			participant.videoTracks.forEach((trackPublication) => trackPublication.unpublish());
			tracks.forEach((track) => track.detach());

			// participant.unpublishTracks(tracks);

			// const videoTrack = videoTracks[0];
			// if (videoTrack) {
			//     videoTrack.detach();
			// }

			participant.publishTrack(localVideoTrack);
			localVideoTrack.attach(videoRef.current);
		}).catch((err) => {
			console.log('ERROR', err);
		});
	}, [deviceId]);

	useEffect(() => {
		if (isPaused) {
			setMuted(true);
			setCam(false);
		} else {
			setMuted(false);
			setCam(true);
		}
	}, [isPaused]);

	useInterval(() => {
		setTimestamp(getElapsedTimestampWithCurrentDate(eventStartDate));
	}, 1000);

	useEffect(() => {
		setIconHeight(mobileSize ? 8 : 12);
	}, [mobileSize]);

	useEffect(() => {
		const isSafari = /^((?!chrome|android).)*safari/i.test(
			navigator.userAgent
		);
		if (isSafari) {
			setSound(false);
		}
	}, []);

	useEffect(() => {
		if (isStopped == true) {
			const track = [...videoTracks.values()][0];
			track.stop();
			// participant.unpublish(track);
			// setMuted(true);
			// setCam(false);
		}
	}, [isStopped]);

	// const trackpubsToTracks = (trackMap) => {
	//     console.log('mapping', trackMap);
	//     if (!trackMap) return;
	//     return Array.from(trackMap.values())
	//         .map((publication) => publication.track)
	//         .filter((track) => track !== null);
	// };

	const trackpubsToTracks = (trackMap) => {
		console.log('mapping', trackMap);
		if (!trackMap) return;
		const array = Array.from(trackMap.values())
			.map((publication) => publication.track)
			.filter((track) => track !== null);
		console.log('mapping', array);
		return array;
	};

	useEffect(() => {
		if (!isLocal) return;

		participant.audioTracks.forEach((track) => {
			// console.log(track);
			if (muted) track.track.disable();
			else track.track.enable();
			// track.disable();
		});
	}, [muted]);

	useEffect(() => {
		if (!isLocal) return;
		participant.videoTracks.forEach((track) => {
			// console.log(track);
			if (!cam) track.track.disable();
			else track.track.enable();
			// track.disable();
		});
	}, [cam]);

	useEffect(() => {
		const participantVideoTracks = trackpubsToTracks(participant.videoTracks);

		setVideoTracks(participantVideoTracks);
		setAudioTracks(trackpubsToTracks(participant.audioTracks));

		// TODO: add this function outside useEffect
		const trackSubscribed = (track) => {
			if (track.kind === 'video') {
				setVideoTracks((videoTracks) => [...videoTracks, track]);
			} else if (track.kind === 'audio') {
				setAudioTracks((audioTracks) => [...audioTracks, track]);
			}
		};

		// TODO: add this function outside useEffect
		const trackUnsubscribed = (track) => {
			console.log('trackUnsubscribed', track);

			if (track.kind === 'video') {
				setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
			} else if (track.kind === 'audio') {
				setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
			}
		};

		if (isFunction(participant.on)) {
			participant.on('trackSubscribed', trackSubscribed);
			// participant.on('subscribed', trackSubscribed);
			participant.on('trackUnsubscribed', trackUnsubscribed);

			if (!isLocal) {
				participant.on('trackDisabled', (track) => {
					if (track.kind == 'video') {
						setVideoDisabled(true);
						// videoRef.current = null;
						// videoRef.current = null;
					}
				});

				participant.on('trackEnabled', (track) => {
					if (track.kind == 'video') {
						setVideoDisabled(false);
						if (isFunction(track.attach)) {
							track.attach(videoRef.current);
						}
					}
				});
			}
		}

		return () => {
			setVideoTracks([]);
			setAudioTracks([]);
			if (isFunction(participant.removeAllListeners)) {
				participant.removeAllListeners();
			}
		};
	}, [participant]);

	useEffect(() => {
		if (!videoTracks || videoTracks.length == 0) {
			return;
		}
		const videoTrack = videoTracks[0];

		if (videoTrack) {
			if (saveTrack) {
				if (typeof videoTrack != 'undefined' && videoTrack.id) {
					saveTrack(videoTrack.id, 'video');
				}
			}
			videoTrack.attach(videoRef.current);
			setVideoDisabled(!videoTrack.isEnabled);
			return () => {
				videoTrack.detach();
			};
		}
	}, [videoTracks]);

	useEffect(() => {
		const audioTrack = audioTracks[0];

		setRemoteAudios([...audioTracks].filter((f, i) => i != 0));
		if (audioTrack) {
			if (typeof audioTrack != 'undefined' && audioTrack.id && isLocal && isLocal === true) {
				saveTrack(audioTrack.id, 'audio');
			}

			// DJAWN-722: solve for echo
			audioTrack.mediaStreamTrack.applyConstraints({
				echoCancellation: true,
				noiseSuppression: true,
			});
			audioTrack.attach(audioRef.current);
			return () => {
				audioTrack.detach();
			};
		}
	}, [audioTracks]);

	useEffect(() => {
		if (!remoteAudios.length) return;

		const audioEl = document.getElementById('remote-audios');
		remoteAudios.map((audio) => {
			if (attachedTracks.find((sid) => audio.sid == sid) == null) {
				const el = document.createElement('audio');
				el.setAttribute('autoplay', '');
				el.muted = !sound;
				audioEl.appendChild(el);
				audio.attach(el);
				attachedTracks.push(audio.sid);
			}
		});

		if (isLocal) {
			const isSafari = /^((?!chrome|android).)*safari/i.test(
				navigator.userAgent
			);
			if (isSafari) {
				setSound(true);
			}
		}
	}, [remoteAudios]);

	useEffect(() => {
		const audioEl = document.getElementById('remote-audios');
		if (!audioEl || !audioEl.children || !audioEl.children.length) return;

		for (let i = 0; i < audioEl.children.length; i++) {
			if (audioEl.children[i]) {
				audioEl.children[i].muted = !sound;
			}
		}
	}, [sound]);

	const setBookmark = () => {
		const video = document.getElementsByTagName('video')[0];
		const scaleFactor = 1;
		const w = video.videoWidth * scaleFactor;
		const h = video.videoHeight * scaleFactor;
		const canvas = document.createElement('canvas');
		canvas.width = w;
		canvas.height = h;
		const ctx = canvas.getContext('2d');
		ctx.drawImage(video, 0, 0, w, h);

		canvas.toBlob((blob) => {
			const formData = new FormData();
			formData.append('image', blob, 'test.png');
			addBookmark(formData, eventId)
				.then((res) => {
					defaultToastSucesss(
						'Bookmark added successfully!'
					);
				})
				.catch((err) => {
					defaultToastError(err.message);
				});
		});
	};

	// useEffect(() => {
	//     if (!window || mobileSize) return;
	//     window.addEventListener(
	//         'scroll',
	//         (e) => {
	//             if(e.target.id && e.target.id.length > 0) return;
	//             if (videoRef.current.clientHeight < e.target.scrollTop) {
	//                 setVideoFixed(true);
	//             } else {
	//                 setVideoFixed(false);
	//             }
	//         },
	//         true
	//     );
	// }, []);

	// useEffect(() => {
	// 	defaultToastWarning('aa');
	// 	if (isMobile) {
	// 		defaultToastWarning('true mobile');
	// 	}

	// 	if (isIOS) {
	// 		defaultToastWarning('ios');
	// 	}
	// 	console.log(isMobile);
	// 	console.log(isIOS);
	// }, [isMobile, isIOS]);
	const participantScreen = useFullScreenHandle();

	useEffect(() => {
		if (!arrowButtonRef || !arrowButtonRef.current) return;
		const arrowButtonRect = arrowButtonRef.current.getBoundingClientRect();
		setLeftArrowButtonPosition(arrowButtonRect.left + window.scrollY);
		setTopArrowButtonPosition(arrowButtonRect.top + window.scrollY);
	}, [arrowButtonRef]);

	const queueContainer = () => (
		<>
			{((mobileSize || (isTablet && !landscapeMode)) && !openQueueContainer) && (
				<div className="relative pl-2">
					<button
						type="button"
						className="inline-flex items-center justify-center w-8 h-8 text-indigo-100 transition-colors duration-150 bg-djawn rounded-full focus:shadow-outline hover:bg-djawn-300
bg-opacity-50 relative"
						style={{
							outline: 'none',
							zIndex: '60',
							marginTop: '-6%'
						}}
						ref={arrowButtonRef}
						id="live-event-arrow-button"
						onClick={() => {
							// updatePopupsStatus('show_cards', true);
							const arrowButtonRect = arrowButtonRef.current.getBoundingClientRect();
							const pulsingArrowButtonRect = pulsingArrowButtonRef.current.getBoundingClientRect();
							onOpenQueueContainer(arrowButtonRect.top + window.scrollY, pulsingArrowButtonRect.top + window.scrollY);
						}}
						onKeyDown={() => {
							// updatePopupsStatus('show_cards', true);
							const arrowButtonRect = arrowButtonRef.current.getBoundingClientRect();
							const pulsingArrowButtonRect = pulsingArrowButtonRef.current.getBoundingClientRect();
							onOpenQueueContainer(arrowButtonRect.top + window.scrollY, pulsingArrowButtonRect.top + window.scrollY);
						}}
					>
						{openQueueContainer ? (
							<svg
								className="w-4 h-4 fill-current"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M9 5l7 7-7 7"
								/>
							</svg>
						) : (
							<svg
								className="w-4 h-4 fill-current"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M15 19l-7-7 7-7"
								/>
							</svg>
						)}
						<button
							type="button"
							className="absolute outline-none w-5 h-5 ml-auto mr-auto left-0 right-0 text-center animate-ping items-center justify-center duration-1000 bg-djawn text-gray-100 rounded-full focus:shadow-outline hover:bg-djawn-300"
							ref={pulsingArrowButtonRef}
						/>
					</button>

				</div>
			)}
		</>
	);

	const onMicButtonClick = () => {
		if (!authStatus) {
			defaultToastWarning('Anonymous user can not activate the mic, please log in to activate your mic');
			return;
		}
		if (micAccessApprovedByBuyer == true) {
			setShowParticipantMicOptions(true);
		} else {
			setShowMicRequestConfirmationModal(true);
		}
	};

	return (
		<FullScreen
			handle={participantScreen}
			onChange={(e) => {
				if (mobileSize && orientation == 0) {
					if (e == false) return;
					defaultToastWarning('Change orientation to landscape for fullscreen mode!');
					return;
				}
				setResize(e);
			}}
			className={`${mobileSize || (isTablet && !landscapeMode) ? 'bg-white dark:bg-darkGray-50 border-radius-25px' : ''}`}
		>
			<ParticipantMicRequest
				showMicRequestConfirmationModal={showMicRequestConfirmationModal}
				onClose={() => setShowMicRequestConfirmationModal(false)}
			/>
			<ParticipantMicBuyerOptions
				showModal={showParticipantMicOptions}
				setShowModal={setShowParticipantMicOptions}
			/>
			<ParticipantMicBuyerNotifier />
			<ParticipantMicRequestDecision />
			<div
				className={` 
		 ${!mobileSize && videoFixed && 'video-fixed'}
		 ${(mobileSize || (isTablet && !landscapeMode)) && resize && 'h-screen'}
		 ${!mobileSize && !videoFixed && ('participant w-full h-full')}
    ${isTruthy(mobileSize || (isTablet && !landscapeMode)) && 'flex flex-col justify-center'}
		`}
				id="participant"
			>
				<ReactTooltip />
				{/* <h3>

				{nickname} - {eventName}
			</h3> */}
				{
					mobileSize && participantScreen.active && (orientation == 0 || orientation == 180)
						? <p className="mb-5 text-white">Change orientation to landscape for a better experience</p>
						: null
				}
				<div
					// className={`participant-video ${
					//     resize == true ? 'large-video' : 'small-video'
					// } relative`}
					ref={videoDivRef}
					className={`relative z-10 ${mobileSize && resize ? 'w-11/12 h-5/6 float-right' : 'h-full'} ${isTruthy(videoDisabled || showThankYou) && (isMobile ? (!isTablet ? (`${landscapeMode ? 'landscape-mobile-video' : 'small-mobile-video-placeholder'}`) : '') : 'flex justify-center')}`}
				>
					{resize === false ? (
						<>
							{(videoDisabled || showThankYou) && (
								<div
									className={`bg-black  ${mobileSize ? (!isTablet ? `${landscapeMode ? 'landscape-mobile-video flex items-center justify-center participant-video' : 'small-mobile-video-placeholder flex items-center justify-center'}` : 'w-full h-80 flex') : 'participant-video-placeholder chat-desktop-height w-full h-full flex items-center justify-center'}`}
									// className="participant-video xs:small-mobile-video md:chat-desktop-height w-auto h-auto md:w-full md:h-full"
									style={{ objectFit: 'cover' }}
									hidden={!videoDisabled && !showThankYou}
								>
									{!showThankYou ? (<h2 className={`text-white font-lg ${!!(mobileSize) && 'video-paused-font'}`}>Seller has paused the video</h2>) : (
										<div className="mx-10 flex items-center">
											<svg className="w-14 h-14" fill="red" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
											<p className="text-lg text-red-500">
										The live video stream
										has ended. Thank you for
										attending!
											</p>
										</div>
									)}
								</div>
							)}
							<video
								ref={videoRef}
								autoPlay
								hidden={videoDisabled || showThankYou}
								controls={isMobile}
								// src={"https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4"}
								// className="h-full w-full"
								// className="participant-video w-full h-full("
								className={`participant-video ${(!videoDisabled && !showThankYou) ? 'block' : 'hidden'} ${isMobile ? (!isTablet ? `${landscapeMode ? 'landscape-mobile-video' : 'small-mobile-video no-bottom-borders'} ` : (isTablet ? ((landscapeMode && !isTablet ? '' : (isTablet && !landscapeMode ? 'tablet-portait-live-event-video-height tablet-portait-live-event-video-width' : 'chat-desktop-height'))) : 'w-full h-80')) : `participant-video ${landscapeMode && !isTablet ? '' : (isTablet && !landscapeMode ? 'tablet-portait-live-event-video-height tablet-portait-live-event-video-width' : 'chat-desktop-height')} w-full h-full`}`}
								style={{ objectFit: 'cover' }}
								// className="participant-video xs:small-mobile-video md:chat-desktop-height w-auto h-auto md:w-full md:h-full"
							/>
						</>
					) : (
						<>
							{(videoDisabled || showThankYou) && (
								<div
									className="bg-black w-full h-full flex items-center justify-center"
									// className="participant-video xs:small-mobile-video md:chat-desktop-height w-auto h-auto md:w-full md:h-full"
									style={{ objectFit: 'cover' }}
									hidden={!videoDisabled || !showThankYou}
								>
									{!showThankYou ? (<h2 className={`text-white font-lg ${!!(mobileSize) && 'video-paused-font'}`}>Seller has paused the video</h2>) : (
										<div className="pt-20 mx-10 flex items-center">
											<svg className="w-14 h-14" fill="red" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
											<p className="text-lg text-red-500">
										The live video stream
										has ended. Thank you for
										attending!
											</p>
										</div>
									)}
								</div>
							)}
							<video
								ref={videoRef}
								autoPlay
								className={`mobile-participant-video w-full h-full ${(!videoDisabled && !showThankYou) ? 'block' : 'hidden'}`}
								style={{ objectFit: 'cover' }}
							/>
						</>
					)}

					{/* {queueContainer()} */}

					{/* {(mobileSize && !openQueueContainer) && (
						<>

							<div
								className="animate-ping fixed inline-flex items-center justify-center duration-1000 bg-djawn text-gray-100 rounded-full focus:shadow-outline hover:bg-djawn-300"
								style={{
									left: '0.45rem',
									outline: 'none',
									width: '1.6rem',
									height: '1.6rem',
									marginTop: '-4%'
								}}
								ref={pulsingArrowButtonRef}
							/>
							<button
								type="button"
								className="fixed left-0 md:left-4 inline-flex items-center justify-center w-10 h-10 mr-2 text-indigo-100 transition-colors duration-150 bg-djawn rounded-full focus:shadow-outline hover:bg-djawn-300
bg-opacity-50"
								style={{
									outline: 'none',
									zIndex: '60',
									marginTop: '-6%'
								}}
								ref={arrowButtonRef}
								onClick={() => {
									// updatePopupsStatus('show_cards', true);
									const arrowButtonRect = arrowButtonRef.current.getBoundingClientRect();
									const pulsingArrowButtonRect = pulsingArrowButtonRef.current.getBoundingClientRect();
									onOpenQueueContainer(arrowButtonRect.top + window.scrollY, pulsingArrowButtonRect.top + window.scrollY);
								}}
							>
								{openQueueContainer ? (
									<svg
										className="w-4 h-4 fill-current"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M9 5l7 7-7 7"
										/>
									</svg>
								) : (
									<svg
										className="w-4 h-4 fill-current"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M15 19l-7-7 7-7"
										/>
									</svg>
								)}
							</button>
						</>
					)} */}

					{
						isMobile && !isTablet
							? (
								<div
									className="absolute top-0 right-0 h-16 bg-black"
									style={{ maxWidth: videoFixed ? '450px' : '100%', zIndex: '20' }}
								>
									<div
										className={`flex justify-between ${mobileSize && orientation != 0 && !resize && 'w-10/12'} ${mobileSize && orientation == 0 && !resize && 'w-11/12'}`}
										style={{ zIndex: '30' }}
									>
										<div className="flex flex-col absolute top-0 right-0 py-3" style={{ zIndex: '30' }}>
											<button
												type="button"
												onClick={exitEventRoom}
												className={`${!mobileSize ? 'leave-button' : 'mobile-leave-button'}`}
												tabIndex={-1}
												style={{ zIndex: '30' }}
											>
												{isLocal ? 'End Event' : 'Leave'}
											</button>
											{showEndTurn ? (
												<button
													type="button"
													onClick={onEndTurn}
													tabIndex={-1}
													className={`${!mobileSize ? 'leave-button' : 'mobile-leave-button'} my-4`}
													style={{ zIndex: '30' }}
												>
									End Turn
												</button>
											) : null}
										</div>
									</div>
								</div>
							)

							:						(
								<div
									className="absolute inset-x-0 top-0 h-16"
									style={{ maxWidth: videoFixed ? '450px' : '100%' }}
									style={{ zIndex: '20' }}
								>
									<div
										className={`flex justify-between ${mobileSize && orientation != 0 && !resize && 'w-10/12'} ${mobileSize && orientation == 0 && !resize && 'w-11/12'}`}
										style={{ zIndex: '30' }}
									>
										<div className="absolute" style={{ zIndex: '30' }}>
											{!showThankYou && !isTablet ? (
												!isLocal
													? (
														<div className="absolute flex justify-between items-center top-0 left-0 px-3 py-3" data-tip="Bookmark">
															{
																isLoggedIn()
																&& (
																	<button
																		type="button"
																		className="border-2 bg-opacity-75 rounded-full md:h-10 h-8 md:w-10 w-8 flex items-center justify-center
												disabled:opacity-50 focus:outline-none  border-djawn hover:bg-djawn"
																		style={{ zIndex: '30' }}
																		onClick={setBookmark}
																	>
																		<BookmarkIcon fill="#e82e2c" stroke="white" className="h-8 w-8 hover:fill-black hover:text-black" />
																	</button>
																)

															}

															<p
																className="text-xl not-italic font-sans tracking-wide text-white px-2"
																style={{
																	marginRight: 'auto', alignItems: 'center', fontSize: mobileSize ? '15px' : '25px', zIndex: '30'
																}}
															>
																{timestamp}
															</p>
														</div>
													) : (
														<p
															className="text-xl not-italic font-sans tracking-wide text-white top-0 left-0 px-3 py-3"
															style={{
																marginRight: 'auto', alignItems: 'center', fontSize: mobileSize ? '15px' : '25px', zIndex: '30'
															}}
														>
															{timestamp}
														</p>
													)
											) : null}

										</div>
										{!isMobile && !isTablet ? (
											<div className="absolute" style={{ zIndex: '30', left: '50%', transform: 'translateX(-50%)' }}>
												<p className="text-xl text-white">
													{eventName}
												</p>
											</div>
										) : null}
										<div className="flex flex-col absolute top-0 right-0 py-3 px-3" style={{ zIndex: '30' }}>
											<button
												type="button"
												onClick={exitEventRoom}
												className={`${!mobileSize ? 'leave-button' : 'mobile-leave-button'}`}
												tabIndex={-1}
												style={{ zIndex: '30' }}
											>
												{isLocal ? 'End Event' : 'Leave'}
											</button>
											{showEndTurn ? (
												<button
													type="button"
													onClick={onEndTurn}
													className={`${!mobileSize ? 'leave-button' : 'mobile-leave-button'} my-4`}
													style={{ zIndex: '30' }}
													tabIndex={-1}
												>
									End Turn
												</button>
											) : null}
										</div>
									</div>
								</div>
							)
					}

					{
						!isMobile || (isTablet && landscapeMode) ? (
							<div
								className="absolute inset-x-0 bottom-0 h-16"
								style={{ zIndex: '20' }}
							>
								<div>

									{isLocal ? (
										<div className="flex items-center justify-center mt-2">
											<button
												type="button"
												className={`video-control-btn 
						bg-white
						${muted ? 'bg-pressedIconsRedColor text-white pulsing-button' : ' dark:text-white dark:bg-darkGray-50 '
										}
						hover:text-pressedIconsRedColor
						rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
												onClick={() => {
													setMuted(!muted);
												}}
											>
												{!muted ? (
													<MicIcon
														width="12"
														height="17"
														stroke="currentColor"
														fill="currentColor"
													/>
												) : (
													<MicOffIcon
														width="25"
														height="24"
														stroke="currentColor"
														fill="currentColor"
													/>
												)}
											</button>
											<button
												type="button"
												className={`video-control-btn 
						bg-white
						${!cam ? 'bg-pressedIconsRedColor text-white' : 'dark:text-white dark:bg-darkGray-50 '
										}
						hover:text-pressedIconsRedColor
						rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center`}
												onClick={() => {
													setCam(!cam);
												}}
											>
												{!cam ? <VideoOffIcon /> : <VideoOnIcon />}
											</button>
											{
												!participantScreen.active
													? (
														<button
															type="button"
															className={`video-control-btn bg-white dark:text-white dark:bg-darkGray-50 
						hover:text-pressedIconsRedColor
						rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center px-3 ml-2`}
															// onClick={!resize ? participantScreen.enter : participantScreen.exit}
															onClick={() => {
																if (mobileSize && orientation == 0) {
																	defaultToastWarning('Change orientation to landscape for fullscreen mode!');
																	return;
																}
																setResize(true);
																if (!mobileSize) participantScreen.enter();
															}}
														>
															<ResizeIcon />
														</button>
													)
													: (
														<button
															type="button"
															className={`video-control-btn bg-pressedIconsRedColor text-white
					dark:text-white dark:bg-darkGray-50
					hover:text-pressedIconsRedColor
					rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center px-3 ml-2`}
															// onClick={!resize ? participantScreen.enter : participantScreen.exit}
															onClick={() => {
																if (mobileSize && orientation == 0) {
																	defaultToastWarning('Change orientation to landscape for fullscreen mode!');
																	return;
																}
																setResize(false);
																if (!mobileSize) participantScreen.exit();
															}}
														>
															<ResizeIcon />
														</button>
													)
											}
											<button
												type="button"
												className={`video-control-btn 
										bg-white
										${reversedCamera == 1 ? 'bg-pressedIconsRedColor text-white' : 'dark:text-white dark:bg-darkGray-50 '
										}
										hover:text-pressedIconsRedColor
										rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
												onClick={() => {
													changeCameraOnClick(videoRef, videoTracks);
												}}
											>
												<VideoCameraIcon />
											</button>
										</div>
									) : (
										<div className="flex items-center justify-center mt-2">
											{!isSeller && (
												<button
													type="button"
													className={`video-control-btn bg-white
						${requestedMicAccess || isBuyerMuted ? 'bg-pressedIconsRedColor dark:bg-pressedIconsRedColor text-white' : ' dark:text-white dark:bg-darkGray-50'
												}
						hover:text-pressedIconsRedColor
						 rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2
						disabled:opacity-50`}
													onClick={onMicButtonClick}
												>
													{micAccessApprovedByBuyer === true ? (
														!isBuyerMuted ? (
															<MicIcon
																width="12"
																height="17"
																stroke="currentColor"
																fill="currentColor"
															/>
														) : (
															<MicOffIcon
																width="25"
																height="24"
																stroke="currentColor"
																fill="currentColor"
															/>
														)
													) : <RaiseHandIcon /> }
												</button>
											)}
											<button
												type="button"
												className={`video-control-btn bg-white
						${!sound ? 'bg-pressedIconsRedColor dark:bg-pressedIconsRedColor text-white pulsing-button' : 'dark:text-white dark:bg-darkGray-50'
										}
						hover:text-pressedIconsRedColor
						  rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
												onClick={() => {
													setSound(!sound);
												}}
											>
												{!sound ? (
													<SoundOffIcon
														stroke="currentColor"
														fill="currentColor"
														width="24"
														height="24"
													/>
												) : (
													<SoundOnIcon
														stroke="currentColor"
														fill="currentColor"
														width="24"
														height="24"
													/>
												)}
											</button>
											{
												!participantScreen.active && !resize
													? (
														<button
															type="button"
															className={`video-control-btn bg-white dark:text-white dark:bg-darkGray-50 
						hover:text-pressedIconsRedColor
						rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center px-3 ml-2`}
															// onClick={!resize ? participantScreen.enter : participantScreen.exit}
															onClick={() => {
																if (mobileSize && orientation == 0) {
																	defaultToastWarning('Change orientation to landscape for fullscreen mode!');
																	return;
																}
																setResize(true);
																if (!mobileSize) participantScreen.enter();
															}}
														>
															<ResizeIcon />
														</button>
													)
													: (
														<button
															type="button"
															className={`video-control-btn bg-pressedIconsRedColor text-white
					dark:text-white dark:bg-darkGray-50
					hover:text-pressedIconsRedColor
					rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center px-3 ml-2`}
															// onClick={!resize ? participantScreen.enter : participantScreen.exit}
															onClick={() => {
																if (mobileSize && orientation == 0) {
																	defaultToastWarning('Change orientation to landscape for fullscreen mode!');
																	return;
																}
																setResize(false);
																if (!mobileSize) participantScreen.exit();
															}}
														>
															<ResizeIcon />
														</button>
													)
											}

										</div>
									)}

								</div>
								{/* <div>3</div> */}
								{/* </div> */}
								{/* <p
					className="text-xl not-italic font-sans font-semibold tracking-wide text-white"
					style={{ marginRight: 'auto', alignItems: 'center' }}
				>
					{timestamp}
				</p> */}
							</div>

						)
							: (
								<>
									<div
										className="absolute bottom-0 text-center h-10"
										style={{ zIndex: '20', left: '40%', right: '40%' }}
									>
										<div className="w-max">
											<div className="flex items-center justify-center mt-2">
												<p
													className="text-xl §not-italic font-sans tracking-wide text-white px-2"
													style={{
														alignItems: 'center', fontSize: mobileSize ? '15px' : '25px', zIndex: '30', margin: '0 auto'
													}}
												>
													{!showThankYou ? timestamp : ''}
												</p>
											</div>

										</div>
										{/* <div>3</div> */}
										{/* </div> */}
										{/* <p
					className="text-xl not-italic font-sans font-semibold tracking-wide text-white"
					style={{ marginRight: 'auto', alignItems: 'center' }}
				>
					{timestamp}
				</p> */}
									</div>

								</>
							)
					}

				</div>

				{/* {isLocal ? (
				<div class="flex items-center justify-center mt-2">
					<button
						className={`video-control-btn bg-white bg-opacity-75 rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
						onClick={() => {
							setMuted(!muted);
						}}
					>
						{!muted ? <MicIcon /> : <MicOffIcon />}
					</button>
					<button
						className={`video-control-btn bg-white bg-opacity-75 rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center`}
						onClick={() => {
							setCam(!cam);
						}}
					>
						{!cam ? <VideoOffIcon /> : <VideoOnIcon />}
					</button>
					<button
						className={`video-control-btn bg-white bg-opacity-75 rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center px-3 ml-2`}
						onClick={() => {
							setResize(!resize);
						}}
					>
						<ResizeIcon />
					</button>
					<button
						className={`video-control-btn bg-white bg-opacity-75 rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center px-3 ml-2`}
						onClick={optionsOnClick}
					>
						<OptionsIcon />
					</button>
					<button
						className={`video-control-btn bg-white bg-opacity-75 rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center px-3 ml-2`}
						onClick={() => {
							changeCameraOnClick(videoRef, videoTracks);
						}}
					>
						<OptionsIcon />
					</button>
				</div>
			) : (
				<div class="flex items-center justify-center mt-2">
					<button
						className={`video-control-btn bg-white bg-opacity-75 rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2
						disabled:opacity-50`}
						disabled={raiseHand}
						onClick={() => {
							setRaiseHand(!raiseHand);
						}}
					>
						<RaiseHandIcon />
					</button>
					<button
						className={`video-control-btn bg-white bg-opacity-75 rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
						onClick={() => {
							setSound(!sound);
						}}
					>
						{!sound ? <SoundOffIcon /> : <SoundOnIcon />}
					</button>
					<button
						className={`video-control-btn bg-white bg-opacity-75 rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center px-3 ml-2`}
						onClick={() => {
							setResize(!resize);
						}}
					>
						<ResizeIcon />
					</button>
				</div>
			)} */}

				<audio
					ref={audioRef}
					autoPlay
					muted={!sound}
				/>
				<div id="remote-audios" />
				{isMobile && !landscapeMode && (
					<div className="my-1">
						<div className="w-full h-15">
							<div className="">
								{isLocal ? (
									<div className="flex flex-nowrap items-center">
										<div className="relative flex-1 flex justify-start">
											{((mobileSize || (isTablet && !landscapeMode)) && !openQueueContainer) && (
												<ArrowButton
													openQueueContainer={openQueueContainer}
													updatePopupsStatus={updatePopupsStatus}
													buttonRef={arrowButtonRef}
													containerRef={arrowButtonContainerRef}
													onOpenQueueContainer={onOpenQueueContainer}
												/>
											)}
										</div>
										<div className="flex flex-grow text-center">
											<button
												type="button"
												className={`video-control-btn 
${muted ? 'bg-djawn text-white pulsing-button' : ' dark:text-white dark:bg-darkGray-50 '
									}
rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
												onClick={() => {
													setMuted(!muted);
												}}
											>
												{!muted ? (
													<MicIcon
														width="12"
														height="17"
														stroke="currentColor"
														fill="currentColor"
													/>
												) : (
													<MicOffIcon
														width="25"
														height="24"
														stroke="currentColor"
														fill="currentColor"
													/>
												)}
											</button>
											<button
												type="button"
												className={`video-control-btn 
${!cam ? 'text-red-600' : 'dark:text-white dark:bg-darkGray-50 '
									}
rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center`}
												onClick={() => {
													setCam(!cam);
												}}
											>
												{!cam ? <VideoOffIcon /> : <VideoOnIcon />}
											</button>
											<button
												type="button"
												className={`video-control-btn 
				${reversedCamera == 1 ? 'text-red-600' : 'dark:text-white dark:bg-darkGray-50 '
									}
				hover:text-pressedIconsRedColor
				rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
												onClick={() => {
													changeCameraOnClick(videoRef, videoTracks);
												}}
											>
												<VideoCameraIcon />
											</button>
											<EventNameInformationButton
												updatePopupsStatus={updatePopupsStatus}
												buttonRef={eventNamePopupRef}
												showEventNameInChatContainer={showEventNameInChatContainer}
												setShowEventNameInChatContainer={setShowEventNameInChatContainer}
											/>
										</div>
									</div>
								) : (
									<div className="flex flex-nowrap items-center">
										<div className="relative flex-1 flex justify-start">
											{((mobileSize || (isTablet && !landscapeMode)) && !openQueueContainer) && (
												<ArrowButton
													openQueueContainer={openQueueContainer}
													updatePopupsStatus={updatePopupsStatus}
													buttonRef={arrowButtonRef}
													containerRef={arrowButtonContainerRef}
													onOpenQueueContainer={onOpenQueueContainer}
												/>
											)}
										</div>
										<div className="flex flex-grow text-center">
											{!isSeller && (
												<button
													type="button"
													className={clsx('rounded-full border-djawn hover:bg-djawn border-2 video-control-btn bg-white',
														requestedMicAccess || isBuyerMuted ? 'bg-pressedIconsRedColor dark:bg-pressedIconsRedColor text-white' : 'dark:text-white dark:bg-darkGray-50',
														requestedMicAccess && !micAccessApprovedByBuyer && 'pulsing-button',
														`hover:text-pressedIconsRedColor rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center disabled:opacity-50`,)}
													onClick={onMicButtonClick}
												>
													{micAccessApprovedByBuyer === true ? (
														!isBuyerMuted ? (
															<MicIcon
																width="12"
																height="17"
																stroke="#e82e2c"
																fill="#e82e2c"
															/>
														) : (
															<MicOffIcon
																width="25"
																height="24"
																stroke="white"
																fill="white"
															/>
														)
													) : <RaiseHandIcon className={clsx('h-8 w-8', requestedMicAccess ? 'text-white' : 'text-djawn')} />}
												</button>
											)}
											<button
												type="button"
												className={`video-control-btn
												border-djawn border-2 
	${!sound ? 'bg-djawn text-white pulsing-button' : 'dark:text-white dark:bg-darkGray-50'}
	rounded-full h-${iconHeight} w-${iconHeight} flex items-center justify-center mx-2`}
												onClick={() => {
													setSound(!sound);
												}}
											>
												{!sound ? (
													<SoundOffIcon
														stroke="white"
														fill="white"
														className="h-6 w-6 hover:fill-djawn hover:text-djawn"
													/>
												) : (
													<SoundOnIcon
														stroke="#e82e2c"
														fill="#e82e2c"
														className="h-5 w-5 hover:fill-white hover:text-black"
													/>
												)}
											</button>
											{isLoggedIn() &&	(
												<button
													type="button"
													className="border-2 bg-opacity-75 rounded-full md:h-10 h-8 md:w-10 w-8 flex items-center justify-center
							disabled:opacity-50 focus:outline-none border-djawn hover:bg-djawn"
													style={{ zIndex: '30' }}
													onClick={setBookmark}
												>
													<BookmarkIcon fill="#e82e2c" stroke="none" className="h-8 w-8 hover:fill-black hover:text-black" />
												</button>
											)}
											<EventNameInformationButton
												buttonRef={eventNamePopupRef}
												updatePopupsStatus={updatePopupsStatus}
												showEventNameInChatContainer={showEventNameInChatContainer}
												setShowEventNameInChatContainer={setShowEventNameInChatContainer}
											/>
										</div>
									</div>
								)}

							</div>
						</div>
						{/* <div className="border-b-4 border-indigo-500/100 mt-2 rounded-b-full" /> */}
					</div>
				)}
			</div>
		</FullScreen>
	);
};

export default Participant;

// function useScreenOrientation() {
//     if(!window) return;
//     const [orientation, setOrientation] = useState(window.screen.orientation.type);

//     useEffect(() => {
//     if(!window) return;
//       const handleOrientationChange= () => setOrientation(window.screen.orientation.type);
//       window.addEventListener('orientationchange', handleOrientationChange);
//       return () => window.removeEventListener('orientationchange', handleOrientationChange);
//     }, []);

//     return orientation;
//   }

function useScreenOrientation(onOrientationChange) {
	if (!window) return;
	const [orientation, setOrientation] = useState(window.orientation);
	const [landscapeMode, setLandscapeMode] = useState(false);

	useEffect(() => {
		if (!isMobile) return;
		if (orientation == 0 || orientation == 180) {
			document.body.style.overflowY = 'hidden';
			onOrientationChange(true);
			setLandscapeMode(false);
		} else {
			document.body.style.overflow = 'visible';
			onOrientationChange(false);
			setLandscapeMode(true);
		}
	}, [orientation]);

	useEffect(() => {
		if (!window) return;
		const handleOrientationChange = () => setOrientation(window.orientation);
		window.addEventListener('orientationchange', handleOrientationChange);
		return () => window.removeEventListener('orientationchange', handleOrientationChange);
	}, []);

	return [orientation, landscapeMode];
}
