/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, {
	useState, useEffect, useRef, useContext
} from 'react';
import { navigate } from 'gatsby';
import BounceLoader from 'react-spinners/BounceLoader';
import { css } from '@emotion/core';
import { Beforeunload } from 'react-beforeunload';
import { isSupported } from 'twilio-video';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import {
	getAccessToken,
	isLoggedIn,
	removeUserFromLocalStorage,
	shouldRedirectToSignIn
} from '../../../utils/auth';
import { redirectToDefaultBrowser } from '../../../utils/redirect';
import axiosConfig from '../../../api/config';

import {
	getEventDetails,
	isEventStopped,
	exitRoom,
	isRoomHost,
	isUserEligibleForEvent,
	exitRoomByAnonymousToken,
	stopEventRoom,
	sendEventReportEmail,
	exitRoomViaFetch,
	exitRoomByAnonymousTokenViaFetch,
	stopEventRoomViaFetch,
	sellerHasNoLiveEventInProgress,
	isEventOwner
} from '../../../api/event.request';
import Room from '../../../components/room/room';

import RoomPolicyConfirmation from '../../../components/room-policy-confirmation';
import UnsupportedBrowserModal from '../../../containers/event/room/unsupported-browser-modal';
import {
	hideProductSavedNotification,
	setAuthenticatedUserId
} from '../../../state/action';
import { getAuthenticatedUserId } from '../../../api/auth.request';
import useStateRef from '../../../hooks/useStateRef';
import useErrorNotifier from '../../../hooks/use-error-notifier';
import { LiveEventDataProvider } from '../../../contexts/live-event-data';
import {
	NotificationSoundContext,
	NotificationSoundProvider
} from '../../../contexts/notification-sound';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const RoomPage = ({
	params, location, dispatch, userId
}) => {
	const [nickname, setNickname] = useState('');
	const [joinEvent, setJoinEvent] = useState(false);
	const [authStatus, setAuthStatus] = useState(false);
	const [isHost, setIsHost] = useState(false);
	const [isSeller, setIsSeller] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [hasStarted, setHasStarted] = useState(false);
	const [eventDetails, setEventDetails] = useState(null);
	const [paypalClient, setPaypalClient] = useState(null);
	const [tries, setTries] = useState(15);
	const [showPolicyModal, setShowPolicyModal] = useState(false);
	const [isEligible, setIsEligible] = useState(false);
	const [showEventDetailsButton, setShowEventDetailsButton] = useState(false);
	const [
		showUnsupportedBrowserModal,
		setShowUnsupportedBrowserModal
	] = useState(false);
	const { showToastError } = useErrorNotifier();
	const isHostRef = useRef(isHost);
	const setIsHostInterceptor = (value) => {
		isHostRef.current = value;
		setIsHost(value);
	};
	useEffect(() => {
		if (!isSupported) {
			setShowUnsupportedBrowserModal(true);
			return;
		}
		setShowPolicyModal(true);
		if (shouldRedirectToSignIn()) {
			removeUserFromLocalStorage();
		}
		if (!params || !params.eventId) return;

		isEventOwner(params.eventId)
			.then((res) => {
				setShowEventDetailsButton(!res.data);
			})
			.catch((err) => {
				console.error(err);
			});

		isUserEligibleForEvent(
			params.eventId,
			localStorage.getItem(`anon-${params.eventId}`) || 'undefined'
		)
			.then((_) => {
				setIsEligible(true);
			})
			.catch((err) => {
				if (err.response && err.response.data && err.response.data.error) {
					navigate('/', {
						state: {
							toastError: err.response.data.error
						}
					});
				} else if (window.location) {
					window.location.href = window.location.origin;
				} else {
					navigate('/');
				}
			});

		if (!userId) {
			getAuthenticatedUserId()
				.then((userRes) => {
					dispatch(setAuthenticatedUserId(userRes.data));
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [params]);

	useEffect(() => {
		if (!window) return;
		window.addEventListener('unload', () => {
			const baseUrl = axiosConfig.defaults.baseURL;
			const accessToken = getAccessToken();
			if (!accessToken) {
				const anonToken = localStorage.getItem(`anon-${params.eventId}`);
				if (anonToken) {
					navigator.sendBeacon(
						`${baseUrl}event/room/delete/post/anonymous/${params.eventId}/${anonToken}`,
						'deleting'
					);
				}
			} else {
				navigator.sendBeacon(
					`${baseUrl}event/room/delete/post/${params.eventId}/${nickname}`,
					'deleting'
				);
			}

			if (!isHostRef.current) return;
			navigator.sendBeacon(
				`${baseUrl}event/stop/post/${params.eventId}`,
				'stopping'
			);
		});

		window.addEventListener('popstate', () => {
			let exitRoomParam = nickname;
			let exitRoomMethod = exitRoom;
			const accessToken = getAccessToken();
			if (!accessToken) {
				const anonToken = localStorage.getItem(`anon-${params.eventId}`);
				if (anonToken) {
					exitRoomParam = anonToken;
					exitRoomMethod = exitRoomByAnonymousToken;
				}
			}

			exitRoomMethod(params.eventId, exitRoomParam).catch((err) => {
				const mute = err;
			});
		});

		return () => (window.onpopstate = () => {});
	}, [nickname, isHost]);

	useEffect(() => {
		if ((showPolicyModal && !isEligible) || loaded) return;
		setLoaded(false);
		authState();
		isRoomHost(params.eventId)
			.then((res) => {
				setIsHost(res.data.isHost);
				setIsSeller(res.data.isHost);
				setHasStarted(res.data.statusId == 59);
				if (res.data.isHost) {
					sellerHasNoLiveEventInProgress(params.eventId).catch((err) => {
						showToastError(err);
						navigate('/');
					});
				}

				eventStopped(true);

				getEventDetails(params.eventId, true)
					.then((eventDetailRes) => {
						setEventDetails(eventDetailRes.data);
						setLoaded(true);
					})
					.catch((err) => {
						const mute = err;
					});
			})
			.catch((err) => {
				const tempTries = 0;
				const hasBeenStarted = false;

				getEventDetails(params.eventId, true)
					.then((res) => {
						setEventDetails(res.data);
						setLoaded(true);
					})
					.catch((eventDetailErr) => {
						const mute = eventDetailErr;
					});

				setLoaded(true);
			});
	}, [showPolicyModal, isEligible]);

	const eventStopped = async (quit) => {
		await isEventStopped(params.eventId)
			.then((stopped) => {
				if (stopped.data && stopped.data === true) {
					if (quit) {
						navigate(`/event/${params.eventId}`);
					}
				}
			})
			.catch((err) => {
				const loggedIn = isLoggedIn();
				if (
					((quit && isHost) === false && loggedIn === false)
					|| (!joinEvent && isLoggedIn() === false)
				) {
					if (window.location) {
						window.location.href = window.location.origin;
					} else {
						navigate('/');
					}
				}
			});
	};

	const authState = () => {
		if (!isLoggedIn()) {
			const cachedNickname = localStorage.getItem(
				`event-nickname-${params.eventId}`
			);
			if (cachedNickname && cachedNickname.length > 0) {
				setNickname(cachedNickname);
				setJoinEvent(true);
				setAuthStatus(true);
			} else {
				setAuthStatus(false);
			}
		} else {
			setAuthStatus(true);
			setJoinEvent(true);
		}
	};

	useEffect(() => {
		redirectToDefaultBrowser();

		dispatch(hideProductSavedNotification());
	}, [window]);

	if ((!loaded || !isEligible) && !showUnsupportedBrowserModal) {
		return (
			<div className="w-screen h-screen bg-white absolute left-0 top-0">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}

	return (
		<Beforeunload
			onBeforeunload={(e) => {
				e = e || window.event;
				if (e) {
					e.returnValue = 'Djawn';
				}
				return 'Djawn';
			}}
		>
			<NotificationSoundProvider isSeller={isHost}>
				<ToastContainer />
				<UnsupportedBrowserModal
					showModal={showUnsupportedBrowserModal}
					onClose={() => setShowUnsupportedBrowserModal(false)}
					eventId={params.eventId}
				/>

				<RoomPolicyConfirmation
					showModal={showPolicyModal}
					setShowModal={setShowPolicyModal}
					eventId={params.eventId}
					showEventDetailsButton={showEventDetailsButton}
					onConfirm={() => {
						setShowPolicyModal(false);
					}}
					onCancel={() => navigate('/')}
				/>
				{isEligible && !showPolicyModal && (
					<LiveEventDataProvider
						eventTypeId={
							eventDetails
							&& eventDetails.event
							&& eventDetails.event.eventTypeId
						}
						eventId={params.eventId}
						breakTemplateId={
							eventDetails
							&& eventDetails.event
							&& eventDetails.event.initialBreakTemplateId
						}
						isHost={isSeller}
					>
						<Room
							params={params}
							eventInformation={eventDetails}
							setEventInformation={setEventDetails}
							setNickname={setNickname}
							nickname={nickname}
							restart={location && location.state && location.state.restart}
							eventStopped={eventStopped}
							authStatus={authStatus}
							isRoomHost={isHost}
							setIsRoomHost={setIsHostInterceptor}
							hasStarted={hasStarted}
							setHasStarted={setHasStarted}
							isSeller={isSeller}
							anonymous={() => {
								const item = localStorage.getItem(
									`event-nickname-${params.eventId}`
								);
								return item && item.length > 0 && !authStatus;
							}}
						/>
					</LiveEventDataProvider>
				)}
			</NotificationSoundProvider>
		</Beforeunload>
	);
};
export default connect(
	(state) => ({
		userId: state.utils.userId
	}),
	null
)(RoomPage);
