/* eslint-disable array-bracket-spacing */
import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const MessageSenderModal = ({
	showModal,
	setShowModal,
	sendEmail,
	defaultSubject = ''
}) => {
	const [subject, setSubject] = useState(defaultSubject);
	const [contentBlock] = useState(null);
	const [editorState, setEditorState] = useState(null);

	useEffect(() => {
		if (!contentBlock) return;
		setEditorState(
			EditorState.createWithContent(
				ContentState.createFromBlockArray(contentBlock.contentBlocks)
			)
		);
	}, [contentBlock]);

	const getHtmlContent = () => draftToHtml(convertToRaw(editorState.getCurrentContent()));

	return (
		<>
			{showModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
							<div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
								<p className="font-semibold text-gray-800">
									Send Email to Buyers
								</p>
								<svg
									className="cursor-pointer w-6 h-6"
									onClick={() => setShowModal(false)}
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</div>
							<div className="flex flex-col px-6 py-5 bg-gray-50">
								<p className="mb-2 font-semibold text-gray-700">
									Email Subject
								</p>
								<textarea
									type="text"
									name=""
									placeholder="Type message..."
									defaultValue={subject}
									className="p-5 mb-5 bg-white border border-gray-200 rounded shadow-sm h-36"
									onChange={(e) => setSubject(e.target.value)}
								/>
								<p className="mb-2 font-semibold text-gray-700">Email Body</p>
								<Editor
									editorState={editorState}
									wrapperClassName="demo-wrapper"
									editorClassName="p-5 mb-5 bg-white border border-gray-200 rounded shadow-sm h-36"
									onEditorStateChange={setEditorState}
								/>
							</div>
							<div className="flex flex-row items-center justify-between p-5 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg">
								<p className="font-semibold text-gray-600">Cancel</p>
								<button
									type="button"
									className="px-4 py-2 text-white font-semibold bg-blue-500 rounded"
									onClick={() => {
										const htmlMessage = getHtmlContent();
										const requestBody = { subject, htmlMessage };
										setShowModal(false);
										sendEmail(requestBody);
									}}
								>
									{' '}
									Send Email
								</button>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black" />
				</>
			) : null}
		</>
	);
};

export default MessageSenderModal;
