// eslint-disable-next-line no-unused-vars
import React from 'react';
import firebase from 'gatsby-plugin-firebase';
import moment from 'moment';
import useErrorNotifier from './use-error-notifier';
import {
	CONVERSATION_COULD_NOT_BE_CREATED,
	CONVERSATION_MESSAGE_COULD_NOT_BE_SENT
} from '../utils/errorCodes';

const useConversation = () => {
	const { setErrorFunction } = useErrorNotifier();
	const conversationRef = firebase.database().ref('conversation');
	const mentionsRef = firebase.database().ref('message_mention');
	const messagesRef = firebase.database().ref('message');

	const conversationParticipantRef = firebase
		.database()
		.ref('conversation_participant');

	const findConversationByName = async (name, isRefund = false) => {
		let conversation = null;
		await conversationRef
			.orderByChild('name')
			.equalTo(name)
			.once('value', (convSnapshot) => {
				convSnapshot.forEach((convChildSnapshot) => {
					const foundConversation = convChildSnapshot.val();
					foundConversation.id = convChildSnapshot.key;
					if (foundConversation.refund == isRefund) {
						conversation = foundConversation;
					}
				});
			});

		return conversation;
	};

	const findConversationByChild = async (
		child = '',
		value,
		liveEvent = false
	) => {
		let conversation = null;
		await conversationRef
			.orderByChild(child)
			.equalTo(value)
			.once('value', (convSnapshot) => {
				convSnapshot.forEach((convChildSnapshot) => {
					const foundConversation = convChildSnapshot.val();
					foundConversation.id = convChildSnapshot.key;
					if (liveEvent) {
						if (foundConversation.live_event) {
							conversation = foundConversation;
						}
					} else {
						conversation = foundConversation;
					}
				});
			});

		return conversation;
	};

	const findConversationByKey = async (key) => {
		let conversation = null;
		await conversationRef.child(key).once('value', (convSnapshot) => {
			convSnapshot.forEach((convChildSnapshot) => {
				const foundConversation = convChildSnapshot.val();
				foundConversation.id = convChildSnapshot.key;
				conversation = foundConversation;
			});
		});

		return conversation;
	};

	const addParticipantToConversation = async (
		conversationId,
		participantUserId = '',
		showConversation = true,
		additionalObj = null
	) => {
		let existingParticipant = null;
		await conversationParticipantRef
			.orderByChild('conversation_id')
			.equalTo(conversationId)
			.once('value', (convSnapshot) => {
				convSnapshot.forEach((convChildSnapshot) => {
					const foundParticipant = convChildSnapshot.val();
					if (foundParticipant.user_id == participantUserId) {
						foundParticipant.id = convChildSnapshot.key;
						existingParticipant = foundParticipant;
					}
				});
			});

		if (!existingParticipant) {
			let participantObj = {
				conversation_id: conversationId,
				user_id: participantUserId,
				date: moment().utc().toDate().toJSON(),
				last_seen_date: moment().subtract(1, 'minutes').utc().toDate()
					.toJSON(),
				show_conversation: showConversation
			};
			if (additionalObj) {
				participantObj = { ...participantObj, ...additionalObj };
			}
			return conversationParticipantRef.push(participantObj);
		}
		return null;
	};
	const createConversation = async (
		name = '',
		hostUserId = '',
		isGroup = true,
		allowAddParticipants = true,
		additionalObj = null,
		showConversation = true,
		participantUserIds = []
	) => {
		let conversationObj = {
			name,
			date: moment().utc().toDate().toJSON(),
			user_id: hostUserId,
			last_seen_date: moment().subtract(1, 'minutes').utc().toDate()
				.toJSON(),
			show_conversation: showConversation,
			active: true,
			is_group: isGroup
		};
		if (additionalObj) {
			conversationObj = { ...conversationObj, ...additionalObj };
		}

		if (!allowAddParticipants) {
			conversationObj.disable_add_participants = false;
		}

		let createdConversationSnapshot = null;
		try {
			createdConversationSnapshot = await conversationRef.push(conversationObj);
			if (participantUserIds && participantUserIds.length) {
				participantUserIds.forEach((userId) => {
					conversationParticipantRef.push({
						conversation_id: createdConversationSnapshot.key,
						user_id: userId,
						date: moment().utc().toDate().toJSON(),
						last_seen_date: moment()
							.subtract(1, 'minutes')
							.utc()
							.toDate()
							.toJSON(),
						show_conversation: true
					});
				});
			}
		} catch (err) {
			setErrorFunction(CONVERSATION_COULD_NOT_BE_CREATED);
		}
		return createdConversationSnapshot.key;
	};

	const sendMessage = (message, conversationId, userId, mentions = []) => {
		messagesRef
			.push({
				conversation_id: conversationId,
				user_id: userId,
				sent_date: moment().utc().toDate().toJSON(),
				message
			})
			.then((snap) => {
				if (mentions && mentions.length > 0) {
					const messageId = snap.key;
					mentions.forEach((mention) => {
						mentionsRef.push({
							messageId,
							conversation_id: conversationId,
							user_mentioned: mention,
							seen: false
						});
					});
				}
			})
			.catch(() => {
				setErrorFunction(CONVERSATION_MESSAGE_COULD_NOT_BE_SENT);
			});
	};

	const getMessages = async (conversationId) => {
		const messages = [];
		if (!conversationId) return [];
		await messagesRef
			.orderByChild('conversation_id')
			.equalTo(conversationId)
			.once('value', (snapshot) => {
				snapshot.forEach((childSnapshot) => {
					const message = childSnapshot.val();
					message.id = childSnapshot.key;
					messages.push(message);
				});
			});

		return messages;
	};

	return {
		createConversation,
		addParticipantToConversation,
		findConversationByChild,
		findConversationByKey,
		findConversationByName,
		getMessages,
		sendMessage
	};
};

export default useConversation;
