import React from 'react';
import { formatUTCDateToLocal } from '../../utils/formatter';

const AdminEventInformation = ({
	showMonitoringTool,
	event,
	totalAttendees = 0,
	totalAnonymous = 0,
	onTwilioDataClicked,
	showTwilioRemoveToken,
	onTwilioRemoveTokenClicked
}) => (
	<div className="w-full flex flex-col">
		<p>
			<b>Event Name:</b>
			{' '}
			{event.eventName}
		</p>
		<p>
			<b>Event Type:</b>
			{' '}
			{event.eventType.referenceDataValue}
		</p>
		<p>
			<b>Merchant Name:</b>
			{' '}
			{event.seller.accountUsers[0].account.companyName}
		</p>
		<p>
			<b>Event Time:</b>
			{' '}
			{formatUTCDateToLocal(event.eventTime)}
		</p>
		<br />
		<p>
			<b>Total Attendees: </b>
			{totalAttendees}
		</p>
		<p>
			<b>Total Anonymous: </b>
			{totalAnonymous}
		</p>
		{showMonitoringTool && (
			<div className="flex">
				<button
					type="button"
					onClick={onTwilioDataClicked}
					className="rounded-lg px-3 py-2 bg-djawn text-white cursor-pointer hover:bg-djawn-300 text-center md:h-auto h-10 flex items-center w-48 mr-4"
				>
					Open Twilio data
				</button>

				{showTwilioRemoveToken && (
					<button
						type="button"
						onClick={onTwilioRemoveTokenClicked}
						className="rounded-lg px-3 py-2 bg-blue-600 text-white cursor-pointer hover:bg-blue-400 text-center md:h-auto h-10 flex items-center w-48"
					>
						Remove Monitoring Tool Twilio Token
					</button>
				)}
			</div>
		)}
	</div>
);
export default AdminEventInformation;
