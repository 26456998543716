import axiosConfig from './config';

export const sellerStoreNameCheck = async (storeName) => await axiosConfig.get(`seller/storenameavailable?storeName=${storeName}`);
export const getDefaultLogo = async () => await axiosConfig.get('seller/defaultlogo');
export const generalQuestions = async () => await axiosConfig.get('seller/generalquestions');
export const sellerRegister = async (requestBody) => await axiosConfig.post('seller/register', requestBody);
export const getSellerProfile = async () => await axiosConfig.get('seller/profile');
export const sellerProfileUpdate = async (requestBody) => await axiosConfig.put('seller/update', requestBody);
export const elligibleForSellerApplication = async () => await axiosConfig.get('seller/eligible-for-submission');
export const isUserASeller = async () => await axiosConfig.get('seller/confirm-seller');
export const getUnauthorizedSellerError = async () => await axiosConfig.get('seller/unauthorized-seller-error');
export const getMerchantOnBoard = async (isUpdate) => {
	if (!isUpdate) {
		isUpdate = false;
	}

	return await axiosConfig.get(`seller/paypal-onboarding-url/${isUpdate}`);
};
export const getStripeMerchantOnBoard = async (isUpdate) => {
	if (!isUpdate) {
		isUpdate = false;
	}

	return await axiosConfig.get(`seller/stripe-onboarding-url/${isUpdate}`);
};
export const savePayalMerchant = async (merchantId, paypalMerchantId) => await axiosConfig.put(
	`seller/save-merchant/${merchantId}/${paypalMerchantId}`,
	null,
);
export const favoriteSeller = async (accountId) => await axiosConfig.post(
	`seller/favorite/${accountId}`,
	null,
);
export const isSellerFavorite = async (accountId) => await axiosConfig.get(`seller/favorite/${accountId}`);
export const sellerApplicationDecision = async (token, isApproved) => await axiosConfig.put(`seller/seller-decision/${token}/${isApproved}`, null);

export const showOnBoardProcess = async () => await axiosConfig.get('seller/show-onboard');
export const updatePaypalSeller = async (data) => await axiosConfig.put('seller/update-paypal', data);
export const updateStripeAccountId = async (accountId) => await axiosConfig.put(`seller/update-stripe/${accountId}`, null);
