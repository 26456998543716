import React, { useContext } from 'react';
import Button from '../../../components/button';
import Modal from '../../../components/modals';
import { TwilioTrackContext } from '../../../contexts/twilio-track';

const ParticipantMicBuyerOptions = ({ showModal, setShowModal }) => {
	const {
		mutedByBuyer,
		twilioParticipantId,
		muteOrUnmuteMicParticipants,
		closeMic
	} = useContext(TwilioTrackContext);

	if (!showModal) return <></>;
	return (
		<Modal
			showModal={showModal}
			onClose={() => setShowModal(false)}
			hideCloseButton
		>
			<div>
				<p>Your mic is active.</p>
				<div className="mt-3 flex xxs:flex-row justify-center items-center xxs:w-full xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0 mb-5">
					<div className="mr-3">
						<Button
							height="12"
							width="24"
							mobileWidth="24"
							mobileHeight="10"
							onClick={() => {
								setShowModal(false);
								closeMic([twilioParticipantId], false, false);
							}}
						>
							Cancel the Mic
						</Button>
					</div>
					<div calssName="mr-3">
						<Button
							height="12"
							width="24"
							mobileWidth="24"
							mobileHeight="10"
							onClick={() => {
								setShowModal(false);
								// TODO: Confirm if the null userId is going as null rather then "null"
								muteOrUnmuteMicParticipants(
									[
										{
											twilioParticipantId,
											userId: null
										}
									],
									!mutedByBuyer,
									false,
									false
								);
							}}
						>
							{mutedByBuyer ? 'Unmute' : 'Mute'}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ParticipantMicBuyerOptions;
