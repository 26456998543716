/* eslint-disable no-undef */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'firebase/database';
// import { isLoggedIn } from './../src/utils/auth';
import { navigate } from 'gatsby';
import { isLoggedIn } from './src/utils/auth';
import { isAdmin } from './src/api/auth.request';
import { isUserASeller } from './src/api/seller.request';

const pagesNotAvailableForAnonymous = [
	'/view-profile',
	'/profile',
	'/orders',
	'/cancel',
	'/event',
	'/no-available-products/',
	'/no-available-products',
	'/product-register',
	'/sales',
	'/product',
	'/products',
	'/products/',
	'/seller-register',
	'/admin',
	'/admin/',
	'/seller-application',
	'/seller-application/',
	'/payment-error/',
	'/thank-you/',
	'/orders/',
	'/thank-you/',
	'/sales/',
	'/stripe/',
	'/product/',
	'/event/messages/',
	'/admin/event/'
];

const pagesNotAvailableForBuyer = [
	'/event',
	'/no-available-products/',
	'/no-available-products',
	'/product-register',
	'/sales',
	'/product',
	'/products',
	'/products/',
	'/admin',
	'/admin/',
	'/seller-application',
	'/sales/',
	'/product/',
	'/event/messages/',
	'/admin/event/'
];

const pagesNotAvailableForSeller = [
	'/admin',
	'/seller-application',
	'/admin/event/'
];

const pagesAvailableForAdmin = [
	'/admin',
	'/admin/',
	'/seller-application',
	'/seller-application/',
	'/admin/event/'
];

require('./src/styles/global.css');

const checkAvailability = (pathName, isUserAdmin, isUserSeller) => {
	if (isUserSeller) {
		if (isUserAdmin) {
			const pagesWithoutCheckingAdminPages = pagesNotAvailableForSeller.filter((x) => !pagesAvailableForAdmin.some((t) => t === x));
			if (pagesWithoutCheckingAdminPages.some((e) => e === pathName)) {
				navigate('/not-authorized');
			} else {
				window.document.body.style.display = 'block';
			}
		} else if (pagesNotAvailableForSeller.some((e) => e === pathName)) {
			navigate('/not-authorized');
		} else {
			window.document.body.style.display = 'block';
		}
	} else if (isUserAdmin) {
		const pagesWithoutCheckingAdminPages = pagesNotAvailableForBuyer.filter((x) => !pagesAvailableForAdmin.some((t) => t === x));
		if (pagesWithoutCheckingAdminPages.some((e) => e === pathName)) {
			navigate('/not-authorized');
		} else {
			window.document.body.style.display = 'block';
		}
	} else if (pagesNotAvailableForBuyer.some((e) => e === pathName)) {
		navigate('/not-authorized');
	} else {
		window.document.body.style.display = 'block';
	}
};

const checkBuyerAvailability = (pathName, isUserAdmin = false) => {
	isUserASeller()
		.then(() => {
			checkAvailability(pathName, isUserAdmin, true);
		})
		.catch(() => {
			checkAvailability(pathName, isUserAdmin, false);
		});
};

export const onPreRouteUpdate = ({ location }) => {
	window.document.body.style.display = 'none';
	if (location.pathname == '/not-authorized') {
		window.document.body.style.display = 'block';
		return;
	}

	const locations = location.pathname.split('/');
	let hasMoreSubRoutes = false;
	if (locations.length > 2) {
		locations.splice(-1, 1);
		hasMoreSubRoutes = true;
	}
	let pathName = locations.join('/');

	if (hasMoreSubRoutes) {
		pathName += '/';
	}

	const isUserLoggedIn = isLoggedIn();

	const onUnauthorized = () => navigate('/not-authorized');

	if (
		!isUserLoggedIn
			&& pagesNotAvailableForAnonymous.some((e) => e === pathName)
	) {
		onUnauthorized();
	} else if (isUserLoggedIn) {
		// This is for double check on server
		isAdmin()
			.then((res) => {
				if (res.data === false) {
					if (pagesAvailableForAdmin.some((e) => e === pathName)) {
						onUnauthorized();
						window.document.body.style.display = 'block';
						return;
					}
				}
				checkBuyerAvailability(pathName, res.data);
			})
			.catch(() => {
				onUnauthorized();
			});
	} else {
		window.document.body.style.display = 'block';
	}
};

export { default as wrapRootElement } from './src/state/redux-wrapper';
