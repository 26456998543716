import React, { useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import BackButton from '../../components/button/back-button';
import NextButton from '../../components/button/next-button';
import { InfoIcon } from '../../components/Event/event-icons';
import { CreateEventContext } from '../../contexts/create-event';

const SetupTiers = () => {
	const [sportTypeText, setSportsTypeText] = useState('');

	const {
		breakTemplate,
		sports,
		costPerSlot,
		setCostPerSlot,
		setActiveStep
	} = useContext(CreateEventContext);

	useEffect(() => {
		const sportType = sports.find(
			(type) => type.referenceDataId == breakTemplate.template.sportTypeId
		);
		if (!sportType) return;
		setSportsTypeText(sportType.referenceDataValue);
	}, []);

	const onBackClick = () => {
		setActiveStep(2);
	};

	const onNextClick = () => {
		setActiveStep(4);
	};

	return (
		<div className="flex-grow p-4">
			<ReactTooltip multiline />
			<div className="justify-center mx-auto w-full md:w-1/2 lg:w-1/2">
				<div className="mb-6">
					<label
						htmlFor="event_type"
						className="block text-sm font-medium text-gray-700 pl-2"
					>
						Sport
					</label>
					<div className="mt-1 relative rounded-md shadow-sm">
						<input
							type="text"
							min="0"
							name="sporttype"
							id="sportType"
							className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10 text-gray-500 sm:text-sm border border-gray-200 rounded-md"
							placeholder="Sport Type"
							value={sportTypeText}
							//  onChange={(e) => setNumberOfSlots(e.target.value)}
							disabled="true"
							readOnly
						/>
					</div>
				</div>

				<div className="mb-6">
					<label
						htmlFor="slots_count"
						className="block text-sm font-medium text-gray-700 pl-2"
					>
						Number of slots
					</label>
					<div className="mt-1 relative rounded-md shadow-sm">
						<input
							type="number"
							min="0"
							name="slots_count"
							id="slots_count"
							className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10 text-gray-500  sm:text-sm border border-gray-200 rounded-md"
							placeholder="Number of slots"
							value={(breakTemplate.template || {}).slotSize}
							disabled="true"
							//  onChange={(e) => setNumberOfSlots(e.target.value)}
							readOnly
						/>
					</div>
				</div>

				<div className="mb-6">
					<label
						htmlFor="cost_per_slot"
						className="block text-sm font-medium text-gray-700 pl-2"
					>
						<div className="flex justify-start space-x-2">
							<div>Cost per slot</div>
							<div
								data-tip="What's the buy-in for each slot? (This includes any shipping costs.)<br />
										Make sure you double-check your total break cost covers your inventory and any shipping costs."
							>
								<InfoIcon />
							</div>
						</div>
					</label>
					<div className="mt-1 flex rounded-md shadow-sm">
						<span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
							$
						</span>
						<input
							type="text"
							min="0"
							name="cost_per_slot"
							id="cost_per_slot"
							className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-none rounded-r-md"
							placeholder="Cost per slot"
							value={costPerSlot}
							onChange={(e) => setCostPerSlot(e.target.value)}
						/>
					</div>
				</div>

				<div className="mb-4 border-t">
					<p className="block text-4lx font-medium text-center text-gray-700 pt-4">
						Total Break Cost $
						{breakTemplate.template.slotSize * costPerSlot}
					</p>

					<p className="block text-4lx font-medium text-center text-gray-700 pt-8">
						Please edit or confirm these values
					</p>
				</div>
			</div>
			<div className="flex justify-center mx-auto">
				<div className="mr-2">
					<BackButton onClick={onBackClick} />
				</div>
				<div className="ml-2">
					<NextButton onClick={onNextClick} />
				</div>
			</div>
		</div>
	);
};

export default SetupTiers;
