export const buttonTypes = {
	primary: {
		bgColor: 'red-500',
		bgHoverColor: 'red-700',
		textColor: 'gray-100',
		textHoverColor: 'gray-100',
		darkBgColor: 'red-500',
		darkBgHoverColor: 'red-700'
	},
	secondary: {
		bgColor: 'blue-600',
		bgHoverColor: 'blue-700',
		textColor: 'gray-100',
		textHoverColor: 'gray-100',
		darkBgColor: 'blue-600',
		darkBgHoverColor: 'blue-700'
	},
	djawn: {
		bgColor: 'djawn',
		bgHoverColor: 'djawn-300',
		textColor: 'white',
		textHoverColor: 'gray-100',
		darkBgColor: 'djawn',
		darkBgHoverColor: 'djawn-300'
	},
	'djawn-outline': {
		bgColor: 'white',
		bgHoverColor: 'djawn-300',
		textColor: 'djawn',
		border: 'djawn',
		textHoverColor: 'white',
		darkBgColor: 'darkGray-100',
		darkBgHoverColor: 'djawn-300',
	},
	dark: {
		bgColor: 'gray-700',
		bgHoverColor: 'gray-500',
		textColor: 'gray-100',
		border: 'gray-700',
		textHoverColor: 'white',
		darkBgColor: 'gray-700',
		darkBgHoverColor: 'gray-500'
	},
};
