/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, {
	useState, useEffect, useRef, useContext
} from 'react';
import AttendeesEventQueue from './attendees-event-queue';
import ProductsEventQueue from './products-event-queue';
import LineHistoryEventQueue from './line-history-event-queue';
import RipNShipEventQueue from './ripnship-event-queue';
import BreaksEventQueue from './breaks-event-queue';
import RandomizedBreaksEventQueue from './randomized-breaks-event-queue';
import SellerInformationEventQueue from './seller-information-event-queue';
import { getCurrentUser } from '../../utils/auth';
import {
	RIP_N_SHIP,
	SELECT_PRODUCTS_PRODUCT_SELECTION_OPTION
} from '../../utils/constants';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import { ACTIVE_LINE_VIEW, AVAILABLE_PRODUCTS_VIEW } from '../../utils/views';
import EventQueueFooterButtons from '../../containers/event/room/event-queue-footer-buttons';
import BanConfirmation from '../ban-confirmation';
import { SellerEventAttendeeActionsProvider } from '../../contexts/seller-event-attendee-actions';
import SellerAttendeeActionsModal from '../../containers/event/room/seller-attendee-actions-modal';
import BulkMicActionsModal from '../../containers/event/room/bulk-mic-actions-modal';

const EventQueue = ({
	eventInformation,
	isMobile,
	isHost,
	showProductModal,
	setShowProductModal,
	setSearchInputActive,
	eventId,
	selectedProduct,
	setSelectedProduct,
	isSeller,
	setShowPurchasedItemsModal,
	setPurchasedItemsBuyer,
	onEndTurn,
	changeWsMessage,
	initiatedEndTurnsWsMessage,
	setInitiatedEndTurnsWsMessage,
	endTurnWsMessage,
	exitWsMessage,
	newBuyersWsMessage,
	setPurchasedProductsUserId,
	setEventInformation,
	updatedBuyersQueueWsMessage,
	joinQueue,
	reinitiateEventInformation,
	joined,
	setJoined,
	setShowQueueModal,
	openPurchasedItemsModalByQueueOrderId,
	showSeller,
	ownerNickname,
	timers,
	setTimers,
	buyers,
	setBuyers,
	disableRipNShipFunctionality = false,
	showAddToCartPopup,
	onProductNamePopupClose,
	addToCartPopupFadingOut,
	eventGeneralDetails,
	fetchAttendeesAndUpdateAudioParticipants
}) => {
	const {
		views, activeView, setActiveView, isRipNShip
	} = useContext(
		LiveEventDataContext
	);
	const [filteredEventProducts, setFilteredEventProducts] = useState([]);
	const [eventProducts, setEventProducts] = useState([]);
	const [productSearchValue, setProductSearchValue] = useState('');
	const [historyBuyers, setHistoryBuyers] = useState([]);
	const [barrierLine, setBarrierLine] = useState(null);
	const productSearchInputRef = useRef();
	const [
		userCanAddOrRemoveEventProducts,
		setUserCanAddOrRemoveEventProducts
	] = useState(false);
	const [userCanAddProducts, setUserCanAddProducts] = useState(false);
	const [productSelectionId, setProductSelectionId] = useState(null);
	const [showBulkMicActionsModal, setShowBulkMicActionsModal] = useState(false);

	const handleProductSearch = (e, text) => {
		let value = '';
		if (e && e.target) {
			value = e.target.value;
			setProductSearchValue(value);
		} else {
			value = text;
		}
		const tempProducts = [...eventProducts].filter(
			(eventProduct) => eventProduct.product.productName
				.toLowerCase()
				.includes(value.toLowerCase())
				|| eventProduct.product.salesPriceRipShip.toString().includes(value)
				|| eventProduct.product.totalAvailableStock.toString().includes(value)
				|| (eventProduct.product.tags
					&& eventProduct.product.tags.length
					&& eventProduct.product.tags.some((p) => p.tags.toLowerCase().includes(value.toLowerCase())))
		);
		setFilteredEventProducts(tempProducts);
	};

	useEffect(() => {
		if (!eventInformation || !eventInformation.event) return;
		if (eventInformation.event.eventTypeId != RIP_N_SHIP) return;
		const availableProducts = eventInformation.products.filter(
			(f) => f.product.totalAvailableStock > 0
		);

		setEventProducts(availableProducts);
		setFilteredEventProducts(availableProducts);

		setHistoryBuyers(eventInformation.buyersHistoryQueue);
		setBarrierLine(eventInformation.queuePaymentLineBarrier);
		let found = false;

		eventInformation.buyersQueue.map((r) => {
			if (r.userId == getCurrentUser().id) {
				setJoined(true);
				found = true;
			}
			return r;
		});

		if (found == false) {
			setJoined(false);
		}

		if (
			isSeller
			&& eventInformation.event.ripNShip
			&& eventInformation.event.ripNShip.productSelectionId
		) {
			setProductSelectionId(eventInformation.event.ripNShip.productSelectionId);
			if (
				eventInformation.event.ripNShip.productSelectionId
				== SELECT_PRODUCTS_PRODUCT_SELECTION_OPTION
			) {
				setUserCanAddOrRemoveEventProducts(true);
			}

			setUserCanAddProducts(true);
		}
	}, [eventInformation]);

	useEffect(() => {
		if (!isRipNShip) return;

		if (isHost) {
			setActiveView(ACTIVE_LINE_VIEW);
		} else {
			setActiveView(AVAILABLE_PRODUCTS_VIEW);
		}
	}, [isRipNShip]);

	const setAllBuyers = (newBuyers) => {
		const tempEventInformation = { ...eventInformation };
		tempEventInformation.buyersQueue = newBuyers;
		setEventInformation(tempEventInformation);
	};

	return (
		<div className={`${isMobile ? 'w-full' : 'w-80'} dark:bg-darkGray-50`}>
			<div className="flex row border-b-2 dark:border-black">
				<label
					htmlFor="product_status"
					className="block text-sm font-medium text-gray-700 pl-2 dark:text-white "
				>
					Change View:
				</label>
				<select
					id="product_status"
					name="product_status"
					autoComplete="event_type"
					className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border dark:border-black bg-white dark:bg-darkGray-50 border-gray-200 dark:text-white rounded-md"
					onBlur={(e) => setActiveView(e.target.value)}
					onChange={(e) => setActiveView(e.target.value)}
					value={activeView}
				>
					{views.map((object, i) => (
						<option key={object} value={object}>
							{object}
						</option>
					))}
				</select>
			</div>
			{activeView === 'Available Product(s)' && (
				<div className="relative text-gray-600 dark:bg-darkGray-50 dark:border-black w-full md:w-auto mt-3 mb-3 flex justify-center">
					<input
						type="search"
						name="serch"
						placeholder="Search"
						ref={productSearchInputRef}
						onBlur={() => setSearchInputActive(false)}
						onFocus={() => {
							setSearchInputActive(true);
						}}
						className="h-10 px-5 pl-10 text-sm focus:outline-none border bg-white dark:bg-darkGray-50 dark:border-black text-black dark:text-white rounded-md w-full md:w-auto ios-input-background"
						onChange={handleProductSearch}
					/>
					<button
						type="submit"
						className="absolute left-0 top-0 mt-3 ml-4 dark:text-white"
						onClick={() => {
							if (
								productSearchInputRef
								&& productSearchInputRef.current
								&& productSearchInputRef.current.focus
							) {
								productSearchInputRef.current.focus();
							}
						}}
					>
						<svg
							className="h-4 w-4 fill-current"
							xmlns="http://www.w3.org/2000/svg"
							version="1.1"
							id="Capa_1"
							x="0px"
							y="0px"
							viewBox="0 0 56.966 56.966"
							width="512px"
							height="512px"
						>
							<path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
						</svg>
					</button>
				</div>
			)}
			<div
				className={`border-2 dark:border-black ${
					isMobile
						? 'h-80'
						: `${
							activeView == 'Available Product(s)'
								&& isSeller
								&& !isMobile
								&& (userCanAddProducts || userCanAddOrRemoveEventProducts)
								? 'h-103'
								: 'h-96'
						  }`
				} ${
					(activeView != 'Available Product(s)'
						|| !showAddToCartPopup
						|| !isRipNShip)
					&& 'overflow-y-auto'
				}`}
			>
				{activeView === 'Available Product(s)' && (
					<ProductsEventQueue
						eventProducts={eventProducts}
						filteredEventProducts={filteredEventProducts}
						eventId={eventId}
						setShowProductModal={setShowProductModal}
						setSelectedProduct={setSelectedProduct}
						setEventInformation={setEventInformation}
						disableRowClickedFunctionality={disableRipNShipFunctionality}
						showAddToCartInformationPopup={showAddToCartPopup}
						onProductNamePopupClose={onProductNamePopupClose}
						addToCartPopupFadingOut={addToCartPopupFadingOut}
						userCanAddOrRemoveEventProducts={userCanAddOrRemoveEventProducts}
						userCanAddProducts={userCanAddProducts}
						productSelectionId={productSelectionId}
					/>
				)}
				{activeView === 'Line History' && (
					<LineHistoryEventQueue
						historyBuyers={historyBuyers}
						setBuyer={setPurchasedItemsBuyer}
						openPurchasedItemsModalByQueueOrderId={
							openPurchasedItemsModalByQueueOrderId
						}
						setShowPurchasedItemsModal={setShowPurchasedItemsModal}
					/>
				)}
				{activeView === 'Slots/Teams - Pick your slots' && (
					<BreaksEventQueue breaks={eventInformation.breaks} />
				)}

				{activeView === 'Slots/Teams - Randomized Breaks' && (
					<RandomizedBreaksEventQueue />
				)}
				{activeView === 'Seller Information' && (
					<SellerInformationEventQueue
						storeName={eventInformation.seller.storeName}
						addressLine1={eventInformation.seller.addressLine1}
						apartmentSuite={eventInformation.seller.addressLine2}
						city={eventInformation.seller.city}
						state={eventInformation.seller.state}
						postalCode={eventInformation.seller.zip}
						shippingFrequency={eventInformation.seller.shippingFrequency}
						shipCards={eventInformation.seller.shipCards}
						shippingType={eventInformation.seller.shippingType}
						shippingCost={eventInformation.seller.shippingCost}
						showSeller={showSeller}
						eventGeneralDetails={eventGeneralDetails}
					/>
				)}
				<SellerEventAttendeeActionsProvider eventId={eventId}>
					{isSeller ? (
						<>
							<BanConfirmation />
							<SellerAttendeeActionsModal
								fetchAttendeesAndUpdateAudioParticipants={
									fetchAttendeesAndUpdateAudioParticipants
								}
							/>
							<BulkMicActionsModal
								showModal={showBulkMicActionsModal}
								setShowModal={setShowBulkMicActionsModal}
							/>
						</>
					) : null}
					{activeView === 'Attendees' && (
						<AttendeesEventQueue
							isHost={isHost}
							ownerNickname={ownerNickname}
						/>
					)}
					{isRipNShip && (
						<RipNShipEventQueue
							eventId={eventId}
							initialBuyers={eventInformation.buyersQueue}
							historyBuyers={eventInformation.buyersHistoryQueue}
							isSeller={isSeller}
							changeWsMessage={changeWsMessage}
							initiatedEndTurnsWsMessage={initiatedEndTurnsWsMessage}
							setInitiatedEndTurnsWsMessage={setInitiatedEndTurnsWsMessage}
							endTurnWsMessage={endTurnWsMessage}
							exitWsMessage={exitWsMessage}
							reinitiateEventInformation={reinitiateEventInformation}
							newBuyersWsMessage={newBuyersWsMessage}
							barrierLine={barrierLine}
							visible={activeView === 'Active Line'}
							setBuyer={setPurchasedItemsBuyer}
							setAllBuyers={setAllBuyers}
							setShowPurchasedItemsModal={setShowPurchasedItemsModal}
							setPurchasedProductsUserId={setPurchasedProductsUserId}
							updatedBuyersQueueWsMessage={updatedBuyersQueueWsMessage}
							joinQueue={joinQueue}
							joined={joined}
							setJoined={setJoined}
							onEndTurn={onEndTurn}
							timers={timers}
							setTimers={setTimers}
							buyers={buyers}
							setBuyers={setBuyers}
							hideJoinButton={disableRipNShipFunctionality}
						/>
					)}
				</SellerEventAttendeeActionsProvider>
			</div>
			{isRipNShip && (
				<div className="flex justify-center items-center m-1">
					{disableRipNShipFunctionality ? (
						<p className="text-red-700 font-semibold">Line is closed</p>
					) : (
						<p className="text-blue-500 font-semibold">Line is open</p>
					)}
				</div>
			)}
			{isSeller || isRipNShip ? (
				<EventQueueFooterButtons
					isSeller={isSeller}
					onClick={() => setShowQueueModal(true)}
					onOpenBulkMicActions={() => setShowBulkMicActionsModal(true)}
				/>
			) : null}
		</div>
	);
};

export default EventQueue;
