import React, { useState, useEffect } from 'react';
import firebase from 'gatsby-plugin-firebase';
import Autocomplete from '../autocomplete';
import ConversationTypeMessage from './conversation-type-message';
import {
	CONVERSATION_NAME_EXISTS,
	DIRECT_MESSAGE_SAME_PARTICIPANT,
	GROUP_MESSAGE_SAME_PARTICIPANT
} from '../../utils/errorCodes';
import ConversationUserCard from './conversation-user-card';
import useConversation from '../../hooks/useConversation';

const CreateConversation = ({
	authenticatedUserId,
	mobileSize,
	directConversationExists,
	showError,
	refreshConversations,
	openCreatedConversation,
	groupConversationExists,
	showInternetConnectionError,
	setInputFocused,
	authenticated,
	enabledChatInput
}) => {
	const [conversationName, setConversationName] = useState('');
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [isConnectedToInternet, setIsConnectedToInternet] = useState(true);
	const { createConversation, sendMessage } = useConversation();

	useEffect(() => {
		const connectedRef = firebase.database().ref('.info/connected');
		connectedRef.on('value', (snap) => {
			if (snap.val() === true) {
				setIsConnectedToInternet(true);
			} else {
				setIsConnectedToInternet(false);
			}
		});
	}, []);

	return (
		<>
			<div className="flex flex-col items-start mb-4 w-full max-w-max max-h-2/3 min-w-full">
				<div className="flex flex-col w-full min-w-full">
					<div className="mb-6 w-full md:w-full lg:w-full xl:w-full">
						<div className="mt-1 relative rounded-md w-full">
							<input
								type="text"
								name="conversation_name"
								id="conversation_name"
								className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 w-full h-10 md:w-96 text-sm md:text-md border border-gray-200 rounded-md"
								placeholder="Give this conversation a meaningful name"
								value={conversationName}
								required
								maxLength={255}
								onBlur={(e) => setConversationName(e.target.value)}
								onChange={(e) => setConversationName(e.target.value)}
							/>
						</div>
					</div>

					<div className="flex relative text-gray-600 mb-2 md:mb-0 bg-yellow-300 w-full md:w-96 z-50">
						<Autocomplete
							setItemSelected={(item) => {
								const exists = selectedUsers.some((v) => v.id === item.id);
								if (exists) return;
								setSelectedUsers((prevUsers) => [...prevUsers, item]);
							}}
							authenticatedUserId={authenticatedUserId}
							selectedUsers={selectedUsers}
						/>
					</div>
				</div>

				<div className="flex max-h-full overflow-y-auto mt-5 border-2 border-gray rounded-xl">
					{selectedUsers && selectedUsers.length > 0 && (
						<div className="flex py-2 px-2 flex-wrap">
							{selectedUsers.map((object, i) => (
								<div className="flex pt-2 col-span-0" key={i}>
									<ConversationUserCard
										image={object.image}
										value={object.id}
										name={object.name}
										onItemRemoved={(value) => {
											const filteredSelectedUsers = selectedUsers.filter(
												(item) => item.id !== value
											);
											setSelectedUsers(filteredSelectedUsers);
										}}
									/>
								</div>
							))}
						</div>
					)}
				</div>
			</div>
			{selectedUsers
				&& ((selectedUsers.length > 1
					&& conversationName
					&& conversationName.replace(/\s/g, '').length)
					|| selectedUsers.length == 1) && (
				<div
					className="flex flex-col w-full bg-white"
					style={{
						position: mobileSize && 'fixed',
						bottom: mobileSize && 10,
						right: 1
					}}
				>
					<div className="flex rounded-lg border-2 border-grey mt-10">
						<ConversationTypeMessage
							onConversationCreation
							toolbarId="cc"
							enabledChatInput={enabledChatInput}
							setInputFocused={setInputFocused}
							mobileSize={mobileSize}
							authenticated={authenticated}
							createConversation={async (message) => {
								if (!isConnectedToInternet) {
									showInternetConnectionError();
									return;
								}

								if (
									selectedUsers
										&& selectedUsers.length
										&& selectedUsers.length === 1
								) {
									const result = await directConversationExists(
										authenticatedUserId,
										selectedUsers[0].id
									);
									if (result) {
										showError(DIRECT_MESSAGE_SAME_PARTICIPANT);
										return;
									}
								} else {
									const result = await groupConversationExists(
										authenticatedUserId,
										selectedUsers.slice()
									);
									if (result) {
										showError(GROUP_MESSAGE_SAME_PARTICIPANT);
										return;
									}
								}
								const database = firebase.database();

								database
									.ref('conversation')
									.orderByChild('name')
									.equalTo(conversationName)
									.once('value', (snapshot) => {
										if (
											!snapshot.val()
												|| (!conversationName && selectedUsers.length == 1)
										) {
											createConversation(
												conversationName,
												authenticatedUserId,
												selectedUsers.length != 1,
												true,
												null,
												true,
												selectedUsers && selectedUsers.map((user) => user.id)
											).then((createdConversationId) => {
												sendMessage(
													message,
													createdConversationId,
													authenticatedUserId
												);
												refreshConversations();
												if (createdConversationId) {
													database
														.ref('conversation')
														.child(createdConversationId)
														.once('value', (newConvSnapshot) => {
															let tempConversationObj = {};
															tempConversationObj = newConvSnapshot.val();
															tempConversationObj.id = newConvSnapshot.key;
															openCreatedConversation(tempConversationObj);
														});
												}
											});
										} else {
											showError(CONVERSATION_NAME_EXISTS);
										}
									});
							}}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default CreateConversation;
