import {
	ADD_ITEM_TO_CART, CHAT_SCROLLING_STARTED, CHAT_SCROLLING_DONE, REMOVE_ITEM_FROM_CART,
	SHOW_MOBILE_SEARCH, UPDATE_ITEM_TIMER, UPDATE_MESSAGES_COUNT, SET_USER_ID,
	SET_DARK_MODE, SET_LIGHT_MODE, SET_SAVED_PRODUCT_NOTIFICATION,
	RESET_SAVED_PRODUCT_NOTIFICATION
} from '../types';

export const addCartItem = (cartItem) => ({
	type: ADD_ITEM_TO_CART,
	cartItem
});

export const removeCartItem = (cartItem) => ({
	type: REMOVE_ITEM_FROM_CART,
	cartItem
});

export const updateCartItemTimer = (cartItem) => ({
	type: UPDATE_ITEM_TIMER,
	cartItem
});

export const updateShowMobileSearch = () => ({
	type: SHOW_MOBILE_SEARCH
});

export const isChatScrolling = () => ({
	type: CHAT_SCROLLING_STARTED
});

export const chatFinishedScrolling = () => ({
	type: CHAT_SCROLLING_DONE
});

export const updateMessagesCount = (messages) => ({
	type: UPDATE_MESSAGES_COUNT,
	unreadMessages: messages
});

export const setAuthenticatedUserId = (userId) => ({
	type: SET_USER_ID,
	userId
});

export const setDarkMode = () => ({
	type: SET_DARK_MODE
});

export const setLightMode = () => ({
	type: SET_LIGHT_MODE
});

export const showProductSavedNotification = (message) => ({
	type: SET_SAVED_PRODUCT_NOTIFICATION,
	message
});

export const hideProductSavedNotification = () => ({
	type: RESET_SAVED_PRODUCT_NOTIFICATION,
	message: ''
});
