import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getEvents } from '../api/event.request';
import Feeds from '../components/feeds';
import { setAuthenticatedUserId } from '../state/action';
import { getAuthenticatedUserId } from '../api/auth.request';
import {
	UPCOMING_EVENTS,
	DEFAULT_FEEDS_PAGE_SIZE
} from '../utils/listTypeConstants';

const MainPage = ({
	cartItems,
	showMobileSearch,
	userId,
	dispatch,
	location
}) => {
	useEffect(() => {
		if (!userId) {
			getAuthenticatedUserId()
				.then((userRes) => {
					dispatch(setAuthenticatedUserId(userRes.data));
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [userId]);

	return (
		<Feeds
			cartItems={cartItems}
			showMobileSearch={showMobileSearch}
			dispatch={dispatch}
			location={location}
			showBanner
			getFeeds={(
				currentPage,
				eventType = '',
				overridenDefaultPageSize = null
			) => getEvents(
				UPCOMING_EVENTS,
				overridenDefaultPageSize || DEFAULT_FEEDS_PAGE_SIZE,
				currentPage,
				eventType
			)}
		/>
	);
};

export default connect(
	(state) => ({
		cartItems: state.cart.cartItems,
		showMobileSearch: state.utils.showMobileSearch,
		userId: state.utils.userId
	}),
	null
)(MainPage);
