import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { ToastContainer } from 'react-toastify';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import LayoutHome from '../../components/layouts/layout-home';
import HeaderHome from '../../components/common/header-home';
import SEO from '../../components/seo';
import CreateEvent from '../../containers/create-event';
import { hasAvailableProductsInStock } from '../../api/product.request';
import { defaultToastError } from '../../utils/toast-error';
import { CreateEventProvider } from '../../contexts/create-event';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const EventsPage = () => {
	const [loading, setLoading] = useState(false);
	const [noAvailableProducts, setNoAvailableProducts] = useState(false);

	useEffect(() => {
		hasAvailableProductsInStock()
			.then(() => {
				setNoAvailableProducts(false);
			})
			.catch(() => {
				setNoAvailableProducts(true);
			});
	}, []);

	if (loading) {
		return (
			<div className="w-screen h-screen bg-white absolute left-0 top-0">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}
	return (
		<LayoutHome sidebar={false}>
			<SEO title="Create Event" auth />

			<div className="bg-gray-100 mx-auto w-full h-full pt-0">
				<HeaderHome
					siteTitle="Title"
					auth={false}
					signin={false}
					setLoading={setLoading}
					hideSearch
					onError={(errorMessage) => {
						defaultToastError(errorMessage);
					}}
					redirectToHomeIfNotSeller
				/>
				<ToastContainer />
				{noAvailableProducts ? (
					<div className="bg-white pt-20">
						<div className="flex w-full justify-center pb-8">
							<p className="text-2xl text-gray-700 text-center mx-auto mt-5">
								You don't have any products yet
							</p>
						</div>
						<div className="flex xxs:flex-col xxs:w-full mb-4 xxs:mb-4 sm:mb-4 md:mb-0 lg:mb-0">
							<div>
								<Link to="/products">
									<button
										type="button"
										className="h-10 mx-auto flex items-center justify-center px-6 py-2 border border-transparent rounded-sm text-base font-medium text-white bg-red-700 hover:bg-red-900 md:py-4 md:text-lg md:px-10 mb-5"
									>
										Add Product
									</button>
								</Link>
							</div>
						</div>
					</div>
				) : (
					<CreateEventProvider>
						<CreateEvent />
					</CreateEventProvider>
				)}
			</div>
		</LayoutHome>
	);
};

export default EventsPage;
