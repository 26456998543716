import React from 'react';

const NotAuthorizedPage = () => (
	<div style={{ textAlign: 'center' }} className="mt-20 font-bold">
		<div className="flex flex-col">
			<div style={{ textAlign: 'center' }}>
				<p className="text-opacity-100">
					You are not authorized to view this page.
				</p>
			</div>
		</div>
	</div>
);

export default NotAuthorizedPage;
