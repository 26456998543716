import React from 'react';
import PropTypes from 'prop-types';
import { buttonTypes } from './types';

const Button = ({
	children,
	type = 'primary',
	onClick,
	width = '20',
	mobileWidth = 'full',
	height = '12',
	mobileHeight = 'auto',
	textSize = 'xs',
	additionalClassName = '',
	rounded = true,
	roundedSize = 'md',
	...restProps
}) => {
	const buttonType = buttonTypes[type];
	return (
		<button
			type="button"
			className={`w-${mobileWidth} md:w-${width} h-${mobileHeight} md:h-${height} 
            bg-${buttonType.bgColor} hover:bg-${buttonType.bgHoverColor} text-${
			buttonType.textColor
		} 
            hover:text-${buttonType.textHoverColor} 
			hover:border-0 hover:border-${buttonType.bgHoverColor}
            text-${textSize} ${rounded && `rounded-${roundedSize}`} 
            font-semibold ${
		buttonType.border
			? `border-2 border-${buttonType.border}`
			: 'border-0'
		} disabled:opacity-50
			disabled:cursor-default
            dark:bg-${buttonType.darkBgColor}
			dark:hover:bg-${buttonType.darkBgHoverColor}
			shadow-lg
			${additionalClassName} `}
			{...restProps}
			onClick={onClick}
			onKeyDown={onClick}
		>
			{children}
		</button>
	);
};

Button.propTypes = {
	type: PropTypes.string,
	onClick: PropTypes.func,
	width: PropTypes.string,
	mobileWidth: PropTypes.string,
	height: PropTypes.string,
	mobileHeight: PropTypes.string,
	textSize: PropTypes.string,
	additionalClassName: PropTypes.string,
	rounded: PropTypes.bool,
	roundedSize: PropTypes.string
};

export default Button;
