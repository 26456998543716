/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState, useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import SortingComponent from '../sorting';
import EllipisWithTooltip from '../ellipsis-tooltip';
import { LiveEventDataContext } from '../../contexts/live-event-data';

const RandomizedBreaksEventQueue = () => {
	const { teamsRandomization } = useContext(LiveEventDataContext);
	const [filteredTeamsRandomization, setFilteredTeamsRandomization] = useState(
		[]
	);
	const [sortedAsc, setSortedAsc] = useState(false);

	useEffect(() => {
		setFilteredTeamsRandomization(teamsRandomization);
	}, [teamsRandomization]);

	const sortByTiers = (tierIndex) => {
		setSortedAsc(!sortedAsc);
		const sortedArray = filteredTeamsRandomization.slice().sort((a, b) => {
			if (sortedAsc) {
				if (a.tiers[tierIndex].team > b.tiers[tierIndex].team) {
					return -1;
				}
				if (b.tiers[tierIndex].team > a.tiers[tierIndex].team) {
					return 1;
				}
				return 0;
			}
			if (a.tiers[tierIndex].team > b.tiers[tierIndex].team) {
				return 1;
			}
			if (b.tiers[tierIndex].team > a.tiers[tierIndex].team) {
				return -1;
			}
			return 0;
		});
		setFilteredTeamsRandomization(sortedArray);
	};

	const sort = (label, columnName) => (
		<SortingComponent
			label={label}
			arrayToBeSorted={filteredTeamsRandomization.slice()}
			setArray={setFilteredTeamsRandomization}
			field={columnName}
			changeColorOfColumn
			defaultColumnColor="white"
		/>
	);

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	return (
		<div>
			<ReactTooltip />
			<table className="table-fixed text-left">
				<thead>
					<tr>
						<th className="w-1/12 p-4 text-left text-sm font-medium text-gray-100 bg-djawn ">
							{sort('Slot #', 'slot')}
						</th>
						<th className="w-3/12 p-4 text-left text-sm font-medium text-gray-100 bg-djawn">
							{sort('Name', 'nickname')}
						</th>
						{filteredTeamsRandomization && filteredTeamsRandomization.length > 0
							? filteredTeamsRandomization[0].tiers.map((tier, index) => (
								<th className="w-4/12 xxs:w-3/12 p-4 text-left text-sm font-medium text-gray-100 bg-djawn">
									<div className="flex flex-row">
											Tier
										{' '}
										{tier.tier}
										<div
											className="flex justify-center items-center cursor-pointer hover:bg-opacity-50 ml-1"
											onClick={() => {
												sortByTiers(index);
											}}
											onKeyDown={() => {
												sortByTiers(index);
											}}
										>
											<svg
												className="w-4 h-4"
												fill="none"
												stroke="currentColor"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d={
														sortedAsc
															? 'M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z'
															: 'M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z'
													}
												/>
											</svg>
										</div>
									</div>
								</th>
							  ))
							: null}
						<th className="w-1/12 p-4 text-left text-sm font-medium bg-djawn ">
							{sort('Sort order', 'displayOrder')}
						</th>
					</tr>
				</thead>
				<tbody className="text-gray-800 dark:text-white text-sm">
					{(!filteredTeamsRandomization
						|| !filteredTeamsRandomization.length) && (
						<tr>
							<th colSpan={2}>
								<h4 className="m-4">
									Randomization will occur once all slots are filled
								</h4>
							</th>
						</tr>
					)}
					{filteredTeamsRandomization
						&& filteredTeamsRandomization.length > 0
						&& filteredTeamsRandomization.map((randomization, index) => (
							<tr key={index}>
								<td className="px-4 xxs:px-4 py-2 whitespace-nowrap">
									{randomization.slot}
								</td>
								<td className="px-4 xxs:px-4 py-2">
									<div className="text-left w-24">
										<EllipisWithTooltip
											isParagraph
											placement="bottom"
											className="text-sm font-light leading-relaxed text-left"
											overridenStyle={{
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis'
											}}
										>
											{randomization.nickname}
										</EllipisWithTooltip>
									</div>
								</td>
								{randomization.tiers.map((tier) => (
									<td className="px-4 xxs:px-4 py-2 whitespace-nowrap">
										{tier.team}
									</td>
								))}
								<td className="px-4 xxs:px-4 py-2 whitespace-nowrap">
									{randomization.displayOrder}
								</td>
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
};

export default RandomizedBreaksEventQueue;
