/* eslint-disable no-mixed-operators */
import React, { useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import EllipisWithTooltip from '../ellipsis-tooltip';
import HamburgerMenuIcon from '../../assets/icons/hamburger-menu-icon';
import { SellerEventAttendeeActionsContext } from '../../contexts/seller-event-attendee-actions';
import { LiveEventDataContext } from '../../contexts/live-event-data';
import { TwilioTrackContext } from '../../contexts/twilio-track';
import MicOrRequestIcon from '../mic-or-request-mic-icon/index';

const AttendeesEventQueue = ({ isHost, ownerNickname }) => {
	const { audioParticipants } = useContext(TwilioTrackContext);
	const { setSelectedUserForActions } = useContext(
		SellerEventAttendeeActionsContext
	);
	const { attendees } = useContext(LiveEventDataContext);

	return (
		<div className="">
			<table className="table-fixed text-left w-full">
				<thead>
					<tr>
						<th className="w-7/12 p-4 text-left text-sm font-medium text-gray-100 bg-djawn">
							Screen name
						</th>
						{isHost && (
							<th className="w-5/12 p-4 text-center text-sm font-medium text-gray-100 bg-djawn">
								Actions
							</th>
						)}
					</tr>
				</thead>
				<tbody className="text-gray-800 text-sm dark:text-white dark:bg-darkGray-50">
					{attendees.map((attendee) => (
						<>
							<ReactTooltip />
							<tr key={attendee.nickname}>
								<td className="px-4 xxs:px-4 py-2">
									<div className="flex items-center">
										<p className="text-sm font-light leading-relaxed text-left flex w-10/12">
											<EllipisWithTooltip
												isParagraph
												placement="bottom"
												className="text-sm"
												overridenStyle={{
													whiteSpace: 'nowrap',
													overflow: 'hidden',
													textOverflow: 'ellipsis'
												}}
											>
												{attendee.nickname}
											</EllipisWithTooltip>
										</p>
										<div className="flex w-2/12">
											<MicOrRequestIcon
												attendee={attendee}
												audioParticipants={audioParticipants}
											/>
										</div>
									</div>
								</td>
								{isHost
									&& !attendee.isAnonymous
									&& attendee.nickname != ownerNickname
									&& `${ownerNickname}-2` != attendee.nickname && (
									<td
										className="px-4 py-2 cursor-pointer whitespace-nowrap underline flex justify-center"
										onClick={() => setSelectedUserForActions(attendee)}
										onKeyDown={() => setSelectedUserForActions(attendee)}
									>
										<HamburgerMenuIcon className="w-6 h-6" />
									</td>
								)}
							</tr>
						</>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default AttendeesEventQueue;
