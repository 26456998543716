import React from 'react';
import { connect } from 'react-redux';
import { getEvents } from '../api/event.request';
import Feeds from '../components/feeds';
import {
	COMPLETED_EVENTS,
	DEFAULT_FEEDS_PAGE_SIZE
} from '../utils/listTypeConstants';

const PastEventsPage = ({
	cartItems,
	showMobileSearch,
	dispatch,
	location
}) => (
	<Feeds
		cartItems={cartItems}
		showMobileSearch={showMobileSearch}
		dispatch={dispatch}
		location={location}
		getFeeds={(currentPage, eventType = '', overridenDefaultPageSize = null) => getEvents(
			COMPLETED_EVENTS,
			overridenDefaultPageSize || DEFAULT_FEEDS_PAGE_SIZE,
			currentPage,
			eventType
		)}
	/>
);

export default connect(
	(state) => ({
		cartItems: state.cart.cartItems,
		showMobileSearch: state.utils.showMobileSearch
	}),
	null
)(PastEventsPage);
