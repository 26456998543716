import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { InfoIcon } from '../../components/Event/event-icons';
import useReferenceData from '../../hooks/use-reference-data';
import { PRODUCT_SELECTION_REFERENCE_TYPE_ID } from '../../utils/constants';

const RipNShipProductSelection = ({
	onProductSelectionChange,
	selectedProductSelectionId
}) => {
	const [productSelections, productSelectionsLoaded] = useReferenceData(
		PRODUCT_SELECTION_REFERENCE_TYPE_ID,
		true
	);

	useEffect(() => {
		if (!productSelections) return;
		ReactTooltip.rebuild();
	}, [productSelectionsLoaded]);

	return (
		<div>
			<div className="flex flex-col md:flex-row justify-between mt-5">
				<ReactTooltip />
				<div className="flex justify-start md:justify-center items:start items-center space-x-2">
					<span className="text-lg font-semibold inline-block">
						Product selection *
					</span>
				</div>
			</div>
			<div className="mt-3 flex justify-between md:w-5/12 w-full">
				{productSelections.map((productSelection) => (
					<label className="inline-flex items-center" key={productSelection.id}>
						<input
							type="radio"
							className="form-radio"
							name="shippingType"
							checked={productSelection.id == selectedProductSelectionId}
							onChange={() => onProductSelectionChange(productSelection.id)}
						/>

						<span className="flex text-center justify-start space-x-2 my-2 ml-2">
							<span>{productSelection.value}</span>
							<div data-tip={productSelection.description}>
								<InfoIcon />
							</div>
						</span>
					</label>
				))}
			</div>
		</div>
	);
};

export default RipNShipProductSelection;
