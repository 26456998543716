/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
const isBrowser = typeof window !== 'undefined';

const getUser = () => (window.localStorage.gatsbyUser
	? JSON.parse(window.localStorage.gatsbyUser)
	: {});

const setUser = (user) => (window.localStorage.gatsbyUser = JSON.stringify(user));

export const saveLogin = ({
	name,
	proPic,
	id,
	email,
	token,
	refreshToken,
	userDetails,
	expirationDate,
	firstName,
	lastName
}) => setUser({
	name,
	pic: proPic,
	id,
	email,
	token,
	refreshToken,
	userDetails,
	expirationDate,
	firstName,
	lastName
});

export const setProfileImage = (imageUrl) => {
	const user = getUser();
	user.pic = imageUrl;
	window.localStorage.gatsbyUser = JSON.stringify(user);
};

export const handleLogin = ({
	id,
	email,
	token,
	refreshToken,
	expirationDate
}) => {
	if (!isBrowser) return false;

	const user = getUser();

	if (email === user.email && token === user.token) {
		return setUser({
			name: user.name,
			pic: user.pic,
			id,
			email,
			token,
			refreshToken,
			expirationDate
		});
	}

	return false;
};

export const isLoggedIn = () => {
	if (!isBrowser) return false;

	const user = getUser();
	return !!user.email;
};

export const getCurrentUser = () => isBrowser && getUser();

export const removeUserFromLocalStorage = () => {
	localStorage.removeItem('gatsbyUser');
};

export const logout = () => {
	if (!isBrowser) return;
	setUser({});
};

export const getAccessToken = () => {
	if (!isBrowser) return null;
	const user = getUser();
	return user.token;
};

const getOnlyDate = (date) => {
	const d = new Date(date);
	d.setHours(0, 0, 0, 0);
	return d;
};

export const shouldRedirectToSignIn = () => {
	const user = getCurrentUser();
	if (!user) {
		localStorage.removeItem('gatsbyUser');
		return true;
	}
	if (user.token) {
		if (!user.expirationDate) {
			localStorage.removeItem('gatsbyUser');
			return true;
		}
		const parsedExpirationDate = getOnlyDate(new Date(user.expirationDate));
		const currentDate = getOnlyDate(new Date());
		if (currentDate >= parsedExpirationDate) {
			localStorage.removeItem('gatsbyUser');
			return true;
		}
		return false;
	}
	localStorage.removeItem('gatsbyUser');
	return true;
};

export const refreshGoogleAuthTokenSetup = (res) => {
	const refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

	const refreshToken = async () => {
		const newAuthRes = await res.reloadAuthResponse();
		refreshTiming(newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
		setTimeout(refreshToken, refreshTiming);
	};

	setTimeout(refreshToken, refreshTiming);
};
