/* eslint-disable no-throw-literal */
import { useState } from 'react';
import {
	addEventProducts,
	createRipNShipEvent,
	getCreatedEventBreakTemplates,
	getCreatedEventBreakTemplatesByTiers,
	getEventStepInitials,
	getUserTemplatesByTiers,
	getUserTemplatesByUser,
	saveEventStep,
	saveEventTemplate,
	updateInventory,
	uploadEventImages
} from '../api/event.request';
import {
	ALL_PRODUCTS_PRODUCT_SELECTION_OPTION,
	FREE_SHIPPING_REFERENCE_DATA,
	PICK_YOUR_SLOTS,
	RANDOMIZED,
	RIP_N_SHIP,
	SELECT_PRODUCTS_PRODUCT_SELECTION_OPTION,
	SHIPPING_CARDS_REFERENCE_TYPE,
	SHIPPING_TYPES_REFERENCE_TYPE
} from '../utils/constants';
import { formatDateTimeToUTC } from '../utils/formatter';

import useReferenceData from './use-reference-data';
import useConversation from './useConversation';
import useAsyncEffect from './useAsyncEffect';

const useCreateEvent = () => {
	const { createConversation } = useConversation();
	const [shippingTypes] = useReferenceData(SHIPPING_TYPES_REFERENCE_TYPE);
	const [shippingCards] = useReferenceData(SHIPPING_CARDS_REFERENCE_TYPE);
	const [selectedShippingType, setSelectedShippingType] = useState(null);
	const [selectedShippingCard, setSelectedShippingCard] = useState(null);
	const [selectedProductSelectionId, setSelectedProductSelectionId] = useState(
		SELECT_PRODUCTS_PRODUCT_SELECTION_OPTION
	);
	const [shippingFrequency, setShippingFrequency] = useState('');
	const [fixedShipRate, setFixedShipRate] = useState(0);
	const [loading, setLoading] = useState(true);
	const [eventTypes, setEventTypes] = useState([]);
	const [templateActionType, setTemplateActionType] = useState('');
	const [sports, setSports] = useState([]);
	const [tierTypes, setTierTypes] = useState([]);
	const [activeStep, setActiveStep] = useState(0);
	const [eventName, setEventName] = useState('');
	const [eventDescription, setEventDescription] = useState('');
	const [eventTypeId, setEventTypeId] = useState(0);
	const [templates, setTemplates] = useState([]);
	const [userTemplates, setUserTemplates] = useState([]);
	const [sellerImage, setSellerImage] = useState(null);
	const [files, setFiles] = useState([]);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [costPerSlot, setCostPerSlot] = useState(0);
	const [
		maximumNumberOfSlotsAllowed,
		setMaximumNumberOfSlotsAllowed
	] = useState(0);
	const [
		maximumNumberOfTiersAllowed,
		setMaximumNumberOfTiersAllowed
	] = useState(0);
	const [
		maximumNumberOfSlotsAndTiersAllowed,
		setMaximumNumberOfSlotsAndTiersAllowed
	] = useState(0);
	const [editedTemplate, setEditedTemplate] = useState({
		templateName: '',
		isEdited: false,
		tierSize: 0,
		slotSize: 0,
		sportTypeID: '',
		breakEventTemplateId: '',
		teams: [],
		tiersData: [],
		pickYourSlotCosts: []
	});
	const [eventDate, setEventDate] = useState({
		date: '',
		hour: '',
		minutes: '',
		amOrPm: ''
	});

	const [eventData, setEventData] = useState({
		timezone: '',
		slots: 0,
		pricePerSlot: 0,
		tierSize: 0,
		presetId: 0,
		tierSetupId: 0,
		description: ''
	});
	const [breakTemplate, setBreakTemplate] = useState({
		templateId: '',
		template: {
			sportTypeID: 0
		}
	});

	const getEventInitialData = async () => {
		const initialEventDataRes = await getEventStepInitials();
		return {
			eventTypes: initialEventDataRes.data.eventTypes,
			sports: initialEventDataRes.data.sports,
			tierTypes: initialEventDataRes.data.tierTypes,
			breakLimit: initialEventDataRes.data.breakLimit
		};
	};

	useAsyncEffect(async () => {
		const eventInitialData = await getEventInitialData();
		setEventTypes(eventInitialData.eventTypes);
		setSports(eventInitialData.sports);
		setTierTypes(eventInitialData.tierTypes);
		setMaximumNumberOfSlotsAllowed(
			eventInitialData.breakLimit.maximumNumberOfSlots
		);
		setMaximumNumberOfTiersAllowed(
			eventInitialData.breakLimit.maximumNumberOfTiers
		);
		setMaximumNumberOfSlotsAndTiersAllowed(
			eventInitialData.breakLimit.maximumNumberOfSlotsAndTiers
		);
		setLoading(false);
	}, []);

	const loadTemplates = async () => {
		let templateResult;
		if (eventTypeId == PICK_YOUR_SLOTS) {
			templateResult = await getCreatedEventBreakTemplatesByTiers(1);
		} else {
			templateResult = await getCreatedEventBreakTemplates();
		}

		if (templateResult.data && templateResult.data.length) {
			setTemplates(templateResult.data);
		}
	};

	const loadUserTemplates = async () => {
		let templateResult;
		if (eventTypeId == PICK_YOUR_SLOTS) {
			templateResult = await getUserTemplatesByTiers(1);
		} else {
			templateResult = await getUserTemplatesByUser();
		}

		if (templateResult.data) {
			setUserTemplates(templateResult.data.templates);
			setSellerImage(templateResult.data.sellerImage);
		}
	};

	const validateRipNShipValues = () => {
		const ripnShipStepValues = {
			shippingTypeId: selectedShippingType,
			fixedShipRates: fixedShipRate,
			shippingFrequency,
			shipAllCardsId: selectedShippingCard,
			productSelectionId: selectedProductSelectionId
		};

		if (
			ripnShipStepValues.shippingTypeId <= 0
			|| ripnShipStepValues.shipAllCardsId <= 0
			|| ripnShipStepValues.fixedShipRates < 0
			|| !ripnShipStepValues.shippingFrequency
			|| ripnShipStepValues.shippingFrequency.length <= 0
		) {
			throw 'Please fill all the fields or validate.';
		}

		if (
			ripnShipStepValues.fixedShipRates <= 0
			&& ripnShipStepValues.shippingTypeId != FREE_SHIPPING_REFERENCE_DATA
		) {
			throw 'Shipping Price cannot be 0, if you want to create with free shipping please change the Shipping Type to "Free Shipping"';
		}

		return ripnShipStepValues;
	};

	const validateBreakProductsQuantity = () => {
		const productsWithNoQuantitySet = selectedProducts.filter(
			(p) => p.quantity == 0 || p.quantity == ''
		);
		if (productsWithNoQuantitySet.length > 0) throw 'Please enter quantity for selected products.';

		const productsWithBiggerQuantityThanAvailableStock = selectedProducts.filter(
			(p) => p.quantity > p.product.totalAvailableStock
		);

		if (productsWithBiggerQuantityThanAvailableStock.length > 0) throw 'Sorry the amount you are trying to reserve is greater than the available stock for the product.  Please reduce the quantity you are trying to reserve and try again.';
	};

	const saveUpdatedBreakTemplate = async () => {
		let template = null;
		let breakTemplateId = 0;
		if (eventTypeId == RANDOMIZED) {
			if (templateActionType == 'template') {
				if (editedTemplate.customTemplateName) {
					template = {
						templateName: editedTemplate.templateName,
						tierSize: editedTemplate.tierSize,
						slotSize: editedTemplate.slotSize,
						sportTypeID: editedTemplate.sportTypeID,
						teams: editedTemplate.teams
					};
				} else {
					breakTemplateId = breakTemplate.templateId;
				}
			} else {
				template = {
					templateName: editedTemplate.templateName,
					tierSize: editedTemplate.tierSize,
					slotSize: editedTemplate.slotSize,
					sportTypeID: editedTemplate.sportTypeID,
					teams: editedTemplate.tiersData
				};
			}
		} else if (eventTypeId == PICK_YOUR_SLOTS) {
			if (templateActionType == 'template') {
				if (editedTemplate.customTemplateName) {
					template = {
						templateName: editedTemplate.templateName,
						tierSize: editedTemplate.tierSize,
						slotSize: editedTemplate.slotSize,
						sportTypeID: editedTemplate.sportTypeID,
						teams: editedTemplate.teams,
						pickYourSlotCost: editedTemplate.pickYourSlotCosts
					};
				} else {
					breakTemplateId = breakTemplate.templateId;
				}
			} else {
				template = {
					templateName: editedTemplate.templateName,
					tierSize: editedTemplate.tierSize,
					slotSize: editedTemplate.slotSize,
					sportTypeID: editedTemplate.sportTypeID,
					teams: editedTemplate.templateTiersData,
					pickYourSlotCost: editedTemplate.pickYourSlotCosts
				};
			}
		}

		if (breakTemplateId == 0) {
			const savedEventTemplateResponse = await saveEventTemplate(template);
			breakTemplateId = savedEventTemplateResponse.data.breakTemplateId;
		}

		return breakTemplateId;
	};

	const createEvent = async (description) => {
		let ripnShipStepValues;
		if (eventTypeId == RIP_N_SHIP) {
			ripnShipStepValues = validateRipNShipValues();
		} else if (eventTypeId == RANDOMIZED) {
			validateBreakProductsQuantity();
		}

		const newCreateRequest = {
			eventName,
			eventTypeId,
			eventDate: formatDateTimeToUTC(
				eventDate.date,
				eventDate.hour,
				eventDate.minutes,
				eventDate.amOrPm
			),
			timezone: 'GMT',
			description: description || eventDescription || ''
		};

		if (eventTypeId != RIP_N_SHIP) {
			// TODO: This might be off
			newCreateRequest.breakTemplateId = await saveUpdatedBreakTemplate();
			if (
				eventTypeId == PICK_YOUR_SLOTS
				&& editedTemplate.pickYourSlotCosts.length
			) {
				newCreateRequest.slots = editedTemplate.pickYourSlotCosts.length;
			} else {
				newCreateRequest.slots =					templateActionType == 'template'
					? editedTemplate.slotSize
					: breakTemplate.template.slotSize;
			}
			newCreateRequest.pricePerSlot =				templateActionType == 'template' ? costPerSlot : costPerSlot;
			newCreateRequest.tierSize = breakTemplate.template.tierSize;
			newCreateRequest.pickYourSlotCost =				eventTypeId == PICK_YOUR_SLOTS
				? editedTemplate.pickYourSlotCosts
				: null;
		}

		const areProductsValid =			selectedProductSelectionId == ALL_PRODUCTS_PRODUCT_SELECTION_OPTION
			? true
			: selectedProducts.length;

		if (
			!areProductsValid
			|| !files.length
			|| !newCreateRequest.description.length
		) {
			throw 'Please select product, description & images.';
		}

		const savedEventResponse = await saveEventStep(newCreateRequest);

		if (eventTypeId == RIP_N_SHIP) {
			let productIds = [];
			if (selectedProductSelectionId != ALL_PRODUCTS_PRODUCT_SELECTION_OPTION) {
				productIds = selectedProducts.map((p) => p.product.productId);
			}
			await addEventProducts(
				savedEventResponse.data.eventId,
				productIds,
				selectedProductSelectionId
			);
			await createRipNShipEvent(
				ripnShipStepValues,
				savedEventResponse.data.eventId
			);
		} else {
			const currentSelectedProducts = [...selectedProducts].map((product) => ({
				productId: product.product.productId,
				quantity: product.quantity
			}));
			await updateInventory(
				savedEventResponse.data.eventId,
				currentSelectedProducts
			);

			createConversation(
				eventName,
				savedEventResponse.data.sellerId,
				true,
				false,
				{ event_id: savedEventResponse.data.eventId },
				false
			);
		}

		await uploadEventImages(files, savedEventResponse.data.eventId);

		return savedEventResponse.data.eventId;
	};

	return {
		eventData,
		eventTypes,
		sports,
		tierTypes,
		loading,
		setLoading,
		activeStep,
		setActiveStep,
		eventName,
		setEventName,
		eventTypeId,
		setEventTypeId,
		eventDate,
		setEventDate,
		setEventData,
		loadTemplates,
		loadUserTemplates,
		templates,
		userTemplates,
		sellerImage,
		breakTemplate,
		setBreakTemplate,
		templateActionType,
		setTemplateActionType,
		eventDescription,
		setEventDescription,
		files,
		setFiles,
		selectedProducts,
		setSelectedProducts,
		shippingTypes,
		shippingCards,
		selectedShippingType,
		setSelectedShippingType,
		selectedShippingCard,
		setSelectedShippingCard,
		fixedShipRate,
		setFixedShipRate,
		shippingFrequency,
		setShippingFrequency,
		createEvent,
		editedTemplate,
		setEditedTemplate,
		costPerSlot,
		setCostPerSlot,
		selectedProductSelectionId,
		setSelectedProductSelectionId,
		maximumNumberOfSlotsAllowed,
		maximumNumberOfTiersAllowed,
		maximumNumberOfSlotsAndTiersAllowed
	};
};

export default useCreateEvent;
