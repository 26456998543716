import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import stripeLogo from '../images/stripe_logo.png';

const StripeMissingInformationModal = ({ showModal, setShowModal, url }) => {
	const cancelButtonRef = useRef();

	return (
		<Transition.Root show={showModal} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-50 inset-0 overflow-y-auto"
				initialFocus={cancelButtonRef}
				open={showModal}
				onClose={() => setShowModal(false)}
			>
				<div className="flex items-center md:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay
							className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
							style={{ zIndex: '1040!important' }}
						/>
					</Transition.Child>

					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						style={{ zIndex: '1050!important' }}
					>
						<div className="inline-block fixed md:relative bg-opacity-100 align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
							<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative">
								<div className="flex items-center justify-center">
									<div className="mt-3 text-center justify-center sm:mt-0 sm:ml-4 sm:text-left">
										<div className="flex flex-col items-center justify-center">
											<div className="sm:col-auto md:col-span-1 lg:col-span-1 xl:col-s store-logo-info mt-10">
												<div className="flex flex-col items-center mt-1 relative sm:w-full md:w-full lg:w-full xl:w-full">
													<img
														alt="stripe"
														src={stripeLogo}
														width={200}
														height={100}
													/>
													<a
														href={url}
														className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
													>
														Stripe information is missing, please go to the
														following link for more details and finish the
														required information.
													</a>
												</div>
												<div className="flex mt-8 justify-center">
													<div className="sm:text-center store-next-button">
														<button
															type="button"
															className="min-w-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-10 rounded-md "
															onClick={() => setShowModal(false)}
														>
															Close
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<button
									type="button"
									ref={cancelButtonRef}
									className="absolute px-3 pt-3 text-gray-500 top-0 right-0 cursor-pointer outline-none border-none focus:outline-none"
									onClick={() => setShowModal(false)}
								>
									X
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default StripeMissingInformationModal;
