/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import { Stepper, Step } from 'react-form-stepper';
import { ToastContainer, toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import StoreInformation from './store-information';
import GeneralQuestions from './general-questions';
import Cards from './cards';
import ShippingAndPayments from './shipping-payments';
import Submit from './submit';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const CreateSellerStepper = ({ trackingId, paypalMerchantId }) => {
	const [loading, setLoading] = useState(true);
	const [stepContainers, setStepContainers] = useState(null);

	const getInitialData = async () => {
		setLoading(false);
	};

	useEffect(() => {
		getInitialData();
	}, []);

	useEffect(() => {
		if (loading || !isMobile) return;
		setStepContainers(document.querySelectorAll('.RFS-StepContainer'));
	}, [loading, isMobile]);
	const [activeStep, setActiveStep] = useState(0);
	const [step1, setStep1] = useState(true);
	const [step2, setStep2] = useState(false);
	const [step3, setStep3] = useState(false);
	const [step4, setStep4] = useState(false);
	const [step5, setStep5] = useState(false);
	const [, setStep6] = useState(false);

	const [step1Data, setStep1Data] = useState({
		storeName: '',
		email: '',
		phoneNumber: '',
		addressLine1: '',
		apartmentSuite: '',
		city: '',
		state: '',
		postalCode: '',
		storeLogo: '',
		storeLogoName: ''
	});
	const [step2Data, setStep2Data] = useState({
		plateform: '',
		scammer: '',
		references: '',
		plateformText: '',
		scammerText: '',
		referencesText: '',
		plateformQuestionData: '',
		scammerQuestionData: '',
		referencesQuestionData: ''
	});
	const [step3Data, setStep3Data] = useState({
		cardData: [],
		otherCardDetail: '',
		favouriteBrand: ''
	});
	const [step4Data, setStep4Data] = useState({
		shipCardValue: '',
		frequencyShipping: '',
		shippingType: '',
		rate: '',
		paymentUserName: {}
	});
	const [step5Data, setStep5Data] = useState({
		termCondition: '',
		sellerPolicy: ''
	});
	const [sellerData, setSellerData] = useState({
		storeName: '',
		email: '',
		phoneNumber: '',
		addressLine1: '',
		apartmentSuite: '',
		city: '',
		state: '',
		postalCode: '',
		storeLogo: '',
		storeLogoName: '',
		plateform: '',
		scammer: '',
		references: '',
		cardData: [],
		otherCardDetail: '',
		favouriteBrand: '',
		shipCardValue: '',
		shipCardText: '',
		frequencyShipping: '',
		shippingType: '',
		shippingTypeText: '',
		rate: '',
		paymentUserName: []
	});

	const goToStep = (value) => {
		if (value === 1) {
			setActiveStep(0);
			setStep1(true);
			setStep2(false);
			setStep3(false);
			setStep4(false);
			setStep5(false);
		}
		if (value === 2) {
			setActiveStep(1);
			setStep1(false);
			setStep2(true);
			setStep3(false);
			setStep4(false);
			setStep5(false);
			setStep6(false);
		}
		if (value === 3) {
			setActiveStep(2);
			setStep1(false);
			setStep2(false);
			setStep3(true);
			setStep4(false);
			setStep5(false);
			setStep6(false);
		}
		if (value === 4) {
			setActiveStep(3);
			setStep1(false);
			setStep2(false);
			setStep3(false);
			setStep4(true);
			setStep5(false);
			setStep6(false);
		}
		if (value === 5) {
			setActiveStep(4);
			setStep1(false);
			setStep2(false);
			setStep3(false);
			setStep4(false);
			setStep5(true);
			setStep6(false);
		}
	};

	useEffect(() => {
		if (!isMobile) return;
		if (stepContainers) {
			stepContainers.forEach((stepContainer, index) => {
				if (
					index == activeStep
					|| index == activeStep - 1
					|| index == activeStep + 1
				) {
					stepContainer.style.display = null;
					if (index == activeStep - 1) {
						if (
							stepContainer.firstChild.classList[0] == 'RFS-ConnectorContainer'
						) {
							stepContainer.firstChild.style.display = 'none';
						}
					}
				} else {
					stepContainer.style.display = 'none';
				}
			});
		}
	}, [stepContainers, activeStep, isMobile]);

	const step1Complete = (value) => {
		setStep1Data(value);
		setActiveStep(1);
		setStep1(false);
		setStep2(true);
		setStep3(false);
		setStep4(false);
		window.localStorage.sellerData = JSON.stringify({
			step1: {},
			step2: {},
			step3: {},
			step4: {},
			step5: {}
		});
		const formData = window.localStorage.sellerData
			? JSON.parse(window.localStorage.sellerData)
			: {
				step1: {},
				step2: {},
				step3: {},
				step4: {},
				step5: {}
			  };
		formData.step1 = value;
		window.localStorage.sellerData = JSON.stringify(formData);
	};

	const step2Complete = (value) => {
		setStep2Data(value || '');
		setActiveStep(2);
		setStep2(false);
		setStep3(true);
		setStep4(false);
		setStep5(false);
		const formData = window.localStorage.sellerData
			? JSON.parse(window.localStorage.sellerData)
			: {
				step1: {},
				step2: {},
				step3: {},
				step4: {},
				step5: {}
			  };
		formData.step2 = value;
		window.localStorage.sellerData = JSON.stringify(formData);
	};

	const step3Complete = (value) => {
		setStep3Data(value);
		setActiveStep(3);
		setStep3(false);
		setStep4(true);
		setStep5(false);
		const formData = window.localStorage.sellerData
			? JSON.parse(window.localStorage.sellerData)
			: {
				step1: {},
				step2: {},
				step3: {},
				step4: {},
				step5: {}
			  };
		formData.step3 = value;
		window.localStorage.sellerData = JSON.stringify(formData);
	};

	const step4Complete = (value) => {
		const formData = {
			storeName: step1.storeName,
			email: step1.email,
			phoneNumber: step1.phoneNumber,
			addressLine1: step1.addressLine1,
			apartmentSuite: step1.apartmentSuite,
			city: step1.city,
			state: step1.state,
			postalCode: step1.postalCode,
			storeLogo: step1.storeLogo,
			storeLogoName: step1.storeLogoName,
			plateform: step2.plateform,
			scammer: step2.scammer,
			references: step2.references,
			cardData: step3.cardData,
			otherCardDetail: step3.otherCardDetail,
			favouriteBrand: step3.favouriteBrand,
			shipCardValue: step4.shipCardValue,
			frequencyShipping: step4.frequencyShipping,
			shippingType: step4.shippingType,
			rate: step4.rate,
			paymentUserName: step4.paymentUserName,
			shippingTypeText: step4.shippingTypeText,
			shippingCardText: step4.shippingCardText
		};
		setSellerData(formData);
		setStep4Data(value);
		setActiveStep(5);
		setStep4(false);
		setStep5(true);
		setStep6(false);
		const formDataLocalStorage = window.localStorage.sellerData
			? JSON.parse(window.localStorage.sellerData)
			: {
				step1: {},
				step2: {},
				step3: {},
				step4: {},
				step5: {}
			  };
		formDataLocalStorage.step4 = value;
		window.localStorage.sellerData = JSON.stringify(formDataLocalStorage);
	};

	const step5Complete = (value) => {
		setStep5Data(value);
		setActiveStep(5);
		setStep4(false);
		setStep5(true);
		const formData = window.localStorage.sellerData
			? JSON.parse(window.localStorage.sellerData)
			: {
				step1: {},
				step2: {},
				step3: {},
				step4: {},
				step5: {}
			  };
		formData.step5 = value;
		window.localStorage.sellerData = JSON.stringify(formData);
	};

	const showToast = (message) => {
		toast.error(message, {
			position: 'top-center',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	};

	if (loading) {
		return (
			<div className="w-screen h-screen bg-white absolute left-0 top-0">
				<div className="flex h-full items-center">
					<BounceLoader loading css={override} size={30} />
				</div>
			</div>
		);
	}
	return (
		<div className="flex flex-col flex-grow w-full bg-gray-100 lg:p-9 sm:p-5">
			<div className="flex flex-col flex-grow w-11/12 h-auto parent bg-white border rounded-lg mx-auto">
				<ToastContainer />

				<div className="flex flex-grow md:px-12 lg:px-12 xl:px-12 p-0 cursor-default">
					<div
						style={{ cursor: 'default' }}
						className="flex flex-row justify-center lg:w-full mx-auto mt-8 h-auto sm:w-80"
					>
						<Stepper
							activeStep={activeStep}
							style={{ cursor: 'default' }}
							className="sm:w-80 lg:w-full "
						>
							<Step
								style={{ cursor: 'default' }}
								label="Store information"
								// className="w-full h-12 p-2 mx-2 font-semibold text-lg rounded-full border-2 border-gray-600  focus:outline-none cursor-default"
								step
							/>
							<Step
								// className="w-full h-12 p-2 mx-2 font-semibold text-lg rounded-full border-2 border-gray-600  focus:outline-none cursor-default"
								style={{ cursor: 'default' }}
								label="General questions"
							/>
							<Step
								style={{ cursor: 'default' }}
								label="Cards"
								// className="w-full h-12 p-2 mx-2 font-semibold text-lg rounded-full border-2 border-gray-600  focus:outline-none cursor-default"
							/>
							<Step
								// className="w-full h-12 p-2 mx-2 font-semibold text-lg rounded-full border-2 border-gray-600  focus:outline-none cursor-default"
								style={{ cursor: 'default' }}
								label="Shipping"
							/>
							<Step
								// className="w-full h-12 p-2 mx-2 font-semibold  rounded-full border-2 border-gray-600  focus:outline-none cursor-default"
								style={{ cursor: 'default' }}
								label="Submit"
							/>
						</Stepper>
					</div>
				</div>

				<div className="flex flex-grow px-12 p-8 pt-0 h-full justify-center items-center w-full md:w-3/4 mx-auto">
					{!loading && (
						<div className="w-full h-full mx-auto">
							{/* {(step1) && (
									<SellerMerchantOnBoard onNext={(value) => goToStep(2)} url={merchantOnBoardUrl} isMerchantOnBoard={isMerchantOnBoard} />
								)} */}
							{step1 && (
								<StoreInformation
									stepData={step1Data}
									onNext={(value) => step1Complete(value)}
									triggerError={(value) => showToast(value)}
									// onPrev={(value) => goToStep(2)}
								/>
							)}
							{step2 && (
								<GeneralQuestions
									stepData={step2Data}
									onPrev={(form2Values) => {
										setStep2Data(form2Values);
										goToStep(1);
									}}
									onNext={(value) => step2Complete(value)}
									triggerError={(value) => showToast(value)}
								/>
							)}
							{step3 && (
								<Cards
									stepData={step3Data}
									onPrev={() => goToStep(2)}
									onNext={(value) => step3Complete(value)}
									triggerError={(value) => showToast(value)}
								/>
							)}
							{step4 && (
								<ShippingAndPayments
									stepData={step4Data}
									onPrev={() => goToStep(3)}
									onNext={(value) => step4Complete(value)}
									triggerError={(value) => showToast(value)}
								/>
							)}
							{step5 && (
								<Submit
									sellerData={sellerData}
									paypalMerchantId={paypalMerchantId}
									paypalTrackingId={trackingId}
									stepData={step5Data}
									onPrev={() => goToStep(4)}
									onNext={(value) => step5Complete(value)}
									triggerError={(value) => showToast(value)}
								/>
							)}
						</div>
					)}
				</div>
				{loading && (
					<div className="w-screen h-screen bg-white absolute left-0 top-0">
						<div className="flex h-full items-center">
							<BounceLoader loading css={override} size={30} />
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default CreateSellerStepper;
