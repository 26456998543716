import React from 'react';
import OrderDetails from '../../components/order/order-details';

const SalesDetailsPage = ({ params }) => (
	<OrderDetails
		orderId={params.orderId}
		showCustomer
		useSalesList
		showStripeFee
	/>
);

export default SalesDetailsPage;
