import React, { useState, useEffect, useRef } from 'react';
import { Stepper, Step } from 'react-form-stepper';
import CheckoutOrder from './checkout-order';
import CheckoutShipping from './checkout-shipping';
import CheckoutPayment from './checkout-payment';
import { loadProfileData } from '../../api/auth.request';

const Checkout = ({
	toggleMiniCart,
	items,
	setShowCheckout,
	totalPrice,
	timerObject,
	removeItemDispatch,
	productName,
	paymentOrder,
	removeItemsFromOrder,
	onPurchased,
	showThankYou,
	onCheckout,
	stripeClientSecret,
	transferGroup,
	setStripeClientSecret,
	setTransferGroup,
	chargeId,
	checkoutUrl,
	setCheckoutUrl,
	showStep3,
	liveEvent
}) => {
	const addressRef = useRef();
	const [activeStep, setActiveStep] = useState(0);
	const [mailingAddress1, setMailingAddress1] = useState('');
	const [mailingAddress2, setMailingAddress2] = useState('');
	const [mailingCity, setMailingCity] = useState('');
	const [mailingState, setMailingState] = useState('');
	const [mailingZip, setMailingZip] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [nickname, setNickname] = useState('');
	const [name, setName] = useState('');
	const [googleMapsEnabled, setGoogleMapsEnabled] = useState(true);
	const [initialAddress, setInitialAddress] = useState(null);
	const [initialState, setInitialState] = useState(null);
	const [initialZip, setInitialZip] = useState(null);
	const [initialCity, setInitialCity] = useState(null);
	const [initialFirstName, setInitialFirstName] = useState(null);
	const [initialLastName, setInitialLastName] = useState(null);
	const [initialMailingAddress2, setInitialMailingAddress2] = useState(null);

	const onCloseCheckout = () => {
		toggleMiniCart();
		setStripeClientSecret(null);
		setTransferGroup(null);
		setActiveStep(0);
	};

	useEffect(() => {
		if (showStep3 == true) {
			setActiveStep(2);
		}
	}, [showStep3]);

	window.gm_authFailure = () => {
		setGoogleMapsEnabled(false);
	};

	useEffect(() => {
		let headers = null;
		if (window) {
			if (window.localStorage) {
				if (window.localStorage.gatsbyUser) {
					const user = JSON.parse(window.localStorage.gatsbyUser);
					headers = {
						headers: { Authorization: `Bearer ${user.token}` }
					};
				}
			}
		}

		loadProfileData(headers).then((res) => {
			const profile = res.data;
			setNickname(profile.nickName);
			setMailingAddress1(profile.mailingAddress1);
			setMailingState(profile.mailingState);
			setMailingZip(profile.mailingZip);
			setMailingCity(profile.mailingCity);
			setFirstName(profile.firstName);
			setLastName(profile.lastName);
			setMailingAddress2(profile.mailingAddress2);
			if (addressRef && addressRef.current) {
				addressRef.current.value = profile.mailingAddress1;
			}
			if (!initialAddress) {
				setInitialAddress(profile.mailingAddress1);
				setInitialState(profile.mailingState);
				setInitialZip(profile.mailingZip);
				setInitialCity(profile.mailingCity);
				setInitialFirstName(profile.firstName);
				setInitialLastName(profile.lastName);
				setInitialMailingAddress2(profile.mailingAddress2);
			}
		});
	}, []);

	return (
		<>
			<div className="flex flex-row px-2 py-2">
				<div className="flex flex-grow items-center border-b-2 pb-3">
					<p className="text-xl align-middle leading-4 px-5">
						Checkout - Order
					</p>
				</div>
				<div className="flex flex-none text-white">
					<button
						type="button"
						className="w-8 h-8 focus:outline-none text-white hover:text-gray-500 text-sm p-1 rounded-sm hover:bg-white"
						onClick={() => toggleMiniCart()}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
						>
							<path
								fillRule="evenodd"
								d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
								clipRule="evenodd"
							/>
						</svg>
					</button>
				</div>
			</div>
			<div className="flex flex-row justify-center w-full mx-auto h-auto dark:text-white">
				<Stepper activeStep={activeStep} className="w-8/12">
					<Step
						label="Order"
						className="p-2 mx-2 font-semibold text-lg rounded-full border-2 border-gray-600 hover:bg-gray-200 focus:outline-none"
					/>
					<Step label="Shipping" className="" />
					<Step label="Pay" className="" />
				</Stepper>
			</div>
			{activeStep == 0 && (
				<CheckoutOrder
					items={items}
					setShowCheckout={setShowCheckout}
					setActiveStep={setActiveStep}
					paymentOrder={paymentOrder}
					removeItemsFromOrder={removeItemsFromOrder}
					closeCheckout={onCloseCheckout}
				/>
			)}
			{activeStep == 1 && (
				<CheckoutShipping
					setActiveStep={setActiveStep}
					closeCheckout={onCloseCheckout}
					address={mailingAddress1}
					googleMapsEnabled={googleMapsEnabled}
					setAddress={setMailingAddress1}
					city={mailingCity}
					setCity={setMailingCity}
					zip={mailingZip}
					setZip={setMailingZip}
					firstName={firstName}
					setFirstName={setFirstName}
					lastName={lastName}
					setLastName={setLastName}
					state={mailingState}
					setState={setMailingState}
					mailingAddress2={mailingAddress2}
					setMailingAddress2={setMailingAddress2}
					nickname={nickname}
					name={name}
					setName={setName}
					initialAddress={initialAddress}
					initialCity={initialCity}
					initialZip={initialZip}
					initialState={initialState}
					initialMailingAddress2={initialMailingAddress2}
					initialFirstName={initialFirstName}
					initialLastName={initialLastName}
				/>
			)}

			{activeStep == 2 && (
				<CheckoutPayment
					setActiveStep={setActiveStep}
					closeCheckout={onCloseCheckout}
					totalPrice={totalPrice}
					timerObject={timerObject}
					items={items}
					removeItemDispatch={removeItemDispatch}
					paymentOrder={paymentOrder}
					productName={productName}
					onPurchased={onPurchased}
					showThankYou={showThankYou}
					mailingAddress1={mailingAddress1}
					mailingAddress2={mailingAddress2}
					mailingCity={mailingCity}
					mailingZip={mailingZip}
					mailingState={mailingState}
					firstName={firstName}
					lastName={lastName}
					onCheckout={onCheckout}
					setShowCheckout={setShowCheckout}
					removeItemsFromOrder={removeItemsFromOrder}
					stripeClientSecret={stripeClientSecret}
					transferGroup={transferGroup}
					chargeId={chargeId}
					checkoutUrl={checkoutUrl}
					setCheckoutUrl={setCheckoutUrl}
					liveEvent={liveEvent}
				/>
			)}
		</>
	);
};

export default Checkout;
