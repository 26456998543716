import React from 'react';
import Button from '.';

const NextButton = ({ onClick, overridenText = 'NEXT', disabled = false }) => (
	<Button
		type="secondary"
		width="auto"
		height="auto"
		mobileHeight="auto"
		mobileWidth="auto"
		textSize="md"
		disabled={disabled}
		additionalClassName="py-2 px-6 font-bold shadow-md"
		onClick={onClick}
	>
		{overridenText}
	</Button>
);

export default NextButton;
