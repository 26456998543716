import React, { useState } from 'react';

const Accordion = ({ children, title, showAccordion = false }) => {
	const [openAccordion, setOpenAccordion] = useState(showAccordion);

	return (
		<div className="flex flex-col w-full items-start">
			<div className="flex flex-wrap justify-center w-full">
				<button
					type="button"
					className="w-full flex flex-row items-center justify-between text-left text-gray-100 text-sm font-medium py-2 px-3 rounded-md shadow-sm bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-none"
					onClick={() => setOpenAccordion(!openAccordion)}
				>
					<span className="flex text-gray-100 font-bold text-base tracking-wide">
						{title}
					</span>
					<span className="flex w-5 h-5">
						{openAccordion ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M5 15l7-7 7 7"
								/>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M19 9l-7 7-7-7"
								/>
							</svg>
						)}
					</span>
				</button>
			</div>
			<div
				className={`flex flex-row justify-center xxs:justify-start w-full overflow-x-auto transform ${
					openAccordion
						? 'h-auto ease-in transition border rounded-md shadow-sm'
						: 'h-0 ease-out transition'
				}`}
			>
				{children}
			</div>
		</div>
	);
};

export default Accordion;
