/* eslint-disable no-nested-ternary */
/* eslint-disable array-bracket-spacing */
import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import Moment from 'moment';
import * as html2pdf from 'html2pdf.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import BounceLoader from 'react-spinners/BounceLoader';
import { useQueryParam, StringParam } from 'use-query-params';
import ReactTooltip from 'react-tooltip';
import InfiniteScroll from 'react-infinite-scroll-component';
import { css } from '@emotion/core';
import Layout from '../layouts/layout-default';
import SEO from '../seo';
import Sidebar from '../common/sidebar';
import HeaderHome from '../common/header-home';
import { reserveProduct } from '../../api/product.request';

import {
	sendEventReportEmail,
	stopEventRoom,
	getEventFeed
} from '../../api/event.request';
import {
	elligibleForSellerApplication,
	getStripeMerchantOnBoard,
	getUnauthorizedSellerError,
	showOnBoardProcess,
	updatePaypalSeller,
	updateStripeAccountId
} from '../../api/seller.request';
import { getTimezoneName } from '../../utils/formatter';
import BecomeSellerImage from '../../images/djawn_banner.png';
import WelcomeToDjawnImage from '../../images/welcome-to-djawn-banner.png';
import { createHubConnection } from '../../utils/hubConnection';
import MiniCart from '../common/mini-cart';
import {
	addCartItem,
	removeCartItem,
	updateCartItemTimer
} from '../../state/action';
import { defaultToastError } from '../../utils/toast-error';
import AvailableSlots from '../common/available-slots';
import FeedButtons from '../common/feed-buttons';
import StoreModal from '../common/store-modal';
import RoomPolicyConfirmation from '../room-policy-confirmation';

import SellerOnBoardModal from '../seller-onboard-modal';
import StripeMissingInformationModal from '../stripe-missing-information-modal';
import { redirectToDefaultBrowser } from '../../utils/redirect';
import EllipisWithTooltip from '../ellipsis-tooltip';
import { isLoggedIn } from '../../utils/auth';
import Loader from '../loader/index';
import { DEFAULT_FEEDS_PAGE_SIZE } from '../../utils/listTypeConstants';
import useScreenOrientation from '../../hooks/useScreenOrientation';
import useAsyncState from '../../hooks/useAsyncState';
import useBooleanQueryParam from '../../hooks/useBooleanQueryParam';

const browser = typeof window !== 'undefined' && window;

const showError = (message) => {
	toast.error(message, {
		position: 'top-center',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'colored'
	});
};

const Feeds = ({
	cartItems,
	showMobileSearch,
	dispatch,
	location,
	getFeeds,
	showBanner = false
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [currentPage, setCurrentPage] = useAsyncState(0);
	const [loaded, setLoaded] = useState(false);
	const [feedData, setFeedData] = useState([]);
	const [hubConnection, setHubConnection] = useState(null);
	const [isCartOpen, setIsCartOpen] = useState(false);
	const [breaksSelected, setBreaksSelected] = useState(false);
	const [ripNShipSelected, setRipNShipSelected] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [isStoreModalOpen, setIsStoreModalOpen] = useState(false);
	const [storeInfo, setStoreInfo] = useState(null);
	const [sellerImage, setSellerImage] = useState(null);
	const [serverUnavailable, setServerUnavailable] = useState(false);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [feedsLoading, setFeedsLoading] = useState(false);
	const [seenToastError, setSeenToastError] = useState(false);
	const [showPolicyModal, setShowPolicyModal] = useState(false);
	const [selectedEventId, setSelectedEventId] = useState(null);
	const [showOnBoardProcessModal, setShowOnBoardProcessModal] = useState(false);
	const [onboardingUrl, setOnboardingUrl] = useState(null);
	const [merchantId] = useQueryParam('merchantId', StringParam);
	const [pageLoading, setPageLoading] = useState(false);
	const [refresh] = useQueryParam('refresh', StringParam);
	const [paypalMerchantId] = useQueryParam('merchantIdInPayPal', StringParam);
	const [openCart] = useBooleanQueryParam('openCart');
	const [hideBanner, setHideBanner] = useState(true);
	const [showSellerOnBoardModal, setShowSellerOnBoardModal] = useState(false);
	const [stripeDashboardLoginLink, setStripeDashboardLoginLink] = useState(
		null
	);
	const [showStripeDashboardModal, setShowStripeDashboardModal] = useState(
		false
	);
	const [redirectToStep3, setRedirectToStep3] = useState(false);
	const [, setItemsLoaded] = useState(false);
	const [wsMessage, setWsMessage] = useState(null);
	const { isLandscape } = useScreenOrientation();
	const [filteredFeeds, setFilteredFeeds] = useState([]);

	const uniqueFeeds = (list) => {
		const resArr = [];
		[...list].forEach((item) => {
			const i = resArr.findIndex((x) => x.eventId == item.eventId);
			if (i <= -1) {
				resArr.push(item);
			}
		});
		return resArr;
	};

	useEffect(() => {
		let feeds = [...feedData];
		if (searchValue) {
			feeds = feeds.filter(
				(f) => f.eventName.toLowerCase().includes(searchValue)
					|| (f.storeName && f.storeName.toLowerCase().includes(searchValue))
					|| (f.products.length
						&& f.products.some((p) => p.toLowerCase().includes(searchValue)))
					|| (f.tags.length
						&& f.tags.some((p) => p.toLowerCase().includes(searchValue)))
			);
		}
		setFilteredFeeds(uniqueFeeds(feeds));
	}, [feedData, searchValue]);

	useEffect(() => {
		redirectToDefaultBrowser();
	}, [window]);

	useEffect(() => {
		if (openCart === true) {
			setIsCartOpen(true);
		}
	}, [openCart]);

	const loadFeeds = (
		eventType = 'all',
		overridenPage = 0,
		overridePage = false,
		searchAll = false
	) => {
		const page = searchAll ? 0 : overridePage ? overridenPage : currentPage;
		const searchAllPage = 250;
		if (loaded) {
			setFeedsLoading(true);
		}
		return getFeeds(
			page,
			eventType,
			searchAll || searchValue ? searchAllPage : null
		)
			.then((res) => {
				if (res && res.data) {
					if (
						res.data.length < searchAll
							? searchAllPage
							: DEFAULT_FEEDS_PAGE_SIZE
					) {
						setHasMoreData(false);
					}
					const feeds = page == 0 ? res.data : [...feedData].concat(res.data);
					setFeedData(feeds);
					setCurrentPage(page + 1);
					setServerUnavailable(false);
					return feeds;
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.finally((feeds) => {
				setLoaded(true);
				setFeedsLoading(false);
				return feeds;
			});
	};

	const onSearch = (value) => {
		setLoaded(false);
		loadFeeds(
			ripNShipSelected ? 'ripnship' : breaksSelected ? 'break' : 'all',
			0,
			true,
			true
		);
		setSearchValue(value);
		// setSearchValue(value);

		// if (!value) value = '';
		// value = value.toLowerCase();

		// const filter = [...filterList(true)].filter(
		// 	(f) => f.eventName.toLowerCase().includes(value)
		// 		|| (f.storeName && f.storeName.toLowerCase().includes(value))
		// 		|| (f.products.length
		// 			&& f.products.some((p) => p.toLowerCase().includes(value)))
		// 		|| (f.tags.length && f.tags.some((p) => p.toLowerCase().includes(value)))
		// );
		// setSearchedFeeds(filter);
	};

	useEffect(() => {
		if (merchantId) {
			updatePaypalSeller({
				paypalTrackingId: merchantId,
				merchantId: paypalMerchantId
			}).then(() => {
				navigate('/');
			});
		}
	}, [merchantId]);

	useEffect(() => {
		if (refresh && refresh.length > 0) {
			navigate('/');
		}
		if (!location.state) return;

		if (location.state && location.state.redirectToStep3) {
			setRedirectToStep3(true);
		}
		if (
			location.state
			&& location.state.refresh == true
			&& location.state.stopEventId
		) {
			setPageLoading(true);
			// stopEventRoom(location.state.stopEventId).then((res) => {
			stopEventRoom(location.state.stopEventId)
				.then((res) => {
					document.getElementById(
						'event-room-event-buyers-template'
					).innerHTML = res.data;
					setTimeout(
						html2pdf()
							.from(document.getElementById('event-room-event-buyers-template'))
							.set({
								margin: 1,
								pagebreak: { mode: ['avoid-all'] },
								html2canvas: { dpi: 192, letterRendering: true },
								jsPDF: {
									orientation: 'portrait',
									unit: 'cm'
								}
							})
							.outputPdf()
							.then((pdf) => {
								sendEventReportEmail(location.state.stopEventId, btoa(pdf))
									.then(() => {
										window.history.replaceState(null, '');
										window.location.reload(true);
										setPageLoading(false);
									})
									.catch((err) => {
										window.history.replaceState(null, '');
										setPageLoading(false);
										console.error(err);
										window.location.reload(true);
									});
							}),
						2000
					);
				})
				.catch((err) => {
					window.history.replaceState(null, '');
					setPageLoading(false);
					window.location.reload(true);
					console.error(err);
				});
		}

		if (location.state && location.state.stripeAccountId) {
			updateStripeAccountId(location.state.stripeAccountId).then(() => {
				navigate('/');
			});
		}

		if (
			location.state
			&& location.state.stripeAccountError
			&& location.state.stripeAccountError === true
		) {
			getUnauthorizedSellerError().then((res) => {
				if (res && res.data && res.data.error) {
					defaultToastError(res.data.error);
				}
			});
		}

		if (location.state && location.state.toastError && !seenToastError) {
			defaultToastError(location.state.toastError);
			setSeenToastError(true);
		}
		if (location.state && location.state.errorMessage && !seenToastError) {
			showError(location.state.errorMessage);
			setSeenToastError(true);
		}
	}, [location.state]);

	useEffect(() => {
		if (!seenToastError) return;
		navigate('/');
	}, [seenToastError]);

	const changeProductQuantityToCart = async (product, quantity) => {
		if (product.quantity + quantity <= 0) {
			showError('You cannot choose a quantity of 0');
			return;
		}

		reserveProduct(product.eventId, product.id, quantity)
			.then(() => {
				dispatch(removeCartItem(product));
				product.quantity += quantity;
				dispatch(addCartItem(product));
			})
			.catch((err) => {
				if (err.response) {
					const { isSlotAvailable } = err.response.data;
					if (!isSlotAvailable) {
						showError('Adding additional products is not possible');
					}
				}
			});
	};

	const openWelcomeToDjawnHelpingPage = () => {
		window.open('https://support.djawn.com/welcome-to-djawn/', '_blank');
	};

	function handleGoToSellerProcess() {
		const cachedUser = JSON.parse(localStorage.getItem('gatsbyUser'));
		if (cachedUser) {
			elligibleForSellerApplication()
				.catch()
				.then(() => {
					navigate('/seller-register');
				})
				.catch((err) => {
					if (err && err.response) {
						defaultToastError(err.response.data.error);
					}
				});
		} else {
			navigate('/signin');
		}
	}

	useEffect(() => {
		showOnBoardProcess()
			.then((onBoardRes) => {
				if (onBoardRes.data === true) {
					getStripeMerchantOnBoard(false).then((res) => {
						if (res.data.url) {
							setOnboardingUrl(res.data.url);
							setShowOnBoardProcessModal(true);
						}
					});
				}
			})
			.catch((err) => {
				console.error('err', err);
			});
		setHubConnection(createHubConnection('event'));
		loadFeeds();
	}, []);

	const setUpdatedEvent = (eventId) => {
		getEventFeed(eventId).then((res) => {
			let tempFeedsData = [...feedData];
			const feedIndex = tempFeedsData.findIndex((f) => f.eventId == eventId);
			if (feedIndex > -1) {
				if (res.data.status == 'Started') {
					tempFeedsData[feedIndex] = null;
					tempFeedsData = [res.data].concat(tempFeedsData);
				} else if (res.data.status == 'Stopped') {
					tempFeedsData[feedIndex] = null;
				} else {
					tempFeedsData[feedIndex] = res.data;
				}
			} else {
				tempFeedsData = [res.data].concat(tempFeedsData);
			}

			setFeedData(tempFeedsData.filter((f) => f != null));
		});
	};

	useEffect(() => {
		if (!wsMessage) return;
		if (wsMessage.type === 'feeds' && wsMessage.eventId) {
			setUpdatedEvent(wsMessage.eventId);
		}
	}, [wsMessage]);

	useEffect(() => {
		if (!hubConnection) return;
		hubConnection
			.start()
			.then(() => {
				hubConnection.on('ReceiveMessage', (message) => {
					setWsMessage(message);
				});
			})
			.catch((e) => {
				console.error(e);
			});
	}, [hubConnection]);

	const formateDateToLocal = (date) => {
		if (date !== null) {
			const formatedDate = new Date(date);

			const localDate = Moment(formatedDate).format('ddd, DD-MMM-YYYY');

			const timeFormatOptions = { hour: 'numeric', minute: '2-digit' };
			const localTime = formatedDate.toLocaleTimeString(
				'en-US',
				timeFormatOptions
			);

			let localTimeZone = formatedDate.toTimeString();

			// eslint-disable-next-line prefer-destructuring
			localTimeZone = localTimeZone.split('(')[1];
			localTimeZone = localTimeZone.slice(0, localTimeZone.length - 1);
			const zoneArray = localTimeZone.split(' ');

			let finalTimeZone = '';
			zoneArray.forEach((item) => {
				finalTimeZone = finalTimeZone.concat(item.slice(0, 1));
			});

			return `${localDate} • ${localTime} (${getTimezoneName(date)})`;
		}
		return 'No Date';
	};

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};

	const getLinkUrl = (status, id) => {
		if (status === 'Started') return `/event/room/${id}`;
		return `/event/${id}`;
	};

	if (pageLoading) {
		return <Loader />;
	}

	return (
		browser && (
			<>
				<StoreModal
					isOpen={isStoreModalOpen}
					setIsOpen={setIsStoreModalOpen}
					store={storeInfo}
					image={sellerImage}
				/>
				<Layout>
					<div
						className={`flex flex-col flex-grow w-full bg-white ${
							showMobileSearch === true && 'items-center mt-2'
						}`}
					>
						<SEO title="Upcoming Events" />
						<ToastContainer
							position="top-center"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
						{stripeDashboardLoginLink && (
							<StripeMissingInformationModal
								showModal={showStripeDashboardModal}
								setShowModal={setShowStripeDashboardModal}
								url={stripeDashboardLoginLink}
							/>
						)}
						{showSellerOnBoardModal && (
							<SellerOnBoardModal
								showModal={showOnBoardProcessModal}
								setShowModal={setShowOnBoardProcessModal}
								url={onboardingUrl}
							/>
						)}
						<HeaderHome
							siteTitle="Event Details"
							showCart
							cartOnClick={() => setIsCartOpen(!isCartOpen)}
							cartItemCount={() => {
								let total = 0;
								cartItems.forEach((cartItem) => {
									if (cartItem.quantity) {
										total += cartItem.quantity;
									} else {
										total += 1;
									}
								});
								return total;
							}}
							onChange={toggleSidebar}
							onSearch={onSearch}
							setLoading={setLoaded}
							setShowSellerOnBoardModal={setShowSellerOnBoardModal}
							onSellerConfirmed={(bannerStatus) => {
								setHideBanner(bannerStatus);
							}}
							onError={(errorMessage) => {
								defaultToastError(errorMessage);
							}}
							onStripeLoginLinkGenerated={(url) => {
								setStripeDashboardLoginLink(url);
								setShowStripeDashboardModal(true);
							}}
						/>
						{selectedEventId && (
							<RoomPolicyConfirmation
								setSelectedEventId={setSelectedEventId}
								showModal={showPolicyModal}
								setShowModal={setShowPolicyModal}
								onConfirm={() => {
									navigate(`/event/room/${selectedEventId}`);
								}}
								onCancel={() => {
									navigate('/');
								}}
							/>
						)}

						<div className="flex flex-row flex-1">
							<Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
							<div
								className={`flex flex-1 flex-col pl-0 sm:pl-0 ${
									isOpen && 'md:pl-60 lg:pl-60'
								}`}
							>
								<div className="flex flex-grow md:px-12 p-2 pt-0">
									<div className="flex flex-row justify-center border-b w-full mx-auto md:mt-4 mt-0 h-auto md:text-md text-sm lg:text-lg">
										<div className="flex">
											<button
												type="button"
												disabled={!loaded}
												className={`w-full px-2 md:px-4 py-2 hover:text-white text-black hover:bg-djawn-300 focus:outline-none
												border rounded-md border-djawn 
											${!breaksSelected && !ripNShipSelected && 'bg-djawn text-white'}`}
												onClick={() => {
													setBreaksSelected(false);
													setRipNShipSelected(false);
													onSearch(searchValue);
													setCurrentPage(0).then((page) => {
														loadFeeds('all', page, true);
													});
												}}
											>
												All
											</button>
										</div>
										<div className="flex">
											<button
												type="button"
												disabled={!loaded}
												className={`w-full px-2 md:px-4 hover:text-white text-black py-2 hover:bg-djawn-300 focus:outline-none
												border rounded-md border-djawn
										${breaksSelected && 'bg-djawn text-white'}`}
												onClick={() => {
													setRipNShipSelected(false);
													setBreaksSelected(true);
													setCurrentPage(0).then((page) => {
														loadFeeds('break', page, true);
													});
												}}
											>
												Break
											</button>
										</div>
										<div className="flex">
											<button
												type="button"
												disabled={!loaded}
												className={`w-full px-2 md:px-4 py-2 text-black hover:text-white hover:bg-djawn-300 focus:outline-none
												border rounded-md border-djawn
										${ripNShipSelected && 'bg-djawn text-white'}`}
												onClick={() => {
													setBreaksSelected(false);
													setRipNShipSelected(true);
													setCurrentPage(0).then((page) => {
														loadFeeds('ripnship', page, true);
													});
												}}
											>
												Rip & Ship
											</button>
										</div>
									</div>
								</div>

								{!isLoggedIn() && (
									<div className="flex flex-col items-center pl-0 sm:pl-0 md:pl-0 lg:pl-0 xl:pl-0 justify-center">
										<img
											src={WelcomeToDjawnImage}
											alt="Welcome to DJAWN!"
											className="feed-banner cursor-pointer"
											onClick={openWelcomeToDjawnHelpingPage}
											onKeyDown={openWelcomeToDjawnHelpingPage}
										/>
									</div>
								)}

								{!hideBanner && showBanner && (
									<div className="flex flex-col items-center pl-0 sm:pl-0 md:pl-0 lg:pl-0 xl:pl-0 justify-center">
										<img
											src={BecomeSellerImage}
											alt="Become a seller"
											className="feed-banner cursor-pointer"
											onClick={handleGoToSellerProcess}
											onKeyDown={handleGoToSellerProcess}
										/>
									</div>
								)}

								<div className="flex flex-col flex-grow p-0 md:p-2 lg:p-4 xl:p-8 pt-0 md:pt-0 lg:pt-0 xl:pt-0 h-full">
									{loaded
										&& !feedsLoading
										&& filteredFeeds.length === 0
										&& !serverUnavailable && (
										<p className="flex flex-row text-3xl not-italic font-sans font-semibold tracking-wide mb-1 cursor-pointer break-all justify-center items-center content-center mt-20">
												No results found
										</p>
									)}
									{loaded && serverUnavailable && (
										<p className="flex flex-row text-xl not-italic font-sans font-semibold tracking-wide mb-1 cursor-pointer break-all justify-center items-center content-center mt-20">
											Sorry for the inconvenience, the site is currently being
											updated and will be available shortly. Please come back
											soon.
										</p>
									)}
									{!loaded && (
										<div className="flex flex-row text-3xl not-italic font-sans font-semibold tracking-wide mb-1 cursor-pointer break-all justify-center items-center content-center mt-20">
											<BounceLoader loading size={30} />
										</div>
									)}
									<InfiniteScroll
										next={() => loadFeeds(
											ripNShipSelected
												? 'ripnship'
												: breaksSelected
													? 'break'
													: 'all'
										)}
										hasMore
										dataLength={filteredFeeds.length}
									>
										<div className="flex flex-grow flex-wrap justify-start content-start">
											{filteredFeeds.map((feed, index) => (
												<>
													<div
														key={index}
														className={`flex flex-wrap flex-col ${
															isLandscape ? 'w-1/2' : 'w-full'
														} md:w-1/2 lg:w-4/12 xl:w-3/12 px-4 py-4`}
													>
														<ReactTooltip />
														{feed.eventImage !== undefined
															&& feed.eventImage !== null && (
															<Link
																to={getLinkUrl(feed.status, feed.eventId)}
																className={`${
																	feed.isRoomFull && 'disabled-link'
																}`}
																disabled={feed.isRoomFull}
															>
																<div
																	className="flex flex-wrap w-full h-48 min-h-80 max-h-80 bg-cover bg-center relative"
																	style={{
																		backgroundImage: `url(${feed.eventImage})`
																	}}
																>
																	<FeedButtons feed={feed} />
																</div>
															</Link>
														)}
														{(feed.eventImage === undefined
															|| feed.eventImage === null) && (
															<Link
																to={getLinkUrl(feed.status, feed.eventId)}
																className={`${
																	feed.isRoomFull && 'disabled-link'
																}`}
																disabled={feed.isRoomFull}
															>
																<div
																	className="flex flex-wrap w-full h-48 min-h-80 max-h-80 bg-cover bg-center relative"
																	style={{
																		backgroundImage: `url(${feed.eventImage})`
																	}}
																>
																	<FeedButtons feed={feed} />
																</div>
															</Link>
														)}

														<div className="flex flex-col flex-wrap justify-between w-full px-0 pt-2 pb-0">
															<div className="flex flex-col">
																<Link
																	to={getLinkUrl(feed.status, feed.eventId)}
																>
																	<EllipisWithTooltip
																		isParagraph
																		isMultiline
																		placement="bottom"
																		className="text-lg line-clamp-2 not-italic font-sans font-semibold tracking-wide mb-1 cursor-pointer"
																	>
																		{`${feed.eventName}`}
																	</EllipisWithTooltip>
																</Link>
																<p className="text-gray-600 mb-1 truncate">
																	<span className="text-sm font-medium">
																		{formateDateToLocal(feed.eventDate)}
																	</span>
																</p>
																<div className="flex justify-between">
																	<p
																		className="text-sm font-medium text-gray-600 mb-1 underline cursor-pointer"
																		onClick={() => {
																			setStoreInfo(feed.account);
																			setSellerImage(feed.sellerImage);
																			setIsStoreModalOpen(true);
																		}}
																		onKeyDown={() => {
																			setStoreInfo(feed.account);
																			setSellerImage(feed.sellerImage);
																			setIsStoreModalOpen(true);
																		}}
																		style={{
																			maxWidth: 200
																		}}
																	>
																		{`${feed.storeName}`}
																	</p>
																	{feed.status === 'Planned'
																		&& (feed.eventTypeId == 10
																			|| feed.eventTypeId == 9) && (
																		<AvailableSlots
																			availableSlotNumber={
																				feed.numberOfAvailableSlots
																			}
																			totalNumberOfSlots={
																				feed.totalNumberOfSlots
																			}
																		/>
																	)}
																	{/* {feed.status === 'Started' && (
																<RoomFull isRoomFull={feed.isRoomFull} />
															)} */}
																</div>
															</div>
														</div>
													</div>
													{hasMoreData
													&& feedsLoading
													&& index == filteredFeeds.length - 1 ? (
															<div className="flex flex-wrap flex-col w-full px-4 py-4">
																<Loader
																	overridenCss={css`
																	top: 50%;
																	left: 50%;
																	transform: translate(-50%, -50%);
																`}
																/>
															</div>
														) : null}
												</>
											))}
										</div>
									</InfiniteScroll>
								</div>
							</div>
						</div>

						<div style={{ display: 'none' }}>
							<div id="event-room-event-buyers-template" />
						</div>
						<MiniCart
							isOpen={isCartOpen}
							showError={(item) => {
								showError(item);
							}}
							cartOpenedFromLiveEvent={() => openCart === true}
							updateTimerDispatch={(item) => {
								dispatch(updateCartItemTimer(item));
							}}
							cartItemLength={() => {
								let total = 0;
								cartItems.forEach((cartItem) => {
									if (cartItem.quantity) {
										total += cartItem.quantity;
									} else {
										total += 1;
									}
								});
								return total;
							}}
							removeItemDispatch={(item) => {
								dispatch(removeCartItem(item));
							}}
							addProductQuantityToCart={(product) => {
								changeProductQuantityToCart(product, 1);
							}}
							decreaseProductQuantityFromCart={(product) => changeProductQuantityToCart(product, -1)}
							closeCart={() => {
								if (openCart === true && window && window.close) {
									window.close();
								} else {
									setIsCartOpen(!isCartOpen);
								}
							}}
							items={cartItems}
							redirectToStep3={redirectToStep3}
							setRedirectToStep3={setRedirectToStep3}
							onLoadedItems={() => {
								setItemsLoaded(true);
							}}
						/>
					</div>
				</Layout>
			</>
		)
	);
};

export default Feeds;
