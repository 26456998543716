/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useRef } from 'react';

import { navigate, Link } from 'gatsby';

import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import Layout from '../../components/layouts/layout-default';
import SEO from '../../components/seo';

import logo from '../../images/djawn_logo.png';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const PaymentErrorPage = (props) => {
	const [loading] = useState(false);
	const [loadingMessage] = useState('loading...');
	const timer = useRef(null);

	useEffect(() => {
		timer.current = setInterval(() => {
			navigate(`/event/${props.eventId}`);
		}, 10000);
		return () => clearInterval(timer.current);
	}, []);

	const gotoEventDetails = () => {
		navigate(`/event/${props.params.eventId}`);
	};

	if (loading) {
		return (
			<div className="w-screen h-screen xxs:h-auto bg-white opacity-50 absolute left-0 top-0">
				<div className="flex flex-col w-full h-full justify-center items-center">
					<div className="w-full">
						<BounceLoader loading css={override} size={30} />
					</div>
					<div className="w-full mt-4">
						<p className="text-xl not-italic text-center font-sans font-semibold tracking-wide mb-1">
							{loadingMessage}
						</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<Layout>
			<div className="flex flex-col flex-1">
				<header className="flex w-full sticky top-0 z-50">
					<SEO title="Thank You Page" />
					<nav className="w-full bg-white border-b">
						<div className=" mx-auto px-4 sm:px-6 lg:px-8">
							<div className="flex items-center justify-center h-16">
								<div className="flex items-center">
									<div className="flex-shrink-0">
										<Link to={`/event/${props.params.eventId}`}>
											<img
												className="h-8 w-auto sm:h-10 cursor-pointer"
												src={logo}
												alt="Workflow"
											/>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</nav>
				</header>

				<div className="flex flex-col flex-1 justify-center content-center">
					<div className="flex flex-col justify-items-center bg-white w-4/6 max-w-xl mx-auto -mt-10 py-10 rounded-lg">
						<div className="flex w-full">
							<h2 className="text-base text-gray-500 text-center sm:text-lg sm:max-w-xl sm:mx-auto  md:text-xl mb-4">
								Uh oh! An error has occurred processing your request.
								<br />
								Please contact support for assistance.
							</h2>
						</div>

						<div className="flex w-full">
							<button
								type="button"
								className=" w-36 mx-auto focus:outline-none text-white text-sm py-2.5 px-5 rounded-md bg-blue-500 hover:bg-blue-600 hover:shadow-lg"
								onClick={() => gotoEventDetails()}
							>
								OK
							</button>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default PaymentErrorPage;
