import React, { useRef, useEffect } from 'react';

const MiniEventTimer = ({
	date, item, breakId, timerEnded, text
}) => {
	const timer = useRef(breakId);
	const minutesRef = useRef();
	const secondsRef = useRef();

	const getTimeRemaining = (endtime) => {
		const t = Date.parse(endtime) - Date.parse(new Date());
		const seconds = Math.floor((t / 1000) % 60);
		const minutes = Math.floor((t / 1000 / 60) % 60);
		const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
		const days = Math.floor(t / (1000 * 60 * 60 * 24));

		return {
			total: t,
			days,
			hours,
			minutes,
			seconds
		};
	};

	const updateClock = () => {
		const t = getTimeRemaining(date);
		if (t.total <= 0 || t.total === 0 || t.total === 1000) {
			timerEnded(breakId);
			clearInterval(timer.current);
		} else if (minutesRef.current && secondsRef.current) {
			minutesRef.current.innerText = `0${t.minutes}`.slice(-2);
			secondsRef.current.innerHTML = `0${t.seconds}`.slice(-2);
		}
	};

	useEffect(() => {
		timer.current = setInterval(() => {
			updateClock();
		}, 1000);
		return () => clearInterval(timer.current);
	}, [JSON.stringify(item)]);

	return (
		<div id={`${breakId}`} className="flex flex-row">
			{text == null ? (
				<span>Time remaining&nbsp;</span>
			) : (
				<span>
					{text}
					&nbsp;
				</span>
			)}
			<div>
				<span className="minutes" ref={minutesRef} />
			</div>
			<div>
				<span>:</span>
			</div>
			<div>
				<span className="seconds" ref={secondsRef} />
			</div>
			<div>
				<span className="">&nbsp;min</span>
			</div>
		</div>
	);
};

export default MiniEventTimer;
