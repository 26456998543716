import clsx from 'clsx';
import React from 'react';
import HamburgerMenuIcon from '../../../assets/icons/hamburger-menu-icon';
import Button from '../../../components/button';

const EventQueueFooterButtons = ({
	isSeller,
	onClick,
	onOpenBulkMicActions
}) => (
	<>
		<div className="flex justify-center items-center mt-4">
			{isSeller ? (
				<div className="z-50 w-24">
					<Button
						width="24"
						height="8"
						mobileHeight="8"
						mobileWidth="full"
						onClick={onOpenBulkMicActions}
						additionalClassName="z-50"
					>
						Actions
					</Button>
				</div>
			) : null}
			<div
				className={clsx(isSeller && 'flex-1 flex justify-center')}
				style={isSeller ? { transform: 'translateX(-3.5rem)' } : {}}
			>
				<button
					type="button"
					className="bg-white border border-djawn dark:bg-darkGray-50 rounded-full h-10 w-10 flex items-center justify-center focus:outline-none"
					onClick={onClick}
				>
					<HamburgerMenuIcon className="w-8 h-8" />
				</button>
			</div>
		</div>
	</>
);

export default EventQueueFooterButtons;
