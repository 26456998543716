import { useEffect } from 'react';

const useRepositionOnResize = (handler, active = true) => {
	useEffect(() => {
		if (!active) return;
		const listener = () => {
			handler();
		};

		window.addEventListener('resize', listener);

		return () => {
			if (!active) return;
			window.removeEventListener('resize', listener);
		};
	}, [handler, active]);
};

export default useRepositionOnResize;
