/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import { referenceData } from '../../api/referenceData.request';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

function Cards({ stepData, onPrev, onNext }) {
	const [referenceDataValue, setReferenceDataValue] = useState('');
	const [otherCardDetail, setOtherCardDetail] = useState(
		stepData.otherCardDetail
	);
	const [favouriteBrand, setFavouriteBrand] = useState(stepData.favouriteBrand);
	const [loading, setLoading] = useState(true);
	const [checkboxdata, setcheckbox] = useState([]);
	const [isOtherCardDetail, setIsOtherCardDetail] = useState(false);

	useEffect(() => {
		if (localStorage.getItem('checkbox') != null) {
			setcheckbox(
				stepData.cardData
					? stepData.cardData
					: JSON.parse(localStorage.getItem('checkbox'))
			);
			stepData.cardData
				&& stepData.cardData.length > 0
				&& stepData.cardData.map((i) => {
					if (i === 'Other') {
						setIsOtherCardDetail(true);
					}
				});
		}
	}, []);

	function checkBox(e) {
		const checkData = e.target.getAttribute('data-id');

		if (checkData === 'Other') {
			if (e.target.checked) {
				setIsOtherCardDetail(true);
			} else {
				setIsOtherCardDetail(false);
			}
		}

		setcheckbox((pre) => {
			if (pre.includes(checkData)) {
				const data = pre.filter((e) => e != checkData);
				localStorage.setItem('checkbox', JSON.stringify(data));

				return data;
			}
			localStorage.setItem('checkbox', JSON.stringify([...pre, checkData]));
			return [...pre, checkData];
		});
	}

	useEffect(() => {
		const referenceTypeId = 37;
		referenceData(referenceTypeId)
			.then((res) => {
				setReferenceDataValue(res.data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const prevStep = () => {
		onPrev(2);
	};
	const nextStep = () => {
		let otherCardData = '';
		checkboxdata
			&& checkboxdata.length > 0
			&& checkboxdata.map((i) => {
				if (i === 'Other') {
					otherCardData = otherCardDetail;
				}
			});
		const setp3values = {
			cardData: checkboxdata,
			otherCardDetail: otherCardData,
			favouriteBrand
		};

		onNext(setp3values);
	};

	return (
		<div className="flex-grow px-0 grid sm:grid-rows-4 md:grid-rows-4 lg:grid-rows-4 xl:grid-rows-4 lg:grid-flow-col xl:grid-flow-col gap-4">
			<div className="mb-6 sm:w-full md:w-full lg:w-full xl:w-full mt-6">
				<label
					htmlFor="plateform"
					className="block text-sm font-medium text-gray-700 pl-2 "
				>
					Which type of cards do you plan on selling? (you can select multiple)
				</label>
				<div className="flex-grow px-0 grid sm:grid-rows-7 md:grid-rows-2 lg:grid-rows-1 xl:grid-rows-1 lg:grid-flow-col xl:grid-cols-3 ">
					{loading && (
						<div className="flex h-full items-center">
							<BounceLoader loading css={override} size={30} />
						</div>
					)}
					<div className="xl:flex lg:flex md:flex lg:col-span-2 xl:col-span-2 gap-6">
						{referenceDataValue
							&& referenceDataValue.length > 0
							&& referenceDataValue.map((e, index) => (
								<div className="flex-grow mt-6 ml-2 h-10" key={index}>
									<label className="flex flex-grow items-center">
										<input
											type="checkbox"
											className="form-checkbox"
											checked={!!checkboxdata.includes(e.referenceDataValue)}
											onChange={checkBox}
											data-id={e.referenceDataValue}
										/>
										<span className="ml-2" style={{ whiteSpace: 'nowrap' }}>
											{e.referenceDataValue}
										</span>
									</label>
								</div>
							))}
						<div className="flex-grow mt-4 ml-2  md:w-48 w-auto ">
							<div
								className={`relative rounded-md ${
									isOtherCardDetail && 'shadow-sm'
								} h-10 md:w-48 w-auto `}
							>
								<input
									type="text"
									name="other_card"
									id="other_card"
									className={`focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md ${
										isOtherCardDetail ? 'block' : 'hidden'
									}`}
									placeholder="Please provide details about other"
									value={
										otherCardDetail.length <= 1
											? otherCardDetail.replace(/\s/g, '')
											: otherCardDetail
									}
									required
									maxLength={200}
									onBlur={(e) => setOtherCardDetail(e.target.value)}
									onChange={(e) => setOtherCardDetail(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="mb-6 sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
				<label
					htmlFor="car_brand"
					className="block text-sm font-medium text-gray-700 pl-2 "
				>
					What is your favourite brand of cards?
				</label>
				<div className="mt-1 relative rounded-md shadow-sm pl-2">
					<input
						type="text"
						name="car_brand"
						id="car_brand"
						className="focus:ring-indigo-400 focus:border-indigo-400 pl-2 pr-2 block w-full h-10  sm:text-sm border border-gray-200 rounded-md mt-2"
						placeholder="You can enter multiple here.."
						value={
							favouriteBrand.length <= 1
								? favouriteBrand.replace(/\s/g, '')
								: favouriteBrand
						}
						required
						maxLength={200}
						onBlur={(e) => setFavouriteBrand(e.target.value)}
						onChange={(e) => setFavouriteBrand(e.target.value)}
					/>
				</div>
			</div>

			<div className="flex justify-between mx-auto">
				<div className="w-2/5">
					<button
						type="button"
						className="min-w-full bg-gray-200 hover:bg-gray-300 text-white font-bold py-2 px-6 rounded-lg"
						onClick={prevStep}
					>
						Previous
					</button>
				</div>
				<div className="w-2/5">
					<button
						type="button"
						className="min-w-full bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-lg"
						onClick={nextStep}
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
}
export default Cards;
