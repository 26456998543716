import React from 'react';

const ConversationReactions = ({
	showModal,
	setShowModal,
	reactions,
	participants
}) => (
	<>
		{showModal ? (
			<>
				<div
					className="justify-center items-center flex fixed inset-0 outline-none focus:outline-none"
					style={{ zIndex: 99999 }}
				>
					<div className="border-0 rounded-lg shadow-lg flex flex-col bg-white outline-none focus:outline-none w-full md:w-auto">
						<div className="flex flex-row items-center justify-between p-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg md:ml-0">
							<div style={{ textAlign: 'center' }}>
								<p className="text-opacity-100 font-bold">Reactions</p>
							</div>
							<div className="flex">
								<button
									type="button"
									className="focus:outline-none items-center text-black hover:text-black text-lg rounded-md rounded-tr-none rounded-br-none cursor-pointer"
									onClick={() => {
										setShowModal(!showModal);
									}}
								>
									<svg
										className="w-6 h-6"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</button>
							</div>
						</div>
						<div className="flex flex-col px-6 py-5 bg-gray-50 mb-5 md:mx-0">
							{reactions
								&& reactions.map((object, i) => {
									const participant = participants.find(
										(r) => r.user_id == object.user_id
									);
									return (
										<div
											key={i}
											className="w-full px-5 py-3 flex flex-row justify-between"
										>
											<div className="flex flex-row justify-between w-11/12 max-w-max">
												<div className="flex items-center">
													<img
														src={participant.image}
														alt="foto"
														className="w-10 h-10 mr-3 border rounded-2xl"
													/>
													<div className="flex flex-col">
														<span className="font-bold text-md mr-2 font-sans break-all">
															{participant.name}
														</span>
														<div className="whitespace-pre-wrap break-all">
															{participant.nickname}
														</div>
													</div>
												</div>
											</div>
											<div className="md:block ml-auto mt-auto mb-auto mr-2 items-end w-1/12">
												<svg
													className="w-8 h-8 m-1"
													fill="red"
													viewBox="0 0 20 20"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
												</svg>
											</div>
										</div>
									);
								})}
						</div>
					</div>
				</div>
				<div className="opacity-25 fixed inset-0 z-40 bg-black" />
			</>
		) : null}
	</>
);

export default ConversationReactions;
