import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { css } from '@emotion/core';
import BounceLoader from 'react-spinners/BounceLoader';
import { ToastContainer, toast } from 'react-toastify';
import { saveLogin } from '../utils/auth';
import Header from '../components/header';
import {
	facebookAuthentication,
	googleAuthentication
} from '../api/auth.request';
import { defaultToastError } from '../utils/toast-error';
import Layout from '../components/layouts/layout-default';
import FacebookGoogleAuth from '../components/common/fb-google-auth';
import { redirectToDefaultBrowser } from '../utils/redirect';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: blue;
`;

const SigninPage = ({ location }) => {
	const [loading, setLoading] = useState(false);
	const [redirectUrl, setRedirectUrl] = useState('');

	const onButtonClicked = () => {
		setLoading(true);
	};

	// useEffect(() => {
	// 	logout();
	// 	setLoading(false);
	// }, []);

	useEffect(() => {
		redirectToDefaultBrowser();
	}, [window]);

	useEffect(() => {
		if (!location.state || !location.state.redirectUrl) return;
		if (location.state.redirectUrl) {
			setRedirectUrl(location.state.redirectUrl);
		}
	}, [location.state]);

	const authUser = async (response, accessToken, provider = 'facebook') => {
		const req = { accessToken };
		const providerAuthentication =			provider === 'facebook' ? facebookAuthentication : googleAuthentication;
		const res = await providerAuthentication(req).catch((error) => {
			if (error.response) {
				if (error.response.status === 404) {
					if (provider === 'facebook') {
						navigate('/signup', {
							state: { facebookResponse: response }
						});
					} else {
						navigate('/signup', {
							state: {
								googleResponse: {
									response: response.profileObj,
									token: response.tokenId
								}
							}
						});
					}
				} else {
					defaultToastError(error.response.data.error);
				}
			} else if (error.request) {
				toast.error(
					'⚠️ Sign In Failed! Server not responding. Please try after a while',
					{
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					}
				);
				setLoading(false);
			} else {
				// Something happened in setting up the request that triggered an Error
				toast.error(
					'⚠️ Sign In Failed! Something went wrong. Please try again.',
					{
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					}
				);
			}
		});

		if (res !== undefined && res !== null) {
			if (res.status === 200) {
				saveLogin({
					name: res.data.name,
					// proPic: response.picture.data.url,
					id: res.data.id,
					email: res.data.email,
					token: res.data.token,
					refreshToken: res.data.refreshToken,
					userDetails: res.data.userDetails,
					expirationDate: res.data.expirationDate,
					firstName: res.data.firstName,
					lastName: res.data.lastName
				});
				if (redirectUrl && redirectUrl.length > 0) {
					navigate(redirectUrl);
				} else {
					navigate('/');
				}
			} else {
				setLoading(false);
				toast.error('⚠️ Login Failed! Please try again.', {
					position: 'top-center',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
			}
		}
	};

	const onLoginError = () => {
		setLoading(false);
		toast.error('⚠️ Login Failed! Please try again.', {
			position: 'top-center',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	};

	const onReAuthAction = () => {
		navigate('/');
	};

	return (
		<Layout>
			<div className="flex flex-col flex-1">
				<Header siteTitle="Signin" auth />
				{loading && (
					<div className="w-screen h-screen bg-white absolute left-0 top-0 z-50">
						<div className="flex h-full items-center">
							<BounceLoader loading css={override} size={30} />
						</div>
					</div>
				)}

				{!loading && (
					<div className="flex flex-col flex-1 justify-center content-center">
						<div className="flex flex-col justify-items-center bg-white w-4/6 max-w-xl mx-auto -mt-10 py-10 rounded-lg">
							<h2 className="text-base text-gray-500 text-center sm:text-lg sm:max-w-xl sm:mx-auto  md:text-xl mb-4">
								Sign In
							</h2>
							<ToastContainer />
							<FacebookGoogleAuth
								buttonTitle="Sign In With Facebook"
								onButtonClicked={() => onButtonClicked()}
								authUser={(response, accessToken, provider) => authUser(response, accessToken, provider)}
								onReAuth={() => onReAuthAction()}
								onError={() => onLoginError()}
							/>
						</div>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default SigninPage;
