import { ADD_ITEM_TO_CART, REMOVE_ITEM_FROM_CART, UPDATE_ITEM_TIMER } from '../types';

const initialState = {
	cartItems: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ADD_ITEM_TO_CART:
			const tempAddedItems = [...state.cartItems];
			tempAddedItems.push(action.cartItem);
			return { ...state, cartItems: tempAddedItems };
		case REMOVE_ITEM_FROM_CART:
			let tempRemovedItems = [...state.cartItems];
			tempRemovedItems = tempRemovedItems.filter((f) => f.id != action.cartItem.id);
			return { ...state, cartItems: tempRemovedItems };
		case UPDATE_ITEM_TIMER:
			const tempItems = [...state.cartItems].map((item) => {
				if (item.id == action.cartItem.id) {
					item.time = action.cartItem.time;
				}
				return item;
			});
			return { ...state, cartItems: tempItems };
		default:
			return state;
	}
};
